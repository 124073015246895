import { BGCoalCostingService, BusinessAllocationDropdownDto, BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, CostingTypesEnum, GetBargesCoalCoastReq, PurchaseContractService, PurchaseDropDownDto } from '@exportx/shared-models-and-services';
import { SequenceUtils } from '@exportx/ui-utils';
import { Card, Row, Col, Button, Form, Select, Tabs, Steps, FormInstance } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AlertMessages, useAuthState } from '../../../../common';

interface ICoalCostingBgSelectFormProps {
    cancelHandler?: () => void;
    initialValues: any;
    coalCostingType: CostingTypesEnum;
    setSelectedRowKeysData: (selectedRows: any) => void
    formRef: FormInstance<any>
}
export const CoalCostingBgSelectForm = (props: ICoalCostingBgSelectFormProps) => {
    const { cancelHandler, coalCostingType, setSelectedRowKeysData } = props;
    const navigate = useNavigate();
    const { Option } = Select
    const [purchases, setPurchases] = useState<PurchaseDropDownDto[]>([]);
    const { authContext } = useAuthState();
    const [form] = Form.useForm();
    const [businessDropDown, setBusinessDropDown] = useState<BusinessAllocationDropdownDto[]>([]);
    const [bargeData, setBargeData] = useState<any[]>([]);
    const [pcData, setPcData] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    let pathToreDirectPcDetails = '/po-detail-view';

    const purchase = new PurchaseContractService();
    const businessNumberService = new BusinessNumberService();
    const bgCoalCostingService = new BGCoalCostingService();


    const redirectToPcFullView = (contractId: string, plantCode: string) => {
        navigate(pathToreDirectPcDetails, { state: { contractId, plantCode } });
    }
    let pathToreDirectToBargeDetails = '/barge-detail-view';
    const redirectToBargeDetailsFullView = (bargeId: number) => {
        navigate(pathToreDirectToBargeDetails, { state: { bargeId } });
    }

    useEffect(() => {
        getAllPurchaseContracts();
    }, [])

    const getAllPurchaseContracts = () => {
        purchase.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setPurchases(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    };

    const getBusinessAllocationDropDown = () => {
        businessNumberService.getBusinessAllocationDropDown({ contractId: form.getFieldValue('purchaseContract'), plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setBusinessDropDown(res.data)
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }

    const getBargesForPurFinancialCoalCosting = () => {
        if (!form.getFieldValue('purchaseContract')) {
            AlertMessages.getErrorMessage('Please select Contract')
        }
        const req = new GetBargesCoalCoastReq();
        req.purchaseContractId = form.getFieldValue('purchaseContract');
        req.costingType = coalCostingType;
        req.businessNoIds = form.getFieldValue('businessNumber');
        bgCoalCostingService.getBargesForPurFinancialCoalCosting(req).then((res: any) => {
            if (res.status) {
                setBargeData(res.data.barges);
                setPcData(
                    {
                        ['purchaseContractId']: res.data.purchaseContractId,
                        ['purchaseContractNo']: res.data.purchaseContractNo,
                        ['supplierName']: res.data.supplierName
                    }
                )
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    };

    const pcOnChange = () => {
        getBusinessAllocationDropDown();
    }

    const columns: ColumnsType<any> = [
        {
            title: "Purchase Contract",
            dataIndex: 'purchaseContract',
            render: (text) => {
                return <><a className="link-primary" onClick={() => redirectToPcFullView(pcData?.purchaseContractId, authContext.defaultPlant)}>
                    {pcData?.purchaseContractNo}
                </a></>
            }
        },
        {
            title: "Barge Id",
            dataIndex: 'bargeNo',
            render: (text, record) => <><a className="link-primary" onClick={() => redirectToBargeDetailsFullView(record.bargeNo)}>
                {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
            </a></>
        },
        {
            title: "Barge Nomination",
            dataIndex: 'bargeNomination',
        },
        {
            title: "Jetty",
            dataIndex: 'jetty'
        },
        {
            title: "Loading Date",
            dataIndex: 'loadingDate',
            render: (value, record) => {
                return <>{moment(value).format('YYYY-MM-DD')}</>
            }
        },
        {
            title: "Business Number",
            dataIndex: 'businessNUmber',
        },
        {
            title: "Quality",
            dataIndex: 'quality',
        },

        {
            title: "Purchase Type",
            dataIndex: 'purchaseType',
        },
        {
            title: "Quantity",
            dataIndex: 'quantity',
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeysData(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys: selectedRowKeys
    };

    return (
        <Card title='Select Barge to create costing' className='mt-2'>
            <Form layout='vertical' form={form}>
                <Row>
                    <Col span={8} style={{ paddingLeft: 30, width: 100 }}>
                        <Form.Item name="purchaseContract" label='Purchase Contract'  >
                            <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select purchase Contract' onChange={pcOnChange}>
                                <Option value="">Please select</Option>
                                {purchases.map(item => {
                                    return <Option value={item.pcId}>{item.contractAndSupplier}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ paddingLeft: 40 }}>

                        <Form.Item name='businessNumber' label='Business Number'>
                            <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Business Number'>
                                <Option value="">Please select</Option>
                                {businessDropDown.map(item => {
                                    return <Option value={item.businessNoId}>{item.businessNo}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8} style={{ paddingTop: 30, paddingLeft: 80 }}>

                        <Button type='primary' onClick={getBargesForPurFinancialCoalCosting}>
                            Load Barges
                        </Button>

                    </Col>
                </Row>

                <Table
                    className='contracts'
                    rowSelection={{
                        ...rowSelection
                    }}
                    rowKey={record => record.bargeId}
                    columns={columns}
                    dataSource={bargeData}
                    pagination={false}
                />
            </Form>
        </Card>

    )
}

export default CoalCostingBgSelectForm;