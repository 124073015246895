import { CheckOutlined, CloseCircleOutlined, EditFilled, EyeOutlined, SearchOutlined } from '@ant-design/icons'
import { TaxesAndItemsGetDataDto, TaxesAndItemsReqIdDto, TaxesAndItemsService } from '@exportx/shared-models-and-services'
import { Button, Card, Divider, Input, InputRef, Popconfirm, Space, Switch, Tooltip } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { ColumnType, FilterConfirmProps } from 'antd/lib/table/interface'
import { useEffect, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useNavigate } from 'react-router-dom'
import { AlertMessages, isPermissionExist } from '../../../../common'


interface DataType {
    key: string;
    bpName: string;
    tax: string;
    commodity: string;
}


export const TaxesAndItemsGrid = () => {

    const navigate = useNavigate();
    const [gridData, setGridData] = useState<TaxesAndItemsGetDataDto[]>([]);
    const taxesAndItemsService = new TaxesAndItemsService();

    type DataIndex = keyof DataType;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    useEffect(()=>{
        if(!isPermissionExist([27])){
            return navigate('/')
        }
    },[])

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
            <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });



    useEffect(() => {
        getTaxesAndItemsData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getTaxesAndItemsData = () => {
        taxesAndItemsService.getTaxesAndItemsData().then(res => {
            if (res.status) {
                setGridData(res.data);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message)
        })
    }

    const taxesAndItemsActivateDeactivate = (taxesItemsId) => {
        const req = new TaxesAndItemsReqIdDto(taxesItemsId)
        taxesAndItemsService.taxesAndItemsActivateDeactivate(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage)
                getTaxesAndItemsData()
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message)
        })
    }

    const columns: ColumnsType<any> = [

        // {
        //     title: "Business Partner",
        //     dataIndex: "bpName",
        //     ...getColumnSearchProps('bpName'),
        //     width: '20%',
        // },
        {
            title: "Tax Name",
            dataIndex: "tax",
            ...getColumnSearchProps('tax'),
            width: '20%',
            key: 'Tax Name'
        },
        {
            title: "Item Name",
            dataIndex: "commodity",
            ...getColumnSearchProps('commodity'),
            width: '20%',
            key: 'Item Name'

        },
        {
            title: "Action",
            align: 'center',
            width: '20%',
            key: 'Action',

            render: (value, record) => {
                return <span>
                    {isPermissionExist([27]) && (
                    <Tooltip title={'View'}  > 
                        <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }} onClick={() => (navigate('/create-items-and-taxes', { state: { record: record, hidden: true } }))}></EyeOutlined>
                    </Tooltip>)} <Divider type='vertical' />
                    {isPermissionExist([28]) && (
                    <Tooltip title={record.isActive ? "Edit" : ""}> <EditFilled
                        style={{ fontSize: '24px', color: record.isActive ? " #1890ff" : "#8080807d" }}
                        onClick={() => {
                            if (!record.isActive) {
                                AlertMessages.getErrorMessage('You Could Not Update Deactivated Record')
                            } else {
                                (navigate('/create-items-and-taxes', { state: { record: record, isUpdate: true } }));
                            }
                        }}>

                    </EditFilled>
                    </Tooltip>)}

                    <Divider type='vertical' />
                    {isPermissionExist([29]) && (
                    <Popconfirm onConfirm={() => taxesAndItemsActivateDeactivate(record.taxesItemsId)} title={`${record.isActive ? "Are you sure to Deactivate Record" : "Are You Sure To Activate Record ?"}`}>
                        <Tooltip title={record.isActive ? 'Deactivate' : "Activate"}>
                            <Switch
                                size='default'
                                className={record.isActive ? 'toggle-activated' : 'toggle-deactivated'}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseCircleOutlined />}
                                checked={record.isActive}
                            />
                        </Tooltip>
                    </Popconfirm >)}
                </span >
            }
        }
    ]


    return (
        <>
            <Card title={"Taxes and Items"}
                className='default-card-class'
                extra={<span>{isPermissionExist([26]) && (<Button onClick={() => { navigate('/create-items-and-taxes') }}>Create</Button>)}</span>}>
                <Table rowKey={(record) => record.taxesItemsId}
                    columns={columns} dataSource={gridData} pagination={false}></Table>
            </Card>
        </>


    )
}

export default TaxesAndItemsGrid