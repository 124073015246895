import { EditOutlined, EyeOutlined, RightSquareOutlined } from '@ant-design/icons';
import { JettyDto, JettyService } from "@exportx/shared-models-and-services";
import { Button, Card, Divider, Popconfirm, Switch, Table, Tooltip } from 'antd';
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AlertMessages, isPermissionExist } from "../../../../common";

const JettyGrid = () => {

  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const [, setselectedgridData] = useState<any>(undefined);
  const [gridData, setGridData] = useState<JettyDto[]>([]);
  const service = new JettyService();


  const openFormWithData = (rowData: JettyDto) => {
    setselectedgridData(rowData);
  }

  useEffect(()=>{
      if(!isPermissionExist([71])){
        return navigate('/')
      }
  },[])

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAll = () => {
    service.getAllJettyNames().then(res => {
      if (res.status) {
        setGridData(res.data)
      } else {
        setGridData([]);
      }
    }).catch(err => {
      setGridData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const deleteProfile = (jettyData: JettyDto) => {
    jettyData.isActive = jettyData.isActive ? false : true;
    service.activateOrDeactivate(jettyData).then(res => {
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Jetty${jettyData.isActive ? ' activated ' : ' deactivated '} SuccessFully` }));
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const Columns: any = [
    {
      title: 'JettyName',
      dataIndex: 'jettyName',
      width: 130,
      sorter: (a: { jettyName: string; }, b: { jettyName: any; }) => a.jettyName.localeCompare(b.jettyName),
      sortDirections: ['descend', 'ascend'],

    },

    {
      title: 'Area',
      dataIndex: 'area',
      width: 130,
      sorter: (a: { area: string; }, b: { area: any; }) => a.area.localeCompare(b.area),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Country',
      dataIndex: 'countryName',
      width: 130,
      sorter: (a: { countryName: string; }, b: { countryName: any; }) => a.countryName.localeCompare(b.countryName),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Province',
      dataIndex: 'portName',
      width: 130,
      sorter: (a: { port: string; }, b: { port: any; }) => a.port.localeCompare(b.port),
      sortDirections: ['descend', 'ascend'],
    },


    {
      title: 'Action',
      dataIndex: 'operations',
      width: 130, 
      render: (text, rowData) => (

        <span>
          {isPermissionExist([71]) && (
          <Tooltip title={'View'}  > 
            <EyeOutlined style={{ color: "#1890ff", fontSize: '15px' }} onClick={() => (navigate("/jettyForm", { state: { jettyData: rowData, hidden: true } }))}></EyeOutlined>
          </Tooltip>)}
          {isPermissionExist([72]) && (
          <EditOutlined className={'editSamplTypeIcon'} type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
                navigate("/jettyForm", { state: { jettyData: rowData, isUpdate: true } })

              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Jetty');
              }
            }}
            style={{ color: '#1890ff', fontSize: '20 px' }}
          />)}

          <Divider type="vertical" />
          {isPermissionExist([73]) && (
          <Popconfirm onConfirm={e => { deleteProfile(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate ?'
                : 'Are you sure to Activate ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>)}
        </span>
      )
    },

  ]


  return (

    <div>
      <Card title="Jetty"

        className='default-card-class'
        extra={<>{isPermissionExist([70]) && (<span style={{ color: 'white' }} ><Button onClick={() => navigate("/jettyForm")}>Create</Button> </span>)}</>}>


        <Table rowKey={(rowData)=>rowData.jettyId} columns={Columns} dataSource={gridData} />

      </Card>

    </div>
  )

}


export default JettyGrid;







