import { Card, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

export const ASHPenalty = () => {

  const columns: ColumnsType<any> = [
    {
      title: "Units",
      dataIndex: 'units',
    },
    {
      title: "Basis",
      dataIndex: 'basis',
    },
    {
      title: "Typical",
      dataIndex: 'typical',
    },
    {
      title: "Rejection",
      dataIndex: 'rejection',
    },

    {
      title: "Actual",
      dataIndex: 'actual',
    },
    {
      title: "",
      dataIndex: '',
    },
  ]


  return (
    <Card title='ASH Penalty per MT'>
      <Table columns={columns} dataSource={undefined}></Table>


    </Card>

  )
}

export default ASHPenalty