import { ContractTypesEnum, PurchaseContractDto, PurchaseTermDataDto, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';

export interface IASHData {
    purchaseTermData: PurchaseTermDataDto,
    specType: SpecTypesEnum;
    snapShotPurchaseTermData: PurchaseTermDataDto;
    contractType: ContractTypesEnum;
    snapSpecType: SpecTypesEnum;
}
const ElementsView = (props: IASHData) => {
    const { contractType } = props;
    const [ashFusionDataSource, setASHFusionDataSource] = useState([]);
    const [addendumAshFusionDataSource, setAddendumASHFusionDataSource] = useState([]);

    useEffect(() => {
        if (props.purchaseTermData)
            setASHFusionDataSource(constructDataSource(props.purchaseTermData, props.specType));
        if (props.snapShotPurchaseTermData)
            setAddendumASHFusionDataSource(constructDataSource(props.snapShotPurchaseTermData, props.snapSpecType));
    }, [props.purchaseTermData]);

    const sharedOnCell = (_: any, index: number) => {
        if ([0, 2, 4, 6, 8, 10, 12, 14, 16].includes(index)) {
            return { colSpan: 0 };
        }
        return {};
    };
    const constructDataSource = (purchaseTermData: PurchaseTermDataDto, specType: SpecTypesEnum) => {
        let data = specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData;
        let dataSource = [];

        dataSource.push({
            component: 'Trace Elements',
            rowSpan: 8,
            basis: data?.traceArsenicBasis ? data?.traceArsenicBasis : '-',
            typical: 'Arsenic (ppm)',
            rejection: '-',
        });

        dataSource.push({
            component: 'Trace Elements',
            rowSpan: 0,
            basis: data?.traceArsenicBasis ? data?.traceArsenicBasis : '-',
            typical: data?.traceArsenicTypical ? data?.traceArsenicTypical : '-',
            rejection: data?.traceArsenicRejection ? data?.traceArsenicRejection : '-',
        });

        dataSource.push({
            component: 'Trace Elements',
            rowSpan: 0,
            basis: data?.traceMercuryBasis ? data?.traceMercuryBasis : '-',
            typical: 'Mercury (ppm)',
            rejection: '-',
        });

        dataSource.push({
            component: 'Trace Elements',
            rowSpan: 0,
            basis: data?.traceMercuryBasis ? data?.traceMercuryBasis : '-',
            typical: data?.traceMercuryTypical ? data?.traceMercuryTypical : '-',
            rejection: data?.traceMercuryRejection ? data?.traceMercuryRejection : '-',
        });

        dataSource.push({
            component: 'Trace Elements',
            rowSpan: 0,
            basis: data?.traceSeleniumBasis ? data?.traceSeleniumBasis : '-',
            typical: 'Selenium (ppm)',
            rejection: '-',
        });
        //5
        dataSource.push({
            component: 'Trace Elements',
            rowSpan: 0,
            basis: data?.traceSeleniumBasis ? data?.traceSeleniumBasis : '-',
            typical: data?.traceSeleniumTypical ? data?.traceSeleniumTypical : '-',
            rejection: data?.traceSeleniumRejection ? data?.traceSeleniumRejection : '-',
        });

        dataSource.push({
            component: 'Trace Elements',
            rowSpan: 0,
            basis: data?.traceBoronBasis ? data?.traceBoronBasis : '-',
            typical: 'Boron (ppm)',
            rejection: '-',
        });

        dataSource.push({
            component: 'Trace Elements',
            rowSpan: 0,
            basis: data?.traceBoronBasis ? data?.traceBoronBasis : '-',
            typical: data?.traceBoronTypical ? data?.traceBoronTypical : '-',
            rejection: data?.traceBoronRejection ? data?.traceBoronRejection : '-',
        });

        //9
        dataSource.push({
            component: 'Minor Elements',
            rowSpan: 4,
            basis: data?.minorBaBasis ? data?.minorBaBasis : '-',
            typical: 'Ba, Be, Cd, Cr, Cu, Fe, Mn, Ni, Pb, V, Zn, Sr, Sb, Co, Mo (ppm)',
            rejection: '-',
        });

        dataSource.push({
            component: 'Minor Elements',
            rowSpan: 0,
            basis: data?.minorBaBasis ? data?.minorBaBasis : '-',
            typical: data?.minorBaTypical ? data?.minorBaTypical : '-',
            rejection: data?.minorBaRejection ? data?.minorBaRejection : '-',
        });

        dataSource.push({
            component: 'Minor Elements',
            rowSpan: 0,
            basis: data?.minorPhosphorousBasis ? data?.minorPhosphorousBasis : '-',
            typical: 'Phosphorous,Chlorine (Cl),Flourine (F) (ppm)',
            rejection: '-',
        });

        dataSource.push({
            component: 'Minor Elements',
            rowSpan: 0,
            basis: data?.minorPhosphorousBasis ? data?.minorPhosphorousBasis : '-',
            typical: data?.minorPhosphorousTypical ? data?.minorPhosphorousTypical : '-',
            rejection: data?.minorPhosphorousRejection ? data?.minorPhosphorousRejection : '-',
        });

        return dataSource;
    };
    const valiDateWithSnapShotData = (index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = addendumAshFusionDataSource?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM && valueAtGivenKey !== '-') {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={''}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }


    const columns: ColumnsType<any> = [
        {
            title: '',
            key: 'component',
            dataIndex: 'component',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'components', value)}</span>
            ),
        },
        {
            title: 'Ash Typical',
            dataIndex: 'typical',
            key: 'typical',
            onCell: (_, index) => ({
                colSpan: [0, 2, 4, 6, 8, 10, 12, 14, 16].includes((index as number)) ? 2 : 1,
            }),
            render: (text, record, index) => (
                [0, 2, 4, 6, 8, 10, 12, 14, 16].includes((index as number)) ? <a>{valiDateWithSnapShotData(index, 'typical', text)}</a> : <>{text}</>
            ),
        },
        {
            title: 'Ash Rejection',
            dataIndex: 'rejection',
            key: 'rejection',
            onCell: sharedOnCell,
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'rejection', value)}</span>
            ),
        }
    ];

    const rowClassName = (row: any, index) => {
        return index % 2 === 0 ? 'row-even' : 'row-odd'; 
      };

    return (
        <Table columns={columns} dataSource={ashFusionDataSource} rowClassName={rowClassName} pagination={false} className="contracts-PO-List" />
    )
}

export default ElementsView;