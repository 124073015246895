import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { BpCategoryGetDto, BusinessPartnerService, BusinessPartnerTypeEnum, ContractIdRequest, SalesContractService, ShippingTermsDropDownDto, ShippingTermsService } from '@exportx/shared-models-and-services';
import { Form, Input, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../common';

const { Option } = Select;
export const SalesGridForm = (props) => { 
    const { formatMessage: fm } = useIntl();
    const [qualityData, setQualityData] = useState({});
    const { authContext } = useAuthState();
    const { coalSalesData, form, currency, add, remove, selectedCurrency, exchangeRate } = props;
    const [customerDropData, setCustomerDropData] = useState<any[]>([]);
    const [saleContractsDropData, setSaleContractsDropData] = useState<any[]>([]);
    const [shippingTerms, setShippingTerms] = useState<ShippingTermsDropDownDto[]>([]);

    const salesContractService = new SalesContractService();
    const shippingTermsService = new ShippingTermsService();

    useEffect(() => {
        const getCustomerDropData = () => {
            const bpService = new BusinessPartnerService();
            const req = new BpCategoryGetDto(BusinessPartnerTypeEnum.CUSTOMER)
            bpService.getAllBusinessPartnersForDropDown(req).then((res) => {
                if (res.status) {
                    setCustomerDropData(res.data)
                } else {
                    setCustomerDropData([])
                }
            }).catch((err) => {
                setCustomerDropData([])
            })
        }
        getCustomerDropData();
        getSaleContractsDropData();
        getAllShippingTerms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     let fieldName = 0;
    //     for (const coalSales of coalSalesData) {
    //         const buyer = form.getFieldValue(['coalSalesData', fieldName, 'buyer']);
    //         const salesContract = form.getFieldValue(['coalSalesData', fieldName, 'salesContract'])
    //         getSaleContractsDropData(buyer);
    //         getQualityDetailsByPcId(salesContract ,authContext.defaultPlant)
    //         fieldName += 1;
    //     }
    // }, [coalSalesData])



    const getAllShippingTerms = () => {
        shippingTermsService.getAllShippingTermsDropDown().then(res => {
            if (res.status) {
                setShippingTerms(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }

    const getSaleContractsDropData = (bpId?: string) => {
        salesContractService.getAllSaleContractsForDropDown({ plantCode: authContext.defaultPlant, bpId }).then((res) => {
            if (res.status) {
                setSaleContractsDropData(preVQualityData => {
                    return {
                        ...preVQualityData,
                        [bpId]: res.data
                    }
                })
            } else {
                setSaleContractsDropData(preVQualityData => {
                    return {
                        ...preVQualityData,
                        [bpId]: []
                    }
                })
            }
        }).catch((err) => {
            setSaleContractsDropData(preVQualityData => {
                return {
                    ...preVQualityData,
                    [bpId]: []
                }
            })
        })
    }

    const getQualityDetailsByPcId = (contractId: string, plantCode: string) => {
        const req = { ...new ContractIdRequest(contractId), plantCode };
        salesContractService.getQualityDetailsByPcId(req).then(res => {
            if (res.status) {
                setQualityData(preVQualityData => {
                    return {
                        ...preVQualityData,
                        [contractId]: res.data
                    }
                })
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }



    const salesContractHandler = (contractId: string) => {
        getQualityDetailsByPcId(contractId, authContext.defaultPlant);
        
    }

    const buyerOnChangeHandler = (value: string) => {
        getSaleContractsDropData(value);
    }
    const isContractSelected = (name) => {
        const a = form.getFieldValue(['coalSalesData', name, 'salesContract']);
        return a ? true : false;
    }
    const getTotal = (name) => {
        const quantity = form.getFieldValue(['coalSalesData', name, 'quantity']) ? form.getFieldValue(['coalSalesData', name, 'quantity']) : 0;
        const pricePMT = form.getFieldValue(['coalSalesData', name, 'pricePMT']) ? form.getFieldValue(['coalSalesData', name, 'pricePMT']) : 0;
        const presentCurrency = form.getFieldValue(['coalSalesData', name, 'currency']) ? form.getFieldValue(['coalSalesData', name, 'currency']) : 0;
        const total = quantity * pricePMT;
        form.setFieldValue(['coalSalesData', name, 'total'], total);
        return total;
    }
    return (
        <>
            {
                React.Children.toArray(coalSalesData.map((field, index) => (
                    <tr>
                        <td className='table-form'>
                            <Form.Item
                                key={field.key}
                                name={[field.name, "buyer"]} rules={[{ required: true, message: "Select Buyer" }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={buyerOnChangeHandler}
                                >
                                    {customerDropData?.map((el) => {
                                        return <Option value={el.bpId} >{el.bpName}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item
                                key={field.key}
                                name={[field.name, "salesContract"]} rules={[{ required: true, message: "Select SalesContract" }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={salesContractHandler}
                                >
                                    {saleContractsDropData[form.getFieldValue(['coalSalesData', field.name, 'buyer'])]?.map((el) => {
                                        return <Option value={el.pcId}>{el.contractAndSupplier}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            {
                                isContractSelected(field.name) ? <Form.Item

                                    name={[field.name, "qualityId"]} rules={[{ required: true }]}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                   
                                    >
                                        {qualityData[form.getFieldValue(['coalSalesData', field.name, 'salesContract'])]?.map((el) => {
                                            return <Option value={el.qualityId}>{el.quality}</Option>
                                        })
                                        }
                                    </Select>
                                </Form.Item> : <Form.Item rules={[{ required: true, message: "Enter Quality" }]}
                                    name={[field.name, "qualityInput"]}><Input type='number' />
                                </Form.Item>
                            }

                        </td>
                        <td className='table-form'>
                            {
                                isContractSelected(field.name) ? <Form.Item

                                    name={[field.name, "salesTypeId"]} rules={[{ required: true }]}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                      
                                    >
                                        {qualityData[form.getFieldValue(['coalSalesData', field.name, 'salesContract'])]?.filter(rec => rec.qualityId === form.getFieldValue(['coalSalesData', field.name, 'qualityId']))[0]?.purchaseTypeData?.map((el) => {
                                            return <Option value={el.typeId}>{el.purchaseType}</Option>
                                        })
                                        }
                                    </Select>
                                </Form.Item> : <Form.Item rules={[{ required: true, message: "Select SalesType" }]}
                                    name={[field.name, "salesTypeInput"]}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {shippingTerms?.map((el) => {
                                            return <Option value={el.shiptermId}>{el.shipmenttermName}</Option>
                                        })
                                        }
                                    </Select>
                                </Form.Item>
                            }
                        </td>
                        <td> Per MT</td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Enter Quantity" }]}
                                name={[field.name, "quantity"]}><Input type='number'  />
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Enter PricePMT" }]}
                                name={[field.name, "pricePMT"]}><Input type='number' />
                            </Form.Item>
                        </td>

                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Select Currency" }]} name={[field.name, "currency"]} style={{ width: "80%" }}>
                                <Select
                                    className="w-100"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {currency?.map((c) => {
                                        return <Option value={c.currencyCode}>{c.currencyName}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='text-right'>
                            {getTotal(field.name)}
                            <Form.Item name={[field.name, "total"]} style={{ display: 'none' }}>
                                <Input hidden />
                            </Form.Item>
                        </td>
                        <td className='text-center'>
                            {(coalSalesData.length - 1 === index) && <span>
                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                    <PlusCircleOutlined style={{ fontSize: '21px' }} onClick={() => add()} />
                                </Tooltip>
                            </span>}
                            {index !== 0 && <span>
                                <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                    <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                                        remove(field.name);
                                    }} />
                                </Tooltip>
                            </span>}
                        </td>
                    </tr>
                )))
            }
            <tr>
                <td colSpan={5}></td>
                <td className='text-right' style={{ fontWeight: 'bold' }}>{form.getFieldValue(['coalSalesData'])?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)}</td>
                <td colSpan={2}></td>
                <td className='text-right' style={{ fontWeight: 'bold' }} >{form.getFieldValue(['coalSalesData'])?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)}</td>
                <td></td>
            </tr>
        </>
    )
}

export default SalesGridForm