import { CurrencyConverterCreateDto, CurrencyConverterService, CurrencyService } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';
const CurrencyF = () => {
    let pathToreDirect = '/currency-conversion'


    const [currency, setCurrency] = useState<any[]>([]);
    const [filterState, setFilterState] = useState<{}>({})
    const [formRef] = Form.useForm()
    const { Option } = Select
    const { state }: any = useLocation()
    const currencyConverterService = new CurrencyConverterService()
    const { authContext } = useAuthState()
    const { isUpdate, record, hidden } = state ? state : { isUpdate: false, record: null, hidden: null }
    const isNewRecord = !record;

    useEffect(()=>{
        const accessId = {
            create: [34],
            view: [35],
            edit: [36]
        }

        const hasPermission = isNewRecord ? accessId.create : isUpdate ? accessId.edit : accessId.view

        if(!isPermissionExist(hasPermission)){
            return navigate('/')
        }
    },[])

    useEffect(() => {
        getCurrencyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    if (record) {
        record.fromDate = moment(record?.fromDate)
        record.exchangeDate = moment(record?.exchangeDate)
    }

    const navigate = useNavigate();
    const currencyService = new CurrencyService();
    const getCurrencyData = () => {

        currencyService.getAllCurrenciesForDropDown().then(res => {
            if (res.status) {
                setCurrency(res.data);
            }
        }).catch(err => {
            console.log('error:' + err.message);
        });
    }

    const currencyFilter = (key, label) => {
        setFilterState(prev => {
            return {
                ...prev,
                [label]: key
            }
        })
    }
    const currencyConverterCreation = () => {
        formRef.validateFields().then(values => {
            const req = new CurrencyConverterCreateDto(values.baseCurrency,
                values.quoteCurrency,
                values.exchangeRate,
                moment(values.exchangeDate).format('YYYY-MM-DD'),
                values.toDate,
                authContext.user.userName,
                record?.versionFlag,
                record?.currencyConverterId,
            )
            currencyConverterService.currencyConverterCreation(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    setTimeout(() => {
                        navigate('/currency-conversion')
                    },
                        1000)
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage)
                }
            })
        }).catch(err => {
            console.log(err.message)
        })
    }

    return (
        <div>
            <Card title={<span> Currency Convertor</span>}
                className='default-card-class'
                extra={<Button onClick={() => navigate(pathToreDirect)}>View</Button>}>


                <Form layout="vertical" autoComplete="off" form={formRef} initialValues={record}>
                    <Row>

                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
                            <Form.Item label="From Currency" rules={[{ required: true, message: 'From Currency Required' }]}
                                name="baseCurrency" style={{ width: '80%' }}>
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Currency'
                                    onChange={(key, value) => { currencyFilter(key, 'from') }}
                                >
                                    {currency.filter(filter => { return filter.currencyCode !== filterState['to'] }).map(C => {
                                        return <Select.Option value={C.currencyCode} key={C.currencyCode}>{C.currencyName}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
                            <Form.Item label="TO Currency" rules={[{ required: true, message: 'To Currency Required' }]}
                                name="quoteCurrency" style={{ width: '80%' }}>
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Currency'
                                    onChange={(key, value) => { currencyFilter(key, 'to') }}

                                >
                                    {currency.filter(filter => { return filter.currencyCode !== filterState['from'] }).map(C => {
                                        return <Select.Option value={C.currencyCode} key={C.currencyCode}>{C.currencyName}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
                            <Form.Item
                                name="exchangeDate"
                                label='Date'
                                rules={[{ required: true, message: 'Date Required' }]}

                            >
                                <DatePicker
                                    // disabledDate={getDisabledDates}

                                    style={{ width: "225px" }} format="YYYY-MM-DD" />
                            </Form.Item>

                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
                            <Form.Item label="Rate"
                                name="exchangeRate" style={{ width: '80%' }}
                                rules={[{ required: true, message: 'To Rate Required' }]}
                            >

                                <Input placeholder='To Rate'></Input>
                            </Form.Item>

                        </Col>
                    </Row>

                    <Row>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1} className="gutter-row" >
                            {/* <Form.Item label="From Rate"
                                name="fromRate" style={{ width: '80%' }}
                                rules={[{ required: true, message: 'From Rate Required' }]}
                            >

                                <Input placeholder='From Rate'></Input>
                            </Form.Item> */}

                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1} className="gutter-row" >
                            <Form.Item
                                name="toDate"
                                label='To Date'
                                rules={[{ required: true, message: 'To Date Required' }]}
                                style={{ display: 'none' }}
                                initialValue={moment()}
                            >
                                <DatePicker
                                    // disabledDate={(current) => {
                                    //     let fromDate = moment(formRef.getFieldValue("fromDate")).format("YYYY-MM-DD");
                                    //     return current && current < moment(fromDate, "YYYY-MM-DD");
                                    // }}
                                    style={{ width: "225px" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <div style={{ paddingTop: '10px' }}>
                    <Button
                        style={{ float: 'right', display: hidden ? 'none' : 'block' }}
                        onClick={() => { currencyConverterCreation() }} type='primary'>Save</Button>
                </div>
            </Card >

        </div >
    )
}

export default CurrencyF;