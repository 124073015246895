import { AgingReportReqDto, ContractModesEnum, SamplingAndAnalysisService } from "@exportx/shared-models-and-services";
import { Button, Card, Form, Input, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../../common";

interface IProps {
  contractType: ContractModesEnum
}

export const AgingReportTab = (props: IProps) => {
  const { contractType } = props;
  const { authContext } = useAuthState();
  const [delayedSamplingData, setDelayedSamplingData] = useState([]);
  const [filteredDelayedSamplingData, setFilteredDelayedSamplingData] = useState([]);
  const [loadMore, setLoadMore] = useState(5);

  const qualityService = new SamplingAndAnalysisService();
  useEffect(() => {
    getDelayedSamplingReportData(loadMore);
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [loadMore])

  const getDelayedSamplingReportData = (loadMore: number) => {
    const req = new AgingReportReqDto(loadMore, authContext.defaultPlant, contractType);
    qualityService.getDelayedSamplingReportData(req).then(res => {
      if (res.status) {
        setDelayedSamplingData(res.data);
        setFilteredDelayedSamplingData(res.data)
      } else {
        setDelayedSamplingData([]);
        setFilteredDelayedSamplingData([]);
      }
    }).catch(err => {
      setDelayedSamplingData([]);
      setFilteredDelayedSamplingData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const tableColumns: any = [

    {
      title: 'Barge Id',
      dataIndex: 'bargeDisplayId',
    },

    {
      title: 'Barge Name',
      dataIndex: 'bargeName',
    },

    {
      title: 'Business Number',
      dataIndex: 'businessNo',
    },

    {
      title: 'Purchase Contract',
      dataIndex: 'purchaseContractNo',
    },

    {
      title: 'Purchasing Term',
      dataIndex: 'purchasingTerm',
    },

    {
      title: 'Supplier',
      dataIndex: 'supplierName',
    },

    {
      title: 'Method',
      dataIndex: '',
    },

    {
      title: 'Inco Terms',
      dataIndex: 'incoTerms',
    },

    {
      title: 'Mine Name',
      dataIndex: 'mineName',
    },

    {
      title: 'No Of Days',
      dataIndex: 'noOfDays',
    }
  ]



  return (
    <Card>
      <Form layout="vertical">
        <Form.Item
          label="Purchase Terms"
          name="" >
          <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 200, float: 'left', paddingBlockEnd: '10px' }}>
          </Select>
        </Form.Item>
      </Form>
      <Button type='primary' style={{ float: 'left' }} onClick={() => {
        setLoadMore(prevState => prevState + 10);
      }}>Load More</Button>
      <Input.Search placeholder="Search" style={{ width: 200, float: "right" }} /><br /><br />
      <Table className='contracts' columns={tableColumns} dataSource={filteredDelayedSamplingData}></Table>
    </Card>

  )

}
export default AgingReportTab;