import { FormInstance, Rule } from 'antd/lib/form';
import { Col, Form, Row, Input, InputNumber, Select, Drawer, Button, Space } from 'antd';
import DatePicker from '../../common/data-picker/date-picker'
import { useIntl } from 'react-intl';
import React from 'react';

const { Option } = Select;
const { TextArea } = Input;
export enum InputTypes {
    Select = "Select",
    InputNumber = "InputNumber",
    Switch = "Switch",
    Radio = "Radio",
    Slider = "Slider",
    Button = "Button",
    Upload = "Upload",
    Rate = "Rate",
    Checkbox = "Checkbox",
    Input = "Input",
    TextArea = "TextArea",
    DatePicker = "DatePicker"
}

export interface IPropOptions {
    value: string;
    label: string;

}

export interface IField {
    type: InputTypes,
    placeHolder?: string;
    options?: IPropOptions[];
    otherProps?: any
    callBack?: (data: any) => void;

}


export interface IPropsFormItem {
    label: string;
    name: string;
    validationRules?: Rule[],
    fieldType: IField,
}

export interface IPropsGenericForm {
    formItems: IPropsFormItem[];
    formRef: FormInstance<any>;
    initialValues: any;
    title: string;
    visible: boolean;
    isUpdate: boolean;
    isNewRecord: boolean;
    closeForm: () => void;
    submitForm: () => void;
    clearData: () => void;
}

const getInputField = (fieldType: IField) => {
    if (fieldType.type === InputTypes.Input) {
        return <Input placeholder={fieldType.placeHolder} autoComplete='off' {...fieldType.otherProps} />
    } else if (fieldType.type === InputTypes.InputNumber) {
        return <InputNumber min={0} style={{ width: '100%' }} {...fieldType.otherProps} />
    } else if (fieldType.type === InputTypes.Select) {
        return <Select
            {...fieldType.otherProps}
            placeholder={fieldType.placeHolder}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
            onChange={fieldType?.callBack ? (value) => fieldType.callBack(value) : () => { }}
        >
            {React.Children.toArray([...fieldType?.options].map(item => {
                return <Option value={item.value}>{item.label}</Option>
            }))}
        </Select>
    } else if (fieldType.type === InputTypes.TextArea) {
        return <TextArea aria-multiline {...fieldType.otherProps} />
    } else if (fieldType.type === InputTypes.DatePicker) {
        return <DatePicker style={{ width: '100%' }} {...fieldType.otherProps} />
    }
}

export const GenericForm = (props: IPropsGenericForm) => {
    const { title, formRef, initialValues, formItems, visible, closeForm, submitForm, isUpdate, isNewRecord, clearData } = props;
    const { formatMessage: fm } = useIntl();


    let viewPortWidth = window.innerWidth;
    return (
        <Drawer
            title={title}
            width={viewPortWidth > 768 ? '50%' : '85%'}
            onClose={closeForm}
            open={visible}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                (isNewRecord || isUpdate) && <Space>
                    <Button
                        onClick={() => { clearData(); }}
                    >
                        {fm({ id: "common.clear", defaultMessage: "Clear" })}
                    </Button>
                    <Button onClick={submitForm} type="primary">
                        {fm({ id: "common.save", defaultMessage: "Save" })}
                    </Button>
                </Space>
            }
        >
            <Form layout="vertical" form={formRef} initialValues={initialValues} autoComplete="off" >
                {formItems.map((formItem, index) => {
                    if (index % 2 === 0) {
                        return (
                            <>
                                <Col
                                    xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }} xl={{ span: 11 }}
                                >
                                    <Form.Item
                                        label={formItem.label}
                                        name={formItem.name}
                                        rules={formItem.validationRules}
                                    >
                                        {
                                            getInputField(formItem.fieldType)
                                        }
                                    </Form.Item>
                                </Col>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <Col
                                    xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} xl={{ span: 11, offset: 2 }}
                                >
                                    <Form.Item
                                        label={formItem.label}
                                        name={formItem.name}
                                        rules={formItem.validationRules}
                                    >
                                        {
                                            getInputField(formItem.fieldType)
                                        }
                                    </Form.Item>
                                </Col>
                            </>
                        );
                    }
                })
                    .reduce((r, element, index2) => {
                        index2 % 2 === 0 && r.push([]);
                        r[r.length - 1].push(element);
                        return r;
                    }, [])
                    .map((rowContent) => {
                        return <Row>{rowContent}</Row>;
                    })}
            </Form></Drawer>
    )
}

export default GenericForm;