import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const UserSelection = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    users: [],
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          users: features?.filter((cou) => 832 <= +cou && +cou <= 835)?.map(Number),
        };
      });
    }
  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({ ...prev, [name]: value }))
    }
    else setValues((prev) => ({ ...prev, [name]: [] }));
  }

  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}
    >
      <Card size="small" title="User"
        extra={<Checkbox
          name="Countries"
          indeterminate={values.users.length > 0 && values.users.length < 4}
          checked={values.users.length === 4}
          onChange={(e) => onChange(e, 'users', [832, 833, 834, 835])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="users" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, users: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={832} >Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={833} >View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={834} >Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={835} >Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>

    </Form>
  )
}