import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table } from "antd";
import moment from "moment";
import { FileExcelFilled } from '@ant-design/icons';
import { AccountsService, LedgerReportRequest, PaymentVoucherService } from "@exportx/shared-models-and-services";
import React, { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../common";
import { useIntl } from "react-intl";


const { Option } = Select;
export const Ledger = () => {
  const { formatMessage: fm } = useIntl();
  const [ledgers, setLedgers] = useState([]);
  const [ledgerData, setLedgerData] = useState([]);
  const [form] = Form.useForm();
  const { authContext } = useAuthState();
  const [searchData,setSearchData]=useState("")

  useEffect(() => {
    const service = new AccountsService();
    const getAllAccounts = () => {
      service.getAllAccounts().then(res => {
        if (res.status) {
          setLedgers(res.data)
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
    getAllAccounts();
  }, []);


  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      filteredValue:[String(searchData).toLowerCase()],
      onFilter:(value,record)=>{
        return(
          String(record.date)
          .toLowerCase()
          .includes(value.toLocaleString()) ||
      String(record.ledgerName)
          .toLowerCase()
          .includes(value.toLocaleString()) ||
          String(record.voucherType)
          .toLowerCase()
          .includes(value.toLocaleString()) ||
          String(record.voucherSLNo)
          .toLowerCase()
          .includes(value.toLocaleString()) ||
          String(record.voucherClientID)
          .toLowerCase()
          .includes(value.toLocaleString()) ||
          String(record.debit)
          .toLowerCase()
          .includes(value.toLocaleString()) ||
          String(record.credit)
          .toLowerCase()
          .includes(value.toLocaleString()) ||
          String(record.balance)
          .toLowerCase()
          .includes(value.toLocaleString()) ||
          String(record.balanceType)
          .toLowerCase()
          .includes(value.toLocaleString()) ||
         
      String(record.status)
          .toLowerCase()
          .includes(value.toLocaleString())
        )

      },

      render: (val, record) => {
        if (record?.ledgerName != 'Total')
          return moment(val).format('DD-MMM-YYYY');
      }
    },
    {
      title: 'Particulars',
      dataIndex: 'ledgerName',
      width: '220px'
    },
    {
      title: 'Vc Type',
      dataIndex: 'voucherType',
      width: '220px'
    },
    {
      title: 'ID',
      dataIndex: 'voucherSLNo',
      width: '100px'
    },
    {
      title: 'VoucherNo',
      dataIndex: 'voucherClientID',
      width: '100px'
    },

    {
      title: 'Debit',
      dataIndex: 'debit',
      width: '100px',
      align: 'right' as 'right',
      render: (value, record) => {
        return <>{value ? value : "0"}</>
      }
    },

    {
      title: 'Credit',
      dataIndex: 'credit',
      width: '100px',
      align: 'right' as 'right',
      render: (value, record) => {
        return <>{value ? value : "0"}</>
      }
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      width: '100px',
      align: 'right' as 'right',
      render: (value, record) => {
        return <>{value ? value : "0"}</>
      }
    },
    {
      title: '',
      dataIndex: 'balanceType',
      render: (value, record) => {
        return <>{`(${value})`}</>
      }
    },
  ];

  const getLedgerData = () => {
    form.validateFields().then(values => {
      const request = new LedgerReportRequest(values.fromDate, values.toDate, values.ledgerAccountId, authContext.defaultPlant);
      const service = new PaymentVoucherService();
      service.getLedgerData(request).then(res => {
        if (res.status) {
          setLedgerData(res.data);
        } else {
          setLedgerData([]);
        }
      });
    });
  }

  return (
    <div>
      <Card title={<span>{'Account Ledger'}</span>}>
        <Card>
          <>
            <Form autoComplete="off" form={form}>
              <Row>
                <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1} >
                  <Form.Item label="Select Ledger"
                    name="ledgerAccountId" style={{ width: '100%' }} required>
                    <Select  filterOption={(input, option) => (option!.children as unknown as string)?.toString()?.toLocaleLowerCase()?.includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Ledger'>
                      {React.Children.toArray(ledgers.map(item => {
                        return <Option value={item?.actLedId}>{item?.actLedName}</Option>
                      }))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1} ></Col>
              </Row>
              <Row justify='space-around'>
                <Col>
                  <Form.Item label="From Date"
                    name="fromDate" required>
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="To Date"
                    name="toDate" required><DatePicker />
                  </Form.Item>
                </Col>
                <Col><Button type="primary" onClick={getLedgerData}>Display</Button></Col>
              </Row>
            </Form>
          </>
          <Table dataSource={ledgerData} columns={columns} pagination={false} bordered title={() => <Row justify='end'><Col></Col><Col>
          <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />
            <Button>Print</Button>
            <Button icon={<FileExcelFilled />} >Export</Button>
            
          </Col></Row>} />
        </Card>
      </Card >
    </div>
  )

}
export default Ledger;