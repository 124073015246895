import { Card, Col, Form, FormInstance, Row, Select } from 'antd'
import { BpCategoryGetDto, BPDropDownDto, BusinessPartnerService, BusinessPartnerTypeEnum, SurveyorTypesEnum,  } from '@exportx/shared-models-and-services';
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
const { Option } = Select;

interface ISurveyorDetailsProps {
  formRef: FormInstance<any>
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
  setSurveyorDropData: (value) => void
}

export const SurveyorDetails = (props: ISurveyorDetailsProps) => {
  const [surveyorDropDownData, setSurveyorDropDownData] = useState<BPDropDownDto[]>([]);
  const { formRef, initialValues, layoutType } = props
  const { formatMessage: fm } = useIntl();

  const businessPartnerService = new BusinessPartnerService();

  useEffect(() => {
    getAllSurveyorDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(()=>{
    if(initialValues){
      formRef.setFieldValue('witnessAnalysisType',initialValues?.jointSurveyorAnalysisType)
      formRef.setFieldValue('witnessSurveyor',initialValues?.witnessSurveyorName);
    }
  }, [initialValues])

  const getAllSurveyorDropDown = () => {
    businessPartnerService.getAllSurveyorDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)).then(res => {
      if (res.status) {
        setSurveyorDropDownData(res.data);
        props.setSurveyorDropData(res.data)
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }



  return (
    <Card title="Surveyor">
      <Form initialValues={initialValues}
        form={formRef}
        layout={layoutType}
        autoComplete="off">
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 4 }}>
            <Form.Item label={fm({ id: "mdm.components.surveyordetails.supplierSurveyor", defaultMessage: 'Supplier Surveyor' })}
              name="supplierSurveyor">

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `mdm.components.surveyordetails.reqSupplierSurveyor`, defaultMessage: `Search Surveyor` })}  >
                <Option value={''}>Please Select</Option>
                {surveyorDropDownData.map(item => {
                  return <Option value={item.bpId}>{item.bpName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label={'Analysis Type'}
              name="supplierSurveyorAnalysisType">
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `mdm.components.surveyordetails.reqsupplierSurveyorAnalysisType`, defaultMessage: `Please Select` })}>
                {Object.keys(SurveyorTypesEnum).map(specTypeKey => {
                  return <Option value={SurveyorTypesEnum[specTypeKey]}>{SurveyorTypesEnum[specTypeKey]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 4 }}>
            <Form.Item label={fm({ id: "mdm.components.surveyordetails.srplSurveyor", defaultMessage: 'SRPL Surveyor' })}
              name="internalSurveyor">

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `mdm.components.surveyordetails.reqSrplSurveyor`, defaultMessage: `Search Surveyor` })} >
                <Option value={''}>Please Select</Option>
                {surveyorDropDownData.map(item => {
                  return <Option value={item.bpId}>{item.bpName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label={fm({ id: "mdm.components.surveyordetails.surveyorAnalysisType", defaultMessage: 'Analysis Type' })}
              name="internalSurveyorAnalysisType">
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `mdm.components.surveyordetails.reqSurveyor`, defaultMessage: `Please Select` })}>
                {Object.keys(SurveyorTypesEnum).map(specTypeKey => {
                  return <Option value={SurveyorTypesEnum[specTypeKey]}>{SurveyorTypesEnum[specTypeKey]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 4 }}>
            <Form.Item label={fm({ id: "mdm.components.surveyordetails.witnessSurveyor", defaultMessage: 'Witness Surveyor' })}
              name="witnessSurveyor">
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `mdm.components.surveyordetails.req`, defaultMessage: `Search Surveyor` })} >
                <Option value={''}>Please Select</Option>
                {surveyorDropDownData.map(item => {
                  return <Option value={item.bpId}>{item.bpName}</Option>
                })}
              </Select>


            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label={fm({ id: "mdm.components.surveyordetails.witnessAnalysisType", defaultMessage: 'Analysis Type' })}
              name="witnessAnalysisType">
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `SurveyorTypesEnum.components.surveyordetails.reqWitness`, defaultMessage: `Please Select` })}>
                {Object.keys(SurveyorTypesEnum).map(specTypeKey => {
                  return <Option value={SurveyorTypesEnum[specTypeKey]}>{SurveyorTypesEnum[specTypeKey]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }} >

          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 4 }}>
            <Form.Item label={fm({ id: "mdm.components.surveyordetails.additionalSurveyor", defaultMessage: 'Additional Surveyor' })}
              name="additionalSurveyor">
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `mdm.components.surveyordetails.req`, defaultMessage: `Search Surveyor` })} >
                <Option value={''}>Please Select</Option>
                {surveyorDropDownData.map(item => {
                  return <Option value={item.bpId}>{item.bpName}</Option>
                })}
              </Select>


            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label={fm({ id: "mdm.components.surveyordetails.additionalAnalysisType", defaultMessage: 'AnalysisType' })}
              name="additionalAnalysisType">
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `mdm.components.surveyordetails.reqWitness`, defaultMessage: `Please Select` })}>
                {Object.keys(SurveyorTypesEnum).map(specTypeKey => {
                  return <Option value={SurveyorTypesEnum[specTypeKey]}>{SurveyorTypesEnum[specTypeKey]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }} >

          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 4 }}>
            <Form.Item label={fm({ id: "mdm.components.surveyordetails.additionalSurveyor1", defaultMessage: 'Additional Surveyor1' })}
              name="additionalSurveyor1">
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `mdm.components.surveyordetails.req`, defaultMessage: `Search Surveyor` })} >
                <Option value={''}>Please Select</Option>
                {surveyorDropDownData.map(item => {
                  return <Option value={item.bpId}>{item.bpName}</Option>
                })}
              </Select>


            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label={fm({ id: "mdm.components.surveyordetails.additional1AnalysisType", defaultMessage: 'Analysis Type' })}
              name="additionalAnalysisType1">
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                placeholder={fm({ id: `mdm.components.surveyordetails.reqWitness`, defaultMessage: `Please Select` })}>
                {Object.keys(SurveyorTypesEnum).map(specTypeKey => {
                  return <Option value={SurveyorTypesEnum[specTypeKey]}>{SurveyorTypesEnum[specTypeKey]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          </Col>

        </Row>

      </Form>
      {props?.children}
    </Card>

  )
}
export default SurveyorDetails