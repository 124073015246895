import React, { PropsWithChildren, createContext, useMemo, useReducer } from 'react';
import { authReducer } from './reducers';

export type InitialStateType = {
    clientId: string;
    authServerUrl: string;
    isAuthenticated: boolean,
    user: any,
    defaultPlant: string,
    defaultPlantCurrency: string,
    token: any,
    menuAccessObject: any,
    defaultPlantName: string,
    mapLoginPlantCode: string
}

export const initialAuthState: InitialStateType = {
    clientId: '',
    authServerUrl: '',
    isAuthenticated: false,
    user: {
        userName: 'Harish Anandhavarapu'
    },
    defaultPlant: 'BKM',
    defaultPlantCurrency: 'IDR',
    defaultPlantName: "BKM",
    token: null,
    menuAccessObject: [],
    mapLoginPlantCode: ''
}

interface IAMClientAuthContextType {
    authContext: InitialStateType;
    dispatch: React.Dispatch<any>;
}
interface AuthContextProps {
    children: React.ReactNode;
}

const AuthContext = createContext<IAMClientAuthContextType>({
    authContext: initialAuthState,
    dispatch: () => null
});

interface AuthContextProps {
    children: React.ReactNode;
    authServerUrl: string;
    clientId: string;
    mapLoginPlantCode: string;
}

const IAMClientProvider: React.FC<PropsWithChildren<AuthContextProps>> = ({
    clientId,
    authServerUrl,
    children,
    mapLoginPlantCode
}: {
    children: React.ReactNode;
    authServerUrl: string;
    clientId: string;
    mapLoginPlantCode: string;

}) => {
    const existing = JSON.parse(localStorage.getItem('currentUser')) || {}
    const [authContext, dispatch] = useReducer(authReducer, { ...initialAuthState, ...existing, clientId, authServerUrl, mapLoginPlantCode });

    const value = useMemo(() => ({ authContext, dispatch }), [authContext]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthState = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuthState must be used within a IAMClientProvider");
    }
    return context;
}

export { IAMClientProvider, AuthContext, useAuthState };
