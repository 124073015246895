import React, { useEffect, useState } from 'react'
import { DownloadOutlined, EditFilled } from '@ant-design/icons';
import { BACGetDataDto, ContractStatusEnum, MarkupPriceApproveReqDto, MarkupPriceService, PlantCodeReqDto } from '@exportx/shared-models-and-services';
import { Button, Card, Divider, Input, Table, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import Link from 'antd/lib/typography/Link';
import { StatusTag, useAuthState, AlertMessages } from '../../../../common';

export const MarkUpGrid = () => {


  const { Search } = Input;
  const navigate = useNavigate();
  const mupService = new MarkupPriceService();
  const [gridData, setGridData] = useState<BACGetDataDto[]>([])
  const { authContext } = useAuthState()

  useEffect(() => {
    getMarkupPriceGridData()
  }, [])

  const getMarkupPriceGridData = () => {
    const req = new PlantCodeReqDto(authContext.defaultPlant);
    mupService.getMarkupPriceList(req).then(res => {
      if (res.status) {
        setGridData(res.data);
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const approveOrRejectHandler = (markupPriceId: string, status: ContractStatusEnum) => {
    const req = new MarkupPriceApproveReqDto(markupPriceId, authContext.defaultPlant, status, authContext.user.userName)
    mupService.approveMarkupPrice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getMarkupPriceGridData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const editClickHandler = (baId: string) => {
    navigate('/markup-price-form', { state: { id: baId } })
  }



  const Columns: any = [

    {
      title: 'Feature No',
      dataIndex: 'featureNo',
      render: (value, record) => {
        return <> <Link onClick={() => navigate('/markup-price-detailed-view', { state: { id: record.markupPriceId } })}>{value}</Link> </>
      }
    },
    {
      title: 'Business Number',
      dataIndex: 'businessNo',
    },
    {
      title: 'Sales Contract No',
      dataIndex: 'saleContractNo',
    },
    {
      title: 'Exchange Rate',
      dataIndex: 'exchangeRate',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => {
        return <> <StatusTag status={value} /></>
      },
    },
    {
      title: 'Action',
      className: 'operations',
      width: 130,
      align: 'Center',

      render: (text, rowData) => (
        <span>
          <EditFilled
            onClick={() => {
              editClickHandler(rowData.markupPriceId);
            }}
            style={{ color: '#1890ff', fontSize: '16px', display: `${rowData.status !== ContractStatusEnum.ACTIVE ? "" : 'none'}` }}
          />
          <Divider type="vertical"></Divider>&nbsp;&nbsp;
          {rowData.status === ContractStatusEnum.DRAFT && <Button type="primary" size="small" onClick={() => approveOrRejectHandler(rowData.markupPriceId, ContractStatusEnum.PENDING_APPROVAL)}>Send For Approval</Button>}
        </span>)
    }

  ]


  const onSearch = () => {

  };
  return (
    <div>
      <Card
        title={<span style={{ color: 'black' }}>Mark Up Price</span>}
        style={{ textAlign: 'left' }} extra={<span style={{ color: 'white' }} ><Button type="primary" onClick={() => navigate("/markup-price-form")}>New Markup Price</Button> </span>}>
        <Tooltip placement="bottom" title='Download' color='#11ad9b'>
          <Button shape="circle" icon={<DownloadOutlined />} style={{ float: 'right' }} />
        </Tooltip>
        <Search placeholder=" Search " allowClear onSearch={onSearch} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
        <Table bordered columns={Columns} dataSource={gridData} size='small' />
      </Card>

    </div>
  )
}

export default MarkUpGrid; 