import { UploadOutlined } from '@ant-design/icons';
import { CompanyCodeReq, CompanyTdsService, ContractModesEnum, ContractStatusEnum, TaxDropDownDto, TaxesService, TdsResModel } from '@exportx/shared-models-and-services';
import { ClassValidator, SequenceUtils, getLocalFormat, getNumberFromLocalString, internationalFormattedValue } from '@exportx/ui-utils';
import { Button, Col, Descriptions, Form, FormInstance, InputNumber, Row, Select, Typography, Upload } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../common';

interface IProps {
  selectedRowsData: any[];
  formRef: FormInstance<any>;
  costingDate?: string;
  contractType: ContractModesEnum;
  costingsSave?: (values: any) => void;
  filterData?: any;
  updateData?: any;
  initialValues?: any;
  setInitialValues?: React.Dispatch<any>
}
const { Option } = Select;
const { Text } = Typography;
export const PriceAdjustment = (props: IProps) => {
  const { selectedRowsData, formRef, contractType, costingsSave, updateData, initialValues, setInitialValues } = props;
  const classValidator = new ClassValidator();
  const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
  const companyTdsService = new CompanyTdsService();
  const taxesService = new TaxesService();
  const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([])
  const { authContext } = useAuthState();
  const [fileList, setFileList] = useState([]);
  const [counter, setCounter] = useState<number>(0);
  const currency = authContext.defaultPlantCurrency;
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count !== 0) {
      if (updateData) {
        formRef.setFieldsValue({ remarks: updateData.remarks, tax1Id: updateData.taxId1, tax2Id: updateData.taxId2, tdsId: updateData.tdsId });
        const remaining = updateData?.advanceTarnsactionsData?.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0)
        const v = Number(updateData?.costingsTotal) - remaining;
        console.log(Number(updateData?.costingsTotal) - remaining, updateData)
        formRef.setFieldValue('remainingBalance', Number(Number(v).toFixed(2)).toLocaleString())
        onTaxChange1(updateData.taxId1)
        onTaxChange2(updateData.taxId2)
        tdsOnchange(updateData.tdsId);
        setCounter(counter + 1);

      }
    }


  }, [updateData, taxDropDown, count]);














  useEffect(() => {
    if (initialValues) {
      formRef.setFieldValue('costingInvoiceTotal', Number(Number(initialValues?.totalAmount).toFixed(2)).toLocaleString())
      formRef.setFieldValue('remainingBalance', Number(Number(initialValues?.totalAmount).toFixed(2)).toLocaleString())
    };

  }, [initialValues])

  // console.log(initialValues, "....................", updateData)
  useEffect(() => {
    const barges = [];
    const coalSuppliers = [];
    let totalQuantity = 0;
    let coalBasePrice = 0;
    let totalAmount = 0;
    const bargesSet = new Set();
    const coalSuppliersSet = new Set();
    selectedRowsData.forEach(element => {
      totalQuantity += element.quantityInMt;
      coalBasePrice += element.coalBasePrice * element.exchangeRate;
      totalAmount += element.total;
      if (element.incoterm === 'FOB BARGE') {
        if (!bargesSet.has(element.bargeId)) {
          barges.push({ bargeNo: element.bargeId, bargeNomination: element.bargeNomination });
          bargesSet.add(element.bargeId)
        }
      }
      if (!coalSuppliersSet.has(element)) {
        coalSuppliers.push({ coalSupplierId: element.coalSupplierId, coalSupplierName: element.coalSupplierName });
        coalSuppliersSet.add(element.coalSupplierId)
      }
    });
    setInitialValues(prev => {
      return {
        ...prev,
        barges,
        coalSuppliers,
        totalQuantity,
        coalBasePrice,
        costingsTotal: totalAmount,
        tax1Total: 0,
        tax2Total: 0,
        tdsTotal: 0,
        totalAmount,
        remarks: ''
      }
    });

    onTaxChange1(formRef.getFieldValue('tax1Id'))
    onTaxChange2(formRef.getFieldValue('tax2Id'))
    tdsOnchange(formRef.getFieldValue('tdsId'))
  }, [selectedRowsData])


  useEffect(() => {
    getTdsData();
    taxActiveDropDown();
    formRef.setFieldValue('baseCurrency', currency)
  }, []);




  const getTdsData = () => {
    const req = new CompanyCodeReq();
    req.company = authContext.defaultPlant;
    req.date = moment().format('YYYY-MM-DD');
    companyTdsService.getTdsByCompanyCode(req).then(res => {
      if (res.status) {
        setTdsModel(res.data);
      } else {
        setTdsModel([]);
      }
    }).catch(err => {
      console.log(err);
      setTdsModel([]);
    });
  }

  function tdsOnchange(tdsId) {
    const obj = tdsModel.filter(res => res.companyTdsId === tdsId)[0];
    if (tdsId) {
      setInitialValues(prevState => {
        const tdsPercentage = obj?.tdsValue ? obj?.tdsValue : 0;
        const tdsTotal = (tdsPercentage / 100) * prevState.costingsTotal;
        const tax1Total = prevState.tax1Total;
        const tax2Total = prevState.tax2Total;
        const withTax = (prevState.costingsTotal + tax1Total + tax2Total) - tdsTotal
        formRef.setFieldValue('costingInvoiceTotal', Number(withTax.toFixed(2)).toLocaleString());
        const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
        const toBeSupplier = withTax - totalAdjustedValue;
        formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());
        return {
          ...prevState,
          tdsId,
          tdsPercentage,
          tdsTotal,
          totalAmount: withTax
        }
      })
    } else {
      setInitialValues(prevState => {
        const tdsPercentage = 0;
        const totalAmount = (prevState.totalAmount + prevState.tdsTotal);
        return {
          ...prevState,
          tdsId,
          tdsPercentage,
          tdsTotal: 0,
          totalAmount
        }
      })
    }
  }

  const taxActiveDropDown = () => {
    taxesService.getActiveTaxDropDown().then(res => {
      if (res.status) {
        setTaxDropDown(res.data)
        setCount(prev => prev + 1);
      }
    }).catch(err => {
      console.log(err.message)
    })
  }
  function onTaxChange1(taxId) {
    console.log('onTaxChange1', taxId)
    const vat = taxDropDown.filter(res => res.taxId === taxId);
    if (taxId) {
      const tax1Id = vat[0]?.taxId ? vat[0]?.taxId : null;
      const tax1Percentage = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
      setInitialValues(prevState => {
        const tax1Total = (tax1Percentage / 100) * prevState.costingsTotal;
        const tax2Total = prevState.tax2Total;
        const tdsTotal = prevState.tdsTotal;
        const withTax = (prevState.costingsTotal + tax1Total + tax2Total) - tdsTotal
        formRef.setFieldValue('costingInvoiceTotal', Number(withTax.toFixed(2)).toLocaleString());
        const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
        const toBeSupplier = withTax - totalAdjustedValue;
        formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());

        return {
          ...prevState,
          tax1Id,
          tax1Percentage,
          tax1Total,
          totalAmount: withTax
        }
      })
    } else {
      const tax1Id = null;
      const tax1Percentage = 0;
      setInitialValues(prevState => {
        const totalAmount = (prevState.totalAmount - prevState.tax1Total);
        return {
          ...prevState,
          tax1Id,
          tax1Percentage,
          tax1Total: 0,
          totalAmount: totalAmount
        }
      })
    }
  }



  function onTaxChange2(taxId) {
    console.log('onTaxChange2', taxId)

    const vat = taxDropDown.filter(res => res.taxId === taxId);
    if (taxId) {
      const tax2Id = vat[0]?.taxId ? vat[0]?.taxId : null;
      const tax2Percentage = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
      setInitialValues(prevState => {
        const tax2Total = (tax2Percentage / 100) * prevState.costingsTotal;
        const tax1Total = prevState.tax1Total;
        const tdsTotal = prevState.tdsTotal;
        const withTax = (prevState.costingsTotal + tax1Total + tax2Total) - tdsTotal
        formRef.setFieldValue('costingInvoiceTotal', Number(withTax.toFixed(2)).toLocaleString());
        const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
        const toBeSupplier = withTax - totalAdjustedValue;
        formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());
        return {
          ...prevState,
          tax2Id,
          tax2Percentage,
          tax2Total,
          totalAmount: withTax
        }
      })
    } else {
      const tax2Id = null;
      const tax2Percentage = 0;
      setInitialValues(prevState => {
        const totalAmount = (prevState.totalAmount - prevState.tax2Total);
        return {
          ...prevState,
          tax2Id,
          tax2Percentage,
          tax2Total: 0,
          totalAmount: totalAmount
        }
      })
    }
  }


  const workLogOnChange = () => {
    setInitialValues(prevState => {
      const remarks = formRef.getFieldValue('remarks');
      return {
        ...prevState,
        remarks
      }
    })
  };


  const onFileChange = (data) => {
    setFileList([data.file]);
  };


  const suffixSelector2 = (
    <Form.Item name="baseCurrency" noStyle label="Please Select">
      <Select disabled>
        <Option value={currency}>{currency}</Option>
      </Select>
    </Form.Item>
  );


  return <>
    <br></br>
    <Form form={formRef} autoComplete='off' initialValues={initialValues} key={counter}>
      <Row className='pr-2'>
        <Col span={1}>
        </Col>
        <Col span={19}>
          <Descriptions >
            <Descriptions.Item label={'Barge'}>{<span><b>
              {initialValues ? initialValues?.barges?.map(rec => {
                return SequenceUtils.formatNumberToSpecificLength(rec?.bargeNo) + ' - ' + rec?.bargeNomination
              }) : ''}
            </b></span>}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={1}> </Col>
        <Col span={16}>
          <Descriptions >
            <Descriptions.Item label={'Coal Supplier'}>{<span  ><b> {initialValues ? Array.from(new Set(initialValues?.coalSuppliers?.map(rec => {
              return rec?.coalSupplierName
            }))).toString() : ''}</b></span>}</Descriptions.Item>
          </Descriptions>
        </Col>

      </Row>

      <Row>
        <Col span={5} offset={1}>
          <b>Total Qty(Mt)</b>
        </Col>
        <Col span={14} offset={4}>
          <Text>{internationalFormattedValue(initialValues?.totalQuantity)}</Text>
        </Col>

      </Row>

      <Row>
        <Col span={5} offset={1}>
          <b>Coal Price Per MT</b>
        </Col>
        <Col span={14} offset={4}>
          <Text>{getLocalFormat(initialValues?.coalBasePrice ? initialValues?.coalBasePrice / selectedRowsData.length : 0, currency)}</Text>
        </Col>

      </Row>


      <Row>
        <Col span={5} offset={1}>
          <b>Total</b>
        </Col>
        <Col span={14} offset={4}>
          <Text>{getLocalFormat(initialValues?.costingsTotal, currency)}</Text>
        </Col>

      </Row>
      <br></br>
      <Row>
        <Col span={7} offset={1}>
          <Form.Item name={"tax1Id"} label={<b>TAX 1</b>} >
            <Select
              onChange={onTaxChange1}
              placeholder='Select tax'
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
            >
              <Option value={null}>Not Applicable</Option>
              {taxDropDown?.map((rec) => {
                return <Option value={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
              })}
            </Select>
          </Form.Item >
        </Col>
        <Col span={14} offset={2}>
          <Text>{getLocalFormat(initialValues?.tax1Total, currency)}</Text>
        </Col>

      </Row>

      <Row>
        <Col span={7} offset={1}>
          <Form.Item name={"tax2Id"} label={<b>TAX 2</b>} >
            <Select
              onChange={onTaxChange2}
              placeholder='Select tax'
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
            >
              <Option value={null}>Not Applicable</Option>
              {taxDropDown?.map((rec) => {
                return <Option value={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
              })}
            </Select>
          </Form.Item >
        </Col>
        <Col span={14} offset={2}>
          <Text>{getLocalFormat(initialValues?.tax2Total, currency)}</Text>
        </Col>

      </Row>

      <Row>
        <Col span={7} offset={1}>
          <Form.Item name='tdsId' label={<b>TDS&nbsp;&nbsp;</b>}>
            <Select
              onChange={tdsOnchange}
              placeholder='Select TDS'
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
            >
              <Option value={null}>Not Applicable</Option>
              {tdsModel?.map((rec) => {
                return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={14} offset={2}>
          <Text>{getLocalFormat(initialValues?.tdsTotal, currency)}</Text>
        </Col>

      </Row>
      {/* <Row>
        <Col span={5} offset={1}>
          <b>Total Amount</b>
        </Col>
        <Col span={14} offset={4}>
          <Text>{getLocalFormat(initialValues?.totalAmount, currency)}</Text>
        </Col>

      </Row> */}
      <br></br>

      <Row>
        <Col span={7} offset={1}>
          <Form.Item label={'Amount'}></Form.Item>
        </Col>
        <Col span={14} offset={2}>
          <Form.Item name={'costingInvoiceTotal'}>
            <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
            </InputNumber>
          </Form.Item>
        </Col>

      </Row>
      <Row>
        <Col span={7} offset={1}>
          <Form.Item label={'Adjusted Against Advances'}></Form.Item>
        </Col>
        <Col span={14} offset={2}>
          <Form.Item name={'totalAdjustedValue'}>
            <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
            </InputNumber>
          </Form.Item>
        </Col>

      </Row>
      <Row>
        <Col span={7} offset={1}>
          <Form.Item label={'To be paid to Supplier'}></Form.Item>
        </Col>
        <Col span={14} offset={2}>
          <Form.Item name={'remainingBalance'}>
            <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
            </InputNumber>
          </Form.Item>
        </Col>

      </Row>
      <Row>
        <Col span={7} offset={1}>
          <Form.Item label={'Work Log'}></Form.Item>
        </Col>
        <Col span={14} offset={2}>
          <Form.Item
            name='remarks'
            style={{ width: '70%' }}
          >
            <TextArea rows={2} style={{ width: '150%' }} onChange={() => workLogOnChange()} />
          </Form.Item>
        </Col>
      </Row>
      <Row className='pr-2'>
        <Col span={15}>
          <Form.Item name='filesData' label="Upload The Invoice" hidden={true}>
            <Upload
              multiple={false}
              fileList={fileList}
              listType='text'
              maxCount={1}
              customRequest={onFileChange}
              onRemove={() => setFileList([])}
              showUploadList>
              <Button icon={<UploadOutlined />}>Choose File</Button>
            </Upload>
          </Form.Item>
        </Col>

      </Row>

      <Row className='pt-2 pr-2' justify='space-between'>
        <Col>
        </Col>
        <Col>
          {/* <Button type='primary' onClick={() => costingsSave(initialValues)}>Submit For Approval</Button> */}
          <Button type='primary' disabled={updateData?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => costingsSave(initialValues)}>Submit For Approval</Button>
        </Col>
      </Row>
    </Form>

  </>
}

export default PriceAdjustment