import { Button, Card, Col, Descriptions, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountsService, AccountsVoucherTypesEnum, BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, PaymentVoucherMasterDto, PaymentVoucherService, PaymentmodesService } from '@exportx/shared-models-and-services';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { AlertMessages, TableForm, useAuthState } from '../../../common';
import DatePicker from '../../../common/data-picker/date-picker';

interface IPropsPaymentF {
    paymentTypeCategory: AccountsVoucherTypesEnum
}

export const PaymentF = (props: IPropsPaymentF) => {
    const { paymentTypeCategory } = props
    const { authContext } = useAuthState();
    const { Option } = Select;
    const pathToreDirect = paymentTypeCategory === AccountsVoucherTypesEnum.PAYMENT_VOUCHER ? '/payment-voucher' : '/receipt-voucher';
    const { TextArea } = Input;
    const { formatMessage: fm } = useIntl();
    const navigate = useNavigate();
    const { state }: any = useLocation()
    const { groupEntryData } = state ? state : { groupEntryData: {} };
    const [formRef] = Form.useForm();
    const [ledgerAccounts, setLedgerAccounts] = useState([]);
    const [nonLedgerAccounts, setNonLedgerAccounts] = useState([]);
    const [pmtModes, setPmtModes] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0)
    const service = new PaymentVoucherService();
    const businessService = new BusinessNumberService()
    const [businessDropDown, setBusinessDropDown] = useState<BusinessNoDropDownDto[]>([])

    if (groupEntryData?.tranDate)
        groupEntryData.tranDate = moment(groupEntryData.tranDate)
    else
        groupEntryData.tranDate = moment()


    useEffect(() => {
        const accountsService = new AccountsService();
        const pmtModesService = new PaymentmodesService();
        const getPmtModesService = () => {
            pmtModesService.getAllActivePaymentmodes().then((res => {
                if (res.status) {
                    setPmtModes(res.data)
                } else {
                    setPmtModes([])
                }
            })).catch((err => {
                setPmtModes([])
            }))
        }
        const getAllAccountsData = () => {
            accountsService.getAllPmtLedgerAccounts().then((res => {
                if (res.status) {
                    setLedgerAccounts(res.data)
                } else {
                    setLedgerAccounts([])
                }
            })).catch((err => {
                setLedgerAccounts([])
            }))
        }

        const getNonLedgerAccounts = () => {
            accountsService.getAllNonPmtLedgerAccounts().then((res => {
                if (res.status) {
                    setNonLedgerAccounts(res.data)
                } else {
                    setNonLedgerAccounts([])
                }
            })).catch(err => {
                setNonLedgerAccounts([])
            })
        }
        const getBusinessNoDropDown = () => {
            const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
            businessService.getBusinessNoDropDown(req).then(res => {
                if (res.status) {
                    setBusinessDropDown(res.data)
                } else {
                    console.log(res.internalMessage)
                }
            }).catch(err => {
                console.log(err.message)
            })
        }
        getAllAccountsData();
        getNonLedgerAccounts();
        getPmtModesService();
        getBusinessNoDropDown();
    }, [])



    const columns: any = [
        {
            title: 'Particulars',
            dataIndex: 'particulars',
            render: (value, row, index) => {
                return (
                    <Form.Item name={[index, "ledgerID"]}>
                        <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Search Particulars '>
                            {nonLedgerAccounts.map((b) => {
                                return <Option value={b.actLedId}>{b.actLedName}</Option>
                            })}
                        </Select>
                    </Form.Item>
                );
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: '120px',
            render: (value, row, index) => {
                return (
                    <>
                        <Form.Item name={[index, "amount"]}
                            rules={[
                                {
                                    pattern: /^[0-9]*$/,
                                    message: "Only Numbers Accepted"
                                },
                            ]}>
                            <Input
                                placeholder="amount"
                            />
                        </Form.Item>
                        <Form.Item name={"type"} style={{ display: 'none' }}>
                            <Input hidden />
                        </Form.Item>
                        <Form.Item name={"plantCode"} style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={"slNo"} style={{ display: 'none' }}>
                            <Input hidden />
                        </Form.Item>
                    </>
                );
            }
        }
    ]

    const submitFormHandler = () => {
        formRef.validateFields().then(values => {
            submitForm(values)
        })
    }
    const clearData = () => {
        formRef.resetFields();
    }


    const submitForm = (values: any) => {
        // const createDto: PaymentVoucherMasterDto = new PaymentVoucherMasterDto(paymentTypeCategory, authContext.user.userName, values.tranDate, values.voucherDisplayID, values.remarks, authContext.defaultPlant, `${totalAmount}`, values.businessNo, values.pmtVoucherTransaction, undefined);
        const createDto: PaymentVoucherMasterDto = new PaymentVoucherMasterDto(values.voucherId, values.slNo, undefined, paymentTypeCategory, values.tranDate, values.voucherDisplayID, values.remarks, `${totalAmount}`, authContext.defaultPlant, groupEntryData?.versionFlag, authContext.user.userName, authContext.user.userName, undefined, values.type, values.transaction, values.businessNo, undefined, undefined, undefined, values.pmtVoucherTransaction);

        let voucherId = values.voucherId;
        let transaction = values.transaction;
        let voucherSlNo = values.slNo;
        const req: any = { ...createDto, actLedger: values.actLedger, type: values.type, voucherId, transaction, voucherSlNo };
        service.create(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(`Payment Voucher Created SuccessFully`);
                setTimeout(() => {
                    navigate(pathToreDirect);
                }, 1000);
            }
            else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }

        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const handleTotal = (_, values: any) => {
        if (values?.pmtVoucherTransaction) {
            const transactions = [...values.pmtVoucherTransaction];
            let sum = 0;
            transactions.forEach(element => {
                sum += element?.amount ? Number(element.amount) : 0;
            });
            formRef.setFieldValue('totalAmount', sum);
            setTotalAmount(sum);
        }

    }

    return (
        <div>
            <Card title={<span> {paymentTypeCategory === AccountsVoucherTypesEnum.PAYMENT_VOUCHER ? "Payment Voucher Details" : "Receipt Voucher Details"}</span>}
                extra={<Button type='primary' className='panel_button' onClick={() => navigate(pathToreDirect)}>View</Button>}>
                <Card>
                    <Form
                        layout='vertical'
                        form={formRef}
                        autoComplete='off'
                        onValuesChange={handleTotal}
                        initialValues={groupEntryData}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1}>
                                <Form.Item name={"voucherId"} style={{ display: 'none' }}>
                                    <Input hidden />
                                </Form.Item>
                                <Form.Item name={"slNo"} style={{ display: 'none' }}>
                                    <Input hidden />
                                </Form.Item>

                                <Form.Item label="Account" rules={[{ required: true }]}
                                    name="actLedger" style={{ width: '100%' }}>
                                    <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Account'>
                                        {ledgerAccounts.map((a) => {
                                            return <Option value={a.actLedId}>{a.actLedName}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 3 }} offset={1}>
                                <Form.Item label="Date" rules={[{ required: true, }]}
                                    name="tranDate" style={{ width: '100%' }}>
                                    <DatePicker />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1}>
                                <Form.Item label="Payment Type" rules={[{ required: true, }]}
                                    name="type" style={{ width: '100%' }}>
                                    <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Payment Type'>
                                        {pmtModes.map(pmtMode => {
                                            return <Option value={pmtMode.paymentMode}>{pmtMode.paymentMode}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1}>
                                <Form.Item label="Transaction Number"
                                    name="transaction" style={{ width: '100%' }}>
                                    <Input placeholder='' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1}>
                                <Descriptions>
                                    <Descriptions.Item>Voucher No:</Descriptions.Item>
                                </Descriptions>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1} >
                                <Form.Item label="Business Number" rules={[{ required: false, }]}
                                    name="businessNo">
                                    <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Business Number'>
                                        {businessDropDown.map(pmtMode => {
                                            return <Option value={pmtMode.bnId}>{pmtMode.businessNo}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <br></br>

                        <Form.List name="pmtVoucherTransaction">
                            {(particulars, { add, remove }) => {
                                return <TableForm dataSource={particulars} add={add} remove={remove} columns={columns} />;
                            }}
                        </Form.List>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1} >
                                <Descriptions>
                                    <Descriptions.Item>Total Amount: {totalAmount}</Descriptions.Item>
                                    <Form.Item name="totalAmount" style={{ display: 'none' }}>
                                        <Input hidden />
                                    </Form.Item>
                                </Descriptions>
                            </Col>
                        </Row>

                        <br /><br />
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 22 }} offset={1}>
                                <Form.Item name='remarks' label='Narration'>
                                    <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ float: 'right' }}>
                            <Button type='primary' onClick={submitFormHandler} >Submit</Button>
                            {/* <Button danger>Cancel</Button> */}

                            <Button htmlType="button" style={{ float: 'right' }} onClick={() => {
                                clearData();
                            }}>Clear</Button>
                        </div>

                    </Form>
                </Card>
            </Card>
        </div >
    )
}

export default PaymentF