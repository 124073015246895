import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000000'
const styles = StyleSheet.create({
  container: {
    borderBottomColor: 'black',
    borderTopColor: 'black',
    borderTop: 0.4,
    borderBottom: 0.4,
    // display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'row'
  },
  description: {
    width: '55%',
    flexDirection: 'column',
    borderRight: 0.4,
    padding: 4,
    fontStyle: 'bold',
    textAlign: 'center',
    borderRightColor: borderColor,
  },
  qty: {
    width: '15%',
    flexDirection: 'column',
    borderRight: 0.4,
    padding: 4,
    fontStyle: 'bold',
    textAlign: 'right',
    borderRightColor: borderColor,
  },
  price: {
    width: '15%',
    flexDirection: 'column',
    borderRight: 0.4,
    padding: 4,
    fontStyle: 'bold',
    textAlign: 'right',
    borderRightColor: borderColor,
  },
  amount: {
    width: '15%',
    flexDirection: 'column',
    padding: 4,
    fontStyle: 'bold',
    textAlign: 'right',
  },
});

const InvoiceHeader = (props) => (
  <View style={styles.container}>
    <Text style={styles.description}>Description</Text>
    <Text style={styles.qty}>{props.demurrageName === `Demurrage` ? `Demurrage in days` : props.demurrageName === `Despatch` ? `Despatch in days` : `Quantity`}</Text>
    <Text style={styles.price}>{props.demurrageName === `Demurrage` ? `Demurrage Per Day` : props.demurrageName === `Despatch` ? `Despatch  Per Day` : `Unit Price`}</Text>
    <Text style={styles.amount}>Amount</Text>
  </View>
);

export default InvoiceHeader