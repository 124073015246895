import { CommercialGetAllDto, CompanyCodeReq, CompanyTdsService, ContractModesEnum, ContractStatusEnum, ContractTermEnum, CostingTypesEnum, CurrencyConverterService, CurrencyDto, CurrencyExchangeRequestDto, CurrencyService, GetAllPerFormaDataDto, PerformaCoalCostingService, SalesFreightCostingReqDto, ShippingTypes, TaxDropDownDto, TaxesService, TdsResModel } from '@exportx/shared-models-and-services';
import { getLocalFormat, getNumberFromLocalString } from '@exportx/ui-utils';
import { Button, Checkbox, CheckboxProps, Col, Drawer, Form, FormInstance, Input, InputNumber, Row, Select, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';
import { QualityPriceAdjustments } from '../common';

interface PriceIProps {
    totalBargeQuantity: number;
    formRef: FormInstance<any>;
    bargeSelection: any;
    taxTotal: any;
    setTaxTotal: React.Dispatch<any>;
    initialValues?: CommercialGetAllDto | GetAllPerFormaDataDto;
    qualityFieldValue?: number;
    setPriceAdjustmentDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
    priceAdjustmentDrawer?: boolean;
    setDummyRefresh: React.Dispatch<React.SetStateAction<number>>;
    dummyRefresh: number;
    submitHandler?: () => void;
    costingCurrency?: any;
    contractType: ContractModesEnum;
    costingType: CostingTypesEnum;

}



const CmSalePriceAdjustment = (props: PriceIProps) => {
    const { costingCurrency, contractType, costingType, submitHandler, totalBargeQuantity, formRef, bargeSelection, setTaxTotal, taxTotal, initialValues, qualityFieldValue, setPriceAdjustmentDrawer, priceAdjustmentDrawer, setDummyRefresh, dummyRefresh } = props;
    // const { contractType, submitHandler, totalBargeQuantity, formRef, bargeSelection, setTaxTotal, taxTotal, initialValues, qualityFieldValue, setPriceAdjustmentDrawer, priceAdjustmentDrawer, setDummyRefresh, dummyRefresh } = props;
    const { Option } = Select;
    const { Text } = Typography;
    const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
    const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([])
    const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);

    const { authContext } = useAuthState();
    const companyTdsService = new CompanyTdsService();
    const taxesService = new TaxesService();
    const currencyConverterService = new CurrencyConverterService();
    const currencyService = new CurrencyService();
    const performaService = new PerformaCoalCostingService();

    // const currency = authContext.defaultPlantCurrency;
    const [systemCurrency, setSystemCurrency] = useState<any>(authContext.defaultPlantCurrency);
    const [exchangeDateVisible, setExchangeDateVisible] = useState<boolean>(true);
    const [disableFreight, setDisableFreight] = useState(false);

    useEffect(() => {
        getTdsData();
        taxActiveDropDown();
        getCurrency();
        checkExistingSalesFreightCosting()

    }, []);

    useEffect(() => {
        formRef.setFieldValue("baseCurrency", bargeSelection?.baseCurrency)
        visibleDisable(bargeSelection?.baseCurrency, formRef.getFieldValue('costingCurrency'));
        setSystemCurrency(bargeSelection?.baseCurrency)
        if (bargeSelection?.purchasingTerms === ContractTermEnum.NON_SPEC || bargeSelection?.noPriceAdjustment === "true") {
            formRef.setFieldValue('adjustedPriceValue', bargeSelection?.coalBasePrice);
        }
    }, [bargeSelection]);



    useEffect(() => {
        formRef.setFieldValue('adjustedPriceValue', formRef.getFieldValue('qualityAdjustPrice'));
        pricePmtOnChange(formRef.getFieldValue('qualityAdjustPrice') ? Number(formRef.getFieldValue('qualityAdjustPrice')) : 1)
    }, [dummyRefresh]);



    useEffect(() => {
        if (initialValues) {
            setSystemCurrency(initialValues?.costingCurrency);
            baseCurrencyOnChangeHandler(initialValues?.baseCurrency)
            formRef.setFieldValue('baseCurrency', initialValues?.baseCurrency);
            formRef.setFieldValue('adjustedPriceValue', Number(initialValues.adjustedPriceValue));

            if (initialValues?.exchangeDate) {
                const ex: any = moment(initialValues.exchangeDate)
                initialValues.exchangeDate = ex;
            }

            formRef.setFieldValue('exchangeDate', initialValues.exchangeDate);

            if (initialValues?.baseCurrency !== initialValues?.costingCurrency) {
                getCurrencyExchangeRate(initialValues?.baseCurrency, initialValues?.costingCurrency, initialValues.exchangeDate);
            } else {
                visibleDisable(systemCurrency, systemCurrency);
            };
            pricePmtOnChange(Number(initialValues.adjustedPriceValue));

        }

    }, [initialValues]);

    useEffect(() => {
        formRef.setFieldValue('totalBargeQuantity', totalBargeQuantity);
        subTotalOnChangeHandler(Number(taxTotal?.fcTotal),
            initialValues?.taxId1 ? initialValues.taxId1 : formRef.getFieldValue('taxId1'),
            initialValues?.taxId2 ? initialValues.taxId2 : formRef.getFieldValue('taxId2'),
            initialValues?.tdsId ? initialValues.tdsId : formRef.getFieldValue('tdsId'));
        freightSubTotalOnChangeHandler(Number(taxTotal?.freightTotalAmt ?? 0),
            initialValues?.freightTaxId1 ? initialValues.freightTaxId1 : formRef.getFieldValue('freightTaxId1'),
            initialValues?.freightTaxId2 ? initialValues.freightTaxId2 : formRef.getFieldValue('freightTaxId2'),
            initialValues?.freightTdsId ? initialValues.freightTdsId : formRef.getFieldValue('freightTdsId'));
        pricePmtOnChange(formRef.getFieldValue('adjustedPriceValue') ? Number(formRef.getFieldValue('adjustedPriceValue')) : 1);
        pcTypeCharges();
    }, [totalBargeQuantity]);



    const visibleDisable = (bargeSelectionCurrency: string, systemCurrency: string) => {
        if (bargeSelectionCurrency === systemCurrency) {
            setExchangeDateVisible(true);
            formRef.setFieldValue('exchangeDate', undefined);
        } else {
            setExchangeDateVisible(false);
        };
    };


    const pcTypeCharges = () => {
        setTaxTotal((prev) => {
            const fcCharges = Number(formRef.getFieldValue('totalBargeQuantity')) * bargeSelection?.fcCharges ? Number(bargeSelection?.fcCharges) : 0 * (prev.exchangeRate ? Number(prev.exchangeRate) : 0);
            const fcTotal = fcCharges + Number(prev.totalCoalPrice);
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const toBeSupplier = fcTotal - totalAdjustedValue;

            formRef.setFieldValue('coalCostingTotal', Number(fcTotal.toFixed(2)).toLocaleString());
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());
            return {
                ...prev,
                fcCharges: fcCharges ? Number(Number(fcCharges).toFixed(2)) : 0,
                fcTotal: fcTotal ? Number(Number(fcTotal).toFixed(2)) : 0,
                coalCostingTotal: fcTotal ? Number(Number(fcTotal).toFixed(2)) : 0,
            };
        });
    };

    useEffect(() => {
        pricePmtOnChange(formRef.getFieldValue('adjustedPriceValue') ? Number(formRef.getFieldValue('adjustedPriceValue')) : 1);
        subTotalOnChangeHandler(Number(taxTotal?.fcTotal), formRef.getFieldValue('taxId1'), formRef.getFieldValue('taxId2'), formRef.getFieldValue('tdsId'))
        freightSubTotalOnChangeHandler(Number(taxTotal?.freightTotalAmt), formRef.getFieldValue('freightTaxId1'), formRef.getFieldValue('freightTaxId2'), formRef.getFieldValue('freightTdsId'))
        pcTypeCharges();
    }, [qualityFieldValue]);


    if (initialValues?.exchangeDate) {
        const ex: any = moment(initialValues.exchangeDate)
        initialValues.exchangeDate = ex;
    }

    const getTdsData = () => {
        const req = new CompanyCodeReq();
        req.company = authContext.defaultPlant;
        req.date = moment().format('YYYY-MM-DD');
        companyTdsService.getTdsByCompanyCode(req).then(res => {
            if (res.status) {
                setTdsModel(res.data);
            } else {
                setTdsModel([]);
            }
        }).catch(err => {
            console.log(err);
            setTdsModel([]);
        });
    };

    const checkExistingSalesFreightCosting = () => {
        const req = new SalesFreightCostingReqDto(formRef.getFieldValue('contractId'), formRef.getFieldValue('qualityId'), formRef.getFieldValue('purchaseType'), authContext.defaultPlant)
        performaService.checkExistingSalesFreightCosting(req).then(res => {
            if (res.status) {
                setDisableFreight(false)
            }
            else {
                setDisableFreight(true)
            }
        }).catch(err => {
            console.log(err);
            setDisableFreight(true)
        });
    }

    const taxActiveDropDown = () => {
        taxesService.getActiveTaxDropDown().then(res => {
            if (res.status) {
                setTaxDropDown(res.data)
            } else {
                setTaxDropDown([])
            }
        }).catch(err => {
            console.log(err.message);
            setTaxDropDown([]);
        })
    };

    const getCurrency = () => {
        currencyService.getAllCurrenciesForDropDown().then(res => {
            if (res.status) {
                setCurrencies(res.data);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message));
    };

    const getCurrencyExchangeRate = (fromCurrency, toCurrency, date) => {
        const req = new CurrencyExchangeRequestDto(fromCurrency, toCurrency, moment(date).format('YYYY-MM-DD'));
        if (moment(req.exchangeDate).format('YYYY-MM-DD') === 'Invalid date') {
            return;
        }
        currencyConverterService.getCurrencyExchangeRate(req).then(res => {
            if (res.status) {
                if (res.data.exchangeRate) {
                    formRef.setFieldValue('exchangeRate', res.data.exchangeRate)
                    const exchangeRateValue = Number(res.data.exchangeRate) * (initialValues?.adjustedPriceValue ? Number(initialValues?.adjustedPriceValue) : Number(formRef.getFieldValue('qualityAdjustPrice')));
                    setTaxTotal((prev) => {
                        return {
                            ...prev,
                            exchangeRateValue: exchangeRateValue,
                            exchangeRate: Number(res.data.exchangeRate),
                            displayExchangeRate: Number(res.data.displayExchangeRate)
                        }
                    });

                    pricePmtOnChange(formRef.getFieldValue("adjustedPriceValue") ? Number(formRef.getFieldValue("adjustedPriceValue")) : 1)
                };
            } else {
                delete taxTotal?.displayExchangeRate;
                delete taxTotal.exchangeRateValue;

                pricePmtOnChange(formRef.getFieldValue("adjustedPriceValue") ? Number(formRef.getFieldValue("adjustedPriceValue")) : 1)
                AlertMessages.getInfoMessage(res.internalMessage);
                // const exchangeRateValue = 1 * (initialValues?.adjustedPriceValue ? Number(initialValues?.adjustedPriceValue) : Number(formRef.getFieldValue('qualityAdjustPrice')));
                setTaxTotal((prev) => {
                    return {
                        ...prev,
                        exchangeRateValue: 1
                    }
                });
                pcTypeCharges();
            }
        }).catch(err => { console.log(err.message) });
    };


    const exChangeDateHandler = (value) => {
        getCurrencyExchangeRate(systemCurrency, costingCurrency, value);
    };

    const baseCurrencyOnChangeHandler = (value) => {
        setSystemCurrency(value);
        visibleDisable(value, formRef.getFieldValue('costingCurrency'));
    }


    const suffixSelector = (
        <Form.Item name="baseCurrency" noStyle label="Please Select">
            <Select
                onChange={baseCurrencyOnChangeHandler}
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 60 }}
            >
                {currencies?.map((rec) => {
                    return <Option value={rec.currencyCode} key={rec.currencyId}>{rec.currencyCode}</Option>
                })}
            </Select>
        </Form.Item>
    );



    const suffixSelector2 = (
        <Form.Item name="costingCurrency" noStyle label="Please Select">
            <Select disabled
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 60 }}
            >
                {currencies?.map((rec) => {
                    return <Option value={rec.currencyCode} key={rec.currencyId}>{rec.currencyCode}</Option>
                })}
            </Select>
        </Form.Item>
    );

    const onFreightTaxChange1 = (v: string, tb: number) => {

        let p: number = taxDropDown?.filter((rec) => rec?.taxId === v)[0]?.taxPercentage;
        if (!taxDropDown.length) {
            if (initialValues?.freightTaxPerc1) {
                p = initialValues.freightTaxPerc1
            }
        }
        const taxTotal1 = (v === undefined || v === null) ? 0 : (Number(p) / 100) * Number(tb);
        setTaxTotal((prev) => {
            const withTax = taxTotal1 + prev?.taxTotal2 + prev.freightTotalAmt - (prev?.freightTdsTotal)
            formRef.setFieldValue('freightCostingTotal', Number(withTax?.toFixed(2)).toLocaleString());

            return {
                ...prev,
                freightTaxTotal1: taxTotal1,
                freightTaxPerc1: Number(p),
                freightCostingTotal: withTax ? Number(withTax.toFixed(2)) : 0,
            }
        })
    };

    const onTaxChange1 = (v: string, tb: number) => {

        let p: number = taxDropDown?.filter((rec) => rec?.taxId === v)[0]?.taxPercentage;
        if (!taxDropDown.length) {
            if (initialValues?.taxPercentage1) {
                p = initialValues.taxPercentage1
            }
        }
        const taxTotal1 = (v === undefined || v === null) ? 0 : (Number(p) / 100) * Number(tb);
        setTaxTotal((prev) => {
            const withTax = taxTotal1 + prev?.taxTotal2 + prev.fcTotal - (prev?.tdsTotal)
            formRef.setFieldValue('coalCostingTotal', Number(withTax?.toFixed(2)).toLocaleString());
            let totalInvoice = (withTax)?.toFixed(2);
            if (prev?.includeFreight || formRef.getFieldValue('includeFreight')) {
                totalInvoice = (Number(withTax) + Number(prev?.freightCostingTotal))?.toFixed(2);
            }
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const toBeSupplier = +totalInvoice - totalAdjustedValue;
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());
            return {
                ...prev,
                taxTotal1: taxTotal1,
                taxPercentage1: Number(p),
                coalCostingTotal: withTax ? Number(withTax.toFixed(2)) : 0,
                invoiceTotal: totalInvoice,
                remainingBalance: toBeSupplier
            }
        })
    };


    const onFreightTaxChange2 = (v: string, tb: number) => {
        let p: number = taxDropDown?.filter((rec) => rec?.taxId === v)[0]?.taxPercentage;
        if (!taxDropDown.length) {
            if (initialValues?.freightTaxPerc2) {
                p = initialValues.freightTaxPerc2
            }
        }
        const taxTotal2 = (v === undefined || v === null) ? 0 : (Number(p) / 100) * Number(tb);
        setTaxTotal((prev) => {
            const withTax = prev?.freightTaxTotal1 + taxTotal2 + prev.freightTotalAmt - (prev?.freightTdsTotal)
            formRef.setFieldValue('freightCostingTotal', Number(withTax?.toFixed(2)).toLocaleString());
            let totalInvoice = (withTax)?.toFixed(2);
            if (prev?.includeFreight || formRef.getFieldValue('includeFreight')) {
                totalInvoice = (Number(prev?.coalCostingTotal) + Number(withTax))?.toFixed(2);
            }
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const toBeSupplier = +totalInvoice - totalAdjustedValue;
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());
            return {
                ...prev,
                freightTaxTotal2: taxTotal2,
                freightTaxPerc2: Number(p),
                freightCostingTotal: withTax ? Number(withTax.toFixed(2)) : 0,
                invoiceTotal: totalInvoice,
                remainingBalance: toBeSupplier
            }
        })
    }

    const onTaxChange2 = (v: string, tb: number) => {
        let p: number = taxDropDown?.filter((rec) => rec?.taxId === v)[0]?.taxPercentage;
        if (!taxDropDown.length) {
            if (initialValues?.taxPercentage1) {
                p = initialValues.taxPercentage1
            }
        }
        const taxTotal2 = (v === undefined || v === null) ? 0 : (Number(p) / 100) * Number(tb);
        setTaxTotal((prev) => {
            const withTax = prev?.taxTotal1 + taxTotal2 + prev.fcTotal - (prev?.tdsTotal)
            formRef.setFieldValue('coalCostingTotal', Number(withTax.toFixed(2)).toLocaleString());
            let totalInvoice = (withTax)?.toFixed(2);
            if (prev?.includeFreight || formRef.getFieldValue('includeFreight')) {
                totalInvoice = (Number(withTax) + Number(prev?.freightCostingTotal))?.toFixed(2);
            }
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const toBeSupplier = +totalInvoice - totalAdjustedValue;
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());

            return {
                ...prev,
                taxTotal2: taxTotal2,
                taxPercentage2: Number(p),
                coalCostingTotal: withTax ? Number(withTax.toFixed(2)) : 0,
                invoiceTotal: totalInvoice,
                remainingBalance: toBeSupplier
            }
        })
    }

    const onChangeFreightTds = (v: string, tb: number) => {
        let p: number = tdsModel?.filter((rec) => rec?.companyTdsId === v)[0]?.tdsValue;
        if (!tdsModel.length) {
            if (initialValues?.freightTdsPerc) {
                p = initialValues.freightTdsPerc
            }
        }
        const tdsTotal = (v === undefined || v === null) ? 0 : (Number(p) / 100) * Number(tb);
        setTaxTotal((prev) => {
            const withTax = prev?.freightTaxTotal1 + prev?.freightTaxTotal2 + prev.freightTotalAmt - (tdsTotal)
            formRef.setFieldValue('freightCostingTotal', Number(withTax.toFixed(2)).toLocaleString());
            let totalInvoice = (withTax)?.toFixed(2);
            if (prev?.includeFreight || formRef.getFieldValue('includeFreight')) {
                totalInvoice = (Number(withTax) + Number(prev?.coalCostingTotal))?.toFixed(2);
            }
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const toBeSupplier = +totalInvoice - totalAdjustedValue;
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());

            return {
                ...prev,
                freightTdsTotal: tdsTotal,
                freightTdsPerc: Number(p),
                freightCostingTotal: withTax ? Number(withTax.toFixed(2)) : 0,
                invoiceTotal: totalInvoice,
                remainingBalance: toBeSupplier

            }
        });
    };

    const tdsOnchange = (v: string, tb: number) => {
        let p: number = tdsModel?.filter((rec) => rec?.companyTdsId === v)[0]?.tdsValue;
        if (!tdsModel.length) {
            if (initialValues?.tdsPercentage) {
                p = initialValues.tdsPercentage
            }
        }
        setTaxTotal((prev) => {
            const tdsTotal = (v === undefined || v === null) ? 0 : (Number(p) / 100) * Number(tb);
            const withTax = prev?.taxTotal1 + prev?.taxTotal2 + prev.fcTotal - (tdsTotal)
            formRef.setFieldValue('coalCostingTotal', Number(withTax.toFixed(2)).toLocaleString());
            let totalInvoice = (withTax)?.toFixed(2);
            if (prev?.includeFreight || formRef.getFieldValue('includeFreight')) {
                totalInvoice = (Number(withTax) + Number(prev?.freightCostingTotal))?.toFixed(2);
            }
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const toBeSupplier = +totalInvoice - totalAdjustedValue;
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());

            return {
                ...prev,
                tdsTotal: tdsTotal,
                tdsPercentage: Number(p),
                coalCostingTotal: withTax ? Number(withTax.toFixed(2)) : 0,
                invoiceTotal: totalInvoice,
                remainingBalance: toBeSupplier

            }
        });
    };

    const pricePmtOnChange = (value: number) => {
        setTaxTotal((prev) => {
            const exchangeRateValue = Number(value) * (prev.exchangeRate ? prev.exchangeRate : 1)
            const freight = prev?.freightPricePmt || initialValues?.freightPricePmt
            return {
                ...prev,
                exchangeRateValue,
                totalCoalPrice: exchangeRateValue * Number(formRef.getFieldValue('totalBargeQuantity')),
                freightPrice: freight * (prev.exchangeRate ? prev.exchangeRate : 1),
                freightTotalAmt: freight * (prev.exchangeRate ? prev.exchangeRate : 1) * Number(formRef.getFieldValue('totalBargeQuantity')),
                freightPricePmt: freight
            };
        });
        // pcTypeCharges();
        // subTotalOnChangeHandler(Number(taxTotal?.totalCoalPrice), formRef.getFieldValue('taxId1'), formRef.getFieldValue('taxId2'), formRef.getFieldValue('tdsId'))
    };


    //pricePmtOnChange is not execute totalCoalPrice changing time
    useEffect(() => {
        pcTypeCharges();
        subTotalOnChangeHandler(Number(taxTotal?.fcTotal), formRef.getFieldValue('taxId1'), formRef.getFieldValue('taxId2'), formRef.getFieldValue('tdsId'))
        freightSubTotalOnChangeHandler(Number(taxTotal?.freightTotalAmt), formRef.getFieldValue('freightTaxId1'), formRef.getFieldValue('freightTaxId2'), formRef.getFieldValue('freightTdsId'))
    }, [taxTotal?.totalCoalPrice, taxTotal?.fcTotal, taxTotal?.freightTotalAmt]);


    const freightSubTotalOnChangeHandler = (e: number, t1: string, t2: string, tds: string) => {
        if (!t1 || !t2 || tds) {
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            let total =  +taxTotal?.coalCostingTotal;
            if(formRef.getFieldValue('includeFreight') || taxTotal?.includeFreight || initialValues?.includeFreight) {
                total = (taxTotal?.freightTotalAmt + taxTotal?.coalCostingTotal)
                formRef.setFieldValue('freightCostingTotal', Number(taxTotal?.freightTotalAmt)?.toFixed(2));
                setTaxTotal((prev) => ({ ...prev, freightCostingTotal: prev?.freightTotalAmt }))
            }
            const toBeSupplier = total - totalAdjustedValue;

            formRef.setFieldValue('remainingBalance', Number(toBeSupplier).toLocaleString());
        }
        if (t1 !== undefined) {
            onFreightTaxChange1(t1, Number(e));
        };
        if (t2 !== undefined) {
            onFreightTaxChange2(t2, Number(e));
        };
        if (tds !== undefined) {
            onChangeFreightTds(tds, Number(e));
        };
    };

    const subTotalOnChangeHandler = async (e: number, t1: string, t2: string, tds: string) => {
        if (!t1 || !t2 || tds) {
            const totalAdjustedValue = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0
            const total = formRef.getFieldValue('includeFreight') ? (taxTotal?.freightTotalAmt + taxTotal?.totalCoalPrice) : +taxTotal?.totalCoalPrice;
            const toBeSupplier = total - totalAdjustedValue;
            formRef.setFieldValue('coalCostingTotal', Number(taxTotal?.totalCoalPrice.toFixed(2)).toLocaleString());
            formRef.setFieldValue('remainingBalance', Number(toBeSupplier.toFixed(2)).toLocaleString());
        }
        console.log(e, t1, t2, tds)
        if (t1 !== undefined) {
            await onTaxChange1(t1, Number(e));
        };
        if (t2 !== undefined) {
            await onTaxChange2(t2, Number(e));
        };
        if (tds !== undefined) {
            await tdsOnchange(tds, Number(e));
        };
    };

    const onChange: CheckboxProps['onChange'] = (e) => {
        setTaxTotal((prev) => ({ ...prev, includeFreight: e.target.checked }))
        formRef.setFieldValue('includeFreight', e.target.checked)
        freightSubTotalOnChangeHandler(Number(taxTotal?.freightTotalAmt), formRef.getFieldValue('freightTaxId1'), formRef.getFieldValue('freightTaxId2'), formRef.getFieldValue('freightTdsId'))
        subTotalOnChangeHandler(Number(taxTotal?.fcTotal), formRef.getFieldValue('taxId1'), formRef.getFieldValue('taxId2'), formRef.getFieldValue('tdsId'))


    };


    return (
        <>
            <Form
                form={formRef}
                layout='vertical'
                initialValues={initialValues}
            >
                <Row >
                    <Form.Item name={'cmCostingId'} hidden>
                        <Input></Input>
                    </Form.Item>
                </Row>

                <br />
                <Row>
                    <Col span={24}>
                        <Row >
                            <Col span={6} offset={0} >
                                <Form.Item name={'exchangeDate'} label={"Exchange Date"} >
                                    <DatePicker style={{ width: 160 }} onChange={exChangeDateHandler} disabled={exchangeDateVisible} />
                                </Form.Item>
                            </Col>
                            <Col span={8} offset={2}>
                                <Form.Item label={"Adjusted Price PMT"} name={'adjustedPriceValue'}>
                                    <InputNumber
                                        onChange={(e) => pricePmtOnChange((Number(e)))}
                                        addonAfter={suffixSelector}
                                        disabled={true}>
                                    </InputNumber>
                                </Form.Item>
                            </Col>
                            {([ShippingTypes.CFR_DP, ShippingTypes.CIF_DP].includes(formRef.getFieldValue('incoterm')) || [ShippingTypes.CFR_DP, ShippingTypes.CIF_DP].includes(initialValues?.incoterm)) &&

                                <Col span={6} offset={1}>
                                    <Form.Item name={'includeFreight'} label={null}>
                                        <br />
                                        <br />
                                        <Checkbox
                                            disabled={disableFreight}
                                            onChange={onChange}
                                            checked={taxTotal?.includeFreight || formRef.getFieldValue('includeFreight')}
                                        >

                                            <b>Include Freight</b>
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            }

                            <Col>
                            </Col>

                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Col span={taxTotal?.includeFreight ? 12 : 24}>
                        <table className="table table-bordered table-sm">
                            <tr>
                                <th colSpan={2}>Exchange Rate</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.displayExchangeRate, systemCurrency)}</Text>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Price after Exchange</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.exchangeRateValue, costingCurrency)}</Text>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Total Coal Price</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.totalCoalPrice, costingCurrency)}</Text>

                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>FC Charges</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.fcCharges, costingCurrency)}</Text>

                                </td>
                            </tr>
                            {/* <tr>
                                <th colSpan={2}>Freight</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.freightPricePmt, costingCurrency)}</Text>

                                </td>
                            </tr> */}
                            <tr>
                                <th colSpan={2}>Total Amount</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.fcTotal, costingCurrency)}</Text>

                                </td>
                            </tr>
                            <tr>
                                <th>TAX 1</th>
                                <td>
                                    <Form.Item name={"taxId1"}>
                                        <Select
                                            style={{ width: '160px' }}
                                            onChange={(v) => onTaxChange1(v, taxTotal?.fcTotal)}
                                            placeholder='Select tax'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={undefined}>Not Applicable</Option>
                                            {taxDropDown?.map((rec) => {
                                                return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                                            })}
                                        </Select>
                                    </Form.Item >
                                </td>
                                <td>{getLocalFormat(taxTotal?.taxTotal1, costingCurrency)}</td>
                            </tr>
                            <tr>
                                <th>TAX 2</th>
                                <td>
                                    <Form.Item name={"taxId2"}>
                                        <Select
                                            style={{ width: '160px' }}
                                            onChange={(v) => onTaxChange2(v, taxTotal?.fcTotal)}
                                            placeholder='Select tax'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={undefined}>Not Applicable</Option>
                                            {taxDropDown?.map((rec) => {
                                                return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                                            })}
                                        </Select>
                                    </Form.Item >
                                </td>
                                <td>{getLocalFormat(taxTotal?.taxTotal2, costingCurrency)}</td>
                            </tr>
                            <tr>
                                <th><Form.Item label={'TDS'}></Form.Item></th>
                                <td>
                                    <Form.Item name={"tdsId"}>
                                        <Select
                                            style={{ width: '160px' }}
                                            onChange={(v) => tdsOnchange(v, taxTotal?.fcTotal)}
                                            placeholder='Select TDS'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={undefined}>Not Applicable</Option>
                                            {tdsModel?.map((rec) => {
                                                return <Option value={rec.companyTdsId} key={rec.companyTdsId}>{rec.tdsName}</Option>
                                            })}
                                        </Select>
                                    </Form.Item >
                                </td>
                                <td>{getLocalFormat(taxTotal?.tdsTotal, costingCurrency)}</td>
                            </tr>
                            <tr>
                                <th colSpan={2} >
                                    Amount
                                </th>
                                <td>
                                    <Form.Item name={'coalCostingTotal'}>
                                        <InputNumber addonAfter={suffixSelector2} disabled>
                                        </InputNumber>
                                    </Form.Item>

                                </td>

                            </tr>
                            <tr>
                                <th colSpan={2} >
                                    Adjusted Against Advances
                                </th>
                                <td>
                                    <Form.Item name={'totalAdjustedValue'}>
                                        <InputNumber addonAfter={suffixSelector2} disabled>
                                        </InputNumber>
                                    </Form.Item>

                                </td>

                            </tr>
                            <tr>
                                <th colSpan={2} >
                                    To be paid to Supplier
                                </th>
                                <td>
                                    <Form.Item name={'remainingBalance'}>
                                        <InputNumber addonAfter={suffixSelector2} disabled>
                                        </InputNumber>
                                    </Form.Item>

                                </td>

                            </tr>



                        </table>
                    </Col>
                    {taxTotal?.includeFreight &&
                        <Col span={10} offset={2}>
                            <table className="table table-bordered table-sm">
                                <tr>
                                    <th colSpan={3} className='text-center'>Freight Charges</th>

                                </tr>
                                <tr>
                                    <th colSpan={2}>Freight (PMT)</th>
                                    <td>
                                        <Text>{getLocalFormat(taxTotal?.freightPricePmt, systemCurrency)}</Text>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>Exchange Rate</th>
                                    <td>
                                        <Text>{getLocalFormat(taxTotal?.displayExchangeRate, systemCurrency)}</Text>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>Freight after Exchange</th>
                                    <td>
                                        <Text>{getLocalFormat(taxTotal?.freightPrice, costingCurrency)}</Text>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>Total Freight</th>
                                    <td>
                                        <Text>{getLocalFormat(taxTotal?.freightTotalAmt, costingCurrency)}</Text>

                                    </td>
                                </tr>
                                <tr>
                                    <th>TAX 1</th>
                                    <td>
                                        <Form.Item name={"freightTaxId1"}>
                                            <Select
                                                style={{ width: '160px' }}
                                                onChange={(v) => onFreightTaxChange1(v, taxTotal?.freightTotalAmt)}
                                                placeholder='Select tax'
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                            >
                                                <Option value={undefined}>Not Applicable</Option>
                                                {taxDropDown?.map((rec) => {
                                                    return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                                                })}
                                            </Select>
                                        </Form.Item >
                                    </td>
                                    <td>{getLocalFormat(taxTotal?.freightTaxTotal1, costingCurrency)}</td>
                                </tr>
                                <tr>
                                    <th>TAX 2</th>
                                    <td>
                                        <Form.Item name={"freightTaxId2"}>
                                            <Select
                                                style={{ width: '160px' }}
                                                onChange={(v) => onFreightTaxChange2(v, taxTotal?.freightTotalAmt)}
                                                placeholder='Select tax'
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                            >
                                                <Option value={undefined}>Not Applicable</Option>
                                                {taxDropDown?.map((rec) => {
                                                    return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                                                })}
                                            </Select>
                                        </Form.Item >
                                    </td>
                                    <td>{getLocalFormat(taxTotal?.freightTaxTotal2, costingCurrency)}</td>
                                </tr>
                                <tr>
                                    <th><Form.Item label={'TDS'}></Form.Item></th>
                                    <td>
                                        <Form.Item name={"freightTdsId"}>
                                            <Select
                                                style={{ width: '160px' }}
                                                onChange={(v) => onChangeFreightTds(v, taxTotal?.freightTotalAmt)}
                                                placeholder='Select TDS'
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                            >
                                                <Option value={undefined}>Not Applicable</Option>
                                                {tdsModel?.map((rec) => {
                                                    return <Option value={rec.companyTdsId} key={rec.companyTdsId}>{rec.tdsName}</Option>
                                                })}
                                            </Select>
                                        </Form.Item >
                                    </td>
                                    <td>{getLocalFormat(taxTotal?.freightTdsTotal, costingCurrency)}</td>
                                </tr>
                                <tr>
                                    <th colSpan={2} >
                                        Total Freight Amount
                                    </th>
                                    <td>
                                        <Form.Item name={'freightCostingTotal'}>
                                            <InputNumber addonAfter={suffixSelector2} disabled>
                                            </InputNumber>
                                        </Form.Item>

                                    </td>

                                </tr>

                            </table>

                        </Col>
                    }

                </Row>
                <Button type='primary' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => submitHandler()} style={{ float: 'right' }}>Submit Invoice</Button>
            </Form >
            <Drawer
                open={priceAdjustmentDrawer}
                key={dummyRefresh}
                footer={null}
                width={1000}
                closable={false}

            >
                <QualityPriceAdjustments
                    formInitialValues={initialValues}
                    contractType={contractType}
                    selectedRowData={bargeSelection}
                    setDummyRefresh={setDummyRefresh}
                    commercialProp={"Commercial"}
                    setPriceAdjustmentDrawer={setPriceAdjustmentDrawer}
                    formRef={formRef}
                />
            </Drawer>
        </>
    )
}

export default CmSalePriceAdjustment