import { purchaseSamplingAndSupplierReponseDto, ScqTypicalRequestDto } from '@exportx/shared-models-and-services';
import { SequenceUtils } from '@exportx/ui-utils';
import { Affix, Button, Card, Row, Table } from 'antd';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
interface IPropsQualityBargeSelection {
    samplingData: purchaseSamplingAndSupplierReponseDto[];
    selectedBargeIds: string[];
    setSelectedBargeIds: React.Dispatch<React.SetStateAction<string[]>>;
    setIsEntryFormActivated?: React.Dispatch<React.SetStateAction<boolean>>;
    bargeDataAtUpdateTime?: any;
    setBargeScq?: React.Dispatch<React.SetStateAction<ScqTypicalRequestDto>>
}
export const QualityBargeSelection = (props: IPropsQualityBargeSelection) => {
    const { samplingData, selectedBargeIds, setSelectedBargeIds, setIsEntryFormActivated, bargeDataAtUpdateTime, setBargeScq } = props;
    const { formatMessage: fm } = useIntl();


    useEffect(() => {
        const bgIds = [];
        bargeDataAtUpdateTime?.forEach(rec => {
            bgIds.push(rec.bargeId)
        });
        rowSelection.onChange(bgIds, bargeDataAtUpdateTime)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bargeDataAtUpdateTime])


    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedBargeIds(selectedRowKeys);
            if (selectedRows?.length) {
                setBargeScq((prev) => { return { ...prev, allocationId: selectedRows[0].bnaId } });
            }
        },
        selectedRowKeys: selectedBargeIds
    };

    const commonColumns: any[] = [
        {
            title: fm({ id: "mdm.components.bargeDetails.bargeId", defaultMessage: 'Barge Id' }),
            dataIndex: 'bargeId',
            width: 130,
            align: 'left',
            render: (text, record) => <>
                {SequenceUtils.formatNumberToSpecificLength(record.bargeId)}
            </>
        },
        {
            title: fm({ id: "mdm.components.bargeDetails.allocationId", defaultMessage: 'Allocation Id' }),
            dataIndex: 'allocationId',
            width: 130,
            align: 'left',
        },
        {
            title: fm({ id: "mdm.components.bargeDetails.bargeNomination", defaultMessage: 'Barge Nomination' }),
            dataIndex: 'bargeNomination',
            width: 130,
            align: 'left',
        },
        {
            title: fm({ id: "mdm.components.bargeDetails.supplier", defaultMessage: 'Supplier' }),
            dataIndex: 'supplier',
            width: 130,
            align: 'left',
        },
        {
            title: fm({ id: "mdm.components.bargeDetails.salesQuality", defaultMessage: 'Purchase Quality' }),
            dataIndex: 'quality',
            width: 130,
            align: 'left',
        },
        {
            title: fm({ id: "mdm.components.bargeDetails.bargeQuantity", defaultMessage: 'Barge Quantity' }),
            dataIndex: 'bargeQuantity',
            width: 130,
            align: 'left',
        },
    ];

    return (
        <div>
            <Card
                title={<span style={{ color: 'black' }}>Barges To Create Quality</span>}
            >
                <Table
                    className='contracts'
                    rowSelection={
                        rowSelection
                    }
                    rowKey={record => record.bgId}
                    dataSource={samplingData}
                    columns={[...commonColumns]}
                    pagination={false}
                    bordered />
                <Affix offsetBottom={0} style={selectedBargeIds.length === 0 ? { display: 'none' } : { display: 'inline' }}>
                    <Card bodyStyle={{ padding: '6px 12px' }}>
                        <Row justify="end">
                            <Button
                                onClick={() => setIsEntryFormActivated(true)}
                                type='primary'
                                style={selectedBargeIds.length !== 0 ? { display: 'inline' } : { display: 'none' }}
                            >Next
                            </Button>
                        </Row>
                    </Card>
                </Affix>
            </Card>
        </div>
    )
}

export default QualityBargeSelection