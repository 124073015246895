import { FormInstance, Rule } from 'antd/lib/form';
import { Col, Form, Row, Input, InputNumber, Select, Card, Radio } from 'antd';
import DatePicker from '../../common/data-picker/date-picker'
import { useIntl } from 'react-intl';
import { InputTypes } from '../generic-form/generic-form';
import React from 'react';

const { Option } = Select;
const { TextArea } = Input;


export interface IPropOptionsWithOutPopUp {
    value: string;
    label: string;
}

export interface IFieldWithOutPopUp {
    type: InputTypes,
    placeHolder?: string;
    options?: IPropOptionsWithOutPopUp[];
    otherProps?: any;
    callBack?: (data: any) => void;
}


export interface IPropsFormItemWithOutPopUp {
    label: string;
    name: string;
    validationRules?: Rule[],
    fieldType: IFieldWithOutPopUp,
    hidden?:boolean
}

export interface IPropsGenericFormWithOutPopUp {
    formItems: IPropsFormItemWithOutPopUp[];
    formRef: FormInstance<any>;
    initialValues: any;
    title: string;
    isUpdate: boolean;
    isNewRecord: boolean;
    closeForm: () => void;
    submitForm: () => void;
    clearData: () => void;
    wrapByCard?: boolean;
    children?: React.ReactNode;
    perRowCount?: number;
}

const getInputField = (fieldType: IFieldWithOutPopUp) => {
    if (fieldType.type === InputTypes.Input) {
        return <Input placeholder={fieldType.placeHolder} autoComplete='off' {...fieldType.otherProps} />
    } else if (fieldType.type === InputTypes.InputNumber) {
        return <InputNumber min={0} style={{ width: '100%' }} {...fieldType.otherProps} />
    } else if (fieldType.type === InputTypes.Select) {
        return <Select
            {...fieldType.otherProps}
            placeholder={fieldType.placeHolder}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
            onChange={fieldType?.callBack ? (value) => fieldType.callBack(value) : () => { }}
        >
            {React.Children.toArray([...fieldType?.options].map(item => {
                return <Option value={item.value}>{item.label}</Option>
            }))}
        </Select>
    } else if (fieldType.type === InputTypes.TextArea) {
        return <TextArea aria-multiline {...fieldType.otherProps} />
    } else if (fieldType.type === InputTypes.DatePicker) {
        return <DatePicker style={{ width: '100%' }} {...fieldType.otherProps} />
    } else if (fieldType.type === InputTypes.Radio) {
        return <Radio.Group {...fieldType.otherProps}>
            {React.Children.toArray([...fieldType?.options].map(item => {
                return <Radio value={item.value}>{item.label}</Radio>
            }))}
        </Radio.Group>
    }
}

export const GenericFormWithOutPopUP = (props: IPropsGenericFormWithOutPopUp) => {
    const { title, formRef, initialValues, formItems, } = props;

    const getLayoutSettings = (perRowCount: number = 3) => {
        if (perRowCount === 2) {
            return {
                column1: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 11 },
                    lg: { span: 11 },
                    xl: { span: 11 }
                },
                column2: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 11, offset: 2 },
                    lg: { span: 11, offset: 2 },
                    xl: { span: 11, offset: 2 }
                }
            }
        } else if (perRowCount === 3) {
            return {
                column1: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 7 },
                    lg: { span: 7 },
                    xl: { span: 7 }
                },
                column2: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 7, offset: 1 },
                    lg: { span: 7, offset: 1 },
                    xl: { span: 7, offset: 1 }
                }
            }
        } else if (perRowCount === 4) {
            return {
                column1: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 5 },
                    lg: { span: 5 },
                    xl: { span: 5 }
                },
                column2: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 5, offset: 1 },
                    lg: { span: 5, offset: 1 },
                    xl: { span: 5, offset: 1 }
                }
            }
        } else {
            return {
                column1: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 11 },
                    lg: { span: 11 },
                    xl: { span: 11 }
                },
                column2: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 11, offset: 2 },
                    lg: { span: 11, offset: 2 },
                    xl: { span: 11, offset: 2 }
                }
            }
        }
    }
    const layOutSettings = getLayoutSettings(props.perRowCount);
    const getWrappedByCard = () => {
        return (<>
            <Form layout="vertical" form={formRef} initialValues={initialValues} autoComplete="off" >
                {React.Children.toArray(formItems.map((formItem, index) => {
                    if (index % 3 === 0) {
                        return (
                            <Col
                                {...layOutSettings.column1}
                            >
                                <Form.Item
                                    label={formItem.label}
                                    name={formItem.name}
                                    rules={formItem.validationRules}
                                    hidden={formItem.hidden}
                                >
                                    {
                                        getInputField(formItem.fieldType)
                                    }
                                </Form.Item>
                            </Col>
                        );
                    } else {
                        return (
                            <Col
                                {...layOutSettings.column2}
                            >
                                <Form.Item
                                    label={formItem.label}
                                    name={formItem.name}
                                    rules={formItem.validationRules}
                                    hidden={formItem.hidden}
                                >
                                    {
                                        getInputField(formItem.fieldType)
                                    }
                                </Form.Item>
                            </Col>
                        );
                    }
                }).reduce((r, element, index2) => {
                    index2 % 3 === 0 && r.push([]);
                    r[r.length - 1].push(element);
                    return r;
                }, []).map((rowContent) => {
                    return <Row>{rowContent}</Row>;
                }))}
            </Form>
            {props?.children}
        </>)
    }
    if (props.wrapByCard) {
        return (<Card
            title={title}
        >
            {getWrappedByCard()}
        </Card>)
    }
    return (
        getWrappedByCard()
    )
}

export default GenericFormWithOutPopUP;