import { BargeSurveyorDto } from '@exportx/shared-models-and-services';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react'

interface IBargeSurveyorProps {
    bargeSurveyor: BargeSurveyorDto;
    surveyorDropDownData:any
   
}
export const BargeSurveyorDetailsView = (props: IBargeSurveyorProps) => {
    const [surveyorData, setSurveyorData] = useState([]);
    const {surveyorDropDownData} = props
   
    useEffect(() => {
        if (props. bargeSurveyor) {
            setSurveyorData([
                {
                    surveyorType: 'Supplier',
                    surveyorName: surveyorDropDownData.filter((e) => props. bargeSurveyor?.supplierSurveyor == e.bpId)[0]?.bpName,
                    analysisType: props. bargeSurveyor?.supplierSurveyorAnalysisType
                },
                {
                    //TODO:change dynamic
                    surveyorType: 'Internal',
                    surveyorName:surveyorDropDownData.filter((e) => props. bargeSurveyor?.internalSurveyor == e.bpId)[0]?.bpName,
                    analysisType: props. bargeSurveyor?.internalSurveyorAnalysisType
                },
                 {
                    surveyorType: 'WithNess',
                    surveyorName:  surveyorDropDownData.filter((e) => props. bargeSurveyor?.witnessSurveyor == e.bpId)[0]?.bpName,
                    analysisType: props. bargeSurveyor?.witnessAnalysisType
                },
                {
                    surveyorType: 'Additional Surveyor',
                    surveyorName:   surveyorDropDownData.filter((e) => props. bargeSurveyor?.additionalSurveyor == e.bpId)[0]?.bpName,
                    analysisType: props. bargeSurveyor?.additionalAnalysisType
                },
                {
                    surveyorType: "additionalSurveyor1",
                    surveyorName:    surveyorDropDownData.filter((e) => props. bargeSurveyor?.additionalSurveyor1 == e.bpId)[0]?.bpName,
                    analysisType: props. bargeSurveyor?.additionalAnalysisType1
                },
            ]);
        }
    }, [props.bargeSurveyor])


    const surveyor: ColumnsType<any> = [
        {
            title: 'Surveyor Type',
            dataIndex: 'surveyorType',
            key: 'surveyorType',
            
        },
        {
            title: 'Surveyor Name',
            dataIndex: 'surveyorName',
            key: 'surveyorName',
        },
        {
            title: 'Surveyor Analysis Type',
            dataIndex: 'analysisType',
            key: 'analysisType',
        },
        
    ];
    return (
        <div><Table columns={surveyor} dataSource={surveyorData} pagination={false} bordered /><br></br></div>
    )
}

export default BargeSurveyorDetailsView