import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ContractModesEnum, PurchaseSupplierReportDto, SamplingAndAnalysisService, SamplingSampleTypeReqDto, SamplingTypes } from '@exportx/shared-models-and-services';
import { SequenceUtils } from '@exportx/ui-utils';
import { Button, Divider, Input, Popconfirm, Table, Tooltip } from 'antd';
import Link from 'antd/es/typography/Link';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, useAuthState } from '../../../../common';


interface IActualReportProps {
    sampleType?: SamplingTypes
    contractType: ContractModesEnum
}

export const ActualReport = (props: IActualReportProps) => {
    const { formatMessage: fm } = useIntl();
    const { sampleType, contractType } = props
    const [purchaseSupplier, setPurchaseSupplier] = useState<PurchaseSupplierReportDto[]>([])
    const { authContext } = useAuthState()
    const [searchedText, setSearchedText] = useState("")
    const mapToGroupByGroupId = new Map<number, any[]>();
    const navigate = useNavigate();
    let pathToreDirect = '/barge-detail-view'

    const qualityService = new SamplingAndAnalysisService();
    useEffect(() => {
        getPurchaseSamplingReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [])

    const getPurchaseSamplingReports = () => {
        const req = new SamplingSampleTypeReqDto(sampleType, authContext.defaultPlant, contractType);
        qualityService.getPurchaseSamplingReports(req).then(res => {
            if (res.status) {
                setPurchaseSupplier(res.data)
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const redirectToFullView = (bargeId: string) => {
        navigate(pathToreDirect, { state: { bargeId } });
    }

    const columns: any = [

        {
            title: 'Barge Id',
            dataIndex: 'bargeNumber',
            key: 'bid',
            fixed: 'left',
            width: '100px',
            render: (text, record) => {
                const link = `/#/barge-detail-view?barge_id=${record?.bargeId}`;
                return <>
                    {/* <Link className="link-primary" onClick={() => { redirectToFullView(record?.bargeId); }}>
                        {SequenceUtils.formatNumberToSpecificLength(record.bargeNumber.toString())}
                    </Link> */}
                     <a href={link} className="link-primary">
            {SequenceUtils.formatNumberToSpecificLength(record?.bargeNumber)}
          </a>
                </>
            },
            sorter: (a, b) => a.bargeId !== null ? a.bargeId.localeCompare(b.bargeId) : "Have Null Values",
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                const aaa = new Set(Object.keys(record).map((key) => {
                    return String(record[key]).toLowerCase().includes(value.toLocaleString())
                }))
                if (aaa.size && aaa.has(true))
                    return true;
                else
                    return false;
            },

        },
        {
            title: 'Barge Name',
            dataIndex: 'bargeNomination',
            key: 'bargeNomination',
            fixed: 'left',
            width: '180px',
        },
        {
            title: 'Business Number',
            dataIndex: 'businessNo',
            key: 'bn',
            fixed: 'left',
            width: '180px',
        },
        {
            title: `${contractType === ContractModesEnum.SALES ? "SALES CONTRACT" : "PURCHASE  CONTRACT"}`,
            dataIndex: 'purchaseContractNo',
            key: 'pcs',
            fixed: 'left',
            width: '180px',
        },
        {
            title: `${contractType === ContractModesEnum.SALES ? "VENDOR" : "SUPPLIER"}`,
            dataIndex: 'supplierName',
            key: 'splr',
            width: '180px',
        },
        {
            title: 'Port Type',
            dataIndex: 'portType',
            key: 'bas',
            width: '80px',
        },
        {
            title: 'Method',
            dataIndex: 'method',
            width: '80px',
        },
        {
            title: 'Basis',
            dataIndex: 'cvBasis',
            key: 'bas',
            width: '80px',
        },

        {
            title: 'GROSS CALORIFIC VALUE',
            children: [
                {
                    title: 'Typical',
                    key: 'typ',
                    width: '80px',
                    dataIndex: 'cvTypical',
                },
                {
                    title: 'Rejection',
                    key: 'rej',
                    width: '80px',
                    dataIndex: 'cvRejection',
                },
                {
                    title: 'Actual',
                    key: 'actl',
                    width: '80px',
                    dataIndex: 'cvActual',
                },
            ],
        },
        {
            title: 'ASH (%)',
            children: [
                {
                    title: 'Typical',
                    key: 'typ',
                    width: '80px',
                    dataIndex: 'ashTypical',
                },
                {
                    title: 'Rejection',
                    key: 'rej',
                    width: '80px',
                    dataIndex: 'ashRejection',
                },
                {
                    title: 'Actual',
                    key: 'actl',
                    width: '80px',
                    dataIndex: 'ashActual',
                },
            ],
        },
        {
            title: 'TOTAL SULPHUR (%)',
            children: [
                {
                    title: 'Typical',
                    key: 'typ',
                    width: '80px',
                    dataIndex: 'tsTypical',
                },
                {
                    title: 'Rejection',
                    key: 'rej',
                    width: '80px',
                    dataIndex: 'tsRejection',
                },
                {
                    title: 'Actual',
                    key: 'actl',
                    width: '80px',
                    dataIndex: 'tsActual',
                },
            ],
        },
        {
            title: 'TOTAL MOISTURE (%)',
            children: [
                {
                    title: 'Typical',
                    key: 'typ',
                    width: '80px',
                    dataIndex: 'tmTypical',
                },
                {
                    title: 'Rejection',
                    key: 'rej',
                    width: '80px',
                    dataIndex: 'tmRejection',
                },
                {
                    title: 'Actual',
                    key: 'actl',
                    width: '80px',
                    dataIndex: 'tmActual',
                },
            ],
        },
        {
            title: 'INHERENT MOISTURE (%)',
            children: [
                {
                    title: 'Typical',
                    key: 'typ',
                    width: '80px',
                    dataIndex: 'inmTypical',
                },
                {
                    title: 'Rejection',
                    key: 'rej',
                    width: '80px',
                    dataIndex: 'inmRejection',
                },
                {
                    title: 'Actual',
                    key: 'actl',
                    width: '80px',
                    dataIndex: 'inmActual',
                },
            ],
        },
        {
            title: 'VOLATILE MOISTURE (%)',
            children: [
                {
                    title: 'Typical',
                    key: 'typ',
                    width: '80px',
                    dataIndex: 'vmTypical',
                },
                {
                    title: 'Rejection',
                    key: 'rej',
                    width: '80px',
                    dataIndex: 'vmRejection',
                },
                {
                    title: 'Actual',
                    key: 'actl',
                    width: '80px',
                    dataIndex: 'vmActual',
                },
            ],
        },
        {
            title: 'FIXED CARBON (%)',
            children: [
                {
                    title: 'Typical',
                    key: 'typ',
                    width: '80px',
                    dataIndex: 'fcTypical',
                },
                {
                    title: 'Rejection',
                    key: 'rej',
                    width: '80px',
                    dataIndex: 'fcRejection',
                },
                {
                    title: 'Actual',
                    key: 'actl',
                    width: '80px',
                    dataIndex: 'fcActual',
                },
            ],
        },
        {
            title: 'FUEL RATIO (FC/VM)',
            children: [
                {
                    title: 'Typical',
                    key: 'typ',
                    width: '80px',
                    dataIndex: 'frTypical',
                },
                {
                    title: 'Rejection',
                    key: 'rej',
                    width: '80px',
                    dataIndex: 'frRejection',
                },
                {
                    title: 'Actual',
                    key: 'actl',
                    width: '80px',
                    dataIndex: 'frActual',
                },
            ],
        },
        {
            title: 'HGI',
            children: [
                {
                    title: 'Typical',
                    key: 'typ',
                    width: '80px',
                    dataIndex: 'hgTypical',
                },
                {
                    title: 'Rejection',
                    key: 'rej',
                    width: '80px',
                    dataIndex: 'hgRejection',
                },
                {
                    title: 'Actual',
                    key: 'actl',
                    width: '80px',
                    dataIndex: 'hgActual',
                },
            ],
        },
        {
            title: 'Action',
            fixed: 'right',
            width: '80px',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (text, rowData) => {
                return <span>
                    <Tooltip placement="topRight" title="Edit">
                        <EditOutlined type="edit"
                            onClick={() => { editClickHandler(rowData) }}
                            style={{ color: '#1890ff', fontSize: '18px' }}
                        /></Tooltip>

                    <Divider type="vertical" />
                    <Popconfirm
                        onConfirm={e => {
                            deletePurchaseSamplingSupplier(rowData?.actualGroupId)
                        }}
                        title={fm({ id: "common.deleteConfirmMsg", defaultMessage: 'Are you sure to Delete Record ?' })}>
                        <Tooltip placement="topRight" title="Delete">
                            <DeleteOutlined type='delete' onClick={() => { }} style={{ color: 'red', fontSize: '18px' }} />
                        </Tooltip>
                    </Popconfirm>
                </span>
            }
        },
    ]

    const deletePurchaseSamplingSupplier = (actualGroupId: string) => {
        qualityService.deletePurchaseSamplingSupplier({ groupId: actualGroupId, plantCode: authContext.defaultPlant, contractType: contractType }).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getPurchaseSamplingReports();
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message))
    }

    const editClickHandler = (rowData) => {
        const qty = mapToGroupByGroupId.get(Number(rowData.actualGroupId));
        let pathToRedirect;
        if (sampleType === SamplingTypes.SUPPLIER) {
            pathToRedirect = 'supplier-update'
        } else if (sampleType === SamplingTypes.ACTUAL) {
            pathToRedirect = 'actual-update'
        } else if (sampleType === SamplingTypes.INTERNAL) {
            pathToRedirect = 'company-update'
        } else if (sampleType === SamplingTypes.PRE_SHIPMENT) {
            pathToRedirect = 'shipment-update'
        }
        navigate(`/${contractType === ContractModesEnum.PURCHASE ? "purchase" : "sales"}-sampling/` + pathToRedirect, { state: { qualityData: qty } });
    }

    const processQualityData = (qualityData: PurchaseSupplierReportDto[]) => {
        const returnArray = []
        qualityData.forEach(rec => {
            if (!mapToGroupByGroupId.has(Number(rec.actualGroupId)))
                mapToGroupByGroupId.set(Number(rec.actualGroupId), [{ ...rec, rowSpan: 1 }])
            else {
                const existingData: any = mapToGroupByGroupId.get(Number(rec.actualGroupId));
                existingData[0].rowSpan = existingData.length + 1;
                mapToGroupByGroupId.set(Number(rec.actualGroupId), [...existingData, rec]);
            }
        })
        for (const a of new Map([...mapToGroupByGroupId].sort((a, b) => b[0] - a[0])).values()) {
            returnArray.push(...a);
        }
        console.log(returnArray)
        return qualityData;
    }

    return (
        <div>
            <Button type='primary' style={{ float: 'left' }}>Load More</Button>
            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} style={{ width: 200, float: "right" }} />
            <Table bordered columns={columns} scroll={{ x: 1500 }} dataSource={processQualityData(purchaseSupplier)} />

        </div>
    )
}

export default ActualReport;