import { AxiosInstance, UsersService } from '@exportx/shared-models-and-services';
import { useState } from 'react';
import AppRoutes from './app-routes';
import { LoadingSpinner, LoginComponent, logout, useAuthState } from './components/common';
import bgImage from './images/img1.jpg'

function App() {
  let counter = 0;
  const [load, setLoad] = useState(false);
  const { authContext, dispatch } = useAuthState();
  const service = new UsersService();

  AxiosInstance.interceptors.request.use(request => {
    counter++;
    if (!request['loadstatus']) {
      setLoad(true);
    }
    request.withCredentials = true;
    return request;
  });

  AxiosInstance.interceptors.response.use(async response => {
    counter--;
    if (counter === 0) {
      setLoad(false);
      if (response.data.statusCode === 403 || response.data.statusCode === 401 || response.data.statusCode === 404 || response.data.statusCode === 500) {
        if (response.data.statusCode === 401) {
          if (!response.config.url.includes('refreshJwtAccessToken')) {
            await service.refreshJwtAccessToken({ username: authContext.user.username, password: `SaiResources${Math.random()}` })
            return AxiosInstance(response.config);
          }
        } else {
          // props.history.push(`/${response.data.statusCode}`);
        }
      }
    }
    return response;
  }, async (error) => {
    counter--;
    if (counter === 0)
      setLoad(false);
    if (error.response.status === 401) {
      if (!error.config.url.includes('refreshJwtAccessToken')) {
        await service.refreshJwtAccessToken({ username: authContext.user.username, password: `SaiResources${Math.random()}` })
        return AxiosInstance(error.config);
      } else {
        logout(dispatch);
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  })

  return (authContext.isAuthenticated ?  <>
    <LoadingSpinner loading={load} />
    <AppRoutes />
  </> :
    <div style={{
      backgroundColor: 'white',
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover', // Image will be contained within the background
      backgroundRepeat: 'no-repeat', // Prevent image repetition
      backgroundPosition: 'center', // Center the image horizontally and vertically
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <LoginComponent />
    </div>
  );
}

export default App;
