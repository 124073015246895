import { ContractTypesEnum, PurchaseTermDataDto, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';

export interface ISizeDistributionData {
    purchaseTermData: PurchaseTermDataDto,
    specType: SpecTypesEnum;
    snapShotPurchaseTermData: PurchaseTermDataDto;
    contractType: ContractTypesEnum;
    snapSpecType: SpecTypesEnum;
}
const SizeDistributionDataView = (props: ISizeDistributionData) => {
    const { contractType } = props;
    const [sizeDistributionData, setSizeDistributionData] = useState([]);
    const [addendumSizeDistributionData, setAddendumSizeDistributionData] = useState([]);

    useEffect(() => {
        if (props.purchaseTermData)
            setAddendumSizeDistributionData(constructDataSource(props.purchaseTermData, props.specType));
        if (props.snapShotPurchaseTermData)
            setAddendumSizeDistributionData(constructDataSource(props.snapShotPurchaseTermData, props.snapSpecType));
    }, [props.purchaseTermData]);

    const constructDataSource = (purchaseTermData: PurchaseTermDataDto, specType: SpecTypesEnum) => {
        let data = specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData
        let dataSource = [];
        dataSource.push({
            name: 'Size Distribution (%)',
            typical: '-',
            rejection: '-',
        });

        dataSource.push({
            name: data?.sizeDist1Name ? data?.sizeDist1Name : '-',
            typical: data?.sizeDist1Typical ? data?.sizeDist1Typical : '-',
            rejection: data?.sizeDist1Rejection ? data?.sizeDist1Rejection : '-',
        });

        dataSource.push({
            name: data?.sizeDist2Name ? data?.sizeDist2Name : '-',
            typical: data?.sizeDist2Typical ? data?.sizeDist2Typical : '-',
            rejection: data?.sizeDist2Rejection ? data?.sizeDist2Rejection : '-',
        });

        dataSource.push({
            name: data?.sizeDist3Name ? data?.sizeDist3Name : '-',
            typical: data?.sizeDist3Typical ? data?.sizeDist3Typical : '-',
            rejection: data?.sizeDist3Rejection ? data?.sizeDist3Rejection : '-',
        });

        dataSource.push({
            name: data?.sizeDist4Name ? data?.sizeDist4Name : '-',
            typical: data?.sizeDist4Typical ? data?.sizeDist4Typical : '-',
            rejection: data?.sizeDist4Rejection ? data?.sizeDist4Rejection : '-',
        });

        dataSource.push({
            name: data?.sizeDist5Name ? data?.sizeDist5Name : '-',
            typical: data?.sizeDist5Typical ? data?.sizeDist5Typical : '-',
            rejection: data?.sizeDist5Rejection ? data?.sizeDist5Rejection : '-',
        });
        return dataSource;
    }
    const sharedOnCell = (_: any, index: number) => {
        if ([0].includes(index)) {
            return { colSpan: 0 };
        }
        return {};
    };

    const valiDateWithSnapShotData = (index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = addendumSizeDistributionData?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM && valueAtGivenKey !== '-') {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={''}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }
    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            onCell: (_, index) => ({
                colSpan: [0].includes((index as number)) ? 3 : 1,
            }),
            render: (value, row: any, index) => (
                <a>{valiDateWithSnapShotData(index, 'name', value)}</a>
            ),
        },
        {
            title: 'Typical',
            key: 'typical',
            dataIndex: 'typical',
            onCell: sharedOnCell,
            render: (value, row: any, index) => (
                <a>{valiDateWithSnapShotData(index, 'typical', value)}</a>
            ),
        },
        {
            title: 'Rejection',
            key: 'rejection',
            dataIndex: 'rejection',
            onCell: sharedOnCell,
            render: (value, row: any, index) => (
                <a>{valiDateWithSnapShotData(index, 'rejection', value)}</a>
            ),
        }
    ];

    const rowClassName = (row: any, index) => {
        return index % 2 === 0 ? 'row-even' : 'row-odd'; 
      };

    return (
        <Table columns={columns} dataSource={sizeDistributionData} rowClassName={rowClassName} pagination={false} className="contracts-PO-List" />
    )
}

export default SizeDistributionDataView;