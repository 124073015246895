
import './company-grid.css';
import { CompanyDto, CompanyService, companyUpdateRequest } from '@exportx/shared-models-and-services';
import { ColumnProps } from 'antd/lib/table';
import { useIntl } from 'react-intl';
import { AlertMessages, FilterableGrid, isPermissionExist, useAuthState } from '../../../../../common';
import { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import React from 'react';
// import { CompanyService } from '@brandix-apps/shared-services/plant-management';

export interface ICompanyGridProps {
}

export const CompanyGrid = (props: ICompanyGridProps) => {
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const { authContext } = useAuthState();
  const [companiesData, setCompaniesData] = useState<CompanyDto[]>([])

  const service = new CompanyService();

  useEffect(()=>{
    if(!isPermissionExist([11])) {
        return navigate('/');
    }
},[])

  useEffect(() => {
    getAllCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * To Get All The Companies Data 
   */
  const getAllCompanies = () => {
    service.getAllCompanies().then(res => {
      if (res.status) {
        setCompaniesData(res.data);
      } else {
        if (res.errorCode) {
          setCompaniesData([]);
          // AlertMessages.getErrorMessage(fm({ id: `pms.errorCodes.${res.errorCode}` }));
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setCompaniesData([]);
    })
  }
  /**
 * To Delete Selected Record
 * @param CompanyViewData 
 */
  const deleteCompany = (CompanyViewData: CompanyDto) => {
    const CompanyModel = new companyUpdateRequest();
    CompanyModel.companyCode = CompanyViewData.companyCode;
    CompanyModel.versionFlag = CompanyViewData.versionFlag;
    CompanyModel.updatedUser = authContext.user.userName;
    service.ActivatedeActivateCompany(CompanyModel).then(res => {
      if (res.status) {
        getAllCompanies();
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);

        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const sampleTypeColumns: ColumnProps<any>[] = [

    {
      title: fm({
        id: 'pms.components.companyGrid.companyCode',
        defaultMessage: 'Company Code'
      }),
      dataIndex: 'companyCode',
      //align: 'center',
      render: (value: string) => (<div style={{ textAlign: 'left' }}>{value}</div>)
    },
    {
      title: fm({
        id: 'pms.components.CompanyGrid.companName',
        defaultMessage: 'Company Name'
      }),
      dataIndex: 'companyName',
      //align: 'center',
      render: (value: string) => (<div style={{ textAlign: 'left' }}>{value}</div>)
    }
  ];
  const view = (companyData: CompanyDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (companyData) {
      isNewRecord = false;
    }
    let pathToreDirect = '/createCompany';
    navigate(pathToreDirect, { state: { companyData, isNewRecord, isUpdate } });
  }

  return (
    <Card
      title={<span>{fm({ id: 'mdm.components.companyForm.header', defaultMessage: 'Company Details' })}</span>}
      style={{ overflow: 'auto' }}
      headStyle={{ backgroundColor: '#1ad1ff', border: 0 }}
      extra={<>{isPermissionExist([10]) && (
        <Button
          // type='primary'
          onClick={() => view(undefined, false)}
        >
          {fm({ id: 'common.create', defaultMessage: 'Create' })}
        </Button>)}</>
      }
      bodyStyle={{ overflow: 'auto' }}
      className='default-card-class'
    >
      <FilterableGrid
        rowKey='companyCode'
        mainColumns={sampleTypeColumns}
        data={companiesData}
        filterColumnsData={[
          {
            dataIndex: 'companyCode',
            placeHolder: fm({ id: 'pms.components.companyGrid.companyCode', defaultMessage: 'Company Code' }),
          },
          {
            dataIndex: 'companyName',
            placeHolder: fm({
              id: 'pms.components.CompanyGrid.companyName',
              defaultMessage: 'Company Name'
            })
          }
        ]}
        otherTableProps={{ bordered: true }} activateOrDeactivate={deleteCompany} view={view} />
    </Card>
  );

};