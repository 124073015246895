import { AllocatedTypeEnum, GetAllBargeMappingDataDto, ResponsePersonEnum } from "@exportx/shared-models-and-services"
import { Card, Row, Col, Switch, Input, Descriptions, Select, Form, InputNumber } from "antd"
import { useEffect, useState } from "react";


const { Option } = Select;
interface IPropsReDraft {
    record: GetAllBargeMappingDataDto;
    index: number;
    formValuesData: Map<string, any>;
    setFormValuesData: React.Dispatch<React.SetStateAction<Map<string, any>>>
}
export const RedraftForm = (props: IPropsReDraft) => {
    const { record, formValuesData, setFormValuesData } = props;
    const [allocationType, setAllocationType] = useState<any>(true);
    const [availableQuantity, setAvailableQuantity] = useState<number>();
    const [defaultQuantity, setDefaultQuantity] = useState<number>(0);
    const [redraftForm] = Form.useForm();
    let key = record.id + '$@$' + record.allocationUId;
    const defaultValue = formValuesData?.get(key);

    useEffect(() => {
        let allocation: any = true;
        if (formValuesData?.get(key)?.allocationType) {
            if (formValuesData?.get(key)?.allocationType === AllocatedTypeEnum.FULL) {
                allocation = true;
            } else {
                allocation = false;
            }
            setDefaultQuantity(Number(defaultValue.quantity));
        } else {
            if (typeof (formValuesData?.get(key)?.allocationType) === "boolean") {
                allocation = false;
                setDefaultQuantity(Number(defaultValue.quantity))
            } else {
                allocation = true;
            }
        }
        setAllocationType(allocation);
        defaultValue.allocationType = allocation;
        defaultValue.availableQuantity = Number(record?.availableQuantity);
        defaultValue.totalQuantity = Number(record?.bargeQuantityInMt);
        defaultValue.bargeQuantityInMt = Number(record?.bargeQuantityInMt);
        setAvailableQuantity(record.availableQuantity);
    }, [])

    const onValuesChangeHandler = (changedValues: any, allValues: any) => {
        const changedValueKeys = Object.keys(changedValues);
        if (changedValueKeys.includes('quantity')) {
            const totalAvailableQty = Number(record?.availableQuantity) + defaultQuantity;
            const value = Number(changedValues['quantity'])
            let result = totalAvailableQty - value;
            console.log(result)
            redraftForm.setFieldValue('availableQuantity', result.toFixed(3));
            setAvailableQuantity(result);
            if (value > totalAvailableQty) {
                redraftForm.setFieldValue('quantity', defaultQuantity);
                redraftForm.setFieldValue('availableQuantity', record?.availableQuantity);
                setAvailableQuantity(Number(record?.availableQuantity))
            }
        }

        if (changedValueKeys.includes('redraftQuantity')) {
            const redraftQuantity = Number(changedValues['redraftQuantity']);
            const finalQty = (allValues['allocationType'] === true || allValues['allocationType'] === AllocatedTypeEnum.FULL) ? Number(record?.bargeQuantityInMt) : Number(allValues['quantity']);

            const rdQty = (redraftQuantity - finalQty);
            redraftForm.setFieldValue('quantityDifference', rdQty.toFixed(3));
        }

        if (changedValueKeys.includes('allocationType')) {
            setAllocationType(changedValues['allocationType']);
            if ((changedValues['allocationType'] === true || changedValues['allocationType'] === AllocatedTypeEnum.FULL)) {
                redraftForm.setFieldValue('quantity', Number(record?.bargeQuantityInMt));
                redraftForm.setFieldValue('availableQuantity', 0);
            }
        }
        formValuesData.set(key, { ...defaultValue, ...allValues })
        setFormValuesData(formValuesData);
    }

    return (
        <Card>
            <Form form={redraftForm} initialValues={{ ...defaultValue }} onValuesChange={onValuesChangeHandler} autoComplete='off' layout='vertical'>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                        <Form.Item name={"allocationType"} label="Allocated Type" initialValue={allocationType} >
                            <Switch
                                checkedChildren={`${AllocatedTypeEnum.FULL}`}
                                unCheckedChildren={`${AllocatedTypeEnum.PARTIAL}`}
                                checked={allocationType}
                            />
                        </Form.Item>
                    </Col>
                    {!allocationType && <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                        <Form.Item name={'quantity'} label='Quantity'>
                            <Input type={'number'} placeholder='Quantity' autoComplete="off" min={0} />
                        </Form.Item>
                    </Col>}
                    {!allocationType && <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                        <Descriptions layout="vertical">
                            <Descriptions.Item label="Available Quantity">{Number(availableQuantity)?.toFixed(3)}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    }
                </Row>
                <h6>Redraft Details</h6>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                        <Form.Item name={'redraftQuantity'} label='Redraft Quantity' >
                            <Input autoComplete="off" type={'number'} placeholder='Redraft Quantity' />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name={'redraftParty'} label={'Redraft Party'}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                allowClear showSearch placeholder='Select Redraft Party'>
                                <Option value={''}>Please Select</Option>
                                {Object.keys(ResponsePersonEnum).map((rec) => {
                                    return <Option value={ResponsePersonEnum[rec]}>{ResponsePersonEnum[rec]}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                        <Form.Item name={'quantityDifference'} label='Quantity Difference' >
                            <Input disabled={true} autoComplete="off" placeholder='Quantity Difference' type={'number'} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                        <Form.Item name={'quantityDifferenceAccount'} label={'Quantity Difference Account'}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                allowClear showSearch placeholder='Select Quantity Party' style={{}}>
                                <Option value={''}>Please Select</Option>
                                {Object.values(ResponsePersonEnum).filter(rec => { return rec !== ResponsePersonEnum.BUYER }).map(rec => {
                                    return <Option value={rec}>{rec}</Option>
                                })}
                            </Select>

                        </Form.Item>

                    </Col>
                </Row>
                <Row >
                    <Col>
                        <Form.Item name={'availableQuantity'} style={{ display: 'none' }}>
                            <InputNumber type={'hidden'} />
                        </Form.Item>
                        <Form.Item name={'bargeQuantityInMt'} hidden>
                            <InputNumber type={'hidden'} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

export default RedraftForm;