import { AnchorageCreateDto, AnchorageService, BargeAgreementService, BargeDropDownDto, BargeVendorIdReqDto, PriceTypeEnum, YESOrNoRadioEnum, ResponsePersonEnum, VatIncludeExclude } from '@exportx/shared-models-and-services';
import { BargeService, BpCategoryGetDto, BusinessPartnerService, BusinessPartnerTypeEnum, ContractIdRequest, CurrencyService, PurchaseContractService, PurchaseDropDownDto } from '@exportx/shared-models-and-services';
import { SequenceUtils } from '@exportx/ui-utils';
import { Card, Col, Descriptions, Form, FormInstance, Input, Modal, Radio, RadioChangeEvent, Row, Select, Table } from 'antd'
import FormItem from 'antd/es/form/FormItem';
import { FormLayout } from 'antd/lib/form/Form';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../common';
import { NewModal } from './purchaseContractModal';

interface IBargeDetailsProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
  setPcQualitiesData: (values) => void;
  setBargeIdSelection?: (rowSelectedData: any) => void;
  setPcTypeId?: (values) => void;
  setBargeOrderData?: any;
  Action?: string
}

const { Option } = Select;

export const BargeDetails = (props: IBargeDetailsProps) => {
  const { authContext } = useAuthState();
  const { formRef, layoutType, initialValues, setBargeIdSelection, setBargeOrderData, Action } = props;
  const { formatMessage: fm } = useIntl();
  const [priceType, setPriceType] = useState<any>();
  const [vendorsData, setVendorsData] = useState<any[]>([]);
  const [selectedBargeVendor, setSelectedBargeVendor] = useState();
  const [bargingAgreementsModalVisible, setBargingAgreementsModalVisible] = useState(false);
  const [bargeAgreementsGridData, setBargeAgreementsGridData] = useState<any[]>([]);
  const [vat, setVat] = useState<any>();
  const [purchases, setPurchases] = useState<PurchaseDropDownDto[]>([]);
  const [loadedQuantity,] = useState<number>(0);
  const [returnQuantity, setReturnQuantity] = useState<any>(false);
  const [bargeData, setBargeData] = useState<BargeDropDownDto[]>([]);
  const [currencies, setCurrencies] = useState<any[]>([]);
  const currencyService = new CurrencyService();
  const [showModal, setShowModal] = useState<boolean>(false);
  const purchaseService = new PurchaseContractService();
  const [qualityData, setQualityData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [pcqId, setPcqId] = useState<any>();
  const [anchorageData, setAnchorageData] = useState<AnchorageCreateDto[]>([]);
  const [othersChanges, setOthersChanges] = useState(false);
  const [rowSelectionChangedValue, setRowSelectionChangedValue] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pcTypeData, setPcTypeData] = useState<any>();
  const [values, setValues] = useState("");
  const [refreshPCSelectModal, setRefreshPCSelectModal] = useState<number>(1);


  const anchorageService = new AnchorageService();
  const bargeAgreementService = new BargeAgreementService()
  const purchase = new PurchaseContractService();
  const service = new BargeService();
  const businessPartnerService = new BusinessPartnerService();

  const onPriceTypeChange = (basePriceValue: PriceTypeEnum) => {
    setPriceType(basePriceValue);
    if (basePriceValue === PriceTypeEnum.LUMP_SUM) {
      formRef.setFieldValue('bargingBasePrice', undefined)
    }
    if (basePriceValue === PriceTypeEnum.PER_MT) {
      formRef.setFieldValue('lumpSumBasePrice', undefined)
    }

  }

  useEffect(() => {
    if (pcTypeData?.[0]?.purchaseType !== "FOB BARGE" || initialValues?.purchaseType !== "FOB BARGE") {
      formRef.setFieldValue('bargingBasePrice', 0);
    }
  }, [pcTypeData])



  if (initialValues?.bargePlannedQuantityInMt) {
    formRef.setFieldValue('bargePlannedQuantityInMt', initialValues?.bargePlannedQuantityInMt)
  }

  if (initialValues) {
    initialValues.blDate = initialValues?.blDate ? moment(initialValues?.blDate, 'YYYY-MM-DD') : undefined;
  }

  useEffect(() => {
    if (initialValues) {
      initialValues.pph5 = initialValues?.pph5
      formRef.setFieldsValue(initialValues);
      setReturnQuantity(Number(initialValues?.returnCargoQuantityInMt) > 0);
      if (initialValues.others == '0') {
        setRowSelectionChangedValue(1);
      } else {
        setRowSelectionChangedValue(undefined);
      }
    }
  }, [initialValues])


  const suffixSelector = (name: string) => (
    <Form.Item name={name} noStyle label="Please Select" rules={[
      {
        required: true, message: 'Select Currency'
      }
    ]}>
      <Select allowClear showSearch style={{ width: 70 }}>
        {currencies.map(item => {
          return <Option value={item.currencyCode}>{item.currencyName}</Option>
        })}
      </Select>
    </Form.Item>
  );

  useEffect(() => {
    let bargingPriceValue: PriceTypeEnum = initialValues?.bargingType
    setPriceType(bargingPriceValue)
    setPcqId(initialValues?.purchaseContract)

  }, [initialValues?.bargingType]);

  useEffect(() => {
    getAllBusinessPartnersForDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR));
    getAllPurchaseContracts();
    getAllBargeDropDown();
    getCurrencyData();
    getAllAnchorages();
  }, []);

  const getAllBusinessPartnersForDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
    businessPartnerService.getAllBargeVendorsDropDown(bpCategoryGetDto).then(res => {
      if (res.status) {
        if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.VENDOR)
          setVendorsData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getAllPurchaseContracts = () => {
    purchase.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setPurchases(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getAllBargeDropDown = () => {
    service.getAllBargeDropDown({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        if (initialValues && initialValues.id) {
          const bargeId = initialValues.id;
          setBargeData(res.data.filter(rec => rec.id != bargeId));
        } else {
          setBargeData(res.data);
        }

      } else {
        if (res.errorCode) {
          setBargeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setBargeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getAllAnchorages = () => {
    anchorageService.getAllAnchorages().then(res => {
      if (res.status) {
        setAnchorageData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getQualityDetailsByPcId = (pcId: string) => {
    const req = new ContractIdRequest(pcId, authContext.defaultPlant);
    purchaseService.getQualityDetailsByPcId(req).then(res => {
      if (res.status) {
        setQualityData(res.data)
      }
    }).catch(err => {
      console.log(err.message);
    })
  }




  const handleReturnCargoQuantityInMt = () => {
    const bargeQuantityInMt = formRef.getFieldValue('bargeQuantityInMt') ?? 0;
    const returnCargoQuantityInMt = formRef.getFieldValue('returnCargoQuantityInMt') ? formRef.getFieldValue('returnCargoQuantityInMt') : 0;
    const totalValue = Number(bargeQuantityInMt) - Number(returnCargoQuantityInMt);
    const deadFreight = formRef.getFieldValue('deadFreight');
    formRef.setFieldValue('loadedQuantityInMt', totalValue);
    const incurredValidation = Number(deadFreight) - Number(bargeQuantityInMt)
    if (incurredValidation < 0) {
      formRef.setFieldValue('deadFreightIncurred', 0);
    } else {
      formRef.setFieldValue('deadFreightIncurred', incurredValidation);
    }
    setReturnQuantity(Number(returnCargoQuantityInMt) > 0);
  }


  const getCurrencyData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrencies(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const purchaseContractChangeHandler = (value) => {
    setQualityData([])
    setShowModal(value ? true : !value ? false : undefined);
    setPcTypeData(!value ? undefined : pcTypeData)

    getQualityDetailsByPcId(value);
    setPcqId(value);
    setRefreshPCSelectModal(prev => prev + 1);
    const contractNo = purchases.filter(rec => rec.pcId === value)[0]?.pcNo
    formRef.setFieldValue('purchaseContractNo', contractNo);
  }

  const handleClose = () => {
    setShowModal(false);
    setRefreshPCSelectModal(prev => prev + 1);
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Area',
      dataIndex: 'area'
    },
    {
      title: 'Price',
      dataIndex: 'bargingBasePrice',
      render: (value, record) => {
        return <>
          {value + " " + record.currency}

        </>

      }
    },

    {
      title: 'Barging Type',
      dataIndex: 'bargingType'
    },
    {
      title: 'Vat Applicability',
      dataIndex: 'vatApplicability'
    },
  ]

  const bargeVendorSelection = (value, text) => {
    setBargeAgreementsGridData([]);
    setSelectedBargeVendor(value);
    setBargingAgreementsModalVisible(value ? true : value === undefined ? false : undefined);
    const bargeVendorName = vendorsData.filter(rec => rec.bpId === value)[0]?.bpName;
    formRef.setFieldValue('bargeVendorName', bargeVendorName);
    const req = new BargeVendorIdReqDto(value)
    bargeAgreementService.getBargingAgreementByVendor(req).then(res => {
      if (res.status) {
        setBargeAgreementsGridData(res.data);
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const rowSelection = {
    onChange: (selectedRowKey, selectedRows) => {
      setBargeIdSelection(selectedRows[0]?.bapId)
      setRowSelectionChangedValue(selectedRows[0])
      setSelectedRowKeys(selectedRowKey);
      if (selectedRowKey.length) {
        setOthersChanges(false);
        formRef.setFieldValue('bargingType', selectedRows[0]?.bargingType)
        formRef.setFieldValue('bargingBasePrice', selectedRows[0]?.bargingBasePrice)
        formRef.setFieldValue('others', false);
        formRef.setFieldValue('lumpSumBasePrice', selectedRows[0]?.bargingBasePrice)
        formRef.setFieldValue('bargingBasePriceCurrency', selectedRows[0]?.currency)
        formRef.setFieldValue('lumpSumBasePriceCurrency', selectedRows[0]?.currency)
        formRef.setFieldValue('deadFreight', selectedRows[0]?.bapDeadFreight)
        formRef.setFieldValue('vatInclOrExcl', selectedRowData?.vatInclOrExcl)
      }
    },
    selectedRowKeys: selectedRowKeys
  };

  const othersOnchange = () => {
    setSelectedRowKeys([]);
    setOthersChanges(true);
    formRef.setFieldValue('others', true);
    setRowSelectionChangedValue(undefined);
    formRef.setFieldValue('bargingType', undefined);
    formRef.setFieldValue('bargingBasePrice', undefined);
    formRef.setFieldValue('deadFreightApplicability', undefined);
    formRef.setFieldValue('vatInclOrExcl', undefined)
  }

  const bargeModalOnCancel = () => {
    setBargingAgreementsModalVisible(false);

    setPriceType(undefined)
    setRowSelectionChangedValue(undefined)
  }

  const onOk = () => {
    setBargingAgreementsModalVisible(false);
    if (rowSelectionChangedValue?.bargingType === PriceTypeEnum.PER_MT) {
      setPriceType(PriceTypeEnum.PER_MT)
    }
    if (rowSelectionChangedValue?.bargingType === PriceTypeEnum.LUMP_SUM) {
      setPriceType(PriceTypeEnum.LUMP_SUM)
    }

  }



  return (
    <Card title="Add details to create barge">
      <Form
        key={initialValues}
        initialValues={initialValues}
        form={formRef}
        layout={layoutType}
        autoComplete="off">
        <Row>
          <Form.Item name='id' style={{ display: 'none' }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name='purchaseContractNo' style={{ display: 'none' }} >
            <Input hidden />
          </Form.Item>
          <Form.Item name='bargeVendorName' style={{ display: 'none' }} >
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item name="bargeNomination"

              label={fm({ id: "mdm.components. bargeDetails.bargeNomination", defaultMessage: 'Barge Nomination' })}
            // initialValue = {initialValues.bargeNomination}
            >
              <Input
                placeholder={fm({
                  id: `common.bargeDetails.reqBargeNomination`,
                  defaultMessage: `Please Enter Barge Nomination`
                })} autoComplete='off'
              />
            </Form.Item></Col>

          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>

            <Form.Item name="purchaseContract"
              label={fm({ id: "mdm.components.bargeDetails.purchaseContract", defaultMessage: 'Purchase Contract' })}>
              <Select

                placeholder={fm({ id: `common.bargeDetails.reqpurchaseContract`, defaultMessage: `Please Enter Purchase Contact` })}
                showSearch
                allowClear
                onChange={(value) => purchaseContractChangeHandler(value)}
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}

                disabled={Action === 'Update' && !initialValues?.eligibleBargeOperations}
              >

                {purchases.map(item => {
                  return <Option value={item.pcId}>{item.contractAndSupplier}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            {pcTypeData?.[0]?.purchaseType === "FOB BARGE" || initialValues?.purchaseType === "FOB BARGE" ?
              <Form.Item
                name="bargeVendor"
                label={fm({ id: "mdm.components.bargeDetails.bargeVendor", defaultMessage: 'Barge Vendor' })}>
                <Select
                  placeholder={fm({ id: `common.bargeDetails.reqbargeVendor`, defaultMessage: `Please Enter Barge Vendor` })}
                  allowClear
                  showSearch
                  onChange={(value, text) => bargeVendorSelection(value, text)}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                  <Option value={''}>Plesae Select</Option>
                  {vendorsData.map(item => {
                    return <Option value={item.bpId}>{item.bpName}</Option>
                  })}
                </Select>
              </Form.Item> : <></>}

          </Col>
        </Row>
        <Row >
          {pcqId && <Descriptions
            title=""
          // column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >

            <Descriptions.Item label="Purchase Quality" style={{ paddingLeft: 110 }}>
              <span style={{ paddingTop: 1 }}> {selectedRowData?.[0]?.quality ? selectedRowData?.[0]?.quality : initialValues?.quality}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Purchase Type" style={{ paddingLeft: 110 }}>
              <span style={{ paddingTop: 1 }}> {pcTypeData?.[0]?.purchaseType ? pcTypeData?.[0]?.purchaseType : initialValues?.purchaseType}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Purchasing Terms" style={{ paddingLeft: 110 }}>
              <span style={{ paddingTop: 1 }}>   {selectedRowData?.[0]?.purchasingTerm ? selectedRowData?.[0]?.purchasingTerm : initialValues?.purchaseTerm}</span>
            </Descriptions.Item>
          </Descriptions>
          }
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            {pcTypeData?.[0]?.purchaseType === "FOB BARGE" || initialValues?.purchaseType === "FOB BARGE" ?
              <Form.Item
                name="bargingType"
                label={fm({ id: "mdm.components.bargeDetails.bargingType", defaultMessage: 'Barge Type' })}>
                <Radio.Group onChange={(e) => { onPriceTypeChange(e.target.value) }} disabled={rowSelectionChangedValue ? true : false}>
                  {Object.keys(PriceTypeEnum).map(priceTypeKey => {
                    return <Radio value={PriceTypeEnum[priceTypeKey]} onClick={() => onPriceTypeChange(PriceTypeEnum[priceTypeKey])} >{PriceTypeEnum[priceTypeKey]}</Radio>
                  })}
                </Radio.Group>
              </Form.Item> : <></>}
          </Col>
          <Col style={{ display: rowSelectionChangedValue ? 'none' : 'block' }} xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            {pcTypeData?.[0]?.purchaseType === "FOB BARGE" || initialValues?.purchaseType === "FOB BARGE" ?
              <Form.Item
                name="vatInclOrExcl"
                label={fm({ id: "mdm.components.bargeDetails.Vat Applicability", defaultMessage: 'Vat Applicability' })} >
                <Radio.Group disabled={rowSelectionChangedValue ? true : false} >
                  {Object.keys(VatIncludeExclude).map(vatKey => {
                    return <Radio value={VatIncludeExclude[vatKey]}  >{VatIncludeExclude[vatKey]}</Radio>
                  })}
                </Radio.Group>
              </Form.Item> : <></>}
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            {(priceType === PriceTypeEnum.PER_MT) && (
              <FormItem
                name="bargingBasePrice"
                label={fm({ id: "mdm.components.bargeDetails.bargingBasePrice", defaultMessage: 'Base PricePer (MT)' })}>
                <Input disabled={rowSelectionChangedValue ? true : false} addonAfter={suffixSelector('bargingBasePriceCurrency')} placeholder={fm({ id: `common.bargeDetails.reqbargingBasePrice`, defaultMessage: `Please Enter Base Price` })} autoComplete='off' />
              </FormItem>)}

            {(priceType === PriceTypeEnum.LUMP_SUM) && (<FormItem name="lumpSumBasePrice"
              label={fm({ id: "mdm.components.bargeDetails.lumpSumBasePrice", defaultMessage: 'LumpSum BasePrice' })}>
              <Input disabled={rowSelectionChangedValue ? true : false} addonAfter={suffixSelector('lumpSumBasePriceCurrency')} placeholder={fm({ id: `common.bargeDetails.reqLumpSumBasePrice`, defaultMessage: `Please Enter LumpSum Base Price` })} autoComplete='off' />
            </FormItem>)}
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item
              name="loadingJetty"
              label={fm({ id: "mdm.components.bargeDetails.loadingJetty", defaultMessage: 'Loading Jetty' })}>

              <Input
                placeholder={fm({
                  id: `common.bargeDetails.loadingJetty`,
                  defaultMessage: `Please Enter Loading Jetty`
                })} autoComplete='off'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item
              name="bargeQuantityInMt"
              rules={[{ required: true, message: 'Please Enter Barge Quantity' }]}
              label={fm({ id: "mdm.components.bargeDetails.bargeQuantityInMt", defaultMessage: 'Quantity(MT)' })}>
              <Input type={'number'} onChange={handleReturnCargoQuantityInMt} placeholder={fm({ id: `common.bargeDetails.reqEnter Value`, defaultMessage: `Please Enter Value` })} autoComplete='off' />
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item
              name="loadedQuantityInMt"
              label={fm({ id: "mdm.components.bargeDetails.loadedQuantityInMt", defaultMessage: 'Loaded Quantity(MT)' })}>
              <Input defaultValue={loadedQuantity} autoComplete='off' type='number' disabled />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item
              name="returnCargoQuantityInMt"
              label={fm({ id: "mdm.components.bargeDetails.returnCargoQuantityInMt", defaultMessage: 'Return Quantity(MT)' })}>
              <Input onChange={handleReturnCargoQuantityInMt} placeholder={fm({ id: `common.bargeDetails.reqEnterValue`, defaultMessage: `Please Enter Value` })} autoComplete='off' />
            </Form.Item>
          </Col>

        </Row>
        <Row>
          {returnQuantity && <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item
              name="returnBarge"
              label={fm({ id: "mdm.components.bargeDetails.barge", defaultMessage: 'bargeId' })}>
              <Select
                placeholder="Select Barge Id"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                style={{ width: "100%" }}>
                {bargeData.map(dropData => {
                  return <Option value={dropData.id}>{SequenceUtils.formatNumberToSpecificLength(dropData.bargeId.toString()) + " " + `(${dropData.bargeNomination})`}</Option>
                })}

              </Select>
            </Form.Item>
          </Col>}

          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            {/* <Form.Item
              name="supplierBargeWindow"
              label={fm({ id: "mdm.components.bargeDetails.supplierBargeWindow", defaultMessage: 'Supplier (BW)' })}>
              <Input placeholder={fm({ id: `common.bargeDetails.reqsupplierBargeWindow`, defaultMessage: `Please Enter Supplier (BW)` })} autoComplete='off' />
            </Form.Item> */}
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            {/* <Form.Item
              name="buyerBargeWindow"
              label={fm({ id: "mdm.components.bargeDetails.buyerBargeWindow", defaultMessage: 'Buyer(BW)' })}>
              <Input placeholder={fm({ id: `common.bargeDetails.reqbuyerBargeWindow`, defaultMessage: `Please Enter Buyer (BW)` })} autoComplete='off' />
            </Form.Item> */}
          </Col>
        </Row>
        {/* <Row> */}
        {/* <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="blDate" label={fm({ id: "mdm.components.bargetimings.blDate", defaultMessage: "BL Date" })}
            > */}
        {/* <DatePicker placeholder={fm({ id: `mdm.components.bargetimings.reqblDate`, defaultMessage: ` Select BLDate` })} autoComplete='off'  showTime format="YYYY-MM-DD HH:mm:ss"/> */}
        {/* <DatePicker style={{ width: 330 }} placeholder={fm({ id: `mdm.components.bargetimings.reqblDate`, defaultMessage: ` Select BLDate` })} format="YYYY-MM-DD" />
            </Form.Item>
          </Col> */}
        {/* <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="blNumber"
              label={fm({ id: "mdm.components.bargeDetails.blNumber", defaultMessage: 'BL Number' })}>
              <Input placeholder={fm({ id: `common.bargeDetails.reqblNumber`, defaultMessage: `Please Enter Value` })} autoComplete='off' />
            </Form.Item>
          </Col> */}
        {/* <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="blQuantity"
              label={fm({ id: "mdm.components.bargeDetails.blQuantity", defaultMessage: 'BL Quantity' })}>
              <Input placeholder={fm({ id: `common.bargeDetails.reqblQuantity`, defaultMessage: `Please Enter Value` })} autoComplete='off' />
            </Form.Item>
          </Col> */}
        {/* </Row> */}
        <br></br>
        <Row>

          {(priceType === PriceTypeEnum.PER_MT) && (
            <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item name={'deadFreightApplicability'} label='Dead Weight Applicability' >
                <Radio.Group>
                  {Object.keys(ResponsePersonEnum).map(person => {
                    return <Radio value={ResponsePersonEnum[person]}>{ResponsePersonEnum[person]}</Radio>
                  })}
                </Radio.Group>

              </Form.Item>
            </Col>
          )}
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name={'deadFreight'} label='Dead Weight' rules={[
              // {
              //   pattern: /^(\d+|\d+\.\d{1,2})$/,
              //   message: 'Please enter a valid number with up to 2 decimal places.',
              // },
            ]}
            // normalize={(value) => {
            //   // Normalize the input value to have up to 2 decimal places
            //   if (value === '') return value;
            //   const parts = value.toString().split('.');
            //   if (parts.length > 1) {
            //     return parseFloat(parts[0] + '.' + parts[1].slice(0, 2));
            //   }
            //   return parseFloat(value);
            // }}
            >
              <Input onChange={handleReturnCargoQuantityInMt} placeholder='Dead Weight'

                type={'number'}></Input>
            </Form.Item>
          </Col>
          {/* <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name={'deadFreightIncurred'} label='Dead Weight Incurred'>
              <Input placeholder='Dead Weight Incurred' type={'number'}></Input>
            </Form.Item>
          </Col> */}


          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              name={'deadFreightIncurred'}
              label='Dead Weight Incurred'
            // rules={[
            //   {
            //     pattern: /^(\d+|\d+\.\d{1,2})$/,
            //     message: 'Please enter a valid number with up to 2 decimal places.',
            //   },
            // ]}
            // normalize={(value) => {
            //   // Normalize the input value to have up to 2 decimal places
            //   if (value === '') return value;
            //   const parts = value.toString().split('.');
            //   if (parts.length > 1) {
            //     return parseFloat(parts[0] + '.' + parts[1].slice(0, 2));
            //   }
            //   return parseFloat(value);
            // }}
            >
              <Input placeholder='Dead Weight Incurred' type={'number'} />
            </Form.Item>
          </Col>;

        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name={'pph5'} label='TDS'>

              <Radio.Group  >
                {Object.keys(YESOrNoRadioEnum).map(tds => {
                  return <Radio value={YESOrNoRadioEnum[tds]}>{YESOrNoRadioEnum[tds]}</Radio>
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Modal
          open={bargingAgreementsModalVisible}
          title={'Barging Agreements'}
          width={800}
          onCancel={bargeModalOnCancel}
          onOk={onOk}
          okText='Ok'
          closable={true}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <Table
            key={selectedBargeVendor}
            columns={columns}
            dataSource={bargeAgreementsGridData}
            pagination={false}
            bordered={true}
            rowKey={(record) => record.bapId}
            rowSelection={{ type: 'radio', ...rowSelection }}
          />
          <br></br>
          <Form.Item name={'others'} style={{ paddingLeft: 15 }} >
            <Radio value={othersChanges} checked={othersChanges} onChange={othersOnchange}>Others</Radio>
          </Form.Item>
        </Modal>

      </Form>
      {props?.children}
      <Modal
        key={refreshPCSelectModal}
        open={showModal} width={window.innerWidth > 768 ? '70%' : '85%'} onCancel={handleClose} closable={false}
        footer={null}>
        <NewModal
          setPcTypeId={props.setPcTypeId}
          setShowModal={setShowModal}
          setPcTypeData={setPcTypeData}
          qualityData={qualityData}
          closeModal={handleClose}
          pcId={pcqId}
          setBargeOrderData={setBargeOrderData}
          selectedRows={(value) => {
            setSelectedRowData(value);
            props.setPcQualitiesData(value);
          }} />
      </Modal>



    </Card >
  )
}
export default BargeDetails