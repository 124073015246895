
import React, { useState } from 'react';
import { ContractTypesEnum, PurchaseContractDto, PurchaseTermDataDto, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Button, Collapse, Tabs } from 'antd';
import { AnalysisDataView } from './analysis-data-view';
import ASHFusionView from './ash-fusion';
import ElementsView from './elements';
import OtherSpecs from './other-specs';
import SizeDistributionDataView from './size-distribution-data-view';
import TabPane from 'antd/es/tabs/TabPane';
import UltimateDataView from './ultimate-data-view';
import ASHDataView from './ash-data-view';
import { UltimateModel } from './ultimate.model';
import { AshModel } from './ash.model';

export interface IAnalysisData {
    purchaseTermData: PurchaseTermDataDto,
    specType: SpecTypesEnum;
    snapShotPurchaseTermData: PurchaseTermDataDto;
    snapSpecType: any;
    contractType: ContractTypesEnum;
}
const { Panel } = Collapse;


export const SpecsViewPage = (props: IAnalysisData) => {
    const { purchaseTermData, specType, contractType, snapShotPurchaseTermData, snapSpecType } = props;

    const [activeKey, setActiveKey] = useState<(number | string)[]>([]);

    const handlePanelChange = (key: (number | string) | (number | string)[]) => {
        setActiveKey(key as (number | string)[]);
      };

    const assignObjectValues = (childObject: any, parentObject: any) => {
        Object.keys(childObject).forEach(key => {
            childObject[key] = parentObject?.[key];
        })
        return childObject;
    }

    return (
        <>
        <Tabs>
            <TabPane tab="Typical Analysis" key='1'>
                <AnalysisDataView purchaseTermData={purchaseTermData} specType={specType} snapShotPurchaseTermData={snapShotPurchaseTermData} contractType={contractType} snapSpecType={snapSpecType} showAdditionalData={false}/>
            </TabPane>
            <TabPane tab="Additional" key='2'>

                <Button type='link' onClick={()=> setActiveKey([])}>Collapse All</Button>
                <Button type='link' onClick={()=> setActiveKey([1,2,3,4,5,6])}>Expand All</Button>

                <Collapse ghost activeKey={activeKey} onChange={handlePanelChange}>
                    <Panel header="Ultimate" key="1">
                        <UltimateDataView ultimate={assignObjectValues(new UltimateModel(), specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData)} contractType={contractType} addendumUltimate={assignObjectValues(new UltimateModel(), specType == SpecTypesEnum.ASTM ? snapShotPurchaseTermData?.astmData : snapShotPurchaseTermData?.isoData)}/>
                    </Panel>
                    <Panel header="ASH" key="2">
                        <ASHDataView ash={assignObjectValues(new AshModel(), specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData)} contractType={contractType} addendumAsh={assignObjectValues(new AshModel(), specType == SpecTypesEnum.ASTM ? snapShotPurchaseTermData?.astmData : snapShotPurchaseTermData?.isoData)}/>
                    </Panel>
                    <Panel header="Size Distribution" key="3">
                        <SizeDistributionDataView purchaseTermData={purchaseTermData} specType={specType} snapShotPurchaseTermData={snapShotPurchaseTermData} contractType={contractType} snapSpecType={snapSpecType}/>
                    </Panel>
                    <Panel header="ASH Fusion" key="4">
                        <ASHFusionView purchaseTermData={purchaseTermData} specType={specType} snapShotPurchaseTermData={snapShotPurchaseTermData} contractType={contractType} snapSpecType={snapSpecType}/>
                    </Panel>
                    <Panel header="Elements" key="5">
                        <ElementsView purchaseTermData={purchaseTermData} specType={specType} snapShotPurchaseTermData={snapShotPurchaseTermData} contractType={contractType} snapSpecType={snapSpecType}/>
                    </Panel>
                    <Panel header="Others" key="6">
                        <OtherSpecs purchaseTermData={purchaseTermData} specType={specType}
                            snapShotPurchaseTermData={snapShotPurchaseTermData}
                            contractType={contractType} snapSpecType={snapSpecType}/>
                    </Panel>
                </Collapse>

            </TabPane>
        </Tabs>
        </>
    )
}

export default SpecsViewPage;