import { BaCostingIdReqDto, BudgetAndCostingService } from '@exportx/shared-models-and-services'
import { Affix, Button, Col, Descriptions, Row, Table, Tag } from 'antd'
import Card from 'antd/lib/card/Card'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AlertMessages, useAuthState } from '../../../../common'

const BACostingDetailedView = () => {
    const [gridData, setGridData] = useState<any>()
    const bacService = new BudgetAndCostingService()
    const { authContext } = useAuthState()
    const { state }: any = useLocation()
    const { id } = state ? state : { id: null }
    const Navigate = useNavigate()

    useEffect(() => {
        getBacUpdatedData()
    }, [id])


    const getBacUpdatedData = () => {
        const req = new BaCostingIdReqDto(id, authContext.defaultPlant)
        bacService.getBacUpdatedData(req).then(res => {
            if (res.status) {
                setGridData(res.data)
            } else {
                // AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }



    const coalQuantityTotal = gridData?.coalSalesData.reduce((a, c) => a + Number(c.quantity), 0);
    const ct = gridData?.coalSalesData.reduce((a, c) => a + Number(c.total), 0);
    const purchaseQuantityTotal = gridData?.coalPurchaseData.reduce((a, c) => a + Number(c.quantity), 0);
    const pt = gridData?.coalPurchaseData.reduce((a, c) => a + Number(c.total), 0);
    const csfQ = gridData?.csfManualData.reduce((a, c) => a + Number(c.quantity), 0);
    const csfT = gridData?.csfManualData.reduce((a, c) => a + Number(c.total), 0);
    const cpfQ = gridData?.csfManualData.reduce((a, c) => a + Number(c.quantity), 0);
    const cpfT = gridData?.csfManualData.reduce((a, c) => a + Number(c.total), 0);
    const bgcQ = gridData?.bargeCostData.reduce((a, c) => a + Number(c.quantity), 0);
    const bgcT = gridData?.bargeCostData.reduce((a, c) => a + Number(c.total), 0);
    const meQ = gridData?.miscExpensesData.reduce((a, c) => a + Number(c.quantity), 0);
    const meT = gridData?.miscExpensesData.reduce((a, c) => a + Number(c.total), 0);


    return (
        <Card title={<span>Budget And Costing - <Tag color={'blue'}>{gridData?.budgetCostingNo}</Tag></span>}>
            <Descriptions layout='horizontal' size='small'>
                <Descriptions.Item label='Laycan' >{gridData ? moment(gridData?.laycan[0]).format('DD-MMM-YY') + " " + 'To' + " " + moment(gridData?.laycan[1]).format('DD-MMM-YY') : undefined}</Descriptions.Item>
                <Descriptions.Item label='Vessel Name' >{gridData?.vesselName}</Descriptions.Item>
                <Descriptions.Item label='Currency'>{gridData?.budgetCurrency}</Descriptions.Item>
                <Descriptions.Item label='Exchange Rate' >{gridData?.exchangeRate}</Descriptions.Item>
            </Descriptions>

            <table className='table table-sm table-bordered'>
                <thead className='table-header'>
                    <tr className="bg-primary text-white">
                        <th>Customer / Vendor</th>
                        <th>Contract</th>
                        <th>Quality</th>
                        <th>Sales / Purchase Type</th>
                        <th>Price Type</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Currency</th>
                        <th>Total</th>
                    </tr>
                    {gridData ? <>
                        <tr>
                            <td colSpan={9}><b>Coal Sales</b></td>

                        </tr>
                        {
                            gridData?.coalSalesData.map((el) => {
                                return <tr>
                                    <td>{el.bpsBpName}</td>
                                    <td>{el.scContractNo}</td>
                                    <td>{el.scQuality}</td>
                                    <td>{el.salesType}</td>
                                    <td>{'Per MT'}</td>
                                    <td>{el.quantity}</td>
                                    <td>{el.pricePMT}</td>
                                    <td>{el.currency}</td>
                                    <td>{el.total}</td>
                                </tr>
                            })
                        }
                        <td colSpan={5}></td>
                        <td colSpan={1}>{<b>{coalQuantityTotal}</b>}</td>
                        <td colSpan={2}>{ }</td>
                        <td colSpan={1}>{<b>{ct} </b>}</td>
                        <tr>
                            <td colSpan={9}><b>Coal Purchase</b></td>
                        </tr>
                        {
                            gridData?.coalPurchaseData.map((el) => {
                                return <tr>
                                    <td>{el.bppBpName}</td>
                                    <td>{el.pcContractNo}</td>
                                    <td>{el.pcQuality}</td>
                                    <td>{el.pcContractNo}</td>
                                    <td>{'Per MT'}</td>
                                    <td>{el.quantity}</td>
                                    <td>{el.plantCode}</td>
                                    <td>{el.currency}</td>
                                    <td>{el.total}</td>
                                </tr>
                            })
                        }
                        <td colSpan={5}></td>
                        <td colSpan={1}>{<b>{purchaseQuantityTotal}</b>}</td>
                        <td colSpan={2}></td>
                        <td colSpan={1}>{<b>{pt}</b>}</td>


                        <tr>
                            <td colSpan={9}><b>Service Fee</b></td>

                        </tr>
                        {
                            gridData?.csfManualData.map((el) => {
                                return <tr>
                                    <td>{el.csmBpName}</td>
                                    <td>{ }</td>
                                    <td>{el.plantCode}</td>
                                    <td>{el.createdUser}</td>
                                    <td>{el.priceType}</td>
                                    <td>{el.quantity}</td>
                                    <td>{el.plantCode}</td>
                                    <td>{el.currency}</td>
                                    <td>{el.total}</td>
                                </tr>
                            })
                        }
                        <td colSpan={5}></td>
                        <td colSpan={1}>{<b>{csfQ}</b>}</td>
                        <td colSpan={2}>{ }</td>
                        <td colSpan={1}>{<b>{csfT}</b>}</td>
                        <tr>
                            <td colSpan={9}><b>Procurement Fee</b></td>

                        </tr>
                        {
                            gridData?.cpfManualData.map((el, index) => {
                                return <tr>
                                    <td>{el.cpmBpName}</td>
                                    <td>{el.createdUser}</td>
                                    <td>{el.plantCode}</td>
                                    <td>{el.createdUser}</td>
                                    <td>{el.priceType}</td>
                                    <td>{el.quantity}</td>
                                    <td>{el.plantCode}</td>
                                    <td>{el.currency}</td>
                                    <td>{el.total}</td>
                                </tr>
                            })
                        }
                        <td colSpan={5}></td>
                        <td colSpan={1}>{<b>{cpfQ}</b>}</td>
                        <td colSpan={2}>{ }</td>
                        <td colSpan={1}>{<b>{cpfT}</b>}</td>
                        <tr>
                            <td colSpan={9}><b>Barge Cost</b></td>

                        </tr>
                        {
                            gridData?.bargeCostData.map((el) => {
                                return <tr>
                                    <td>{el.bcdBpName}</td>
                                    <td>{el.createdUser}</td>
                                    <td>{el.plantCode}</td>
                                    <td>{el.createdUser}</td>
                                    <td>{el.priceType}</td>
                                    <td>{el.quantity}</td>
                                    <td>{el.plantCode}</td>
                                    <td>{el.currency}</td>
                                    <td>{el.total}</td>
                                </tr>
                            })
                        }

                        <td colSpan={5}></td>
                        <td colSpan={1}>{<b>{bgcQ}</b>}</td>
                        <td colSpan={2}>{ }</td>
                        <td colSpan={1}>{<b>{bgcT}</b>}</td>
                        <tr>
                            <td colSpan={9}><b>Misc Expenses</b></td>

                        </tr>
                        {
                            gridData?.miscExpensesData.map((el) => {
                                return <tr>
                                    <td>{el.costingType}</td>
                                    <td>{el.createdUser}</td>
                                    <td>{el.plantCode}</td>
                                    <td>{el.createdUser}</td>
                                    <td>{el.priceType}</td>
                                    <td>{el.quantity}</td>
                                    <td>{el.pricePMT}</td>
                                    <td>{el.currency}</td>
                                    <td>{el.total}</td>
                                </tr>
                            })
                        }
                        <td colSpan={5}></td>
                        <td colSpan={1}>{<b>{meQ}</b>}</td>
                        <td colSpan={2}>{ }</td>
                        <td colSpan={1}>{<b>{meT}</b>}</td>
                    </> : <></>}
                </thead>
            </table>

            <Affix offsetBottom={0}>
                <Card bodyStyle={{ padding: '6px 6px ' }}>
                    <Row >
                        <Col ><Button danger type='primary' onClick={() => Navigate(-1)}>Cancel</Button></Col>
                        <Col offset={18} style={{ paddingLeft: 160 }}><Button type='primary' onClick={() => Navigate('/budget-costing-form', { state: { id: gridData?.budgetAndCostingId } })}>Edit</Button></Col>
                    </Row>
                </Card>
            </Affix>

        </Card >
    )
}

export default BACostingDetailedView