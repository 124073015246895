import { Document, Page, StyleSheet, View } from "@react-pdf/renderer";
import InvoiceItemsTable from "./invoice-items-table";
import InvoiceNo from './invoice-no';
import InvoiceQualityResults from "./invoice-quality-results";
import InvoiceTitle from "./invoice-title";


interface InvoiceIProps {
    invoice: any;
    demurrageName: string;
    costingName: any;

}

const Invoice = (props: InvoiceIProps) => {
    const { invoice, demurrageName, costingName } = props;

    const styles = StyleSheet.create({
        page: {
            fontFamily: "Helvetica",
            fontSize: 8,
            paddingTop: 30,
            paddingLeft: 20,
            paddingRight: 20,
            lineHeight: 1.5,
            flexDirection: "column",
        },
    });

    return <>

        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    style={{
                        borderLeft: 0.65,
                        borderRight: 0.65,
                        borderBottom: 0.65,
                        borderTop: 0.4,
                        borderTopColor: "black",
                        borderBottomColor: "black",
                        borderLeftColor: "black",
                        borderRightColor: "black",
                    }}
                >
                    <InvoiceNo
                        costingId={invoice?.costingNo}
                        to={invoice?.vendor}
                        businessNo={invoice?.businessNo}
                        invoiceNo={invoice?.invoiceNo}
                        invoiceDate={invoice?.invoiceDate}
                        costingName={costingName}
                    />
                    {invoice?.bargeVouchers && invoice?.bargeVouchers?.length > 0 && (
                        <InvoiceTitle
                            invoice={invoice}
                            demurrageName={demurrageName}
                            costingName={costingName}
                            purchaseType={"FOB BARGE"}
                            purchaseIndexLinked={"yes"}
                        />
                    )}
                    <InvoiceItemsTable
                        invoice={invoice}
                        demurrageName={demurrageName}
                    />
                    {invoice?.qualityResults && (
                        <View style={{ marginTop: 5 }}>
                            <InvoiceQualityResults results={invoice?.qualityResults} />
                        </View>
                    )}
                </View>
            </Page>
        </Document>

    </>
}

export default Invoice