import { Card, Col, Form, FormInstance, Input, Radio, Row, Select, Tooltip } from 'antd';
import { PlusCircleOutlined, DeleteFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { FileUploadForm } from '../../../../common';
import { BpCategoryGetDto, BPDropDownDto, BusinessPartnerService, BusinessPartnerTypeEnum, CurrencyService, PriceTypeEnum, TaxDropDownDto, TaxesService, TaxTypeReqDto, TaxTypes, VatIncludeExclude } from '@exportx/shared-models-and-services';
import { useIntl } from 'react-intl';
import { FormLayout } from 'antd/lib/form/Form';
import { ClassValidator } from '@exportx/ui-utils';
import DatePicker from '../../../../common/data-picker/date-picker';

const { Option } = Select;
interface INewBargeAgreementProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}



const NewBargeAgreement = (props: INewBargeAgreementProps) => {
  const { formRef, layoutType, initialValues } = props;
  const { formatMessage: fm } = useIntl();
  const classValidator = new ClassValidator();
  const [vendorsData, setVendorsData] = useState<BPDropDownDto[]>([]);
  const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([]);
  const [isVatSelected, setIsVatSelected] = useState([VatIncludeExclude.EXCLUDE])
  const [currency, setCurrency] = useState<any[]>([false]);
  const [priceType, setPriceType] = useState([PriceTypeEnum.LUMP_SUM]);



  const businessPartnerService = new BusinessPartnerService();
  const currencyService = new CurrencyService();
  const taxesService = new TaxesService();

  useEffect(() => {
    getAllBargeVendorsDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR));
    getCurrenciesData();
    getAllActiveVatDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValues) {
      formRef.setFieldValue('filesData', initialValues?.filesData);
      formRef.setFieldsValue({ specPriceData: initialValues?.specPriceData });
      formRef.setFieldsValue(initialValues);
      if(initialValues?.action === 'addendum') {
        formRef.setFieldValue('filesData', []);
      }
      let pcType = [];
      let vatInclude = []
      initialValues?.specPriceData.forEach(specPrice => {
        pcType.push(specPrice.bargingType);
        vatInclude.push(specPrice.vatInclOrExcl);
      })
      setPriceType(pcType);
      setIsVatSelected(vatInclude);
    }
  }, [initialValues]);

  const getAllBargeVendorsDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
    businessPartnerService.getAllBargeVendorsDropDown(bpCategoryGetDto).then(res => {
      if (res.status) {
        if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.VENDOR)
          setVendorsData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getAllActiveVatDropDown = () => {
    const req = new TaxTypeReqDto();
    req.taxType = TaxTypes.VAT;
    taxesService.getTaxesByCategory(req).then(res => {
      if (res.status) {
        setTaxDropDown(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getCurrenciesData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrency(res.data);
      }
    }).catch(err => {
      console.log("error");
    })
  }

  const radioButtonChangeHandler = (key: number, value: PriceTypeEnum) => {
    setPriceType(prevState => {
      const newState = { ...prevState };
      newState[key] = value;
      return newState;
    })
  };

  const vatOnChangeHandler = (key: number, value: VatIncludeExclude) => {
    setIsVatSelected(prevState => {
      const newState = { ...prevState };
      newState[key] = value;
      return newState;
    })
  };

  const handleVatChange = (key: number) => {
    const vat = formRef.getFieldValue(['specPriceData', key, 'vatId'])
    if (vat) {
      const vatId = taxDropDown.filter(rec => rec.taxId === vat)[0];
      const price = Number(formRef.getFieldValue(['specPriceData', key, 'price']));
      const priceAfterVat = (price ? price / ((vatId.taxPercentage / 100) + 1) : 0);
      formRef.setFieldValue(['specPriceData', key, 'priceAfterVat'], priceAfterVat?.toFixed(2));
    } else {
      const price = Number(formRef.getFieldValue(['specPriceData', key, 'price']));
      const priceAfterVat = price ? price : 0;
      formRef.setFieldValue(['specPriceData', key, 'priceAfterVat'], priceAfterVat?.toFixed(2));
    }
  }




  return (
    <div>
      <Card>
        <Form
          form={formRef}
          layout={layoutType}
          autoComplete="off"
          initialValues={initialValues}
        >
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 1 }}>
              <Form.Item name={'bgApId'} style={{ display: 'none' }}><Input hidden /></Form.Item>
              <Form.Item
                label={fm({ id: 'mdm.components.bargeAgreementForm.bargeVendorId', defaultMessage: "Barge Vendor" })}
                name="bargeVendorId"
                rules={[{ required: true, message: fm({ id: "mdm.components.bargeAgreementForm.bargeVendorIdReq", defaultMessage: "Please Select Barge Vendor" }) }]}
                style={{ marginLeft: "-34px" }}
              >
                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch >

                  {vendorsData.map(item => {
                    return <Option key={item.bpId} value={item.bpId}>{item.bpName}</Option>
                  })}</Select>

              </Form.Item>

            </Col>
            <Col xs={{ span: 14 }} md={{ span: 14 }} lg={{ span: 5, offset: 1 }}>
              <Form.Item

                label={fm({ id: 'mdm.components.bargeAgreementForm.agreementDate', defaultMessage: "Agreement Date" })}
                name="agreementDate"
                rules={[{ required: true, message: fm({ id: "mdm.components.bargeAgreementForm.agreementDateReq", defaultMessage: "Please Select Agreement Date" }) }]}
              >
                <DatePicker style={{ width: "220px" }} format="YYYY-MM-DD" />
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 1 }}>

              <Form.Item
                label={fm({ id: 'mdm.components.bargeAgreementForm.currency', defaultMessage: "Currency" })}
                name="currency"
                rules={[{ required: true, message: fm({ id: "mdm.components.bargeAgreementForm.currencyReq", defaultMessage: "Please Select Currency" }) }]}
              >
                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder=" Select currency" >


                  {currency.map(item => {
                    return <Option value={item.currencyCode}>{item.currencyName}</Option>
                  })}

                </Select>
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 1 }}></Col>


          </Row>

          <Form.List name="specPriceData">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  // <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Row>
                    <Form.Item name={'bgApId'}><Input hidden /></Form.Item>
                    <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 22 }} lg={{ span: 22 }} xl={{ span: 22 }}>
                      <div className='contractTypeBlock'>
                        <Row>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                            <Form.Item
                              label={fm({ id: 'mdm.components.bargeAgreementForm.area', defaultMessage: " Area" })}
                              name={[name, "area"]}
                              rules={[{
                                required: true, message: fm({
                                  id: "mdm.components.bargeAgreementForm.areaReq",
                                  defaultMessage: "Please Enter Area"
                                }),
                              },
                              // ...classValidator.alphaNumericSpecialCharsWithSpaces(),
                            ]}
                            >
                              <Input />
                            </Form.Item>

                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                            <Form.Item

                              label={fm({ id: 'mdm.components.bargeAgreementForm.price', defaultMessage: 'Price' })}
                              name={[name, "price"]}
                              rules={[{
                                required: true, message: fm({ id: "mdm.components.bargeAgreementForm.priceReq", defaultMessage: "Please Enter Price" }),

                              },
                              {
                                pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                                message: `Only Number & Decimals`
                              }]
                              }

                            >
                              <Input type='text' onChange={() => handleVatChange(key)} />
                            </Form.Item>

                          </Col>

                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                            <Form.Item
                              name={[name, "bargingType"]}
                              label={fm({ id: "mdm.components.bargeAgreementForm.bargingType", defaultMessage: 'Barge Type' })}>
                              <Radio.Group onChange={(e) => { radioButtonChangeHandler(key, e.target.value) }}  >
                                {Object.keys(PriceTypeEnum).map(priceTypeKey => {
                                  return <Radio value={PriceTypeEnum[priceTypeKey]} onClick={() => radioButtonChangeHandler(key, PriceTypeEnum[priceTypeKey])} >{PriceTypeEnum[priceTypeKey]}</Radio>
                                })}
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          {(priceType[key] === PriceTypeEnum.PER_MT) && (<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                            <Form.Item
                              name={[name, "deadFreight"]}
                              label={fm({ id: "mdm.components.bargeAgreementForm.deadWeight", defaultMessage: 'Dead Weight' })}>
                              <Input placeholder={fm({ id: `common.bargeAgreementForm.reqdeadWeight`, defaultMessage: `Enter DeadWeight` })} autoComplete='off' />
                            </Form.Item>
                          </Col>)}
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 1 }}>
                            <Form.Item
                              label={fm({ id: 'mdm.components.bargeAgreementForm.vatInclOrExcl', defaultMessage: 'VAT Applicability (PPN)' })}
                              name={[name, "vatInclOrExcl"]}
                              style={{ width: '320px' }}
                            >
                              <Radio.Group onChange={(e) => { vatOnChangeHandler(key, e.target.value) }}>
                                {Object.keys(VatIncludeExclude).map(vatKey => {
                                  return <Radio value={VatIncludeExclude[vatKey]} onClick={() => vatOnChangeHandler(key, VatIncludeExclude[vatKey])} >{VatIncludeExclude[vatKey]}</Radio>
                                })}
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          {(isVatSelected[key] === VatIncludeExclude.INCLUDE) && (<><Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                            <Form.Item name={[name, "vatId"]} label={"Vat to be Applied"} style={{ width: '100%' }}>
                              <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear
                                showSearch
                                placeholder="PLease Select"
                                onChange={(vatId) => {
                                  handleVatChange(key)
                                }}
                              >
                                <Option key={0} value={null}>Select Vat</Option>
                                {taxDropDown.map((rec) => {
                                  return <Option value={rec.taxId}>{`${rec.taxName} (${rec.taxPercentage})`}</Option>
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                              <Form.Item name={[name, "priceAfterVat"]}
                                label={'Price after Vat'}
                              // style={{ display: 'none' }}
                              ><Input disabled={true} /></Form.Item></Col></>)}
                          <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                            {index !== 0 &&
                              <span style={{
                                position: "absolute",
                                marginTop: "33px",
                                marginLeft: "35px",
                              }}>
                                <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                  <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(name)} />
                                </Tooltip>
                              </span>
                            }
                            {(fields.length - 1 === index && fields.length < 5) && <span style={{
                              position: "absolute",
                              marginTop: "33px",
                              marginLeft: "15px"
                            }}>
                              <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                <PlusCircleOutlined
                                  onClick={() => {
                                    setPriceType(prevState => {
                                      const newState = { ...prevState };
                                      newState[fields.length] = PriceTypeEnum.LUMP_SUM;
                                      return newState;
                                    });
                                    add();

                                  }}
                                />
                              </Tooltip>
                            </span>}
                          </Col>
                        </Row>
                      </div>

                    </Col>

                  </Row>
                ))}
              </>
            )}
          </Form.List>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} >
              <Form.Item label={fm({ id: 'mdm.components.vendorAdvance.remarks', defaultMessage: 'Remarks' })}
                name="remarks">
                <Input.TextArea />
              </Form.Item></Col>
          </Row>

          <Card>
            <FileUploadForm maxCount={3} layoutType={layoutType} formRef={formRef} 
            initialValues={initialValues?.action !== 'addendum' ? initialValues?.filesData : []}
             />
          </Card>

        </Form>
        {props?.children}
      </Card>
    </div>
  )
}

export default NewBargeAgreement;