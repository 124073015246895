import React from 'react';
import { Col, Descriptions, Form, Input, Row } from 'antd'
import FormItem from 'antd/es/form/FormItem'


const Pph22Export = () => {
    return (
        <>

            <div style={{ backgroundColor: "", }} >
                <Form>
                    <Row>
                        <Col span={8} offset={1}>
                            <FormItem name='amount' label='Amount' >
                                <Input placeholder='amount'></Input>
                            </FormItem>
                        </Col><br></br>

                    </Row>
                    <Row>
                        <Col span={8} offset={1}>
                            <FormItem name='pph22ExportPercentage' >
                                <Descriptions colon={false}>
                                    <Descriptions.Item label='PPH22 Export %'>{ }</Descriptions.Item>
                                </Descriptions>
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem name='pph22ExportInKOLO' >
                                <Descriptions colon={false}>
                                    <Descriptions.Item label='PPH22 Export in KOLO'>{ }</Descriptions.Item>
                                </Descriptions>
                            </FormItem>
                        </Col>

                    </Row>

                </Form>

            </div>
        </>

    )
}

export default Pph22Export