import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Radio, RadioChangeEvent, Row, Select, Tooltip } from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { FormInstance, FormLayout } from 'antd/lib/form/Form';
import { CurrencyService, TaxTypeEnum } from '@exportx/shared-models-and-services';
import { AlertMessages } from '../notifications';
import { ClassValidator } from '@exportx/ui-utils';

export interface IKycFormProps {
  maxCount: number;
  layoutType: FormLayout;
  formRef: FormInstance<any>;
  initialValues: any;
}
const { TextArea } = Input;
const { Option } = Select;
const KycForm = (props: IKycFormProps) => {
  const { formatMessage: fm } = useIntl();
  const { maxCount, layoutType, formRef, initialValues } = props;
  const [currencies, setCurrencies] = useState<any[]>([]);
  const classValidator = new ClassValidator();
  const [value, setValue] = useState("");
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  }


  const currencyService = new CurrencyService()
  useEffect(() => {
    getCurrencyData();
  }, []);

  const getCurrencyData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrencies(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }



  return (
    <Form layout={layoutType} form={formRef} initialValues={initialValues}>
      <Form.List name="kycFormData">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row key={field.key}>
                <Col
                  xs={{ span: 22 }}
                  sm={{ span: 22 }}
                  md={{ span: 22 }}
                  lg={{ span: 22 }}
                  xl={{ span: 22 }}
                >
                  <Card title='KYC Details'>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label={fm({ id: "common.kycForm.bankName", defaultMessage: 'Bank Name' })

                          }
                          {...field}
                          name={[field.name, 'bankName']}
                          rules={[
                            {
                              required: true, message: fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                            },
                            ...classValidator.alphaSpaces(),
                          ]}
                        >
                          <Input placeholder={fm({ id: `common.kycForm.reqBankName`, defaultMessage: `Enter Bank Name` })} autoComplete='off' />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
                        <Form.Item
                          label={fm({ id: "common.kycForm.accountHolderName", defaultMessage: 'Account Holder Name' })}
                          {...field}
                          name={[field.name, 'accountHolderName']}
                          rules={[
                            {
                              max: 40, message: fm({ id: "common.kycForm.reqAccountHolderName" })
                            },
                            ...classValidator.alphaSpaces(),
                          ]}
                        >
                          <Input placeholder={fm({ id: `common.kycForm.reqAccountHolderName`, defaultMessage: `Enter Account Holder Name` })} autoComplete='off' />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
                        <Form.Item
                          label={fm({ id: "common.kycForm.accountNumber", defaultMessage: 'Account Number' })}
                          {...field}
                          name={[field.name, 'accountNumber']}
                          rules={[
                            {
                              required: true, message: fm({ id: "common.kycForm.reqAccountNumber", defaultMessage: 'Enter Account Number' })
                            },
                            ...classValidator.onlyNumeric(),

                          ]}
                        >
                          <Input placeholder={fm({ id: `common.kycForm.reqAccountNumber`, defaultMessage: `Enter Account Number` })} autoComplete='off' />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label={fm({ id: "common.kycForm.swiftCode", defaultMessage: 'Swift Code' })}
                          {...field}
                          name={[field.name, 'swiftCode']}
                          rules={[
                            {
                              required: true, message:   'Please Enter SwiftCode' 
                            },
                            {
                              max: 15, message: fm({ id: "common.kycForm.reqSwiftCode", defaultMessage: 'Enter Swift Code' })
                            }
                          ]}
                        >
                          <Input placeholder={fm({ id: `common.kycForm.reqSwiftCode`, defaultMessage: `Enter Swift Code` })} autoComplete='off' />
                        </Form.Item>
                      </Col>

                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
                        <Form.Item
                          label={fm({ id: "common.kycForm.iBanNumber", defaultMessage: 'IBAN Number' })}
                          {...field}
                          name={[field.name, 'iBanNumber']}
                          rules={[
                            {
                              required: false, message: fm({ id: "common.kycForm.reqIBanNumber", defaultMessage: 'Enter IBAN Number' })
                            }
                          ]}
                        >
                          <Input placeholder={fm({ id: `common.kycForm.reqIBanNumber`, defaultMessage: `Enter IBAN Number` })} autoComplete='off' />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
                        <Form.Item
                          label={fm({ id: "common.kycForm.currency", defaultMessage: 'Swift Code' })}
                          {...field}
                          name={[field.name, 'currency']}
                          rules={[
                            {
                              required: true, message: fm({ id: `common.kycForm.reqCurrency`, defaultMessage: `Select Currency` })
                            }
                          ]}
                        ><Select
                          placeholder={fm({ id: `common.kycForm.reqCurrency`, defaultMessage: `Select Currency` })}
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                        >
                            <Option value={''}>Please Select</Option>
                            {currencies.map(item => {
                              return <Option value={item.currencyCode}>{item.currencyName}</Option>
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                   
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }} xl={{ span: 11 }}>
                        <Form.Item label={fm({ id: "common.kycForm.address", defaultMessage: 'Address' })}
                          {...field}
                          name={[field.name, 'address']}
                          rules={[
                            {
                              required: true, message: fm({ id: "common.kycForm.reqAddress" })
                            }
                          ]}
                        >
                          <TextArea rows={4} placeholder={fm({ id: `common.kycForm.reqAddress`, defaultMessage: `Enter Address` })} autoComplete='off' />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                {index !== 0 && <Col xs={{ span: 2 }}
                  sm={{ span: 2 }}
                  md={{ span: 2 }}
                  lg={{ span: 2 }}
                  xl={{ span: 2 }}>
                  <span style={{
                    position: "absolute",
                    marginTop: "47px",
                    marginLeft: "10px",
                  }}>
                    <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                      <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(field.name)} />
                    </Tooltip>
                  </span>
                </Col>}
              </Row>
            ))}
            <Row>
              <Col>
                <Button type="dashed" onClick={() => {
                  if (maxCount > fields.length) {
                    add()
                  } else {
                    AlertMessages.getErrorMessage(`You can't add more than ${maxCount} fields`)
                  }

                }} block icon={<PlusOutlined />}>
                  ADD New KYC Data
                </Button>
              </Col>
            </Row>
          </>)}
      </Form.List>
    </Form>
  )
}

export default KycForm;