
import { DeleteFilled, DownCircleOutlined, EditFilled, UpCircleOutlined } from '@ant-design/icons';
import { BNoRequest, BnaGridResDto, BusinessNoAllocationId, BusinessNumberService, ContractModesEnum, PcQualitiesResponseDto, PurchaseContractService, PurchaseDropDownDto, SalesContractService, SalesQualitiesResponseDto } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Descriptions, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { FormInstance } from 'rc-field-form';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../common';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router-dom';
interface IAddContractProps {
    formRef?: FormInstance<any>
    business?: string;
    drawer?: boolean;
    allocationGridState?: any;
    bargeBusiness?: string;
    allocationForm?: Boolean;
    setAllocationForm?: (value: Boolean) => void;
    setDummyRefresh: React.Dispatch<React.SetStateAction<number>>
}


export const AddContract = (props: IAddContractProps) => {
    const { business, bargeBusiness, setDummyRefresh } = props;
    const { authContext } = useAuthState();
    const [salesData, setSalesData] = useState<SalesQualitiesResponseDto[]>([]);
    const [purchases, setPurchases] = useState<PurchaseDropDownDto[]>([]);
    const purchase = new PurchaseContractService();
    const [selectedRowKeysData, setPcSelectedRowKeysData] = useState<any>();
    const [sales, setSales] = useState<any>([]);
    const [purchaseData, setPurchaseData] = useState<PcQualitiesResponseDto[]>([]);
    const [modal, setModal] = useState(false);
    const [purchaseModal, setPurchaseModal] = useState(false);
    const [selectedPcId, setSelectedPcId] = useState(undefined);
    const [selectedScId, setSelectedScId] = useState(undefined);
    const { Option } = Select;
    const [rowsSelection, setScRowsSelection] = useState<any>();
    const salesService = new SalesContractService();
    const businessService = new BusinessNumberService();
    const [formRef] = Form.useForm();
    const { formatMessage: fm } = useIntl();
    const [specification, setSpecification] = useState<any>([]);
    const [pcStandard, setPcStandard] = useState<any>([]);
    const [selectedPcType, setSelectedPcType] = useState<any>();
    const [scTypeSelectionData, setScSelectionTypeData] = useState<any>();
    const [selectedRowKeyTwo, setSelectedRowKeyTwo] = useState<React.Key[]>([]);
    const [scSelectedRowKey, setScSelectedRowKeyTwo] = useState<React.Key[]>([]);
    const [pcTypeColumnsData, setPcColumnsData] = useState<any[]>();
    const [scTypeColumnsData, setScTypeColumnsData] = useState<any[]>();
    const [allocation, setAllocation] = useState<BnaGridResDto[]>([]);
    const [contractRecord, setContractRecord] = useState<any>();
    const [allocatedQuantityRecord, setAllocatedQuantityRecord] = useState<any>(undefined)
    const [indexValue, setDecrementIndexValue] = useState<number>();
    const [requiredState, setRequiredState] = useState<boolean>(true);
    const [contractsIndex, setContractsIndex] = useState<number>();
    const [pcRowSelectedKye, setPcRowSelectedKey] = useState<any>();
    const [pctRowSelectionKye, setPctRowSelectionKey] = useState<any>();
    const [scRowSelectedKye, setScRowSelectedKey] = useState<any>();
    const [sctRowSelectionKye, setSctRowSelectionKey] = useState<any>();


    useEffect(() => {
        getAllPurchaseContracts();
        specificationStandard();
        pcSpecificationStandard();
        getAllSaleContractsForDropDown();
        // businessNoAllocatedData();
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    useEffect(() => {
        businessNoAllocatedData();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [bargeBusiness]);


    useEffect(() => {
        if (purchaseData[0]?.PcLayCan) {
            layCanDate();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [purchaseData[0]?.PcLayCan]);


    useEffect(() => {
        if (allocatedQuantityRecord?.contractType === ContractModesEnum.PURCHASE) {
            setPcSelectedRowKeysData(allocatedQuantityRecord);
            setRequiredState(false);
            allocationCreate();
        };
        if (allocatedQuantityRecord?.contractType === ContractModesEnum.SALES) {
            setScRowsSelection(allocatedQuantityRecord);
            setRequiredState(false);
            allocationCreate();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [allocatedQuantityRecord]);

    const getAllPurchaseContracts = () => {
        purchase.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setPurchases(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        });
    };

    const pcSpecificationStandard = () => {
        purchase.pcSpecificationStandard(1).then(res => {
            if (res.status) {
                setPcStandard(res.data);
            };
        }).catch(err => {
            console.log('error: ' + err.message);
        });
    };

    const getAllSaleContractsForDropDown = () => {
        salesService.getAllSaleContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setSales(res.data);
            };
        }).catch(err => {
            console.log('error: ' + err.message);
        });
    };

    const specificationStandard = () => {
        salesService.specificationStandard(2).then(res => {
            if (res.status) {
                setSpecification(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        });
    };


    const layCanDate = () => {
        if (purchaseData[0]?.PcLayCan) {
            const layCan = purchaseData[0]?.PcLayCan;
            const split = layCan.split(',')
            moment(split[0]).format('DD-MM-YYYY');
            moment(split[1]).format('DD-MM-YYYY');
        };
    };

    const showPurchaseModal = (pcId: string, record?: any) => {
        purchase.getPurchaseQualitiesData({ contractId: pcId, plantCode: authContext.defaultPlant }).then(res => {
            setPcSelectedRowKeysData(undefined);
            if (res.status) {
                setPurchaseModal(!pcId ? false : true);
                setPurchaseData(res.data);
                formRef.setFieldValue('pcSpecification', res.data[0]?.specificationStandard);
                if (record) {
                    setContractRecord(record);
                    showPurchaseModal(pcId);
                    setRequiredState(false);
                    setScSelectionTypeData(undefined);
                };
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            console.log(err.message);
        });
        setSelectedPcId(pcId ? pcId : undefined);

    };


    const showModal = (scId: string, record?: any) => {
        salesService.getQualitiesData({ contractId: scId, plantCode: authContext.defaultPlant }).then(res => {
            setScRowsSelection(undefined);
            if (res.status) {
                setModal(!scId ? false : true);
                setSalesData(res.data);
                formRef.setFieldValue('scSpecification', res.data[0]?.specificationStandard);
                if (record) {
                    setContractRecord(record);
                    showModal(scId);
                    setRequiredState(false);
                    setSelectedPcType(undefined);
                }
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            console.log(err.message);
        })
        setSelectedScId(scId ? scId : undefined);
    };

    const closePurchaseModal = () => {
        setPurchaseModal(false);
        formRef.setFieldValue('purchaseContract', undefined);
        setPcSelectedRowKeysData(undefined);
        setContractRecord(undefined);
        setPctRowSelectionKey(undefined);
        setPcRowSelectedKey(undefined);
    };

    const closeModal = () => {
        setModal(false)
        formRef.setFieldValue('salesContract', undefined);
        setScRowsSelection(undefined);
        setContractRecord(undefined);
        setSctRowSelectionKey(undefined);
        setScRowSelectedKey(undefined);
    };

    const onReset = () => {
        formRef.resetFields();
        setPcSelectedRowKeysData(undefined);
        setScRowsSelection(undefined);

    }


    const onOkPurchase = () => {
        setPurchaseModal(false);
        if (selectedRowKeysData === undefined) {
            formRef.setFieldValue('purchaseContract', undefined);
        };
    };

    const onOk = () => {
        setModal(false);
        if (rowsSelection === undefined) {
            formRef.setFieldValue('salesContract', undefined);
        };
    };

    const allocationQuantity = allocation?.filter((rec: any) => {
        const validation = allocatedQuantityRecord?.contractType === ContractModesEnum.PURCHASE ? rec.pcContractIid : allocatedQuantityRecord?.contractType === ContractModesEnum.SALES ? rec.scContractId : undefined;
        const req = allocatedQuantityRecord?.contractType === ContractModesEnum.PURCHASE ? allocatedQuantityRecord.pcId : allocatedQuantityRecord?.contractType === ContractModesEnum.SALES ? allocatedQuantityRecord.scId : undefined;
        return validation === req;
    });
    const minimumValue = { sales: rowsSelection?.quantityInMt, purchase: selectedRowKeysData?.pcQuantityInMt };
    const objectValue = Object.values(minimumValue);
    const minimumQuantityAvailable = Math.min(...objectValue);

    const minimumQuantityValue = { sales: Number(allocationQuantity[0]?.scQuantityAdjusted), purchase: Number(allocationQuantity[0]?.pcQuantityAdjusted) }
    const objectQuantityValue = Object.values(minimumQuantityValue);
    const minimumAvailableQuantity = Math.min(...objectQuantityValue);


    const allocationCreate = () => {
        formRef.validateFields().then(values => {
            if (Number(values.qualityAllocation) > minimumQuantityAvailable || Number(values.qualityAllocation) === 0) {
                AlertMessages.getErrorMessage(`Quantity allocating is greater than available/required quantity`);
                return false;
            };
            if (minimumQuantityValue) {
                if (allocatedQuantityRecord?.quantityAllocated > minimumAvailableQuantity || Number(allocatedQuantityRecord?.quantityAllocated) === 0) {
                    AlertMessages.getErrorMessage(`Quantity allocating is greater than available/required quantity`);
                    return false;
                };
            };


            const bothData: any = {
                businessNoId: business,
                salesData: rowsSelection ? rowsSelection : undefined,
                purchaseData: selectedRowKeysData ? selectedRowKeysData : undefined,
                scSpecification: values.scSpecification,
                pcSpecification: values.pcSpecification,
                qualityAllocation: allocatedQuantityRecord ? allocatedQuantityRecord.quantityAllocated : values.qualityAllocation,
                createdUser: authContext.user.userName,
                plantCode: authContext.defaultPlant,
                businessAllocationId: allocatedQuantityRecord ? allocatedQuantityRecord.businessAllocationId : contractRecord?.businessAllocationId,
                scTypeId: allocatedQuantityRecord ? allocatedQuantityRecord.scTypeId : scTypeSelectionData ? scTypeSelectionData[0]?.scTypeId : undefined,
                pcTypeId: allocatedQuantityRecord ? allocatedQuantityRecord.pcTypeId : selectedPcType ? selectedPcType[0]?.pcTypeId : undefined,
                contractType: allocatedQuantityRecord?.contractType
            }
            businessService.allocationCreate(bothData).then(res => {
                if (res.status) {
                    onReset();
                    businessNoAllocatedData();
                    setContractRecord(undefined);
                    closeModal();
                    closePurchaseModal();
                    setDecrementIndexValue(undefined);
                    setContractsIndex(null);
                    setDummyRefresh(prev => prev + 1);
                    AlertMessages.getSuccessMessage(res.internalMessage);

                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err.message);
            })
        })
    }

    const pcRowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setPcRowSelectedKey(selectedRowKeys);
            setPcSelectedRowKeysData(selectedRows[0]);
            setPcColumnsData(selectedRows[0].pcType);
            formRef.setFieldValue('pcSpecification', selectedRows[0]?.specStandard);
            // setRequiredState(!selectedRowKeysData.length? true : false);
        },
        getCheckboxProps: (record: SalesQualitiesResponseDto) => ({
            disabled: record.remaining === 0,
        }),
        selectedRowKeys: pcRowSelectedKye
    };


    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
        setSelectedPcType(selectedRows);
        setPctRowSelectionKey(newSelectedRowKeys);
    }
    const pcTypeRowSelection = {
        selectedRowKeyTwo,
        onChange: onSelectChange,
        selectedRowKeys: pctRowSelectionKye,
    };


    const scRowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setScRowSelectedKey(selectedRowKeys);
            setScRowsSelection(selectedRows[0]);
            setScTypeColumnsData(selectedRows[0].scType);
            // setRequiredState(!rowsSelection.length? true : false);
            formRef.setFieldValue('scSpecification', selectedRows[0]?.specificationStandard);
        },
        getCheckboxProps: (record: SalesQualitiesResponseDto) => ({
            disabled: record.remaining === 0,
        }),
        selectedRowKeys: scRowSelectedKye,
    };


    const scTypeRowSelectionOnChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
        setScSelectionTypeData(selectedRows);
        setSctRowSelectionKey(newSelectedRowKeys);
    };
    const scTypeRowSelection = {
        scSelectedRowKey,
        onChange: scTypeRowSelectionOnChange,
        selectedRowKeys: sctRowSelectionKye
    };

    const businessNoAllocatedData = () => {
        const req = new BNoRequest(business, authContext.defaultPlant)
        businessService.businessNoAllocatedData(req).then(res => {
            if (res.status) {
                setAllocation(res.data);
            }
            //  else {
            //     AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            // }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        });
    };


    const getAllocationGridData = (serviceData: any[]) => {
        const dataToBeSet = [];
        serviceData.forEach(rec => {
            dataToBeSet.push({
                displayAllocationId: rec.displayAllocationId,
                businessAllocationId: rec.businessAllocationId,
                contractNo: rec.pcContractNo,
                tolerance: rec.pcToleranceAdjusted,
                incoTerms: rec.pcPurchaseType,
                quantity: rec.pcQuantity,
                quality: rec.pcQualitiesQuality,
                supplierAndBuyer: rec.supplierName,
                quantityAllocated: rec.quantityAllocated,
                defaultAllocated: rec.quantityAllocated,
                remaining: Number(rec.pcQuantity) - Number(rec.pcQuantityAllocated),
                pcId: rec.pcContractIid,
                pcQId: rec.pcQualitiesId,
                pcTypeId: rec.pcTypeId,
                rowSpan: 2,
                contractType: ContractModesEnum.PURCHASE,
                isMapped: rec.isMapped,
                minRemaining: Math.min(...[Number(rec.pcQuantity) - Number(rec.pcQuantityAllocated), Number(rec.scQuantity) - Number(rec.scQuantityAllocated)])
            });
            dataToBeSet.push({
                displayAllocationId: rec.displayAllocationId,
                businessAllocationId: rec.businessAllocationId,
                contractNo: rec.scContractNo,
                scQualitiesQuality: rec.scQualitiesQuality,
                tolerance: rec.scToleranceAdjusted,
                incoTerms: rec.scSalesType,
                quantity: rec.scQuantity,
                quality: rec.scQualitiesQuality,
                supplierAndBuyer: rec.scMineName,
                quantityAllocated: rec.quantityAllocated,
                defaultAllocated: rec.quantityAllocated,
                remaining: Number(rec.scQuantity) - Number(rec.scQuantityAllocated),
                scId: rec.scContractId,
                scQId: rec.scQualitiesId,
                scTypeId: rec.scTypeId,
                rowSpan: 0,
                contractType: ContractModesEnum.SALES,
                isMapped: rec.isMapped,
                minRemaining: Math.min(...[Number(rec.pcQuantity) - Number(rec.pcQuantityAllocated), Number(rec.scQuantity) - Number(rec.scQuantityAllocated)])
            });
        })
        return dataToBeSet;

    }


    const deleteAllocation = (id: string) => {
        const req = new BusinessNoAllocationId(id);
        businessService.deleteAllocation(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                businessNoAllocatedData();
                setDummyRefresh(prev => prev + 1);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message))
    }

    const allocatedColumns: ColumnsType<any> = [
        {
            title: "Allocated Id",
            dataIndex: 'displayAllocationId',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, row: any, index) => {
                return value;
            }
        },

        {
            title: "CONTRACT NO",
            dataIndex: 'contractNo',
            width: contractsIndex === undefined ? 150 : 210,
            render: (value, record, index) => {
                let link: string;
                if (record?.contractType === ContractModesEnum.PURCHASE) {
                    link = `/#/po-detail-view?pc_id=${record.pcId}`
                } else if (record?.contractType === ContractModesEnum.SALES) {
                    link = `/#/so-detail-view?sc_id=${record.scId}`
                }
                return <>
                    {<>{contractsIndex === index ? <Select
                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                        showSearch
                        allowClear
                        placeholder="Select Purchase Contract"
                        onChange={(value) => {
                            return record?.contractType === ContractModesEnum.PURCHASE ? showPurchaseModal(value, record) : showModal(value, record)
                        }}

                    >
                        {(record?.contractType === ContractModesEnum.PURCHASE ? purchases : sales).map(item => {
                            return <Option value={item.pcId}>{item.contractAndSupplier}</Option>
                        })}
                    </Select> : <Link style={{ fontSize: 14 }} href={link} >{value}</Link>}
                        &nbsp;&nbsp;
                        <EditFilled
                            style={{ color: "#4E89FF", fontSize: 18 }}
                            onClick={() => { setContractsIndex(index); setDecrementIndexValue(undefined); }}
                        />
                    </>}
                </>
            }

        },

        {
            title: "SUPPLIER/BUYER",
            dataIndex: 'supplierAndBuyer',
        },
        {
            title: "QUALITY",
            dataIndex: 'quality',
        },
        {
            title: "INCO TERMS",
            dataIndex: 'incoTerms',
        },
        {
            title: "QUANTITY",
            dataIndex: 'quantity',
            render: (value, record) => {
                return <>
                    {value < 0 || isNaN(value) ? 0 : value}
                </>
            }
        },
        {
            title: "Allocated Quantity",
            dataIndex: 'quantityAllocated',
            width: indexValue === undefined ? 150 : 230,
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record, index) => {
                return <>
                    {value ? <>
                        <Tooltip title='Increase Allocation'>
                            <UpCircleOutlined
                                style={{ fontSize: 18 }}
                                onClick={() => {
                                    if (record.minRemaining <= 0) {
                                        AlertMessages.getErrorMessage(`You can't allocate more quantity`);
                                    } else {
                                        setDecrementIndexValue(index);
                                        setRequiredState(false);
                                    }
                                }}
                                className={record.minRemaining <= 0 ? 'disabled-Edit-Icon' : 'Editable-Icon'}
                            />
                        </Tooltip>
                        &nbsp;&nbsp;
                        {<span style={{ fontSize: 14 }}>
                            {indexValue === index ?
                                <><InputNumber
                                    defaultValue={value}
                                    onChange={(value) => {
                                        if (value <= Number(record.defaultAllocated) + Number(record.minRemaining) && value > 0) {
                                            record.quantityAllocated = value;
                                        } else {
                                            record.quantityAllocated = Number(record.defaultAllocated) + Number(record.minRemaining);
                                        }
                                    }}
                                    style={{ width: 70 }}
                                    min={0}
                                    max={Number(record.defaultAllocated) + Number(record.minRemaining)}
                                />
                                    &nbsp;
                                    <Button
                                        onClick={() => {
                                            setAllocatedQuantityRecord(record);
                                            setRequiredState(false);
                                        }} type='primary'
                                    >Update
                                    </Button>
                                </> : value}
                        </span>}&nbsp;&nbsp;
                        <Tooltip title='Decrease Allocation'>
                            <DownCircleOutlined
                                style={{ color: "#4E89FF", fontSize: 18 }}
                                onClick={() => {
                                    setDecrementIndexValue(index);
                                    setRequiredState(false);
                                    setContractsIndex(undefined);
                                }}
                            />
                        </Tooltip>
                    </> : <></>}
                </>;
            }
        },
        {
            title: "REMAINING",
            dataIndex: 'remaining',
            render: (value, record) => {
                return <>
                    {value < 0 || isNaN(value) ? 0 : value}
                </>
            }
        },
        {
            title: 'Action',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                return <>
                    <Popconfirm
                        onConfirm={() => {
                            deleteAllocation(record.businessAllocationId)
                        }}
                        title='Are you sure to Delete Record ?'
                    >
                        <DeleteFilled hidden={record.isMapped} style={{ color: "red", fontSize: 18 }} />
                    </Popconfirm></>;
            }
        }
    ];



    const tableColumns: any = [
        {
            title: "Laycan",
            dataIndex: 'layCan',
            align: 'center',
            key: '1',
            render: (value, record) => { return record?.layCan ? `${moment(record.layCan.split(',')[0]).format('DD MMM YYYY')} - ${moment(record.layCan.split(',')[1]).format('DD MMM YYYY')}` : ''; }
        },
        {
            title: "Quality",
            dataIndex: 'quality',
            align: 'center',
            key: '2',

        },
        {
            title: 'Tolerance',
            dataIndex: 'tolerance',
            align: 'center',
            render: (value) => {
                return <>
                    {value + " %"}
                </>
            }
        },

        {
            title: 'Purchasing Term',
            dataIndex: 'purchasingTerm',
            align: 'center',
            key: '7',

        },
        {
            title: "Quantity",
            dataIndex: 'quantityInMt',
            align: 'center',
            key: '8'

        },
        {
            title: "Remaining",
            dataIndex: 'remaining',
            align: 'center',
            key: '9'
        },


    ]

    const scTypeColumns: ColumnsType<any> = [
        {
            title: 'MINE NAME',
            dataIndex: 'bpName',
        },
        {
            title: 'Sales TYPE',
            dataIndex: 'salesType',
        },
        {
            title: 'JETTY NAME',
            dataIndex: 'jettyName',
        },
        {
            title: 'COAL PRICE',
            dataIndex: 'averageCoalIndexPrice',
        },
    ]

    const pcQualityColumns: ColumnsType<any> = [
        {
            title: "Laycan",
            dataIndex: 'PcLayCan',
            align: 'center',
            render: (layCan, row) => {
                return layCan ? `${moment(layCan.split(',')[0]).format('DD MMM YYYY')} - ${moment(layCan.split(',')[1]).format('DD MMM YYYY')}` : ''
            }
        },
        {
            title: "Quality",
            dataIndex: 'pcQuality',
            align: 'center',
        },
        {
            title: 'Tolerance',
            dataIndex: 'pcTolerance',
            align: 'center',
            render: (value) => {
                return <>
                    {value + " %"}
                </>
            }
        },
        {
            title: 'Purchasing Term',
            dataIndex: 'pcPurchasingTerm',
            align: 'center'
        },
        {
            title: "Quantity",
            dataIndex: 'pcQuantityInMt',
            align: 'center'
        },
        {
            title: "Remaining",
            dataIndex: 'remaining',
            align: 'center'
        }
    ]


    const pcTypeColumns: ColumnsType<any> = [
        {
            title: 'MINE NAME',
            dataIndex: 'bpName',
        },
        {
            title: 'PURCHASE TYPE',
            dataIndex: 'purchaseType',
        },
        {
            title: 'JETTY NAME',
            dataIndex: 'jettyName',
        },
        {
            title: 'COAL PRICE',
            dataIndex: 'pcAverageCoalIndexPrice',
        },
    ]


    return (
        <>
            {/* {allocationForm ? <Button type='primary' style={{ float: 'right' }} onClick={() => { setAllocationForm(!allocationForm); formRef.resetFields() }}> Allocate</Button> : <Button type='primary' danger style={{ float: 'right' }} onClick={() => setAllocationForm(!allocationForm)}>Cancel</Button>}
            <br /><br /> */}
            {/* {allocationForm ? <></> : <> */}


            <Form
                layout="vertical"
                title="Purchase Contract"
                form={formRef}
                initialValues={undefined}
            >
                <Row >
                    <Col span={8}>
                        <Form.Item
                            label={fm({ id: 'mdm.components.purchaseContract', defaultMessage: "Purchase Contract" })}
                            name="purchaseContract"
                            rules={
                                [
                                    { required: requiredState, message: fm({ id: 'mdm.components.purchaseContract', defaultMessage: " Please Select Purchase Contract" }) },
                                ]}
                        >
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                showSearch
                                allowClear
                                placeholder="Select  Purchase Contract"
                                // onSelect={() => handleDropdownSelect()}
                                onChange={(pcID) => showPurchaseModal(pcID)}
                            >
                                {purchases.map(item => {
                                    return <Option value={item.pcId}>{item.contractAndSupplier}</Option>
                                })}
                            </Select>
                        </Form.Item>

                        <Descriptions.Item label="Product">Purchase Contract:<b> {contractRecord?.businessAllocationId || allocatedQuantityRecord ? undefined : selectedRowKeysData?.contractNo}</b></Descriptions.Item><br /><br />
                        <Descriptions.Item label="Billing">	Quantity: <b>{contractRecord?.businessAllocationId || allocatedQuantityRecord ? undefined : selectedRowKeysData?.remaining}</b></Descriptions.Item><br /><br />

                    </Col>

                    <Col span={8}>
                        <Form.Item name={"salesContract"} label={'Sales Contract'} rules={[{ required: requiredState, message: 'Please Select Sales Contract' }]}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                showSearch
                                allowClear
                                placeholder="Select Sales Contract"
                                onChange={(scId) => showModal(scId)}
                            >
                                {/* <Option value={' '}> Select Sales Contract</Option> */}
                                {sales.map(item => { return <Option value={item.pcId}>{item.contractAndSupplier}</Option> })}
                            </Select>

                        </Form.Item>

                        <Descriptions.Item label="Product">Sales Contract: <b>{contractRecord?.businessAllocationId || allocatedQuantityRecord ? undefined : rowsSelection?.contractNo}</b></Descriptions.Item><br /><br />
                        <Descriptions.Item label="Billing">	Quantity: <b>{contractRecord?.businessAllocationId || allocatedQuantityRecord ? undefined : rowsSelection?.remaining}</b></Descriptions.Item><br /><br />

                    </Col>

                    <Col span={8} >

                        <Form.Item name={"qualityAllocation"} rules={[{ required: requiredState }]} label={"Quantity Allocation"}>

                            <Input placeholder="Quantity Allocation" autoComplete='off' />
                        </Form.Item>
                        <Descriptions.Item>
                            Available Allocation Quantity :<b>{!minimumQuantityAvailable || contractRecord?.businessAllocationId ? undefined : minimumQuantityAvailable}</b>
                        </Descriptions.Item>
                    </Col>

                </Row>
                <Row>

                    <Col span={8}></Col>

                    <Col span={8}>
                        <Form.Item
                            name={"pcSpecification"}
                            style={{ display: 'none' }}
                            label={"Purchase Specification"}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} showSearch
                                allowClear
                                placeholder="Select Purchase Specification"
                            >
                                <Option>
                                    Please Select
                                </Option>
                                {specification.map(rec => { return <Option value={rec.specificationStandard}>{rec.specificationStandard}</Option> })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}></Col>

                </Row>

                <Row gutter={24}>

                    <Col span={8}>


                    </Col>

                    <Col span={8}></Col>
                    <Col span={8}>

                        <Form.Item name={"scSpecification"} label={"Sales Specification"} style={{ display: 'none' }}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} showSearch
                                allowClear
                                placeholder="Select Sales Specification"
                            >
                                <Option>
                                    Please Select
                                </Option>
                                {pcStandard.map(rec => { return <Option value={rec.specificationStandard}>{rec.specificationStandard}</Option> })}
                            </Select>

                        </Form.Item>
                    </Col>


                </Row>

                <br />

                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 18 }}>
                        <Form.Item>
                            {/* <Button
                                    >Calculate</Button> */}
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }}>
                        <Form.Item>
                            <Button onClick={onReset}
                            >Clear</Button>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3 }}>
                        <Form.Item>
                            <Button
                                type="primary" htmlType="submit" onClick={() => { allocationCreate() }}
                            >Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <Modal
                key={"scId" + selectedScId}
                cancelButtonProps={{ style: { display: 'none' } }}
                title="Quality Selection"
                open={modal}
                onCancel={closeModal}
                onOk={contractRecord?.businessAllocationId ? allocationCreate : onOk}
                okText='Submit'
                width={1000}
                okButtonProps={{ disabled: scTypeSelectionData === undefined ? true : false }}

            >
                <Card title='Sales Quality Selection'>
                    <Table
                        columns={tableColumns}
                        dataSource={salesData}
                        pagination={false}
                        rowSelection={{ ...scRowSelection, type: "radio" }}
                        rowKey={record => record?.scQualitiesId}
                    >

                    </Table>
                </Card>

                <br />
                {rowsSelection ? <Card title='Select Sales Type'>
                    <Table
                        columns={scTypeColumns}
                        dataSource={scTypeColumnsData}
                        pagination={false}
                        rowSelection={{ ...scTypeRowSelection, type: 'radio' }}
                        rowKey={record => record.scTypeId}
                    >
                    </Table>
                </Card> : <></>}


            </Modal>

            <Modal
                key={'pcId' + selectedPcId}
                cancelButtonProps={{ style: { display: 'none' } }}
                title="Quality Selection"
                open={purchaseModal}
                onCancel={closePurchaseModal}
                // footer={true}
                onOk={contractRecord?.businessAllocationId ? allocationCreate : onOkPurchase}
                okText='Submit'
                width={1000}
                okButtonProps={{ disabled: selectedPcType === undefined ? true : false }}


            >
                <Card title={'Select Purchase Quality'}>

                    <Table
                        columns={pcQualityColumns}
                        dataSource={purchaseData}
                        pagination={false}
                        rowKey={record => record?.pcQualitiesId}
                        rowSelection={{ ...pcRowSelection, type: "radio" }}

                    >

                    </Table>
                </Card>
                <br></br>
                {selectedRowKeysData ? <Card title={'Select Purchase Type'}>
                    <Table
                        columns={pcTypeColumns}
                        dataSource={pcTypeColumnsData}
                        rowSelection={{ ...pcTypeRowSelection, type: "radio" }}
                        rowKey={record => record.pcTypeId}
                        pagination={false}
                    >
                    </Table>
                </Card> : <> </>}
            </Modal>
            {/* </>} */}

            <Table
                rowKey={record => { return record.pcContractIid + record.businessAllocationId + record.scContractId }}
                columns={allocatedColumns}
                bordered
                pagination={false}
                dataSource={getAllocationGridData(allocation)}
            ></Table>

        </>
    )
}

export default AddContract







