import * as antdIcons from '@ant-design/icons';
import { IconType, MenusDto } from '@exportx/shared-models-and-services';
import { ClassValidator } from '@exportx/ui-utils';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuthState } from '../../../common';

interface IMenuFormProps {
    submitHandler: (req: MenusDto) => void;
    initialValues?: MenusDto
}

const { Option } = Select;
export const MenuForm = (props: IMenuFormProps) => {
    const { formatMessage: fm } = useIntl();
    const classValidator = new ClassValidator();
    const { authContext } = useAuthState()
    const [formRef] = Form.useForm();
    const { submitHandler, initialValues } = props;
    const [iconType, setIconType] = useState(IconType.SYS_LIB);

    const onSubmit = () => {
        formRef.validateFields().then(values => {
            const req = new MenusDto(authContext.user.userName, authContext.user.id, values.menuId, values.menuName, values.order, values.iconType, values.iconName, values.path, values.component, true, values.versionFlag, values.moduleId, undefined, values.applicationId, undefined);
            submitHandler(req);
        }).catch(err => {
            console.log(err.message, 'error msg')
        })
    }
    const onIconTypeChange = (iconType: IconType) => {
        setIconType(iconType);
        formRef.setFieldValue('iconName', '');
    };

    return (
        <div>
            <Form layout='vertical'
                form={formRef} initialValues={initialValues}>
                <Row>
                    <Col>
                        <Form.Item style={{ display: 'none' }} name='moduleId'>
                            <Input type='hidden' />
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }} name='menuId'>
                            <Input type='hidden' />
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }} name='applicationId'>
                            <Input type='hidden' />
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }} name='versionFlag'>
                            <Input type='hidden' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={11} lg={11} xl={11} xxl={11}>
                        <Form.Item name='menuName' label={fm({ id: "menu.common.menuName", defaultMessage: 'Menu Name' })}
                            rules={[{ required: true, message: fm({ id: 'menu.form.rules.menuNameRequired', defaultMessage: 'Please give Menu Name' }) }, ...classValidator.alphaSpaces()]}>
                            <Input placeholder={fm({ id: "menu.common.menuName", defaultMessage: 'Menu Name' })} /></Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={11} xl={11} xxl={11} offset={2}>
                        <Form.Item name='order' label={fm({ id: "menu.common.order", defaultMessage: 'Order' })}
                            rules={[{ required: true, message: fm({ id: 'menu.form.rules.orderRequired', defaultMessage: 'Please give Order' }) }, { pattern: new RegExp(/^[0-9]*$/), message: fm({ id: 'menu.form.rules.orderPattern', defaultMessage: 'Order should contain numbers' }) }]}>
                            <Input placeholder={fm({ id: "menu.common.order", defaultMessage: 'Order' })} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24} lg={11} xl={11} xxl={11}>
                        <Form.Item
                            label={fm({ id: "menu.common.iconType", defaultMessage: "Icon Type" })}
                            name="iconType"
                            rules={[{ required: true, message: fm({ id: 'menu.form.rules.iconTypeRequired', defaultMessage: 'Please select Icon Type' }) },]}>
                            <Select onChange={onIconTypeChange}>
                                {
                                    Object.keys(IconType).map(rec => {
                                        return <Option value={IconType[rec]}>{IconType[rec]}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={11} xl={11} xxl={11} offset={2}>
                        <Form.Item name='iconName' label={fm({ id: "submenus.common.iconName", defaultMessage: 'Icon Name' })}
                            rules={[{ required: true, message: fm({ id: 'submenus.form.iconNameRequired', defaultMessage: ' Icon Name is required' }) }, { pattern: new RegExp(/^[A-Za-z]+$/) }]}>
                            {
                                iconType === IconType.SYS_LIB ?
                                    <Select placeholder={fm({ id: "submenus.common.iconName", defaultMessage: 'Icon Name' })} filterOption={(input, option) => (option!.children as unknown as string).toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: '100%' }}>
                                        {
                                            Object.keys(antdIcons).map(rec => {
                                                return <Option value={rec}>{rec}</Option>
                                            })
                                        }
                                    </Select> :
                                    <Input placeholder={fm({ id: "submenus.common.iconName", defaultMessage: 'Icon Name' })} />
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={24} lg={11} xl={11} xxl={11}>
                        <Form.Item name='path' label={fm({ id: "submenus.common.path", defaultMessage: 'Path' })}
                            rules={[
                                { required: false, message: fm({ id: 'submenus.form.pathRequired', defaultMessage: 'path  is required' }) }
                            ]}>
                            <Input placeholder={fm({ id: "submenus.common.path", defaultMessage: 'Enter Path' })} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} lg={11} xl={11} xxl={11} offset={2}>
                        <Form.Item name='component' label={fm({ id: "submenus.common.component", defaultMessage: 'Component' })}
                            rules={[
                                { required: false, message: fm({ id: 'submenus.form.componentRequired', defaultMessage: 'component is required' }) },
                                // { pattern: new RegExp(/^[A-Za-z]+$/) }
                                { pattern: new RegExp(/^[A-Za-z\s]*$/) }
                            ]}>
                            <Input placeholder='component' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='end'>
                    <Col>
                        <Button onClick={onSubmit} type='primary'>Submit</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}