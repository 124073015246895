import { Card, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React from 'react'

export const GarPenalty = () => {

  const columns: ColumnsType<any> = [
    {
      title: 'Units',
      dataIndex: 'units'
    },

    {
      title: 'Basis',
      dataIndex: 'basis'
    },
    {
      title: 'Typical',
      dataIndex: 'typical'
    },

    {
      title: 'Rejection',
      dataIndex: 'rejection'
    },
    {
      title: 'Actual',
      dataIndex: 'actual'
    },
    {
      title: 'Bonus Cap',
      dataIndex: 'bonusCap'
    },
    {
      title: 'Penalty Cap',
      dataIndex: 'penaltyCap'
    },
  ]



  return (
    <Card title='GAR Penalty / Bonus per MT'>

      <Table columns={columns} dataSource={undefined}></Table>


    </Card>
  )
}

export default GarPenalty