import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons'
import { ContractStatusEnum, DebitCreditService, NoteTypeReq } from '@exportx/shared-models-and-services'
import { Button, Card, Col, Drawer, Form, Radio, RadioChangeEvent, Row, Table } from 'antd'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useAuthState } from '../../../../common'
import DebitCreditNoteEntry from '../debit-credit-note-entry/debit-credit-note-entry'
import DebitCreditNoteChild from './debit-credit-note-child'

interface IProps {
    noteType?: string;
    type?: ContractStatusEnum
}


export default function DebitCreditNoteApproval(props?: IProps) {

    const [data, setData] = useState<any[]>([])
    const [noteType, setNoteype] = useState<any>();
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    const [expandedIndex, setExpandedIndex] = useState([]);
    const { authContext } = useAuthState();
    const [form] = Form.useForm();
    const service = new DebitCreditService();

    const options = [
        { label: 'Debit Note', value: 'Debit Note' },
        { label: 'Credit Note', value: 'Credit Note' },
    ];

    useEffect(() => {
        getData()
    }, [props.noteType])


    const getData = () => {
        const req = new NoteTypeReq(props.noteType, authContext.defaultPlant)
        service.getDataForApproval(req).then((res) => {
            if (res.status) {
                setData(res.data)
            } else {
                setData([])
            }
        }).catch((err) => {
            console.log('err', err)
        })
    };



    const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
        setNoteype(value);
    };


    const columns = [
        {
            title: `${props.noteType} No#`,
            dataIndex: 'refrenceNo',
            render: (value, record) => {
                return <>
                    <a href={`/#/debit-credit-note-entry?reference_id=${record.debCreId}`}>{value}</a>
                </>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            render: (text) => moment(text).format('DD-MM-YYYY')
        },

        {
            title: 'Account',
            dataIndex: 'account'
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount'
        }
    ]


    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.debCreId);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    }
    const renderItems = (record: any, index, indent, expanded) => {
        return <>
            <DebitCreditNoteChild key={record.debCreId} debCreId={record.debCreId} />
        </>
    };
    return (
        <Card title={`${props.noteType} Approval`}>
            <Form form={form} autoComplete='off'>
                {/* <Row gutter={24} justify='center'>
                    <Col span={6} >
                        <Form.Item name='noteType' label='Note Type:'   >
                            <Radio.Group options={options} onChange={radioOnChange} value={noteType} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button type='primary' onClick={() => { getData() }}>Get Data</Button>
                    </Col>
                </Row> */}
                <Row>
                    <Table
                        size='large'
                        className='contracts'
                        // scroll={{ x: true, y: 1000 }}
                        rowKey={(row) => row.debCreId}
                        columns={columns}
                        dataSource={data}
                        expandIconColumnIndex={0}
                        expandable={{
                            expandedRowRender: renderItems,
                            expandedRowKeys: expandedIndex,
                            onExpand: setIndex,
                            fixed: 'right'
                        }}
                        expandIcon={({ expanded, onExpand, record }) =>
                            expanded ? (
                                <UpCircleFilled
                                    onClick={(e) => onExpand(record, e)}
                                >
                                    Collapse
                                </UpCircleFilled>
                            ) : (
                                <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
                            )
                        }
                    />
                </Row>


            </Form>
            <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
                onClose={() => setDrawerVisible(false)} open={drawerVisible} closable={true}>
                <DebitCreditNoteEntry />
            </Drawer>
        </Card>
    )
}
