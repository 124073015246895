import { DeleteOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { AccountsService, AgainstDbCrGetDataDto, CompanyCodeReq, CompanyTdsService, CurrencyDto, CurrencyService, DebitCredit, DebitCreditAgainstEnum, DebitCreditService, DebitCreditTransactionsDto, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, ReferencesReqDto, TaxesAndItemsService, TdsResModel } from "@exportx/shared-models-and-services";
import { getBase64 } from "@exportx/ui-utils";
import { Button, Card, Checkbox, Col, Divider, Drawer, Form, Input, Radio, RadioChangeEvent, Row, Select, Typography, Upload } from "antd";
import Link from "antd/es/typography/Link";
import TextArea from "antd/lib/input/TextArea";
import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import queryString from 'query-string';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { AlertMessages, isPermissionExist, useAuthState } from "../../../../common";
import DatePicker from "../../../../common/data-picker/date-picker";
import DebitCreditRejectedEntries from "./debit-credit-rejected-entries";
import { UploadOutlined } from '@ant-design/icons';

export interface DebitCreditNoteEntryProps {
    isUpdate?: true,
    debCreId?: string,
    noteType: string
}

export default function DebitCreditNoteEntry(props: DebitCreditNoteEntryProps) {
    const { Option } = Select
    const { authContext } = useAuthState()
    const [debitCreditTrans, setDebitCreditTrans] = useState<any[]>([])
    const [formRef] = Form.useForm()
    const [noteType, setNoteype] = useState<any>(props.noteType)
    const { Text } = Typography;
    const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
    const [taxes, setTaxes] = useState<GetTaxesByBpIdDto[]>([]);
    const [accountsData, setAccountsData] = useState<any[]>([])
    const [against, setAgainst] = useState<any>()
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    const [editData, setEditData] = useState<DebitCredit>()
    const companyTdsService = new CompanyTdsService();
    const taxesAndItemsService = new TaxesAndItemsService();
    const accountsService = new AccountsService()
    const debitCreditService = new DebitCreditService()
    const [subTotalValue, setSubTotalValue] = useState<number>()
    const [dummyRefresh, setDummyRefresh] = useState(1);
    const [editedRowIndex, setEditedRowIndex] = useState<number>();
    const [totalAmount, setTotalAmount] = useState<any>({ vat: 0, tds: 0, total: 0, taxPercentage: 0, tdsPercent: 0 })
    const [referenceData, setReferenceData] = useState<AgainstDbCrGetDataDto[]>();
    const [regNumber, setRegNumber] = useState<number>();
    const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);
    const currencyService = new CurrencyService();
    const location = useLocation();
    const paramCpcId = queryString.parse(location.search)?.reference_id;
    // let { reference_id }: any = paramCpcId != null ? { cpcId: paramCpcId } : { cpcId: null };
    const [fileList, setFileList] = useState([]);


    useEffect(() => {
        getAllSundryAccounts()
        getTdsData()
        getTaxesByItemsId()
        getCurrency()
    }, []);

    useEffect(() => {
        formRef.setFieldsValue(editData);
        setAgainst(editData?.debitCreditTransactions[0]?.against)
        formRef.setFieldValue('against', editData?.debitCreditTransactions[0]?.against)
        setDebitCreditTrans(editData?.debitCreditTransactions ? editData?.debitCreditTransactions : []);
        accountOnChangeHandler(editData?.account, editData?.noteType);
        formRef.setFieldValue('tdsId', editData?.tdsId)
        getTaxesByItemsId()
        vatOnchange(editData?.vatApplicable);
        tdsOnchange(editData?.tdsId);
        if (editData) {
            setFileList(editData?.filesData)
            setTotalAmount((prev => {
                return {
                    ...prev,
                    vat: Number(editData?.taxTotal),
                    tds: Number(editData?.tdsTotal),
                    total: Number(editData?.totalAmount),
                    taxPercentage: Number(editData?.taxPercentage),
                    tdsPercent: Number(editData?.tdsPercentage),
                    tdsId: editData?.tdsId,
                    taxId: editData?.taxId,
                }
            }))
        }

    }, [editData]);

    useEffect(()=>{
        setNoteype(props.noteType)
        formRef.setFieldValue('noteType', props.noteType);
    },[props.noteType])


    // const getData = () => {
    //     const req = new NoteTypeReq(form.getFieldValue('noteType'), authContext.defaultPlant)
    //     service.getRejectedEntries(req).then((res) => {
    //         if (res.status) {
    //             setData(res.data);
    //         } else {
    //             setData([]);
    //         }
    //     }).catch((err) => {
    //         console.log('err', err)
    //     });
    // };

    // useEffect(() => {
    //     editHandler(reference_id)
    // }, [reference_id])



    const getCurrency = () => {
        currencyService.getAllCurrenciesForDropDown().then(res => {
            if (res.status) {
                setCurrencies(res.data);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message));
    };



    function editTableData(rowData, index) {
        formRef.setFieldsValue(rowData)
        setEditedRowIndex(index)

    };

    const addRecord = () => {
        formRef.validateFields().then(values => {
            if (values) {
                const result = [...debitCreditTrans];
                const findIndexOfDuplicateRecord = debitCreditTrans.findIndex(rec => rec.refrence === values.refrence);
                if (findIndexOfDuplicateRecord !== -1) {
                    result[findIndexOfDuplicateRecord] = { ...values, amount: Number(debitCreditTrans[findIndexOfDuplicateRecord].amount) + Number(values.amount) }
                } else {
                    result.push(values);
                }
                if (editedRowIndex == undefined) {
                    setDebitCreditTrans(result)
                    const sum = result.reduce((acc, array) => Number(acc) + Number(array.amount), 0)
                    setTotalAmount(prev => { return { ...prev, total: Number(prev.vat + prev.tds + Number(sum)) } });
                    formRef.resetFields(['refrence', 'amount', 'costingCurrency'])
                } else {
                    let result1;
                    setDebitCreditTrans(prevState => { debitCreditTrans[editedRowIndex] = values; return result1 = [...debitCreditTrans] });
                    const sum = result1.reduce((acc, array) => Number(acc) + Number(array.amount), 0)
                    setTotalAmount(prev => { return { ...prev, total: Number(prev.vat + prev.tds + Number(sum)) } })
                    setEditedRowIndex(undefined);
                };
                if (values.tdsId) {
                    tdsOnchange(values.tdsId);
                }
                if (totalAmount?.vatApplicable) {
                    vatOnchange(totalAmount?.vatApplicable);
                }
            };

        });


    }


    const deleteRecord = (index) => {
        const results = [...debitCreditTrans]
        results.splice(index, 1)
        setDebitCreditTrans(results)
    }


    const tableColumns: ColumnsType<any> = [
        {
            title: "Against",
            dataIndex: "against"
        },
        {
            title: "Refrence",
            dataIndex: "referenceForTable",
        },
        // {
        //     title: "RefrenceDummy",
        //     dataIndex: "costingCurrency",
        // },
        {
            title: "Amount",
            dataIndex: "amount",
            align: 'right',
            render: (value, record) => <span>{Number(value).toFixed(2) + " " + record?.costingCurrency}</span>
        },
        {
            title: 'Action',
            render: (text, row, index) => {
                return <span>
                    <DeleteOutlined type='delete' onClick={() => deleteRecord(index)} style={{ color: 'red', fontSize: '18px' }} />
                    <EditOutlined className={'editSamplTypeIcon'} type="edit"
                        onClick={() => editTableData(row, index)}
                        style={{ color: '#1890ff', fontSize: '14px' }}
                    />
                </span>

            }
        }

    ]


    const getTaxesByItemsId = () => {
        const req = new ItemsIdRequestDto(ItemsCodesEnum.BARGE, moment(formRef.getFieldValue('date')).format("YYYY-MM-DD"));
        taxesAndItemsService.getTaxesByItemsId(req).then(res => {
            if (res.status) {
                setTaxes(res.data);
            } else {
                setTaxes([]);
            }
        }).catch(err => {
            console.log(err);
            setTaxes([]);
        });
    }


    const vatOnchange = (e: boolean) => {
        if (e) {
            const taxId = taxes[0]?.taxId ? taxes[0]?.taxId : null;
            const taxPercentage = taxes[0]?.taxPercentage ? Number(taxes[0]?.taxPercentage) : 0;
            setTotalAmount((prevState) => {
                const vatValue = (taxPercentage / 100) * Number(prevState?.total);
                return { ...prevState, vat: vatValue, taxId: taxId, taxPercentage: taxPercentage, total: (prevState.total + Number(vatValue)), vatApplicable: e }
            }
            )
        } else {
            setTotalAmount(prevState => {
                const vatValue = 0;
                return {
                    ...prevState,
                    vat: vatValue,
                    total: prevState.total - prevState.vat,
                    vatApplicable: false
                }
            })
        }

    }


    const getTdsData = () => {
        const req = new CompanyCodeReq();
        req.company = authContext.defaultPlant;
        req.date = formRef.getFieldValue('date') ? formRef.getFieldValue('date') : moment();
        companyTdsService.getTdsByCompanyCode(req).then(res => {
            if (res.status) {
                setTdsModel(res.data);
            } else {
                setTdsModel([]);
            }
        }).catch(err => {
            console.log(err);
            setTdsModel([]);
        });
    }

    function tdsOnchange(tdsId) {
        const obj = tdsModel.filter(res => res.companyTdsId === tdsId)[0];
        const tdsPercentage = obj?.tdsValue ? obj?.tdsValue : 0;
        setTotalAmount((prev) => {
            const tdsTotal = (tdsPercentage / 100) * Number(prev.total)

            //     return { ...prev, tdsId: tdsId,
            //          total: Number((subTotalValue + tdsTotal).toFixed(2)), tds: Number((tdsTotal).toFixed(2)), tdsPercent: Number((tdsPercentage).toFixed(2)) }
            // })
            return { ...prev, tdsId: tdsId, total: (Number(tdsTotal.toFixed(2)) + prev.total - prev.tds), tds: Number((tdsTotal).toFixed(2)), tdsPercent: Number((tdsPercentage).toFixed(2)) }
        })
    }
    const closeRejectionDrawer = () => {
        setDrawerVisible(false)
        setDummyRefresh(prev => prev + 1);

    }


    function onSubmitForApproval() {
        formRef.validateFields().then(async values => {
            console.log(values)
            setEditData(values.debitCreditTransactionsId)
            setEditData(values.debitCreditId)
            const formFilesData = formRef.getFieldValue('filesData');
            let filesData = []
            if (formFilesData?.file) {
                const base64 = await getBase64(formFilesData.file.originFileObj);
                filesData = [{
                    ...formFilesData.file,
                    base64Url: base64,
                    fileDescription: 'Invoice file'
                }];
            }
            const debitCreditTransactionsDto: DebitCreditTransactionsDto[] = []
            debitCreditTrans.forEach((el) => {
                const childDto = new DebitCreditTransactionsDto(el.amount, el.against, el.refrence, el.account, true, authContext.user.userName, undefined, values.debitCreditTransactionId, values.debitCreditId, undefined, undefined, undefined, undefined, undefined, el.costingCurrency)
                debitCreditTransactionsDto.push(childDto);
            })
            const parentDto = new DebitCredit(values.debitCreditNo, values.date, totalAmount.taxPercentage, totalAmount.vat, totalAmount.tdsPercent, totalAmount.tds, totalAmount.total, values.remarks, authContext.defaultPlant, totalAmount.taxId, values.tdsId, values.noteType, debitCreditTransactionsDto, filesData, true, authContext.user.userName, 1, values.debitCreditId, undefined, undefined, undefined, undefined, totalAmount.vatApplicable)
            debitCreditService.createDebitCreditNoteEntry(parentDto).then((res) => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000)
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => console.log(err.message))
        }).catch(err => console.log(err.message))
    }

    const getAllSundryAccounts = () => {
        accountsService.getAllSundryAccounts().then((res) => {
            if (res.status) {
                setAccountsData(res.data)
            } else {
                setAccountsData([])
            }
        })
    }


    const options = [
        ...(props.noteType === 'Credit Note') ? [
            { label: 'Credit Note', value: 'Credit Note' },

        ] : [
            { label: 'Debit Note', value: 'Debit Note' }
        ]
    ];

    const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
        setNoteype(value);
        againstOnChange(formRef.getFieldValue('against'), value);
    };

    const onFileChange = (data) => {
        setFileList([data.file]);
      }

    const againstOnChange = (value, typeOfNote?: any) => {
        if (value) {
            setAgainst(value);
            const req = new ReferencesReqDto(value, authContext.defaultPlant, formRef.getFieldValue('account'), typeOfNote ? typeOfNote : noteType)
            accountsService.getReferences(req).then(res => {
                if (res.status) {
                    setReferenceData(res.data);
                } else {
                    setReferenceData([]);
                    formRef.setFieldValue('amount', undefined);
                    formRef.setFieldValue('refrence', undefined);
                    formRef.setFieldValue('referenceForTable', undefined);
                    formRef.setFieldValue('costingCurrency', undefined);

                };
            }).catch(err => console.log(err.message));
        } else {
            setAgainst(undefined);
            formRef.setFieldValue('amount', undefined);
            formRef.setFieldValue('refrence', undefined);
            formRef.setFieldValue('referenceForTable', undefined);
            formRef.setFieldValue('costingCurrency', undefined);
        };

    };

    const referenceOnChangeHandler = (value, option) => {
        // console.log(value, option)
        // formRef.setFieldValue('amount', option?.outstanding);
        // if (!debitCreditTrans.length) {
        //     formRef.setFieldValue('amount', option?.outstanding);
        // }
        formRef.setFieldValue('referenceForTable', option?.children);
        validationOfAmount(value)
    };

    const validationOfAmount = (value) => {
        if (value) {
            if (formRef.getFieldValue('against') === DebitCreditAgainstEnum.ON_REFERENCE) {
                const remainingAmount = Number(referenceData.find((rec) => rec.costingId === value)?.outstanding);
                const costingCurrency = referenceData.find((rec) => rec.costingId === value)?.costingCurrency;
                const amount = Number(debitCreditTrans.find((rec) => rec.refrence === value)?.amount);
                if (amount) {
                    setRegNumber(remainingAmount - amount);
                    formRef.setFieldValue('amount', remainingAmount - amount);
                } else {
                    setRegNumber(remainingAmount);
                    formRef.setFieldValue('amount', remainingAmount);
                };
                formRef.setFieldValue('costingCurrency', costingCurrency);

            } else if (formRef.getFieldValue('against') === DebitCreditAgainstEnum.ON_INVOICE) {
                const remainingAmount = Number(referenceData.find((rec) => rec.invoiceId === value?.split('@#$')[1])?.outstanding);
                const costingCurrency = referenceData.find((rec) => rec.invoiceId === value?.split('@#$')[1])?.costingCurrency;
                const amount = Number(debitCreditTrans.find((rec) => rec.refrence === value)?.amount)
                if (amount) {
                    setRegNumber(remainingAmount - amount);
                    formRef.setFieldValue('amount', remainingAmount - amount);

                } else {
                    setRegNumber(remainingAmount);
                    formRef.setFieldValue('amount', remainingAmount);
                };
                formRef.setFieldValue('costingCurrency', costingCurrency);

            };
        } else {
            formRef.setFieldValue('amount', 0);
            formRef.setFieldValue('costingCurrency', undefined);

        }

    }
    const accountOnChangeHandler = (value, noteType?: string) => {
        againstOnChange(formRef.getFieldValue('against'), noteType)
    };

    const amountOnchangeHandler = (value) => {
        if (value > regNumber) {
            AlertMessages.getErrorMessage(`Value should be less than ${regNumber} character`);
            formRef.setFieldValue('amount', 0);
        };
    };

    const suffixSelector = (
        <Form.Item name="costingCurrency" noStyle label="Please Select">
            <Select
                disabled
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                style={{ width: 80 }}
            >
                {currencies?.map((rec) => {
                    return <Option value={rec.currencyCode}>{rec.currencyCode}</Option>
                })}
            </Select>
        </Form.Item>
    );

    return (
        <>
            <Row justify='end'>
                {isPermissionExist([747]) && (<Link onClick={() => setDrawerVisible(true)}>View Entries</Link>)}
            </Row>
            {isPermissionExist([746]) && (
            <Card title={props.noteType} key={editData?.debitCreditId} >
                <Form
                    form={formRef}
                    initialValues={editData}
                    autoComplete='off'
                    layout='vertical'

                >
                    <Row  >

                        <Form.Item
                            hidden
                            name={'debitCreditId'}

                        >
                            <Input />
                        </Form.Item>
                        <Col span={3} offset={1}>
                            <Form.Item
                                name='noteType'
                                label='Note Type:'
                                rules={[{ required: true }]}

                            >
                                <Radio.Group
                                    disabled
                                    buttonStyle="solid"
                                    optionType="button"
                                    options={options}
                                    onChange={radioOnChange}
                                    value={noteType}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4} offset={2}>
                            <Form.Item
                                label={`${props.noteType} No`}
                                name="debitCreditNo"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4} offset={2}>
                            <Form.Item
                                label="Account"
                                name="account"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    allowClear
                                    showSearch

                                    placeholder='Select Account'
                                    onChange={(value) => { accountOnChangeHandler(value) }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option!.children as unknown as string)?.toString()?.toLocaleLowerCase()?.includes(input.toLocaleLowerCase())} >
                                    {accountsData?.map(item => {
                                        return <Option key={item.actLedId} value={item.actLedId}>{item.actLedName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} offset={1}>
                            <Form.Item
                                label="Date:"
                                name="date"
                                rules={[{ required: true }]}
                                initialValue={moment()}
                            >
                                <DatePicker defaultValue={moment()} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row  >

                        <Form.Item
                            hidden
                            name={'debitCreditId'}
                        ><Input />
                        </Form.Item>
                        <Col span={4} offset={1}>
                            <Form.Item
                                label="Against:"
                                name="against"

                                rules={[{ required: true }]}
                            >
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                    placeholder='Select '
                                    allowClear
                                    showSearch
                                    disabled={debitCreditTrans.length !== 0}
                                    onChange={(value) => { againstOnChange(value) }} >
                                    {
                                        Object.values(DebitCreditAgainstEnum).filter((rec) => rec !== DebitCreditAgainstEnum.ON_ACCOUNT).map((i) => {
                                            return <Option key={i} value={i}>{i}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} offset={1}>
                            {
                                against == DebitCreditAgainstEnum.ON_REFERENCE ?
                                    <Form.Item
                                        label="Refrence:"
                                        name="refrence"
                                    >
                                        <Select
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                            allowClear
                                            showSearch
                                            placeholder='Select Costing'
                                            onChange={(value: string, option: any) => referenceOnChangeHandler(value, option)}
                                        >
                                            {referenceData?.map((rec) => {
                                                return <Option outstanding={rec.outstanding} value={rec.costingId}>{rec.costingNo}</Option>
                                            })}
                                        </Select>
                                    </Form.Item> :
                                    <Form.Item
                                        label="Refrence:"
                                        name="refrence"
                                    >
                                        <Select
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                            allowClear
                                            showSearch
                                            placeholder='Select Invoice'
                                            onChange={(value: string, option: any) => referenceOnChangeHandler(value, option)}
                                        >
                                            {referenceData?.map((rec) => {
                                                return <Option outstanding={rec.outstanding} value={rec.costingId + "@#$" + rec.invoiceId}>{rec.invoiceNo}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                            }

                        </Col>
                        <Form.Item
                            hidden
                            name={'referenceForTable'}
                        >
                            <Input></Input>

                        </Form.Item>
                        <Col span={6} offset={1} >
                            <Form.Item
                                label="Amount:"
                                name="amount"
                            >
                                <Input
                                    onChange={(value) => amountOnchangeHandler(Number(value.target.value))}
                                    type='number'
                                    addonAfter={suffixSelector}
                                >
                                </Input>
                            </Form.Item>
                        </Col>
                        <Col span={1} offset={1}>
                            <Form.Item
                                label={' '}
                            >
                                <Button
                                    onClick={addRecord}
                                    type='primary'
                                    icon={<PlusSquareOutlined style={{ fontSize: '18px' }} />}>

                                </Button>
                            </Form.Item>

                        </Col>

                    </Row>
                </Form>
                {debitCreditTrans?.length ?
                    <Table rowKey={(row) => row.debCreId}
                        size="small" bordered
                        columns={tableColumns}
                        dataSource={debitCreditTrans}
                        pagination={false}
                        summary={(pageData) => {
                            let SubTotal = 0;
                            pageData.forEach(({ amount }) => {
                                SubTotal += Number(amount);
                                setSubTotalValue(SubTotal);
                            });


                            return (
                                <>
                                    <Table.Summary.Row className='tableFooter'>
                                        <Table.Summary.Cell index={1} colSpan={1}> </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1} ><Text style={{ textAlign: 'center' }}><b>Sub Total</b>  </Text></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}><Text style={{ textAlign: 'right' }}><b>{SubTotal}</b></Text></Table.Summary.Cell>
                                        {/* <Table.Summary.Cell index={12}><Text style={{ textAlign: 'end' }}>{Math.round(totalQuantity)}</Text></Table.Summary.Cell> */}
                                    </Table.Summary.Row>
                                </>
                            );
                        }
                        } ></Table> : <></>}
                <br />

                <Divider type='horizontal' />
                <Row gutter={24}>
                    <Col span={12}>
                        <Form
                            form={formRef}
                            initialValues={editData}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            // layout="horizontal"
                            style={{ maxWidth: 300, justifyContent: 'right' }} autoComplete='off'>

                            <Form.Item
                                name='remarks'
                                label="Worklog"  >
                                <TextArea rows={2} style={{ width: '150%' }} />
                            </Form.Item>

                        </Form>
                    </Col>

                    <Col span={12}>

                        <Form
                            form={formRef}
                            initialValues={editData}
                            style={{ maxWidth: 500, justifyContent: 'right' }}
                            autoComplete='off'
                        >

                            <Row gutter={16}>
                                <Col span={7}>
                                    <Form.Item
                                        name='vat'
                                        label='Vat Applicable Y/N'
                                    >
                                        <Checkbox checked={totalAmount?.vatApplicable} onChange={(e) => vatOnchange(e.target.checked)} /> &nbsp;&nbsp;
                                        {/* <Text>{': ' + (totalAmount.vat ? totalAmount?.vat?.toFixed(2) : 0)}</Text> */}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label=''>
                                        <Text>{': ' + (totalAmount.vat ? totalAmount?.vat?.toFixed(2) : 0)}</Text>

                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label='TDS Applicable'
                                        name={'tdsId'}
                                        initialValue={editData?.tdsId}
                                    >
                                        <Select
                                            onChange={tdsOnchange}
                                            placeholder='Select TDS'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            {tdsModel?.map((rec) => (
                                                <Option key={rec.companyTdsId} value={rec.companyTdsId}>{rec.tdsName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label='TDS Amount'>
                                        <Text>{(totalAmount.tds ? totalAmount.tds : 0)}</Text>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                name='total'
                                label={<b>Total Amount</b>}
                            >
                                <Text>{totalAmount?.total?.toFixed(2)}</Text>
                            </Form.Item>
                            <Form.Item name='filesData' label="Upload The Invoice">
                                <Upload
                                    multiple={false}
                                    fileList={fileList}
                                    listType='text'
                                    maxCount={1}
                                    customRequest={onFileChange}
                                    onRemove={() => setFileList([])}
                                    showUploadList>
                                    <Button icon={<UploadOutlined />}>Choose File</Button>
                                </Upload>
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    style={{ marginLeft: '100px' }}
                                    type="primary"
                                    onClick={onSubmitForApproval}
                                >
                                    Submit for Approval
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>

                </Row>
            </Card>)}
            <Drawer
                bodyStyle={{ paddingBottom: 80 }}
                title='Update'
                width={window.innerWidth > 768 ? '70%' : '85%'}
                onClose={closeRejectionDrawer}
                open={drawerVisible}
                closable={true}>
                <DebitCreditRejectedEntries
                    key={dummyRefresh}
                    setDummyRefresh={setDummyRefresh}
                    noteType={props.noteType}
                    setEditDataToForm={(values) => { setEditData(values); setDrawerVisible(false); }}
                />
            </Drawer>
        </>


    )
}