import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditOutlined, MinusOutlined, PlusOutlined, SaveTwoTone } from "@ant-design/icons"
import { BargeService, BPDropDownDto, BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, BusinessPartnerService, CurrencyConverterService, CurrencyExchangeRequestDto, CurrencyService, ExpenseEntryIdReq, OrganizationReqDto, PurchaseContractService, ReportsService, UnitIdDto, UnitsService } from "@exportx/shared-models-and-services"
import { AutoComplete, Button, Card, Col, Input, InputNumber, Popconfirm, Row, Select, Space, Table, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { AlertMessages, isPermissionExist, useAuthState } from "../../../common"
import DatePicker from "../../../common/data-picker/date-picker"
import moment from "moment"
import { isPermissionExists } from "../../../common/iam-client-react/permission-checker/permission-checker"
import { useNavigate } from "react-router-dom"
import { SequenceUtils } from "@exportx/ui-utils"

export const LoadingPlans = () => {
    const [data,setData] = useState<any[]>([])
    const [editData, setEditData] = useState<any>()
    const [usersData, setUnitsData] = useState<any[]>([])
    const [businessDropDown, setBusinessDropDown] = useState<BusinessNoDropDownDto[]>([]);
    const [currencies, setCurrencies] = useState<any[]>([]);
    const [bpDropdown, setBpDropdown] = useState<BPDropDownDto[]>()
    const [bgDropDown, setBgDropDown] = useState<any[]>([])
    const [purchaseDropdown,setPurchaseDropDown] = useState<any[]>([])
    const [hideExchange,setHideExchangeRate] = useState(false)
    const [hideBgQty,setHideBgQty] = useState(false)

    const navigate = useNavigate()
    const { authContext } = useAuthState();
    const { Option } = Select;
    const service = new ReportsService()
    const unitService = new UnitsService()
    const businessService = new BusinessNumberService();
    const currencyService = new CurrencyService();
    const businessPartner = new BusinessPartnerService()
    const currencyConverterService = new CurrencyConverterService();
    const bargeService = new BargeService();
    const purchase = new PurchaseContractService();


    useEffect(()=>{
        if(!isPermissionExists([889,890,891])) {
            return navigate('/')
        }
        getLoadingPlansData()
        getUnitsByClientId()
        getBusinessNoDropDown()
        getCurrencyData()
        getAllBusinessPartnersDropDown()
        getAllPurchaseContracts()
    },[])

    const getLoadingPlansData = () => {
        service.getLoadingPlansData().then((res)=>{
            if(res.status){
                setData(res.data)
                getBargeDropDown(res.data)
            }
        }).catch((error)=>{
            AlertMessages.getErrorMessage(error.message)
        })
    }

    const getUnitsByClientId = () => {
        const units = new UnitIdDto('',1,1)
        unitService.getAllUnitsDropDown(units).then((res) => {
            if (res.status) {
                setUnitsData(res.data);
            } else {
                setUnitsData([]);
            }
        }).catch(err => {
            console.log(err.message, 'error message')
        })
    }

    const getBusinessNoDropDown = () => {
        const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
        businessService.getBusinessNoDropDown(req).then(res => {
          if (res.status) {
            setBusinessDropDown(res.data)
          } else {
            console.log(res.internalMessage)
          }
        }).catch(err => {
          console.log(err.message)
        })
    }

    const getBargeDropDown = (alldata) => {
        bargeService.getAllPlantCodeBarges().then(res => {
          if (res.status) {
            setBgDropDown(res.data)
          }
        }).catch(err => {
          console.log(err.message)
        })
      }

    const getAllPurchaseContracts = () => {
        purchase.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setPurchaseDropDown(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        });
    };

    const deleteEntry = (value) => {
        service.deleteEntry({id: value}).then((res) => {
            if (res.status) {
                getLoadingPlansData()
                AlertMessages.getSuccessMessage(res.internalMessage)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)

            }
        }).catch((err) => {
            AlertMessages.getErrorMessage('')
        })
    }

    const rowDataUpdateHAndler = (value, key, index) => {
        let newValue = value && value.target ? value.target.value : value
        const newData = [...data];
        if (typeof newValue === 'object' && newValue !== null) {
            console.log('barge',newValue)
            if(key === "BGname"){
                newData[index]['loadingQuantity'] = newValue.Qty
                setHideBgQty(true)
            }
            newData[index][key] = { ...newValue }; 
        } else {
            newData[index][key] = newValue;
        }
        if(newData[index]['countryParty'] === "BKM" || newData[index]['countryParty'] === "SDAM"){
            newData[index]['amountCurrency'] = 'IDR';
        } else {
            newData[index]['amountCurrency'] = 'USD'
        }
        let priceCurrency = newData[index]['priceCurrency'];
        let exchangeRate = newData[index]['exchangeRate'];
        let price = newData[index]['price'];
        if(priceCurrency && priceCurrency !== authContext.defaultPlantCurrency){
            newData[index]['exchangeRate'] = exchangeRate ? exchangeRate : 1;
            newData[index]['price'] = price ? price : 0;
            if(priceCurrency === 'USD' && authContext.defaultPlantCurrency === 'IDR'){
                let totalAmount = exchangeRate * price;
                newData[index]['amount'] = Number(totalAmount.toFixed(3));
            } else {
                let totalAmount = exchangeRate / price;
                newData[index]['amount'] = Number(totalAmount.toFixed(3));
            }
            setHideExchangeRate(false)
        } else {
            if(newData[index]['priceCurrency'] && newData[index]['priceCurrency'] === authContext.defaultPlantCurrency){
                setHideExchangeRate(true)
            }
            newData[index]['exchangeRate'] = 1
            newData[index]['amount'] = newData[index]['price']
        }

        // newData[index][key] = newValue;
        newData[index].isUpdated = true;
        setData(newData);
    };

    const handleSubmit = () => {
        const req = data.filter((item) => item.isNewRecord === true || item.isUpdated === true)
        req?.map((item)=>{

            if (item.supplier && typeof item.supplier === 'object' && item.supplier.bpId) {
                item.supplier = item.supplier.bpId;
            }
            if (item.motherVessel && typeof item.motherVessel === 'object' && item.motherVessel.bnId) {
                item.motherVessel = item.motherVessel.bnId;
            }
            if (item.BGname && typeof item.BGname === 'object' && item.BGname.id) {
                item.BGname = item.BGname.id;
            }
            item.createdUser = authContext.user.userName
            item.updatedUser = authContext.user.userName
            item.plantCode = authContext.defaultPlant
        })
        service.createLoadingPlans(req).then((res)=> {
           if(res.status){
                getLoadingPlansData()
                setEditData(null)
                AlertMessages.getSuccessMessage(res.internalMessage)
           }
        }).catch(err =>{
            AlertMessages.getErrorMessage(err.message)
        })
    }

    const getCurrencyData = () => {
        currencyService.getAllCurrenciesForDropDown().then(res => {
          if (res.status) {
            setCurrencies(res.data);
          }
        }).catch(err => {
          console.log('error: ' + err.message);
        })
    }

    const getAllBusinessPartnersDropDown = () =>{
        businessPartner.getAllBusinessPartnersDropDown().then(res => {
            if(res.status){
                setBpDropdown(res.data)
            }
        }).catch(err => {
          console.log('error: ' + err.message);
        })
    }

    const suffixSelector = (index: number, name: string, currency: string, hide: boolean) => (
          <Select
            value={currency || null}
            onChange={(e) => rowDataUpdateHAndler(e, name, index)}
            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
            allowClear 
            showSearch
            disabled={hide}
            style={{ width: 70 }}>
            {currencies.map(item => {
              return <Option value={item.currencyCode}>{item.currencyName}</Option>
            })}
          </Select>
      );

    const columns: ColumnsType<any>= [
        {
            title: 'Operation Team',
            hidden: !isPermissionExists([889]),
            children: [
                {
                    title: 'Supplier',
                    dataIndex: 'supplier',
                    render: (text, record, index) => {
                        const matchedOption = bpDropdown?.find(item => item.bpId === (record.supplier?.bpId || record.supplier));
                        return editData === index ? (
                            <AutoComplete
                                onSelect={(value, option) => {
                                    rowDataUpdateHAndler({ bpId: value, bpName: option.label }, 'supplier', index);
                                }}
                                onChange={(e) => rowDataUpdateHAndler(e, 'supplier', index)}
                                filterOption={(input, option) =>
                                    (option!.label as unknown as string)
                                      .toString()
                                      .toLocaleLowerCase()
                                      .includes(input.toLocaleLowerCase())
                                }
                                style={{ width: '100%' }}
                                placeholder="supplier"
                                value={
                                    (() => {
                                        
                                        return matchedOption
                                            ? matchedOption.bpName
                                            : record.supplier?.bpName || record.supplier || null;
                                    })()
                                }
                                options={bpDropdown.map(item => ({ label: item?.bpName, value: item?.bpId }))}
                                allowClear
                            />
                            
                        ) : (
                            <>{matchedOption ? matchedOption?.bpName : text || null}</>
                        )
                    }
                },
                {
                    title: 'Counter Party',
                    dataIndex: 'countryParty',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Select 
                                onChange={(e)=>{rowDataUpdateHAndler(e,'countryParty',index)}} 
                                style={{ width: '100%' }} 
                                placeholder={'Counter Party'} 
                                value={record.countryParty ? record.countryParty : null}
                                allowClear
                                >
                                {usersData.map(item => {
                                    return <Option value={item?.name}>{item?.name}</Option>
                                })}
                            </Select>
                        ) : (
                            text
                        )
                    },
                },
                {
                    title: 'Mother Vessel',
                    dataIndex: 'motherVessel',
                    render: (text, record, index) => {
                        const matchedOption = businessDropDown?.find(item => item.bnId === (record.motherVessel?.bnId || record.motherVessel));

                        return editData === index ? (
                            <AutoComplete
                                onSelect={(value, option) => {
                                    rowDataUpdateHAndler({ bnId: value, businessName: option.label }, 'motherVessel', index);
                                }}
                                onChange={(e) => rowDataUpdateHAndler(e, 'motherVessel', index)}
                                style={{ width: '100%' }}
                                placeholder="Mother vessel"
                                filterOption={(input, option) =>
                                    (option!.label as unknown as string)
                                      .toString()
                                      .toLocaleLowerCase()
                                      .includes(input.toLocaleLowerCase())
                                }
                                value={
                                    (() => {
                                        
                                        return matchedOption
                                            ? matchedOption.businessName
                                            : record.motherVessel?.businessName || record.motherVessel || null;
                                    })()
                                }
                                options={businessDropDown.map(item => ({ label: item?.businessName, value: item?.bnId }))}
                                allowClear
                            />
                        ) : (
                            <>{matchedOption ? matchedOption.businessName : text || null}</>
                        )
                    }
                },
                {
                    title: 'BG Name',
                    dataIndex: 'BGname',
                    width: 120,
                    render: (text, record, index) => {
                        // const matchedOption = bgDropDown?.find(item => item.id === (record.BGname?.id || record.BGname));
                        const filteredOptions = bgDropDown?.filter(item =>
                            !data.some(dataItem => 
                                dataItem.BGname === item.id 
                            )
                        );
                
                        const matchedOption = bgDropDown?.find(
                            item => item.id === (record.BGname?.id || record.BGname)
                        );
                        return editData === index ? (
                            <AutoComplete 
                                onSelect={(value, option) => {
                                    rowDataUpdateHAndler({ id: value, bgNo: option.label, Qty: option.qty }, 'BGname', index);
                                }}
                                onChange={(e) => rowDataUpdateHAndler(e, 'BGname', index)}
                                style={{ width: '100%' }}
                                placeholder="Barge name"
                                filterOption={(input, option) =>
                                    (option!.label as unknown as string)
                                      .toString()
                                      .toLocaleLowerCase()
                                      .includes(input.toLocaleLowerCase())
                                }
                                value={
                                    (() => {  
                                        return matchedOption
                                            ? `${SequenceUtils.formatNumberToSpecificLength(matchedOption.bargeId.toString()) + " " + `(${matchedOption.plantCode})`}`
                                            : record.BGname?.bgNo || record.BGname || null;
                                    })()
                                }
                                options={filteredOptions.map(item => ({ label: `${SequenceUtils.formatNumberToSpecificLength(item.bargeId.toString()) + " " + `(${item.plantCode})`}`, value: item?.id, qty: item?.quantity }))}
                                allowClear
                            />
                        ) : (
                            <>{matchedOption ? `${SequenceUtils.formatNumberToSpecificLength(matchedOption.bargeId.toString()) + " " + `(${matchedOption.plantCode})`}` : text || null}</>
                        )
                    }
                },
                {
                    title: 'Type Of Barge',
                    dataIndex: 'typeOfBarge',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Select 
                                onChange={(e)=>{rowDataUpdateHAndler(e,'typeOfBarge',index)}} 
                                style={{ width: '100%' }} 
                                placeholder={'Type of barges'} 
                                value={record.typeOfBarge ? record.typeOfBarge : null}
                                allowClear
                            >
                                {["LT","SPOT"].map(item => {
                                    return <Option value={item}>{item}</Option>
                                })}
                            </Select>
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Jetty Name',
                    dataIndex: 'jettyName',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.jettyName ? record.jettyName : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'jettyName',index)}}
                                placeholder="Jetty name"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'SI Quantity',
                    dataIndex: 'siQuantity',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.siQuantity ? record.siQuantity : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'siQuantity',index)}}
                                placeholder="SI Quantity"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Quantity',
                    dataIndex: 'loadingQuantity',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.loadingQuantity ? record.loadingQuantity : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'loadingQuantity',index)}}
                                placeholder="Quantity"
                                autoComplete="off"
                                disabled={hideBgQty}
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Expected Date of Loading',
                    dataIndex: 'exptDateOfLoading',
                    render: (text, record, index) => {
                        const formattedDate = moment(record.exptDateOfLoading).isValid()
                            ? moment(record.exptDateOfLoading).format('DD-MMM-YYYY')
                            : '';
                        return editData === index ? (
                            <DatePicker 
                                value={record.exptDateOfLoading ? moment(record.exptDateOfLoading) : ""}
                                onChange={(date) => {
                                    if (moment(date).isValid()) {
                                        const formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
                                        rowDataUpdateHAndler(formattedDate, 'exptDateOfLoading', index);
                                    }
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="Select Date"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            formattedDate
                        )
                    }
                },
            ]
        },
        {
            title: 'Procurement Team',
            hidden: !isPermissionExists([890]),
            children: [
                {
                    title: 'Contract No',
                    dataIndex: 'contractNo',
                    width: 120,
                    render: (text, record, index) => {
                        const matchedOption = purchaseDropdown.find(
                            (rec) => rec.pcId === record.contractNo
                        );
                        return editData === index ? (
                            <Select 
                                value={matchedOption ? matchedOption.pcId : record.pcId || null}
                                onChange={(e) => rowDataUpdateHAndler(e, 'contractNo', index)}
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                style={{width: "120px"}}
                                showSearch
                                allowClear
                                placeholder="Contract No"
                            >
                                {purchaseDropdown.map((rec)=> (
                                    <Option value={rec.pcId}>{rec.pcNo}</Option>
                                ))}
                            </Select>
                        ) : (
                            <>{text}</>
                        )
                    }
                },
                {
                    title: 'Payment Terms',
                    dataIndex: 'termsOfPayment',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.termsOfPayment ? record.termsOfPayment : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'termsOfPayment',index)}}
                                placeholder="Payment Terms"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Payment Type',
                    dataIndex: 'typeOfPayment',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Select
                                value={record.typeOfPayment ? record.typeOfPayment : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e,'typeOfPayment',index)}}
                                style={{width: "100%"}}
                                placeholder="Payment Type"
                                allowClear
                            >
                                {["PI","CI","Advance","Barge Payment"].map(item => {
                                    return <Option value={item}>{item}</Option>
                                })}
                            </Select>
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Quantity',
                    dataIndex: 'procurementQuantity',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.procurementQuantity ? record.procurementQuantity : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'procurementQuantity',index)}}
                                placeholder="quantity"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Price',
                    dataIndex: 'price',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                addonAfter={suffixSelector(index,'priceCurrency',record.priceCurrency,false)}
                                value={record.price ? record.price : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'price',index)}}
                                placeholder="price"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            <span>{text} ({record.priceCurrency})</span>
                        )
                    }
                },
                {
                    title: 'Exchange Rate',
                    dataIndex: 'exchangeRate',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.exchangeRate ? record.exchangeRate : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'exchangeRate',index)}}
                                placeholder="exchange rate"
                                autoComplete="off"
                                disabled={hideExchange}
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.amount ? record.amount : null}
                                addonAfter={suffixSelector(index,'amountCurrency',record.amountCurrency,true)}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'amount',index)}}
                                placeholder="amount"
                                autoComplete="off"
                                disabled={true}
                                allowClear
                            />
                        ) : (
                            <span>{text} ({record.amountCurrency})</span>
                        )
                    }
                },
                {
                    title: 'PPH',
                    dataIndex: 'PPH',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.PPH ? record.PPH : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'PPH',index)}}
                                placeholder="pph"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'PPN',
                    dataIndex: 'PPN',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.PPN ? record.PPN : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'PPN',index)}}
                                placeholder="ppn"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Advance',
                    dataIndex: 'advance',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.advance ? record.advance : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'advance',index)}}
                                placeholder="advance"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'PI Adjustment',
                    dataIndex: 'PIAdjustment',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.PIAdjustment ? record.PIAdjustment : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'PIAdjustment',index)}}
                                placeholder="PI Adjustment"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Net Amount',
                    dataIndex: 'netAmount',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.netAmount ? record.netAmount : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'netAmount',index)}}
                                placeholder="Net Amount"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                }
            ]
        },
        {
            title: 'Finance Team',
            hidden: !isPermissionExists([891]),
            children: [
                {
                    title: 'Final Payment',
                    dataIndex: 'finalPayment',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.finalPayment ? record.finalPayment : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'finalPayment',index)}}
                                placeholder="Final payment"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Payment Status',
                    dataIndex: 'paymentStatus',
                    render: (text, record, index) => {
                        return editData === index ? (
                            <Input 
                                value={record.paymentStatus ? record.paymentStatus : null}
                                onChange={(e)=>{rowDataUpdateHAndler(e.target.value,'paymentStatus',index)}}
                                placeholder="Payment Status"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            text
                        )
                    }
                },
                {
                    title: 'Expected Date of Receipt',
                    dataIndex: 'exptDateOfReceipt',
                    render: (text, record, index) => {
                        const formattedDate = record.exptDateOfReceipt
                            ? moment(record.exptDateOfReceipt).format('DD-MMM-YYYY')
                            : '';
                        return editData === index ? (
                            <DatePicker 
                                value={record.exptDateOfReceipt ? moment(record.exptDateOfReceipt) : ""}
                                onChange={(e)=>{
                                    if (moment(e).isValid()) {
                                        const formattedDate = moment(e).format("YYYY-MM-DD HH:mm:ss");
                                        rowDataUpdateHAndler(formattedDate,'exptDateOfReceipt',index)
                                    }
                                }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder="Select Date"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            formattedDate
                        )
                    }
                },
                {
                    title: 'Expected Date of Settlement',
                    dataIndex: 'exptDateOfSettlement',
                    render: (text, record, index) => {
                        const formattedDate = record.exptDateOfSettlement
                            ? moment(record.exptDateOfSettlement).format('DD-MMM-YYYY')
                            : '';
                        return editData === index ? (
                            <DatePicker 
                                value={record.exptDateOfSettlement ? moment(record.exptDateOfSettlement) : ""}
                                onChange={(e)=>{
                                    if (moment(e).isValid()) {
                                        const formattedDate = moment(e).format("YYYY-MM-DD HH:mm:ss");
                                        rowDataUpdateHAndler(formattedDate,'exptDateOfSettlement',index)
                                    }
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="Select Date"
                                autoComplete="off"
                                allowClear
                            />
                        ) : (
                            formattedDate
                        )
                    }
                }
            ]
        },
        {
            title: 'Action',
            fixed: 'right',
            render: (text, record, index) => {
                return editData === index ? (
                    <>
                    <Tooltip placement="top" title="Save">
                        <SaveTwoTone
                            onClick={handleSubmit}
                            style={{ color: '#1890ff', fontSize: '18px' }}
                        />
                    </Tooltip>
                    &nbsp;&nbsp;
                    <Tooltip placement="top" title="Cancel">
                        <CloseCircleOutlined
                            onClick={() => handleCancel(index)}
                            style={{ color: 'red', fontSize: '18px' }}
                        />
                    </Tooltip>
                    </>
                ) : (
                    <>
                    <Tooltip placement="top" title="Edit">
                        <EditOutlined
                            type="edit"
                            onClick={() => {const newData = [...data];
                                newData[index].isUpdated = true; setEditData(index);setData(newData)}}
                            style={{ color: '#1890ff', fontSize: '18px' }}
                        />
                    </Tooltip>
                    &nbsp;&nbsp;
                        <Popconfirm
                            onConfirm={e => {
                                deleteEntry(record.id)
                            }}
                            title={'Are you sure to delete ?'}>
                            <Tooltip placement="top" title={`${text === true ? 'You Cant Delete This Record' : 'Delete'}`}>
                                <DeleteOutlined type='delete' style={{ color: `${text === true ? '#d1b2b2' : 'red'}`, fontSize: '18px' }} />
                            </Tooltip>
                        </Popconfirm>
                    </>
                )
            },
        },
    ]

    const addNewRow = () => {
        const hasNewRecord = data.some((row) => row.isNewRecord);
        if (!hasNewRecord) {
            const newRow = { isNewRecord: true };
            setData([ newRow, ...data]);
            setEditData(0);
        } else {
            AlertMessages.getWarningMessage("Can't Add New Row ")
        }
    };


    const handleCancel = (index) => {
        const newData = data.filter((row, i) => !(row.isNewRecord && i === index));
        setData(newData);
        setEditData(null);
        getLoadingPlansData()
    };

    return (
        <Card
            title={<span style={{ fontSize: '24px' }}>Loading Plans</span>}
            extra={<span >{isPermissionExists([889]) && (<Button type="primary" icon={<PlusOutlined />} onClick={addNewRow} >
                Add Row
            </Button>)}</span>}
        >
            <Table
                rowKey={(row) => row.id}
                className='contracts'
                columns={columns}
                dataSource={data}
                scroll={{ x: 'max-content' }} 
                size="large"
                bordered
            />
        </Card>
    )
}