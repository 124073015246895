import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";

export const MasterRoleSelection = ({ formRef, initialValues }) => {

  const [masterInitialValues, setMasterInitialValues] = useState({
    countries: [],
    currencies: [],
    company: [],
    commodity: [],
    basis: [],
    taxes: [],
    items_taxes: [],
    company_tds: [],
    exchange_rates: [],
    vendor_type: [],
    customer_type: [],
    facilitator_type: [],
    shipping_terms: [],
    payment_modes: [],
    uoms: [],
    province: [],
    anchorage: [],
    jetty: [],
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setMasterInitialValues((prev) => {
        return {
          ...prev,
          countries: features?.filter((cou) => 1 <= +cou && +cou <= 5)?.map(Number),
          currencies: features?.filter((cou) => 6 <= +cou && +cou <= 9)?.map(Number),
          company: features?.filter((cou) => 10 <= +cou && +cou <= 13)?.map(Number),
          commodity: features?.filter((cou) => 14 <= +cou && +cou <= 17)?.map(Number),
          basis: features?.filter((cou) => 18 <= +cou && +cou <= 21)?.map(Number),
          taxes: features?.filter((cou) => 22 <= +cou && +cou <= 25)?.map(Number),
          items_taxes: features?.filter((cou) => 26 <= +cou && +cou <= 29)?.map(Number),
          company_tds: features?.filter((cou) => 30 <= +cou && +cou <= 33)?.map(Number),
          exchange_rates: features?.filter((cou) => 34 <= +cou && +cou <= 37)?.map(Number),
          vendor_type: features?.filter((cou) => 38 <= +cou && +cou <= 41)?.map(Number),
          customer_type: features?.filter((cou) => 42 <= +cou && +cou <= 45)?.map(Number),
          facilitator_type: features?.filter((cou) => 46 <= +cou && +cou <= 49)?.map(Number),
          shipping_terms: features?.filter((cou) => 50 <= +cou && +cou <= 50)?.map(Number),
          payment_modes: features?.filter((cou) => 54 <= +cou && +cou <= 57)?.map(Number),
          uoms: features?.filter((cou) => 58 <= +cou && +cou <= 61)?.map(Number),
          province: features?.filter((cou) => 62 <= +cou && +cou <= 65)?.map(Number),
          anchorage: features?.filter((cou) => 66 <= +cou && +cou <= 69)?.map(Number),
          jetty: features?.filter((cou) => 70 <= +cou && +cou <= 73)?.map(Number),
        };
      });

    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(masterInitialValues)
  }, [formRef, masterInitialValues])

  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setMasterInitialValues((prev) => {
        return {
          ...prev,
          [name]: value
        }
      })
    }
    else setMasterInitialValues((prev) => {
      return {
        ...prev,
        [name]: []
      }
    });
  }


  return (
    <Form
      name="validate_other"
      layout='vertical'
      initialValues={masterInitialValues}
      form={formRef}
    >
      <Card size="small" title="Countries" extra={<Checkbox
        name="Countries"
        indeterminate={masterInitialValues.countries.length > 0 && masterInitialValues.countries.length < 4}
        checked={masterInitialValues.countries.length === 4}
        onChange={(e) => onChange(e, 'countries', [2, 3, 4, 5])}
      >
        <b>Select All</b>
      </Checkbox>}>
        <Form.Item style={{ marginBottom: 0 }} name="countries">

          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, countries: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={2}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={3}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={4}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={5}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="currencies"
        extra={<Checkbox
          name="currencies"
          indeterminate={masterInitialValues.currencies.length > 0 && masterInitialValues.currencies.length < 4}
          checked={masterInitialValues.currencies.length === 4}
          onChange={(e) => onChange(e, 'currencies', [6, 7, 8, 9])}
        >
          <b>Select All</b>
        </Checkbox>}>
        <Form.Item name="currencies" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, currencies: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={6}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={7}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={8}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={9}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Company"
        extra={<Checkbox
          name="company"
          indeterminate={masterInitialValues.company.length > 0 && masterInitialValues.company.length < 4}
          checked={masterInitialValues.company.length === 4}
          onChange={(e) => onChange(e, 'company', [10, 11, 12, 13])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="company" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, company: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={10}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={11}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={12}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={13}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Commodity"
        extra={<Checkbox
          name="commodity"
          indeterminate={masterInitialValues.commodity.length > 0 && masterInitialValues.commodity.length < 4}
          checked={masterInitialValues.commodity.length === 4}
          onChange={(e) => onChange(e, 'commodity', [14, 15, 16, 17])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="commodity" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, commodity: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={14}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={15}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={16}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={17}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Basis"
        extra={<Checkbox
          name="basis"
          indeterminate={masterInitialValues.basis.length > 0 && masterInitialValues.basis.length < 4}
          checked={masterInitialValues.basis.length === 4}
          onChange={(e) => onChange(e, 'basis', [18, 19, 20, 21])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="basis" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, basis: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={18}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={19}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={20}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={21}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Taxes"
        extra={<Checkbox
          name="taxes"
          indeterminate={masterInitialValues.taxes.length > 0 && masterInitialValues.taxes.length < 4}
          checked={masterInitialValues.taxes.length === 4}
          onChange={(e) => onChange(e, 'taxes', [22, 23, 24, 25])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="taxes" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, taxes: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={22}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={23}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={24}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={25}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Items And Taxes"
        extra={<Checkbox
          name="items_taxes"
          indeterminate={masterInitialValues.items_taxes.length > 0 && masterInitialValues.items_taxes.length < 4}
          checked={masterInitialValues.items_taxes.length === 4}
          onChange={(e) => onChange(e, 'items_taxes', [26, 27, 28, 29])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="items_taxes" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, items_taxes: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={26}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={27}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={28}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={29}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Company TDS"
        extra={<Checkbox
          name="company_tds"
          indeterminate={masterInitialValues.company_tds.length > 0 && masterInitialValues.company_tds.length < 4}
          checked={masterInitialValues.company_tds.length === 4}
          onChange={(e) => onChange(e, 'company_tds', [30, 31, 32, 33])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="company_tds" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, company_tds: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={30}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={31}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={32}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={33}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Exchange Rates"
        extra={<Checkbox
          name="exchange_rates"
          indeterminate={masterInitialValues.exchange_rates.length > 0 && masterInitialValues.exchange_rates.length < 4}
          checked={masterInitialValues.exchange_rates.length === 4}
          onChange={(e) => onChange(e, 'exchange_rates', [34, 35, 36, 37])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="exchange_rates" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, exchange_rates: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={34}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={35}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={36}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={37}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Vendor Type"
        extra={<Checkbox
          name="vendor_type"
          indeterminate={masterInitialValues.vendor_type.length > 0 && masterInitialValues.vendor_type.length < 4}
          checked={masterInitialValues.vendor_type.length === 4}
          onChange={(e) => onChange(e, 'vendor_type', [38, 39, 40, 41])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="vendor_type" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, vendor_type: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={38}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={39}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={40}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={41}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Customer Type"
        extra={<Checkbox
          name="customer_type"
          indeterminate={masterInitialValues.customer_type.length > 0 && masterInitialValues.customer_type.length < 4}
          checked={masterInitialValues.customer_type.length === 4}
          onChange={(e) => onChange(e, 'customer_type', [42, 43, 44, 45])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="customer_type" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, customer_type: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={42}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={43}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={44}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={45}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Facilitator Type"
        extra={<Checkbox
          name="facilitator_type"
          indeterminate={masterInitialValues.facilitator_type.length > 0 && masterInitialValues.facilitator_type.length < 4}
          checked={masterInitialValues.facilitator_type.length === 4}
          onChange={(e) => onChange(e, 'facilitator_type', [46, 47, 48, 49])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="facilitator_type" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, facilitator_type: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={46}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={47}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={48}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={49}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Shipping Terms"
        extra={<Checkbox
          name="shipping_terms"
          indeterminate={masterInitialValues.shipping_terms.length > 0 && masterInitialValues.shipping_terms.length < 4}
          checked={masterInitialValues.shipping_terms.length === 4}
          onChange={(e) => onChange(e, 'shipping_terms', [50, 51, 52, 53])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="shipping_terms" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, shipping_terms: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={50}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={51}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={52}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={53}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Payment Modes"
        extra={<Checkbox
          name="payment_modes"
          indeterminate={masterInitialValues.payment_modes.length > 0 && masterInitialValues.payment_modes.length < 4}
          checked={masterInitialValues.payment_modes.length === 4}
          onChange={(e) => onChange(e, 'payment_modes', [54, 55, 56, 57])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="payment_modes" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, payment_modes: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={54}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={55}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={56}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={57}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="UOM's"
        extra={<Checkbox
          name="uoms"
          indeterminate={masterInitialValues.uoms.length > 0 && masterInitialValues.uoms.length < 4}
          checked={masterInitialValues.uoms.length === 4}
          onChange={(e) => onChange(e, 'uoms', [58, 59, 60, 61])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="uoms" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, uoms: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={58}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={59}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={60}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={61}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Province"
        extra={<Checkbox
          name="province"
          indeterminate={masterInitialValues.province.length > 0 && masterInitialValues.province.length < 4}
          checked={masterInitialValues.province.length === 4}
          onChange={(e) => onChange(e, 'province', [62, 63, 64, 65])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="province" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, province: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={62}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={63}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={64}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={65}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Anchorage" type="inner"
        extra={<Checkbox
          name="anchorage"
          indeterminate={masterInitialValues.anchorage.length > 0 && masterInitialValues.anchorage.length < 4}
          checked={masterInitialValues.anchorage.length === 4}
          onChange={(e) => onChange(e, 'anchorage', [66, 67, 68, 69])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="anchorage" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, anchorage: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={66}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={67}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={68}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={69}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

      </Card>
      <br />
      <Card size="small" title="Jetty"
        extra={<Checkbox
          name="jetty"
          indeterminate={masterInitialValues.jetty.length > 0 && masterInitialValues.jetty.length < 4}
          checked={masterInitialValues.jetty.length === 4}
          onChange={(e) => onChange(e, 'jetty', [70, 71, 72, 7])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="jetty" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setMasterInitialValues(prev => ({ ...prev, jetty: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={70}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={71}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={72}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={73}>Delete</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
    </Form>
  )
}