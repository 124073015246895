import { AdvanceEnum, BpCategoryGetDto, BPDropDownDto, BusinessPartnerService, BusinessPartnerTypeEnum, CurrencyConverterService, CurrencyExchangeRequestDto, CurrencyService, DeductionCategoryEnum, PriceTypeEnum, PurchaseContractService, PurchaseDropDownDto, VendorAdvService } from '@exportx/shared-models-and-services';
import { ClassValidator } from '@exportx/ui-utils';
import { Card, Col, Form, FormInstance, Input, Row, Select } from 'antd'
import DatePicker from '../../../../common/data-picker/date-picker';
import FormItem from 'antd/es/form/FormItem';
import { FormLayout } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { AlertMessages, useAuthState } from '../../../../common';
import PaymentTerm from '../../../contracts/creation-forms/payment-terms';

const { Option } = Select;
interface IVendorAdvanceProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
  advanceCategory?: AdvanceEnum
  vendor?: string;
  bpId?: string;

}


const VendorAdvance = (props: IVendorAdvanceProps) => {
  const { authContext } = useAuthState();
  const { formRef, layoutType, initialValues, vendor, bpId, } = props;
  const { formatMessage: fm } = useIntl();
  const { state }: any = useLocation();
  const classValidator = new ClassValidator();


  const [vendorsData, setVendorsData] = useState<BPDropDownDto[]>([]);
  const [purchases, setPurchases] = useState<PurchaseDropDownDto[]>([]);
  const [currency, setCurrency] = useState<any[]>([]);

  const businessPartnerService = new BusinessPartnerService();
  const purchase = new PurchaseContractService();
  const currencyService = new CurrencyService();
  const currencyConverterService = new CurrencyConverterService();

  const [deduction, setDeduction] = useState<string>("")
  const [currencyData, setCurrencyData] = useState<any>('');
  const [currencySystem, setCurrencySystem] = useState<any>('')
  const [deductionType, setDeductionType] = useState<string>("")
  const [exRate, setExRate] = useState<any>()
  const [isSameCurrency, setIsSameCurrency] = useState<boolean>(false)


  const getCurrencyData = () => {
    currencyService.getCurrncyName({ currencyId: formRef.getFieldValue("negotiationCurrency") }).then(res => {
      setCurrencyData(res.currencyCode);
    }).catch(err => {
      console.log('error:' + err.message)
    })
  }

  const getSystemCurrencyData = () => {
    currencyService.getCurrncyName({ currencyId: formRef.getFieldValue("billingCurrency") }).then(res => {
      setCurrencySystem(res.currencyCode);
    }).catch(err => {
      console.log('error:' + err.message)
    })
  }



  useEffect(() => {
    if (initialValues) {
      initialValues.id = initialValues?.id
      if(initialValues?.jisdorDate){
        initialValues.jisdorDate = moment(initialValues?.jisdorDate) 
      }
      initialValues.contractDate = (initialValues.contractDate) ? moment(initialValues?.contractDate) : undefined
      if (initialValues.billingCurrency !== initialValues.negotiationCurrency) {
        setIsSameCurrency(false);
      } else {
        setIsSameCurrency(true);
      }
      setDeduction(initialValues?.deductionType)
      formRef.setFieldsValue(initialValues)
      getCurrencyData()
      calculateSystemCurrency()
    }
  }, [initialValues])


  useEffect(() => {
    if (vendor) {
      formRef.setFieldValue('purchaseContractNo', vendor);
      formRef.setFieldValue('vendorId', bpId)
    }

    formRef.setFieldValue('contractType', state?.contract ? state?.contract : AdvanceEnum.VendorAdvanceAgainstContract)
  }, [vendor])

  useEffect(() => {
    getAllBusinessPartnersForDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR));
    getAllPurchaseContracts();
    getCurrenciesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllBusinessPartnersForDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
    businessPartnerService.getAllBusinessPartnersForDropDown(bpCategoryGetDto).then(res => {
      if (res.status) {
        if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.VENDOR)
          setVendorsData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  };



  const getAllPurchaseContracts = () => {
    purchase.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setPurchases(res.data);
      }
    }).catch(err => {
      console.log('error:' + err.message);
    })
  };

  const getCurrenciesData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrency(res.data);
      }

    }).catch(err => {
      console.log("error");
    })

  }

  const deductionData = (values) => {
    setDeduction(values)
  }

  const deductionDataType = (value) => {
    setDeductionType(value)
  }




  const calculateSystemCurrency = () => {
    let billingCurrency = formRef.getFieldValue("billingCurrency");
    let negotiationCurrency = formRef.getFieldValue("negotiationCurrency");
    let exchangeRate = formRef.getFieldValue("exchangeRate");
    let advanceAmount = formRef.getFieldValue("advanceAmount");
    let jisdorDate = formRef.getFieldValue('jisdorDate')

    if (billingCurrency !== negotiationCurrency) {
      exchangeRate = exchangeRate ? exchangeRate : 1;
      advanceAmount = advanceAmount ? advanceAmount : 0;

      const req = new CurrencyExchangeRequestDto(negotiationCurrency, billingCurrency, moment(jisdorDate).format('YYYY-MM-DD'))
      currencyConverterService.getCurrencyExchangeRate(req).then(res => {
        if (res.status) {
          setExRate(res.data);
          formRef.setFieldValue('exchangeRate', res?.data?.exchangeRate);
          formRef.setFieldValue('displayExchangeRate', res?.data?.displayExchangeRate);
          let totalAmount = res?.data?.exchangeRate * advanceAmount;
          totalAmount = Number(totalAmount.toFixed(3));
          formRef.setFieldValue('amount', totalAmount);
        } else {
          //AlertMessages.getErrorMessage(res.internalMessage);
          formRef.setFieldValue('exchangeRate', undefined);
          formRef.setFieldValue('displayExchangeRate', undefined);
        }
      }).catch(err => {
        console.log('error:' + err.message);
      })
      setIsSameCurrency(false)

    } else {
      setIsSameCurrency(true);
      formRef.setFieldValue('exchangeRate', 1);
      formRef.setFieldValue('displayExchangeRate', 1);
    }

  };




  let contractType = '';
  if (state?.contractType) {
    contractType = state.contractType
  } else if (state?.contract) {
    contractType = state?.contract
  } else {
    contractType = "Vendor Advance Contract"
  };
  const changePriceValue = () => {

    if (formRef.getFieldValue('deductionType') === PriceTypeEnum.PER_MT) {
      formRef.setFieldValue('pricePMT', null)
    }

    if (formRef.getFieldValue('deductionType') === PriceTypeEnum.LUMP_SUM) {
      formRef.setFieldValue('lumpSum', null)
    }



  }


  return (
    //`${state.contractType ? state.contractType : state.contract}`
    <Card title={contractType}>
      <Form initialValues={initialValues}
        form={formRef}
        layout={layoutType}
        autoComplete="off"
      >

        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
            <Form.Item
              style={{ display: "none" }}
              name="contractType"

            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear showSearch placeholder="Please select Contract Type"  >
                <Option value={state?.contract} key={state?.contract}><span >{state?.contract}</span></Option>

              </Select>
            </Form.Item>

          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}></Col>
          <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}></Col>
          <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}></Col>
        </Row>

        <Card title="Contract Details">
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
              <Form.Item
                label={fm({ id: "mdm.components.vendorAdvance.vendorId", defaultMessage: 'Vendor' })}
                name="vendorId" rules={[{ required: vendor ? true : false, message: ' Please Select Vendor' }]}>

                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}

                  disabled={vendor ? true : false}
                  allowClear showSearch placeholder={fm({ id: `common.vendorAdvance.reqVendorId`, defaultMessage: `Please Search Vendor` })}>
                  {/* <Option value={''}>Please Select</Option> */}

                  {vendorsData.map(item => {
                    return <Option value={item.bpId} key={item.bpId}>{item.bpName}</Option>
                  })}
                </Select>

              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
              <Form.Item
                label={fm({ id: "mdm.components.vendorAdvance.purchaseContract", defaultMessage: 'Purchase Contract' })}
                name="purchaseContractNo" rules={[{ required: contractType === AdvanceEnum.VendorAdvanceAgainstContract ? true : false, message: 'purchaseContractNo' }]}>
                <Select
                  filterOption={(input, option) => (option!.children as unknown as string)?.toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear
                  showSearch
                  disabled={vendor ? true : false}
                  placeholder={fm({ id: `common.vendorAdvance.reqPurchaseContract`, defaultMessage: `Please Select Purchase Contract` })}>
                  {/* <Option value={''}>Please Select</Option> */}
                  {purchases.map(item => {
                    return <Option value={item.pcId} key={item.pcId}>{item.contractAndSupplier}</Option>
                  })}
                </Select>

              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1}>
              <Form.Item name="contractDate"
                label={fm({ id: "mdm.components.vendorAdvance.contractDate", defaultMessage: 'Contract Date' })}

                rules={[{ required: true, message: 'Contract Date' }]} >
                <DatePicker style={{ width: "200px" }} format="YYYY-MM-DD" />
              </Form.Item></Col>

            <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>

          </Row>

          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
              <Form.Item name="billingCurrency"
                label={fm({ id: "mdm.components.vendorAdvance.currency", defaultMessage: 'Currency' })}
                rules={[{ required: true, message: 'Currency' }]}
              >
                <Select

                  filterOption={(input, option) => (option!.children as unknown as string).toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear showSearch placeholder="Please select currency" onChange={calculateSystemCurrency} onSelect={getSystemCurrencyData}>


                  {/* {currency.filter(i => i.currencyName == 'USD').map(item => {  */}
                  {currency.map(item => {
                    return <Option value={item.currencyCode} key={item.currencyCode}>{item.currencyName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
              <Form.Item name="negotiationCurrency"
                label={fm({ id: "mdm.components.vendorAdvance.Negotiation Currency", defaultMessage: 'Negotiation Currency' })}
                rules={[{ required: true, message: 'Negotiation Currency' }]}
              >
                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear showSearch placeholder="Please select currency" onSelect={getCurrencyData} onChange={calculateSystemCurrency}>

                  {currency.map(item => {
                    return <Option value={item.currencyCode} key={item.currencyCode}>{item.currencyName}</Option>
                  })}

                </Select>
              </Form.Item>
            </Col>

            {!isSameCurrency && <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1}>
              <Form.Item name="jisdorDate"
                label={fm({ id: "mdm.components.vendorAdvance.jisdorDate", defaultMessage: 'JISDOR Date' })}

                rules={[{ required: true, message: 'JISDOR Date' }]} >
                <DatePicker style={{ width: "200px" }} format="YYYY-MM-DD" onChange={calculateSystemCurrency} />
              </Form.Item></Col>
            }

            {!isSameCurrency && <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1} >
              <Form.Item label={fm({ id: "mdm.components.vendorAdvance.advanceAmount", defaultMessage: 'Advance Amount' })}

                name='advanceAmount'
                rules={[{ required: true, message: 'Advance Amount' },
                {
                  pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                  message: `Only Number & Decimals`
                }
                ]}>
                <Input placeholder={fm({ id: `common.vendorAdvance.reqAdvanceAmount`, defaultMessage: `Advance Amount` })} autoComplete='off' addonAfter={currencyData} onChange={calculateSystemCurrency}></Input></Form.Item>
            </Col>}
            {!isSameCurrency && <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1} >
              <Form.Item label={fm({ id: "mdm.components.vendorAdvance.exchangeRate", defaultMessage: 'Exchange Rate' })}

                name='exchangeRate' rules={[{ required: true, message: 'ExchangeRate' }]} hidden>
                <Input disabled type='number' placeholder={fm({ id: `common.vendorAdvance.reqAdvanceAmount`, defaultMessage: `ExchangeRate` })} autoComplete='off'
                  onChange={calculateSystemCurrency}
                ></Input></Form.Item>
              <Form.Item label={fm({ id: "mdm.components.vendorAdvance.exchangeRate", defaultMessage: 'Exchange Rate' })}
                name='displayExchangeRate' rules={[{ required: true, message: 'ExchangeRate' }]}>
                <Input disabled type='number' placeholder={fm({ id: `common.vendorAdvance.reqAdvanceAmount`, defaultMessage: `ExchangeRate` })} autoComplete='off'
                  onChange={calculateSystemCurrency}
                ></Input></Form.Item>
            </Col>}



            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
              <Form.Item name="amount"
                label={fm({ id: "mdm.components.vendorAdvance.Amount(System Currency)", defaultMessage: 'Amount(System Currency)' })}
              >
                <Input type='number' disabled={!isSameCurrency} addonAfter={currencySystem} ></Input>
              </Form.Item>
            </Col>

          </Row>
        </Card>
        <Card title="Deductions">
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
              <FormItem label={fm({ id: "mdm.components.vendorAdvance.deductionType", defaultMessage: 'Deduction Type' })}
                name="deductionType"  >
                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear showSearch placeholder="Select deduction Type"
                  onChange={(values) => { deductionData(values) }} onSelect={changePriceValue} >
                  {Object.keys(PriceTypeEnum).map(deduction => {
                    return <Option value={PriceTypeEnum[deduction]} key={PriceTypeEnum[deduction]}>{PriceTypeEnum[deduction]}</Option>
                  })}
                </Select>
              </FormItem>
            </Col>

            {(PriceTypeEnum.PER_MT === deduction) &&
              <>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
                  <Form.Item label={fm({ id: 'mdm.components.vendorAdvance.price PMT', defaultMessage: 'Price PMT' })}
                    name="pricePMT"
                    rules={[
                      {
                        pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                        message: `Only Number & Decimals`
                      }
                    ]} >
                    <Input autoComplete='off' placeholder={fm({ id: `common.vendorAdvance.reqPricePMT`, defaultMessage: `Price PMT` })} addonAfter={currencySystem}></Input>
                  </Form.Item>
                </Col>

              </>}


            {(PriceTypeEnum.LUMP_SUM === deduction) && <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1}>
              <Form.Item
                label={fm({ id: 'mdm.components.vendorAdvance.deduction category', defaultMessage: 'Deduction Category' })}
                name="deductionCategory" >
                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear showSearch placeholder="Deduction Category"
                  onChange={(value) => { deductionDataType(value) }}
                >
                  {Object.keys(DeductionCategoryEnum).map(deductionCategoryKeys => {
                    return <Option value={DeductionCategoryEnum[deductionCategoryKeys]}>{DeductionCategoryEnum[deductionCategoryKeys]}</Option>
                  })}
                </Select>
              </Form.Item>


            </Col>}



            {(PriceTypeEnum.LUMP_SUM === deduction) &&
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
                <Form.Item label={fm({ id: 'mdm.components.vendorAdvance.lumpsum', defaultMessage: 'Price' })}
                  name="lumpSum"
                  rules={[
                    {
                      pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                      message: `Only Number & Decimals`
                    }
                  ]}>
                  <Input autoComplete='off' placeholder={fm({ id: `common.vendorAdvance.reqlumpsum`, defaultMessage: `Price` })} addonAfter={currencySystem} ></Input>
                </Form.Item>

              </Col>}
            {(DeductionCategoryEnum.Others === deductionType) &&
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
                <Form.Item label={fm({ id: 'mdm.components.vendorAdvance.remarks', defaultMessage: 'Remarks' })}
                  name="other"
                >
                  <Input placeholder={fm({ id: `common.vendorAdvance.reqRemarks`, defaultMessage: `Remarks` })}  ></Input>
                </Form.Item>

              </Col>}

            {/* {(DeductionCategoryEnum.Others === deductionType) &&
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
                <Form.Item label={fm({ id: 'mdm.components.vendorAdvance.remarks', defaultMessage: 'Remarks' })}
                  name="others" 
                 >
                  <Input placeholder={fm({ id: `common.vendorAdvance.reqRemarks`, defaultMessage: `Remarks` })}  ></Input>
                </Form.Item>

              </Col>}
              */}
          </Row>

        </Card>

        <PaymentTerm
          // account={"account"}
          formRef={formRef} initialValues={initialValues} layoutType={'horizontal'} />
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} >
            <Form.Item label={fm({ id: 'mdm.components.vendorAdvance.remarks', defaultMessage: 'Remarks' })}
              name="remarks">
              <Input.TextArea />
            </Form.Item></Col>
        </Row>
      </Form>
      {props?.children}
    </Card>)
}

export default VendorAdvance