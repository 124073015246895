import { Button, Card, Col, Form, Row, Tooltip } from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import UserBasicDetails from './user-basic-details';
import ContactDetails from './contact-details';
import { FormInstance, FormLayout } from 'antd/lib/form/Form';
import { AlertMessages } from '../notifications';

export interface IPOCFormProps {
    maxCount: number;
    layoutType: FormLayout;
    formRef: FormInstance<any>;
    initialValues: any;
}

const POCForm = (props: IPOCFormProps) => {
    const { formatMessage: fm } = useIntl();
    const { maxCount, formRef, layoutType, initialValues } = props;
    return (
        <Form layout={layoutType} form={formRef} initialValues={initialValues}>
            <Form.List name="pocFormData">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Row key={field.key}>
                                <Col
                                    xs={{ span: 22 }}
                                    sm={{ span: 22 }}
                                    md={{ span: 22 }}
                                    lg={{ span: 22 }}
                                    xl={{ span: 22 }}
                                >
                                    <Card title='Poc Details'>
                                        <UserBasicDetails field={field} />
                                        <ContactDetails field={field} />
                                    </Card>
                                </Col>

                                {index !== 0 && <Col xs={{ span: 2 }}
                                    sm={{ span: 2 }}
                                    md={{ span: 2 }}
                                    lg={{ span: 2 }}
                                    xl={{ span: 2 }}>
                                    <span style={{
                                        position: "absolute",
                                        marginTop: "47px",
                                        marginLeft: "10px",
                                    }}>
                                        <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                            <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(field.name)} />
                                        </Tooltip>
                                    </span>
                                </Col>}
                            </Row>
                        ))}
                        <Row>
                            <Col>
                                <Button type="dashed" onClick={() => {
                                    if (maxCount > fields.length) {
                                        add()
                                    } else {
                                        AlertMessages.getErrorMessage(`You can't add more than ${maxCount} fields`)
                                    }

                                }} block icon={<PlusOutlined />}>
                                    ADD New POC
                                </Button>
                            </Col>
                        </Row>
                    </>)}
            </Form.List>

        </Form>
    )
}

export default POCForm;