import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const CostingDetails = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    costing_details: [],
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          costing_details: features?.filter((cou) => 766 <= +cou && +cou <= 771)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])


  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({ ...prev, [name]: value }))
    }
    else setValues((prev) => ({ ...prev, [name]: [] }));
  }

  return (
    <Form
      name="validate_other"
      layout='vertical'
      initialValues={values}
      form={formRef}
    >
      <Card size="small" title="Budget & Costing"
        extra={<Checkbox
          name="costing_details"
          indeterminate={values.costing_details.length > 0 && values.costing_details.length < 6}
          checked={values.costing_details.length === 6}
          onChange={(e) => onChange(e, 'costing_details', [766, 767, 768, 769, 770, 771])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="costing_details" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, costing_details: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={766} >Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={767} >View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={768} >Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={769} >Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={770} >Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={771} >Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>

    </Form>
  )
}