// import './shipping-terms-form.css';
import { Form, Input, Button, Card, Row, Col, Select } from 'antd';
import { CommodityDto, CommodityEnum, CommodityService } from '@exportx/shared-models-and-services';
import { AlertMessages, useAuthState } from '../../../../../common';
import { useIntl } from 'react-intl';

export interface CommodityFormProps {

  commodityData: CommodityDto;
  updateDetails: (commodityData: CommodityDto) => void;
  isUpdate: boolean;
  isNewRecord: boolean;
  closeForm: () => void;

}

export function CommodityForm(props: CommodityFormProps) {
  const { authContext } = useAuthState();//to access global authorized user data(Login user Data which is passed as created user or updated user)
  const [form] = Form.useForm();
  const service = new CommodityService();
  const { formatMessage: fm } = useIntl();


  const save = (data: CommodityDto) => {
    service.create({ ...data, createdUser: authContext.user.userName }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        onReset();
        props.closeForm();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      //AlertMessages.getErrorMessage(err.message);
      console.log(err.message)
    })
  }


  const saveData = (values: CommodityDto) => {
    if (props.isUpdate) {
      props.updateDetails({ ...props.commodityData, ...values, updatedUser: authContext.user.userName });
    } else {
      save(values);
    }

  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <Card
      title="Commodity"
      className='default-card-class' >

      <Form layout="vertical" form={form} onFinish={saveData} initialValues={props.commodityData} autoComplete='off'>
        <Row gutter={20}>
          <Form.Item name="commodityId" style={{ display: 'none' }}>
            <Input hidden />
          </Form.Item>

          <Form.Item style={{ display: 'none' }} name="createdUser">
            <Input hidden />
          </Form.Item>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <Form.Item
              name="commodityName"
              label={fm({ id: "mdm.components.CommodityForm.commodityName", defaultMessage: 'Commodity Name' })}
              rules={[{ required: true, message: 'Input Your  Commodity Name' }]}

            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <Form.Item
              name="commodityCode"
              label={fm({ id: "mdm.components.CommodityForm.commodityCode", defaultMessage: 'Commodity Code' })}
              rules={[{ required: true, message: 'Input Your Commodity Code' }]}

            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
            <Form.Item
              name="category"
              label={'Commodity Category'}
              rules={[{ required: true, message: 'Input Your Commodity Category' }]}
            >
              <Select >
                {Object.keys(CommodityEnum).map((rec) => {
                  return <Select.Option value={CommodityEnum[rec]} key={CommodityEnum[rec]}>{CommodityEnum[rec]}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {(props.isNewRecord || props.isUpdate) &&
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onReset}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit" >
                Submit
              </Button>


            </Col>
          </Row>}
      </Form>
    </Card>


  );
}

export default CommodityForm;

