import React from 'react';
import { ContractModesEnum, SamplingTypes } from '@exportx/shared-models-and-services';
import ActualReport from './actual-report';
import { Card } from 'antd';

interface IProps {
    sampleType: SamplingTypes;
    contractType: ContractModesEnum
}

export const PurchaseSampleReport = (props: IProps) => {
    return (
        <Card title={`${props.contractType === ContractModesEnum.SALES ? "S&A - Sales" : "S&A - Purchase"}`}>
            <div><ActualReport sampleType={props.sampleType} contractType={ContractModesEnum.PURCHASE} /></div>
        </Card>
    )
}

export default PurchaseSampleReport