import { SpecTypesEnum } from "@exportx/shared-models-and-services";
import { FormInstance, Tabs } from "antd";
import { FormLayout } from "antd/lib/form/Form";
import { Analysis } from "./analysis";
import { AshFusion } from "./ashfusion";
import { Elements } from "./elements";
import { Other } from "./other";
import { SizeDistribution } from "./sizedistribution";



const { TabPane } = Tabs;
interface IASTMSpecProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
  specType: SpecTypesEnum;
}
export const ASTMSpecification = (props: IASTMSpecProps) => {
  return (
    <Tabs>
      <TabPane tab="Analysis" key="1">
        <Analysis {...props} />
      </TabPane>
      <TabPane tab="Size Distribution" key="2">
        <SizeDistribution {...props} />
      </TabPane>
      <TabPane tab="Ash Fusion " key="3">
        <AshFusion {...props} />
      </TabPane>
      <TabPane tab="Elements " key="4">
        <Elements  {...props} />
      </TabPane>
      <TabPane tab="Other " key="5">
        <Other {...props} />
      </TabPane>
    </Tabs>
  )
}
export default ASTMSpecification;
