import { BargeCostingsService, BPFCostingRequestIdDto, ContractStatusEnum, GetDataDemurrageDto, GetExpApprovalDataDto, PaymentTypeEnum } from "@exportx/shared-models-and-services"
import { Input, Card, Table, Form, Row, Col, List, Button, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { SequenceUtils, getLocalFormat } from "@exportx/ui-utils"
import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import moment from "moment"
import { InvoiceDetails, DemurrageDispatchDetails } from "../costing-detail-view"
import { AlertMessages, useAuthState } from "../../../common"

interface IProps {
  type?: ContractStatusEnum;
  paymentType?: PaymentTypeEnum;
}

export const DemurrageApproval = (props: IProps) => {
  const { type, paymentType } = props

  const [gridData, setGridData] = useState<GetDataDemurrageDto[]>();
  const bargeCostingService = new BargeCostingsService();
  const { authContext } = useAuthState();
  const [searchedText, setSearchedText] = useState("");
  const [expandedIndex, setExpandedIndex] = useState([]);

  const [formRef] = Form.useForm();


  useEffect(() => {
    getDemurrageData()
  }, [])


  const getDemurrageData = () => {
    const status = type === ContractStatusEnum.BUSINESS_APPROVAL
      ? ContractStatusEnum.PENDING_APPROVAL
      : ContractStatusEnum.PENDING_AUDIT_APPROVAL
    const req = new GetExpApprovalDataDto(paymentType, authContext.defaultPlant, status)
    bargeCostingService.getDemurrageDataForApproval(req).then(res => {
      if (res.status) {
        setGridData(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.bgDemurrageId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  }

  const approveStatus = type === ContractStatusEnum.BUSINESS_APPROVAL
    ? ContractStatusEnum.ACTIVE
    : ContractStatusEnum.ACTIVE


  const approveDemurrage = (costingId: string, status: ContractStatusEnum) => {
    formRef.validateFields().then(values => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(costingId, values['remarks'], status, authContext.user.userName, authContext.defaultPlant, type);

      console.log('req =>', req)
      bargeCostingService.demurrageApproval2(req).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getDemurrageData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);

        }
      }).catch(err => {
        console.log(err.message)
      })
    }).catch(err => {
      console.log(err)
    })

  }

  const renderItems = (record: any) => {
    return <>

      <DemurrageDispatchDetails 
      costingId={record.bgDemurrageId} 
      remainingAmount={0}
      />

      <Form
        form={formRef}
        // initialValues={initialValues}
        layout='horizontal'
        autoComplete='off'
      >
        <Row className='pt-2'>
          <Col span={16}>
            <Form.Item style={{ width: '100%' }} name='remarks' label="Work Log"
              rules={[
                {
                  required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                }
              ]}>
              <Input style={{ width: '95%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <Row>
              <Col span={3}>Worklog :</Col>
              <Col span={20}>
                {record?.workLog?.length > 0 && <List
                  bordered
                  dataSource={record?.workLog ? record?.workLog : []}
                  renderItem={(item, index) => (
                    <List.Item key={index} style={{ width: "100%" }}>
                      <div><span>{index + 1}</span><span>.</span>&nbsp;{item.workLog}</div>
                    </List.Item>
                  )}
                />}

              </Col>
            </Row>

          </Col>
        </Row>
        <Row>
          <Col span={16}>
          </Col>
        </Row>
        <br />
        {record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL &&
          <Row justify='end'>
            <Col span={2} > <Button type='primary' onClick={() => approveDemurrage(record?.bgDemurrageId, approveStatus)} >Approve</Button></Col>
            <Col span={2} >
              <Popconfirm
                onConfirm={e => {
                  approveDemurrage(record?.bgDemurrageId, ContractStatusEnum.CANCELLED);
                }}
                title={'Are you sure to reject'}>
                <Tooltip placement="top" title={'Reject'}>
                  <Button style={{ color: 'red' }}>Reject</Button>
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>

        }
      </Form>

      <br />
      <InvoiceDetails
        costingId={record.bgDemurrageId}
        status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
        level={ContractStatusEnum.BUSINESS_APPROVAL}
        invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
        invApproveDisabled={record.approvalStatus === ContractStatusEnum.PENDING_APPROVAL}
      />
    </>

  };



  const tableColumns: ColumnsType<any> = [
    {
      title: 'Demurrage Ref #',
      dataIndex: 'costingNo',
      width: '20%',
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record)
      },
    },
    {
      title: 'MV Name',
      dataIndex: 'businessNumber',
      // width: '40%', 
      render: (value, record) => {
        const href = `/#/bn-detail-view?bn_Id=${record.businessNo}`
        return <>
          <a href={href}>{value}</a>
        </>
      }

    },
    {
      title: 'Cost Type',
      dataIndex: 'allowedTimeToLoad',//timeCompletedForLoadMV
      render: (value, record) => {
        let costType: string = ''
        if (Number(value) > Number(record.timeCompletedForLoadMV)) {
          costType = 'Dispatch'
        } else if (Number(value) < Number(record.timeCompletedForLoadMV)) {
          costType = 'Demurrage'
        } else {
          costType = ' '
        }
        return <>
          {costType}
        </>
      }
    },
    {
      title: 'Date',
      dataIndex: 'demurrageDate',
      render: (value, record) => { return <>{moment(value)?.format('DD-MM-YYYY')}</> }
    },
    {
      title: 'Account Type',
      dataIndex: 'account',
    },
    {
      title: 'Business Partner',
      dataIndex: 'bpName',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      render: (value, record) => {
        return <>
          {getLocalFormat(Number(value), record.quoteCurrency)}
        </>
      }
    }

  ]


  return (
    <>
      <Card title={<span style={{ color: 'white' }}>Demurrage Approval for MV</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}>
        <Input.Search
          placeholder="Search"
          allowClear
          onChange={(e) => { setSearchedText(e.target.value) }}
          onSearch={(value) => { setSearchedText(value) }}
          style={{ width: 200, float: "right" }}
        />
        <Table
          size='small'
          expandIconColumnIndex={0}
          // className='contracts'
          rowKey={record => record.bgDemurrageId}
          columns={tableColumns}
          dataSource={gridData}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right'
          }}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleFilled
                onClick={(e) => onExpand(record, e)}
              >
                Collapse
              </UpCircleFilled>
            ) : (
              <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
            )
          }
          pagination={false}
          scroll={{ x: 500 }}
          bordered
        />

      </Card>
    </>
  )

}
export default DemurrageApproval