import { ReferenceFeatures } from '@exportx/shared-models-and-services';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
import { useAuthState } from '../../../common';

interface InvoiceNoIProps {
    costingId?: string;
    to?: string;
    businessNo?: string;
    invoiceNo?: string;
    invoiceDate?: string;
    costingName?: any
}

const styles = StyleSheet.create({
    container: {
        borderBottomColor: 'black',
        borderBottomWidth: 0.4,
        flexDirection: 'row',

    },
    left: {
        width: '55%',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    right: {
        width: "45%",
        flexDirection: 'column',
        borderLeft: 0.4,
        borderLefttColor: "black",
    },
    logo: {
        width: 160,
        height: 80,
        justifyContent: 'flex-start',

    },
    companyText: {
        borderRadius: 5,
        padding: 5,
        fontSize: 14,
        fontStyle: 'bold'
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 0.4,
        borderBottomColor: 'black',
    },
    label: {
        width: "25%",
        padding: 5,
        fontSize: 8,
        fontWeight: "bold"
    },
    value: {
        fontSize: 8,
        padding: 5,
        textTransform: 'uppercase',
    }

});


const InvoiceNo = (props: InvoiceNoIProps) => {
    const { authContext } = useAuthState()
    const { costingId, to, businessNo, invoiceNo, invoiceDate, costingName } = props


    return (

        <Fragment>

            <View style={styles.container}>
                <View style={styles.left}>
                    {/* {authContext.defaultPlant === "SDAM" ?
                        <Image style={styles.logo} src={logo} /> */}
                        {/* // : */}
                        <Text style={styles.companyText}>{authContext.defaultPlantName}</Text>
                    {/* // } */}
                </View>
                <View style={styles.right}>
                    <Text style={{ padding: 6, fontSize: 10, fontWeight: 'bold', textAlign: 'center', alignContent: 'center', alignItems: 'center', borderBottomWidth: 0.4, borderBottomColor: 'black' }}>{`${costingName} Invoice`}</Text>
                    <View style={styles.row}>
                        <Text style={styles.label}>Costing ID </Text>
                        <Text style={styles.value}> {` : ${costingId}`}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>To</Text>
                        <Text style={styles.value}> {` : ${to}`}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Business No</Text>
                        <Text style={styles.value}> {` : ${businessNo}`}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Invoice No</Text>
                        <Text style={styles.value}> {` : ${invoiceNo}`}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.label}>Invoice Date</Text>
                        <Text style={styles.value}> {`: ${invoiceDate}`}</Text>
                    </View>
                </View>
            </View>
        </Fragment>
    )
}

export default InvoiceNo