import { Card } from 'antd';
import { DonutChart } from '../../common/charts/do-nut-chart';

export const BillStatusTracker = () => {
    const chartData = [
        {
            value: 13,
            type: "Open Bills"
        },
        {
            value: 53,
            type: "Overdue Bills"
        },
        {
            value: 34,
            type: "Paid Bills"
        }
    ]
    return (
        <Card title='Bills  Status Tracker (Payables)'>
            <DonutChart chartData={chartData} height={"300px"} />
        </Card>
    )
}