import { DeleteOutlined, EditOutlined, FilePdfTwoTone, PlusSquareTwoTone, MinusSquareTwoTone } from '@ant-design/icons';
import { BargeCostingVouchersParentDto, ContractStatusEnum, ExpenseEntryIdReq, ExpenseEntryService, NoteTypeReq, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Divider, Input, Popconfirm, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from '../../../../common';
import Invoice from '../../invoice/invoice';
import { SequenceUtils } from '@exportx/ui-utils';
import AddInvoiceDrawer from '../../add-invoice-drawer';
export interface ExpenseEntryRejectedListProps {
    setEditDataToForm: (values) => void
    setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>

    updateExpensive: any,
    type: string,
    accessId?: any
}

export default function ExpenseEntryRejectedList(props: ExpenseEntryRejectedListProps) {
    const { setDummyRefresh, updateExpensive, accessId } = props
    const [rejectedEntries, setRejectedEntries] = useState<any[]>([])
    const service = new ExpenseEntryService()
    const { authContext } = useAuthState();
    const [vouchers, setVouchers] = useState<BargeCostingVouchersParentDto>();
    const [searchedText, setSearchedText] = useState("");
    const [drawerOpen, setDrawerOpen] = useState<boolean>();
    const [costingId, setCostingId] = useState<any>();

    useEffect(() => {
        getRejectedEntries()
    }, [props.type])

    const deleteEntry = (value) => {
        console.log(value)
        const req = new ExpenseEntryIdReq()
        req.expenseEntryId = value

        service.deleteEntry(req).then((res) => {
            if (res.status) {
                setDummyRefresh(prev => prev + 1);
                AlertMessages.getSuccessMessage(res.internalMessage)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)

            }
        }).catch((err) => {
            AlertMessages.getErrorMessage('')
        })
    }


    const getRejectedEntries = () => {
        const req = new NoteTypeReq(props.type === 'Account Receivable' ? 'Other Income' : '', authContext.defaultPlant)
        // const req = new PlantCodeReqDto(authContext.defaultPlant)
        service.getRejectedEntries(req).then((res) => {
            if (res.status) {
                console.log('getRejectedEntries', res)
                const filteredRejectedEntries = res.data.filter(item => {
                    return hasPermission(item)
                })
                setRejectedEntries(filteredRejectedEntries)
                // setRejectedEntries(res.data)
            } else {
                setRejectedEntries([])
            }
        }).catch(err => {
            console.log('err', err)
            setRejectedEntries([])

        })
    }

    const hasPermission = (ledger) => {
        let permission;
        if(ledger.act_led_name === 'Chemical Spray'){
          permission = 417
          ledger.addInvoicePermission = {
            create: 425,
            view: 426,
            update: 427,
            delete: 428,
            approve: 429,
            reject: 430,
            release: 431,
            files: 432 
          }
          ledger.editPermission = 418
          ledger.deletePermission = 419
        } else if(ledger.act_led_name === 'Export Tax'){
          permission = 438
          ledger.addInvoicePermission = {
                create: 446,
                view: 447,
                update: 448,
                delete: 449,
                approve: 450,
                reject: 451,
                release: 452,
                files: 453 
              }
          ledger.editPermission = 439
          ledger.deletePermission = 440

        } else if(ledger.act_led_name === 'Documentation Charges'){
          permission = 459
          ledger.addInvoicePermission = {
                create: 467,
                view: 468,
                update: 469,
                delete: 470,
                approve: 471,
                reject: 472,
                release: 473,
                files: 474 
              }
          ledger.editPermission = 460
          ledger.deletePermission = 461
        } else if(ledger.act_led_name === 'Laporan Charges'){
          permission = 480
          ledger.addInvoicePermission = {
                create: 488,
                view: 489,
                update: 490,
                delete: 491,
                approve: 492,
                reject: 493,
                release: 494,
                files: 495 
              }
          ledger.editPermission = 481
          ledger.deletePermission = 482
        } else if(ledger.act_led_name === 'Sampling & Analysis Charges'){
          permission = 501
          ledger.addInvoicePermission = {
                create: 509,
                view: 510,
                update: 511,
                delete: 512,
                approve: 513,
                reject: 514,
                release: 415,
                files: 416 
            }
          ledger.editPermission = 502
          ledger.deletePermission = 503
        } else if(ledger.act_led_name === 'Pre Shipment Inspection'){
          permission = 522
          ledger.addInvoicePermission = {
                create: 530,
                view: 531,
                update: 532,
                delete: 533,
                approve: 534,
                reject: 535,
                release: 536,
                files: 437 
            }
          ledger.editPermission = 523
          ledger.deletePermission = 524
        } else if(ledger.act_led_name === 'Other Expenses'){
          permission = 543
          ledger.addInvoicePermission = {
                create: 551,
                view: 552,
                update: 553,
                delete: 554,
                approve: 555,
                reject: 556,
                release: 557,
                files: 558 
              }
          ledger.editPermission = 544
          ledger.deletePermission = 545
        } else if(ledger.act_led_name === 'Return Cargo Charges'){
          permission = 564
          ledger.addInvoicePermission = {
                create: 572,
                view: 573,
                update: 574,
                delete: 575,
                approve: 576,
                reject: 577,
                release: 578,
                files: 579 
              }
          ledger.editPermission = 565
          ledger.deletePermission = 566
        } else if(ledger.act_led_name === 'Floating Crane Charges'){
          permission = 585
          ledger.addInvoicePermission = {
                create: 593,
                view: 594,
                update: 595,
                delete: 596,
                approve: 597,
                reject: 598,
                release: 599,
                files: 600 
            }
          ledger.editPermission = 586
          ledger.deletePermission = 587
        } else if(ledger.act_led_name === 'Stevedore Costing'){
          permission = 606
          ledger.addInvoicePermission = {
                create: 614,
                view: 615,
                update: 616,
                delete: 617,
                approve: 618,
                reject: 619,
                release: 620,
                files: 621 
              }
          ledger.editPermission = 607
          ledger.deletePermission = 608
        } else if(ledger.act_led_name === 'Insurance'){
          permission = 687
          ledger.addInvoicePermission = {
                create: 694,
                view: 695,
                update: 696,
                delete: 697,
                approve: 698,
                reject: 699,
                release: 700,
                files: 701 
              }
          ledger.editPermission = 688
          ledger.deletePermission = 689
        } else if(ledger.act_led_name === 'Freight Costing'){
          permission = 627
          ledger.addInvoicePermission = {
                create: 634,
                view: 635,
                update: 636,
                delete: 637,
                approve: 638,
                reject: 639,
                release: 640,
                files: 641
              }
          ledger.editPermission = 628
          ledger.deletePermission = 629
        } else if(ledger.act_led_name === 'PNBP Charges'){
          permission = 648
          ledger.addInvoicePermission = {
                create: 654,
                view: 655,
                update: 656,
                delete: 657,
                approve: 658,
                reject: 659,
                release: 660,
                files: 661 
              }
          ledger.editPermission = 648
          ledger.deletePermission = 649
        } else if(ledger.act_led_name === 'Other Income'){
          permission = 667
          ledger.addInvoicePermission = {
                create: 674,
                view: 675,
                update: 676,
                delete: 677,
                approve: 678,
                reject: 679,
                release: 680,
                files: 681 
              }
          ledger.editPermission = 668
          ledger.deletePermission = 669
        } else {
          permission = 1
          ledger.addInvoicePermission = {
                create: 1,
                view: 1,
                update: 1,
                delete: 1,
                approve: 1,
                reject: 1,
                release: 1,
                files: 1 
              }
          ledger.editPermission = 1
          ledger.deletePermission = 1
        }
        if(isPermissionExist([permission])){
          return ledger
        }
    }



    const getExpensiveInvoiceData = (id: string) => {
        const req = new ExpenseEntryIdReq();
        req.expenseEntryId = id;
        req.plantCode = authContext.defaultPlant
        service.getExpensiveInvoiceData(req).then(res => {
            if (res.status) {
                setVouchers(res.data);
            } else {
                console.log(res.internalMessage);
            }
        }).catch(err => console.log(err.message));
    }

    const columns: any = [
        {
            title: 'Actin',
            dataIndex: 'isInPmtNotice',
            width: '15%',
            align: 'center',
            key: 'expenseEntryId',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (text, rowData) => (
                <span>
                  {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) && isPermissionExist([rowData?.editPermission]) && (

                    <Tooltip placement="topRight" title="Edit">
                        <EditOutlined
                            type="edit"
                            onClick={() => updateExpensive(rowData.expenseEntryId)}
                            style={{ color: '#1890ff', fontSize: '18px' }}
                        /></Tooltip>)}

                    <Divider type="vertical" />
                    {isPermissionExist([rowData?.deletePermission]) && (
                    <Popconfirm
                        disabled={text}
                        onConfirm={e => {
                            deleteEntry(rowData.expenseEntryId)
                        }}
                        title={'Are you sure to delete ?'}>
                        <Tooltip placement="topRight" title={`${text === true ? 'You Cant Delete This Record' : 'Delete'}`}>
                            <DeleteOutlined type='delete' style={{ color: `${text === true ? '#d1b2b2' : 'red'}`, fontSize: '18px' }} />
                        </Tooltip>
                    </Popconfirm>)}
                    <Divider type="vertical" />
                    {isPermissionExist([rowData?.addInvoicePermission?.view]) && (
                    <Tooltip placement="topRight" title="Add Invoice">
                        <PlusSquareTwoTone onClick={() => {
                            setDrawerOpen(true);
                            setCostingId((prev) => { return { ...prev, costingId: rowData.expenseEntryId, totalAmount: rowData.totalAmount, accessId: rowData.addInvoicePermission } });
                            // setDummyRefresh(prev => prev + 1);
                        }}
                            style={{ color: '#1890ff', fontSize: '20px' }} />
                    </Tooltip>)}
                     {/* <Tooltip placement="topRight" title="Add Invoice">
                        {!invoiceFrom ? <>
                            <PlusSquareTwoTone
                                key={rowData.expenseEntryId}
                                onClick={() => {
                                    setInvoiceForm(true);
                                    setInvoiceCostingData((prev) => { return { ...prev, referenceFeatures: ReferenceFeatures.EXPENSE_ENTRY, costingId: rowData.expenseEntryId, totalAmount: Number(rowData.totalAmount) } })

                                }}
                                style={{ color: '#1890ff', fontSize: '20px' }} />

                        </> : <>


                            <MinusSquareTwoTone key={rowData.expenseEntryId} style={{ color: '#1890ff', fontSize: '20px' }} onClick={() => {
                                setInvoiceForm(false);
                            }} />
                        </>

                        }


                    </Tooltip> */}

                    <Divider type='vertical'></Divider>

                    <PDFDownloadLink
                        document={<Invoice invoice={vouchers} demurrageName={''} costingName={vouchers?.costingName} />}
                        fileName={`${rowData?.expenseEntryNo}.pdf`}
                    >
                        <Tooltip placement="topRight" title="Invoice">
                            <FilePdfTwoTone onClick={() => getExpensiveInvoiceData(rowData.expenseEntryId)} style={{ color: '#1890ff', fontSize: '20px' }}
                            />
                        </Tooltip>
                    </PDFDownloadLink>

                </span>
            )
        },
        {
            title: 'Entry Ref#',
            dataIndex: 'expenseEntryNo',
            sorter: (a, b) => a.expenseEntryNo.localeCompare(b.expenseEntryNo),
            sortDirections: ['descend', 'ascend'],
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
        },
        {
            title: 'Expense Ledger',
            dataIndex: 'act_led_name',
            
        },
        {
            title: 'Expense Type',
            dataIndex: 'expensiveType',
            sorter: (a, b) => a.expensiveType.localeCompare(b.expensiveType),
            sortDirections: ['descend', 'ascend'],
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            align: 'right',
            sorter: (a, b) => a.totalAmount.localeCompare(b.totalAmount),
            sortDirections: ['descend', 'ascend'],
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value) => Number(value).toFixed(2)
        },
        {
            title: 'BusinessNo',
            dataIndex: 'businessNumber',
            sorter: (a, b) => a.businessNumber.localeCompare(b.businessNumber),
            sortDirections: ['descend', 'ascend'],
            render: (value, record) => {
                const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
                return <>
                    <a href={link}>{value}</a>
                </>
            }

        },
        {
            title: 'Barges',
            dataIndex: 'bargeId',
            sorter: (a, b) => a.bargeSupplier.localeCompare(b.bargeSupplier),
            sortDirections: ['descend', 'ascend'],
            render: (v, record) => {
                const link = `/#/barge-detail-view?barge_id=${record.bgId}`;
                return <>
                    {v === undefined || v === null ? "(null)" :
                        <a href={link} className="link-primary">
                            {SequenceUtils.formatNumberToSpecificLength(v) + "-" + `(${record.bargeName})`}
                        </a>}
                </>
            }
        },

        {
            title: 'Status',
            dataIndex: 'approvalStatus',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, _) => {
                return <>
                    <StatusTag status={value}></StatusTag>
                </>
            }
        },
    ];


    const entriesDataModification = (data: any) => {
        const entriesData = [];
        data.forEach((parent) => {
            parent.transactionalData.forEach((child, index) => {
                entriesData.push({
                    expenseEntryNo: parent.expenseEntryNo,
                    expensiveType: parent.expensiveType,
                    totalAmount: parent.totalAmount,
                    // rowSpan: index === 0 ? parent.transactionalData.length : 0,
                    businessNumber: child.businessNumber + `(${child.motherVesselName})`,
                    bargeId: child.bargeId,
                    approvalStatus: parent.approvalStatus,
                    bnId: child.bnId,
                    bgId: child.bgId,
                    expenseEntryId: parent.expenseEntryId,
                    bargeName: child.bargeName,
                    isInPmtNotice: parent.isInPmtNotice,
                    act_led_name: parent.act_led_name,
                    addInvoicePermission: parent.addInvoicePermission,
                    editPermission: parent.editPermission,
                    deletePermission: parent.deletePermission

                })

            })
        })

        return entriesData
    }


    return (
        <>

            <Input.Search
                placeholder="Search"
                allowClear
                onChange={(e) => { setSearchedText(e.target.value) }}
                onSearch={(value) => { setSearchedText(value) }}
                style={{ width: 200, float: "right" }}
            />    <Table
                columns={columns}
                size='small' bordered
                dataSource={entriesDataModification(rejectedEntries)}
                rowKey={(row) => row.expenseEntryId}
            />

            <AddInvoiceDrawer openDrawer={drawerOpen} setDrawerOpen={setDrawerOpen} costingId={costingId} setDummyRefresh={setDummyRefresh} referenceFeatures={ReferenceFeatures.EXPENSE_ENTRY} />
            {/* {invoiceFrom &&

                <AddInvoice key={inVoiceCostingData.expenseEntryId} costingData={inVoiceCostingData} />
            } */}
        </>
    )
}
