import React from "react";
import { AdvancePreview, AdvanceReceiversService, ContractStatusEnum, ReferenceFeatures, VendorAdvService } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Form, Input, List, Popconfirm, Row, Select, Table, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertMessages, useAuthState } from "../../../../../common";
import Link from 'antd/lib/typography/Link';
import { DownCircleFilled, UpCircleFilled } from "@ant-design/icons"
import { InvoiceDetails } from "../../../../costings/costing-detail-view";
import { useIntl } from 'react-intl';
import { getLocalFormat } from "@exportx/ui-utils";

interface AdvanceApprovalProps {
    feature?: ReferenceFeatures;

}

export const AdvanceBusinessApprovals = (props: AdvanceApprovalProps) => {
    const { feature } = props
    const [advanceActive, setadvanceActive] = useState<any>();
    let pathToreDirect = feature === ReferenceFeatures.ADVANCE_RECEIVABLES ? '/advance-receivables-detailed-view':'/advanceDetailView'
    const { authContext } = useAuthState()
    const service = new VendorAdvService()
    const advReceivableService = new AdvanceReceiversService()
    const [searchData, setSearchData] = useState("")
    const [expandedIndex, setExpandedIndex] = useState([]);
    const [formRef] = Form.useForm();
    const { formatMessage: fm } = useIntl();

    useEffect(() => {
        if (feature === ReferenceFeatures.ADVANCE_INVOICE) {
            getAdvanceApprovalCostings();
        } else getAdvanceReceivableApprovalCostings()
    }, [feature]);


    const getAdvanceReceivableApprovalCostings = () => {
        advReceivableService.getAdvanceReceivableApprovalCostings({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setadvanceActive(res.data)
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message)
        })
    }
    const getAdvanceApprovalCostings = () => {
        service.getAdvanceApprovalCostings({ plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setadvanceActive(res.data)
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message)
        })
    }

    const advanceApproveOrReject = (costingId: string, status: string) => {
        const req = new AdvancePreview(costingId, authContext.defaultPlant, authContext.user.userName, status);
        if (feature === ReferenceFeatures.ADVANCE_INVOICE) {
            service.advanceApproveOrReject(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
                    window.location.reload()
                } else {
                    AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
                }
            }).catch(err => {
                AlertMessages.getErrorMessage(err.message);
            })
        }
        else {
            console.log('req', req)
            advReceivableService.advanceReceivableApproveOrReject(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
                    window.location.reload()
                } else {
                    AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
                }
            }).catch(err => {
                AlertMessages.getErrorMessage(err.message);
            })


        }


    }


    const tableColumns: any = [

        {
            title: 'Contract No',
            dataIndex: 'contract_no',
            filteredValue: [String(searchData).toLowerCase()],
            onFilter: (value, record) => {
                return (
                    String(record.contract_no)
                        .toLowerCase()
                        .includes(value.toLocaleString())
                )
            },
            render: (value, rowData) => {
                const link = `/#${pathToreDirect}?id=${rowData.id}`
                return <> {
                    <Link href={link} className="link-primary" >{value}</Link>
                }
                </>
            }

        },

        {
            title: 'Contract Type',
            dataIndex: 'contract_type',
        },

        {
            title: 'Contract Date',
            dataIndex: 'contract_date',
            render: (text, record) => { return record.contract_date !== undefined ? moment(record.contract_date).format('YYYY-MM-DD') : "" },
            sorter: (a, b) => moment(a.contract_date).unix() - moment(b.contract_date).unix(),
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Vendor',
            dataIndex: 'bp_name',
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (value, record) => {
                return <>{getLocalFormat(value, authContext.defaultPlantCurrency)}</>
            }
        },

    ]

    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.id);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    }
    const renderItems = (record: any, index, indent, expanded) => {
        console.log('record', record)
        return <>
            <Form
                form={formRef}
                // initialValues={initialValues}
                layout='horizontal'
                autoComplete='off'
            >
                <Row className='pt-2'>
                    <Col span={16}>
                        <Form.Item style={{ width: '100%' }} name='remarks' label="Work Log"
                            rules={[
                                {
                                    required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                                }
                            ]}>
                            <Input style={{ width: '95%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={16}>
                        <Row>
                            <Col span={3}>Worklog :</Col>
                            <Col span={20}>
                                {record?.workLog?.length > 0 && <List
                                    bordered
                                    dataSource={record?.workLog ? record?.workLog : []}
                                    renderItem={(item, index) => (
                                        <List.Item key={index} style={{ width: "100%" }}>
                                            <div><span>{index + 1}</span><span>.</span>&nbsp;{item.workLog}</div>
                                        </List.Item>
                                    )}
                                />}

                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row>
                    <Col span={16}>
                    </Col>
                </Row>
                <br />
                <Row justify='end'>
                    {record?.contract_status === ContractStatusEnum.PENDING_APPROVAL &&
                        <Col span={2} > <Button type='primary' onClick={() => advanceApproveOrReject(record?.id, ContractStatusEnum.ACTIVE)} >Approve</Button></Col>
                    }
                    <Col span={2} >
                        <Popconfirm
                            onConfirm={e => {
                                advanceApproveOrReject(record?.id, ContractStatusEnum.CANCELLED);
                            }}
                            title={'Are you sure to reject'}>
                            <Tooltip placement="top" title={'Reject'}>
                                <Button style={{ color: 'red' }}>Reject</Button>
                            </Tooltip>
                        </Popconfirm>
                    </Col>
                </Row>
            </Form>


            <br />
            <InvoiceDetails
                costingId={record.id}
                status={ContractStatusEnum.PENDING_AUDIT_APPROVAL}
                level={ContractStatusEnum.BUSINESS_APPROVAL}
                invoiceStatus={ContractStatusEnum.BUSINESS_APPROVAL}
                invApproveDisabled={record.contract_status === ContractStatusEnum.PENDING_APPROVAL}
            />
        </>
    };

    return (
        <Card>


            <Form layout="vertical">
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

                        <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
                    </Col>
                </Row>

            </Form>
            <Table className='contracts' columns={tableColumns} dataSource={advanceActive}
                bordered={true}
                rowKey={(rowData) => rowData.id}
                expandable={{
                    expandedRowRender: renderItems,
                    expandedRowKeys: expandedIndex,
                    onExpand: setIndex,
                    fixed: 'right'
                }}
                expandIcon={({ expanded, onExpand, record }) =>
                    expanded ? (
                        <UpCircleFilled
                            onClick={(e) => onExpand(record, e)}
                        >
                            Collapse
                        </UpCircleFilled>
                    ) : (
                        <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
                    )
                }
            />

        </Card>
    )

}