
import { GetAllRolesDto, RolesIdReqDto, RolesService } from '@exportx/shared-models-and-services';
import { Button, Input, Table } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, TableActions, useAuthState } from '../../../common';
import { SequenceUtils } from '@exportx/ui-utils';


interface IRolesGridProps {
    rolesData: GetAllRolesDto[];
    setInitialValues: Dispatch<SetStateAction<GetAllRolesDto>>;
    createButtonHandler: () => void;
    // getAllRoles: () => void

}




export const RolesGrid = (props: IRolesGridProps) => {
    const { rolesData, setInitialValues, createButtonHandler } = props;
    const rolesService = new RolesService();
    const { authContext } = useAuthState();
    const { formatMessage: fm } = useIntl();
    const [searchedText, setSearchedText] = useState("");



    const editOnClickHandler = (record: GetAllRolesDto) => {
        setInitialValues(record);
        createButtonHandler();
    };

    const statusUpdate = (id: number) => {
        const req = new RolesIdReqDto(authContext.user.userName, authContext.user.userId, id)
        rolesService.activateOrDeactivate(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                // getAllRoles();
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message, "err message"))
    }


    const RolesColumns = [
        {
            title: fm({ id: "roles.common.name", defaultMessage: 'Name' }),
            dataIndex: 'roleName',
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
            render: (value: any, record: GetAllRolesDto) => {
                return <Button type="link" onClick={() => editOnClickHandler(record)}>{value}</Button>
            }
        },
        {
            title: fm({ id: "roles.common.description", defaultMessage: 'Description' }),
            dataIndex: 'description'
        },
        // {
        //     title: fm({ id: "roles.common.unit", defaultMessage: 'unit' }),
        //     dataIndex: 'unit',
        // },
        {
            title: fm({ id: "roles.grid.actions", defaultMessage: 'Actions' }),
            key: 'actions',
            render: (value: any, record: GetAllRolesDto) => {
                return <>
                    <TableActions record={record} editOnClickHandler={editOnClickHandler} statusUpdate={statusUpdate} recordId={record.rolesId}></TableActions>
                </>
            }
        }

    ]
    return <>
        <Input.Search
            placeholder="Search"
            allowClear
            onChange={(e) => { setSearchedText(e.target.value) }}
            onSearch={(value) => { setSearchedText(value) }}
            style={{ width: 200, float: "right" }}
        />
        <Table className='contracts' columns={RolesColumns} dataSource={rolesData} />
    </>
}