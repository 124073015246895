import { CompanyCreateDto, CompanyService, CompanyUpdateDto, CurrencyService } from '@exportx/shared-models-and-services';
import { ClassValidator } from "@exportx/ui-utils";
import { Button, Card, Form, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, GenericFormWithOutPopUP, InputTypes, isPermissionExist, useAuthState } from "../../../../../common";

interface ICompanyFormProps {


}

export const CompanyForm = (props: ICompanyFormProps) => {
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const { state }: any = useLocation();
  const { authContext } = useAuthState();//to access global authorized user data(Login user Data which is passed as created user or updated user)
  //Common for all Page Components
  const { isUpdate, companyData, isNewRecord } = state ? state : { isUpdate: false, companyData: null, isNewRecord: true }; // Read values passed on router  
  const [formRef] = Form.useForm();
  const [currencies, setCurrencies] = useState<any[]>([]);
  const service = new CompanyService();

  const currencyService = new CurrencyService()

  useEffect(()=>{
    const accessId = {
      create: [10],
      view: [11],
      edit: [12]
    }
    const hasPermission = isNewRecord ? accessId.create : isUpdate ? accessId.edit : accessId.view;

    if(!isPermissionExist(hasPermission)){
      return navigate('/')
    }

  },[])

  useEffect(() => {
    getCurrencyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  const getCurrencyData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        // setCurrencies(res.data);
        setCurrencies(res.data.map(rec => { return { value: rec.currencyCode, label: rec.currencyCode } }));
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const classValidator = new ClassValidator();


  let pathToreDirect = '/company';
  const submitFormHandler = () => {
    formRef.validateFields().then(values => {
      submitForm(values);
    })
  }
  const clearData = () => {
    formRef.resetFields();
  }

  const submitForm = (values: any) => {
    if (isUpdate && companyData?.companyCode) {
      update(values);
      return;
    }
    const createDto: CompanyCreateDto = new CompanyCreateDto(values.companyCode, values.companyName, values.currencyCode, authContext.user.userName);
    service.createCompany(createDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Company created SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const update = (values: any) => {
    const updateDto: CompanyUpdateDto = new CompanyUpdateDto(values.companyCode, values.companyName, values.currencyCode, authContext.user.userName, companyData.versionFlag);
    service.updateCompany(updateDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: ` Company Data Updated SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const formItems = [
    {
      name: 'companyCode',
      label: fm({ id: "mdm.components.companyForm.companyCode", defaultMessage: 'company Code' }),
      fieldType: {
        otherProps: {
          disabled: companyData?.companyCode ? true : false
        }, type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.companyForm.companyCodeReq", defaultMessage: 'Please Enter Company Code' })
      },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.companyForm.companyCodeReq`, defaultMessage: "Please Enter Company Code" }) },

        ...classValidator.alphaNumericWithOutSpecialChars(),
        ...classValidator.lengthValidation(15, fm({ id: "mdm.components.companyForm.companyCode", defaultMessage: ' Company Code' }))
      ]
    },
    {
      name: 'companyName',
      label: fm({ id: "mdm.components.companyForm.companyName", defaultMessage: 'Company Name' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.companyForm.companyNameReq", defaultMessage: 'Please Enter company Name' }) },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.companyForm.companyNameReq`, defaultMessage: "Please Enter company Name" }) },

        ...classValidator.alphaNumericWithOutSpecialChars(),
        ...classValidator.lengthValidation(40, fm({ id: "mdm.components.companyForm.companyName", defaultMessage: 'companyName' }))
      ]
    },
    {
      name: 'currencyCode',
      label: fm({ id: `mdm.components.companyForm.currency`, defaultMessage: 'Currency' }),
      fieldType: { type: InputTypes.Select, options: currencies, placeHolder: fm({ id: `mdm.components.companyForm.currencyReq`, defaultMessage: 'Select Currency' }) },
      validationRules: [
        { required: true, message: fm({ id: `mdm.components.companyForm.currencyReq`, defaultMessage: 'Select Currency' }) },
      ]
    },
    {
      name: 'unitId',
      label: fm({ id: "mdm.components.companyForm.companyCode", defaultMessage: 'company Code' }),
      hidden: true,
      fieldType: {
        otherProps: {
          disabled: companyData?.companyCode ? true : false,

        }, type: InputTypes.Input, placeHolder: fm({ id: "mdm.components.companyForm.companyCodeReq", defaultMessage: 'Please Enter Company Code' })
      },
     
    },
  ]


  return (
    <Card bodyStyle={{ overflow: 'auto' }} title={fm({ id: 'mdm.components.companyForm.header', defaultMessage: 'Company Details' })}
      className='default-card-class'
      extra={
        <Button
          onClick={() => navigate(pathToreDirect)}
        >
          {fm({ id: 'common.view', defaultMessage: 'View' })}
        </Button>
      }
    >
      <GenericFormWithOutPopUP
        title={'Company'}
        formItems={formItems}
        clearData={clearData}
        formRef={formRef}
        isUpdate={isUpdate}
        isNewRecord={isNewRecord}
        closeForm={() => { }}
        submitForm={() => { }}
        initialValues={companyData}              >
        {
          (isNewRecord || isUpdate) && <Space>
            <Button
              onClick={() => { clearData(); }}
            >
              {fm({ id: "common.clear", defaultMessage: "Clear" })}
            </Button>
            <Button onClick={submitFormHandler} type="primary">
              {fm({ id: "common.submit", defaultMessage: "Submit" })}
            </Button>
          </Space>
        }
      </GenericFormWithOutPopUP>
    </Card>






  )
}

export default CompanyForm;
