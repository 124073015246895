// import { PurchaseContractService } from "@exportx/shared-models-and-services";
import { VendorAdvService } from "@exportx/shared-models-and-services";
import { Card, Col, Form, Input, Row, Select, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertMessages, useAuthState } from "../../../../../common";
import Link from 'antd/lib/typography/Link';

const AdvanceActiveApproval = () => {
  const [advanceActive, setadvanceActive] = useState<any>();
  const [searchData, setSearchData] = useState("")

  const service = new VendorAdvService()
  const navigate = useNavigate();
  let pathToreDirect = '/advanceDetailView'
  const { authContext } = useAuthState()

  const redirectToFullView = (contractId: string) => {
    navigate(pathToreDirect, { state: { contractId } });
  }

  useEffect(() => {
    AdvanceActive();
  }, []);


  const AdvanceActive = () => {
    service.getAdvanceApprovalData({ plantCode: authContext.defaultPlant }).then(res => {
        if (res.status) {
          setadvanceActive(res.data)
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message)
      })
  }
  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contract_no',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contract_no)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contract_date)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.bp_name)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.approvedBy)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
      render: (value, rowData) =>{
        const link = `/#${pathToreDirect}?id=${rowData.id}`
        return <> {
        <Link href={link} className="link-primary" >{value}</Link>
      }
      </>}

    },

    {
      title: 'Contract Type',
      dataIndex: 'contract_type',
    },

    {
      title: 'Contract Date',
      dataIndex: 'contract_date',
      render: (text, record) => { return record.contract_date !== undefined ? moment(record.contract_date).format('YYYY-MM-DD') : "" },
      sorter: (a, b) => moment(a.contract_date).unix() - moment(b.contract_date).unix(),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Vendor',
      dataIndex: 'bp_name',
    },

    {
      title: 'Approved By',
      dataIndex: 'approved_by',
    },




  ]


  return (
    <Card>


      <Form layout="vertical">
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
          </Col>
        </Row>



      </Form>
      <Table className='contracts' columns={tableColumns} dataSource={advanceActive}></Table>

    </Card>

  )

}
export default AdvanceActiveApproval;