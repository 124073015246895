import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { BpCategoryGetDto, BusinessPartnerService, BusinessPartnerTypeEnum, PriceTypeEnum } from '@exportx/shared-models-and-services';
import { Form, Input, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const { Option } = Select;
export const ServiceFeesGridForm = (props) => {
    const { formatMessage: fm } = useIntl();
    const { csfManualData, form, currency, add, remove } = props;
    const [vendorsDropData, setVendorsDropData] = useState<any[]>([]);


 

    useEffect(() => {
        const getVendorDropData = () => {
            const bpService = new BusinessPartnerService();
            const req = new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)
            bpService.getAllBusinessPartnersForDropDown(req).then((res) => {
                if (res.status) {
                    setVendorsDropData(res.data)
                } else {
                    setVendorsDropData([])
                }
            }).catch((err) => {
                setVendorsDropData([])
            })
        }
        getVendorDropData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);





    const getTotal = (name) => {
        const priceType = form.getFieldValue(['csfManualData', name, 'priceType']);
        const quantity = form.getFieldValue(['csfManualData', name, 'quantity']) ? form.getFieldValue(['csfManualData', name, 'quantity']) : 0;
        const pricePMT = form.getFieldValue(['csfManualData', name, 'pricePMT']) ? form.getFieldValue(['csfManualData', name, 'pricePMT']) : 0;
        if (priceType === PriceTypeEnum.LUMP_SUM) {
            form.setFieldValue(['csfManualData', name, 'total'], pricePMT);
            return pricePMT;
        }
        const total = quantity * pricePMT;
        form.setFieldValue(['csfManualData', name, 'total'], total);
        return total;
    }
    return (
        <>
            {
                React.Children.toArray(csfManualData?.map((field, index) => (
                    <tr>
                        <td className='table-form'>
                            <Form.Item
                                key={field.key}
                                name={[field.name, "buyer"]} rules={[{ required: true, message: "Select Buyer" }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {vendorsDropData?.map((el) => {
                                        return <Option value={el.bpId} >{el.bpName}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            -
                        </td>
                        <td className='table-form'>
                            {
                                <Form.Item rules={[{ required: true, message: "Enter Quality" }]}
                                    name={[field.name, "qualityInput"]}><Input type='number' />
                                </Form.Item>
                            }

                        </td>
                        <td className='table-form'>
                            -
                        </td>
                        <td className='table-form'>
                            <Form.Item
                                name={[field.name, "priceType"]}>
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch >
                                    {React.Children.toArray(Object.keys(PriceTypeEnum).map(deduction => {
                                        return <Option value={PriceTypeEnum[deduction]}>{PriceTypeEnum[deduction]}</Option>
                                    }))}
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Enter Quantity" }]}
                                name={[field.name, "quantity"]}><Input type='number' />
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Enter PricePMT" }]}
                                name={[field.name, "pricePMT"]}><Input type='number' />
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Select Currency" }]} name={[field.name, "currency"]} style={{ width: "80%" }}>
                                <Select
                                    className="w-100"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {currency?.map((c) => {
                                        return <Option value={c.currencyCode}>{c.currencyName}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='text-right'>
                            {getTotal(field.name)}
                            <Form.Item name={[field.name, "total"]} style={{ display: 'none' }}>
                                <Input hidden />
                            </Form.Item>
                        </td>
                        <td className='text-center'>
                            {(csfManualData.length - 1 === index) && <span>
                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                    <PlusCircleOutlined style={{ fontSize: '21px' }} onClick={() => add({ priceType: PriceTypeEnum.PER_MT })} />
                                </Tooltip>
                            </span>}
                            {index !== 0 && <span>
                                <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                    <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                                        remove(field.name);
                                    }} />
                                </Tooltip>
                            </span>}
                        </td>
                    </tr>
                )))
            }
            <tr>
                <td colSpan={5}></td>
                <td className='text-right' style={{ fontWeight: 'bold' }}>{form.getFieldValue(['csfManualData'])?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)}</td>
                <td colSpan={2}></td>
                <td className='text-right' style={{ fontWeight: 'bold' }} >{form.getFieldValue(['csfManualData'])?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)}</td>
                <td></td>
            </tr>
        </>
    )
}

export default ServiceFeesGridForm