import { BasisDropDownDto, BasisService } from '@exportx/shared-models-and-services';
import { Card, Col, Form, FormInstance, Input, Row, Select } from 'antd'
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'

interface IElementsProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}

export const Elements = (props: IElementsProps) => {
  const { Option } = Select;
  const { formRef, initialValues, children, layoutType } = props;


  const basisService = new BasisService();
  const [basisData, setBasisData] = useState<BasisDropDownDto[]>([]);
  useEffect(() => {
    getCBasisData();

  }, []);


  const getCBasisData = () => {
    basisService.getAllBasisDropDownDto().then(res => {
      if (res.status) {
        setBasisData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }



  return (
    <Card>
      <Form
        layout={layoutType} form={formRef} initialValues={initialValues}
      >

        <Row >

          <Col><h3 >Trace Elements</h3></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item name="traceArsenicBasis"
              label="Arsnic(PPM)"
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >

                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="traceArsenicTypical" >

              <Input type="number" placeholder="Typical" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item name="traceArsenicRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item name="traceMercuryBasis" label="Mercury(ppm)">

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >

                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item name="traceMercuryTypical" >

              <Input type="number" placeholder="Typical" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="traceMercuryRejection">
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}  >
            <Form.Item name="traceSeleniumBasis" label="Selenium(ppm)">

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="traceSeleniumTypical" >

              <Input type="number" placeholder="Typical" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="traceSeleniumRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="traceBoronBasis" label="Boron(ppm)" >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >

                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="traceBoronTypical" >

              <Input type="number" placeholder="Typical" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="traceBoronRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <h3>Minor Elements</h3>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}  >
            <h5>initialde formation</h5>
            <Form.Item name="minorBaBasis"  >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >

                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="minorBaTypical" >

              <Input type="number" placeholder="Typical" style={{ marginTop: "29px" }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="minorBaRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "29px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item name="minorPhosphorousBasis" label="Softening(Deg.C)">

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >

                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="minorPhosphorousTypical">

              <Input type="number" placeholder="Typical" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item name="minorPhosphorousRejection">
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>



      </Form>

    </Card>
  )
}

export default Elements;