import { ContractTypesEnum } from '@exportx/shared-models-and-services';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { UltimateModel } from './ultimate.model';

export interface IUltimateData {
    ultimate: UltimateModel;
    addendumUltimate: UltimateModel;
    contractType: ContractTypesEnum;
}
const UltimateDataView = (props: IUltimateData) => {
    const { contractType } = props;
    const [ultimateDataSource, setUltimateDataSource] = useState([]);
    const [addendumUltimateDataSource, setAddendumUltimateDataSource] = useState([]);

    useEffect(() => {
        if (props.ultimate)
            setUltimateDataSource(constructDataSource(props.ultimate));
        if (props.addendumUltimate)
            setAddendumUltimateDataSource(constructDataSource(props.addendumUltimate));
    }, [props.ultimate]);

    const constructDataSource = (ultimate: UltimateModel) => {
        let dataSource = [];
        dataSource.push({
            basis: 'Carbon (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ultimate.ultimateCarbonBasis ? ultimate.ultimateCarbonBasis : '-',
            typical: ultimate.ultimateCarbonTypical ? ultimate.ultimateCarbonTypical : '-',
            rejection: ultimate.ultimateCarbonRejection ? ultimate.ultimateCarbonRejection : '-',
        });

        dataSource.push({
            basis: 'Hydrogen (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ultimate.ultimateHydrogenBasis ? ultimate.ultimateHydrogenBasis : '-',
            typical: ultimate.ultimateHydrogenTypical ? ultimate.ultimateHydrogenTypical : '-',
            rejection: ultimate.ultimateHydrogenRejection ? ultimate.ultimateHydrogenRejection : '-',
        });

        dataSource.push({
            basis: 'Nitrogen (%)',
            typical: '-',
            rejection: '-',
        });
        dataSource.push({
            basis: ultimate.ultimateNitrogenBasis ? ultimate.ultimateNitrogenBasis : '-',
            typical: ultimate.ultimateNitrogenTypical ? ultimate.ultimateNitrogenTypical : '-',
            rejection: ultimate.ultimateNitrogenRejection ? ultimate.ultimateNitrogenRejection : '-',
        });
        return dataSource;
    };

    const sharedOnCell = (_: any, index: number) => {
        if ([0, 2, 4].includes(index)) {
            return { colSpan: 0 };
        }
        return {};
    };
    const valiDateWithSnapShotData = (index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = addendumUltimateDataSource?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM && valueAtGivenKey !== '-') {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={''}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }

    const ultimateColumns: ColumnsType<any> = [
        {
            title: 'Basis',
            key: 'basis',
            dataIndex: 'basis',
            onCell: (_, index) => ({
                colSpan: [0, 2, 4].includes((index as number)) ? 3 : 1,
            }),
            render: (value, row: any, index) => (
                <a>{valiDateWithSnapShotData(index, 'basis', value)}</a>
            ),
        },
        {
            title: 'Typical',
            key: 'typical',
            dataIndex: 'typical',
            onCell: sharedOnCell,
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'typical', value)}</span>
            ),
        },
        {
            title: 'Rejection',
            key: 'rejection',
            dataIndex: 'rejection',
            onCell: sharedOnCell,
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'rejection', value)}</span>
            ),
        }
    ];

    const rowClassName = (row: any, index) => {
        return index % 2 === 0 ? 'row-even' : 'row-odd'; 
      };

    return (
        <Table columns={ultimateColumns} dataSource={ultimateDataSource} rowClassName={rowClassName} pagination={false} className="contracts-PO-List" />
    )
}

export default UltimateDataView