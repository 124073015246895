import { Button, Card, Col, Input, Modal, Row, Table, Tooltip } from "antd";
import { FileExcelFilled } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useAuthState } from "../../../common";
import { AccountPayableRequestDto, AccountsVoucherTypesEnum, PaymentDateReqDto, PaymentVoucherService } from "@exportx/shared-models-and-services";
import { useIntl } from "react-intl";
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { PaymentG } from "../payment-voucher";
import moment from "moment";


export const AccountsReceivables = () => {
  const { formatMessage: fm } = useIntl();
  const { authContext } = useAuthState();
  const [accountReceivables, setAccountReceivables] = useState([]);
  const navigate = useNavigate();
  const [modelVisible, setModalVisible] = useState(false)
  const [receivables, setReceivables] = useState([])
  const service = new PaymentVoucherService()
  const [searchData, setSearchData] = useState("")

  const moduleClose = () => {
    setModalVisible(false)
  }

  useEffect(() => {
    getReceivables()
  }, [])

  const getReceivables = () => {
    const req = new AccountPayableRequestDto(authContext.defaultPlant)
    service.getAccountReceivables(req).then(res => {
      if (res.status) {
        setReceivables(res.data);
      }
      else {
        setReceivables([]);
      }


    })

  }
  const columns = [
    {
      title: 'Debtor Name',
      dataIndex: 'ledgerName',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.ledgerName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.credit)
            .toLowerCase()
            .includes(value.toLocaleString()) ||

          String(record.status)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
    },
    {
      title: 'Amount',
      dataIndex: 'credit',
      align: 'right' as 'right',
      render: (text, row) => {
        let amount = row.credit + row.debit;
        return amount;
      }
    },
    {
      title: 'Action',
      render: (text, rowData) => (
        <Tooltip placement="top" title={fm({ id: "common.view" })}>
          <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }}
            onClick={e => {
              setModalVisible(true)
              view(undefined, false);
            }}
          />
        </Tooltip>
      )
    }
  ]


  const column = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: '220px',
      render: (val, record) => {
        if (record?.ledgerName != 'Total')
          return moment(val).format('DD-MMM-YYYY');
      }
    },
    {
      title: 'Particulars',
      dataIndex: 'ledgerName',
      width: '220px'
    },
    // {
    //   title: 'Vc Type',
    //   dataIndex: 'voucherType',
    //   width: '220px'
    // },
    // {
    //   title: 'ID',
    //   dataIndex: 'voucherSLNo',
    //   width: '100px'
    // },
    // {
    //   title: 'VoucherNo',
    //   dataIndex: 'voucherClientID',
    //   width: '100px'
    // },

    {
      title: 'Debit',
      dataIndex: 'debit',
      width: '100px',
      align: 'right' as 'right',
      render: (value, record) => {
        return <>{value ? value : "0"}</>
      }
    },

    {
      title: 'Credit',
      dataIndex: 'credit',
      width: '100px',
      align: 'right' as 'right',
      render: (value, record) => {
        return <>{value ? value : "0"}</>
      }
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      width: '100px',
      align: 'right' as 'right',
      render: (value, record) => {
        return <>{value ? value : "0"}</>
      }
    },
    // {
    //   title: '',
    //   dataIndex: 'balanceType',
    //   render: (value, record) => {
    //     return <>{`${value ? value :""}`}</>
    //   }
    // },
  ];

  const view = (paymentData: PaymentDateReqDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (paymentData) {
      isNewRecord = false;
    }

  }



  useEffect(() => {
    const getAccountReceivables = () => {
      const request = new AccountPayableRequestDto(authContext.defaultPlant);
      const service = new PaymentVoucherService();
      service.getAccountReceivables(request).then(res => {
        if (res.status) {
          setAccountReceivables(res.data);
        } else {
          setAccountReceivables([]);
        }
      })
    }
    getAccountReceivables();
  }, [])

  return (
    <div>
      <Card title={<span>{'Account Receivables (Sundry Debtors)'}</span>}>
        <Tooltip placement="topRight" title="Export As Excel">
          <Button
            style={{ float: "right" }}
            size='small'
            type="default"
            icon={<FileExcelFilled />}
          >Export</Button>
        </Tooltip>
        <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />
        <Table columns={columns} dataSource={accountReceivables} pagination={false} bordered />

      </Card>
      <Modal open={modelVisible} onCancel={moduleClose} footer={null} width={1000}>
        <Table columns={column} dataSource={receivables} pagination={false} bordered></Table>
      </Modal>
    </div>
  )

}
export default AccountsReceivables;