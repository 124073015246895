import { BpCategoryGetDto, BPDropDownDto, BusinessPartnerService, BusinessPartnerTypeEnum, SpecTypesEnum, SurveyorEnum } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Form, FormInstance, Input, Row, Select, Space, Tooltip } from 'antd';
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useAuthState } from '../../../common';
const { Option } = Select;
interface ISurveyorDetailsProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}
export const SurveyorDetails = (props: ISurveyorDetailsProps) => {
  const { authContext } = useAuthState();
  const [surveyorDropDownData, setSurveyorDropDownData] = useState<BPDropDownDto[]>([]);
  const { formatMessage: fm } = useIntl();
  const { formRef, layoutType, initialValues } = props;


  const businessPartnerService = new BusinessPartnerService();

  useEffect(() => {
    getAllSurveyorDropDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const getAllSurveyorDropDown = () => {
    businessPartnerService.getAllSurveyorDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)).then(res => {
      if (res.status) {
        setSurveyorDropDownData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getDropDownData = (name: number) => {
    const abc = formRef.getFieldValue(['surveyorData', name, 'surveyorAndAnalysis']);
    if (typeof abc[0] === 'string')
      return React.Children.toArray(surveyorDropDownData.map(item => {
        return <Option value={item.bpId}>{item.bpName}</Option>
      }))
    else {
      const selectedSurveyors = abc?.map(rec => rec?.surveyor);
      return React.Children.toArray(surveyorDropDownData.map(item => {
        return <Option value={item.bpId} disabled={selectedSurveyors.includes(item.bpId)} >{item.bpName}</Option>
      }))
    }
  }

  const getSurveyorTypeData = () => {
    const abc = formRef.getFieldValue(['surveyorData']);
    if (abc.length === 1 && abc[0]?.surveyorType === undefined) {
      return [SurveyorEnum.SUPPLIER, SurveyorEnum.INTERNAL, SurveyorEnum.JOINT].map(contractTermKey => {
        return <Option value={contractTermKey}>{contractTermKey === SurveyorEnum.INTERNAL ? authContext.defaultPlant : contractTermKey}</Option>
      })
    } else {
      const selectedSurveyors = abc?.map(rec => rec?.surveyorType);
      return [SurveyorEnum.SUPPLIER, SurveyorEnum.INTERNAL, SurveyorEnum.JOINT].map(contractTermKey => {
        return <Option value={contractTermKey} disabled={selectedSurveyors.includes(contractTermKey)}>{contractTermKey === SurveyorEnum.INTERNAL ? authContext.defaultPlant : contractTermKey}</Option>
      })
    }

  }

  const surveyorOnChangeHandler = (surveyorId: string, surveyorTypeKey: number, surveyorKey: number) => {
    const surveyorName = surveyorDropDownData.filter(rec => rec.bpId === surveyorId)[0].bpName;
    formRef.setFieldValue(['surveyorData', surveyorTypeKey, 'surveyorAndAnalysis', surveyorKey, 'surveyorName'], surveyorName);
  }



  return (
    <Form
      layout={layoutType}
      form={formRef}
      initialValues={{ surveyorData: initialValues }}
    >
      <Card title={fm({ id: `mdm.components.surveyorForm.header`, defaultMessage: `Surveyor` })}>
        <Form.List name="surveyorData">
          {(qualityFields, { add: addQuality, remove: removeQuality }) => (
            <div >
              {qualityFields.map((qualityField, qualityIndex) => (
                <Card>
                  <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
                      <Form.Item name={[qualityField.name, "surveyorType"]}
                        rules={[
                          {
                            required: true, message: 'Please select Surveyor Type'
                          }
                        ]}
                      >
                        <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch >
                          {
                            getSurveyorTypeData()
                          }</Select>
                      </Form.Item>
                    </Col>
                    <Col>
                      {<Tooltip placement="top" title={fm({ id: "common.delete" })}>
                        <DeleteFilled style={{ color: '#f70404', fontSize: '21px' }} onClick={() => {
                          removeQuality(qualityIndex)
                        }} />
                      </Tooltip>}
                    </Col>
                  </Row>
                  <Form.List name={[qualityField.name, "surveyorAndAnalysis"]}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <Row>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                              <Form.Item label='surveyor' name={[name, "surveyor"]}
                                rules={[
                                  {
                                    required: true, message: 'Please select Surveyor'
                                  }
                                ]}
                              >
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                  allowClear
                                  showSearch
                                  onChange={(value) => surveyorOnChangeHandler(value, qualityField.name, name)}
                                >
                                  {
                                    getDropDownData(qualityField.name)
                                  }
                                </Select>
                              </Form.Item>
                              <Form.Item name={[name, "surveyorId"]} style={{ display: 'none' }}  >
                                <Input hidden />
                              </Form.Item>
                              <Form.Item name={[name, "surveyorName"]} style={{ display: 'none' }}  >
                                <Input hidden />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 1 }}>
                              <Form.Item label='Analysis Type' name={[name, "analysisType"]}
                                rules={[
                                  {
                                    required: true, message: 'Please select Analysis Type'
                                  }
                                ]}
                              >
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch >
                                  {Object.keys(SpecTypesEnum).map(contractTermKey => {
                                    return <Option value={SpecTypesEnum[contractTermKey]}>{SpecTypesEnum[contractTermKey]}</Option>
                                  })}
                                </Select>

                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 2, offset: 1 }}
                              sm={{ span: 2, offset: 1 }}
                              md={{ span: 2, offset: 1 }}
                              lg={{ span: 2, offset: 1 }}
                              xl={{ span: 2, offset: 1 }}>
                              <div className='pt-4'>
                                {(index !== 0 && <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                  <DeleteFilled style={{ color: '#f70404', fontSize: '21px' }} onClick={() => {
                                    remove(name);
                                  }} />
                                </Tooltip>)}
                                {(index === (fields.length - 1) && <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                  <PlusCircleFilled style={{ color: '#2193f3', fontSize: '21px' }} onClick={() => {
                                    formRef.validateFields().then(values => {
                                      add()
                                    })
                                  }} />
                                </Tooltip>)}
                              </div >
                            </Col>
                          </Row>
                        ))}
                      </>)}
                  </Form.List>
                </Card>
              ))}
              <Form.Item >
                <Button type="dashed" onClick={() => {
                  formRef.validateFields().then(values => {
                    addQuality({ surveyorAndAnalysis: [''] });
                  })
                }}>
                  Add Surveyor
                </Button>
              </Form.Item>
            </div>)}
        </Form.List>
      </Card>
      {props?.children}
    </Form>


  )
}
export default SurveyorDetails