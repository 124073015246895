import { Button, Card, Tooltip, Table, Divider, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { FileExcelFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { Excel } from 'antd-table-saveas-excel';
import { AccountsActLedDto, AccountsActLedRequest, AccountsService } from '@exportx/shared-models-and-services';
import { useIntl } from 'react-intl';
import { AlertMessages } from '../../../common';
import { useLocation, useNavigate } from 'react-router-dom';



export const AccountCreationG = () => {
    const { formatMessage: fm } = useIntl();
    const [gridData, setGridData] = useState();
    const service = new AccountsService()
    const [searchData, setSearchData] = useState("")
    const [, setAccountsData] = useState<any>(undefined)
    const navigate = useNavigate();
    let pathToreDirect = '/create-accounts';

    useEffect(() => {
        getAllAccounts();
    }, []);

    const getAllAccounts = () => {
        service.getAllAccounts().then(res => {
            if (res.status) {
                setGridData(res.data)
            } else {
                if (res.errorCode !== 99998) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }


    const deleteAccount = (data) => {
        const reqDto = new AccountsActLedRequest(data?.actLedId);
        service.deleteAccount(reqDto).then(res => {
            console.log(res);
            if (res.status) {
                getAllAccounts();
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                if (res.errorCode) {
                    AlertMessages.getErrorMessage(res.internalMessage);
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }


    const openFormWithData = (viewData: AccountsActLedDto) => {
        setAccountsData(viewData);
        navigate(pathToreDirect, { state: { rowData: viewData } });
    }


    const handleExport = (e: any) => {
        e.preventDefault();

        // Formatting columns - to set default render

        const currentDate = new Date()
            .toISOString()
            .slice(0, 10)
            .split("-")
            .join("/");
        // Formatting columns - to set default render

        let cloneArr = tableColumns.slice(0);
        cloneArr.splice(0, 1);
        cloneArr.splice(-1);
        const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
            if (typeof item.title === 'string')
                return { title: item.title, dataIndex: item.dataIndex };
            else
                return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
        });

        const excel = new Excel();
        excel
            .addSheet("Sheet1")
        excel.addRow();
        excel.addColumns(exportingColumns)
            .addDataSource(gridData)
            .saveAs(`AccountCreation-${currentDate}.xlsx`);
    };

    const tableColumns: any = [
        {
            title: 'Account Name',
            dataIndex: 'actLedName',
            width: '80px',
            sorter: (a, b) => a.actLedName.localeCompare(b.actLedName),
            sortDirections: ['descend', 'ascend'],
            filteredValue: [String(searchData).toLowerCase()],
            onFilter: (value, record) => {
                return (
                    String(record.actLedName)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.slNo)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.actLedActGrp)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||


                    String(record.status)
                        .toLowerCase()
                        .includes(value.toLocaleString()))
            },
        },
        // {
        //     title: 'Main Group',
        //     dataIndex: 'actledActGrp',
        //     width: '80px'
        // },
        {
            title: 'Group',
            dataIndex: 'actLedActGrp',
            width: '80px',
            sorter: (a, b) => a.actLedActGrp.localeCompare(b.actLedActGrp),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "Action",
            align: 'left',
            fixed: 'right',
            width: '80px',
            render: (text, rowData) => (
                <span>
                    <Tooltip placement="topRight" title="Edit">
                        <EditOutlined type="edit"
                            onClick={() => openFormWithData(rowData)}
                            style={{ color: '#1890ff', fontSize: '20px' }}
                        /></Tooltip>

                    <Divider type="vertical" />
                    <Tooltip placement="topRight" title="Delete">
                        <DeleteOutlined type='delete' onClick={() => deleteAccount(rowData)} style={{ color: 'red', fontSize: '20px' }} />
                    </Tooltip>
                </span>
            )
        }
    ]

    return (
        <div>
            <Card title={<span> Account Creation</span>}
                extra={<Button type='primary' onClick={() => navigate(pathToreDirect)}>Create</Button>}>
                <Tooltip placement="topRight" title="Export As Excel">
                    <Button
                        //disabled={gridData.length === 0}
                        style={{ float: "right" }}
                        size='small'
                        type="default"
                        //className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
                        onClick={handleExport}
                        icon={<FileExcelFilled />}
                    >Export</Button>
                </Tooltip>
                <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />
                <Table columns={tableColumns} dataSource={gridData} pagination={false} bordered />


            </Card>
        </div>
    )
}

export default AccountCreationG