import { PaymentmodesDto, PaymentmodesService } from '@exportx/shared-models-and-services';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { useIntl } from 'react-intl';
import { AlertMessages } from '../../../../../common';

/* eslint-disable-next-line */
export interface PaymentmodesFormProps {
  paymentmodeData: PaymentmodesDto;
  updatePaymentmode: (paymentmode: PaymentmodesDto) => void;
  isUpdate: boolean;
  isNewRecord: boolean;
  closeForm: () => void;
}

export function PaymentModesForm(props: PaymentmodesFormProps) {
  const [form] = Form.useForm();
  const paymentmodesService = new PaymentmodesService()
  const { formatMessage: fm } = useIntl();

  const createPaymentmode = (paymentmodeData: PaymentmodesDto) => {
    paymentmodesService.createPaymentmode(paymentmodeData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        props.closeForm();
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const onReset = () => {
    form.resetFields();
  }

  const saveData = (values: PaymentmodesDto) => {
    if (props.isUpdate) {
      props.updatePaymentmode({ ...props.paymentmodeData, ...values });
    } else {
      createPaymentmode(values);
    }

  };

  return (
    <Card title={'Payment Mode'}
      className='default-card-class' >
      <Form form={form} layout={'vertical'} initialValues={props.paymentmodeData} name="control-hooks" autoComplete='off' onFinish={saveData}  >
        <Form.Item name="paymentModeId" style={{ display: "none" }} >
          <Input hidden />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="createdUser" >
          <Input hidden />
        </Form.Item>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 8 }}>
            <Form.Item
              name="paymentMode"
              label={fm({ id: "mdm.components.paymentmodeForm.paymentMode", defaultMessage: 'Payment Mode' })}
              // label="Payment Mode"
              rules={[
                {
                  required: true,
                  message: "Payment Mode Is Required"

                },
                {
                  pattern: /^[^-\s\\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][a-zA-Z0-9-/\\_@ ]*$/,
                  message: `Should contain only alphabets and numbers.`
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {(props.isNewRecord || props.isUpdate) &&
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button htmlType="button" style={{ margin: '0 8px' }} onClick={onReset}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit" >
                Submit
              </Button>


            </Col>
          </Row>}
      </Form>
    </Card>
  );
}

export default PaymentModesForm;
