import { ArrowDownOutlined, ArrowUpOutlined, ReloadOutlined } from '@ant-design/icons';
import { CoalIndexedPriceService, ColaIndexTypeEnum, ProrateGradeIndexes, SpecificationsEnum } from '@exportx/shared-models-and-services';
import { getLocalFormat } from '@exportx/ui-utils';
import { Button, Space, Statistic, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, isPermissionExist } from '../../../../../common';
import ViewFileHandling from '../../../../../common/file-upload-form/view-files-handling';

interface ICoalIndexGridProps {
    coalIndexType: ColaIndexTypeEnum
}
export const CoalIndexPricesGrid = (props: ICoalIndexGridProps) => {
    const { formatMessage: fm } = useIntl();
    const [offSet, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [permission, setPermission] = useState<any>()
    const service = new CoalIndexedPriceService();
    const { coalIndexType } = props;

    useEffect(()=>{
        if(coalIndexType === ColaIndexTypeEnum.WEEKLY){
            setPermission([128])
        } else {
            setPermission([136])
        }
    },[])
    const columns = [];
    columns.push(
        {
            title: coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'Weak Ending' : 'Month Ending',
            dataIndex: coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'weekEndingDate' : 'monthYear',
            render: (text, RowData: any, index: number) => {
                return <>
                    {moment(text).format('DD MMM YYYY')}
                </>
            }
        }
    );
    ProrateGradeIndexes.garIndexes.forEach((garIndex, index) => {
        columns.push({
            title: `${garIndex}  ${SpecificationsEnum.GAR} (${ProrateGradeIndexes.narIndexes[index]}  ${SpecificationsEnum.NAR})`,
            dataIndex: `gar${garIndex}`,
            render: (text, RowData: any, index: number) => {
                let color = 'green';
                let arrow = <ArrowUpOutlined style={{ fontSize: '12px', width: '10px', height: '10px' }} />;
                if (Number(text) < Number(tableData?.[index + 1]?.[`gar${garIndex}`])) {
                    color = 'red';
                    arrow = <ArrowDownOutlined style={{ fontSize: '12px', width: '10px', height: '10px' }} />;
                }
                return <Statistic
                    value={getLocalFormat(text, 'USD')}
                    // precision={2}
                    valueStyle={{
                        color: color,
                        fontSize: '16px'
                    }}
                    prefix={<span color={'green'} key={'green'}>{arrow}</span>}
                />
            }
        },

        );
    });
    if (isPermissionExist(permission)) {
        columns.push({
            title: 'ICI File',
            render: (_, record) => {
                return <>
                    < ViewFileHandling filesData={record?.filesData} required={true} />
                </>
            }
        })
    }

    const getIndexedData = (requestObj: any) => {
        service.getAllCoalIndexPrice(requestObj).then(res => {
            if (res.status) {
                setTableData(prevData => [...prevData, ...res.data]);
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        });
    }

    useEffect(() => {
        let requestObject = {
            limit: 10,
            offSet,
            coalIndexType
        }
        getIndexedData(requestObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offSet]);


    return (
        <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            footer={() => <Space style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={() => setOffSet(prevOffSet => prevOffSet + 5)}
                >
                    Load More
                </Button>

            </Space>}
            // sticky={true}
            scroll={{ x: 700 }}
        />
    )
}

export default CoalIndexPricesGrid;