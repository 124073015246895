import { FixtureNotesService } from '@exportx/shared-models-and-services';
import { Button, Col, Row, Tooltip } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { AlertMessages, FilterableGrid } from '../../../../common';
import { FileExcelFilled } from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';
import { useNavigate } from 'react-router-dom';


interface IPropsFixtureNotesGrid {

}

export const FixtureNotesGrid = (Props: IPropsFixtureNotesGrid) => {
  const { formatMessage: fm } = useIntl();
  const [gridData, setGridData] = useState([])
  const service = new FixtureNotesService();
  const navigate = useNavigate()
  let pathToreDirect = '/detailedview';



  useEffect(() => {
    getAllFixtureNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllFixtureNotes = () => {
    service.getAllFixtureNotes(1).then(res => {
      if (res.status) {
        setGridData(res.data)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columns: any = [
    {
      title: fm({ id: 'mdm.components.fixtureNoteForm.supplierReferenceNo', defaultMessage: "Supplier Reference No" }),
      dataIndex: 'supplierReferenceNo',
      render: (value, record) => <> {
        <Link onClick={() => navigate(pathToreDirect)}>{value}</Link>
      }
      </>

    },
    {
      title: fm({ id: 'mdm.components.fixtureNoteForm.motherVesselName', defaultMessage: "Mother Vessel Name" }),
      dataIndex: 'motherVesselName',

    },
    {
      title: fm({ id: 'mdm.components.fixtureNoteForm.allowedDays', defaultMessage: "Allowed Days" }),
      dataIndex: 'allowedDays',

    },
    {
      title: fm({ id: 'mdm.components.fixtureNoteForm.loadingRatePerDay', defaultMessage: "Loading Rate per Day" }),
      dataIndex: 'loadingRatePerDay',

    },
    {
      title: fm({ id: 'mdm.components.fixtureNoteForm.portOfLoading', defaultMessage: "Port of Loading" }),
      dataIndex: 'portOfLoading',

    },


  ];
  const handleExport = (e: any) => {
    e.preventDefault();

    // Formatting columns - to set default render

    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("/");
    // Formatting columns - to set default render
    let cloneArr = columns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
    });

    const excel = new Excel();
    excel
      .addSheet("Sheet1")
    excel.addRow();
    excel.addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`FixtureNotes-${currentDate}.xlsx`);
  };

  return (
    <div>
      <Row justify="end">
        <Col><Tooltip placement="topRight" title="Export As Excel">
          <Button
            disabled={gridData.length === 0}
            size="large"
            type="default"
            className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
            onClick={handleExport}
            icon={<FileExcelFilled />}
          >Export Excel</Button>
        </Tooltip></Col></Row>
      <FilterableGrid
        rowKey=''
        mainColumns={columns}
        data={gridData}
        filterColumnsData={[{ dataIndex: 'name', placeHolder: fm({ id: "mdm.components.businessPartnerCategoryGrid.name", defaultMessage: 'Name' }) }, { dataIndex: 'type', placeHolder: fm({ id: "mdm.components.businessPartnerCategoryGrid.type", defaultMessage: 'type ' }) }, { dataIndex: 'contact', placeHolder: fm({ id: "mdm.components.businessPartnerCategoryGrid.contact", defaultMessage: 'Fixture-Notes ' }) },]}
        otherTableProps={{ bordered: true }} activateOrDeactivate={undefined} view={undefined}
      />

    </div>
  )
}

export default FixtureNotesGrid;