import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const SamplingAnalysisSelection = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    s_a_pc_supplier: [],
    s_a_pc_company: [],
    s_a_pc_actual: [],
    s_a_pc_pre_shipment: [],
    s_a_pc_supplier_report: [],
    s_a_pc_company_report: [],
    s_a_pc_actual_report: [],
    s_a_pc_aging_report: [],
    s_a_pc_shipment_report: [],
    s_a_sales_actual: [],
    s_a_sales_supplier_report: [],
    s_a_sales_aging_report: [],

  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          s_a_pc_supplier: features?.filter((cou) => 836 <= +cou && +cou <= 840)?.map(Number),
          s_a_pc_company: features?.filter((cou) => 841 <= +cou && +cou <= 845)?.map(Number),
          s_a_pc_actual: features?.filter((cou) => 846 <= +cou && +cou <= 850)?.map(Number),
          s_a_pc_pre_shipment: features?.filter((cou) => 851 <= +cou && +cou <= 855)?.map(Number),
          s_a_pc_supplier_report: features?.filter((cou) => 856 <= +cou && +cou <= 859)?.map(Number),
          s_a_pc_company_report: features?.filter((cou) => 860 <= +cou && +cou <= 863)?.map(Number),
          s_a_pc_actual_report: features?.filter((cou) => 864 <= +cou && +cou <= 867)?.map(Number),
          s_a_pc_aging_report: features?.filter((cou) => 868 <= +cou && +cou <= 871)?.map(Number),
          s_a_pc_shipment_report: features?.filter((cou) => 872 <= +cou && +cou <= 875)?.map(Number),
          s_a_sales_actual: features?.filter((cou) => 876 <= +cou && +cou <= 880)?.map(Number),
          s_a_sales_supplier_report: features?.filter((cou) => 881 <= +cou && +cou <= 884)?.map(Number),
          s_a_sales_aging_report: features?.filter((cou) => 885 <= +cou && +cou <= 888)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({ ...prev, [name]: value }))
    }
    else setValues((prev) => ({ ...prev, [name]: [] }));
  }


  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}

    >
      <Card size="small" title='Purchase Quality'>
        <Card size="small" type="inner" title="S&A Supplier"
          extra={<Checkbox
            name="s_a_pc_supplier"
            indeterminate={values.s_a_pc_supplier.length > 0 && values.s_a_pc_supplier.length < 5}
            checked={values.s_a_pc_supplier.length === 5}
            onChange={(e) => onChange(e, 's_a_pc_supplier', [836, 837, 838, 839, 840])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_pc_supplier" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_pc_supplier: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={836}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={837}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={838}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={839}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={840}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="S&A Company"
          extra={<Checkbox
            name="s_a_pc_company"
            indeterminate={values.s_a_pc_company.length > 0 && values.s_a_pc_company.length < 5}
            checked={values.s_a_pc_company.length === 5}
            onChange={(e) => onChange(e, 's_a_pc_company', [841, 842, 843, 844, 845])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_pc_company" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_pc_company: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={841}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={842}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={843}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={844}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={845}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="S&A Actual"
          extra={<Checkbox
            name="s_a_pc_actual"
            indeterminate={values.s_a_pc_actual.length > 0 && values.s_a_pc_actual.length < 5}
            checked={values.s_a_pc_actual.length === 5}
            onChange={(e) => onChange(e, 's_a_pc_actual', [846, 847, 848, 849, 850])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_pc_actual" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_pc_actual: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={846}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={847}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={848}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={849}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={850}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="S&A Pre-shipment"
          extra={<Checkbox
            name="s_a_pc_pre_shipment"
            indeterminate={values.s_a_pc_pre_shipment.length > 0 && values.s_a_pc_pre_shipment.length < 5}
            checked={values.s_a_pc_pre_shipment.length === 5}
            onChange={(e) => onChange(e, 's_a_pc_pre_shipment', [851, 852, 853, 854, 855])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_pc_pre_shipment" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_pc_pre_shipment: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={851}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={852}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={853}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={854}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={855}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>

        <br />
        <Card size="small" type="inner" title="Purchase Supplier Report"
          extra={<Checkbox
            name="s_a_pc_supplier_report"
            indeterminate={values.s_a_pc_supplier_report.length > 0 && values.s_a_pc_supplier_report.length < 4}
            checked={values.s_a_pc_supplier_report.length === 4}
            onChange={(e) => onChange(e, 's_a_pc_supplier_report', [856, 857, 858, 859])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_pc_supplier_report" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_pc_supplier_report: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={856}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={857}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={858}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={859}>Delete</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="Comapny Reports"
          extra={<Checkbox
            name="s_a_pc_company_report"
            indeterminate={values.s_a_pc_company_report.length > 0 && values.s_a_pc_company_report.length < 4}
            checked={values.s_a_pc_company_report.length === 4}
            onChange={(e) => onChange(e, 's_a_pc_company_report', [860, 861, 862, 863])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_pc_company_report" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_pc_company_report: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={860}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={861}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={862}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={863}>Delete</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="Actual Report"
          extra={<Checkbox
            name="s_a_pc_actual_report"
            indeterminate={values.s_a_pc_actual_report.length > 0 && values.s_a_pc_actual_report.length < 4}
            checked={values.s_a_pc_actual_report.length === 4}
            onChange={(e) => onChange(e, 's_a_pc_actual_report', [864, 865, 866, 867])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_pc_actual_report" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_pc_actual_report: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={864}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={865}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={866}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={867}>Delete</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="purchase Aging Report"
          extra={<Checkbox
            name="s_a_pc_aging_report"
            indeterminate={values.s_a_pc_aging_report.length > 0 && values.s_a_pc_aging_report.length < 4}
            checked={values.s_a_pc_aging_report.length === 4}
            onChange={(e) => onChange(e, 's_a_pc_aging_report', [868, 869, 870, 871])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_pc_aging_report" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_pc_aging_report: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={868}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={869}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={870}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={871}>Delete</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="pre-shipment report"
          extra={<Checkbox
            name="s_a_pc_shipment_report"
            indeterminate={values.s_a_pc_shipment_report.length > 0 && values.s_a_pc_shipment_report.length < 4}
            checked={values.s_a_pc_shipment_report.length === 4}
            onChange={(e) => onChange(e, 's_a_pc_shipment_report', [872, 873, 874, 875])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_pc_shipment_report" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_pc_shipment_report: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={872}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={873}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={874}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={875}>Delete</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>

      </Card>

      <br />
      <Card size="small" title='Sales Quality'>

        <Card size="small" type="inner" title="S&A Actual"
          extra={<Checkbox
            name="s_a_sales_actual"
            indeterminate={values.s_a_sales_actual.length > 0 && values.s_a_sales_actual.length < 5}
            checked={values.s_a_sales_actual.length === 5}
            onChange={(e) => onChange(e, 's_a_sales_actual', [876, 877, 878, 879, 880])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_sales_actual" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_sales_actual: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={876}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={877}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={878}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={879}>Delete</Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox value={880}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="Supplier Report"
          extra={<Checkbox
            name="s_a_sales_supplier_report"
            indeterminate={values.s_a_sales_supplier_report.length > 0 && values.s_a_sales_supplier_report.length < 4}
            checked={values.s_a_sales_supplier_report.length === 4}
            onChange={(e) => onChange(e, 's_a_sales_supplier_report', [881, 882, 883, 884])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_sales_supplier_report" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_sales_supplier_report: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={881}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={882}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={883}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={884}>Delete</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="Aging Report"
          extra={<Checkbox
            name="s_a_sales_aging_report"
            indeterminate={values.s_a_sales_aging_report.length > 0 && values.s_a_sales_aging_report.length < 4}
            checked={values.s_a_sales_aging_report.length === 4}
            onChange={(e) => onChange(e, 's_a_sales_aging_report', [885, 886, 887, 888])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="s_a_sales_aging_report" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, s_a_sales_aging_report: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={885}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={886}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={887}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={888}>Delete</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
      </Card>
    </Form>
  )
}