import { Card, Col, Form, Space, InputNumber, Button, FormInstance, Input, Radio, Row, Select, Tooltip } from 'antd';
import { PlusCircleOutlined, DeleteFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { FileUploadForm } from '../../../../common';
import { AnchorageService, BpCategoryGetDto, BPDropDownDto, BusinessPartnerService, BusinessPartnerTypeEnum, CurrencyService, PriceTypeEnum, TaxDropDownDto, TaxesService, TaxTypeReqDto, TaxTypes, VatIncludeExclude, VesselContractTypeEnum } from '@exportx/shared-models-and-services';
import { useIntl } from 'react-intl';
import { FormLayout } from 'antd/lib/form/Form';
import { ClassValidator } from '@exportx/ui-utils';
import DatePicker from '../../../../common/data-picker/date-picker';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface INewBargeAgreementProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}



const NewVesselAgreement = (props: INewBargeAgreementProps) => {
  const { formRef, layoutType, initialValues } = props;
  const { formatMessage: fm } = useIntl();
  const [vendorsData, setVendorsData] = useState<BPDropDownDto[]>([]);
  const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([]);
  const [currency, setCurrency] = useState<any[]>([false]);
  const [anchorages, setAnchorages] = useState([]);
  const [isVatSelected, setIsVatSelected] = useState([VatIncludeExclude.EXCLUDE])



  const businessPartnerService = new BusinessPartnerService();
  const currencyService = new CurrencyService();
  const taxesService = new TaxesService();
  const anchorageService = new AnchorageService();

  useEffect(() => {
    getAllVesselVendorsDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR));
    getCurrenciesData();
    getAllAnchorages();
    getAllActiveVatDropDown();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  // useEffect(() => {
  //   if (initialValues) {
  //     formRef.setFieldValue('filesData', initialValues?.filesData);
  //     formRef.setFieldsValue({ specPriceData: initialValues?.specPriceData });
  //     formRef.setFieldsValue(initialValues);
  //     let pcType = [];
  //     let vatInclude = []
  //     initialValues?.specPriceData.forEach(specPrice => {
  //       pcType.push(specPrice.bargingType);
  //       vatInclude.push(specPrice.vatInclOrExcl);
  //     })
  //     // setPriceType(pcType);
  //     // setIsVatSelected(vatInclude);
  //   }
  // }, [initialValues]);

  useEffect(() => {
    formRef.resetFields();
  }, [initialValues])

  const getAllActiveVatDropDown = () => {
    const req = new TaxTypeReqDto();
    req.taxType = TaxTypes.VAT;
    taxesService.getTaxesByCategory(req).then(res => {
      if (res.status) {
        setTaxDropDown(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getAllAnchorages = () => {
    anchorageService.getAllAnchorages().then(res => {
      if (res.status) {
        setAnchorages(res.data)
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getAllVesselVendorsDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
    businessPartnerService.getAllVesselVendorsDropDown(bpCategoryGetDto).then(res => {
      if (res.status) {
        if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.VENDOR)
          setVendorsData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const getCurrenciesData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrency(res.data);
      }
    }).catch(err => {
      console.log("error");
    })
  }

  const vatOnChangeHandler = (key: number, value: VatIncludeExclude) => {
    setIsVatSelected(prevState => {
      const newState = { ...prevState };
      newState[key] = value;
      return newState;
    })
  };

  const handleVatChange = (key: number) => {
    const vat = formRef.getFieldValue(['specPriceData', key, 'vatId'])
    if (vat) {
      const vatId = taxDropDown.filter(rec => rec.taxId === vat)[0];
      const price = Number(formRef.getFieldValue(['specPriceData', key, 'freightPrice']));
      const priceAfterVat = price ? price / ((vatId.taxPercentage / 100) + 1) : 0;
      formRef.setFieldValue(['specPriceData', key, 'priceAfterVat'], priceAfterVat?.toFixed(2))
    } else {
      const price = Number(formRef.getFieldValue(['specPriceData', key, 'freightPrice']));
      const priceAfterVat = price ? price : 0;
      formRef.setFieldValue(['specPriceData', key, 'priceAfterVat'], priceAfterVat?.toFixed(2));
    }
  }




  return (
    <div>
      <Card>
        <Form
          form={formRef}
          layout={layoutType}
          autoComplete="off"
          initialValues={initialValues}
          key={Date.now()}
        >
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 1 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.vesselAgreementForm.bargeVendorId', defaultMessage: "Vessel Vendor" })}
                name="vesselVendorId"
                rules={[{ required: true, message: fm({ id: "mdm.components.vesselAgreementForm.bargeVendorIdReq", defaultMessage: "Please Select Vendor" }) }]}
                style={{ marginLeft: "-34px" }}
              >
                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch >

                  {vendorsData.map(item => {
                    return <Option key={item.bpId} value={item.bpId}>{item.bpName}</Option>
                  })}</Select>

              </Form.Item>

            </Col>
            <Col xs={{ span: 14 }} md={{ span: 14 }} lg={{ span: 5, offset: 1 }}>
              <Form.Item
                name={"contractMode"}
                rules={[{ required: true, message: fm({ id: "mdm.components.vesselAgreementForm.contractModeReq", defaultMessage: "Please Select Contract Type" }) }]}
                label={fm({ id: "mdm.components.vesselAgreementForm.contractMode", defaultMessage: 'Contract Type' })}>
                <Radio.Group>
                  {Object.keys(VesselContractTypeEnum).map(TypeKey => {
                    return <Radio value={VesselContractTypeEnum[TypeKey]}>{VesselContractTypeEnum[TypeKey]}</Radio>
                  })}
                </Radio.Group>
              </Form.Item>

            </Col>
            <Col xs={{ span: 14 }} md={{ span: 14 }} lg={{ span: 5, offset: 1 }}>
              <Form.Item

                label={fm({ id: 'mdm.components.vesselAgreementForm.agreementDate', defaultMessage: "Agreement Date" })}
                name="agreementDate"
                rules={[{ required: true, message: fm({ id: "mdm.components.vesselAgreementForm.agreementDateReq", defaultMessage: "Please Select Agreement Date" }) }]}
              >
                <DatePicker style={{ width: "220px" }} format="YYYY-MM-DD" />
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 1 }}>

              <Form.Item
                label={fm({ id: 'mdm.components.vesselAgreementForm.currency', defaultMessage: "Currency" })}
                name="currency"
                rules={[{ required: true, message: fm({ id: "mdm.components.vesselAgreementForm.currencyReq", defaultMessage: "Please Select Currency" }) }]}
              >
                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder=" Select currency" >


                  {currency.map(item => {
                    return <Option value={item.currencyCode} key={item.currencyCode}>{item.currencyName}</Option>
                  })}

                </Select>
              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 1 }}></Col>


          </Row>

          <Form.List name="specPriceData">
            {(fields, { add, remove }) => (<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              {fields.map(({ key, name, ...restField }, index) => (
                <Row>
                  <Col
                    xs={{ span: 23 }}
                    sm={{ span: 23 }}
                    md={{ span: 23 }}
                    lg={{ span: 23 }}
                    xl={{ span: 23 }}>
                    <Card>
                      <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                          <Form.Item
                            label={fm({ id: `mdm.components.vesselAgreementForm.layCan`, defaultMessage: "Laycan" })}
                            name={[name, "layCan"]}
                            rules={[
                              {
                                required: true, message: fm({ id: `mdm.components.vesselAgreementForm.layCanReq`, defaultMessage: "Please select Laycan" })
                              }
                            ]}
                          >
                            <RangePicker />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item
                            name={[name, "vesselName"]}
                            label={fm({ id: `mdm.components.vesselAgreementForm.vesselName`, defaultMessage: "Vessel Name" })}

                          >
                            <Input placeholder={fm({ id: `mdm.components.vesselAgreementForm.vesselNameReq`, defaultMessage: " Please Enter Vessel" })} />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item
                            label={fm({ id: `mdm.components.vesselAgreementForm.quantity`, defaultMessage: "Quantity" })}
                            name={[name, "quantity"]}
                            rules={[
                              {
                                required: true, message: fm({ id: `mdm.components.vesselAgreementForm.quantityReq`, defaultMessage: "Please Enter Quantity" })
                              },
                              {
                                pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                                message: `Only Number & Decimals`
                              }

                            ]}
                          >
                            <InputNumber placeholder={fm({ id: `mdm.components.vesselAgreementForm.quantityReq`, defaultMessage: " Please Enter Quantity" })} style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item
                            label={fm({ id: `mdm.components.vesselAgreementForm.tolerance`, defaultMessage: "Tolerance %" })}
                            name={[name, "tolerance"]}
                            rules={[
                              {
                                pattern: /^(0\.5|[1-9](\.\d+)?|1[0-4](\.\d+)?|15)$/,
                                message: `Please enter a decimal number between 0.5 and 15`,
                              }
                            ]}
                          >
                            <InputNumber placeholder={fm({ id: `mdm.components.vesselAgreementForm.toleranceReq`, defaultMessage: " Please Enter Tolerance" })} style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item
                            name={[name, "priceType"]}
                            rules={[
                              {
                                required: true, message: fm({ id: `mdm.components.vesselAgreementForm.priceTypeReq`, defaultMessage: " Please Select Price Type" })
                              }
                            ]}
                            label={fm({ id: "mdm.components.vesselAgreementForm.priceType", defaultMessage: 'Price Type' })}>

                            <Radio.Group>
                              {Object.keys(PriceTypeEnum).map(priceTypeKey => {
                                return <Radio value={PriceTypeEnum[priceTypeKey]} >{PriceTypeEnum[priceTypeKey]}</Radio>
                              })}
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                          <Form.Item

                            label={fm({ id: 'mdm.components.vesselAgreementForm.freightPrice', defaultMessage: 'Freight Price' })}
                            name={[name, "freightPrice"]}
                            rules={[{
                              required: true, message: fm({ id: "mdm.components.vesselAgreementForm.freightPriceReq", defaultMessage: "Please Enter Price" }),

                            },
                            {
                              pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                              message: `Only Number & Decimals`
                            }]
                            }

                          >
                            <Input type='text' placeholder='Please Enter Freight Price' />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item
                            label={fm({ id: 'mdm.components.bargeAgreementForm.vatInclOrExcl', defaultMessage: 'VAT Applicability (PPN)' })}
                            name={[name, "vatInclOrExcl"]}
                            style={{ width: '320px' }}
                          >
                            <Radio.Group onChange={(e) => { vatOnChangeHandler(key, e.target.value) }}>
                              {Object.keys(VatIncludeExclude).map(vatKey => {
                                return <Radio value={VatIncludeExclude[vatKey]} onClick={() => vatOnChangeHandler(key, VatIncludeExclude[vatKey])} >{VatIncludeExclude[vatKey]}</Radio>
                              })}
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        {formRef.getFieldValue(['specPriceData', name, 'vatInclOrExcl']) === VatIncludeExclude.INCLUDE && (<><Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item name={[name, "vatId"]} label={"Vat to be Applied"} style={{ width: '100%' }}>
                            <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear
                              showSearch
                              placeholder="PLease Select"
                              onChange={(vatId) => {
                                handleVatChange(key)
                              }}
                            >
                              <Option key={0} value={null}>Select Vat</Option>
                              {taxDropDown.map((rec) => {
                                return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} (${rec.taxPercentage})`}</Option>
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                            <Form.Item name={[name, "priceAfterVat"]}
                              label={'Price after Vat'}
                            ><Input disabled={true} /></Form.Item></Col></>)}

                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item
                            label={"Load Port"}
                            name={[name, "loadPort"]}

                          >
                            <Select
                              filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                              allowClear showSearch placeholder={fm({ id: 'common.select', defaultMessage: "Please Select" })}
                              onChange={(value: string, option: any) => {
                                formRef.setFieldValue(['specPriceData', name, 'loadPortName'], option.name)
                              }}
                              >
                              <Option value={''}>Please Select</Option>
                              {
                                anchorages.map(rec => {
                                  return <Option name={rec.anchorageName} key={rec.anchorageId} value={rec.anchorageId} >{rec.anchorageName}</Option>
                                })
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: formRef.getFieldValue(['specPriceData', name, 'vatInclOrExcl']) === VatIncludeExclude.INCLUDE ? 0 : 1 }}>
                          <Form.Item

                            label={fm({ id: 'mdm.components.vesselAgreementForm.loadRate', defaultMessage: 'Load Rate' })}
                            name={[name, "loadRate"]}
                            rules={[{
                              required: false, message: fm({ id: "mdm.components.vesselAgreementForm.loadRate", defaultMessage: "Please Enter Price" }),

                            },
                            {
                              pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                              message: `Only Number & Decimals`
                            }]
                            }

                          >
                            <Input type='text' placeholder='Please Enter Load Rate' />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item
                            label={'Discharge Port'}
                            name={[name, "dischargePort"]}

                          >
                            <Select
                              filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                              allowClear showSearch placeholder={fm({ id: 'common.select', defaultMessage: "Please Select" })}
                              onChange={(value: string, option: any) => {
                                formRef.setFieldValue(['specPriceData', name, 'dischargePortName'], option.name)
                              }}
                              >
                              <Option value={''}>Please Select</Option>
                              {
                                anchorages.map(rec => {
                                  return <Option name={rec.anchorageName} key={rec.anchorageId} value={rec.anchorageId}>{rec.anchorageName}</Option>
                                })
                              }
                            </Select>
                          </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: formRef.getFieldValue(['specPriceData', name, 'vatInclOrExcl']) === VatIncludeExclude.INCLUDE ? 1 : 0 }}>
                          <Form.Item

                            label={fm({ id: 'mdm.components.vesselAgreementForm.dischargeRate', defaultMessage: 'Discharge Rate' })}
                            name={[name, "dischargeRate"]}
                            rules={[{
                              required: false, message: fm({ id: "mdm.components.vesselAgreementForm.dischargeRateReq", defaultMessage: "Please Enter Price" }),

                            },
                            {
                              pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                              message: `Only Number & Decimals`
                            }]
                            }

                          >
                            <Input type='text' placeholder={fm({ id: `mdm.components.vesselAgreementForm.dispatch`, defaultMessage: " Please Enter Discharge Rate" })} />
                          </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item

                            label={fm({ id: 'mdm.components.vesselAgreementForm.price', defaultMessage: 'Demurrage Rate' })}
                            name={[name, "demurrageRate"]}
                            rules={[{
                              required: false, message: fm({ id: "mdm.components.vesselAgreementForm.priceReq", defaultMessage: "Please Enter Price" }),

                            },
                            {
                              pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                              message: `Only Number & Decimals`
                            }]
                            }

                          >
                            <Input type='text' placeholder={fm({ id: `mdm.components.vesselAgreementForm.dispatch`, defaultMessage: " Please Enter Demurrage Rate" })} />
                          </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 1 }}>
                          <Form.Item

                            label={fm({ id: 'mdm.components.vesselAgreementForm.price', defaultMessage: 'Dispatch Rate' })}
                            name={[name, "dispatchRate"]}
                            rules={[{
                              required: false, message: fm({ id: "mdm.components.vesselAgreementForm.priceReq", defaultMessage: "Please Enter Price" }),

                            },
                            {
                              pattern: /^[^-\s\\a-zA-Z\[\]()*!@#$^&_\-+/%=`~{}:";'<>,.?|][Z0-9.\s]*$/,
                              message: `Only Number & Decimals`
                            }]
                            }

                          >
                            <InputNumber placeholder={fm({ id: `mdm.components.vesselAgreementForm.dispatch`, defaultMessage: " Please Enter Dispatch Rate" })} style={{ width: '100%' }} />
                          </Form.Item>

                        </Col>
                      </Row>


                    </Card>
                  </Col>
                  {/* {(fields?.length > 1 && returnDeleteIcon(Number(initialValues.qualityData?.[index]?.['quantityDelivered']), Number(initialValues.qualityData?.[index]?.['quantityAllocated']))) && <Col xs={{ span: 2 }} */}
                  {fields?.length > 1 && <Col xs={{ span: 2 }}
                    sm={{ span: 1 }}
                    md={{ span: 1 }}
                    lg={{ span: 1 }}
                    xl={{ span: 1 }}>
                    <span style={{
                      position: "absolute",
                      marginTop: "33px",
                      marginLeft: "25px",
                    }}>
                      <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                        <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                          remove(name);
                          console.log('qualityId', name['qualityId'])
                        }} />
                      </Tooltip>
                    </span>
                  </Col>
                  }
                </Row>
              ))}
              <Form.Item >
                <Button type="dashed" onClick={add}>
                  Add More
                </Button>
              </Form.Item>
            </Space>)}
          </Form.List>

        </Form>
        {props?.children}
      </Card>
    </div>
  )
}

export default NewVesselAgreement;