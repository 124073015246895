import React, { useEffect, useState } from 'react';
import { Card, Button, Drawer } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { PaymentmodesDto, PaymentmodesService } from '@exportx/shared-models-and-services';
import { AlertMessages, FilterableGrid, isPermissionExist } from '../../../../../common';
import PaymentModesForm from '../payment-modes-form/payment-modes-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// import './payment-modes.css';

/* eslint-disable-next-line */
export interface PaymentModesProps { }

export function PaymentModesGrid() {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate()
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [paymentmodeData, setPaymentmodeData] = useState<PaymentmodesDto[]>([]);
  const [selectedPaymentmodeData, setSelectedPaymentmodeData] = useState<any>(undefined);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(false);

  const paymentmodeDataService = new PaymentmodesService();

  useEffect(()=>{
     if(!isPermissionExist([55])){
       return navigate('/')
     }
  },[])

  useEffect(() => {
    getAllPaymentmodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  /**
   * 
   */
  const getAllPaymentmodes = () => {
    paymentmodeDataService.getAllPaymentmodes().then(res => {
      if (res.status) {
        setPaymentmodeData(res.data);
      } else {
        if (res.errorCode) {
          setPaymentmodeData([]);
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setPaymentmodeData([]);
      AlertMessages.getErrorMessage(err.message);
    })
  }
  /**
   * 
   * @param paymentmodeData 
   */
  const deletePaymentmode = (paymentmodeData: PaymentmodesDto) => {
    paymentmodeData.isActive = paymentmodeData.isActive ? false : true;
    paymentmodeDataService.activatedeActivatePaymentmode(paymentmodeData).then(res => {
      if (res.status) {
        getAllPaymentmodes();
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `PaymentMode ${paymentmodeData.isActive ? ' activated ' : ' deactivated '} SuccessFully` }));
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const updatePaymentmode = (paymentmodeData: PaymentmodesDto) => {
    paymentmodeDataService.updatePaymentmode(paymentmodeData).then(res => {
      console.log(res, "PPPPPPPPPPPPP")
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        closeDrawer()
        getAllPaymentmodes();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  //drawer related
  const closeDrawer = () => {
    setDrawerVisible(false);
    getAllPaymentmodes();
  }

  //TO open the form for updation
  const openFormWithData = (viewData: PaymentmodesDto, update: boolean) => {
    setIsUpdate(update)
    setDrawerVisible(true);
    setSelectedPaymentmodeData(viewData);
  }


  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Payment Mode',
      dataIndex: 'paymentMode',
      // responsive: ['lg'],
      sorter: (a, b) => a.paymentMode.localeCompare(b.paymentMode),
      sortDirections: ['descend', 'ascend']
    },
  ];


  return (
    <Card title='Payment Modes'
      className='default-card-class'
      extra={<>{isPermissionExist([54]) && (<Button
        onClick={() => {
          setIsNewRecord(true); setDrawerVisible(true);
          setSelectedPaymentmodeData(null);
        }}
      >
        {fm({ id: 'common.create', defaultMessage: 'Create' })} </Button>)}</>}>
      <FilterableGrid
        rowKey='paymentModeId'
        mainColumns={columnsSkelton}
        data={paymentmodeData}
        filterColumnsData={[
          {
            dataIndex: 'paymentMode',
            placeHolder: fm({
              id: 'pms.components.ClusterGrid.shippingTermsName',
              defaultMessage: 'Payment Mode'
            })
          }
        ]}
        otherTableProps={{ bordered: true }} activateOrDeactivate={deletePaymentmode} view={openFormWithData} />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Payment Mode' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} open={drawerVisible} closable={true}>
        <PaymentModesForm key={Date.now()}
          updatePaymentmode={updatePaymentmode}
          isUpdate={isUpdate}
          isNewRecord={isNewRecord}
          paymentmodeData={selectedPaymentmodeData}
          closeForm={closeDrawer} />
      </Drawer>
    </Card>
  );
}

export default PaymentModesGrid;
