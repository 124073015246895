export class TypicalModel {
    calorificBasis: string;
    calorificTypical: number;
    calorificRejection: number;
    calorificBonusCap: number;
    calorificPenaltyCap: number;
    calorificBasis1: string;
    calorificTypical1: number;
    calorificRejection1: number;
    calorificBonusCap1: number;
    calorificPenaltyCap1: number;
    calorificBasis2: string;
    calorificTypical2: number;
    calorificRejection2: number;
    calorificBonusCap2: number;
    calorificPenaltyCap2: number;

    totalMoistureBasis: string;
    totalMoistureTypical: number;
    totalMoistureRejection: number;

    inherentMoistureBasis: string;
    inherentMoistureTypical: number;
    inherentMoistureRejection: number;

    ashBasis: string;
    ashTypical: string;
    ashRejection: string;
    ashPenalty: string;
    ashRejectionPenalty: string;

    volatileMatterBasis: string;
    volatileMatterTypical: number;
    volatileMatterRejection: number;

    fixedCarbonBasis: string;
    fixedCarbonTypical: number;
    fixedCarbonRejection: number;

    fuelRatioBasis: string;
    fuelRatioTypical: number;
    fuelRatioRejection: number;

    totalSulphurBasis: string;
    totalSulphurTypical: number;
    totalSulphurRejection: number;
    totalSulphurPenalty: number;
    totalSulphurRejectionPenalty: number;

    hgiUnitsTypical: string;
    hgiUnitsRejection: string;
}