import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    FileSyncOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { ContractStatusEnum } from '@exportx/shared-models-and-services';
interface IProps {
    status: ContractStatusEnum | string;
}
export const StatusTag = (props: IProps) => {
    const { status } = props;

    const getData = (status: ContractStatusEnum | string) => {
        switch (status) {
            case ContractStatusEnum.DRAFT:
                return <Tag icon={<MinusCircleOutlined />} color="default" style={{ fontSize: '10px', borderRadius: '10px', borderColor: '#64748B' }}>
                    {ContractStatusEnum.DRAFT}
                </Tag>
                break;
            case ContractStatusEnum.ACTIVE:
                return <Tag icon={<CheckCircleOutlined />} color="success" style={{ fontSize: '10px', borderRadius: '10px', borderColor: '#0EAD49', backgroundColor: '#E9FCF0', color: '#0EAD49'}}>
                    {ContractStatusEnum.ACTIVE}
                </Tag>
                break;

            case ContractStatusEnum.CANCELLED:
                return <Tag icon={<CloseCircleOutlined />} color="error" style={{ fontSize: '10px', borderRadius: '10px', borderColor: '#FCB1B0' }}>
                    {ContractStatusEnum.CANCELLED}
                </Tag>
                break;


            case ContractStatusEnum.CLOSED:
                return <Tag icon={<ClockCircleOutlined />} color="default" style={{ fontSize: '10px', borderRadius: '10px', borderColor: '#64748B' }}>
                    {ContractStatusEnum.CLOSED}
                </Tag>
                break;


            case ContractStatusEnum.PENDING_APPROVAL:
                return <Tag icon={<FileSyncOutlined />} color="processing" style={{ fontSize: '10px', borderRadius: '10px', borderColor: '#00A0FF', backgroundColor: '#E5FCFF', color: '#00A0FF' }}>
                    {ContractStatusEnum.PENDING_APPROVAL}
                </Tag>
                break;
            case ContractStatusEnum.PENDING_AUDIT_APPROVAL:
                return <Tag icon={<SyncOutlined />} color="processing" style={{ fontSize: '10px', borderRadius: '10px', borderColor: '#74C4FF' }}>
                    {ContractStatusEnum.PENDING_AUDIT_APPROVAL}
                </Tag>
                break;
            case ContractStatusEnum.PENDING_MANGEMENT_APPROVAL:
                return <Tag icon={<SyncOutlined />} color="processing" style={{ fontSize: '10px', borderRadius: '10px', borderColor: '#74C4FF' }}>
                    {ContractStatusEnum.PENDING_MANGEMENT_APPROVAL}
                </Tag>
                break;

            default:
                return <Tag icon={<MinusCircleOutlined />} color="default" style={{ fontSize: '10px', borderRadius: '10px', borderColor: '#64748B' }}>
                    stop
                </Tag>
                break;
        }
    }
    return (
        <>{
            getData(status)
        }</>
    )
}

export default StatusTag