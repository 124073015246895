import { ContractTypesEnum, PurchaseContractDto, PurchaseShippingTermsDto } from '@exportx/shared-models-and-services';
import { Card, Col, Divider, List, Row, Tooltip } from 'antd';

interface IShippingTermsViewProps {
    shippingTerms: PurchaseShippingTermsDto;
    snapShotData: PurchaseContractDto[];
    contractType: ContractTypesEnum;
}

export const ShippingTermsView = (props: IShippingTermsViewProps) => {
    const { shippingTerms, contractType, snapShotData } = props;
    const valiDateWithSnapShotData = (data: any[], index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = data?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM) {
                    if (valueAtGivenKey !== '-') {
                        return <><span>{valueAtGivenKey}</span>&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    } else {
                        return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>{`=>`}&nbsp;<Tooltip placement="top" title={snapShotData?.[0]?.contractDetails?.contractNo?.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    }
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }
    return (
        <Row >
            {shippingTerms?.shippingTerms && <Col xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}>
                <Card title={<span style={{ color: 'black' }}>{' Shipping Terms'}</span>} bordered={false} style={{ textAlign: 'left', backgroundColor: '#F7F9FC', height: '300px', overflowY: 'auto'}} headStyle={{ border: 0 }} bodyStyle={{paddingTop: '2px'}}>
                <Divider dashed style={{marginTop: 0, marginBottom: '10px'}}></Divider>
                <List
                        bordered={false}
                        dataSource={shippingTerms?.shippingTerms}
                        renderItem={(item, index) => (
                            <List.Item key={index} style={{border: 'none'}}>
                                <div><span>{index + 1}</span><span>.</span>&nbsp;{valiDateWithSnapShotData(snapShotData?.[0]?.shippingTerms.shippingTerms, index, 'terms', item.terms)}</div>
                            </List.Item>
                        )}
                    />
                </Card>
            </Col>}
        </Row>
    )
}

export default ShippingTermsView;