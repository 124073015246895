import { BpCategoryGetDto, BusinessPartnerService, BusinessPartnerTypeEnum, ContractIdRequest, PurchaseContractService, ShippingTermsDropDownDto, ShippingTermsService } from '@exportx/shared-models-and-services';
import { Form, Input, Select, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../common';
import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
export const PurchaseGridForm = (props) => {
    const [dummyStateToRefresh, setDummyStateToRefresh] = useState(0);
    const { formatMessage: fm } = useIntl();
    const [qualityData, setQualityData] = useState({});
    const { authContext } = useAuthState();
    const { coalPurchaseData, form, currency, add, remove, selectedCurrency, exchangeRate } = props;
    const [vendorsDropData, setVendorsDropData] = useState<any[]>([]);
    const [purchaseContractsDropData, setPurchaseContractsDropData] = useState<any[]>([]);
    const [shippingTerms, setShippingTerms] = useState<ShippingTermsDropDownDto[]>([]);

    const purchaseContractService = new PurchaseContractService();
    const shippingTermsService = new ShippingTermsService();

    useEffect(() => {
        const getVendorDropData = () => {
            const bpService = new BusinessPartnerService();
            const req = new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)
            bpService.getAllBusinessPartnersForDropDown(req).then((res) => {
                if (res.status) {
                    setVendorsDropData(res.data)
                } else {
                    setVendorsDropData([])
                }
            }).catch((err) => {
                setVendorsDropData([])
            })
        }
        getVendorDropData();
        getPurchaseContractsDropData();
        getAllShippingTerms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // useEffect(() => {
    //     let fieldName = 0;
    //     for (const coalPurchase of coalPurchaseData) {
    //         const buyer = form.getFieldValue(['coalPurchaseData', fieldName, 'buyer']);
    //         const salesContract = form.getFieldValue(['coalPurchaseData', fieldName, 'purchaseContract'])
    //         getPurchaseContractsDropData(buyer);
    //         getQualityDetailsByPcId(salesContract ,authContext.defaultPlant)
    //         fieldName += 1;
    //     }
    // }, [coalPurchaseData])

    const getAllShippingTerms = () => {
        shippingTermsService.getAllShippingTermsDropDown().then(res => {
            if (res.status) {
                setShippingTerms(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }

    const getPurchaseContractsDropData = (bpId?: string) => {
        purchaseContractService.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant, bpId }).then((res) => {
            if (res.status) {

                setPurchaseContractsDropData(preVQualityData => {
                    return {
                        ...preVQualityData,
                        [bpId]: res.data
                    }
                })
            } else {
                setPurchaseContractsDropData(preVQualityData => {
                    return {
                        ...preVQualityData,
                        [bpId]: []
                    }
                })
            }
        }).catch((err) => {
            setPurchaseContractsDropData(preVQualityData => {
                return {
                    ...preVQualityData,
                    [bpId]: []
                }
            })
        })
    }

    const getQualityDetailsByPcId = (contractId: string, plantCode: string) => {
        const req = { ...new ContractIdRequest(contractId), plantCode };
        purchaseContractService.getQualityDetailsByPcId(req).then(res => {
            if (res.status) {
                setQualityData(preVQualityData => {
                    return {
                        ...preVQualityData,
                        [contractId]: res.data
                    }
                })
            } else {
                setQualityData(preVQualityData => {
                    return {
                        ...preVQualityData,
                        [contractId]: []
                    }
                })

            }
        }).catch(err => {
            setQualityData(preVQualityData => {
                return {
                    ...preVQualityData,
                    [contractId]: []
                }
            })
        })
    }
    const purchaseContractHandler = (contractId: string) => {
        getQualityDetailsByPcId(contractId, authContext.defaultPlant);
        setDummyStateToRefresh(c => c + 1);
    }

    const buyerOnChangeHandler = (value: string) => {
        getPurchaseContractsDropData(value)
    }
    const isContractSelected = (name) => {
        const a = form.getFieldValue(['coalPurchaseData', name, 'purchaseContract']);
        return a ? true : false;
    }
    const getTotal = (name) => {
        const quantity = form.getFieldValue(['coalPurchaseData', name, 'quantity']) ? form.getFieldValue(['coalPurchaseData', name, 'quantity']) : 0;
        const pricePMT = form.getFieldValue(['coalPurchaseData', name, 'pricePMT']) ? form.getFieldValue(['coalPurchaseData', name, 'pricePMT']) : 0;
        const presentCurrency = form.getFieldValue(['coalPurchaseData', name, 'currency']) ? form.getFieldValue(['coalPurchaseData', name, 'currency']) : 0;
        const total = quantity * pricePMT;
        form.setFieldValue(['coalPurchaseData', name, 'total'], total);
        return total;
    }
    return (
        <>
            {
                React.Children.toArray(coalPurchaseData?.map((field, index) => (
                    <tr>
                        <td className='table-form'>
                            <Form.Item
                                key={field.key}
                                name={[field.name, "buyer"]} rules={[{ required: true, message: "Select Buyer" }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                // onChange={buyerOnChangeHandler}
                                >
                                    {vendorsDropData.map((el) => {
                                        return <Option value={el.bpId} >{el.bpName}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item
                                key={field.key}
                                name={[field.name, "purchaseContract"]} rules={[{ required: true, message: "Select PurchaseContract" }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={purchaseContractHandler}
                                >
                                    {purchaseContractsDropData[form.getFieldValue(['coalPurchaseData', field.name, 'buyer'])]?.map((el) => {
                                        return <Option value={el.pcId}>{el.contractAndSupplier}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            {
                                isContractSelected(field.name) ? <Form.Item

                                    name={[field.name, "qualityId"]} rules={[{ required: true, message: "Enter Quality" }]}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={() => setDummyStateToRefresh(c => c + 1)}
                                    >
                                        {qualityData[form.getFieldValue(['coalPurchaseData', field.name, 'purchaseContract'])]?.map((el) => {
                                            return <Option value={el.qualityId}>{el.quality}</Option>
                                        })
                                        }
                                    </Select>
                                </Form.Item> : <Form.Item rules={[{ required: true, message: "Enter Quality" }]}
                                    name={[field.name, "qualityInput"]}><Input type='number' />
                                </Form.Item>
                            }

                        </td>
                        <td className='table-form'>
                            {
                                isContractSelected(field.name) ? <Form.Item

                                    name={[field.name, "purchaseTypeId"]} rules={[{ required: true, message: "Select PurchaseType" }]}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={() => setDummyStateToRefresh(c => c + 1)}
                                    >
                                        {qualityData[form.getFieldValue(['coalPurchaseData', field.name, 'purchaseContract'])]?.filter(rec => rec.qualityId === form.getFieldValue(['coalPurchaseData', field.name, 'qualityId']))[0]?.purchaseTypeData?.map((el) => {
                                            return <Option value={el.typeId}>{el.purchaseType}</Option>
                                        })
                                        }
                                    </Select>
                                </Form.Item> : <Form.Item rules={[{ required: true, message: "Select PurchaseType" }]}
                                    name={[field.name, "purchaseTypeInput"]}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {shippingTerms?.map((el) => {
                                            return <Option value={el.shiptermId}>{el.shipmenttermName}</Option>
                                        })
                                        }
                                    </Select>
                                </Form.Item>
                            }
                        </td>
                        <td> Per MT</td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Enter Quantity" }]}
                                name={[field.name, "quantity"]}><Input type='number' onChange={() => setDummyStateToRefresh(c => c + 1)} />
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Enter PricePMT" }]}
                                name={[field.name, "pricePMT"]}><Input type='number' onChange={() => setDummyStateToRefresh(c => c + 1)} />
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Select Currency" }]} name={[field.name, "currency"]} style={{ width: "80%" }}>
                                <Select
                                    className="w-100"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {currency?.map((c) => {
                                        return <Option value={c.currencyCode}>{c.currencyName}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='text-right'>
                            {getTotal(field.name)}
                            <Form.Item name={[field.name, "total"]} style={{ display: 'none' }}>
                                <Input hidden />
                            </Form.Item>
                        </td>
                        <td className='text-center'>
                            {(coalPurchaseData.length - 1 === index) && <span>
                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                    <PlusCircleOutlined style={{ fontSize: '21px' }} onClick={() => add()} />
                                </Tooltip>
                            </span>}
                            {index !== 0 && <span>
                                <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                    <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                                        remove(field.name);
                                    }} />
                                </Tooltip>
                            </span>}
                        </td>
                    </tr>
                )))
            }
            <tr>
                <td colSpan={5}></td>
                <td className='text-right' style={{ fontWeight: 'bold' }}>{form.getFieldValue(['coalPurchaseData'])?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)}</td>
                <td colSpan={2}></td>
                <td className='text-right' style={{ fontWeight: 'bold' }} >{form.getFieldValue(['coalPurchaseData'])?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)}</td>
                <td></td>
            </tr>
        </>
    )
}

export default PurchaseGridForm