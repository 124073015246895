import moment from "moment";
import React from "react";


export const ApprovalTracking = ({ history = [] }) => {
    const approvalDetails = history?.filter((e) => e.approvalLevel && e.approvalLevel !== null);
    return (
        <>
            {approvalDetails?.length > 0 &&
                <table className="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Approved By</th>
                            <th>Approved Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {approvalDetails?.map((log) => (
                            <tr key={log.workLogId}>
                                <td>{log.approvalLevel}</td>
                                <td>{log.updatedUser}</td>
                                <td>{moment(log.updatedAt).format('DD-MM-YYYY hh:mm A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </>
    )
}