
import { UploadOutlined } from '@ant-design/icons';
import { GenderEnum, GetAllUsersDto, IdentityTypeEnum, UsersCreateDto } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Form, Input, Row, Select, Switch, Upload } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuthState } from '../../../common';
import { getBase64 } from '@exportx/ui-utils';
const { Option } = Select;


interface IUserFormProps {
    submitHandler?: (req: UsersCreateDto) => void;
    initialValues?: GetAllUsersDto;
    unitsData: any;
    selectedClients: number;
}

export const UserForm = (props: IUserFormProps) => {
    const [formRef] = Form.useForm();
    const { submitHandler, selectedClients, initialValues, unitsData } = props;
    const [fileList, setFileList] = useState<any>()
    const { authContext } = useAuthState();
    const { formatMessage: fm } = useIntl();
    const [resetPswd, setResetPswd] = useState(false)

    console.log('initialValues', initialValues)

    const onSubmit = async () => {
        formRef.validateFields().then(async (values: UsersCreateDto) => {
            const f = formRef.getFieldValue('filesData');
            let filesData: any = [];
            if (f) {
                const base = await getBase64(f.file.originFileObj);
                filesData = [{
                    ...f.file,
                    base64Url: base,
                    fileDescription: 'User Profile'
                }];
            }
            const req = new UsersCreateDto(values.firstName, values.middleName, values.lastName, values.mobileNo, values.unitId, selectedClients, values.gender, values.identityType, values.identityNo, values.externalRefNo, values.userName, values.email, values.password, values.salt, authContext.user.userName, filesData?.length ? filesData : [], values.userId, values.authenticationId, values.versionFlag);
            console.log('req', req)
            submitHandler(req);
        }).catch(err => {
            console.log(err.message, 'error msg')
        })
    };

    const allowClear = () => {
        formRef.resetFields();
    };


    return (
        <div>

            <Form layout='vertical'
                form={formRef} initialValues={initialValues} autoComplete='off'>
                <Card title='Personal Details' >
                    <Row>
                        <Form.Item name='userId' hidden={true}></Form.Item>
                        <Form.Item name='authenticationId' hidden={true}></Form.Item>
                        <Form.Item name='versionFlag' hidden={true}></Form.Item>
                        <Form.Item name='clientId' hidden={true}></Form.Item>

                        <Col xs={24} sm={24} md={5} lg={7} xl={5} xxl={7}>
                            <Form.Item
                                name='firstName'
                                label={fm({ id: 'user.form.firstName', defaultMessage: 'First Name' })}
                                rules={[{ required: true, message: fm({ id: 'user.form.firstNameRequired', defaultMessage: 'First name is required' }) },
                                    // { pattern: new RegExp(/^[A-Za-z]+$/),
                                    //  message: fm({ id:'user.form.firstNameLettersOnly', defaultMessage: 'Fisrt name contains letters only' }) },
                                ]}>
                                <Input placeholder={fm({ id: 'user.form.firstName', defaultMessage: 'First Name' })} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7} offset={1}>
                            <Form.Item
                                name='middleName'
                                label={fm({ id: 'user.form.middleName', defaultMessage: 'Middle Name' })}
                                rules={[{ required: false, message: fm({ id: 'user.form.middleNameRequired', defaultMessage: 'Middle name is required' }) },
                                    // { pattern: new RegExp(/^[A-Za-z]+$/),
                                    //  message: fm({ id:'user.form.middleNameLettersOnly', defaultMessage: 'Middle name contains letters only' }) },
                                ]}>
                                <Input placeholder={fm({ id: 'user.form.middleName', defaultMessage: 'Middle Name' })} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7} offset={1}>
                            <Form.Item
                                name='lastName'
                                label={fm({ id: 'user.form.lastName', defaultMessage: 'Last Name' })}
                                rules={[{ required: true, message: fm({ id: 'user.form.lastNameRequired', defaultMessage: 'Laast name is required' }) },
                                    // { pattern: new RegExp(/^[A-Za-z]+$/),
                                    //  message: fm({ id:'user.form.lastNameLettersOnly', defaultMessage: 'last name contains letters only' }) },
                                ]}>
                                <Input placeholder={fm({ id: 'user.form.lastName', defaultMessage: 'Last Name' })} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7}>
                            <Form.Item
                                name='mobileNo'
                                label={fm({ id: 'user.common.mobileNo', defaultMessage: 'Mobile Number' })}
                                rules={[{ required: true, message: fm({ id: 'user.form.mobileNoRequired', defaultMessage: 'Mobile number is required' }) },]}>
                                <Input placeholder={fm({ id: 'user.common.mobileNo', defaultMessage: 'Mobile Number' })} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7} offset={1}>
                            <Form.Item
                                name='gender'
                                label={fm({ id: 'user.common.gender', defaultMessage: 'Gender' })}
                                rules={[{ required: true, message: fm({ id: 'user.form.genderRequired', defaultMessage: 'Gender is required' }) },
                                ]}>
                                <Select placeholder='Gender' allowClear showSearch>
                                    {Object.keys(GenderEnum).map((rec) => {
                                        return <Option value={GenderEnum[rec]}  >{GenderEnum[rec]}</Option>
                                    })}     </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <br />

                    {/*  <Row>
                        

                        

                        <Col xs={24} md={24} lg={7} xl={5} xxl={24} offset={1}>
                            <Form.Item
                                name='identityType'
                                label={fm({ id:'user.form.identityType', defaultMessage: 'Identity Type' })}
                                rules={[{ required: false, message: fm({ id:'user.form.identityTypeRequired', defaultMessage: 'Identity type is required' }) },
                                ]}>
                                <Select placeholder='Select Identity Type' allowClear showSearch>
                                    {Object.keys(IdentityTypeEnum).map((rec) => {
                                        return <Option value={IdentityTypeEnum[rec]}  >{IdentityTypeEnum[rec]}</Option>
                                    })}     </Select>
                            </Form.Item>
                        </Col> */}

                    {/* <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7} offset={1}>
                            <Form.Item
                                name='identityNo'
                                label={fm({ id:'user.form.identityNo', defaultMessage: 'Identity Number' })}
                                rules={[{ required: false, message: fm({ id:'user.form.identityNoRequired', defaultMessage: 'Identity Number is required' }) },]}>
                                <Input placeholder={fm({ id:'user.form.identityNo', defaultMessage: 'Identity Number' })} />
                            </Form.Item>
                        </Col> 
                    </Row>*/}
                    {/*  <Row>
                        <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7}  >
                            <Form.Item
                                name='externalRefNo'
                                label={fm({ id:'user.form.externalRefNo', defaultMessage: 'External Ref No' })}
                                rules={[{ required: false, message: fm({ id:'user.form.unitIdRequired', defaultMessage: 'External Ref No is required' }) },
                                ]}>
                                <Input placeholder={fm({ id:'user.common.externalRefNo', defaultMessage: 'External Ref No' })} />
                            </Form.Item>
                        </Col> 

                        <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7} offset={1}>

                        </Col>

                        <Col xs={24} md={24} lg={7} xl={5} xxl={24} offset={1}>

                        </Col>

                        <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7} offset={1}>

                        </Col>
                    </Row>*/}
                </Card>

                <br />
                <Card title='Authentication'>

                    <Row>
                        <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7} offset={1} >
                            <Form.Item
                                name='unitId'
                                label={fm({ id: 'user.form.unitId', defaultMessage: 'Plant Code' })}
                                rules={[{ required: true, message: fm({ id: 'user.form.unitIdRequired', defaultMessage: 'Unit id is required' }) },
                                ]}>
                                <Select style={{ width: '100%' }} placeholder=' Select Unit' disabled={initialValues ? true : false}>
                                    {unitsData.map((rec: any) => {
                                        return <Option value={rec.unitId}>{rec.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={5} lg={7} xl={5} xxl={7} offset={1}>
                            <Form.Item
                                name='userName'
                                label={fm({ id: 'user.form.userName', defaultMessage: 'User Name' })}
                                rules={[{ required: true, message: fm({ id: 'user.form.userNameRequired', defaultMessage: 'User Name id required' }) },]}>
                                <Input placeholder={fm({ id: 'user.form.userName', defaultMessage: 'User Name' })} disabled={initialValues ? true : false}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={5} lg={7} xl={5} xxl={7} offset={1}>
                            <Form.Item
                                name="email"
                                label={fm({ id: 'user.form.email', defaultMessage: 'Email' })}
                                rules={[{ type: 'email', message: fm({ id: 'user.form.emailInvalid', defaultMessage: 'Email is invalid' }) },
                                { required: true, message: fm({ id: 'user.form.emailRequired', defaultMessage: 'Email is required' }) },]}
                            >
                                <Input placeholder={fm({ id: 'user.form.email', defaultMessage: 'Email' })} disabled={initialValues ? true : false}/>
                            </Form.Item>
                        </Col>
                        {initialValues &&
                        <Col xs={24} sm={24} md={5} lg={7} xl={5} xxl={7} offset={1}>
                            <Form.Item name={"resetPswd"} label={fm({ id: `Reset Paswword`, defaultMessage: "Reset Password" })}
                                valuePropName="checked" initialValue={resetPswd}>
                                <Switch onChange={(checked) => { setResetPswd(checked) }} />
                            </Form.Item>
                        </Col>
                        }
                        {(resetPswd || !initialValues) &&
                            <>
                                <Col xs={24} sm={24} md={5} lg={7} xl={5} xxl={7} offset={1}>
                                    <Form.Item
                                        name="password"
                                        label={fm({ id: 'user.form.password', defaultMessage: 'Password' })}
                                        hasFeedback
                                        rules={[
                                            { required: resetPswd || !initialValues, message: fm({ id: 'user.form.passwordRequired', defaultMessage: 'Password is required' }) },
                                        ]}

                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={5} lg={7} xl={5} xxl={7} offset={1}>
                                    <Form.Item
                                        name="confirmPassword"
                                        label={fm({ id: 'user.form.confirmPassword', defaultMessage: 'Confirm Password' })}
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            { required: resetPswd || !initialValues, message: fm({ id: 'user.form.confirmPasswordRequired', defaultMessage: 'confirm password is required' }) },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(fm({ id: 'user.form.passwordNotMatch', defaultMessage: 'Entered password doesnot match' })));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                    </Row>
                    <br></br>
                    <Row>
                        <Col xs={24} sm={24} md={7} lg={7} xl={5} xxl={7} >
                            <Form.Item name={'filesData'}>
                                <Upload

                                    multiple={false}
                                    maxCount={1}
                                    showUploadList={true}
                                    customRequest={(data) => setFileList([data.file])}
                                    onRemove={() => setFileList([])}
                                    fileList={fileList}
                                >
                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>

                            </Form.Item>

                        </Col>
                    </Row>
                </Card>


                <Row  >
                    <Col xs={24} sm={24} md={7} lg={7} xl={2} xxl={7} offset={18}>
                        <Button onClick={allowClear} type='primary' danger>{fm({ id: 'user.form.clear', defaultMessage: 'Clear' })}</Button>
                    </Col>
                    <Col xs={24} sm={24} md={7} lg={7} xl={3} xxl={7} >
                        <Button onClick={onSubmit} type='primary'>{fm({ id: "common.submitButton", defaultMessage: 'Submit' })}</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}