import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { AdvanceEnumType, AdvanceTypeReqDto, CommercialCoalCostingService, PerformaAdvanceDataDto } from '@exportx/shared-models-and-services';
import { getLocalFormat, getNumberFromLocalString } from '@exportx/ui-utils';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../../common';

interface AdvanceIProps {
    formRef: FormInstance<any>;
    vendorId: string;
    contractId: string;
    totalInvoice?: number;
    performaAdvance?: PerformaAdvanceDataDto[];
    setAdjustedData: React.Dispatch<React.SetStateAction<any[]>>;
    adjustedData: any[];
    setInvoiceContracts?: React.Dispatch<React.SetStateAction<PerformaAdvanceDataDto[]>>;
    invoiceContracts?: PerformaAdvanceDataDto[];
    bargeSelectionRowKeys?: any[];
}

export const CmAdvanceAdjustments = (props: AdvanceIProps) => {
    const { formRef, vendorId, contractId, totalInvoice, performaAdvance, setAdjustedData, adjustedData, setInvoiceContracts, invoiceContracts, bargeSelectionRowKeys } = props;
    const { Option } = Select;
    const cmService = new CommercialCoalCostingService();
    const { authContext } = useAuthState();
    const [disableAdjust, setDisableAdjust] = useState<boolean>(false);
    const [disableEditIcon, setDisableEditIcon] = useState<boolean>(false);


    useEffect(() => {
        if (performaAdvance.length !== 0) {
            formRef.setFieldValue('advanceType', AdvanceEnumType.Against_Performa_Invoice);
            formRef.setFieldValue('invoiceContractId', performaAdvance[0]?.contractId);
            formRef.setFieldValue('advanceBalance', Number(performaAdvance[0]?.transactionTotalAmount) ? Number(Number(performaAdvance[0]?.transactionTotalAmount)?.toFixed(2)) : 0);
            formRef.setFieldValue('advanceToAdjust', Number(performaAdvance[0]?.transactionTotalAmount) ? Number(Number(performaAdvance[0]?.transactionTotalAmount)?.toFixed(2)) : 0);
            setInvoiceContracts(performaAdvance);
            if (!adjustedData.length) {
                addAdvanceAdjustmentAmount();
            };

        }
        //totalInvoice, if need totalInvoice Value Calling please Add into Dependency
    }, [performaAdvance]);



    const adjustOnChangeHandler = (e) => {
        const advanceBalance = formRef.getFieldValue('advanceBalance');
        if (Number(advanceBalance) < Number(e.target.value)) {
            formRef.setFieldValue('advanceToAdjust', undefined);
            // setDisableAdjust(true);
            return AlertMessages.getInfoMessage('Do not Exceed Advance Adjust Amount More Than Advance Balance');
        } else {
            // setDisableAdjust(false);
        }
    };


    const addAdvanceAdjustmentAmount = (showAlert?: boolean) => {
        setDisableEditIcon(false)
        // no need totalInvoice validation 
        // if (totalInvoice) {
        formRef.validateFields().then(values => {
            if (values.advanceBalance) {
                const totalAmountOfAdjustments = adjustedData?.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0) + Number(values.advanceToAdjust);
                // if (totalAmountOfAdjustments > Number(totalInvoice)) {
                //     setDisableAdjust(true);
                //     if (showAlert) {
                //         AlertMessages.getErrorMessage('Do not Exceed Advance Adjust Amount More Than Costing Amount');
                //     }
                //     return
                // } else {
                //     setDisableAdjust(false);
                // }
                const costingNo = invoiceContracts?.find(rec => rec.contractId === values.invoiceContractId)?.contractNo;
                const storeValues = [...adjustedData];
                const findDuplicateIndex = adjustedData?.findIndex(rec => rec.adjustedCostingId === values.invoiceContractId);
                if (findDuplicateIndex !== -1) {
                    values.advanceToAdjust = Number(adjustedData[findDuplicateIndex]?.totalAdjustedAmount) + Number(values.advanceToAdjust);
                    storeValues.splice(findDuplicateIndex, 1);
                }
                storeValues.push({
                    advanceType: values.advanceType,
                    adjustAmount: Number(values.advanceBalance),
                    adjustedCostingNo: costingNo,
                    totalAdjustedAmount: Number(values.advanceToAdjust),
                    adjustedCostingId: values.invoiceContractId,
                    userName: authContext.user.userName,
                    plantCode: authContext.defaultPlant,
                    id: values.advanceAdjustmentId,
                });
                setAdjustedData(storeValues);
                formRef.resetFields(['advanceType', 'invoiceContractId', 'advanceBalance', 'advanceToAdjust']);
                // formRef.setFieldValue("costingInvoiceTotal", Number(totalInvoice.toFixed(2)).toLocaleString());
                formRef.setFieldValue("totalAdjustedValue", Number(totalAmountOfAdjustments.toFixed(2)).toLocaleString());
                const remaining = getNumberFromLocalString(formRef.getFieldValue('costingInvoiceTotal')) - Number(totalAmountOfAdjustments)
                if(remaining) {
                    formRef.setFieldValue("remainingBalance", Number(remaining.toFixed(2)).toLocaleString());
                }
            }

        });
        // }

    };
    // }

    const columns: ColumnsType<any> = [
        {
            title: 'Advance Type',
            dataIndex: 'advanceType',
            align: 'center'
        },
        {
            title: 'Advance Balance',
            dataIndex: 'adjustAmount',
            align: 'center',
            render: (value) => {
                return <>
                    {getLocalFormat(Number(value), authContext.defaultPlantCurrency)}
                </>
            }
        },
        {
            title: 'Costing No',
            dataIndex: 'adjustedCostingNo',
            align: 'center'
        },
        {
            title: 'Adjusted Amount',
            dataIndex: 'totalAdjustedAmount',
            align: 'center',
            render: (value) => {
                return <>
                    {getLocalFormat(Number(value), authContext.defaultPlantCurrency)}
                </>
            }
        },
        {
            title: 'Action',
            render: (value, record, index) => {
                return <>
                    {record.advanceType !== AdvanceEnumType.Against_Lc_Balance &&
                        <><EditFilled
                            hidden={disableEditIcon}
                            onClick={() => {
                                advanceTypeOnchangeHandler(record.advanceType, true);
                                formRef.setFieldValue('advanceType', record.advanceType);
                                formRef.setFieldValue('advanceBalance', record.adjustAmount);
                                formRef.setFieldValue('advanceToAdjust', record.totalAdjustedAmount);
                                formRef.setFieldValue('invoiceContractId', record.adjustedCostingId);
                                formRef.setFieldValue('advanceAdjustmentId', record.id);
                                adjustedData.splice(index, 1);
                                setAdjustedData([...adjustedData]);
                            }}
                            style={{ color: '#1890ff', fontSize: '20px' }}
                        ></EditFilled>
                            <DeleteFilled
                                onClick={() => {
                                    formRef.setFieldValue('totalAdjustedValue', Math.abs(Number(record.totalAdjustedAmount) - getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue'))).toLocaleString());
                                    const remainingValue = getNumberFromLocalString(formRef.getFieldValue('costingInvoiceTotal')) - getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue'));
                                    formRef.setFieldValue("remainingBalance", remainingValue.toLocaleString());
                                    adjustedData.splice(index, 1);
                                    setAdjustedData([...adjustedData]);
                                }}
                                style={{ color: '#f70404', fontSize: '21px', float: 'right' }}
                            ></DeleteFilled>
                        </>

                    }


                </>
            }
        },
        {
            dataIndex: 'id',
            render: (value, _, index) => {
                return <Form.Item hidden name={[index, 'advanceAdjustmentId']} initialValue={value}>
                    <Input value={value} defaultValue={value}>

                    </Input>
                </Form.Item>
            }
        }

    ];




    function advanceTypeOnchangeHandler(value: AdvanceEnumType, isEditAlert?: boolean) {
        if (isEditAlert) {
            setDisableEditIcon(isEditAlert);
        }
        formRef.resetFields(['invoiceContractId', 'advanceBalance', 'advanceToAdjust'])
        let contractNo: string = ''
        if (value === AdvanceEnumType.Against_Advance_Account) {
            contractNo = vendorId
        } else if (value === AdvanceEnumType.Against_Purchase_Contract) {
            contractNo = contractId;
        } else if (value === AdvanceEnumType.Against_Performa_Invoice) {
            // contractNo = performaAdvance[0]?.contractId
            contractNo = contractId;

        }
        const req = new AdvanceTypeReqDto(authContext.defaultPlant, value, contractNo, bargeSelectionRowKeys, formRef.getFieldValue('purchaseType'), formRef.getFieldValue('qualityId'), vendorId);
        cmService.getInvoiceContracts(req).then(res => {
            if (res.status) {
                setInvoiceContracts(res.data);
            } else {
                setInvoiceContracts([]);
            };
        }).catch(err => console.log(err.message));
    };


    function advanceAmountHandler(value: any): void {
        console.log(adjustedData, value, invoiceContracts)
        const advanceBalance = invoiceContracts?.filter((rec) => rec?.contractId === value)[0];
        let findAdjustedRecord = adjustedData.find((rec) => rec.adjustedCostingId === value)
        const remainingAdvanceBalance = Number(advanceBalance?.transactionTotalAmount) - Number(findAdjustedRecord?.totalAdjustedAmount)

        if (advanceBalance) {
            if (advanceBalance.priceType === "PMT" && advanceBalance.transactionTotalAmount) {

                const minimumValue = Number(formRef.getFieldValue('totalBargeQuantity')) * Number(advanceBalance.price)
                formRef.setFieldValue('advanceToAdjust', Number(Math.min(minimumValue)?.toFixed(2)));
                // if (!findAdjustedRecord?.adjustedCostingId) {
                //     formRef.setFieldValue('advanceBalance', Number(Number(advanceBalance?.transactionTotalAmount)?.toFixed(2)));
                // } else {
                //     formRef.setFieldValue('advanceBalance', Number(Number(remainingAdvanceBalance)?.toFixed(2)));
                // }

                formRef.setFieldValue('advanceBalance', Number(Number(advanceBalance?.transactionTotalAmount)?.toFixed(2)));

            } else if (Number(advanceBalance?.transactionTotalAmount)) {

                //for adjusted transactionTotalAmount minus adjusted amount validation
                if (!findAdjustedRecord?.adjustedCostingId) {
                    formRef.setFieldValue('advanceBalance', Number(Number(advanceBalance?.transactionTotalAmount)?.toFixed(2)));
                } else {
                    formRef.setFieldValue('advanceBalance', Number(Number(remainingAdvanceBalance)?.toFixed(2)));
                }

            } else {
                formRef.setFieldValue('advanceBalance', undefined);
                formRef.setFieldValue('advanceToAdjust', undefined);
            }
        } else {
            formRef.setFieldValue('advanceBalance', undefined);
            formRef.setFieldValue('advanceToAdjust', undefined);

        }

    };



    return (
        <>
            <br />

            <Form form={formRef} layout='vertical'>
                <Form.Item hidden name={'advanceAdjustmentId'}>
                    <Input>
                    </Input>
                </Form.Item>

                <Row>
                    <Col span={5} offset={1}>
                        <Form.Item name={'advanceType'} label={'Advance Type'}>
                            <Select style={{ width: "130px" }} allowClear showSearch placeholder={'Advance Type'} onChange={(value) => advanceTypeOnchangeHandler(value)}>
                                <Option value=''>
                                    {'Please Select'}
                                </Option>
                                {Object.keys(AdvanceEnumType).filter((item) => item !== 'Against_Lc_Balance').map((rec) => {
                                    return <Option style={{ width: 250 }} value={AdvanceEnumType[rec]} >{AdvanceEnumType[rec]}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={7} offset={4}>
                        <Form.Item name={'invoiceContractId'} label={'Invoice Contract No'}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                allowClear
                                showSearch
                                placeholder={'PLease Select'}
                                onChange={advanceAmountHandler}
                            >
                                {invoiceContracts?.map((rec) => {
                                    return <Option style={{ width: 250 }} value={rec?.contractId}>{rec?.contractNo}</Option>
                                })}

                            </Select>

                        </Form.Item>
                    </Col>
                </Row>

                <Row>

                    <Col span={7} offset={1}>
                        <Form.Item name={'advanceBalance'} label={'Advance Balance'}>
                            <Input disabled placeholder='Advance Balance'>
                            </Input>
                        </Form.Item>
                    </Col>

                    <Col span={7} offset={2}>
                        <Form.Item name={'advanceToAdjust'} label={'Amount To Adjust'}>
                            <Input
                                onChange={adjustOnChangeHandler}
                                placeholder='Amount To Adjust'>
                            </Input>
                        </Form.Item>
                    </Col>

                    <Col span={3} offset={3}>
                        <Form.Item label={' '}>
                            <Button onClick={() => addAdvanceAdjustmentAmount(true)}

                                //  disabled={disableAdjust}
                                type='primary'>Add</Button>
                        </Form.Item>

                    </Col>
                </Row>
            </Form>
            <Row>
                <Col  >
                    <Table columns={columns} dataSource={adjustedData} pagination={false} className='contracts' bordered={true}></Table >
                </Col>
            </Row>




        </>
    )
}

export default CmAdvanceAdjustments