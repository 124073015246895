import { ContractTypesEnum } from '@exportx/shared-models-and-services';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { TypicalModel } from './typical.model';

export interface ITypicalData {
    typical: TypicalModel;
    addendumTypical: TypicalModel;
    contractType: ContractTypesEnum;
}
const TypicalDataView = (props: ITypicalData) => {
    const { contractType } = props;
    const [typicalDataSource, setTypicalDataSource] = useState([]);
    const [addendumTypicalDataSource, setAddendumTypicalDataSource] = useState([]);

    useEffect(() => {
        if (props.typical)
            setTypicalDataSource(constructDataSource(props.typical));
        if (props.addendumTypical)
            setAddendumTypicalDataSource(constructDataSource(props.addendumTypical));
    }, [props.typical]);

    const valiDateWithSnapShotData = (index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = addendumTypicalDataSource?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM && valueAtGivenKey !== '-') {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={''}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }
    const constructDataSource = (typical: TypicalModel) => {
        let dataSource = [];
        dataSource.push({
            components: 'Gross Calorific Value (Kcal/kg)',
            rowSpan: 3,
            basis: typical.calorificBasis ? typical.calorificBasis : '-',
            typical: typical.calorificTypical ? typical.calorificTypical : '-',
            rejection: typical.calorificRejection ? typical.calorificRejection : '-',
            bonusCap: typical.calorificBonusCap ? typical.calorificBonusCap : '-',
            penaltyCap: typical.calorificPenaltyCap ? typical.calorificPenaltyCap : '-',
        });
        dataSource.push({
            components: 'Gross Calorific Value (Kcal/kg)',
            rowSpan: 0,
            basis: typical.calorificBasis1 ? typical.calorificBasis1 : '-',
            typical: typical.calorificTypical1 ? typical.calorificTypical1 : '-',
            rejection: typical.calorificRejection1 ? typical.calorificRejection1 : '-',
            bonusCap: typical.calorificBonusCap1 ? typical.calorificBonusCap1 : '-',
            penaltyCap: typical.calorificPenaltyCap1 ? typical.calorificPenaltyCap1 : '-',
        });
        dataSource.push({
            components: 'Gross Calorific Value (Kcal/kg)',
            rowSpan: 0,
            basis: typical.calorificBasis2 ? typical.calorificBasis2 : '-',
            typical: typical.calorificTypical2 ? typical.calorificTypical2 : '-',
            rejection: typical.calorificRejection2 ? typical.calorificRejection2 : '-',
            bonusCap: typical.calorificBonusCap2 ? typical.calorificBonusCap2 : '-',
            penaltyCap: typical.calorificPenaltyCap2 ? typical.calorificPenaltyCap2 : '-',
        });

        dataSource.push({
            components: 'Total Moisture (%)',
            basis: typical.totalMoistureBasis ? typical.totalMoistureBasis : '-',
            typical: typical.totalMoistureTypical ? typical.totalMoistureTypical : '-',
            rejection: typical.totalMoistureRejection ? typical.totalMoistureRejection : '-',
            bonusCap: '-',
            penaltyCap: '-',
        });

        dataSource.push({
            components: 'Inherent Moisture (%)',
            basis: typical.inherentMoistureBasis ? typical.inherentMoistureBasis : '-',
            typical: typical.inherentMoistureTypical ? typical.inherentMoistureTypical : '-',
            rejection: typical.inherentMoistureRejection ? typical.inherentMoistureRejection : '-',
            bonusCap: '-',
            penaltyCap: '-'
        });

        dataSource.push({
            components: 'Ash (%)',
            basis: typical.ashBasis ? typical.ashBasis : '-',
            typical: typical.ashTypical ? typical.ashTypical : '-',
            rejection: typical.ashRejection ? typical.ashRejection : '-',
            bonusCap: typical.ashPenalty ? typical.ashPenalty : '-',
            penaltyCap: typical.ashRejectionPenalty ? typical.ashRejectionPenalty : '-',
        });

        dataSource.push({
            components: 'Volatile Matter (%)',
            basis: typical.volatileMatterBasis ? typical.volatileMatterBasis : '-',
            typical: typical.volatileMatterTypical ? typical.volatileMatterTypical : '-',
            rejection: typical.volatileMatterRejection ? typical.volatileMatterRejection : '-',
            bonusCap: '',
            penaltyCap: ''
        });

        dataSource.push({
            components: 'Fixed Carbon (%)',
            basis: typical.fixedCarbonBasis ? typical.fixedCarbonBasis : '-',
            typical: typical.fixedCarbonTypical ? typical.fixedCarbonTypical : '-',
            rejection: typical.fixedCarbonRejection ? typical.fixedCarbonRejection : '-',
            bonusCap: '',
            penaltyCap: ''
        });

        dataSource.push({
            components: 'Fuel Ratio(FC/VM)',
            basis: typical.fuelRatioBasis ? typical.fuelRatioBasis : '-',
            typical: typical.fuelRatioTypical ? typical.fuelRatioTypical : '-',
            rejection: typical.fuelRatioRejection ? typical.fuelRatioRejection : '-',
            bonusCap: '',
            penaltyCap: ''
        });

        dataSource.push({
            components: 'Total Sulphur (%)',
            basis: typical.totalSulphurBasis ? typical.totalSulphurBasis : '-',
            typical: typical.totalSulphurTypical ? typical.totalSulphurTypical : '-',
            rejection: typical.totalSulphurRejection ? typical.totalSulphurRejection : '-',
            penaltyCap: typical.totalSulphurRejectionPenalty ? typical.totalSulphurRejectionPenalty : '-',
            bonusCap: typical.totalSulphurPenalty ? typical.totalSulphurPenalty : '-',
        });

        dataSource.push({
            components: 'HGI',
            basis: '-',
            typical: typical.hgiUnitsTypical ? typical.hgiUnitsTypical : '-',
            rejection: typical.hgiUnitsRejection ? typical.hgiUnitsRejection : '-',
            bonusCap: '',
            penaltyCap: ''
        });
        return dataSource;
    };



    const typicalColumns: ColumnsType<any> = [
        {
            title: 'Components',
            dataIndex: 'components',
            key: 'components',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'components', value)}</span>
            ),
        },
        {
            title: 'Basis',
            key: 'basis',
            dataIndex: 'basis',
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'basis', value)}</span>
            ),
        },
        {
            title: 'Typical',
            key: 'typical',
            dataIndex: 'typical',
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'typical', value)}</span>
            ),
        },
        {
            title: 'Rejection',
            key: 'rejection',
            dataIndex: 'rejection',
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'rejection', value)}</span>
            ),
        },
        {
            title: 'Bonus Cap',
            key: 'bonusCap',
            dataIndex: 'bonusCap',
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'bonusCap', value)}</span>
            ),
        },
        {
            title: 'Penalty Cap',
            key: 'penaltyCap',
            dataIndex: 'penaltyCap',
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'penaltyCap', value)}</span>
            ),
        },
    ];

    const rowClassName = (row: any, index) => {
        return index % 2 === 0 ? 'row-even' : 'row-odd'; 
      };

    return (
        <Table 
        columns={typicalColumns} 
        dataSource={typicalDataSource} 
        rowClassName={rowClassName}
        // bordered 
        pagination={false} 
        className="contracts-PO-List"
        />
    )
}

export default TypicalDataView