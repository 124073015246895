import { Tabs } from "antd";
import PurchaseActiveApproval from "./purchase-approval/purchase-contract-active";
import PurchasePendingApproval from "./purchase-approval/purchase-pendding-approval";


const { TabPane } = Tabs;
const PurchaseContractApproval = () => {
  return (

    <Tabs destroyInactiveTabPane={true}>
      <TabPane tab="Pending" key="1">
        <PurchasePendingApproval />
      </TabPane>
      <TabPane tab="Approved" key="2">
        <PurchaseActiveApproval />
      </TabPane>


    </Tabs>


  )
}

export default PurchaseContractApproval;