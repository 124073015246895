import { ContractTypesEnum, PurchaseContractDto, PurchaseTermDataDto, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Descriptions } from 'antd';

export interface IOthersData {
    purchaseTermData: PurchaseTermDataDto,
    specType: SpecTypesEnum;
    snapShotPurchaseTermData: PurchaseTermDataDto;
    contractType: ContractTypesEnum;
    snapSpecType: SpecTypesEnum;
}
const OtherSpecs = (props: IOthersData) => {
    const { purchaseTermData, specType } = props;
    let data = specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData;


    return (
        <Descriptions
            title=""
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
            <Descriptions.Item label="Coal Bulk Density (t/cu.m)">
                {data?.otherCoalBulkDensityTypical}
            </Descriptions.Item>
            <Descriptions.Item label="Stowage Factor (cuf/t)">
                {data?.otherStowageFactorTypical}
            </Descriptions.Item>
            <Descriptions.Item label="IMSBC Group (A,B,C)">
                {data?.otherImsbcGroupTypical}
            </Descriptions.Item>

            <Descriptions.Item label="G & Y Index">
                {data?.otherGandYIndexTypical}
            </Descriptions.Item>
            <Descriptions.Item label="Transmittance Value (Pm)">
                {data?.otherTransmittanceValueTypical}
            </Descriptions.Item>
            <Descriptions.Item label="Max lump size (mm)">
                {data?.otherMaxLumpSizeTypical}
            </Descriptions.Item>

            <Descriptions.Item label="Angle of Repose Minimum">
                {data?.otherAngleMinimumTypical}
            </Descriptions.Item>
            <Descriptions.Item label="Angle of Repose Maximum">
                {data?.otherAngleMaximumTypical}
            </Descriptions.Item>
        </Descriptions>
    )
}

export default OtherSpecs;