import { Card, Button, Table} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {  VendorAdvance } from '@exportx/shared-models-and-services';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
// import VendorAdvance from './vendoradvance';

const DownPaymentsGrid = () => {
const navigate = useNavigate();
const [gridData, ] = useState<any[]>([]);
let pathToreDirect = '/contractdetails'



  const tableColumns: any = [
    {
      title: 'Contract Number',
       dataIndex: 'contractNo',
        width: 130,
         align: 'left',
         sorter: (a, b) => a.contractNo.localeCompare(b.contractNo),
          sortDirections: ['descend', 'ascend'],
      render: ( record) => <> {
        <Link onClick={() => navigate(pathToreDirect , {state:{record}})}>{record.contractNo}</Link>}
      </>
    },

    
    {
      title: 'Vedor Name',
      dataIndex: 'vendorId',
      width: 130,
      sorter: (a, b) => a.vendorId.localeCompare(b.vendorId),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Supplier Reference No',
      dataIndex: 'supplierReferenceNo',
      width: 130,
      sorter: (a, b) => a.supplierRefNo.localeCompare(b.supplierRefNo),
        sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Down Payment Amount',
      dataIndex: 'downPaymentAmount',
      width: 130,
      sorter: (a, b) => a.advanceAmount.localeCompare(b.advanceAmount),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      width: 130,
      sorter: (a, b) => a.advanceAmount.localeCompare(b.advanceAmount),
      sortDirections: ['descend', 'ascend'],
      render: (record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('YYYY-MM-DD') : "" },
    },

    {
      title: 'Action',
      dataIndex: 'operations',
      width: 130,
    },
  ]


  return (
    <div>
      <Card title={<span style={{ color: 'white' }}>DownPayments</span>}

        style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#69c0ff', border: 0 }} extra={<span style={{ color: 'white' }} ><Button type="primary" onClick={() => navigate('/downpayment')}>Create</Button> </span>}>
        <br></br>

        <Table rowKey={record => record.Id} columns={ tableColumns} dataSource={gridData}/>

      </Card>

    </div>
  )

}

export default DownPaymentsGrid
