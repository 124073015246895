import { BpCatCodeEnum, BpCategoryCreateDto, BpCategoryUpdateDto, BusinessPartnerCategoryService, BusinessPartnerTypeEnum } from '@exportx/shared-models-and-services';
import { Space, Button, Form, Row, Col, Input, Select, Card } from 'antd';
import { useIntl } from 'react-intl';
import { ClassValidator } from '@exportx/ui-utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../../../common';
import { useEffect } from 'react';
export interface IBpCategoryFormProps {
    bpCategory: BusinessPartnerTypeEnum;
}

const { Option } = Select;
export const BpCategoryForm = (props: IBpCategoryFormProps) => {
    const { formatMessage: fm } = useIntl();
    const navigate = useNavigate();
    const { state }: any = useLocation();    //to access global authorized user data(Login user Data which is passed as created user or updated user)
    const { authContext } = useAuthState();//Common for all Page Components
    const { isUpdate, bpCategoryDto, isNewRecord } = state || {}; // Read values passed on router    
    //To Access Backend Services Via Shared Services
    const service = new BusinessPartnerCategoryService();
    const baseIntlPath = props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? `mdm.components.customerCategoryForm` : props.bpCategory === BusinessPartnerTypeEnum.VENDOR ? `mdm.components.vendorCategoryForm` : `mdm.components.facilitatorTypeForm`;
    const FormInPutTitle = fm({ id: `${baseIntlPath}.bpCategory`, defaultMessage: `${props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'Customer Type' : props.bpCategory === BusinessPartnerTypeEnum.VENDOR ? ' Vendor Type' : 'Facilitator Type'}` });
    const bpInputCategoryCode = fm({ id: `${baseIntlPath}.bpCategory`, defaultMessage: `${props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'Customer Category Code' : props.bpCategory === BusinessPartnerTypeEnum.VENDOR ? ' Vendor Category Code' : 'Facilitator Type Code'}` });
    const classValidator = new ClassValidator();
    const [form] = Form.useForm();

    useEffect(()=>{
        const accessId = {
            vendorCreate: [38],
            vendorView: [39],
            vendorEdit: [40],
            customerCreate: [42],
            customerView: [43],
            customerEdit: [44],
            facilitatorCreate: [46],
            facilitatorView: [47],
            facilitatorEdit: [48]
        }

        if(props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER) {
            const hasCustomerPermission = isNewRecord ? accessId.customerCreate : isUpdate ? accessId.customerEdit : accessId.customerView;

            if(!isPermissionExist(hasCustomerPermission)){
                return navigate('/')
            }
        } else if (props.bpCategory === BusinessPartnerTypeEnum.VENDOR) {
            const hasVendorPermission = isNewRecord ? accessId.vendorCreate : isUpdate ? accessId.vendorEdit : accessId.vendorView;
            
            if(!isPermissionExist(hasVendorPermission)){
                return navigate('/')
            }
        } else {
            const hasFacilitatorPermission = isNewRecord ? accessId.facilitatorCreate : isUpdate ? accessId.facilitatorEdit : accessId.facilitatorView;

            if(!isPermissionExist(hasFacilitatorPermission)) {
                return navigate('/')
            }
        }
    },[])

    const clearData = () => {
        form.resetFields();
    }
    let pathToreDirect = '/customerType';
    if (BusinessPartnerTypeEnum.CUSTOMER === props.bpCategory) {
        pathToreDirect = '/customerType';
    } else if (BusinessPartnerTypeEnum.VENDOR === props.bpCategory) {
        pathToreDirect = '/vendorType';
    } else {
        pathToreDirect = '/facilitatorType'
    }

    const submitForm = (values: any) => {
        if (isUpdate && bpCategoryDto?.bpCategoryId) {
            update(values);
            return;
        }
        const createDto: BpCategoryCreateDto = new BpCategoryCreateDto(values.bpCategoryCode, values.bpCategory, props.bpCategory, authContext.user.userName);
        service.create(createDto).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `${props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'Customer' : 'Vendor'} Type Created SuccessFully` }));
                setTimeout(() => {
                    navigate(pathToreDirect);
                }, 1000);
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const update = (values: any) => {
        const updateDto: BpCategoryUpdateDto = new BpCategoryUpdateDto(bpCategoryDto.bpCategoryId, values.bpCategoryCode, values.bpCategory, props.bpCategory, authContext.user.userName, bpCategoryDto.versionFlag);
        service.update(updateDto).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `${props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'Customer' : 'Vendor'} Type Updated SuccessFully` }));
                setTimeout(() => {
                    navigate(pathToreDirect);
                }, 1000);
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    return (
        <Card
            title={fm({ id: `${baseIntlPath}.header`, defaultMessage: `${props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'Customer' : 'Vendor'} Types` })}
            style={{ overflow: 'auto' }}
            className='default-card-class'
            extra={
                <Button
                    onClick={() => navigate(pathToreDirect)}
                >
                    {fm({ id: 'common.view', defaultMessage: 'View' })}
                </Button>
            }
            bodyStyle={{ overflow: 'auto' }}
        >
            <Form layout="vertical" form={form} onFinish={submitForm} initialValues={bpCategoryDto}>
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }} xl={{ span: 11 }}>
                        <Form.Item
                            name="bpCategory"
                            label={FormInPutTitle}
                            rules={
                                [
                                    { required: true, message: fm({ id: `${baseIntlPath}.bpCategoryRequired`, defaultMessage: `Enter ${FormInPutTitle}` }) },

                                    ...classValidator.alphaSpaces(),
                                    ...classValidator.lengthValidation(40, fm({ id: `${FormInPutTitle}`,defaultMessage:`${FormInPutTitle}` }))
                                ]}
                        >
                            <Input placeholder={fm({ id: `${baseIntlPath}.bpCategoryRequired`, defaultMessage: `Enter ${FormInPutTitle}` })} autoComplete='off' />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11, offset: 2 }} lg={{ span: 11, offset: 2 }} xl={{ span: 11, offset: 2 }}
                    >
                        <Form.Item
                            label={bpInputCategoryCode}
                            name={'bpCategoryCode'}
                            rules={
                                [
                                    { required: true, message: fm({ id: `${baseIntlPath}.bpCategoryLength`, defaultMessage: `Enter ${bpInputCategoryCode}` }) },
                                    ...classValidator.alphaNumericWithCapitalsWithOutSpace(),
                                    ...classValidator.lengthValidation(6, fm({ id: `${bpInputCategoryCode}`,defaultMessage:`${bpInputCategoryCode}` }))
                                ]}
                        >
                            <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: "100%" }} placeholder={fm({ id: `${baseIntlPath}.bpCategory`, defaultMessage: `Select ${bpInputCategoryCode}` })}>
                                {Object.keys(BpCatCodeEnum).map(bpCat => {
                                    return <Option value={bpCat} key={bpCat}>{BpCatCodeEnum[bpCat]}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Col>
                        {
                            (isNewRecord || isUpdate) && <Space>
                                <Button
                                    onClick={() => { clearData(); }}
                                >
                                    {fm({ id: "common.clear", defaultMessage: "Clear" })}
                                </Button>
                                <Button onClick={() => form.submit()} type="primary">
                                    Submit
                                </Button>
                            </Space>
                        }
                    </Col>
                </Row>
            </Form></Card>
    )
}

export default BpCategoryForm;