import { CurrencyDto, CurrencyService, YESOrNoRadioEnum } from '@exportx/shared-models-and-services';
import { Card, Col, Form, FormInstance, Input, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FileUploadForm from '../../../../common/file-upload-form/FileUploadForm';

interface IBargeOthersProps {
  formRef: FormInstance<any>
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
  chemicalSpray?: any;
  value: YESOrNoRadioEnum;
  setValue: React.Dispatch<React.SetStateAction<YESOrNoRadioEnum>>;
}

const Others = (props: IBargeOthersProps) => {
  const { formRef, layoutType, initialValues, value, setValue } = props
  const { formatMessage: fm } = useIntl();
  const currencyService = new CurrencyService();
  const [currencies, setCurrencies] = useState<CurrencyDto[]>();


  useEffect(() => {
    getAllCurrenciesForDropDown();
  }, []);

  useEffect(() => {
    if (initialValues?.chemicalSpray) {
      setValue(initialValues?.chemicalSpray)
    }
  }, [initialValues?.chemicalSpray]);


  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  }


  const getAllCurrenciesForDropDown = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrencies(res.data);
      } else {
        setCurrencies([]);
      };
    }).catch(err => {
      console.log(err.message, 'err message')
    });
  };

  const addonAfter = (
    <Form.Item name={'chemicalSprayCurrency'} noStyle >
      <Select
        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
        allowClear
        showSearch
        style={{ width: 80 }}
      >
        {currencies?.map((rec) => {
          return <Select.Option value={rec.currencyCode}>
            {rec.currencyCode}
          </Select.Option>
        })}


      </Select>
    </Form.Item>
  )


  return (
    <>
      <Form initialValues={initialValues}
        form={formRef}
        layout={layoutType}
        autoComplete="off">
        <Card title="Others">
          <Row>
            <Col><Form.Item label="Chemical Spray" name="chemicalSpray" >
              <Radio.Group onChange={onChange} defaultValue={initialValues?.chemicalSpray ? initialValues?.chemicalSpray : value}>
                {Object.keys(YESOrNoRadioEnum).map(chemical => {
                  return <Radio value={YESOrNoRadioEnum[chemical]}>{YESOrNoRadioEnum[chemical]}</Radio>
                })}
              </Radio.Group>
            </Form.Item>
            </Col>
            <Col>

              {value === YESOrNoRadioEnum.YES && <>
                <Form.Item label={fm({ id: 'mdm.components.others.chemicalSprayValue', defaultMessage: 'Chemical Spray Value' })}

                  name="chemicalSprayPrice"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Only Numbers Accepted"
                    },
                  ]}>
                  <Input addonAfter={addonAfter} placeholder={fm({ id: `mdm.component.others.reqPrice in IDR`, defaultMessage: `Price ` })} autoComplete='off'></Input>
                </Form.Item> </>
              }
            </Col>

          </Row>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} >
              <Form.Item label={fm({ id: 'mdm.components.others.remarks', defaultMessage: 'Remarks' })}
                name="remarks"
              //rules={[{ required: true, message: 'QUALITY' }]}
              >
                <Input.TextArea />
              </Form.Item></Col>
          </Row>
        </Card>
      </Form>
      <FileUploadForm maxCount={3} layoutType={'vertical'} formRef={formRef} initialValues={initialValues?.filesData ? initialValues?.filesData : []} />
      {props?.children}
    </>





  )
}
export default Others