import { ContractTermEnum, SpecTypesEnum } from "@exportx/shared-models-and-services";
import { Button, Card, Checkbox, Col, Form, Row, Tabs } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { ASTMSpecification } from "./astm";


const { TabPane } = Tabs;
interface ISpecificationProps {
   astmData: any;
   isoData: any;
   purchaseTermFormSubmitHandler: (values: any) => void;
   noPriceAdjustment: boolean;
   specSpecification: SpecTypesEnum;
   purchasingTerm: ContractTermEnum
}
export const Specifications = (props: ISpecificationProps) => {
   const [enableCopy, setEnableCopy] = useState(false);
   const [saveBothSpec, setSaveBothSpec] = useState(SpecTypesEnum.BOTH == props.specSpecification ? true : false);
   const [noPriceAdjustment, setNoPriceAdjustment] = useState(props.noPriceAdjustment);
   const [activeSpecType, setActiveSpecType] = useState(SpecTypesEnum.ASTM);
   const { astmData, isoData, purchasingTerm, purchaseTermFormSubmitHandler } = props;
   const [astmRef] = Form.useForm();
   const [isoRef] = Form.useForm();

   const onChange = (key: SpecTypesEnum) => {
      setActiveSpecType(key);
   };

   const enableCopyHandler = (e: CheckboxChangeEvent) => {
      setEnableCopy(e.target.checked);
      const getASTMValues = astmRef.getFieldsValue();
      isoRef.setFieldsValue(getASTMValues);
   };

   const saveBothSpecHandler = (e: CheckboxChangeEvent) => {
      setSaveBothSpec(e.target.checked);
   };
   const noPriceAdjustmentHandler = (e: CheckboxChangeEvent) => {
      setNoPriceAdjustment(e.target.checked);
   };

   const qualityAdjustments = () => {
      let statement = '';
      const calorificTypical = activeSpecType === 'ASTM' ? astmRef.getFieldValue('calorificTypical') : isoRef.getFieldValue('calorificTypical');
      if (calorificTypical) {
         const calorificStatement = `8.1.	Calorific Value:
         Should the actual GCV Calorific Value is below ${calorificTypical} (ARB) Kcal/Kg, then the Base Price of Coal shall be adjusted according to the following formula:
         
         Final Price= (Base Price X Actual GCV/${calorificTypical}).      
         
         Should be gross calorific value (as received basis) be below ${calorificTypical} Kcal/Kg for any of the barge, buyer shall have the right to reject the relevant barge or if buyer choose not to reject such barge, then the base price shall be adjusted as follows:
         
         Final Price = Base Price-[2x (Base price – (Base price x actual GCV /${calorificTypical} Kcal/Kg)]
         
         Bonus not applicable         
         `;
         statement += calorificStatement;
      }
      const ashTypical = activeSpecType === 'ASTM' ? astmRef.getFieldValue('ashTypical') : isoRef.getFieldValue('ashTypical');
      const ashRejection = activeSpecType === 'ASTM' ? astmRef.getFieldValue('ashRejection') : isoRef.getFieldValue('ashRejection');
      const ashPenalty =activeSpecType === 'ASTM' ? astmRef.getFieldValue('ashPenalty') : isoRef.getFieldValue('ashPenalty');
      if (ashTypical && ashRejection) {
         const ashStatement = `8.2.	ASH CONTENT (ADB) 
         If actual Ash Content (ADB) as per the Certificate of Analysis at loading port, is above ${ashTypical}%, and not exceed the rejection limit, a penalty of USD ${ashPenalty} PMT shall be applied for each 1% increase in Ash Content (ADB)  up to ${ashRejection}%, fraction pro rata.        
         
         If the Ash is more than rejection level of ${ashRejection}% then the Buyer has option reject the cargo or the penalty will be double to USD ${ashPenalty * 2} PMT. (As per buyer options).
         `;
         statement += ashStatement;
      }

      const totalSulphurTypical = activeSpecType === 'ASTM' ? astmRef.getFieldValue('totalSulphurTypical') : isoRef.getFieldValue('totalSulphurTypical');
      const totalSulphurRejection = activeSpecType === 'ASTM' ? astmRef.getFieldValue('totalSulphurRejection') : isoRef.getFieldValue('totalSulphurRejection');
      const totalSulphurPenalty = activeSpecType === 'ASTM' ? astmRef.getFieldValue('totalSulphurPenalty') : isoRef.getFieldValue('totalSulphurPenalty');
      if (totalSulphurTypical && totalSulphurRejection) {
         const sulphurStatement = `8.3.	TOTAL SULPHUR (ADB) 
         If actual Total Sulphur (ADB) is above ${totalSulphurTypical} %, and not exceed the rejection limit, a penalty of USD ${totalSulphurPenalty} PMT shall be applied, for each 0.1% increase in Total Sulphur (ADB) up to ${totalSulphurRejection} % fraction pro rata.
         
         If the total Sulphur (ADB) is more than ${totalSulphurRejection}% then the Buyer has option reject the cargo or the penalty will be double to USD ${totalSulphurPenalty * 2} PMT. (As per buyer options).`;

         statement += sulphurStatement;
      }


      return statement;
   }

   const submitButtonHandler = () => {
      astmRef.validateFields().then(astmValues => {
         isoRef.validateFields().then(isoValues => {
            purchaseTermFormSubmitHandler({ astmData: { ...astmValues }, isoData: { ...isoValues }, specSpecification: saveBothSpec ? SpecTypesEnum.BOTH : activeSpecType, qualityAdjustments: qualityAdjustments(), noPriceAdjustment })
         })
      })
   }

   const cancelHandler = () => {
      astmRef.validateFields().then(astmValues => {
         isoRef.validateFields().then(isoValues => {
            purchaseTermFormSubmitHandler({ astmData: { ...astmValues }, isoData: { ...isoValues }, specSpecification: saveBothSpec ? SpecTypesEnum.BOTH : activeSpecType, qualityAdjustments: qualityAdjustments(), noPriceAdjustment })
         })
      })
   }

   return (
      <Card
         style={{ height: '90vh', width: '100%', top: 0, bottom: 0, marginBottom: '20px' }}
         bodyStyle={{ maxHeight: '90vh', overflowY: 'scroll' }}
         actions={[
            <Row justify='space-around'>
               <Col><Button onClick={() => { cancelHandler(); }}>Cancel</Button></Col>
               <Col>
                  <Button onClick={() => { submitButtonHandler(); }} type="primary">
                     Submit
                  </Button>
               </Col>
            </Row>
         ]}>
         <Tabs onChange={onChange} activeKey={activeSpecType} centered>
            <TabPane tab={SpecTypesEnum.ASTM} key={SpecTypesEnum.ASTM}>
               {purchasingTerm === ContractTermEnum.SPEC && < Checkbox checked={noPriceAdjustment} onChange={noPriceAdjustmentHandler}>No Price Adjustment</Checkbox>}
               <ASTMSpecification formRef={astmRef} initialValues={astmData} layoutType='vertical' specType={SpecTypesEnum.ASTM} />
            </TabPane>
            <TabPane tab={SpecTypesEnum.ISO} key={SpecTypesEnum.ISO}>
               <Card>
                  <Checkbox checked={enableCopy} onChange={enableCopyHandler}>Copy from {SpecTypesEnum.ASTM}</Checkbox>
                  <Checkbox checked={saveBothSpec} onChange={saveBothSpecHandler}>Consider Both {SpecTypesEnum.BOTH}</Checkbox>
                  <ASTMSpecification formRef={isoRef} initialValues={isoData} layoutType='vertical' specType={SpecTypesEnum.ISO} />
               </Card >
            </TabPane>
         </Tabs>
      </Card >
   )
}
export default Specifications;