import { Card, Col, Form, FormInstance, Input, Row } from 'antd'
import { FormLayout } from 'antd/lib/form/Form';
import React from 'react'

interface IAshFusionProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}

export const AshFusion = (props: IAshFusionProps) => {
  const { formRef, initialValues, children, layoutType } = props;
  return (
    <Card>
      <Form
        layout={layoutType} form={formRef} initialValues={initialValues}
      >
        <Col><h3 >Reducing Atmosphere</h3></Col>
        <Row >
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }}>
            <Form.Item name="ashReducingInitialTypical" label="Initial Deformation (Deg.C)">
              <Input type="number" placeholder="Typical" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
            <Form.Item name="ashReducingInitialRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }}>
            <Form.Item name="ashReducingSofteningTypical" label="Softening (Deg.C)" >
              <Input type="number" placeholder="Typical" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
            <Form.Item name="ashReducingSofteningRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
            <Form.Item name="ashReducingHemisphericalTypical" label="Hemispherical Deformation (Deg.C)" >
              <Input type="number" placeholder="Typical" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
            <Form.Item name="ashReducingHemisphericalRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }}   >
            <Form.Item name="ashReducingFlowTypical" label="Flow (Deg.C)" >
              <Input type="number" placeholder="Typical" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }}  >
            <Form.Item name="ashReducingFlowRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <h3>Oxidizing Atmosphere</h3>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
            <Form.Item name="ashOxidizingInitialTypical" label="Initial Deformation (Deg.C)" >

              <Input type="number" placeholder="Typical" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }}>
            <Form.Item name="ashOxidizingInitialRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
            <Form.Item name="ashOxidizingSofteningTypical" label="Softening (Deg.C)">
              <Input type="number" placeholder="Typical" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
            <Form.Item name="ashOxidizingSofteningRejection"  >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }}  >
            <Form.Item name="ashOxidizingHemisphericalTypical" label="Hemispherical Deformation (Deg.C)">
              <Input type="number" placeholder="Typical" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
            <Form.Item name="ashOxidizingHemisphericalRejection">
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }}>
            <Form.Item name="ashOxidizingFlowTypical" label="Flow (Deg.C)">
              <Input type="number" placeholder="Typical" />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
            <Form.Item name="ashOxidizingFlowRejection" >
              <Input type="number" placeholder="Rejection" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>

  )
}
export default AshFusion;