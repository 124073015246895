import { Button, Card, Col, Descriptions, Drawer, Row, Space } from "antd"
import { useState } from "react";
import VesselData from "./mother-vessel-page";

export const MotherVesselDetailView = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    const closeDrawer = () => {
        setDrawerVisible(false);
    }

    const openFormWithData = () => {
        setDrawerVisible(true);
    }

    return (
        <Card title={'Mother Vessel'}>
            <Row justify="end">
                <Col>
                    <Space>
                        <Button type="primary" onClick={() => openFormWithData()}>Edit Mother Vessel</Button>
                    </Space>
                </Col>
            </Row>
            <br />
            <Descriptions column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} layout="vertical" bordered>
                <Descriptions.Item label='Loading Commencement'>{ }</Descriptions.Item>
                <Descriptions.Item label='Loading Completion'>{ }</Descriptions.Item>
                <Descriptions.Item label='Loading Type'>{ }</Descriptions.Item>
                <Descriptions.Item label='Loading Rate Per Day'>{ }</Descriptions.Item>
                <Descriptions.Item label='Vessel Arrived'>{ }</Descriptions.Item>
                <Descriptions.Item label='Vessel Type'>{ }</Descriptions.Item>
                <Descriptions.Item label='Vessel Sailing'>{ }</Descriptions.Item>
                <Descriptions.Item label='Vessel Final Quantity In MT'>{ }</Descriptions.Item>
                <Descriptions.Item label='Cargo Gain/Loss In MT'>{ }</Descriptions.Item>
                <Descriptions.Item label='Discharge Port'>{ }</Descriptions.Item>
                <Descriptions.Item label='ETA/NOR'>{ }</Descriptions.Item>
                <Descriptions.Item label='NOR Acceptance'>{ }</Descriptions.Item>
                <Descriptions.Item label='Stevedor On Mv'>{ }</Descriptions.Item>
                <Descriptions.Item label='Stevedor On Additional Days'>{ }</Descriptions.Item>
                <Descriptions.Item label='Documents On-board'>{ }</Descriptions.Item>
                <Descriptions.Item label='Dead Frieght Account'>{ }</Descriptions.Item>
                <Descriptions.Item label='Dead Frieght Incurred'>{ }</Descriptions.Item>
                <Descriptions.Item label='Supplier Surveyor Name'>{ }</Descriptions.Item>
                <Descriptions.Item label='Supplier Surveyor Analysis Type'>{ }</Descriptions.Item>
                <Descriptions.Item label='Supplier Documentation Agent'>{ }</Descriptions.Item>
                <Descriptions.Item label='Load Port Agent'>{ }</Descriptions.Item>
                <Descriptions.Item label='SRPL Surveyor Name'>{ }</Descriptions.Item>
                <Descriptions.Item label='SRPL Surveyor Analysis Type'>{ }</Descriptions.Item>
                <Descriptions.Item label='SRPL Documentation Agent'>{ }</Descriptions.Item>
                <Descriptions.Item label='Surveyor Price'>{ }</Descriptions.Item>
                <Descriptions.Item label='Surveyor Additional Days'>{ }</Descriptions.Item>
                <Descriptions.Item label='Buyer/Additional Surveyor Name'>{ }</Descriptions.Item>
                <Descriptions.Item label='Buyer Documentation Agent'>{ }</Descriptions.Item>
                <Descriptions.Item label='Remarks'>{ }</Descriptions.Item>
            </Descriptions>
            <Drawer bodyStyle={{ paddingBottom: 80 }} width={window.innerWidth > 768 ? '50%' : '85%'}
                onClose={closeDrawer} open={drawerVisible} closable={true}>
                <VesselData />
            </Drawer>
        </Card>
    )
}
export default MotherVesselDetailView;