import { faShrimp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import { ReactComponent as Cargo } from '../icons/noun-barge-4687804.svg';
import { ReactComponent as LCargo } from '../icons/loading-cargo.svg';
import React from 'react';
import Icon from '@ant-design/icons';

import './loading-spinner.css';

/* eslint-disable-next-line */
export interface LoadingSpinnerProps {
  loading: boolean;
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { loading } = props;
  return (
    <div id="loading-spinner" style={{ display: loading ? "block" : "none" }}>
      <div style={{ margin: "24% 0% 20% 0%" }}>
        <div className="ant-spin ant-spin-lg ant-spin-spinning ant-spin-show-text" >
          <span className="ant-spin-dot ant-spin-dot-spin">
            <i className="ant-spin-dot-item"></i>
            <i className="ant-spin-dot-item"></i>
            <i className="ant-spin-dot-item"></i>
            <i className="ant-spin-dot-item">
            </i></span>
          <div className="ant-spin-text">
            <Icon component={Cargo} className='floating' style={{ color: "#1890ff", fill: "white", fontSize: '60px' }} />
          </div></div>
      </div>
    </div>
    // loading && <Spin indicator={<div id='loader svg-container'>
    //   <Icon  component={LCargo} style={{color: "#1890ff", fill: "white",fontSize:'40px',marginTop: '40vh'}}/>
    //   {/* <FontAwesomeIcon icon={faShrimp} className='fa-spin' fontSize={'40px'} style={{ marginTop: '30vh' }} /> */}
    // </div>} >

    // </Spin>
  );
};

export default LoadingSpinner;
