import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const AccountSelection = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    account_groups: [],
    ledgers: [],
    payment_vouchers: [],
    receipt_vouchers: [],
    journal_vouchers: [],
    account_ledger: [],
    accounting_payable: [],
    account_receivable: [],
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          account_groups: features?.filter((cou) => 784 <= +cou && +cou <= 789)?.map(Number),
          ledgers: features?.filter((cou) => 790 <= +cou && +cou <= 795)?.map(Number),
          payment_vouchers: features?.filter((cou) => 796 <= +cou && +cou <= 801)?.map(Number),
          receipt_vouchers: features?.filter((cou) => 802 <= +cou && +cou <= 807)?.map(Number),
          journal_vouchers: features?.filter((cou) => 808 <= +cou && +cou <= 813)?.map(Number),
          account_ledger: features?.filter((cou) => 814 <= +cou && +cou <= 819)?.map(Number),
          accounting_payable: features?.filter((cou) => 820 <= +cou && +cou <= 825)?.map(Number),
          account_receivable: features?.filter((cou) => 826 <= +cou && +cou <= 831)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({ ...prev, [name]: value }))
    }
    else setValues((prev) => ({ ...prev, [name]: [] }));
  }

  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}

    >
      <Card size="small" title="Account Groups"
        extra={<Checkbox
          name="customers"
          indeterminate={values.account_groups.length > 0 && values.account_groups.length < 6}
          checked={values.account_groups.length === 6}
          onChange={(e) => onChange(e, 'account_groups', [784, 785, 786, 787, 788, 789])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="account_groups" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, account_groups: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={784}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={785}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={786}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={787}>Delete</Checkbox>
              </Col>

              <Col span={6}>
                <Checkbox value={788}>Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={789}>Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Ledgers"
        extra={<Checkbox
          name="ledgers"
          indeterminate={values.ledgers.length > 0 && values.ledgers.length < 6}
          checked={values.ledgers.length === 6}
          onChange={(e) => onChange(e, 'ledgers', [790, 791, 792, 793, 794, 795])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="ledgers" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, ledgers: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={790}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={791}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={792}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={793}>Delete</Checkbox>
              </Col>

              <Col span={6}>
                <Checkbox value={794}>Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={795}>Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Payment Vouchers"
        extra={<Checkbox
          name="customers"
          indeterminate={values.payment_vouchers.length > 0 && values.payment_vouchers.length < 6}
          checked={values.payment_vouchers.length === 6}
          onChange={(e) => onChange(e, 'payment_vouchers', [796, 797, 798, 799, 800, 801])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="payment_vouchers" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, payment_vouchers: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={796}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={797}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={798}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={799}>Delete</Checkbox>
              </Col>

              <Col span={6}>
                <Checkbox value={800}>Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={801}>Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Receipt Vouchers"
        extra={<Checkbox
          name="customers"
          indeterminate={values.receipt_vouchers.length > 0 && values.receipt_vouchers.length < 6}
          checked={values.receipt_vouchers.length === 6}
          onChange={(e) => onChange(e, 'customers', [802, 803, 804, 805, 806, 807])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="receipt_vouchers" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, receipt_vouchers: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={802}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={803}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={804}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={805}>Delete</Checkbox>
              </Col>

              <Col span={6}>
                <Checkbox value={806}>Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={807}>Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Journal Vouchers"
        extra={<Checkbox
          name="journal_vouchers"
          indeterminate={values.journal_vouchers.length > 0 && values.journal_vouchers.length < 6}
          checked={values.journal_vouchers.length === 6}
          onChange={(e) => onChange(e, 'journal_vouchers', [808, 809, 810, 811, 812, 813])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="journal_vouchers" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, journal_vouchers: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={808}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={809}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={810}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={811}>Delete</Checkbox>
              </Col>

              <Col span={6}>
                <Checkbox value={812}>Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={813}>Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title='Accounting Reports' >
        <Card size="small" type="inner" title="Account Ledger"
          extra={<Checkbox
            name="account_ledger"
            indeterminate={values.account_ledger.length > 0 && values.account_ledger.length < 6}
            checked={values.account_ledger.length === 6}
            onChange={(e) => onChange(e, 'account_ledger', [814, 815, 816, 817, 818, 819])}
          >
            <b>Select All</b>
          </Checkbox>}>
          <Form.Item name="account_ledger" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, account_ledger: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={814}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={815}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={816}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={817}>Delete</Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox value={818}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={819}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Payable"
          extra={<Checkbox
            name="accounting_payable"
            indeterminate={values.accounting_payable.length > 0 && values.accounting_payable.length < 6}
            checked={values.accounting_payable.length === 6}
            onChange={(e) => onChange(e, 'accounting_payable', [820, 821, 822, 823, 824, 825])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="accounting_payable" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, accounting_payable: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={820}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={821}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={822}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={823}>Delete</Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox value={824}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={825}>Files</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Receivable"
          extra={<Checkbox
            name="account_receivable"
            indeterminate={values.account_receivable.length > 0 && values.account_receivable.length < 6}
            checked={values.account_receivable.length === 6}
            onChange={(e) => onChange(e, 'account_receivable', [826, 827, 828, 829, 830, 831])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="account_receivable" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, account_receivable: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={826}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={827}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={828}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={829}>Delete</Checkbox>
                </Col>

                <Col span={6}>
                  <Checkbox value={830}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={831}>Files</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>

      </Card>
    </Form>
  )
}