import { Button, Card, Drawer } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';

// import './shipping-terms-grid.css';
import { CommodityDeactivateDto, CommodityDto, CommodityService } from '@exportx/shared-models-and-services';
import { AlertMessages, FilterableGrid, isPermissionExist } from '../../../../../common';

import { useIntl } from 'react-intl';
import CommodityForm from '../commodity-form/commodity.form';
import { useNavigate } from 'react-router-dom';

/* eslint-disable-next-line */
export interface CommodityGridProps { }

export function CommodityGrid(
  props: CommodityGridProps
) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();

  const service = new CommodityService();
  const [commodityData, setCommodityData] = useState<CommodityDto[]>([]);
  const [selectCommodityData, setSelectCommodityData] = useState<any>(undefined);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNewRecord, setIsNewRecord] = useState(false);


  const { formatMessage: fm } = useIntl();

  const openFormWithData = (viewData: CommodityDto, update: boolean) => {
    setIsUpdate(update)
    setDrawerVisible(true);
    setSelectCommodityData(viewData);
  }

  useEffect(()=>{
    if(!isPermissionExist([15])) {
        return navigate('/');
    }
},[])


  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const getAll = () => {
    service.getAll().then(res => {
      if (res.status) {
        setCommodityData(res.data);
      } else {
        setCommodityData([]);
      }
    }).catch(err => {
      setCommodityData([]);
    })
  }


  const deleteUser = (data: CommodityDto) => {
    const reqDto = new CommodityDeactivateDto(data.commodityId, data.updatedUser, data.versionFlag);
    service.activatedeActivate(reqDto).then(res => {
      console.log(res);
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Commodity ${data.isActive ? ' deactivated ' : ' activated '} SuccessFully` }));
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Commodity Name',
      dataIndex: 'commodityName',
      sorter: (a, b) => a.commodityName.localeCompare(b.commodityName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Commodity Code',
      dataIndex: 'commodityCode',
      sorter: (a, b) => a.commodityCode.localeCompare(b.commodityCode),
      sortDirections: ['descend', 'ascend'],
    }
  ];

  const updateUser = (Data: CommodityDto) => {
    service.update(Data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        getAll();
        setDrawerVisible(false);
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const closeDrawer = () => {
    setDrawerVisible(false);
    getAll();
  }

  return (
    <Card
      title='Commodity Details'
      className='default-card-class'
      extra={<>{isPermissionExist([14]) && (
        <Button onClick={() => {
          setIsNewRecord(true);
          setDrawerVisible(true);
          setSelectCommodityData(null);
        }}  >
          {fm({ id: 'common.create', defaultMessage: 'Create' })} </Button>
      )}</>}>
      <FilterableGrid
        rowKey='commodityId'
        mainColumns={columnsSkelton}
        data={commodityData}
        filterColumnsData={[
          {
            dataIndex: 'commodityName',
            placeHolder: fm({
              id: 'pms.components.ClusterGrid.commodityName',
              defaultMessage: 'Commodity Name'
            })
          },
          {
            dataIndex: 'commodityCode',
            placeHolder: fm({
              id: 'pms.components.ClusterGrid.commodityCode',
              defaultMessage: 'Commodity Code'
            })
          }
        ]}
        otherTableProps={{ bordered: true }} activateOrDeactivate={deleteUser} view={openFormWithData} />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Commodity Details' width={window.innerWidth > 768 ? '50%' : '85%'}
        onClose={closeDrawer} open={drawerVisible} closable={true}>
        <CommodityForm
          key={Date.now()}
          updateDetails={updateUser}
          isUpdate={isUpdate}
          isNewRecord={isNewRecord}
          commodityData={selectCommodityData}
          closeForm={closeDrawer} />
      </Drawer>
    </Card>

  );
}

export default CommodityGrid;

