export const SpecInitialData = {
    ashBasis: "ADB",
    ashPenalty: undefined,
    ashRejection: undefined,
    ashRejectionPenalty: undefined,
    ashTypical: undefined,
    calorificBasis: undefined,
    calorificBasis1: undefined,
    calorificBasis2: undefined,
    calorificBonusCap: undefined,
    calorificBonusCap1: undefined,
    calorificBonusCap2: undefined,
    calorificRejection: undefined,
    calorificRejection1: undefined,
    calorificRejection2: undefined,
    calorificTypical: undefined,
    calorificTypical1: undefined,
    calorificTypical2: undefined,
    fixedCarbonBasis: "ADB",
    fixedCarbonRejection: undefined,
    fixedCarbonTypical: undefined,
    fuelRatioBasis: "ADB",
    fuelRatioRejection: undefined,
    fuelRatioTypical:undefined,
    hgiRejection: undefined,
    hgiTypical: undefined,
    inherentMoistureBasis: "ADB",
    inherentMoistureRejection: undefined,
    inherentMoistureTypical: undefined,
    penaltyCap: undefined,
    penaltyCap1: undefined,
    penaltyCap2: undefined,
    specSpecification: undefined,
    totalMoistureBasis: "ARB",
    totalMoistureRejection: undefined,
    totalMoistureTypical: undefined,
    totalSulphurBasis: "ADB",
    totalSulphurPenalty: undefined,
    totalSulphurRejection: undefined,
    totalSulphurRejectionPenalty: undefined,
    totalSulphurTypical: undefined,
    volatileMatterBasis: "ADB",
    volatileMatterRejection: undefined,
    volatileMatterTypical: undefined,
    calorificPenaltyCap: undefined,
    calorificPenaltyCap1: undefined,
    calorificPenaltyCap2: undefined,
    hgiUnitsTypical: undefined,
    hgiUnitsRejection: undefined,
    ashSio2Basis: undefined,
    ashSio2Typical: undefined,
    ashSio2Rejection: undefined,
    ashAi2undefined3Basis: undefined,
    ashAi2undefined3Typical: undefined,
    ashAi2undefined3Rejection: undefined,
    ashFe2undefined3Basis: undefined,
    ashFe2undefined3Typical: undefined,
    ashFe2undefined3Rejection: undefined,
    ashCaoBasis: undefined,
    ashCaoTypical: undefined,
    ashCaoRejection: undefined,
    ashMgoBasis: undefined,
    ashMgoTypical: undefined,
    ashMgoRejection: undefined,
    ashNa2oBasis: undefined,
    ashNa2oTypical: undefined,
    ashNa2oRejection: undefined,
    ashK2oBasis: undefined,
    ashK2oTypical: undefined,
    ashK2oRejection: undefined,
    ashTio2Basis: undefined,
    ashTio2Typical: undefined,
    ashTio2Rejection: undefined,
    ashMn3o4Basis: undefined,
    ashMn3o4Typical: undefined,
    ashMn3o4Rejection: undefined,
    ashSo3Basis: undefined,
    ashSo3Typical: undefined,
    ashSo3Rejection: undefined,
    ashP2o5Basis: undefined,
    ashP2o5Typical: undefined,
    ashP2o5Rejection: undefined,
    ultimateCarbonBasis: undefined,
    ultimateCarbonTypical: undefined,
    ultimateCarbonRejection: undefined,
    ultimateHydrogenBasis: undefined,
    ultimateHydrogenTypical: undefined,
    ultimateHydrogenRejection:undefined,
    ultimateNitrogenBasis: undefined,
    ultimateNitrogenTypical: undefined,
    ultimateNitrogenRejection: undefined,

    sizeDist1Name: "",
    sizeDist1Typical: undefined,
    sizeDist1Rejection: undefined,
    sizeDist2Name: "",
    sizeDist2Typical: undefined,
    sizeDist2Rejection: "",
    sizeDist3Name: "",
    sizeDist3Typical: undefined,
    sizeDist3Rejection: undefined,
    sizeDist4Name: "",
    sizeDist4Typical: undefined,
    sizeDist4Rejection: undefined,
    sizeDist5Name: "",
    sizeDist5Typical: undefined,
    sizeDist5Rejection: undefined,
    ashReducingInitialTypical: undefined,
    ashReducingInitialRejection: undefined,
    ashReducingSofteningTypical: undefined,
    ashReducingSofteningRejection: undefined,
    ashReducingHemisphericalTypical: undefined,
    ashReducingHemisphericalRejection: undefined,
    ashReducingFlowTypical: undefined,
    ashReducingFlowRejection: undefined,
    ashOxidizingInitialTypical: undefined,
    ashOxidizingInitialRejection: undefined,
    ashOxidizingSofteningTypical: undefined,
    ashOxidizingSofteningRejection: undefined,
    ashOxidizingHemisphericalTypical: undefined,
    ashOxidizingHemisphericalRejection: undefined,
    ashOxidizingFlowTypical: "3",
    ashOxidizingFlowRejection: undefined,
    traceArsenicBasis: undefined,
    traceArsenicTypical: undefined,
    traceArsenicRejection: undefined,
    traceMercuryBasis: undefined,
    traceMercuryTypical: undefined,
    traceMercuryRejection: undefined,
    traceSeleniumBasis: undefined,
    traceSeleniumTypical: undefined,
    traceSeleniumRejection: undefined,
    traceBoronBasis: undefined,
    traceBoronTypical: undefined,
    traceBoronRejection: undefined,
    minorBaBasis: undefined,
    minorBaTypical: undefined,
    minorBaRejection: undefined,
    minorPhosphorousBasis: undefined,
    minorPhosphorousTypical: undefined,
    minorPhosphorousRejection: undefined,
    otherCoalBulkDensityTypical: undefined,
    otherStowageFactorTypical: undefined,
    otherImsbcGroupTypical: undefined,
    otherGandYIndexTypical: undefined,
    otherTransmittanceValueTypical: undefined,
    otherMaxLumpSizeTypical: undefined,
    otherAngleMinimumTypical: undefined


};