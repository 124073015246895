import { Card, Col, Form, FormInstance, Input, Row } from 'antd'
import { FormLayout } from 'antd/lib/form/Form';
import React from 'react'

interface IOthers {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;

}

export const Other = (props: IOthers) => {
  const { formRef, initialValues, layoutType } = props;
  return (
    <Card>
      <Form
        layout={layoutType} form={formRef} initialValues={initialValues}
      >
        <Row  >
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Coal Bulk Density (t/cu.m)" name="otherCoalBulkDensityTypical"

            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Stowage Factor (cuf/t)" name="otherStowageFactorTypical"

            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="IMSBC Group (A,B,C)" name="otherImsbcGroupTypical"

            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
            <Form.Item label="G&Y Index" name="otherGandYIndexTypical"
              style={{ marginTop: "20px" }}
            >

              <Input />
            </Form.Item>
          </Col>



          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Transmittance Value (Pm)" name="otherTransmittanceValueTypical">
              <Input />
            </Form.Item>
          </Col >
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Max lump size (mm)" name="otherMaxLumpSizeTypical">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Angle of Repose" name="otherAngleMinimumTypical" style={{ marginLeft: "25px" }}>
              <Input placeholder='Minimum' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}
          >
            <Form.Item label="Angle of Repose" name="otherAngleMaximumTypical" style={{ marginLeft: "25px" }}>
              <Input placeholder='Maximum' />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}
          ></Col>

        </Row>


      </Form>
    </Card>
  )
}
export default Other;