import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { BusinessNumberService, ContractModesEnum, MarkupPriceBusinessReqDto, TaxDropDownDto, TaxesService, TaxTypeReqDto, TaxTypes } from '@exportx/shared-models-and-services';
import { Form, Input, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../common';

const { Option } = Select;
export const BargeCostingGridForm = (props) => {
  const { formatMessage: fm } = useIntl();
  const { authContext } = useAuthState();
  const { bargeCostData, form, add, remove, exchangeRate, businessNo, saleContract, setInitialValues } = props;
  const [barges, setBarges] = useState<any[]>([]);
  const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([]);

  const businessNumberService = new BusinessNumberService();
  const taxesService = new TaxesService();

  useEffect(() => {
    getAllActiveVatDropDown();
  }, []);

  useEffect(() => {
    if (exchangeRate) getTotal();
  }, [exchangeRate])

  useEffect(() => {
    if (businessNo && saleContract) {
      const req = new MarkupPriceBusinessReqDto(businessNo, authContext.defaultPlant, saleContract, undefined, 'Barge Costing');
      businessNumberService.getCostedBargeBySalesContractAndBusinessNo(req).then(res => {
        if (res.status) {
          setBarges(res.data);
        }
      }).catch(err => {
        console.log(err.message);
      })

    }
  }, [businessNo, saleContract])

  const getAllActiveVatDropDown = () => {
    const req = new TaxTypeReqDto();
    req.taxType = TaxTypes.VAT;
    taxesService.getTaxesByCategory(req).then(res => {
      if (res.status) {
        setTaxDropDown(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getTotal = () => {



    bargeCostData?.forEach(({ name }, index) => {
      const quantity = form.getFieldValue(['bargeCostData', name, 'quantity']) ? form.getFieldValue(['bargeCostData', name, 'quantity']) : 0;
      const priceIdr = form.getFieldValue(['bargeCostData', name, 'priceIdr']) ? form.getFieldValue(['bargeCostData', name, 'priceIdr']) : 0;
      const total = (quantity * priceIdr)?.toFixed(2);
      let priceUsd = (Number(total ?? 0) / Number(quantity ?? 0) / Number(exchangeRate ?? 1)); 
      if(isNaN(priceUsd)){
          priceUsd = 0;
      }   
      const vatPercent = form.getFieldValue(['bargeCostData', name, 'vatPercent']) ? form.getFieldValue(['bargeCostData', name, 'vatPercent']) : 0;
      const includeVat = (+total * Number(vatPercent ?? 1)/100) + Number(total);
      // const priceAfterVat = priceIdr ? priceIdr / ((vatPercent / 100) + 1) : 0;

      form.setFieldValue(['bargeCostData', name, 'total'], total);
      form.setFieldValue(['bargeCostData', name, 'priceAftExchange'], includeVat);
      form.setFieldValue(['bargeCostData', name, 'priceUsd'], priceUsd?.toFixed(2));

    })
    setInitialValues(prev => ({ ...prev, bargeCostData: form.getFieldValue('bargeCostData')?.length > 0 ? form.getFieldValue('bargeCostData') : [''] }))


  }

  const buyerOnChangeHandler = (value, key) => {
    const barge = barges.find(el => el.bargeId === value);
    form.setFieldValue(['bargeCostData', key, 'bargeId'], value);
    form.setFieldValue(['bargeCostData', key, 'quantity'], barge?.bargeQuantity);
    form.setFieldValue(['bargeCostData', key, 'priceIdr'], barge?.bargeBasePrice);
    getTotal();
  }
  const onVatChange = (value, key) => {
    form.setFieldValue(['bargeCostData', key, 'vatId'], value);
    const vat = taxDropDown.find(el => el.taxId === value)?.taxPercentage ?? 0;
    form.setFieldValue(['bargeCostData', key, 'vatPercent'], vat);
    getTotal();
  }

  return (
    <>
      {
        React.Children.toArray(bargeCostData.map((field, index) => (
          <tr key={field.name}>
            <td className='table-form'>
              <Form.Item
                key={field.key}
                name={[field.name, "bargeId"]}>
                <Select
                  showSearch
                  placeholder='Select Barge'
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => buyerOnChangeHandler(e, field.name)}
                >
                  {barges?.map((el) => {
                    return <Option value={el.bargeId}>{`${el.bargeNomination} - (${el.bargeNo})`}</Option>
                  })
                  }
                </Select>
              </Form.Item>
            </td>
            <td className='table-form'>
              <Form.Item
                key={field.key}
                name={[field.name, "quantity"]} >
                <Input type='number' placeholder='Enter Quantity' disabled />
              </Form.Item>
            </td>
            <td className='table-form'>
              <Form.Item 
                name={[field.name, "priceIdr"]}>
                <Input type='number' placeholder='Enter' disabled />
              </Form.Item>
            </td>
           
            <td className='table-form'>
              <Form.Item 
                name={[field.name, "total"]}>
                <Input type='number' disabled />
              </Form.Item>
            </td>
            <td className='table-form'>
              <Form.Item
                key={field.key}
                name={[field.name, "vatId"]}>
                <Select
                  showSearch
                  placeholder='Please  Select'
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => onVatChange(e, field.name)}
                >
                  <Option key={0} value={null}>Select Vat</Option>
                  {taxDropDown.map((rec) => {
                    return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} (${rec.taxPercentage})`}</Option>
                  })}
                </Select>
              </Form.Item>
              <Form.Item  style={{ display: 'none' }}
                name={[field.name, "vatPercent"]}>
                <Input type='number' placeholder='Enter' />
              </Form.Item>
            </td>
            <td className='table-form'>
              <Form.Item 
                name={[field.name, "priceAftExchange"]}>
                <Input type='number' disabled />
              </Form.Item>
            </td>
            <td className='table-form'>
              <Form.Item 
                name={[field.name, "priceUsd"]}>
                <Input type='number' disabled />
              </Form.Item>
            </td>



            <td className='text-center'>
              {(bargeCostData.length - 1 === index && (bargeCostData.length < barges?.length)) && <span>
                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                  <PlusCircleOutlined style={{ fontSize: '21px' }} onClick={() => add()} />
                </Tooltip>
              </span>}
              {index !== 0 && <span>
                <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                  <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                    remove(field.name);
                  }} />
                </Tooltip>
              </span>}
            </td>
          </tr>
        )))
      }
    </>
  )
}

export default BargeCostingGridForm