import { UploadOutlined } from '@ant-design/icons';
import { CoalIndexedPriceService, ColaIndexTypeEnum, ProrateGradeIndexes, SpecificationsEnum } from '@exportx/shared-models-and-services';
import { ClassValidator, getBase64 } from '@exportx/ui-utils';
import { Button, Card, Col, Form, Input, InputNumber, Row, Upload, } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../../common';
import DatePicker from '../../../../../common/data-picker/date-picker';

interface ICoalIndexFormProps {
    coalIndexType: ColaIndexTypeEnum,
    saveCallBack: () => void
}
const classValidator = new ClassValidator();
export const CoalIndexPricesForm = (props: ICoalIndexFormProps) => {
    const { authContext } = useAuthState();
    const [fileList, setFileList] = useState([]);
    const { coalIndexType, saveCallBack } = props;
    const { formatMessage: fm } = useIntl();
    const [formRef] = Form.useForm();

    const service = new CoalIndexedPriceService();

    const getDisabledDates = (date: moment.Moment) => {
        if (coalIndexType === ColaIndexTypeEnum.WEEKLY) {
            return date.isoWeekday() !== 5;
        }
    }

    const submitHandler = () => {
        formRef.validateFields().then(formValues => {
            getBase64(formValues.filesData.file.originFileObj).then(base64 => {
                formValues.filesData = [{
                    ...formValues.filesData.file,
                    base64Url: base64,
                    fileDescription: 'ICI file'
                }];
                formValues.date = coalIndexType === ColaIndexTypeEnum.WEEKLY ? moment(formValues.date).format('YYYY-MM-DD') : moment(formValues.date).endOf('month').format('YYYY-MM-DD');
                const requestObject = { coalIndexType, ...formValues, createdUser: authContext.user.userName };
                saveCoalIndexPrices(requestObject);
                // saveMonthly(requestObject)
            });
        })
    }

    const saveCoalIndexPrices = (createData: any) => {
        service.createCoalIndexPrice(createData).then(res => {
            if (res.status) {
                saveCallBack();
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const saveMonthly = (createData: any) => {
        service.monthlyAvgCoalIndexPrice(createData).then(res => {
            if (res.status) {
                saveCallBack();
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }
    const uploadFieldProps = {
        multiple: false,
        onRemove: file => {
            setFileList([]);
        },
        beforeUpload: (file: any) => {
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = data => {
                if (fileList.length === 1) {
                    AlertMessages.getErrorMessage(fm({ id: "pps.components.productionPlanUploadForm.oneFileOnlyValidation", defaultMessage: "You Cannot Upload More Than One File At A Time" }));
                    return true;
                } else {
                    setFileList([...fileList, file]);
                    return false;
                }
            }
        },
        fileList: fileList
    }

    return (
        <Form layout="vertical" form={formRef} >
            <Card>
                <Row justify='space-evenly'>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Form.Item
                            name="date"
                            label={coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'Week Ending' : 'Month'}
                            rules={
                                [
                                    { required: true, message: '' },

                                ]
                            }
                        >
                            <DatePicker disabledDate={getDisabledDates} style={{ width: '100%' }} picker={coalIndexType === ColaIndexTypeEnum.MONTHLY ? "month" : undefined} />
                        </Form.Item>
                    </Col>
                    {ProrateGradeIndexes.garIndexes.map((garIndex, index) => {
                        return <Col
                            xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}
                        >
                            <Form.Item
                                label={`${garIndex}  ${SpecificationsEnum.GAR} (${ProrateGradeIndexes.narIndexes[index]}  ${SpecificationsEnum.NAR})`}
                                name={`gar${garIndex}`}
                                rules={
                                    [
                                        {
                                            required: true, message: ''
                                        },
                                        //...classValidator.onlyNumeric()
                                    ]}
                            >
                                <Input type='number' placeholder={`PMT in USD`} autoComplete='off' />
                            </Form.Item>
                        </Col>
                    })
                    }
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }}>
                        <Form.Item
                            name="filesData"
                            label='ICI file'
                            rules={
                                [
                                    { required: true, message: '' },

                                ]
                            }
                        >
                            <Upload {...uploadFieldProps}>
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

                <Row align='middle' justify='center'><Col><Button type='primary' onClick={submitHandler}>Submit</Button></Col></Row>
            </Card>
        </Form>
    )
}

export default CoalIndexPricesForm;