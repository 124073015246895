import { EyeOutlined } from '@ant-design/icons';
import { BargeRequest, BargeService, ContractModesEnum } from '@exportx/shared-models-and-services';
import { Col, Drawer, Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../common';
import BargeSamplingReport from '../../../sampling-and-analysis/purchasequality/reports/barge-sampling-report';

interface qualityIProps {
    bargeId?: any;
    bgId: string;
}

const ContractQualityResults = (props: qualityIProps) => {
    const { bargeId, bgId } = props
    const bargeService = new BargeService()
    const [pcQualityResults, setPcQualityResults] = useState<any>();
    const [scQualityResults, setScQualityResults] = useState<any>();
    const { authContext } = useAuthState()
    const [drawerState, setDrawerState] = useState<boolean>(false);
    const [contractType, setContractType] = useState<ContractModesEnum>();
    const [dummyRefresh, setDummyRefresh] = useState<number>(1);
    const [bargeScq, setBargeScq] = useState<any>()


    useEffect(() => {
        if (bargeId) {
            bargePcFinQualityResults();
            bargeSalesQualityResults()
        }
    }, [bargeId])

    const reqBargeId = [bargeId]

    const bargePcFinQualityResults = () => {
        const req = new BargeRequest(bargeId, authContext.defaultPlant)
        bargeService.bargePcFinQualityResults(req).then(res => {
            if (res.status) {
                setPcQualityResults(res.data);
            }
        }).catch(err => console.log(err.message))
    }

    const bargeSalesQualityResults = () => {
        const req = new BargeRequest(bargeId, authContext.defaultPlant)
        bargeService.bargeSalesQualityResults(req).then(res => {
            if (res.status) {
                setScQualityResults(res.data)
            }
        }).catch(err => console.log(err.message))
    }

    const pcColumns: ColumnsType<any> = [
        {
            title: "Purchase Quality Results ",
            dataIndex: 'pcqResultsId',
            align: 'center',
            render: (value, record) => {
                return <>
                    <span>{" Quality Results "}
                        <Tooltip placement="top" title={'View Quality Results'}>
                            <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }} onClick={e => {
                                setDrawerState(true);
                                setContractType(ContractModesEnum.PURCHASE);
                                setDummyRefresh(prev => prev + 1);
                            }} />
                        </Tooltip>

                    </span>
                </>
            }
        }
    ]

    const scColumns: ColumnsType<any> = [
        {
            title: "Sales Quality Results ",
            dataIndex: 'pcqResultsId',
            align: 'center',
            render: (value, record) => {
                return <>
                    <span>{" Quality Results "}
                        <Tooltip placement="top" title={'View Quality Results'}>
                            <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }} onClick={e => {
                                setDrawerState(true);
                                setContractType(ContractModesEnum.SALES);
                                setDummyRefresh(prev => prev + 1);
                                setBargeScq(record);
                            }} />
                        </Tooltip>

                    </span>
                </>
            }
        }
    ]
 
    return (
        <>
            <Row>
                <Col span={8}>
                    <Table columns={pcColumns} pagination={false} dataSource={pcQualityResults}></Table>
                </Col>
                <Col span={6}>
                </Col>
                <Col span={8}>
                    <Table columns={scColumns} pagination={false} dataSource={scQualityResults}></Table>
                </Col>
            </Row>
            <Drawer
                title={`BARGE ${contractType} QUALITY RESULTS - 00${bgId}`}
                open={drawerState}
                onClose={() => {
                    setDrawerState(false);
                    setDummyRefresh(prev => prev + 1);
                }}
                footer={null}
                width={900}
            >
                <BargeSamplingReport key={dummyRefresh} bargeId={reqBargeId} contractQuality={'contract'} bargeScq={bargeScq} contractType={contractType} />
            </Drawer>

        </>
    )
}

export default ContractQualityResults