import { Bar } from "@ant-design/plots";
import { useEffect, useState } from "react";

export interface IHorizontalBarChartProps {
    chartData: any[];
}
export const HorizontalBarChart = (props: IHorizontalBarChartProps) => {
    const { chartData } = props;
    const [data, setData] = useState([]);
    
    useEffect(() => {
        setData(chartData);
    }, [chartData.length]);

    const cfg: any = {
        data,
        xField: 'value',
        yField: 'year',
        seriesField: 'year',
        barWidthRatio: 0.3,
        style: {
            autoFit: true,
        },
        legend: {
            position: 'top-left',
        },
    };
    const config = cfg;
    return (
        <Bar {...config} />
    )
}

export default HorizontalBarChart