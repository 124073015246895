import { BasisDropDownDto, BasisService } from '@exportx/shared-models-and-services';
import { Card, Col, Form, FormInstance, Input, Row, Select } from 'antd'
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'
interface ITypicalProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}



export const Typical = (props: ITypicalProps) => {
  const { formRef, initialValues, layoutType } = props;
  const { Option } = Select;
  const basisService = new BasisService();
  const [basisData, setBasisData] = useState<BasisDropDownDto[]>([]);
  useEffect(() => {
    getCBasisData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getCBasisData = () => {
    basisService.getAllBasisDropDownDto().then(res => {
      if (res.status) {
        setBasisData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  return (
    <Card >
      <Form layout={layoutType} form={formRef} initialValues={initialValues}>
        <Form.Item name={"table1id"} style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Form.Item name={"table2id"} style={{ display: 'none' }}>
          <Input hidden />
        </Form.Item>
        <Row>
          <Col><h3>Gross Calorific Value(Kcal/kg)</h3></Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name="calorificBasis"
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='calorificTypical'>
              <Input type="number" min="0" value="10" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='calorificRejection'>
              <Input type="number" min="0" value="10" placeholder='Rejection' />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='calorificBonusCap'>
              <Input type="number" min="0" value="10" placeholder='Bonus Cap' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item
              name="calorificPenaltyCap"  >
              <Input type="number" min="0" value="10" placeholder='Penalty Cap' style={{ width: "120px" }} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}
          ></Col>
        </Row>




        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name="calorificBasis1"
            >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='calorificTypical1'>
              <Input type="number" min="0" value="10" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='calorificRejection1'>
              <Input type="number" min="0" value="10" placeholder='Rejection' />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='calorificBonusCap1'>
              <Input type="number" min="0" value="10" placeholder='Bonus Cap' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item
              name="calorificPenaltyCap1"  >
              <Input type="number" min="0" value="10" placeholder='Penalty Cap' style={{ width: "120px" }} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}
          ></Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name="calorificBasis2"
            >


              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='calorificTypical2'>
              <Input type="number" min="0" value="10" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='calorificRejection2'>
              <Input type="number" min="0" value="10" placeholder='Rejection' />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='calorificBonusCap2'>
              <Input type="number" min="0" value="10" placeholder='Bonus Cap' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item
              name="calorificPenaltyCap2"  >
              <Input type="number" min="0" value="10" placeholder='Penalty Cap' style={{ width: "120px" }} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}
          ></Col>
        </Row>




        <Row>
          <Col><h3>Total Moisture (%)</h3></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name="totalMoistureBasis">
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name="totalMoistureTypical">
              <Input type="text" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name="totalMoistureRejection">
              <Input type="text" placeholder='Rejection' />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col><h3>Inherent Moisture(%)</h3></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='inherentMoistureBasis'>
              <Input value="ADB"></Input>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='inherentMoistureTypical'>
              <Input type="text" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }} >
            <Form.Item name='inherentMoistureRejection'>
              <Input type="text" placeholder='Rejection' />
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col><h3>Ash (%)</h3></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='ashBasis'>
              <Input value="ADB"></Input>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='ashTypical'>
              <Input type="number" min="0" value="10" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='ashRejection'>
              <Input type="number" min="0" value="10" placeholder='Rejection' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='ashPenalty'>
              <Input type="number" min="0" value="10" placeholder='Penalty' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='ashRejectionPenalty'>
              <Input type="number" min="0" value="10" placeholder='Rejection Penalty' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}
          ></Col>
        </Row>
        <Row>
          <Col><h3>Volatile Matter (%)</h3></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='volatileMatterBasis'>
              <Input value="ADB"></Input>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }} >
            <Form.Item name='volatileMatterTypical'>
              <Input type="text" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='volatileMatterRejection'>
              <Input type="text" placeholder='Rejection' />
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col><h3>Fixed Carbon (%)</h3></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='fixedCarbonBasis'>
              <Input value="ADB"></Input>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='fixedCarbonTypical'>
              <Input type="text" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='fixedCarbonRejection'>
              <Input type="text" placeholder='Rejection' />
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col><h3>Fuel Ratio(FC/VM)</h3></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='fuelRatioBasis'>
              <Input value="ADB"></Input>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='fuelRatioTypical'>
              <Input type="text" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='fuelRatioRejection'>
              <Input type="text" placeholder='Rejection' />
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col><h3>Total Sulphur(%)</h3></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='totalSulphurBasis'>
              <Input value="ADB"></Input>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='totalSulphurTypical'>
              <Input type="number" min="0" value="10" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='totalSulphurRejection'>
              <Input type="number" min="0" value="10" placeholder='Rejection' />
            </Form.Item>
          </Col>


          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='totalSulphurPenalty'>
              <Input type="number" min="0" value="10" placeholder='Penalty' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='totalSulphurRejectionPenalty'>
              <Input type="number" min="0" value="10" placeholder='Rejection Penalty' />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}
          ></Col>

        </Row>
        <Row>
          <Col><h3>HGI</h3></Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }} >
            <Form.Item name='hgiUnitsTypical'>
              <Input type="text" placeholder='Typical' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}>
            <Form.Item name='hgiUnitsRejection'>
              <Input type="text" placeholder='Rejection' />
            </Form.Item></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 3, offset: 1 }}></Col>

        </Row>
      </Form>
    </Card>

  )
}

export default Typical;