import { ContractModesEnum, SamplingTypes } from "@exportx/shared-models-and-services";
import { Card } from "antd";
import ActualReport from "../../purchasequality/reports/actual-report";

interface ISupplierProps {
  contractType: ContractModesEnum;
  sampleType:SamplingTypes
} 

const SupplierReports = (props: ISupplierProps) => {
  return (
    <Card title={`${props.contractType === ContractModesEnum.SALES ? "S&A - Sales" : "P&A - Purchase"}`}>
      <ActualReport contractType={ContractModesEnum.SALES} key={ContractModesEnum.SALES + "S&A - Sales"} sampleType={props.sampleType} />
    </Card>

  )
}

export default SupplierReports;