import { Card } from "antd";
import { HorizontalBarChart } from "../../common/charts/horizontal-bar";

export const TopFiveCustomersBySalesChart = () => {
    const data = [
        {
            year: 'TARANJOT RESOURCES PVT LTD',
            value: 38,
        },
        {
            year: 'PVM OIL ASSOCIATES PTE LTD',
            value: 52,
        },
        {
            year: 'SMARTGEN INFRA PTE LTD',
            value: 61,
        },
        {
            year: 'ROYAL EASTERN PTE LTD',
            value: 145,
        },
        {
            year: 'MAHALAXMI ASSOCIATES PVT LTD',
            value: 48,
        },
    ];
    return <>
        <Card title='Top Five Customers by Sales'>
            <HorizontalBarChart chartData={data} />
        </Card>
    </>
}