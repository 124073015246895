import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const ContractsSelection = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    purchase_contract: [],
    purchase_transfer: [],
    sales_contract: [],
    sales_transfer: [],
    weekly: [],
    price_calculation: [],
    monthly: [],
    barge_agreement: [],
    procurement_service: [],
    coal_service_fees: [],
    marketing_fees: [],
    vendor_advance: [],
    vendor_advance_invoices: [],
    vendor_advance_payments: [],
    down_payment: [],
    down_payment_invoices: [],
    down_payment_payments: [],
    advance_against_contracts: [],
    advance_against_contracts_invoices: [],
    advance_against_contracts_payments: [],
    advance_receivables: [],
    advance_receovables_invoices: [],
    advance_receivables_payments: [],

  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          sales_contract: features?.filter((cou) => 95 <= +cou && +cou <= 107)?.map(Number),
          sales_transfer: features?.filter((cou) => 108 <= +cou && +cou <= 109)?.map(Number),
          purchase_contract: features?.filter((cou) => 110 <= +cou && +cou <= 122)?.map(Number),
          purchase_transfer: features?.filter((cou) => 123 <= +cou && +cou <= 124)?.map(Number),
          weekly: features?.filter((cou) => 125 <= +cou && +cou <= 128)?.map(Number),
          price_calculation: features?.filter((cou) => 129 <= +cou && +cou <= 132)?.map(Number),
          monthly: features?.filter((cou) => 133 <= +cou && +cou <= 136)?.map(Number),
          barge_agreement: features?.filter((cou) => 137 <= +cou && +cou <= 145)?.map(Number),
          procurement_service: features?.filter((cou) => 146 <= +cou && +cou <= 154)?.map(Number),
          coal_service_fees: features?.filter((cou) => 155 <= +cou && +cou <= 163)?.map(Number),
          marketing_fees: features?.filter((cou) => 164 <= +cou && +cou <= 172)?.map(Number),
          vendor_advance: features?.filter((cou) => 173 <= +cou && +cou <= 180)?.map(Number),
          vendor_advance_invoices: features?.filter((cou) => 181 <= +cou && +cou <= 188)?.map(Number),
          vendor_advance_payments: features?.filter((cou) => 189 <= +cou && +cou <= 192)?.map(Number),
          down_payment: features?.filter((cou) => 193 <= +cou && +cou <= 200)?.map(Number),
          down_payment_invoices: features?.filter((cou) => 201 <= +cou && +cou <= 208)?.map(Number),
          down_payment_payments: features?.filter((cou) => 209 <= +cou && +cou <= 212)?.map(Number),
          advance_against_contracts: features?.filter((cou) => 213 <= +cou && +cou <= 220)?.map(Number),
          advance_against_contracts_invoices: features?.filter((cou) => 221 <= +cou && +cou <= 228)?.map(Number),
          advance_against_contracts_payments: features?.filter((cou) => 229 <= +cou && +cou <= 232)?.map(Number),
          advance_receivables: features?.filter((cou) => 233 <= +cou && +cou <= 240)?.map(Number),
          advance_receovables_invoices: features?.filter((cou) => 241 <= +cou && +cou <= 248)?.map(Number),
          advance_receivables_payments: features?.filter((cou) => 249 <= +cou && +cou <= 252)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])


  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({ ...prev, [name]: value }))
    }
    else setValues((prev) => ({ ...prev, [name]: [] }));
  }

  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}

    >
      <Card size="small" title='Purchase'>
        <Card size="small" type="inner" title="Purchase Contract"
          extra={<Checkbox
            name="purchase_contract"
            indeterminate={values.purchase_contract.length > 0 && values.purchase_contract.length < 13}
            checked={values.purchase_contract.length === 13}
            onChange={(e) => onChange(e, 'purchase_contract', [110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122])}
          >
            <b>Select All</b>
          </Checkbox>}>

          <Form.Item name="purchase_contract" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, purchase_contract: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={110}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={111}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={112}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={113}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={114}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={115}>Clone</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={116}>Addendum</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={117}>Transfer</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={118}>Freeze</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={119}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={120}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={121}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={122}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Purchase Transfer"
          extra={<Checkbox
            name="purchase_transfer"
            indeterminate={values.purchase_transfer.length > 0 && values.purchase_transfer.length < 2}
            checked={values.purchase_transfer.length === 2}
            onChange={(e) => onChange(e, 'purchase_transfer', [123, 124])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="purchase_transfer" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, purchase_transfer: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={123}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={124}>Approve</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>

      </Card>

      <br />
      <Card size="small" title='Sales'>

        <Card size="small" type="inner" title="Sales Contract"
          extra={<Checkbox
            name="sales_contract"
            indeterminate={values.sales_contract.length > 0 && values.sales_contract.length < 13}
            checked={values.sales_contract.length === 13}
            onChange={(e) => onChange(e, 'sales_contract', [95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="sales_contract" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, sales_contract: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={95}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={96}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={97}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={98}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={99}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={100}>Clone</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={101}>Addendum</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={102}>Transfer</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={103}>Freeze</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={104}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={105}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={106}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={107}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Sales Transfer"
          extra={<Checkbox
            name="sales_transfer"
            indeterminate={values.sales_transfer.length > 0 && values.sales_transfer.length < 2}
            checked={values.sales_transfer.length === 2}
            onChange={(e) => onChange(e, 'sales_transfer', [108, 109])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="sales_transfer" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, sales_transfer: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={108}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={109}>Approve</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
      </Card>
      <br />
      <Card size="small" title='Coal Price Indexes'>

        <Card size="small" type="inner" title="Weekly"
          extra={<Checkbox
            name="weekly"
            indeterminate={values.weekly.length > 0 && values.weekly.length < 4}
            checked={values.weekly.length === 4}
            onChange={(e) => onChange(e, 'weekly', [125, 126, 127, 128])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="weekly" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, weekly: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={125}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={126}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={127}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={128}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Price Calculation"
          extra={<Checkbox
            name="price_calculation"
            indeterminate={values.price_calculation.length > 0 && values.price_calculation.length < 4}
            checked={values.price_calculation.length === 4}
            onChange={(e) => onChange(e, 'price_calculation', [129, 130, 131, 132])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="price_calculation" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, price_calculation: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={129}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={130}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={131}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={132}>Files</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <Card size="small" type="inner" title="Monthly"
          extra={<Checkbox
            name="monthly"
            indeterminate={values.monthly.length > 0 && values.monthly.length < 4}
            checked={values.monthly.length === 4}
            onChange={(e) => onChange(e, 'monthly', [133, 134, 135, 136])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="monthly" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, monthly: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={133}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={134}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={135}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={136}>Files</Checkbox>
                </Col>

              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
      </Card>
      <br />

      <Card size="small" title="Barging Agreeent"
        extra={<Checkbox
          name="barge_agreement"
          indeterminate={values.barge_agreement.length > 0 && values.barge_agreement.length < 9}
          checked={values.barge_agreement.length === 9}
          onChange={(e) => onChange(e, 'barge_agreement', [137, 138, 139, 140, 141, 142, 143, 144, 145])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="barge_agreement" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, barge_agreement: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={137}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={138}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={139}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={140}>Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={141}>View Price</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={142}>Approve</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={143}>Reject</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={144}>Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={145}>Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title='Service Contracts'>

        <Card size="small" type="inner" title="Procurement Service"
          extra={<Checkbox
            name="procurement_service"
            indeterminate={values.procurement_service.length > 0 && values.procurement_service.length < 9}
            checked={values.procurement_service.length === 9}
            onChange={(e) => onChange(e, 'procurement_service', [146, 147, 148, 149, 150, 151, 152, 153, 154])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="procurement_service" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, procurement_service: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={146}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={147}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={148}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={149}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={150}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={151}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={152}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={153}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={154}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="Coal Service Fees"
          extra={<Checkbox
            name="coal_service_fees"
            indeterminate={values.coal_service_fees.length > 0 && values.coal_service_fees.length < 9}
            checked={values.coal_service_fees.length === 9}
            onChange={(e) => onChange(e, 'coal_service_fees', [155, 156, 157, 158, 159, 160, 161, 162, 163])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="coal_service_fees" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, coal_service_fees: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={155}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={156}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={157}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={158}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={159}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={160}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={161}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={162}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={163}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
        <br />
        <Card size="small" type="inner" title="Marketing Fees"
          extra={<Checkbox
            name="marketing_fees"
            indeterminate={values.marketing_fees.length > 0 && values.marketing_fees.length < 9}
            checked={values.marketing_fees.length === 9}
            onChange={(e) => onChange(e, 'marketing_fees', [164, 165, 166, 167, 168, 169, 170, 171, 172])}
          >
            <b>Select All</b>
          </Checkbox>}
        >
          <Form.Item name="marketing_fees" style={{ marginBottom: 0 }}>
            <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, marketing_fees: value }))}>
              <Row>
                <Col span={6}>
                  <Checkbox value={164}>Add</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={165}>View</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={166}>Update</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={167}>Delete</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={168}>View Price</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={169}>Approve</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={170}>Reject</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={171}>Excel</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value={172}>Files</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Card>
      </Card>
      <br />
      <Card size="small" title='Advances'>

        <Card size="small" title='Vendor Advance'>
          <Card size="small" type="inner" title="Vendor Advance"
            extra={<Checkbox
              name="vendor_advance"
              indeterminate={values.vendor_advance.length > 0 && values.vendor_advance.length < 8}
              checked={values.vendor_advance.length === 8}
              onChange={(e) => onChange(e, 'vendor_advance', [173, 174, 175, 176, 177, 178, 179, 180])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="vendor_advance" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, vendor_advance: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={173}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={174}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={175}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={176}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={177}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={178}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={179}>Excel</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={180}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Vendor Advance Invoices"
            extra={<Checkbox
              name="vendor_advance_invoices"
              indeterminate={values.vendor_advance_invoices.length > 0 && values.vendor_advance_invoices.length < 8}
              checked={values.vendor_advance_invoices.length === 8}
              onChange={(e) => onChange(e, 'vendor_advance_invoices', [181, 182, 183, 184, 185, 186, 187, 188])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="vendor_advance_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, vendor_advance_invoices: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={181}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={182}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={183}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={184}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={185}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={186}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={187}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={188}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Vendor Advance Payments"
            extra={<Checkbox
              name="vendor_advance_payments"
              indeterminate={values.vendor_advance_payments.length > 0 && values.vendor_advance_payments.length < 4}
              checked={values.vendor_advance_payments.length === 4}
              onChange={(e) => onChange(e, 'vendor_advance_payments', [189, 190, 191, 192])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="vendor_advance_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, vendor_advance_payments: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={189}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={190}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={191}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={192}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Card>
        <br />
        <Card size="small" title='Down Payment'>

          <Card size="small" type="inner" title="Down Payment"
            extra={<Checkbox
              name="down_payment"
              indeterminate={values.down_payment.length > 0 && values.down_payment.length < 8}
              checked={values.down_payment.length === 8}
              onChange={(e) => onChange(e, 'down_payment', [193, 194, 195, 196, 197, 198, 199, 200])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="down_payment" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, down_payment: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={193}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={194}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={195}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={196}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={197}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={198}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={199}>Excel</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={200}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Down Payment Invoices"
            extra={<Checkbox
              name="down_payment_invoices"
              indeterminate={values.down_payment_invoices.length > 0 && values.down_payment_invoices.length < 8}
              checked={values.down_payment_invoices.length === 8}
              onChange={(e) => onChange(e, 'down_payment_invoices', [201, 202, 203, 204, 205, 206, 207, 208])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="down_payment_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, down_payment_invoices: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={201}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={202}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={203}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={204}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={205}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={206}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={207}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={208}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Down Payment Payments"
            extra={<Checkbox
              name="down_payment_payments"
              indeterminate={values.down_payment_payments.length > 0 && values.down_payment_payments.length < 4}
              checked={values.down_payment_payments.length === 4}
              onChange={(e) => onChange(e, 'customers', [209, 210, 211, 212])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="down_payment_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, down_payment_payments: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={209}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={210}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={211}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={212}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Card>
        <br />
        <Card size="small" title='Advance Against Contracts'>

          <Card size="small" type="inner" title="Advance Against Contracts"
            extra={<Checkbox
              name="advance_against_contracts"
              indeterminate={values.advance_against_contracts.length > 0 && values.advance_against_contracts.length < 8}
              checked={values.advance_against_contracts.length === 8}
              onChange={(e) => onChange(e, 'advance_against_contracts', [213, 214, 215, 216, 217, 218, 219, 220])}
            >
              <b>Select All</b>
            </Checkbox>}>

            <Form.Item name="advance_against_contracts" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, advance_against_contracts: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={213}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={214}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={215}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={216}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={217}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={218}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={219}>Excel</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={220}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Advance Against Contracts Invoices"
            extra={<Checkbox
              name="advance_against_contracts_invoices"
              indeterminate={values.advance_against_contracts_invoices.length > 0 && values.advance_against_contracts_invoices.length < 8}
              checked={values.advance_against_contracts_invoices.length === 8}
              onChange={(e) => onChange(e, 'advance_against_contracts_invoices', [221, 222, 223, 224, 225, 226, 227, 228])}
            >
              <b>Select All</b>
            </Checkbox>}>

            <Form.Item name="advance_against_contracts_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, advance_against_contracts_invoices: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={221}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={222}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={223}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={224}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={225}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={226}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={226}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={228}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Advance Against Contracts payments"
            extra={<Checkbox
              name="advance_against_contracts_payments"
              indeterminate={values.advance_against_contracts_payments.length > 0 && values.advance_against_contracts_payments.length < 4}
              checked={values.advance_against_contracts_payments.length === 4}
              onChange={(e) => onChange(e, 'advance_against_contracts_payments', [229, 230, 231, 232])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="advance_against_contracts_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, advance_against_contracts_payments: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={229}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={230}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={231}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={232}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Card>
        <br />
        <Card size="small" title='Advance Receivables'>

          <Card size="small" type="inner" title="Advance Receivables"
            extra={<Checkbox
              name="advance_receivables"
              indeterminate={values.advance_receivables.length > 0 && values.advance_receivables.length < 8}
              checked={values.advance_receivables.length === 8}
              onChange={(e) => onChange(e, 'advance_receivables', [233, 234, 235, 236, 237, 238, 239, 240])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="advance_receivables" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, advance_receivables: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={233}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={234}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={235}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={236}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={237}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={238}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={239}>Excel</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={240}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Advance Receivables Invoices"
            extra={<Checkbox
              name="advance_receovables_invoices"
              indeterminate={values.advance_receovables_invoices.length > 0 && values.advance_receovables_invoices.length < 8}
              checked={values.advance_receovables_invoices.length === 8}
              onChange={(e) => onChange(e, 'advance_receovables_invoices', [241, 242, 243, 244, 245, 246, 247, 248])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="advance_receovables_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, advance_receovables_invoices: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={241}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={242}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={243}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={244}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={245}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={246}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={247}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={248}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Advance Receivables payments"
            extra={<Checkbox
              name="advance_receivables_payments"
              indeterminate={values.advance_receivables_payments.length > 0 && values.advance_receivables_payments.length < 4}
              checked={values.advance_receivables_payments.length === 4}
              onChange={(e) => onChange(e, 'advance_receivables_payments', [249, 250, 251, 252])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="advance_receivables_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, advance_receivables_payments: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={249}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={250}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={251}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={252}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Card>
      </Card>
    </Form>
  )
}