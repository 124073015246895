import { CostingTypesEnum } from '@exportx/shared-models-and-services';
import { Affix, Button, Card, Col, Form, Row, Steps } from 'antd';
import React, { useState } from 'react'
import { AlertMessages } from '../../../../common';
import BGCoalCostingsForm from './bg-coal-costings-form';
import CoalCostingBgSelectForm from './coal-costing-bg-select-form';
import './costings-selection.css';


interface ICoalCostingStepper {
    cancelHandler: () => void;
    coalCostingType: CostingTypesEnum;
}

const start = 0;
const end = 1;
const CoalCostingStepper = (props: ICoalCostingStepper) => {
    const { cancelHandler, coalCostingType } = props;
    const [selectedRowKeysData, setSelectedRowKeysData] = useState<any[]>([]);
    const [current, setCurrent] = useState(0);
    const { Step } = Steps
    const [bgCoalCostingFormRef] = Form.useForm()
    const [coalCostingBgFormSelectFormRef] = Form.useForm()

    const submitButtonHandler = () => {

    }



    const nextStepHandler = () => {
        if (selectedRowKeysData.length) {
            setCurrent(cur => {
                if (cur + 1 <= end) {
                    return cur + 1;
                } else {
                    return cur;
                }
            });

        } else {
            AlertMessages.getErrorMessage('Please select Barge')
        }
        if (current == end) {
            submitButtonHandler()
        }
    }

    const prevStepHandler = () => {
        setCurrent(cur => {
            if (cur - 1 == start || start < cur - 1) {
                return cur - 1;
            } else {
                return cur;
            }

        });
        if (current == start) {
            cancelHandler()
        }
    }
    return (
        <Card
            style={{ height: '90vh', width: '100%', top: 0, bottom: 0 }}
            bodyStyle={{ minHeight: '80vh', overflowY: 'scroll' }}
            actions={[
                <Row justify='space-around'>
                    <Col><Button onClick={() => { prevStepHandler(); }}>{current == 1 ? 'Previous' : 'Cancel'}</Button></Col>
                    <Col>
                        <Button onClick={() => { nextStepHandler() }} type="primary">
                            {current == 1 ? 'Submit' : 'Next Step'}
                        </Button>
                    </Col>
                </Row>
            ]}>
            <Steps
                type="navigation"
                size="small"
                current={current}
                className="site-navigation-steps"
            >
                <Step title="Barge Selection" key="Barge Selection" />
                <Step title="Costing" key="Costing" />
            </Steps>
            {current === 0 && <CoalCostingBgSelectForm formRef={coalCostingBgFormSelectFormRef} coalCostingType={coalCostingType} initialValues={undefined} setSelectedRowKeysData={setSelectedRowKeysData} />}
            {current === 1 && <BGCoalCostingsForm coalCostingType={coalCostingType} selectedRowKeysData={selectedRowKeysData} formRef={bgCoalCostingFormRef} />}
        </Card>
    )
}

export default CoalCostingStepper;