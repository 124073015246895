import React, { useEffect, useState } from 'react'
import { Affix, Button, Card, Col, Descriptions, Form, Row, Space, Table, Tag, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../../common';
import { useIntl } from 'react-intl';
import { BargeAgreementService, ContractStatusEnum, ContractTypesEnum, PreviewRequests, VesselAgreementPreviewDto } from '@exportx/shared-models-and-services';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getFilesData } from '@exportx/ui-utils';
import queryString from 'query-string';
import Link from 'antd/lib/typography/Link';
import { title } from 'process';
import { render } from '@testing-library/react';

const BargeAgreementDetailedView = () => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [snapData, setSnapData] = useState<any[]>([]);
  //const { baId } = state ? state : { baId: null };
  const location = useLocation();
  const paramBargeId = queryString.parse(location.search)?.ba_Id;
  console.log(paramBargeId, "=====================")
  console.log(queryString.parse(location.search));
  let { baId } = paramBargeId != null ? { baId: paramBargeId } : state ? state : { baId: null };
  
  const [gridData, setGridData] = useState<any>();
  const service = new BargeAgreementService();

  const [filesFormRef] = Form.useForm();
  const { authContext } = useAuthState();

  useEffect(() => {
    if(!isPermissionExist([138])) navigate('/')
    if (baId) {
      getBargeAgreementPreviewData();
    }

  }, [baId])

  let bargeAddendum = '/bargeAgreement-addendum';


  const getBargeAgreementPreviewData = () => {
    const req = new PreviewRequests(baId, authContext.defaultPlant);
    service.getBargeAgreementPreviewData(req).then(res => {
      if (res.status) {
        setGridData(res.data);
        setSnapData(res.snapData)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const sendForApproval = () => {
    const req = new PreviewRequests(baId, authContext.defaultPlant);
    service.sendForApproval(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Barge Agreement Sent successfully' }));
        getBargeAgreementPreviewData()
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const addendumClickHandler = () => {
    const link = `${bargeAddendum}?ba_Id=${baId}`
    navigate(link, { state: { record: {...gridData, action:'addendum'} } })
  }

  const approveOrRejectHandler = (agreementId: string, status: ContractStatusEnum) => {
    const req = new VesselAgreementPreviewDto(agreementId, authContext.defaultPlant, authContext.user.userName, status, gridData.contractType)
    service.approveOrRejectAgreement(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getBargeAgreementPreviewData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button onClick={() => sendForApproval()}>Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <span>
        {isPermissionExist([142]) && (<Button size="small" onClick={() => {
          approveOrRejectHandler(baId, ContractStatusEnum.ACTIVE);
        }}>Approve</Button>)} &nbsp;&nbsp;
        {isPermissionExist([143]) && (<Button type='dashed' size="small" onClick={() => {
          approveOrRejectHandler(baId, ContractStatusEnum.REJECT);
        }}>Reject</Button>)}
      </span>
    }
    if (status === ContractStatusEnum.ACTIVE && gridData?.contractType === ContractTypesEnum.ORIGINAL && isPermissionExist([139])) {
      return <Button onClick={()=> addendumClickHandler()}>Create Addendum</Button>
    }
  }

  const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
    for (const snapDate of snapData) {
      let valueAtGivenKey: any = snapDate;
      for (const key of keys) {
        valueAtGivenKey = valueAtGivenKey?.[key];
      }
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (gridData.contractType === ContractTypesEnum.ADDENDUM) {
          if (isRawData) {
            return value;
          } else {
            return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapDate.fixtureNoteNo.replace('SNP', 'ADD')}><b>
              {value}
            </b></Tooltip></>
          }
        }
      }
    };
    return value
  }

  const valiDateChildWithSnapShotChild = (index: number, key: string, value: string): any => {
    let valueAtGivenKey: any = snapData?.[0]?.specPriceData?.[index]?.[key];
    if (valueAtGivenKey) {
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (gridData.contractType === ContractTypesEnum.ADDENDUM) {
          console.log('came', key, value, valueAtGivenKey)
          return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapData?.[0]?.fixtureNoteNo?.replace('SNP', 'ADD')}><b>
            {value}
          </b></Tooltip></>
        }
      };
    }
    return value;
  }


  const setFormData = () => {
    navigate('/bargeAgreement-update', { state: { record: gridData } });
  }



  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      getFilesData(fileValues.filesData).then(filesData => {
          service.addNewFiles({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          baId: baId
        }).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            getBargeAgreementPreviewData();
            filesFormRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      });
    });
  }




  const bargeDetails: ColumnsType<any> = [
    {
      title: 'Barge Vendor',
      dataIndex: 'vendorName',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['vendorName'], gridData?.vendorName, false)}</span>
      ),
   

    },

    {
      title: 'Currency',
      dataIndex: 'currency',
      render: (value, row: any, index) => (
        <span>{valiDateWithSnapShotData(['currency'], gridData?.currency, false)}</span>
      ),

    },
    {
      title: 'Agreement Date',
      dataIndex: 'agreementDate',
      render(value, record, index) {
      const formattedDate = value ? moment(value).format('DD/MM/YYYY') : '';
      return (
        <span>{valiDateWithSnapShotData(['agreementDate'], formattedDate, false)}</span>
      );
    }

    },
    {
      title: gridData?.contractType === 'Addendum' ? 'Base Contract' : 'Addendum Data' ,
      dataIndex: '',
      render: (text, record) => {
        if (gridData?.contractType === 'Original') {
          return (
            <div>
              {gridData?.addendumData?.map((rec) => {
                const link = `/#/bargeAgreement-detailed-view?ba_Id=${rec.agreementId}`;
                return (
                  <a href={link} className="link-primary">
                    {`${rec.contractNo}, `}
                  </a>
                );
              })}
            </div>
          );
        } else {
          const link = `/#/bargeAgreement-detailed-view?ba_Id=${gridData?.baseContractId}`;
          return (
            <a href={link} className="link-primary">
              {gridData?.baseContractNo}
            </a>
          );
        }
      }
    }

  ];
  const specPriceData: ColumnsType<any> = [
    {
      title: 'Area',
      dataIndex: 'area',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'area', value)}</span>
      ),

    },

    {
      title: 'Price',
      dataIndex: 'price',
      hidden: !isPermissionExist([141]),
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'price', value)}</span>
      ),

    },
    {
      title: 'Barging Type',
      dataIndex: 'bargingType',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'bargingType', value)}</span>
      ),

    },
    {
      title: 'VAT Applicability (PPN)',
      dataIndex: 'vatInclOrExcl',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'vatInclOrExcl', value)}</span>
      ),

    },
  ];
  const remarks: ColumnsType<any> = [
    {
      title: 'Remarks',
      dataIndex: 'remarks',

      render: text => <span>{text}</span>,
    },]


  return (
    <div >
      <Card
        style={{ textAlign: 'center' }}
        className='default-card-class'
        title={<span style={{ color: 'white' }}> Contract -  {(gridData?.fixtureNoteNo)}
          <span style={{ paddingLeft: 20 }}> <StatusTag status={gridData?.status} />
          </span>
        </span>
        }
        extra={<>{getExtraComponent(gridData?.status)}</>}
      >
        <Card >
          <Table dataSource={Array(gridData)} columns={bargeDetails} pagination={false} bordered /><br></br>
          <Table dataSource={gridData?.specPriceData ? gridData?.specPriceData : gridData} columns={specPriceData} pagination={false} bordered /><br></br>
          <Table dataSource={Array(gridData)} columns={remarks} pagination={false} bordered />
        </Card>
        {(gridData?.filesData && isPermissionExist([145])) &&
          <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} >
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button
                      onClick={fileUploadFormHandler}
                      type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </FileUploadForm>
        }

        <Affix offsetBottom={0}>
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>
              <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
              <Col>{(gridData?.status !== ContractStatusEnum.ACTIVE && isPermissionExist([139])) && <Button onClick={setFormData}>Edit</Button>}</Col>
            </Row>
          </Card>
        </Affix>
      </Card>


    </div>
  )
}

export default BargeAgreementDetailedView