import { EditFilled, FileExcelFilled } from '@ant-design/icons';
import { BpCategoryDto, BpCategoryGetDto, BusinessPartnerPreviewData, BusinessPartnerService, BusinessPartnerTypeEnum, ContractStatusEnum } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Input, Row, Tag, Tooltip } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import Link from 'antd/es/typography/Link';
import Table from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, useAuthState, } from '../../../../../common';


interface IPropsBusinessPartnerGrid {
  bpCategory: BusinessPartnerTypeEnum;
}

export const BusinessPartnerGrid = (props: IPropsBusinessPartnerGrid) => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { authContext } = useAuthState();
  //to store Grid data
  const [gridData, setGridData] = useState<any[]>([]);//This is common for all Page Components But Variable Name May Vary
  //let pathToreDirect = '/business-number-detail-view'

  const [searchedText, setSearchedText] = useState("");
  const [createPermission, setCreatePermission] = useState<boolean>(false)
  const [editPermission, setEditPermission] = useState<boolean>(false)
  const [excelPermission, setExcelPermission] = useState<boolean>(false)


  useEffect(()=>{

      let hasPermission = false;

      if(props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER){
        hasPermission = isPermissionExist([75]);
        setCreatePermission(isPermissionExist([74]))
        setEditPermission(isPermissionExist([76]))
        setExcelPermission(isPermissionExist([79]))
      } else if(props.bpCategory === BusinessPartnerTypeEnum.VENDOR){
        hasPermission = isPermissionExist([82]);
        setCreatePermission(isPermissionExist([81]))
        setEditPermission(isPermissionExist([83]))
        setExcelPermission(isPermissionExist([86]))
      } else {
        hasPermission = isPermissionExist([89]);
        setCreatePermission(isPermissionExist([88]))
        setEditPermission(isPermissionExist([90]))
        setExcelPermission(isPermissionExist([93]))
      }

      if(!hasPermission){
        return navigate('/')
      }
  },[])

  const menusData = authContext.menuAccessObject;

  for (let i = 0; i < menusData.length; i++) {
    if (menusData[i].menuName === 'Business Partner') {
      const subMenuData = menusData[i].subMenuData;
      console.log(subMenuData)
    }
  }


  const baseComponent = props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'Customer' : props.bpCategory === BusinessPartnerTypeEnum.VENDOR ? 'Vendor' : 'Facilitator';

  let upDatePath = '/updateCustomer';
  if (BusinessPartnerTypeEnum.CUSTOMER === props.bpCategory) {
    upDatePath = '/updateCustomer';
  } else if (BusinessPartnerTypeEnum.VENDOR === props.bpCategory) {
    upDatePath = '/updateVendor';
  } else {
    upDatePath = '/updateFacilitator';
  }

  let pathToreD = '/detailCustomer';
  if (BusinessPartnerTypeEnum.CUSTOMER === props.bpCategory) {
    pathToreD = '/detailCustomer';
  } else if (BusinessPartnerTypeEnum.VENDOR === props.bpCategory) {
    pathToreD = '/detailVendor';
  } else {
    pathToreD = '/detailFacilitator';
  }


  //To Access Backend Services Via Shared Services
  const service = new BusinessPartnerService();

  useEffect(() => {
    getGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getGridData = () => {
    service.getAllBusinessPartners(new BpCategoryGetDto(props.bpCategory)).then(res => {
      if (res.status) {
        setGridData(res.data);
      };
    }).catch(err => {
      console.log(err.message)
    });
  };

  const handleExport = (e: any) => {
    e.preventDefault();
    // Formatting columns - to set default render
    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("/");
    // Formatting columns - to set default render
    let cloneArr = columns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
    });

    const excel = new Excel();
    excel
      .addSheet("Sheet1")
    excel.addRow();
    excel.addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`${props.bpCategory}-${currentDate}.xlsx`);
  };


  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'bpName',
      sorter: (a, b) => a.bpName !== null ? a.bpName.localeCompare(b.bpName) : "Have Null Values",
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        const aaa = new Set(Object.keys(record).map((key) => {
          return String(record[key]).toLowerCase().includes(value.toLocaleString())
        }))
        if (aaa.size && aaa.has(true))
          return true;
        else
          return false;
      },
      render: (value, record) => {
        const link = `/#${pathToreD}?bp_id=${record.bpId}`
        return <> {
          <Link href={link} className="link-primary" >
            {value}
          </Link>
        }
        </>
      }
    },
    {
      title: 'Type',
      dataIndex: 'bpCategory',
      sorter: (a, b) => a.bpCategory !== null ? a.bpCategory.localeCompare(b.bpCategory) : "Have Null Values",
    },

    {
      title: "Contract Name",
      dataIndex: 'firstName',
      sorter: (a, b) => a.firstName !== null ? a.firstName.localeCompare(b.firstName) : "Have Null Values",
      render: (_, record) => {
        return <>{`${record?.firstName} (${record?.lastName})`}</>
      }
    },
    {
      title: 'Contact Number',
      dataIndex: 'mobileNo',
      sorter: (a, b) => a.mobileNo !== null ? a.mobileNo.localeCompare(b.mobileNo) : "Have Null Values",
      render: (value, record) => {
        return <>{`${record?.mobileNo} (${record?.landLineNo})`}</>
      }
    },
    {
      title: 'Action',
      className: 'operations',
      width: 130,


      render: (_, rowData) => (
        <>{editPermission && (
        <span>
          {(rowData.status !== ContractStatusEnum.CLOSED) ?
            <EditFilled type="edit"
              onClick={() => {
                editClickHandler(rowData.bpId);
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            /> : null
          }
        </span>
        )}</>)

    },



  ];
  const editClickHandler = (bpId: string) => {
    const bpCatId = new BusinessPartnerPreviewData(bpId);

    service.getAllBusinessPartnerDataToUpdate(bpCatId).then(res => {
      if (res.status) {
        navigate(upDatePath, { state: { businessPartnerDto: res.data, isUpdate: true } });
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }

    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }




  const view = (businessPartnerDto: BpCategoryDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (businessPartnerDto) {
      isNewRecord = false;
    }
    let pathToreDirect = '/createCustomer';
    if (BusinessPartnerTypeEnum.CUSTOMER === props.bpCategory) {
      pathToreDirect = '/createCustomer';
    } else if (BusinessPartnerTypeEnum.VENDOR === props.bpCategory) {
      pathToreDirect = '/createVendor';
    } else {
      pathToreDirect = '/createFacilitator';
    }
    navigate(pathToreDirect, { state: { businessPartnerDto, bpCategory: props.bpCategory, isNewRecord, isUpdate } });
  }


  return (
    <Card
      title={<span>{baseComponent}  <Tag color={'blue'}>{gridData?.length}</Tag></span>}
      style={{ overflow: 'auto' }}
      className='default-card-class'
      extra={<>{createPermission && (
        <Button
          onClick={() => view(undefined, false)}
        >
          {fm({ id: 'common.create', defaultMessage: 'Create' })}
        </Button>)}</>
      }
      bodyStyle={{ overflow: 'auto' }}
    >
      <Row>
        <Col offset={18}>
        </Col>
        <Col  >
          <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} style={{ width: 200, float: "right" }} />,

        </Col>
        <Col>
        {excelPermission && (
          <Tooltip placement="topRight" title="Export As Excel">
            <Button
              //disabled={gridData.length === 0}
              // style={{ float: "right" }}
              size='small'
              type="default"
              //className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
              onClick={handleExport}
              icon={<FileExcelFilled />}
            >Export</Button>
          </Tooltip>)}
        </Col>
      </Row>


      <Table
        // className='contracts'
        rowKey={record => record.baId}
        columns={columns}
        dataSource={gridData}
        scroll={{ x: 500 }}
        size="large"
        bordered
        // pagination={false}
      >

      </Table>

    </Card>
  )
}

export default BusinessPartnerGrid;