import { FormInstance, Tabs } from "antd";
import { FormLayout } from "antd/lib/form/Form";
import { Ash } from "./ash";
import { Typical } from "./Typical";
import Ultimate from "./ultimate";

interface IAnalysisSpecProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}

const { TabPane } = Tabs;
export const Analysis = (props: IAnalysisSpecProps) => {
  return (
    <Tabs>
      <TabPane tab="Typical" key="1">
        <Typical {...props} />
      </TabPane>
      <TabPane tab="Ultimate" key="2">
        <Ultimate {...props} />
      </TabPane>
      <TabPane tab="Ash " key="3">
        <Ash {...props} />
      </TabPane>
    </Tabs>


  )
}

export default Analysis;