import { DownOutlined } from '@ant-design/icons';
import { BpCategoryGetDto, BusinessPartnerCategoryService, BusinessPartnerDto, BusinessPartnerService, BusinessPartnerTypeEnum, BusinessPartnerUpdateDto, TaxTypeEnum } from "@exportx/shared-models-and-services";
import { ClassValidator, getFilesData } from "@exportx/ui-utils";
import { Button, Card, Col, Collapse, Dropdown, Form, MenuProps, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AddressForm, AlertMessages, FileUploadForm, GenericFormWithOutPopUP, InputTypes, isPermissionExist, PreviewComponent, useAuthState } from "../../../../../common";
import KycForm from "../../../../../common/kyc-form/kyc-form";
import POCForm from "../../../../../common/poc-form";


const { Panel } = Collapse;
interface IBusinessPartnerFormProps {
  bpCategory: BusinessPartnerTypeEnum;


}
export const BusinessPartnerForm = (props: IBusinessPartnerFormProps) => {

  const [defaultActiveKey, setDefaultActiveKey] = useState(['1']);
  const [goToButtonKeys, setGoToButtonKeys] = useState([]);
  const [bpCategories, setBpCategories] = useState([]);
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { state }: any = useLocation();    //to access global authorized user data(Login user Data which is passed as created user or updated user)
  const { authContext } = useAuthState();//Common for all Page Components
  const { isUpdate, businessPartnerDto, isNewRecord } = state ? state : { isUpdate: false, businessPartnerDto: null, isNewRecord: true }; // Read values passed on router    
  const [personalDataRef] = Form.useForm();
  const [addressRef] = Form.useForm();
  const [kycFormRef] = Form.useForm();
  const [pocFormRef] = Form.useForm();
  const [filesFormRef] = Form.useForm();
  const [, setPrimaryKeyData] = useState({});
  const [businessPartnerData, setBusinessPartnerData] = useState<BusinessPartnerDto>(businessPartnerDto ? businessPartnerDto : { personalData: { addressData: {} }, kycFormData: [''], pocFormData: [''], createdUser: '', filesData: [] })
  const classValidator = new ClassValidator();
  const bpCategoryService = new BusinessPartnerCategoryService();

  //To Access Backend Services Via Shared Services
  const service = new BusinessPartnerService();


  const type = props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'mdm.components.customerForm' : props.bpCategory === BusinessPartnerTypeEnum.VENDOR ? 'mdm.components.vendorForm' : 'mdm.components.facilitatorForm';
  const baseComponent = props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER ? 'Customer' : props.bpCategory === BusinessPartnerTypeEnum.VENDOR ? 'Vendor' : 'Facilitator';
  let pathToreDirect = '/customer';
  if (BusinessPartnerTypeEnum.CUSTOMER === props.bpCategory) {
    pathToreDirect = '/customer';
  } else if (BusinessPartnerTypeEnum.VENDOR === props.bpCategory) {
    pathToreDirect = '/vendor';
  } else {
    pathToreDirect = '/facilitator';
  }


  useEffect(()=>{
    const accessId = {
        vendorCreate: [81],
        vendorEdit: [83],
        customerCreate: [74],
        customerEdit: [76],
        facilitatorCreate: [88],
        facilitatorEdit: [90]
    }

    if(props.bpCategory === BusinessPartnerTypeEnum.CUSTOMER) {
        const hasCustomerPermission = isNewRecord ? accessId.customerCreate : accessId.customerEdit;
        if(!isPermissionExist(hasCustomerPermission)){
            return navigate('/')
        }
    } else if (props.bpCategory === BusinessPartnerTypeEnum.VENDOR) {
        const hasVendorPermission = isNewRecord ? accessId.vendorCreate : accessId.vendorEdit;
        
        if(!isPermissionExist(hasVendorPermission)){
            return navigate('/')
        }
    } else {
        const hasFacilitatorPermission = isNewRecord ? accessId.facilitatorCreate : accessId.facilitatorEdit;

        if(!isPermissionExist(hasFacilitatorPermission)) {
            return navigate('/')
        }
    }
},[props.bpCategory])

  useEffect(() => {
    let limit = Number(defaultActiveKey[0]);
    let i: number, goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultActiveKey])


  useEffect(() => {
    getAllBpCategoryDropDownData();
    if (!(isNewRecord || isUpdate)) {
      setDefaultActiveKey(['3']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (businessPartnerData?.personalData.taxable === true) {
    personalDataRef.setFieldValue('taxable', true)
  } else {
    personalDataRef.setFieldValue('taxable', false)

  }

  const getAllBpCategoryDropDownData = () => {
    const reqObj = new BpCategoryGetDto(props.bpCategory);
    bpCategoryService.getAllBpCategoriesForDropDown(reqObj).then(res => {
      if (res.status) {
        setBpCategories(res.data.map(rec => { return { value: rec.bpCategoryId, label: rec.bpCategory } }));
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    });
  }


  const clearData = () => {
    personalDataRef.resetFields()
    addressRef.resetFields();
  }

  const goToOnClickHandler: MenuProps['onClick'] = ({ key }) => {
    setDefaultActiveKey([`${key}`]);
  };

  const items = goToButtonKeys.map(item => {
    return {
      label: `${item}`,
      key: `${item}`,
    }
  });

  const personalDetailsData = () => {
    personalDataRef.validateFields().then(personalValues => {
      addressRef.validateFields().then(addressValues => {
        setBusinessPartnerData((prevState: any) => { return { ...prevState, personalData: { ...prevState.personalData, ...personalValues, addressData: { ...prevState.addressData, ...addressValues } } } });
        const bpTypeData = bpCategories.find(bpCatData => bpCatData.value === personalValues['bpTypeId'])
        setPrimaryKeyData((prevState: any) => { return { ...prevState, [personalValues['bpTypeId']]: bpTypeData?.label } });
        setDefaultActiveKey(['2']);
      })
    });
  }

  const setPrimaryKeyDataFromSub = (presentObjToSet: any) => {
    setPrimaryKeyData((prevState: any) => { return { ...prevState, ...presentObjToSet } })
  }

  const getUpdatedOutput = (primaryData: any[], secondaryData: any[]) => {
    console.log(primaryData, 'primaryData')
    console.log(secondaryData, "secondaryData")
    if (primaryData.length === 0 || primaryData[0] === "") {


      return secondaryData;
    } else {
      const size = primaryData.length > secondaryData.length ? primaryData.length : secondaryData.length;
      const result = [];
      for (let i = 0; i < size; i++) {
        let primaryDataObject = primaryData[i] ? {
          ...primaryData[i]
        } : {};
        let secondaryDataObject = secondaryData[i] ? {
          ...secondaryData[i]
        } : {};
        result.push(
          {
            ...primaryDataObject,
            ...secondaryDataObject
          }
        )
      }
      console.log("result", result)
      return result;
    }
  }

  const kycAndPocDetails = () => {
    kycFormRef.validateFields().then(kycFormValues => {
      pocFormRef.validateFields().then(pocFormValues => {
        filesFormRef.validateFields().then(fileValues => {
          getFilesData(fileValues.filesData).then(filesData => {
            setBusinessPartnerData((prevState: any) => {
              const prevFilesData = prevState.filesData ? prevState.filesData : [];
              return { ...prevState, kycFormData: [...new Set([...getUpdatedOutput(prevState.kycFormData, kycFormValues.kycFormData)])], pocFormData: [...new Set([...getUpdatedOutput(prevState.pocFormData, pocFormValues.pocFormData)])], filesData: [...new Set([...getUpdatedOutput(prevFilesData, filesData)])], createdUser: authContext.user.userName, updatedUser: authContext.user.userName, }
            });
            setDefaultActiveKey(['3']);
          })
        });
      });
    });
  }


  const formItems = [
    {
      name: 'bpTypeId',
      label: fm({ id: `${type}.businessPartnerType`, defaultMessage: 'BusinessPartner Type' }),
      fieldType: { type: InputTypes.Select, options: bpCategories, placeHolder: fm({ id: `${type}.businessPartnerTypeReq`, defaultMessage: 'Select BusinessPartner type' }) },
      validationRules: [
        { required: true, message: fm({ id: `${type}.businessPartnerTypeReq`, defaultMessage: 'Select BusinessPartner type' }) },
      ]
    },
    {
      name: 'bpName',
      label: fm({ id: `${type}.businessPartnerName`, defaultMessage: 'BusinessPartner Name' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: `${type}.businessPartnerNameReq`, defaultMessage: 'Enter BusinessPartner Name' }) },
      validationRules: [
        {
          required: true, message: fm({ id: `${type}.businessPartnerNameReq`, defaultMessage: 'Enter BusinessPartner Name' })
        },
        // ...classValidator.alphaSpaces(),
        ...classValidator.lengthValidation(50, fm({ id: `${type}.businessPartnerNameReq`, defaultMessage: 'Enter BusinessPartner Name' })),
      ]
    },
    {
      name: 'registrationNo',
      label: fm({ id: `${type}.registrationNo`, defaultMessage: 'Business Reg.No' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: `${type}.registrationNoReq`, defaultMessage: 'Enter Business Reg.No' }) },
      validationRules: [
        {
          required: false, message: fm({ id: `${type}.registrationNoReq`, defaultMessage: 'Enter Business Reg.No' })
        }
      ]
    },
    {
      name: 'directorsName',
      label: fm({ id: `${type}.directorsName`, defaultMessage: 'Directors Name' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: `${type}.directorsNameReq`, defaultMessage: 'Enter Directors Name' }) },
      validationRules: [
        {
          required: false, message: fm({ id: `${type}.directorsNameReq`, defaultMessage: 'Enter Directors Name' })
        },
        ...classValidator.alphaSpaces(),
        ...classValidator.lengthValidation(30, fm({ id: `${type}.directorsNameReq`, defaultMessage: 'Enter Directors Name' })),
      ]
    },
    {
      name: 'taxId',
      label: fm({ id: `${type}.taxId`, defaultMessage: 'Tax Id' }),
      fieldType: { type: InputTypes.Input, placeHolder: fm({ id: `${type}.taxIdReq`, defaultMessage: 'Enter Tax Id' }) },
      validationRules: [
        {
          required: false, message: fm({ id: `${type}.taxIdReq`, defaultMessage: 'Enter Tax Id' })
        }
      ]
    },
    {
      name: 'taxable',
      label: fm({ id: `${type}.taxables`, defaultMessage: 'Taxables' }),
      fieldType: {
        type: InputTypes.Radio, options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" }
        ],
        placeHolder: fm({ id: `${type}.businessPartnerTypeReq`, defaultMessage: 'Choose' })
      },
      validationRules: [
        {
          required: false, message: 'Select Taxables'
        },
      ]
    },
    {
      name: "tds",
      label: fm({ id: `${type}.tds`, defaultMessage: 'TDS' }),
      fieldType: {
        type: InputTypes.Radio, options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" }
        ],
        placeHolder: fm({ id: `${type}.businessPartnerTypeReq`, defaultMessage: 'Choose' })
      },
      validationRules: [
        {
          required: false, message: 'Select TDS'
        },
      ]
    },
    {
      name: 'businessType',
      label: fm({ id: `${type}.taxType`, defaultMessage: 'Business Type' }),
      fieldType: {
        type: InputTypes.Select, options: Object.values(TaxTypeEnum).map(val => {
          return { value: val, label: val }
        }),
        placeHolder: "Select Business Type"
      },
      validationRules: [
        {
          required: false, message: 'Select BusinessType'
        },
      ]

    }
  ];

  const previewDataLabels = [{
    title: fm({ id: `${type}.step1heading`, defaultMessage: `${baseComponent} Details` }),
    keysAndLabelMapping: [
      {
        key: 'personalData',
        labels: {
          bpTypeId: fm({ id: `${type}.businessPartnerType`, defaultMessage: 'BusinessPartner Type' }),
          bpName: fm({ id: `${type}.businessPartnerName`, defaultMessage: 'BusinessPartner Name' }),
          registrationNo: fm({ id: `${type}.registrationNo`, defaultMessage: 'Business Reg.No' }),
          directorsName: fm({ id: `${type}.directorsName`, defaultMessage: 'Directors Name' }),
          taxId: fm({ id: `${type}.taxId`, defaultMessage: 'Tax Id' }),
        }
      },
      {
        key: 'personalData.addressData',
        labels: {
          addressLine1: fm({ id: "common.addressForm.addressLine1", defaultMessage: 'Address Line1' }),
          addressLine2: fm({ id: "common.addressForm.addressLine2", defaultMessage: 'Address Line2' }),
          country: fm({ id: "common.addressForm.country", defaultMessage: 'Country' }),
          doorNo: fm({ id: "common.addressForm.doorNo", defaultMessage: 'Door No' }),
          landmark: fm({ id: "common.addressForm.landmark", defaultMessage: 'Landmark' }),
          state: fm({ id: "common.addressForm.state", defaultMessage: 'State' }),
          zipCode: fm({ id: "common.addressForm.zipCode", defaultMessage: 'ZipCode' }),
        }
      }],
    isGridDisplay: false,
  },
  {
    title: fm({ id: `${type}.step2heading`, defaultMessage: `Contact and KYC Details` }),
    keysAndLabelMapping: [
      {
        key: 'pocFormData',
        title: fm({ id: `contact`, defaultMessage: `Contact Details` }),
        labels: {
          firstName: fm({ id: "common.userForm.firstName", defaultMessage: `First Name`, }),
          lastName: fm({ id: "common.userForm.lastName", defaultMessage: `Last Name` }),
          middleName: fm({ id: "common.userForm.middleName", defaultMessage: `Middle Name` }),
          mobileNo: fm({ id: "common.contactForm.mobileNo", defaultMessage: `Mobile Number` }),
          landLineNo: fm({ id: "common.contactForm.landLineNo", defaultMessage: `LandLine Number` }),
          email: fm({ id: "common.contactForm.email", defaultMessage: `Email` }),
        }
      },
      {
        key: 'kycFormData',
        title: fm({ id: `bank`, defaultMessage: `Bank Detailss` }),
        labels: {
          accountHolderName: fm({ id: "common.kycForm.accountHolderName", defaultMessage: 'Account Holder Name' }),
          accountNumber: fm({ id: "common.kycForm.accountNumber", defaultMessage: 'Account Number' }),
          bankName: fm({ id: "common.kycForm.bankName", defaultMessage: 'Bank Name' }),
          iBanNumber: fm({ id: "common.kycForm.iBanNumber", defaultMessage: 'IBAN Number' }),
          swiftCode: fm({ id: "common.kycForm.swiftCode", defaultMessage: 'Swift Code' }),
          address: fm({ id: "common.kycForm.address", defaultMessage: 'Address' }),
        }
      }
    ],
    isGridDisplay: true,
  },
  {
    title: fm({ id: `${type}.step1heading`, defaultMessage: `${baseComponent} Details` }),
    keysAndLabelMapping: [
      {
        key: 'filesData'
      }],
    isGridDisplay: false,
    filePreview: true
  }
  ];


  const saveData = (values: any) => {
    if (isUpdate && businessPartnerDto?.bpId) {
      update(values);
      return;
    };
    service.create(businessPartnerData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        navigate(pathToreDirect);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    });
  };

  const update = (values: any) => {
    const updateDto: BusinessPartnerUpdateDto = new BusinessPartnerUpdateDto(businessPartnerDto.bpId, businessPartnerData.personalData, businessPartnerData.kycFormData, businessPartnerData.pocFormData, businessPartnerData.filesData, businessPartnerData.isActive, businessPartnerData.createdUser, businessPartnerData.updatedUser, businessPartnerData.createdAt, businessPartnerData.updatedAt, businessPartnerData.versionFlag);
    service.update(updateDto).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };


  return (
    <Card
      title={fm({ id: `${type}.header`, defaultMessage: `${baseComponent}` })}
      style={{ overflow: 'auto' }}
      className='default-card-class'
      extra={
        <Button
          onClick={() => navigate(pathToreDirect)}
        >
          {fm({ id: 'common.view', defaultMessage: 'View' })}
        </Button>
      }
      bodyStyle={{ overflow: 'auto' }}
    >
      <Collapse activeKey={defaultActiveKey}>
        <Panel header={fm({ id: `${type}.step1heading`, defaultMessage: `${baseComponent} Details` })} key="1" collapsible={defaultActiveKey.includes("1") ? "header" : "disabled"}>
          <GenericFormWithOutPopUP
            title={''}
            // key={`${Date.now()}+base`}
            formItems={formItems}
            formRef={personalDataRef}
            initialValues={businessPartnerData.personalData}
            isUpdate={isUpdate}
            isNewRecord={isNewRecord}
            closeForm={() => { }}
            submitForm={() => { }}
            clearData={clearData}
            wrapByCard={true}

          >
            <AddressForm
              title={''}
              // key={`${Date.now()}+address`}

              formRef={addressRef}
              initialValues={businessPartnerData.personalData.addressData}
              isUpdate={isUpdate}
              isNewRecord={isNewRecord}
              closeForm={() => { }}
              submitForm={() => { }}

              clearData={clearData}
              setPrimaryKeyDataFromSub={setPrimaryKeyDataFromSub}
            />
            <Row justify="end">
              <Col>
                {
                  (isNewRecord || isUpdate) && <Space>
                    {/* <Button
                      onClick={() => { clearData(); }}
                    >
                      {fm({ id: "common.clear", defaultMessage: "Clear" })}
                    </Button> */}
                    <Button onClick={personalDetailsData} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }
              </Col>
            </Row>
          </GenericFormWithOutPopUP>
        </Panel>

        <Panel header={fm({ id: `${type}.step2heading`, defaultMessage: `Contact and KYC Details` })} key="2" collapsible={defaultActiveKey.includes("2") ? "header" : "disabled"}>
          <KycForm
            // key={`${Date.now()}+kyc`}
            maxCount={5}
            layoutType='vertical'
            formRef={kycFormRef}
            initialValues={{ kycFormData: businessPartnerData.kycFormData }}
          />
          <POCForm
            // key={`${Date.now()}+poc`}
            maxCount={5}
            layoutType='vertical'
            formRef={pocFormRef}
            initialValues={{ pocFormData: businessPartnerData.pocFormData }}
          />
          <FileUploadForm
            // key={`${Date.now()}+fileUpload`}
            maxCount={3}
            layoutType='vertical'
            formRef={filesFormRef}
            initialValues={businessPartnerData.filesData ? businessPartnerData.filesData : []} />
          <Row justify="end">
            <Col>
              {

                (isNewRecord || isUpdate) && <Space>
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    menu={{ items, onClick: goToOnClickHandler }}
                  >
                    {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                  </Dropdown.Button>
                  {/* <Button
                    onClick={() => { clearData(); }}
                  >
                    {fm({ id: "common.clear", defaultMessage: "Clear" })}
                  </Button> */}
                  <Button onClick={kycAndPocDetails} type="primary">
                    Save and Next
                  </Button>
                </Space>
              }</Col>
          </Row>
        </Panel>

        <Panel header={fm({ id: `${type}.step3heading`, defaultMessage: `Preview and Submit` })} key="3" collapsible={defaultActiveKey.includes("3") ? "header" : "disabled"}>
          <PreviewComponent data={businessPartnerData} labels={previewDataLabels} primaryKeyData={{}} />

          <Row justify="end">
            <Col>{
              (isNewRecord || isUpdate) && <Space>
                {/* <Button onClick={enableEdit} type="primary">
                  Edit
                </Button> */}
                <Button type="primary" style={{ color: '#fff', backgroundColor: '#2be82b' }} onClick={saveData}>
                  Submit
                </Button>
              </Space>
            }
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Card>
  )
}

export default BusinessPartnerForm;