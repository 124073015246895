import { ContractIdRequest, PurchaseContractService, QualityPriceDataDto } from '@exportx/shared-models-and-services';
import { Card, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../common';

interface IQualityDetailedView {
  qualityData?: any;
  pcId?: string;
  selectedRows: (values) => void;
  closeModal?: () => void;
  setShowModal?: (value: boolean) => void;
  setPcTypeData?: (value: any) => void;
  setPcTypeId?: (value: any) => void;
  setBargeOrderData?:any
}

export const NewModal = (props: IQualityDetailedView) => {
  // const [qualityData, setQualityData] = useState([]);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState<string[]>([]);
  const { authContext } = useAuthState();
  const [purchaseType, setPurchaseType] = useState<boolean>(false);
  const [purchaseTypeData, setPurchaseTypeData] = useState<any>()
  const service = new PurchaseContractService();
  const [selectedRowKeyTwo, setSelectedRowKeyTwo] = useState<React.Key[]>([]);



  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeysData(selectedRowKeys);
      props.selectedRows(selectedRows);
      setPurchaseTypeData(selectedRows[0].purchaseTypeData)
      setPurchaseType(true);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    selectedRowKeys: selectedRowKeysData,

  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows) => {
    props.setPcTypeData(selectedRows);
    props.setPcTypeId(selectedRows[0].typeId);
    props.setShowModal(false);
    props.setBargeOrderData((prev)=>{return {...prev,purchaseType:selectedRows[0].purchaseType}})
  }

  const rowSelectionTwo = {
    selectedRowKeyTwo,
    onChange: onSelectChange,
  };
  // useEffect(() => {
  //   getQualityDetailsByPcId(props.pcId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const getQualityDetailsByPcId = (pcId: string) => {
  //   const req = new ContractIdRequest(pcId, authContext.defaultPlant);
  //   service.getPurchaseQualitiesData(req).then(res => {
  //     if (res.status) {
  //       setQualityData(res.data)
  //     } 
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  // const updateBargeData = (data:BargeDetailsDto) => {
  //   const pcId = selectedRowKeysData
  //   bargeService.updateBargeData(data).then(res => {
  //    if(res.status){
  //     AlertMessages.getSuccessMessage("Updated Successfully");
  //    }else{
  //     AlertMessages.getErrorMessage(res.internalMessage)
  //    }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message)
  //   })
  // }

  const tableColumns: any = [
    {
      title: "Laycan",
      dataIndex: 'layCan',
      align: 'center',
      render: (layCan, row) => {
        return layCan ? `${moment(layCan.split(',')[0]).format('DD MMM YYYY')} - ${moment(layCan.split(',')[1]).format('DD MMM YYYY')}` : ''
      }
    },
    {
      title: "Quality",
      dataIndex: 'quality',
      align: 'center',

    },
    {
      title: 'Mine',
      dataIndex: 'mineNameDesc',
      align: 'center'
    },
    // {
    //   title: 'Purchase',
    //   dataIndex: 'purchasingTerm',
    //   align: 'center',
    //   render: (text, record) => <>{() => (record.typeId)}{record.purchaseType}</>
    // },
    // {
    //   title: 'Coal Price',
    //   dataIndex: 'pcAverageCoalIndexPrice',
    //   width: 125
    // },
    {
      title: 'Purchasing Term',
      dataIndex: 'purchasingTerm',
      width: 125
    },
    // {
    //   title: 'Payment',
    //   dataIndex: 'purchasingTerm',
    //   align: 'center'
    // },
    {
      title: "Quantity",
      dataIndex: 'quantity',
      align: 'center'
    },
    {
      title: "Remaining",
      dataIndex: 'remaining',
      align: 'center',
      render: () => { return "0" }
    }
  ]

  const purchaseTypeColumns: ColumnsType<any> = [
    {
      title: 'MINE NAME',
      dataIndex: 'bpName',
    },
    {
      title: '	PURCHASE TYPE',
      dataIndex: 'purchaseType',
    },
    {
      title: 'JETTY NAME',
      dataIndex: 'jettyName',
    },
    {
      title: 'COAL PRICE',
      dataIndex: 'coalPricePMT',
    },
  ]

  return (<>
    <Card title={'Quality Selection'} bordered={false}>
      <Table
        className='contracts'
        key={Date.now()}
        rowSelection={{
          ...rowSelection, type: "radio"

        }}
        rowKey={record => record.qualityId}
        columns={tableColumns}
        dataSource={props.qualityData}
        pagination={false}
        scroll={{ x: 500 }}
        size="large"
        bordered
      ></Table>
    </Card>
    <hr></hr>
    {purchaseType ?
      <Card title='Select Purchase Type'>
        <Table
          columns={purchaseTypeColumns}
          dataSource={purchaseTypeData}
          pagination={false}
          key={Date.now()}
          rowKey={record => record.typeId}
          rowSelection={{
            ...rowSelectionTwo, type: 'radio'
          }}
        ></Table>


      </Card> : <></>
    }

  </>


  )
}
