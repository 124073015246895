import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000000"'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    description: {
        width: '55%',
        flexDirection: 'column',
        borderRight: 0.4,
        paddingLeft: 5,
        paddingTop: 1,
        borderRightColor: "#000000",
    },
    qty: {
        width: '15%',
        flexDirection: 'column',
        borderRight: 0.4,
        padding: 1,
        textAlign: 'right',
        borderRightColor: borderColor,
    },
    price: {
        width: '15%',
        flexDirection: 'column',
        borderRight: 0.4,
        padding: 1,
        textAlign: 'right',
        borderRightColor: borderColor,
    },
    amount: {
        width: '15%',
        padding: 1,
        flexDirection: 'column',
        textAlign: 'right',
    },
});



const InvoiceMiddleRow = ({ items }) => {

    const rows = items?.description?.map(item =>

        <View style={styles.container} key={item.qty}>
            <View style={styles.description}>
                <Text>{item.desc}</Text>
            </View>
            <View style={styles.qty}>
                <Text>{item.quantity}</Text>
            </View>
            <View style={styles.price}>
                <Text>{item.unitPrice}</Text>
            </View>
            <View style={styles.amount}>
                <Text>{item.amount}</Text>
            </View>

        </View>
    )

    return (<Fragment>
        <View
            style=
            {{ borderBottom: 0.4, borderBottomColor: '#000000' }}
        >
            {rows}
        </View>
    </Fragment>);
}


export default InvoiceMiddleRow