import { TaxTypes, TaxesDto, TaxesService } from '@exportx/shared-models-and-services';
import { ClassValidator } from '@exportx/ui-utils';
import { Button, Card, Col, Form, Input, Row, Select, Space, } from 'antd';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, isPermissionExist, useAuthState } from '../../../../../common';
import DatePicker from '../../../../../common/data-picker/date-picker';
import { useEffect } from 'react';


/* eslint-disable-next-line */

const classValidator = new ClassValidator();
export function TaxesForm() {
  const { authContext } = useAuthState();//to access global authorized user data(Login user Data which is passed as created user or updated user)
  const { state }: any = useLocation();
  const { isUpdate, taxesData, isNewRecord } = state ? state : { isUpdate: false, taxesData: null, isNewRecord: true };; // Read values passed on router  
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const service = new TaxesService();

  const { formatMessage: fm } = useIntl();
  const { Option } = Select;


  const onReset = () => {
    form.resetFields();
  };

  useEffect(()=>{
    const accessId = {
      create: [22],
      view: [23],
      edit: [24]
    }
    const hasPermission = isNewRecord ? accessId.create : isUpdate ? accessId.edit : accessId.view;

    if(!isPermissionExist(hasPermission)){
      return navigate('/')
    }

  },[])

  if (taxesData) {
    taxesData.startDate = moment(taxesData?.startDate)
    taxesData.endDate = moment(taxesData?.endDate)
  }


  console.log(taxesData, "taxes data", taxesData?.versionFlag)

  const submitFormHandler = () => {
    form.validateFields().then(values => {
      saveData(values);
    })
  }

  const clearData = () => {
    form.resetFields();
  }


  const saveData = (values: any) => {
    if (isUpdate) {
      updateTax({ ...values, versionFlag: taxesData?.versionFlag, updatedUser: authContext.user.userName,isUpdate: isUpdate});
    } else {
      save({...values, isUpdate: isUpdate, createdUser: authContext.user.userName});
    }
  };
  
  const save = (req: any) => {
    service.createTax(req).then(res =>{
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        navigate("/taxes-view");
        onReset();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateTax = (req: any) => {
    service.updateTax(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage('Updated Successfully');
        navigate("/taxes-view");
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }





  return (
    <Card
      title={<span style={{ color: 'black', float: 'left' }}  >Taxes</span>}
      className='default-card-class'
      style={{ textAlign: 'center' }}
      extra={<Link to='/taxes-view' ><Button>View </Button></Link>}
    >
      <Form form={form} onFinish={saveData} initialValues={taxesData} layout="vertical" name="control-hooks" autoComplete='off'>
        <Form.Item name={"taxId"} style={{ display: 'none' }}  >
          <Input hidden />
        </Form.Item>
        <Row >


          <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1} className="gutter-row">
            <Form.Item
              name="taxName"
              label={fm({ id: "mdm.components.taxesForm.taxName", defaultMessage: 'Tax Name' })}

              rules={[
                {
                  required: true, message: 'Tax Name is Mandatory'
                },
                ...classValidator.alphaNumericSpecialCharsWithSpaces()
              ]}>

              <Input placeholder='Tax Name' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={2} className="gutter-row">
            <Form.Item
              name="taxPercentage"
              label={fm({ id: "mdm.components.taxesForm.taxPercentage", defaultMessage: 'Tax Percentage(%)' })}

              rules={[
                {
                  required: true, message: 'Tax Percentage(%) is Mandatory'

                },
                // ...classValidator.onlyNumeric()
                
              ]}
            >
              <Input placeholder='Tax Percentage(%)' />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={2} className="gutter-row">
            {/* <Form.Item
              name="taxCategory"
              label="Tax Category"
              rules={[
                {
                  required: true, message: 'Tax Category is Mandatory',
                },
              ]}
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                showSearch
                style={{ width: 280, marginRight: "80px" }}
                placeholder="Select Tax Category"
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
              >
                <>
                  <Option key={0} value={null}>Select State Category</Option>
                  {Object.keys(TaxCategoriesEnum).map(tax => {
                    return <Option key={tax} value={TaxCategoriesEnum[tax]}>{TaxCategoriesEnum[tax]}</Option>
                  })}
                </>
              </Select>
            </Form.Item> */}

            <Form.Item
              name="taxType"
              label={fm({ id: "mdm.components.taxesForm.taxType", defaultMessage: 'Tax Type' })}
              rules={[{ required: true, message: 'Tax Category is Mandatory', }]}

            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder="Select Tax Type"
              >
                {Object.keys(TaxTypes).map(key => {
                  return <Option key={key} value={TaxTypes[key]}>{TaxTypes[key]}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 5 }} offset={1} className="gutter-row">
            <Form.Item
              name="startDate"
              label={'Applicable From'}
              rules={[{ required: true, message: 'Tax Period is Mandatory', }]}
            >
              <DatePicker style={{ width: 280 }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 10 }} lg={{ span: 3 }} offset={2} >
            <Form.Item
              name="endDate"
              label={'Applicable To'}
              rules={[{ required: true, message: 'Tax Period is Mandatory', }]}
            >
              <DatePicker style={{ width: 280 }} disabledDate={(current) => {
                let fromDate = moment(form.getFieldValue("fromDate")).format("YYYY-MM-DD");
                return current && current < moment(fromDate, "YYYY-MM-DD");
              }} format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 3 }} offset={4}>

          </Col>
        </Row>

        <Row justify="end">
          <Col >
            {
              (isNewRecord || isUpdate) && <Space>
                <Button
                  onClick={() => { clearData(); }}
                >
                  {fm({ id: "common.clear", defaultMessage: "Clear" })}
                </Button>
                <Button onClick={submitFormHandler} type="primary">
                  Submit
                </Button>
              </Space>
            }</Col>
        </Row>
      </Form>

    </Card>




  );



}

export default TaxesForm