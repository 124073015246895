import { BasisDropDownDto, BasisService } from '@exportx/shared-models-and-services';
import { Card, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'


interface IAshProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}



export const Ash = (props: IAshProps) => {
  const { formRef, initialValues, layoutType } = props;

  const { Option } = Select;

  const basisService = new BasisService();
  const [basisData, setBasisData] = useState<BasisDropDownDto[]>([]);
  useEffect(() => {
    getCBasisData();
  }, []);


  const getCBasisData = () => {
    basisService.getAllBasisDropDownDto().then(res => {
      if (res.status) {
        setBasisData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }




  return (
    <Card
      style={{ border: "1px solid black" }}
    >
      <Form layout={layoutType} form={formRef} initialValues={initialValues}>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashSio2Basis" label="SiO2(%)"

            >



              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>



            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashSio2Typical"

            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashSio2Rejection"


            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashAi203Basis" label="AI203(%)"

            >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashAi203Typical"

            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashAi203Rejection"


            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashFe203Basis" label="Fe203 (%)"

            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashFe203Typical"


            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashFe203Rejection"


            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashCaoBasis" label="CaO (%)"


            >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashCaoTypical"


            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashCaoRejection"

            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashMgoBasis" label="MgO (%)"


            >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashMgoTypical"

            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashMgoRejection"


            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashNa2oBasis" label="Na2O (%)"

            >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name='ashNa2oTypical'

            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashNa2oRejection"


            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashK2oBasis" label="K2O (%)"

            >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashK2oTypical"

            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashK2oRejection"

            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashTio2Basis" label="TiO2 (%)"

            >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashTio2Typical"

            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashTio2Rejection"

            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashMn3o4Basis" label="Mn3O4 (%)"

            >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashMn3o4Typical"

            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashMn3o4Rejection"

            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashSo3Basis" label="SO3 (%)"

            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashSo3Typical"


            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashSo3Rejection"

            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>


        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashP2o5Basis" label="P2O5 (%)"

            >

              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashP2o5Typical"


            >
              <Input type="number" placeholder='Typical' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="ashP2o5Rejection"

            >
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>


      </Form>
    </Card>
  )
}

export default Ash;