import React, { useState, useEffect } from 'react';
import './global-dropdown.css';
import { Select } from 'antd';
import { AlertMessages } from '../../notifications';
const { Option } = Select;

/* eslint-disable-next-line */
export interface IGlobalDropdownProps {
  title?: string;
  serviceObj?: Object;
  serviceName?: string;
  filter?: Object;
  defatultSelectedItem?: string[] | string | number[] | number;
  onChangeExtraObject?: Object;
  onItemChange: (item: string | string[], selectedObject: Object, extraObject?: Object) => void;
  multiSelect?: boolean;
  preDependents?: { item: string[] | string, message?: string };
  style?: React.CSSProperties;
  data?: any[];
  isResultAnArray?: boolean;
  arrayKey?: string;
  manualKeys?: { optionValueAccessor: string | string[], optionKeyAccessor: string, seperator?: string, prefixOnDisplay?: string };
  showSearch?: boolean;
  className?: string;
  disabled?: boolean;
  // if true , then makes the default single item as selected if the response has only 1 value and auto triggers the onItemChange in parent component
  enableMonoSelectFeature?: boolean;
}

export const GlobalDropdown = (
  props: IGlobalDropdownProps
) => {
  const [, setItem] = useState<string>(undefined);
  const [, setItems] = useState<string[]>([]);
  const [dropDownData, setDropDownData] = useState<any[]>([]);

  // let defaultValue: any = '';
  // let defaultValues: any = [];

  // load all the items from the service
  // const setDefaultValues = () => {
  //   if (props.multiSelect) {
  //     if (props.defatultSelectedItem)
  //       defaultValues = props.defatultSelectedItem;
  //     else
  //       defaultValues = [];
  //     setItems(defaultValues);
  //   } else {
  //     if (props.defatultSelectedItem) {
  //       defaultValue = props.defatultSelectedItem;
  //       setItem(defaultValue);
  //     } else {
  //       setItem(undefined);
  //     }
  //   }
  // }

  const getDropDownData = () => {
    if (props.data) {
      setDropDownData(JSON.parse(JSON.stringify(props.data)));
    } else {
      props.serviceObj[props.serviceName](props.filter).then(response => {
        if (response.status) {
          if (props.isResultAnArray) {
            setDropDownData(response.data[props.arrayKey]);
          } else {
            if (props.arrayKey) {
              setDropDownData(response.data[props.arrayKey]);
            } else {
              setDropDownData(response.data);
            }
          }
          // make the default single item as selected if the response has only 1 value
          if (props.enableMonoSelectFeature && response.data.length === 1) {
            if (props.manualKeys) {
              setSelectedItem(response.data[0][props.manualKeys.optionKeyAccessor], { props: null });
            } else {
              setSelectedItem(response.data[0], { props: null });
            }
          }
        } else {
          if (response.errorCode) {
            // AlertMessages.getErrorMessage(fm({ id: `errorCodes.${response.errorCode}`, defaultMessage: `${response.internalMessage}` }));
          } else {
            AlertMessages.getErrorMessage(response.internalMessage);
          }
        }
      }).catch(error => {
        AlertMessages.getErrorMessage(error.message);
      });
    }
  }

  useEffect(() => {
    if (props.preDependents) {
      if (!props.preDependents.item || (props.preDependents.item && props.preDependents.item.length === 0)) {
        return;
      }
    }
    // setDefaultValues();
    getDropDownData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = (selectItems: any[]) => {
    try {
      if (selectItems) {
        if (selectItems.length) {
          let optionKey = '';
          let optionValue: any = null;
          let optionDisplayValue = '';
          let seperator: any = null;
          let keysCount: any = null;
          if (props.manualKeys) {
            optionKey = props.manualKeys.optionKeyAccessor;
            optionValue = props.manualKeys.optionValueAccessor;
            seperator = props.manualKeys.seperator ? props.manualKeys.seperator : '-';
            if (Array.isArray(optionValue)) {
              keysCount = optionValue.length - 1;
            }
          } else {
            // default accessors
            optionKey = 'itemCode';
            optionValue = 'itemName';
          }
          return selectItems.map((option) => {
            if (props.isResultAnArray) {
              return <Option
                key={props.title + '_' + option}
                value={option}>
                {/* {option[optionValue]} */}
                {option}
              </Option>
            } else {
              optionDisplayValue = '';
              if (Array.isArray(optionValue)) {
                optionValue.forEach((key, index) => {
                  optionDisplayValue += option[key]
                  if (keysCount > index)
                    optionDisplayValue += seperator;
                });
              } else {
                optionDisplayValue = option[optionValue];
              }
              if (props.manualKeys && props.manualKeys.prefixOnDisplay) {
                optionDisplayValue = props.manualKeys.prefixOnDisplay + optionDisplayValue;
              }
              return <Option
                key={props.title + '_' + option[optionKey]}
                value={option[optionKey]}>
                {/* {option[optionValue]} */}
                {optionDisplayValue}
              </Option>
            }
          });
        }
      }
    } catch (err) {
      return false;
    }
  }

  const validatePreDependent = () => {
    if (props.preDependents) {
      if (!props.preDependents.item) {
        AlertMessages.getErrorMessage(props.preDependents.message);
        return false;
      }
    }
  }

  const setSelectedItem = (selectedItem: string | string[], optionsObj) => {
    if (selectedItem) {
      if (props.multiSelect && typeof selectedItem !== 'string') {
        setItems(selectedItem);
      } else {
        if (typeof selectedItem === 'string')
          setItem(selectedItem);
      }
      props.onItemChange(selectedItem, optionsObj.props, props.onChangeExtraObject);
    }
  }

  return (
    <Select
      disabled={props.disabled ? true : false}
      className={props.className}
      showSearch={!props.showSearch ? true : false}
      style={props.style}
      optionFilterProp="children"
      defaultValue={props.multiSelect ? props.defatultSelectedItem ? props.defatultSelectedItem : [] : props.defatultSelectedItem ? props.defatultSelectedItem : undefined}
      mode={props.multiSelect ? "multiple" : undefined}
      placeholder='Please Select'
      onFocus={() => { return validatePreDependent() }}
      onChange={setSelectedItem}
      key={'dynamicDropDown' + props.title}>
      {/* <Option key={'default' + '_' + props.title} value='' disabled>Please Select</Option> */}
      {options(dropDownData)}
    </Select>
  );
};

export default GlobalDropdown;
