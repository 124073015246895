import { ArrowDownOutlined, ArrowUpOutlined, DeleteFilled, PlusCircleFilled, ReloadOutlined } from '@ant-design/icons';
import { CoalIndexedPriceService, ColaIndexTypeEnum, FutureIndexDateEnum, FutureIndexedEnum, ProrateGradeIndexes, SpecificationsEnum } from '@exportx/shared-models-and-services';
import { getLocalFormat } from '@exportx/ui-utils';
import { Button, Card, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select, Space, Statistic, Table, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, isPermissionExist } from '../../../../../common';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const { TextArea } = Input;


interface IPropsIndexedPriceForm {
    initialValues: any;
    coalIndexType: ColaIndexTypeEnum,
    layCanDate?: string[];
    grade?: string | number;
    cancelHandler: () => void;
    indexFormSubmitButtonHandler: (values: any) => void;
    specification?: SpecificationsEnum
}

export const IndexedPriceCalculationForm = (props: IPropsIndexedPriceForm) => {
    const service = new CoalIndexedPriceService();
    const { grade, cancelHandler, indexFormSubmitButtonHandler, initialValues, specification } = props;
    const [formRef] = Form.useForm();
    const navigate = useNavigate();
    const { formatMessage: fm } = useIntl();
    const [coalIndexType, setCoalIndexType] = useState(props.coalIndexType);
    const [tableData, setTableData] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [offSet, setOffSet] = useState(0);
    const [isWeighted, setIsWeighted] = useState(false);
    const [isFutureIndex, setIsFutureIndex] = useState(false);
    const [futureIndexDateMethod, setFutureIndexDateMethod] = useState<FutureIndexDateEnum>(undefined);
    const [initialValuesLocal, setInitialValuesLocal] = useState(undefined);
    const actualGrade = grade ? Number(grade) : 1;
    const columns = [];

    useEffect(()=>{
        if(!isPermissionExist([130])) navigate('/')
    },[])

    useEffect(()=>{
        formRef.setFieldValue('actualGrade', actualGrade)
    },[actualGrade])


    useEffect(() => {
        handleProRateIndexName(selectedGrades);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWeighted]);


    useEffect(() => {
        const newInitialValues = { ...initialValues }
        newInitialValues['proRateIndexName'] = initialValues?.['proRateIndexName']?.split(',')
        setInitialValuesLocal(newInitialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        let requestObject = {
            limit: 10,
            offSet,
            coalIndexType
        }
        getIndexedData(requestObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offSet, coalIndexType]);

    useEffect(() => {
        let indexIds = []
        selectedRowKeys.forEach(rowKey => {
            indexIds.push(tableData[rowKey]['id'])
        });
        formRef.setFieldValue('indexIds', indexIds.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowKeys])

    const prorateGradeIndexesOptions = [];
    const narOptions = [];

    columns.push(
        {
            title: coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'Week Ending' : 'Month Ending',
            dataIndex: coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'weekEndingDate' : 'monthYear',
            render: (value, record) => (
                <>

                    {coalIndexType === ColaIndexTypeEnum.WEEKLY ? moment(record.weekEndingDate).format('DD MMM YYYY') : moment(record.monthYear).format('DD MMM YYYY')}
                </>
            )
        }
    );
    ProrateGradeIndexes.garIndexes.forEach((garIndex, index) => {
        columns.push({
            title: `${garIndex} ${SpecificationsEnum.GAR} (${ProrateGradeIndexes.narIndexes[index]} ${SpecificationsEnum.NAR})`,
            dataIndex: `gar${garIndex}`,
            render: (text, RowData: any, index: number) => {
                let color = 'green';
                let arrow = <ArrowUpOutlined style={{ fontSize: '12px', width: '10px', height: '10px' }} />;
                if (Number(text) < Number(tableData?.[index + 1]?.[`gar${garIndex}`])) {
                    color = 'red';
                    arrow = <ArrowDownOutlined style={{ fontSize: '12px', width: '10px', height: '10px' }} />;
                }
                return <Statistic
                    value={getLocalFormat(text, 'USD')}
                    // precision={2}
                    valueStyle={{
                        color: color,
                        fontSize: '16px'
                    }}
                    prefix={<span color={'green'} key={'green'}>{arrow}</span>}
                />
            }
        });


        // prorateGradeIndexesOptions.push({ dataIndex: `gar${garIndex}`, label: `${garIndex} GAR` });
        // narOptions.push({ dataIndex: `nar${ProrateGradeIndexes.narIndexes[index]}`, label: `${ProrateGradeIndexes.narIndexes[index]}  ${SpecificationsEnum.NAR}` });
        switch (specification) {
            case SpecificationsEnum.NAR: prorateGradeIndexesOptions.push({ dataIndex: `nar${ProrateGradeIndexes.narIndexes[index]}`, label: `${ProrateGradeIndexes.narIndexes[index]}  ${SpecificationsEnum.NAR}` });
                break;
            case SpecificationsEnum.GAR: prorateGradeIndexesOptions.push({ dataIndex: `gar${garIndex}`, label: `${garIndex} ${SpecificationsEnum.GAR}` });
                break;
            default: prorateGradeIndexesOptions.push({ dataIndex: `gar${garIndex}`, label: `${garIndex} ${SpecificationsEnum.GAR}` },);
                narOptions.push({ dataIndex: `nar${ProrateGradeIndexes.narIndexes[index]}`, label: `${ProrateGradeIndexes.narIndexes[index]}  ${SpecificationsEnum.NAR}` });
        }


    });

    prorateGradeIndexesOptions.push(...narOptions);


    const getIndexedData = (requestObj: any) => {
        service.getAllCoalIndexPrice(requestObj).then(res => {
            if (res.status) {
                const selectedKeys = []
                const savedIndexIds = initialValues?.indexIds ? initialValues.indexIds.split(',') : [];
                const latestTableData = res.data.map((rec, index) => {
                    if (savedIndexIds.includes(rec['id'])) {
                        selectedKeys.push(index)
                    }
                    return {
                        ...rec,
                        key: index
                    }
                });
                setTableData(latestTableData);
                setSelectedRowKeys(selectedKeys);
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        });
    }




    const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        setSelectedRowKeys(selectedRowKeys);
        const proRateIndex = formRef.getFieldValue('proRateIndexName');
        if (proRateIndex && proRateIndex.length) {
            let total = 0;
            let gradesTotal = [];
            let prorate = 0;
            proRateIndex.forEach(value => {
                gradesTotal[value] = 0;
                prorate += Number(value.slice(3));
            });
            prorate = prorate / proRateIndex.length;
            selectedRows.forEach(row => {
                proRateIndex.forEach(value => {
                    let dataIndex = value;
                    if (value.includes('nar')) {
                        dataIndex = 'gar' + ProrateGradeIndexes.garIndexes[ProrateGradeIndexes.narIndexes.indexOf(Number(value.slice(3)))]
                    }
                    gradesTotal[value] += Number(row[dataIndex]);
                    total += Number(row[dataIndex]);
                })
            });

            const totalFields = selectedRows.length <= 0 ? 1 : selectedRows.length;
            let avgCoalPrice = parseFloat((total / (proRateIndex.length * totalFields)).toFixed(2));

            if (isWeighted) {
                const baselineGar = formRef.getFieldValue('baselineGar');
                prorate = baselineGar ? baselineGar : prorate;
                let weightedTotal = 0;
                proRateIndex.forEach(value => {
                    let defaultWeightage = (100 / gradesTotal.length) / 100;
                    let garWeight = formRef.getFieldValue(value) ? formRef.getFieldValue(value) / 100 : defaultWeightage;
                    weightedTotal += (gradesTotal[value] / totalFields) * garWeight;
                });
                avgCoalPrice = parseFloat(weightedTotal.toFixed(2));
            }
            let actualGradeLocal = isNaN(formRef.getFieldValue('actualGrade')) ? 1 : formRef.getFieldValue('actualGrade');
            const proRateCoalBasePrice = parseFloat((avgCoalPrice * (actualGradeLocal) / prorate).toFixed(2));
            formRef.setFieldsValue({ avgCoalBasePrice: avgCoalPrice, proRateCoalBasePrice });
            calculateFinalPrice();
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleProRateIndexName = (grades: string[]) => {
        setSelectedGrades(grades);
        if (selectedRowKeys.length) {
            let total = 0;
            let gradesTotal = [];
            let prorate = 0;
            grades?.forEach(value => {
                gradesTotal[value] = 0;
                prorate += Number(value?.slice(3));
            });
            prorate = prorate / grades.length;

            selectedRowKeys.forEach(rowKey => {
                grades?.forEach(value => {
                    let dataIndex = value;
                    if (value.includes('nar')) {
                        dataIndex = 'gar' + ProrateGradeIndexes.garIndexes[ProrateGradeIndexes?.narIndexes?.indexOf(Number(value.slice(3)))]
                    }
                    gradesTotal[value] += Number(tableData[rowKey][dataIndex]);
                    total += Number(tableData[rowKey][dataIndex]);
                })
            });


            let avgCoalPrice = parseFloat((total / (grades.length * selectedRowKeys.length)).toFixed(2));

            if (isWeighted) {
                const baselineGar = formRef.getFieldValue('baselineGar');
                prorate = baselineGar ? baselineGar : prorate;
                let weightedTotal = 0;
                grades.forEach(value => {
                    let defaultWeightage = (100 / gradesTotal.length) / 100;
                    let garWeight = formRef.getFieldValue(value) ? formRef.getFieldValue(value) / 100 : defaultWeightage;
                    weightedTotal += (gradesTotal[value] / selectedRowKeys.length) * garWeight;
                });
                avgCoalPrice = parseFloat(weightedTotal.toFixed(2));
            }
            let actualGradeLocal = isNaN(formRef.getFieldValue('actualGrade')) ? 1 : formRef.getFieldValue('actualGrade');
            const proRateCoalBasePrice = parseFloat((avgCoalPrice * (actualGradeLocal) / prorate).toFixed(2));
            formRef.setFieldsValue({ avgCoalBasePrice: avgCoalPrice, proRateCoalBasePrice });
            calculateFinalPrice();
        } else {
            // AlertMessages.getErrorMessage('Please select Index date');
        }
    };

    const calculateFinalPrice = () => {
        const finalPriceDependentFactors = formRef.getFieldsValue(['proRateCoalBasePrice', 'steveDoorPrice', 'bonusPricePMT', 'discountPricePMT', 'otherAdjustments']);
        let finalCoalBasePricePMT = 0;
        Object.keys(finalPriceDependentFactors).forEach(key => {
            if (finalPriceDependentFactors[key] && Array.isArray(finalPriceDependentFactors[key])) {
                finalPriceDependentFactors[key].forEach((othersData) => {
                    finalCoalBasePricePMT += Number(othersData['otherBonusOrDiscount']) ? Number(othersData['otherBonusOrDiscount']) : 0;
                })
            } else {
                if (key === 'steveDoorPrice' || key === 'discountPricePMT') {
                    finalCoalBasePricePMT += Math.abs(Number(finalPriceDependentFactors[key])) ? -Math.abs(Number(finalPriceDependentFactors[key])) : 0;
                } else {
                    finalCoalBasePricePMT += Number(finalPriceDependentFactors[key]) ? Number(finalPriceDependentFactors[key]) : 0;
                }
            }
        });
        finalCoalBasePricePMT = parseFloat((Number(finalCoalBasePricePMT)).toFixed(2));
        formRef.setFieldsValue({ finalCoalBasePricePMT });
    };

    const submitButtonHandler = () => {
        formRef.validateFields().then(values => {
            indexFormSubmitButtonHandler(values);
        });
    }

    const isWeightedHandler = (e: CheckboxChangeEvent) => {
        setIsWeighted(e.target.checked);
    };

    const isFutureIndexHandler = (e: CheckboxChangeEvent) => {
        setIsFutureIndex(e.target.checked);
    };




    return (
        <Card
            style={{ height: '90vh', width: '100%', top: 0, bottom: 0 }}
            bodyStyle={{ maxHeight: '90vh', overflowY: 'scroll' }}
            actions={[
                <Row justify='space-around'>
                    <Col><Button onClick={() => { cancelHandler(); }}>Cancel</Button></Col>
                    <Col>
                        <Button onClick={() => { submitButtonHandler(); }} type="primary">
                            Submit
                        </Button>
                    </Col>
                </Row>
            ]}>
            {!grade && <Row justify='space-around'>
                <Col><p><span style={{ color: 'red' }}>*</span>You have not Entered Quality so we will keep it as 1</p></Col></Row>}
            <Form layout="horizontal" form={formRef} initialValues={initialValuesLocal} key={Date.now()}>
                <Row justify='space-around'>
                    <Col>
                        <Form.Item
                            label='Coal Index Type'
                            name='coalIndexType'
                            rules={[{ required: true, message: 'Please Select Coal Index Type' }]}
                        >
                            <Radio.Group buttonStyle="solid" onChange={(e) => { setCoalIndexType(e.target.value) }}>
                                {Object.keys(ColaIndexTypeEnum).map(contractTermKey => {
                                    return <Radio.Button value={ColaIndexTypeEnum[contractTermKey]}>{ColaIndexTypeEnum[contractTermKey]}</Radio.Button>
                                })}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col>
                        <Form.Item name={'isFutureIndex'} label={'Is Future Indexed'} valuePropName="checked" initialValue={isFutureIndex}>
                            <Checkbox onChange={isFutureIndexHandler}></Checkbox>
                        </Form.Item >
                    </Col>
                </Row>
                {isFutureIndex && <Row justify='space-around'>
                    <Col>
                        <Form.Item
                            label='FutureIndexDate Method'
                            name='futureIndexDateMethod'
                            rules={[{ required: true, message: 'Please Select FutureIndexDate Method' }]}
                        >
                            <Radio.Group buttonStyle="solid" onChange={(e) => { setFutureIndexDateMethod(e.target.value) }}>
                                {Object.keys(FutureIndexDateEnum).map(contractTermKey => {
                                    return <Radio.Button value={FutureIndexDateEnum[contractTermKey]}>{FutureIndexDateEnum[contractTermKey]}</Radio.Button>
                                })}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>}
                {
                    futureIndexDateMethod && <Row justify='space-around'>
                        <Col>
                            <Form.Item
                                name="futureIndexPeriod"
                                rules={[{ required: true, message: 'Please Select futureIndexes' }]}
                            >
                                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                    placeholder="Please Select futureIndexes"
                                    onChange={handleProRateIndexName}>
                                    <Option value={''}>Please Select</Option>
                                    {Object.keys(FutureIndexedEnum).map(contractTermKey => {
                                        return <Option value={FutureIndexedEnum[contractTermKey]}>{FutureIndexedEnum[contractTermKey]}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <b>ALL PRICES IN USD DOLLARS</b>
                <br />
                <br />
                {!isFutureIndex && <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    footer={() => <Space style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                        <Button
                            type="primary"
                            icon={<ReloadOutlined />}
                            //loading={loadings[1]}
                            onClick={() => setOffSet(prevOffSet => prevOffSet + 5)}
                        >
                            Load More
                        </Button>

                    </Space>}
                    // sticky={true}
                    scroll={{ x: 700 }}
                />}
                <br /><br />
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>Pro Rata Grade to be considered </b>:</label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }} >
                        <Form.Item
                            name="proRateIndexName"
                            rules={[{ required: true, message: 'Pro Rata Grade to be considered' }]}

                        >
                            <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                // {...(selectedGrades.length === 2 && { open: false })}
                                placeholder="Please Select Data"
                                onChange={handleProRateIndexName}
                                mode='multiple'>
                                {/* <Option value={''}>Please Select</Option> */}
                                {prorateGradeIndexesOptions.map(item => {
                                    return <Option value={item.dataIndex}>{item.label}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    {(selectedGrades?.length > 1 && <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 0.5 }}>
                        <Form.Item name={'isWeighted'} label={'Is Weighted'} valuePropName="checked" initialValue={isWeighted}>
                            <Checkbox onChange={isWeightedHandler}></Checkbox>
                        </Form.Item >
                    </Col>)}
                </Row>
                {((selectedGrades?.length > 1 && isWeighted) && <><Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>BaseLine GAR </b> :</label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }}  >
                        <Form.Item name='baselineGar' rules={[{ required: true }]}>
                            <Input onChange={() => handleProRateIndexName(selectedGrades)} />
                        </Form.Item>
                    </Col>
                </Row>
                    <Row justify='center'>
                        {selectedGrades?.map(grade => {
                            return <Col>
                                <Form.Item name={grade} rules={[{ required: true }]}>
                                    <Input placeholder={`Please enter ${grade}`} onChange={() => handleProRateIndexName(selectedGrades)} />
                                </Form.Item>
                            </Col>
                        })
                        }
                    </Row>

                </>)}
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>Average Coal Base Price (PMT)</b> :</label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }}  >
                        <Form.Item name='avgCoalBasePrice'>
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>Actual Grade </b> :</label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }}  >
                        <Form.Item name='actualGrade' rules={[{ required: true }]}>
                            <Input onChange={() => handleProRateIndexName(selectedGrades)} />
                        </Form.Item>
                    </Col>
                </Row>
                
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>Pro rata Coal Base Price (PMT) </b>:</label>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }}  >
                        <Form.Item name='proRateCoalBasePrice'>
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>Stevedoring/FloatingCrane </b></label>
                        <span>:</span>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }}  >
                        <Form.Item name='steveDoorPrice'>
                            <InputNumber style={{ width: '100%' }} onChange={calculateFinalPrice} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>Bonus Price (PMT) </b></label>
                        <span>:</span>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }}  >
                        <Form.Item
                            name="bonusPricePMT"
                            rules={[{ required: false }]}
                        >
                            <InputNumber style={{ width: '100%' }} min={0} onChange={calculateFinalPrice} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>Discount Price (PMT)</b></label>
                        <span>:</span>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }}  >
                        <Form.Item
                            name="discountPricePMT"
                            rules={[{ required: false, message: 'Discount Price PMT' }]}
                        >
                            <InputNumber style={{ width: '100%' }} onChange={calculateFinalPrice} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List name="otherAdjustments">
                    {(fields, { add, remove }) => (
                        <>
                            <Row>
                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                                    <label htmlFor=""><b>Other Adjustments</b></label>
                                    <span>:</span>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }} >
                                    <Button type='default'
                                        shape='circle'
                                        icon={<PlusCircleFilled />}
                                        onClick={() => add()}
                                        style={{ float: 'right' }}
                                    />
                                </Col>
                            </Row>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row>
                                    <Col
                                        xs={{ span: 18, offset: 2 }}
                                        sm={{ span: 18, offset: 2 }}
                                        md={{ span: 18, offset: 2 }}
                                        lg={{ span: 18, offset: 2 }}
                                        xl={{ span: 18, offset: 2 }}>
                                        <Row>
                                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8, offset: 2 }} >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'othersRemarks']}
                                                    rules={[{ required: true, message: 'Missing last name' }]}

                                                >

                                                    <Input placeholder="remarks" style={{ width: '100%' }} />
                                                </Form.Item>
                                            </Col> <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8, offset: 1 }}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'otherBonusOrDiscount']}
                                                    rules={[{ required: true, message: 'Missing first name' }]}

                                                >
                                                    <InputNumber placeholder="bonus or Discount or any others" style={{ width: '100%' }} onChange={calculateFinalPrice} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 2 }}
                                                sm={{ span: 2 }}
                                                md={{ span: 2 }}
                                                lg={{ span: 2 }}
                                                xl={{ span: 2 }}>
                                                <span>
                                                    <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                                        <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                                                            remove(name);
                                                            calculateFinalPrice();
                                                        }} />
                                                    </Tooltip>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={{ span: 2 }}
                                        sm={{ span: 2 }}
                                        md={{ span: 2 }}
                                        lg={{ span: 2 }}
                                        xl={{ span: 2 }}></Col>
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>Final Coal Base Price (PMT) </b></label>
                        <span>:</span>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }}  >
                        <Form.Item
                            name="finalCoalBasePricePMT"
                            rules={[{ required: false, message: 'Final Coal Base Price PMT' }]}
                        >
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 1 }} >
                        <label htmlFor=""><b>Remarks </b></label>
                        <span>:</span>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9, offset: 0.5 }}  >
                        <Form.Item name='remarks'>
                            <TextArea rows={15} />
                        </Form.Item>

                    </Col>
                </Row>
                <Form.Item
                    name="indexIds"
                    rules={[{ required: false, message: 'IndexIds must need to check' }]}
                    hidden
                >
                    <Input />
                </Form.Item>
            </Form ></Card>
    )
}

export default IndexedPriceCalculationForm;