import Table, { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react'
import Link from 'antd/lib/typography/Link'
import { useNavigate } from 'react-router-dom';
import { Button, Card, Drawer, Modal } from 'antd';
import { CPSPreviewDto, ContractStatusEnum, ContractTypesEnum, ProcurementContractService, ProcurementContractTypeEnum, PurchaseContractNoReqDto } from '@exportx/shared-models-and-services';
import { useForm } from 'antd/es/form/Form';
import { ProcurementServiceContract } from '../procurement-service';
import { AlertMessages, StatusTag, useAuthState } from '../../../common';
import StatusApprovalsButton from '../../../common/status-tag/status-approvals-button-component';
import moment from 'moment';

interface marketingProps {
    contractId: string;
    contractType: ContractTypesEnum;
}

const MarketingFeesForSales = (props: marketingProps) => {

    const navigate = useNavigate()
    const [purchaseContractModal, setProcurementModal] = useState<boolean>(false)
    const [formRef] = useForm()
    let pathToreDirect = '/po-detail-view';
    const [procurementInitialValues, setProcurementIntialValues] = useState({ quality: [''], filesData: [], lc: [''], others: [''], telegraphicTransfer: [''], });
    const { contractId, contractType } = props
    const [procurement, setProcurement] = useState<any[]>();
    const procurementService = new ProcurementContractService();
    const { authContext } = useAuthState();
    const [dummyState, setDummyState] = useState<number>(1)

    useEffect(() => {
        getProcurementServiceFees();
    }, [contractId])

    const getProcurementServiceFees = () => {
        const req = new PurchaseContractNoReqDto(contractId, authContext.defaultPlant, ProcurementContractTypeEnum.Marketing_Fees);
        procurementService.getProcurementServiceFees(req).then(res => {
            if (res.status) {
                setProcurement(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const procurementStatusApproval = (status: ContractStatusEnum, id: string) => {
        const req = new CPSPreviewDto(id, authContext.defaultPlant, undefined, status, authContext.user.userName)
        procurementService.procurementStatusApproval(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getProcurementServiceFees();
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            console.log(err.message);
        })
    }

    const procurementTableDataProcessing = (values: any[]) => {
        const procurementData = [];
        values?.forEach((rec) => {
            rec.quality.forEach((qualityRecord, index) => {
                procurementData.push(
                    {
                        quantity: qualityRecord.quantity,
                        quantityInMt: qualityRecord.quantityInMt,
                        priceType: qualityRecord.priceType,
                        pricePmt: qualityRecord.pricePmt,
                        layCan: qualityRecord.layCan,
                        incoterm: qualityRecord.incoterm,
                        rowSpan: index === 0 ? rec.quality.length : 0,
                        pcID: rec.pcID,
                        contractNo: rec.contractNo,
                        vendor: rec.vendor,
                        contractType: rec.contractType,
                        status: rec.status
                    }
                )
            })
        })
        return procurementData
    }

    let pathToreDirec = '/marketing-detailedView';
    const procurementColumns: ColumnsType<any> = [
        {
            title: 'Contract No',
            dataIndex: 'contractNo',
            sorter: (a, b) => a.contractNo != null ? a.contractNo.localeCompare(b.contractNo) : "Have Null Values",
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                const link = `/#${pathToreDirec}?cpcId=${record.pcID}`;
                return <a href={link} className='link'>{record.contractNo}</a>;
            }
        },
        {
            title: 'Vendor Name',
            dataIndex: 'vendor',
            sorter: (a, b) => a.vendorName != null ? a.vendorName.localeCompare(b.vendorName) : "Have Null Values",
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, record: any, index) => {
                return <span>{value} </span>;
            }
        },
        {
            title: 'Laycan',
            dataIndex: 'layCan',
            onCell: (record: any) => ({
                style: {whiteSpace: 'nowrap'}
            }),
            render: (value, record) => { return `${moment(value[0]).format('DD-MMM-YYYY')} to ${moment(value[1]).format('DD-MMM-YYYY')}` },
        },
        {
            title: 'Inco Term',
            dataIndex: 'incoterm',
        },
        {
            title: 'Quantity MT',
            dataIndex: 'quantityInMt',
            align: 'right',
            // sorter: (a, b) => a.contractDate != null ? a.contractDate.localeCompare(b.contractDate) : "Have Null Values",
            render: (value) => {
                return <>
                {Math.round(value)}
                </>
            }
        },

        {
            title: 'Deduction Bases',
            dataIndex: 'priceType',
            align: 'right',
            // sorter: (a, b) => a.priceType != null ? a.priceType.localeCompare(b.priceType) : "Have Null Values",

        },
        {
            title: 'Price PMT',
            dataIndex: 'pricePmt',
            align: 'right',
            // sorter: (a, b) => a.pricePmt != null ? a.pricePmt.localeCompare(b.pricePmt) : "Have Null Values",

        },
        {
            title: 'Status',
            dataIndex: 'status',
            // sorter: (a, b) => a.pricePmt != null ? a.pricePmt.localeCompare(b.pricePmt) : "Have Null Values",
            render: (value, record) => {
                return <>
                    <StatusTag status={value} />
                </>
            }
        },
        {
            // title: 'Action',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),

            render: (value, record: any, index) => {
                return <>
                    <StatusApprovalsButton status={record.status} statusApproval={procurementStatusApproval} id={record.pcID} />
                </>

                // return <span><Button type='primary' disabled={record.status === ContractStatusEnum.DRAFT ? false : true} onClick={() => procurementStatusApproval(record.pcID)}>Approve</Button></span>;
            }
            // render: (value, record: any, index) => {
            //     return <span><Button type='primary' style={{ borderColor: "" }} disabled={record.status === ContractStatusEnum.DRAFT ? false : true} onClick={() => procurementStatusApproval(record.pcID)}>Approve</Button></span>;
            // }
        }

    ]

    const rowClassName = (row: any, index) => {
        return index % 2 === 0 ? 'row-even' : 'row-odd'; 
      };

    return (
        <>
            {/* <Card title={<span style={{ float: 'left' }}>{ProcurementContractTypeEnum.Marketing_Fees} </span>} extra={<Button type='primary' onClick={() => setProcurementModal(true)}>{ProcurementContractTypeEnum.Marketing_Fees}</Button>}> */}
                <Table 
                className='purchase-Details'
                columns={procurementColumns} 
                dataSource={procurementTableDataProcessing(procurement)} 
                pagination={false}
                rowClassName={rowClassName}
                style={{overflowX: 'auto'}}
                >

                </Table>
            {/* </Card> */}
 
            <Modal
                open={purchaseContractModal}
                width={1300}
                onCancel={() => { setProcurementModal(false); formRef.resetFields(); setDummyState(prev => prev + 1) }}
                footer={null}
            >
                <ProcurementServiceContract setDummyState={setDummyState} key={dummyState} contractType={ProcurementContractTypeEnum.Marketing_Fees} contractId={contractId} setProcurementModal={setProcurementModal} getProcurementServiceFees={getProcurementServiceFees} closeDrawer={"sales"} />
            </Modal>
        </>



    )
}

export default MarketingFeesForSales