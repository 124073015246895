import { GetAllUsersDto, OrganizationReqDto, UsersIdDto, UsersService } from '@exportx/shared-models-and-services';
import { Input, Table } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, TableActions, useAuthState } from '../../../common';
import { SequenceUtils } from '@exportx/ui-utils';

interface IUserGridProps {
    userData: GetAllUsersDto[]
    createButtonHandler: () => void;
    setInitialValues: Dispatch<SetStateAction<GetAllUsersDto>>;
    selectedClients: number;
    getUsersByOrganizationId: (orgIdReq: OrganizationReqDto) => void
}

export const UserGrid = (props: IUserGridProps) => {
    const { userData, setInitialValues, createButtonHandler, selectedClients, getUsersByOrganizationId } = props;
    const { authContext } = useAuthState();
    const userService = new UsersService();
    const { formatMessage: fm } = useIntl();
    const orgIdReq: OrganizationReqDto = new OrganizationReqDto(authContext.user.userName, authContext.user.id, selectedClients)
    const [searchedText, setSearchedText] = useState('');


    const updateStatus = (usersId: number) => {
        const req = new UsersIdDto(authContext.user.userName, authContext.user.userId, usersId)
        userService.activateDeactivateUsers(req).then((res: any) => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getUsersByOrganizationId(orgIdReq)
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => console.log(err.message));
    }
    const editOnClickHandler = (record: GetAllUsersDto) => {
        if (record.isActive) {
            setInitialValues(record);
            createButtonHandler();
        } else {
            AlertMessages.getErrorMessage('You Cannot Edit Deactivated Record');
        }
    }

    const column = [
        {
            title: fm({ id: 'user.grid.userName', defaultMessage: 'Name' }),
            dataIndex: 'userName',
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
        },
        {
            title: fm({ id: 'user.common.mobileNo', defaultMessage: 'Mobile Number' }),
            dataIndex: 'mobileNo',
        },
        // {
        //     title: fm({ id: 'user.Common.gender', defaultMessage: 'Gender' }),
        //     dataIndex: 'gender',
        // },
        {
            title: fm({ id: 'user.Common.email', defaultMessage: 'Email' }),
            dataIndex: 'email',
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
        },
        {
            title: fm({ id: 'user.Common.plantCode', defaultMessage: 'Plant Code' }),
            dataIndex: 'plantCode',
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
        },
        {
            title: fm({ id: 'user.grid.actions', defaultMessage: 'Actions' }),
            key: 'actions',
            render: (value: any, record: GetAllUsersDto) => {
                return <>
                    <TableActions record={record} editOnClickHandler={editOnClickHandler} statusUpdate={updateStatus} recordId={record.userId} />
                </>
            }
        }
    ]
    console.log('userData', userData)
    return (
        <>
            <div style={{ float: 'right' }}>
                <Input.Search placeholder="Search here..." allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} style={{ width: 200, marginRight: '10px' }} />
            </div>
            <Table className='contracts' columns={column} dataSource={userData} />
        </>
    )
}

