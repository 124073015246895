import { Card } from 'antd';
import { DonutChart } from '../../common/charts/do-nut-chart';

export const InvoiceStatusTracker = () => {
    const chartData = [
        {
            value: 13,
            type: "Open Invoice"
        },
        {
            value: 53,
            type: "Overdue Invoice"
        },
        {
            value: 34,
            type: "Paid Invoice"
        }
    ]
    return (
        <Card title='Invoice  Status Tracker(Receivables)'>
            <DonutChart chartData={chartData} height={"300px"} />
        </Card>
    )
}