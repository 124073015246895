import React from 'react';
import { CostingTypesEnum } from '@exportx/shared-models-and-services';
import { Card, Tabs } from 'antd';
import CoalCostingsGrid from './coal-costings-grid';

const { TabPane } = Tabs;

export const CoalCostings = () => {
    return (
        <Card
            title={<span style={{ color: 'white' }}>Coal Costings</span>}
            style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}
        >

            <Tabs key={CostingTypesEnum.PERFORMA} type={'card'} tabPosition={'top'} defaultActiveKey={CostingTypesEnum.PERFORMA}>
                <TabPane
                    // tab="Open Orders"
                    key={CostingTypesEnum.PERFORMA}
                    tab={<span style={{ color: "#2196f3" }}>{CostingTypesEnum.PERFORMA}</span>}
                >
                    <CoalCostingsGrid coalCostingType={CostingTypesEnum.PERFORMA} />
                </TabPane>
                <TabPane
                    // tab="Open Orders"
                    key={CostingTypesEnum.COMMERCIAL}
                    tab={<span style={{ color: "#2196f3" }}>{CostingTypesEnum.COMMERCIAL}</span>}
                >
                    <CoalCostingsGrid coalCostingType={CostingTypesEnum.COMMERCIAL} />
                </TabPane>
            </Tabs>
        </Card>
    )
}

export default CoalCostings;