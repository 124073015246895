

import { Card } from 'antd'
import Table from 'antd/lib/table'



const CoalCostingDetailView = () => {


    const columns = [
        {
            title: "Barge Id",
            dataIndex: 'bargeId'
        },
        {
            title: "Barge Nomination",
            dataIndex: 'bargeNomination'
        },
        {
            title: "Business Number",
            dataIndex: "businessNo"
        },
        {
            title: "Purchase",
            dataIndex: "purchaseContractNo"
        },
        {
            title: "Quality",
            dataIndex: "quality"
        },
        {
            title: "Purchase Type",
            dataIndex: "purchaseType"
        },
        {
            title: "Quantity",
            dataIndex: "quantity"
        },
        {
            title: "Price PMT",
            dataIndex: "pricePmt"
        },
        {
            title: "Freight Price PMT",
            dataIndex: "freightPricePmt"
        },
        {
            title: "Coal Price",
            dataIndex: "coalPricePMT"
        },
        {
            title: "Advance Adjustment",
            dataIndex: ""
        },
        {
            title: "Total Payable",
            dataIndex: ""
        },
        {
            title: "Paid",
            dataIndex: ""
        },
        {
            title: "Remaining",
            dataIndex: ""
        },

    ]
    const amount = [
        {
            title: "Description",
            dataIndex: ""
        },
        {
            title: "Unit Price",
            dataIndex: ""
        },
        {
            title: "Amount",
            dataIndex: "amount"
        }

    ]
    const invoice = [
        {
            title: "Invoice No",
            dataIndex: "invoiceNo"
        },
        {
            title: "Invoice Date",
            dataIndex: "invoiceDate"
        },
        {
            title: "Fakthur Pazak No",
            dataIndex: ""
        },
        {
            title: "Fakthur Pazak Date",
            dataIndex: ""
        }

    ]
    const approval = [
        {
            title: "Approval Type",
            dataIndex: ""
        },
        {
            title: "Approved by",
            dataIndex: ""
        },
        {
            title: "Approved On",
            dataIndex: ""
        },


    ]
    return (
        <Card title="Barge Performa Coal Costing">


            <Table columns={columns} dataSource={null} scroll={{ x: 2000 }} pagination={false}></Table><br />
            <Table columns={amount} dataSource={null} pagination={false}></Table>
            <Table columns={invoice} dataSource={null} pagination={false}></Table>
            <Card title="Uploaded Documents"></Card>
            <Table columns={approval} dataSource={null} pagination={false}></Table>

        </Card>
    )
}

export default CoalCostingDetailView