import { CheckOutlined, CloseCircleOutlined, EditFilled, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { CompanyTdsGetDataDto, CompanyTdsIdRequestDto, CompanyTdsService } from '@exportx/shared-models-and-services';
import { Button, Card, Divider, Input, InputRef, Popconfirm, Space, Switch, Table, Tooltip } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist } from '../../../../common';


interface DataType {
    key: string;
    fromDate: string;
    toDate: string;
    company: string;
    tds: string;
    tdsValue: string;
}

export const CompanyTdsGrid = () => {
    const navigate = useNavigate()
    const companyTdsService = new CompanyTdsService()
    const [companyTdsData, setCompanyTdsData] = useState<CompanyTdsGetDataDto[]>([])
    type DataIndex = keyof DataType;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    useEffect(()=>{
        if(!isPermissionExist([31])){
            return navigate('/')
        }
    },[])

    useEffect(() => {
        getCompanyTdsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCompanyTdsData = () => {
        companyTdsService.getCompanyTdsData().then((res) => {
            if (res.data) {
                setCompanyTdsData(res.data);
            }
        }).catch(err => {
            console.log(err.message)
        })
    }



    const ActivateDeactivate = (companyTdsId) => {
        const req = new CompanyTdsIdRequestDto(companyTdsId)
        companyTdsService.activateDeactivate(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage)
                getCompanyTdsData()
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message)
        })
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };


    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, }) => (
            <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<any> = [
        {
            title: 'Company',
            dataIndex: 'company',
            ...getColumnSearchProps('company'),


        },
        {
            title: 'From Date',
            dataIndex: 'fromDate',
            ...getColumnSearchProps('fromDate'),

        },
        {
            title: 'To Date',
            dataIndex: 'toDate',
            ...getColumnSearchProps('toDate'),

        },
        {
            title: 'TDS Name',
            dataIndex: 'tds',
            ...getColumnSearchProps('tds'),
            align: 'right'

        },
        {
            title: 'TDS %',
            dataIndex: 'tdsValue',
            ...getColumnSearchProps('tdsValue'),
            align: 'right'

        },
        {
            title: 'Action',
            dataIndex: '',
            width: '20%',


            render: (value, record) => {
                return <span>
                    {isPermissionExist([31]) && (
                    <Tooltip title={'View'}  > 
                        
                            <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }} onClick={() => (navigate('/company-vat-form', { state: { record: record, hidden: true } }))}></EyeOutlined>
                    </Tooltip> 
                    )}
                    <Divider type='vertical' />
                    {isPermissionExist([32]) && (
                    <Tooltip title={record.isActive ? "Edit" : ""}> <EditFilled
                        style={{ fontSize: '24px', color: record.isActive ? " #1890ff" : "#8080807d" }}
                        onClick={() => {
                            if (!record.isActive) {
                                AlertMessages.getErrorMessage('You Could Not Update Deactivated Record')
                            } else {
                                (navigate('/company-vat-form', { state: { record: record, isUpdate: true } }));
                            }
                        }}>

                    </EditFilled>
                    </Tooltip>)}

                    <Divider type='vertical' />
                    {isPermissionExist([33]) && (
                    <Popconfirm onConfirm={() => ActivateDeactivate(record.companyTdsId)} title={`${record.isActive ? "Are you sure to Deactivate Record" : "Are You Sure To Activate Record ?"}`}>
                        <Tooltip title={record.isActive ? 'Deactivate' : "Activate"}>
                            <Switch
                                size='default'
                                className={record.isActive ? 'toggle-activated' : 'toggle-deactivated'}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseCircleOutlined />}
                                checked={record.isActive}
                            />
                        </Tooltip>
                    </Popconfirm >)}
                </span >
            }
        },

    ]
    return (
        <>
            <Card title={"Company Tds Details"}
                className='default-card-class'
                extra={<span>{isPermissionExist([30]) && (<Button onClick={() => { navigate('/company-vat-form') }}>Create</Button>)}</span>}>

                <Table rowKey={(record) => record.companyTdsId} columns={columns} dataSource={companyTdsData}> </Table>


            </Card>
        </>
    )
}

export default CompanyTdsGrid;