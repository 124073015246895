import { CheckOutlined, CloseCircleOutlined, EditFilled, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Input, InputRef, Popconfirm, Space, Switch, Table, TableProps, Tooltip } from 'antd';
import { ColumnType, ColumnsType, FilterConfirmProps } from 'antd/lib/table/interface';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { AlertMessages } from '../notifications';
import { isPermissionExist } from '../iam-client-react';
import { BusinessPartnerTypeEnum } from '@exportx/shared-models-and-services';
interface Iprops {
    mainColumns: ColumnsType<any>;
    data: any[],
    filterColumnsData: { dataIndex: string, placeHolder: string }[],
    otherTableProps?: TableProps<any>,
    activateOrDeactivate: (tableDto: any) => void;
    view: (tableDto: any, isUpdate: boolean) => void;
    rowKey: string;
    bpCategory?: string; 
}

export const FilterableGrid = (props: Iprops) => {
    const { formatMessage: fm } = useIntl();
    const { mainColumns, data, filterColumnsData, otherTableProps, activateOrDeactivate, view, rowKey, bpCategory } = props;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    type DataIndex = string;

    const [hasViewPermission, setHasViewPermission] = useState(false);
    const [hasEditPermission, setHasEditPermission] = useState(false);
    const [hasDeletePermission, setHasDeletePermission] = useState(false);

    useEffect(() => {
        console.log('bpCategory', bpCategory);
    
        const permission: any = {
            countryId: [3, 4, 5],
            currencyId: [7, 8, 9],
            companyCode: [11, 12, 13],
            commodityId: [15, 16, 17],
            basisId: [19, 20, 21],
            taxId: [23, 24, 25],
            shippingTermsId: [51, 52, 53],
            paymentModeId: [55, 56, 57],
            uomId: [59, 60, 61],
        };
    
        if (rowKey === 'bpCategoryId') {
            if (bpCategory === BusinessPartnerTypeEnum.VENDOR) {
                permission.bpCategoryId = [39, 40, 41];
            } else if (bpCategory === BusinessPartnerTypeEnum.CUSTOMER) {
                permission.bpCategoryId = [43, 44, 45];
            } else {
                permission.bpCategoryId = [47, 48, 49];
            }
        }
        console.log('permissons array',permission)
    
        const permissions = permission[rowKey];
        console.log('permissions',permissions)
    
        if (permissions) {
            setHasViewPermission(isPermissionExist([permissions[0]]));
            setHasEditPermission(isPermissionExist([permissions[1]]));
            setHasDeletePermission(isPermissionExist([permissions[2]]));
        } else {
            setHasViewPermission(false);
            setHasEditPermission(false);
            setHasDeletePermission(false);
        }
    }, [rowKey, bpCategory]); 
    

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };



    const getColumnSearchProps = (dataIndex: DataIndex, placeHolder: any): ColumnType<typeof mainColumns> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${placeHolder}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    {/* <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button> */}
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            handleReset(clearFilters)
                            setSearchedColumn(dataIndex);
                            confirm({ closeDropdown: true });
                            // setSearchText((selectedKeys as string[])[0]);


                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const filterColumnDataIndex = filterColumnsData.map(obj => obj.dataIndex);
    const columns: ColumnsType<any> = mainColumns.map(column => {
        const columnsObj = filterColumnDataIndex.includes(column['dataIndex']) ? {
            ...column,
            ...getColumnSearchProps(column['dataIndex'], column['title'])
        } : { ...column }
        return columnsObj;
    });

    columns.push(
        {
            title: fm({ id: "common.action", defaultMessage: 'Action' }),
            dataIndex: 'action',
            width: '20%',
            align: 'center',
            render: (text, RowData: any) => (
                <span>
                    {(hasViewPermission) && (
                    <Tooltip placement="top" title={fm({ id: "common.view" })}>
                        <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }} onClick={e => {
                            view(RowData, false);
                        }} />
                    </Tooltip>
                    )}
                    <Divider type="vertical" />
                    {(hasEditPermission) && (
                    <Tooltip placement="top" title={fm({ id: "common.edit" })}>
                        <EditFilled onClick={e => {
                            if (RowData.isActive) {
                                view(RowData, true);
                            } else {
                                AlertMessages.getErrorMessage(fm({ id: `common.cannotEditDeactivated` }))
                            }
                        }} style={{ fontSize: '24px' }} className={RowData.isActive ? 'Editable-Icon' : 'disabled-Edit-Icon'} />
                    </Tooltip>)}
                    <Divider type="vertical" />
                    {(hasDeletePermission) && (
                    <Popconfirm
                        onConfirm={e => {
                            activateOrDeactivate(RowData);
                        }}
                        title={RowData.isActive ? fm({ id: "common.deActivateConfirmMsg", defaultMessage: 'Are you sure to Deactivate Record ?' }) : fm({ id: "common.activateConfirmMsg", defaultMessage: 'Are you sure to activate Record ?' })}>
                        <Tooltip placement="top" title={RowData.isActive ? fm({ id: "common.deActivate", defaultMessage: 'Deactivate' }) : fm({ id: "common.activate", defaultMessage: 'Activate' })}>
                            <Switch size='default'
                                className={RowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseCircleOutlined />}
                                checked={RowData.isActive} />
                        </Tooltip>
                    </Popconfirm>
                    )}
                </span>)
        }
    )


    return <Table rowKey={(RowData) => RowData[`${rowKey}`]} columns={columns} dataSource={data} {...otherTableProps} />;

}

export default FilterableGrid;