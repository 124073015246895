import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { BGCostingParentReqDto, BPFCostingParentDto, BargeCostingsService, CommercialCoalCostingService, ContractModesEnum, ContractStatusEnum, CostingWorkLogsEnum, PerformaCoalCostingService, ReferenceFeatures } from '@exportx/shared-models-and-services';
import { SequenceUtils, getLocalFormat } from '@exportx/ui-utils';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../../common';
import { BargeCostingChild } from './barge-costing-child';
import { Input } from 'antd'
import { PaymentNoticePageEnum } from '../../../payment-notice';

interface IBargeCostingApproval {
    type?: ContractStatusEnum;
    costingType: CostingWorkLogsEnum;
    contractType?: ContractModesEnum;
    paymentType?: PaymentNoticePageEnum
}

export enum BargeCostingApprovalScreen {
    PMT_NOTICE = 'pmt-notice'
}
export const BargeCostingApproval = (props: IBargeCostingApproval) => {
    const { type, costingType, contractType, paymentType } = props;
    const [expandedIndex, setExpandedIndex] = useState([]);
    const bargeCostingsService = new BargeCostingsService();
    const perFormaService = new PerformaCoalCostingService();
    const commercialService = new CommercialCoalCostingService();
    const { authContext } = useAuthState();
    const [bargeCostingData, setBargeCostingData] = useState<BPFCostingParentDto[]>([]);
    const [searchedText, setSearchedText] = useState("");
   
   
   
    const costingsApiReq = new BGCostingParentReqDto();
    costingsApiReq.status = ContractStatusEnum.PENDING_APPROVAL;
    if (type === ContractStatusEnum.AUDIT_APPROVAL) {
        costingsApiReq.status = ContractStatusEnum.PENDING_AUDIT_APPROVAL
    }
    else if (type === ContractStatusEnum.MANAGEMENT_APPROVAL) {
        costingsApiReq.status = ContractStatusEnum.PENDING_MANGEMENT_APPROVAL
    }
    costingsApiReq.plantCode = authContext.defaultPlant;
    costingsApiReq.costingType = costingType


    useEffect(() => {
        if (costingType === CostingWorkLogsEnum.BARGE_COSTING) {
            getBargeCostingParentData();
        } else if (costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING || costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING || costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING) {
            getPerFormData();
        } else if (costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING || costingType === CostingWorkLogsEnum.PROCUREMENT_FEES || costingType === CostingWorkLogsEnum.MARKETING_FEES) {
            getCommercialData();
        }
    }, []);


    const getBargeCostingParentData = () => {
        const bgCostingReq = new BGCostingParentReqDto();
        bgCostingReq.status = type === ContractStatusEnum.PMT_NOTICE ? ContractStatusEnum.ACTIVE :type === ContractStatusEnum.BUSINESS_APPROVAL ? ContractStatusEnum.PENDING_APPROVAL : ContractStatusEnum.PENDING_AUDIT_APPROVAL;
        bgCostingReq.plantCode = authContext.defaultPlant;
        bgCostingReq.costingType = costingType
        bargeCostingsService.getBargeCostingParentData(bgCostingReq).then(res => {
            if (res.status) {
                setBargeCostingData(res.data);
            }
        }).catch(err => {
            console.log(err.message);
        })
    }

    const getPerFormData = () => {
        perFormaService.getPerformaApprovalCostings(costingsApiReq).then(res => {
            if (res.status) {
                setBargeCostingData(res.data);
            }
        }).catch(err => console.log(err.message))
    }

    const getCommercialData = () => {
        commercialService.getCommercialApprovalCostings(costingsApiReq).then(res => {
            if (res.status) {
                setBargeCostingData(res.data);
            }
        }).catch(err => console.log(err.message))
    }

    const getRoutes = (costingType) => {
        const routes = {
            [CostingWorkLogsEnum.BARGE_COSTING]: 'barge-costings/barge',
            [CostingWorkLogsEnum.PERFORMA_PC_COSTING]: 'performaCoalCosting',
            [CostingWorkLogsEnum.PERFORMA_SC_COSTING]: 'performaCostingSale',
            [CostingWorkLogsEnum.COMMERCIAL_PC_COSTING]: `commercial-coal-costing`,
            [CostingWorkLogsEnum.COMMERCIAL_SC_COSTING]: `commercial-costing-sales`,
            [CostingWorkLogsEnum.PROCUREMENT_FEES]: `procurement-coal-costing`,
            [CostingWorkLogsEnum.MARKETING_FEES]: `marketing-coal-costing`,
            [CostingWorkLogsEnum.SALES_FREIGHT_COSTING]: `salesFreightCosting`,
        }
        return routes[costingType]
    };


    const tableColumns: ColumnsType<any> = [
        {
            title: 'Costing Number',
            dataIndex: 'costingNo',
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },
            render: (value, record) => {
                return <>
                    < a href={`/#/${getRoutes(costingType)}?costingId=${record.costingId}`} className="link-primary" >
                        {value}
                    </a >
                </>
            }
        },
        {
            title: 'Date',
            dataIndex: 'costingDate',
            render: (value, record) => {
                return <>{moment(value).format('YYYY-MM-DD')}</>
            }
        },

        {
            title: 'Purchase ContractNo',
            dataIndex: 'purchaseContractNo',
            render: (v, record) => {
                const id = costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || costingType === CostingWorkLogsEnum.PROCUREMENT_FEES || costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING ? 'pc_id' : 'sc_id';
                const route = costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || costingType === CostingWorkLogsEnum.PROCUREMENT_FEES || costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING ? 'p' : 's';
                const link = `/#/${route}o-detail-view?${id}=${record.pcId}`
                return <>
                    {<a href={link}>{v}</a>}
                </>
            }
        },
        {
            title: 'ContractNO',
            dataIndex: 'contractNo',
            render: (v, record) => {
                const link = `/#/${record.contractType}-detailedView?cpcId=${record.contractId}`
                return <>
                    {<a href={link}>{v}</a>}
                </>
            }
        },


        {
            title: 'Supplier Name',
            dataIndex: 'bargeSupplier',
        },
        {
            title: 'Amount To Be Paid',
            dataIndex: 'totalAmount',
            align: 'right',
            render: (value, record) => {
                return <>{getLocalFormat(value, authContext.defaultPlantCurrency)}</>
            }
        },

    ]

    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.costingId);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    }
    const renderItems = (record: any, index, indent, expanded) => {
        return <>
            <BargeCostingChild paymentType={paymentType} contractType={contractType} type={type} costingInfo={record} getBargeCostingParentData={getBargeCostingParentData} costingType={costingType} />
        </>
    };

    return (
        <>
            <Input.Search
                placeholder="Search"
                allowClear
                onChange={(e) => { setSearchedText(e.target.value) }}
                onSearch={(value) => { setSearchedText(value) }}
                style={{ width: 200, float: "right" }}
            />

            <Table
                size='small'
                className='contracts'
                rowKey={record => record.costingId}
                columns={tableColumns}
                dataSource={bargeCostingData}
                expandable={{
                    expandedRowRender: renderItems,
                    expandedRowKeys: expandedIndex,
                    onExpand: setIndex,
                    fixed: 'right'
                }}
                expandIcon={({ expanded, onExpand, record }) =>
                    expanded ? (
                        <UpCircleFilled
                            onClick={(e) => onExpand(record, e)}
                        >
                            Collapse
                        </UpCircleFilled>
                    ) : (
                        <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
                    )
                }
                scroll={{ x: 500 }}
                bordered
            />

        </>
    )
}

export default BargeCostingApproval;