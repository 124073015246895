import React, { useEffect, useState } from 'react'
import {MarkupPriceIdReqDto, MarkupPriceService, ContractStatusEnum, MarkupPriceApproveReqDto } from '@exportx/shared-models-and-services'
import { Affix, Button, Col, Descriptions, Row, Tag } from 'antd'
import Card from 'antd/lib/card/Card'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { AlertMessages, useAuthState } from '../../../../common'

const MarkupPriceDetailedView = () => {
  const [gridData, setGridData] = useState<any>()
  const { authContext } = useAuthState()
  const { state }: any = useLocation()
  const { id } = state ? state : { id: null }
  const Navigate = useNavigate()

  useEffect(() => {
    getMarkupPriceInfo()
  }, [id])

  const mupService = new MarkupPriceService()

  const getMarkupPriceInfo = () => {
    const req = new MarkupPriceIdReqDto(id, authContext.defaultPlant)
    mupService.getMarkupPriceById(req).then(res => {
      if (res.status) {
        setGridData(res.data)
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const approveOrRejectHandler = (status: ContractStatusEnum) => {
    const req = new MarkupPriceApproveReqDto(id, authContext.defaultPlant, status, authContext.user.userName)
    mupService.approveMarkupPrice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getMarkupPriceInfo();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button size="small" type='primary' onClick={() => approveOrRejectHandler(ContractStatusEnum.PENDING_APPROVAL)}>Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <span>
        <Button size="small" type='primary' onClick={() => approveOrRejectHandler(ContractStatusEnum.ACTIVE)}>Approve</Button> &nbsp;&nbsp;
        <Button type='dashed' size="small" onClick={() => approveOrRejectHandler(ContractStatusEnum.REJECT)}>Reject</Button>
      </span>
    }

  }

  return (
    <Card title={<span>Markup Price - <Tag color={'blue'}>{gridData?.featureNo}</Tag></span>}
      size='small'
      extra={<>{getExtraComponent(gridData?.status)}</>}
    >
      <Descriptions layout='horizontal' size='small'>
        <Descriptions.Item label='Business Number' >{gridData?.businessNo}</Descriptions.Item>
        <Descriptions.Item label='Sales Contract'>{gridData?.saleContractNo}</Descriptions.Item>
        <Descriptions.Item label='Exchange Rate' >{gridData?.exchangeRate}</Descriptions.Item>
        <Descriptions.Item label='Approved By' >{gridData?.approvedBy}</Descriptions.Item>
        <Descriptions.Item label='Approved On' >{gridData?.approvedOn ? moment(gridData?.approvedOn)?.format('DD-MMM-YYYY') : ''}</Descriptions.Item>
      </Descriptions>

      <br />

      <table className='table table-sm table-bordered'>
        <thead className='table-header'>
          <tr className="bg-primary text-white">
            <th className='text-center'>Barge</th>
            <th className='text-center' >Quantity</th>
            <th className='text-center' >IDR PMT </th>
            <th className='text-center'  >Total</th>
            <th className='text-center' >VAT</th>
            <th className='text-center' >Total After VAT</th>
            <th className='text-right' >USD PMT </th>
          </tr>

        </thead>
        <tbody>
          <tr>
            <th colSpan={7}>Coal Costing</th>
          </tr>
          {gridData?.coalCostingData?.map((ccd) => (
            <tr key={ccd.id}>
              <td>{ccd.bargeName}</td>
              <td>{ccd.quantity}</td>
              <td>{ccd.priceIdr}</td>
              <td>{ccd.total}</td>
              <td>{ccd.ppnId || '-'}</td>
              <td>{ccd.priceAfterVat || ccd.total }</td>
              <td className=' text-right'>{ccd.priceUsd}</td>
            </tr>
          ))}
          <tr>
            <th colSpan={7}>Coal Service</th>
          </tr>

          {gridData?.coalServiceData?.map((ccd) => (
            <tr key={ccd.id}>
              <td>{ccd.bargeName}</td>
              <td>{ccd.quantity}</td>
              <td>{ccd.priceIdr}</td>
              <td>{ccd.total}</td>
              <td>{ccd.ppnId || '-'}</td>
              <td>{ccd.priceAfterVat || ccd.total }</td>
              <td className=' text-right'>{ccd.priceUsd}</td>
            </tr>
          ))}

          <tr>
            <th colSpan={7}>Barge Costing</th>
          </tr>
          {gridData?.bargeCostData?.map((ccd) => (
            <tr key={ccd.id}>
              <td>{ccd.bargeName}</td>
              <td>{ccd.quantity}</td>
              <td>{ccd.priceIdr}</td>
              <td>{ccd.total}</td>
              <td>{ccd.ppnId || '-'}</td>
              <td>{ccd.priceAfterVat || ccd.total }</td>
              <td className=' text-right'>{ccd.priceUsd}</td>
            </tr>
          ))}


          <tr>
            <th colSpan={7}>Documentation Charges</th>
          </tr>
          {gridData?.docData?.map((ccd) => (
            <tr key={ccd.id}>
              <td>{ccd.bargeName}</td>
              <td>{ccd.quantity}</td>
              <td>{ccd.priceIdr}</td>
              <td>{ccd.total}</td>
              <td>{ccd.ppnId || '-'}</td>
              <td>{ccd.priceAfterVat || ccd.total }</td>
              <td className=' text-right'>{ccd.priceUsd}</td>
            </tr>
          ))}


          <tr>
            <td className='table-form'><b>Coo </b> </td>
            <td className='table-form'> {gridData?.cooQty} </td>
            <td className='table-form'>{gridData?.cooPriceIdr}</td>
            <td className='table-form'>{gridData?.cooTotal}</td>
            <td className='table-form'>{gridData?.cooPpnName || '-'}</td>
            <td className='table-form'>{gridData?.cooPriceAftExchg || gridData?.cooTotal}</td>
            <td className='table-form  text-right'>{gridData?.cooPriceUsd}</td>


          </tr>

          <tr>
            <td className='table-form'> <b>Total</b></td>
            <td className='table-form'>{gridData?.totalQty}</td>
            <td className='table-form'>{gridData?.totalPriceIdr}</td>
            <td className='table-form'>{gridData?.total}</td>
            <td className='table-form'>{gridData?.totalPpnName || '-'}</td>
            <td className='table-form'>{gridData?.totalPriceAftExchg || gridData?.total}</td>
            <td className='table-form  text-right'>{gridData?.totalPriceUsd}</td>
          </tr>
          <tr>
            <td
              className='table-form'
            >
              {`Mark up ${gridData?.markupPercent} %`}
            </td>
            <td colSpan={5}></td>
            <td
              className='table-form text-right'
            >
              {gridData?.markupPriceUsd}
            </td>
          </tr>

          <tr>
            <td className='table-form'>
              <b>With Kawal</b>
            </td>
            <td className='table-form'>{gridData?.kawalQty}</td>
            <td className='table-form'>{gridData?.kawalPriceIdr}</td>
            <td className='table-form'>{gridData?.kawalTotal}</td>
            <td className='table-form'>{gridData?.kawalPpnName || '-'}</td>
            <td className='table-form'>{gridData?.kawalPriceAftExchg || gridData?.kawalTotal}</td>
            <td className='table-form text-right'>{gridData?.kawalPriceUsd}</td>
          </tr>

          <tr>
            <td colSpan={6}><b>Final Price</b></td>
            <td className=' text-right'><b>{gridData?.finalPrice}</b></td>
          </tr>


        </tbody>

      </table>

      <Affix offsetBottom={0}>
        <Card bodyStyle={{ padding: '6px 6px ' }}>
          <Row >
            <Col ><Button danger type='primary' onClick={() => Navigate(-1)}>Cancel</Button></Col>
            {gridData?.status !== ContractStatusEnum.ACTIVE && <Col offset={18} style={{ paddingLeft: 160 }}><Button type='primary' onClick={() => Navigate('/markup-price-form', { state: { id: gridData?.markupPriceId } })}>Edit</Button></Col>}
          </Row>
        </Card>
      </Affix>

    </Card >
  )
}

export default MarkupPriceDetailedView