import { DeleteFilled, PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { BargeService } from '@exportx/shared-models-and-services';
import { Affix, Button, Card, Col, Form, FormInstance, Input, InputNumber, Row, Table, Tooltip, Upload, UploadFile, UploadProps, message } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import moment from 'moment';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages, useAuthState } from '../../../../../common';
import DatePicker from '../../../../../common/data-picker/date-picker';
import ViewFileHandling from '../../../../../common/file-upload-form/view-files-handling';

interface IBlCreationProps {
    formRef: FormInstance<any>;
    initialValues: any;
    children?: React.ReactNode;
    layoutType: FormLayout;
    onResets?: () => void;
    blCreationSaveData?: () => void;
    gridData: any;
    setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>

}

const BlCreation = (props: IBlCreationProps) => {
    const { formRef, layoutType, onResets, blCreationSaveData, setDummyRefresh, gridData } = props;
    const { authContext } = useAuthState()
    const service = new BargeService();
    const { formatMessage: fm } = useIntl();
    const [blFileList, setBLFileList] = useState<any[]>([]);
    const [fileList, setFileList] = useState<any>()
    const [viewForm, setViewForm] = useState<boolean>(false)
    const [initialValues, setInitialValues] = useState({ billOfLading: [' '], fileList })


    const Columns: any = [
        {
            title: "BL Date",
            dataIndex: "blDate",
            render: (text, record) => { return record.blDate !== undefined ? moment(record.blDate).format('DD-MM-YYYY') : "" },

        },
        {
            title: "BL Qty",
            dataIndex: "blQty"
        },
        {
            title: "BL No",
            dataIndex: "blNo"
        },
        {
            title: "Upload Document",
            render: (value, record) => {
                return <>
                    <ViewFileHandling filesData={record.filesData} required={true} />
                </>
            }
        }
    ];

    const setFormData = () => {
        setViewForm(true);
        formRef.resetFields()
        if (gridData) {
            if (gridData?.billOfLading) {
                const billOfLading = [...gridData?.billOfLading];
                billOfLading?.map((rec: any, index) => {
                    gridData.billOfLading[index] = {
                        ...rec,
                        blDate: moment(rec?.blDate),
                        fileUploadId: rec?.filesData[0]?.fileUploadId
                    }
                });
                formRef.setFieldsValue(gridData);
            };
        };
    };



    const validateFileType = ({ type, name }: UploadFile, allowedTypes: string[] = ['image/jpeg']) => {
        return true;//allowedTypes.includes(type!);
    };
    const getUploadProps = (indexOfThatFile: number, stateVariableSetter: React.Dispatch<React.SetStateAction<any[]>>, stateVariable: any[]) => {
        const uploadProps = {
            multiple: false,
            beforeUpload: (file: UploadFile) => {
                const isAllowedType = validateFileType(file);
                if (!isAllowedType) {
                    message.error(`${file.name} is not JPEG file`);
                    return false;
                }
                stateVariableSetter((prev) => {
                    if (prev.length < 5) {
                        const clonedData = [...prev];
                        clonedData[indexOfThatFile] = file;
                        return clonedData;
                    }
                    return prev;
                });
                return false;
            },
            onRemove: (file: UploadFile) => {
                stateVariableSetter((prev) => {
                    const clonedData = [...prev];
                    clonedData[indexOfThatFile] = [];
                    return clonedData;
                });
            },
            fileList: stateVariable[indexOfThatFile] ? [stateVariable[indexOfThatFile]] : []
        } as UploadProps;
        return uploadProps;
    };

    const uploadButton = (
        <div>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </div>
    );

    return (

        <Card title="BL creation">
            {!viewForm ? <>

                <Table columns={Columns} pagination={false}
                    dataSource={gridData?.billOfLading} ></Table></> : <></>}

            <Button style={{ float: 'right', display: `${viewForm ? 'none' : ''}` }}
                onClick={() => { setFormData(); }}
                type='primary'>Edit</Button>

            {viewForm ? <Form
                initialValues={initialValues}
                form={formRef}
                layout={layoutType}
                autoComplete="off" >

                <Form.List name='billOfLading'>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }, index) => (
                                <Row >
                                    <Col
                                        xs={{ span: 22 }}
                                        sm={{ span: 22 }}
                                        md={{ span: 22 }}
                                        lg={{ span: 22 }}
                                        xl={{ span: 22 }}
                                    >
                                        <Row>
                                            <Col
                                                xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                            >
                                                <Form.Item
                                                    label={'BL No'}

                                                    name={[name, 'blNo']}
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please Enter BL No'
                                                        }
                                                    ]}
                                                >
                                                    <Input placeholder={'Please Enter BL No'} autoComplete='off' />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                            >
                                                <Form.Item
                                                    label={'BL Qty'}

                                                    name={[name, 'blQty']}
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please Enter BL Qty'
                                                        }
                                                    ]}
                                                >
                                                    <InputNumber style={{ width: '100%' }} placeholder={'Please Enter BL Qty'} autoComplete='off' />
                                                </Form.Item>
                                            </Col><Col
                                                xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                            >
                                                <Form.Item
                                                    label={'BL Date'}

                                                    name={[name, 'blDate']}
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please Enter BL Date'
                                                        }
                                                    ]}
                                                >
                                                    <DatePicker placeholder={"Please Enter BL Date"} autoComplete='off' showTime format="YYYY-MM-DD" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}
                                            >

                                                <Form.Item
                                                    label={'UploadDocument'}

                                                    name={[name, 'filesData']}
                                                >
                                                    <Upload
                                                        style={{ width: 130 }}
                                                        multiple={false}
                                                        listType="text"
                                                        showUploadList
                                                        {...getUploadProps(index, setBLFileList, blFileList)}

                                                    >{uploadButton}</Upload>
                                                </Form.Item>
                                                {gridData?.billOfLading?.length === undefined ? <></> : <ViewFileHandling key={index + 1} filesData={gridData?.billOfLading[index]?.filesData} required={false} />}
                                            </Col>
                                            <Form.Item name={[name, "fileUploadId"]}>
                                                <Input hidden>

                                                </Input>
                                            </Form.Item>
                                            <Col
                                                xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }} xl={{ span: 6, offset: 2 }}
                                            >
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={{ span: 2 }}
                                        sm={{ span: 2 }}
                                        md={{ span: 2 }}
                                        lg={{ span: 2 }}
                                        xl={{ span: 2 }}>

                                        <span style={{
                                            position: "absolute",
                                            marginTop: "33px",
                                            marginLeft: "35px",
                                        }}>
                                            <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                                <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(name)} />
                                            </Tooltip>

                                        </span>
                                    </Col>
                                </Row>
                            ))}

                            <Row>
                                <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    Add Documents <span>
                                        <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                            <PlusCircleOutlined onClick={() => {
                                                if (5 > fields.length) {
                                                    add()
                                                } else {
                                                    AlertMessages.getErrorMessage(`You can't add more than ${5} fields`)
                                                }
                                            }} />
                                        </Tooltip>
                                    </span>
                                </Col>
                                <Col xl={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}></Col>
                            </Row>
                        </>)}
                </Form.List>
                <Affix offsetBottom={0}>
                    <Card bodyStyle={{ padding: '6px 12px' }}>
                        <Row justify='space-between'>
                            <Button type='primary' ghost onClick={onResets}>Clear</Button>&nbsp;
                            <Button type='primary' onClick={blCreationSaveData} >Submit</Button>
                        </Row>
                    </Card>
                </Affix>
                <span style={{ float: 'right' }}>

                </span>


                {props?.children}
            </Form> : <></>}
        </Card>

    )
}
export default BlCreation