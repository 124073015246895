import { ContractTypesEnum, PurchaseContractDto, PurchaseTermDataDto, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Collapse } from 'antd';
import ASHDataView from './ash-data-view';
import { AshModel } from './ash.model';
import TypicalDataView from './typical-data-view';
import { TypicalModel } from './typical.model';
import UltimateDataView from './ultimate-data-view';
import { UltimateModel } from './ultimate.model';
export interface IAnalysisData {
    purchaseTermData: PurchaseTermDataDto,
    specType: SpecTypesEnum;
    snapShotPurchaseTermData: PurchaseTermDataDto;
    snapSpecType: SpecTypesEnum;
    contractType: ContractTypesEnum;
    showAdditionalData:Boolean;
}
const { Panel } = Collapse;
export const AnalysisDataView = (props: IAnalysisData) => {
    const { purchaseTermData, specType, contractType, snapShotPurchaseTermData } = props;
    const assignObjectValues = (childObject: any, parentObject: any) => {
        Object.keys(childObject).forEach(key => {
            childObject[key] = parentObject?.[key];
        })
        return childObject;
    }
    return (
        <>
                <TypicalDataView typical={assignObjectValues(new TypicalModel(), specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData)} contractType={contractType} addendumTypical={assignObjectValues(new TypicalModel(), specType == SpecTypesEnum.ASTM ? snapShotPurchaseTermData?.astmData : snapShotPurchaseTermData?.isoData)} />
        </>
    )
}

export default AnalysisDataView;