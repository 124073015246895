import { Card, Col, Form, FormInstance, Row } from 'antd'
import DatePicker from '../../../../common/data-picker/date-picker';
import { FormLayout } from 'antd/lib/form/Form';
import moment from 'moment';
import React from 'react'
import { useIntl } from 'react-intl';


interface IBargeTimingProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;

}
export const BargeTimings = (props: IBargeTimingProps) => {
  const { formRef, layoutType, initialValues } = props;
  if (initialValues) {
    initialValues.loadingDate = initialValues?.loadingDate ? moment(initialValues?.loadingDate, 'YYYY-MM-DD  HH:mm:ss') : undefined;
    initialValues.completionTime = initialValues?.completionTime ? moment(initialValues?.completionTime, 'YYYY-MM-DD  HH:mm:ss') : undefined;
    initialValues.norAnchorage = initialValues?.norAnchorage ? moment(initialValues?.norAnchorage, 'YYYY-MM-DD  HH:mm:ss') : undefined;
  }


  const { formatMessage: fm } = useIntl();


  return (
    <Card title="Barge Timings">
      <Form initialValues={initialValues}
        form={formRef}
        layout={layoutType}
        autoComplete="off" >
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="loadingDate" label={fm({ id: "mdm.components.bargetimings.loadingDate", defaultMessage: 'Loading Date (Jetty)' })} rules={[{ required: false, message: "Loading Date Is Required" }]}>
              <DatePicker
                placeholder={fm({ id: `mdm.components.bargetimings.reqLoadingDate`, defaultMessage: ` Select date&time` })}
                autoComplete='off'
                showTime format="DD-MM-YYYY  HH:mm"
         
              />
            </Form.Item></Col>
          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="completionTime" label={fm({ id: "mdm.components.bargetimings.completionTime", defaultMessage: 'Loading Completion Time (Jetty)' })}>
              <DatePicker
                placeholder={fm({ id: `mdm.components.bargetimings.reqcompletionTime`, defaultMessage: ` Select date&time` })}
                autoComplete='off'
                showTime
                format="DD-MM-YYYY HH:mm"
               
              />
            </Form.Item></Col>

          <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name="norAnchorage" label={fm({ id: "mdm.components.bargetimings.norAnchorage", defaultMessage: "N Anchorage" })}
            >
              <DatePicker 
              placeholder={fm({ id: `mdm.components.bargetimings.reqNorAnchorage`, defaultMessage: ` Select date&time` })}
               autoComplete='off'
                showTime
                 format="DD-MM-YYYY  HH:mm" 
                 
                 />
            </Form.Item>
          </Col>
        </Row>

      </Form>
      {props?.children}
    </Card>
  )
}
export default BargeTimings