import { CPSPreviewDto, ContractStatusEnum, ContractTypeReqDto, ProcurementContractService, ProcurementContractTypeEnum } from '@exportx/shared-models-and-services';
import { Button, Card, Divider, Input, Row, Select, Tag, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import StatusApprovalsButton from '../../../common/status-tag/status-approvals-button-component';
import { Option } from 'antd/lib/mentions';

interface contractTypeCategory {
  contractTypeCategory?: ProcurementContractTypeEnum;
}


export const ProcurementServiceGrid = (props: contractTypeCategory) => {
  const { formatMessage: fm } = useIntl();
  const [gridData, setGridData] = useState<any>([])
  const service = new ProcurementContractService()
  const navigate = useNavigate();
  const [searchedText, setSearchedText] = useState("")
  const { contractTypeCategory } = props
  const { authContext } = useAuthState();
  const [createPemission, setCreatePermission] = useState(false);
  const [, setPage] = useState(1);
  const [status, setStatus] = useState<any>('');


  let pathToreDirect = '';
  if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
    pathToreDirect = '/procurement-contract-detailedView'
  }
  if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
    pathToreDirect = '/coalServiceFee-detailedView'
  }
  if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
    pathToreDirect = '/marketing-detailedView'
  }

  useEffect(() => {
    console.log('contractTypeCategory',contractTypeCategory)
    let permission;

    if(contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees){
      permission = [147];
      setCreatePermission(isPermissionExist([146]))
    } else if(contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees){
      permission = [165]
      setCreatePermission(isPermissionExist([164]))
    } else {
      permission = [156]
      setCreatePermission(isPermissionExist([155]))
    }
    
    if(!isPermissionExist(permission)) navigate('/')
    if (contractTypeCategory) {
      getAllServiceContracts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractTypeCategory]);

  const getAllServiceContracts = () => {
    const req = new ContractTypeReqDto();
    req.contractType = contractTypeCategory;
    req.plantCode = authContext.defaultPlant;
    service.getAllServiceContracts(req).then(res => {
      if (res.status) {
        setGridData(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };


  const procurementStatusApproval = (status: ContractStatusEnum, id: string) => {
    const req = new CPSPreviewDto(id, authContext.defaultPlant, undefined, status, authContext.user.userName)
    service.procurementStatusApproval(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAllServiceContracts()
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      console.log(err.message);
    })
  }


  const columns: ColumnsType<any> = [
    {
      title: fm({ id: "mdm.components.procurmentService.contractNumber", defaultMessage: 'Contract Number' }),
      dataIndex: 'contractNo',
      key: 'contractNo',
      sorter: (a, b) => a.contractNo !== null ? a.contractNo.localeCompare(b.contractNo) : "Have Null Values",
      filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.vendor)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.status)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
      render: (value, record) => {
        const link = `/#${pathToreDirect}?cpcId=${record.pcID}`
        // return PermissionExist('view') ?  <Link href={link} className="link-primary" >{record.contractNo}</Link> : <span>{record.contractNo}</span>
        return <a href={link} className="link" >{record.contractNo}</a>
      },
    },

    {
      title: fm({ id: "mdm.components.procurmentService.contractDate", defaultMessage: 'Contract Date' }),
      dataIndex: 'contractDate',
      sorter: (a, b) => a.contractDate !== null ? a.contractDate.localeCompare(b.contractDate) : "Have Null Values",
      render: (text, record) => { return <span className='table-other-columns'>{record.contractDate !== undefined ? moment(record.contractDate).format('DD-MMM-YYYY') : ""}</span> },
      key: 'contractDate',

    },

    {
      title: fm({ id: "mdm.components.procurmentService.vendorName", defaultMessage: 'Vendor Name' }),
      dataIndex: 'vendor',
      sorter: (a, b) => a.vendor !== null ? a.vendor.localeCompare(b.vendor) : "Have Null Values",
      render: (value, record) => {
        return <span className='table-name-column'>{value}</span>
      },
      key: 'vendor',

    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status != null ? a.status.localeCompare(b.status) : "Have Null Values",
      render: (value, record) => {
        return <>
          <StatusTag status={value} />
        </>
      }
    },
    {
      title: fm({ id: 'mdm.components.procurmentService.status', defaultMessage: "Status" }),
      dataIndex: 'Action',
      hidden: isPermissionExist([151, 160, 169, 152, 161, 170]),
      sorter: (a, b) => a.status !== null ? a.status.localeCompare(b.status) : "Have Null Values",
      render: (_record, value) => <>
        <StatusApprovalsButton status={value.status} statusApproval={procurementStatusApproval} id={value.pcID} from={contractTypeCategory} />
      </>,

    },

  ];

  const createHandler = () => {
    let path = '/procurement-service-create';
    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
      path = '/procurement-service-create'
    } else if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
      path = '/marketing-service-create'
    } else {
      path = '/coalServiceFee-service-create'
    }
    navigate(path)
  }

  // Handle status change
  const handleStatusChange = (value) => {
    setStatus(value);
  };

  // Filter data based on selected status
  const filteredData = status ? gridData.filter((item) => item.status === status) : gridData;

  // const PermissionExist =(type: string) =>{
  //   if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
  //     return type === 'Create' ?  isPermissionExist([146])  : isPermissionExist([147])
  //   } else if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
  //     return type === 'Create' ? isPermissionExist([164])  : isPermissionExist([165])
  //   }
  //   return type === 'Create' ? isPermissionExist([155]) : isPermissionExist([156])

  // }

  
  return (

    <>
    <Card className='card-container'
        title={<span className='card-title'> {contractTypeCategory} Contract 
        </span>}
        headStyle={{border: 'none'}}>
      <Card
        className='contracts-list card-radius'
        title={<Row className='card-title-buttons'>
        <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchedText(e.target.value) }} onSearch={(value) => { setSearchedText(value) }} className='input-search' />
        <Select
        placeholder="Filter by Status"
        className='filter-by-status'
        allowClear
        onChange={handleStatusChange}
      >
        <Option value="Draft">Draft</Option>
        <Option value="Send For Approval">Send for Approval</Option>
        <Option value="Active">Active</Option>
      </Select>
      </Row>}
        headStyle={{border: 'none'}}
        extra={createPemission ? <Tooltip placement="topRight" title="Create"><Row className='mt-16'><Button className='new-button'  size="small" type="primary"
          onClick={() => createHandler()
          }
        >
          Create
        </Button></Row></Tooltip> : null}>
        <Table columns={columns} dataSource={filteredData} 
        className='contracts-list'
        pagination={{
          onChange(current) {
            setPage(current);
          },
          showTotal: (total) => (
            <span 
           className='page-total-items'
            >Total {total} items</span>
          ),
          showSizeChanger: true, 
          pageSizeOptions: ['25', '50', '100'],
          defaultPageSize: 25,
          style: {
            paddingTop: '20px',
          },
        }}
        scroll={{ x: 500 }}
          rowKey={(row) => row.pcID} />
      </Card>
      </Card>
    </>



  )
}

export default ProcurementServiceGrid;