import { StyleSheet, View } from '@react-pdf/renderer';
import InvoiceFooter from './invoice-footer';
import InvoiceHeader from './invoice-header';
import InvoiceMiddleRow from './invoice-Middle';


interface InvoiceItemsTableIProps {
    invoice: any;
    demurrageName: string;
}


const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
    },
});

const InvoiceItemsTable = (props: InvoiceItemsTableIProps) => {
    const { invoice, demurrageName } = props

    return <>
        < View style={styles.tableContainer} >
            <InvoiceHeader demurrageName={demurrageName} />
            <InvoiceMiddleRow items={invoice} />
            <InvoiceFooter items={invoice?.description} />
        </View >
    </>

}

export default InvoiceItemsTable