import { Table } from 'antd';
import { ColumnsType } from "antd/lib/table";

interface IBMSupplierLevelSummeryProps {
    supplierSummaryData: IBMSupplierLevelSummery[]
}
export interface IBMSupplierLevelSummery {
    purchaseQuality: number;
    supplier: string;
    loadingJetty: string;
    allocationDisplayId: number;
    supplierWiseRedraftQty: number;
    supplierWiseAllocatedQty: number;
    uniqueKey: string;
}
export const BMSupplierLevelSummery = (props: IBMSupplierLevelSummeryProps) => {
    const { supplierSummaryData } = props;

    const tableColumn: ColumnsType<any> = [
        {
            title: 'Quality',
            dataIndex: 'purchaseQuality',

        },
        {
            title: 'Supplier',
            dataIndex: 'supplier',

        },
        {
            title: 'MineName',
            dataIndex: 'supplier',

        },
        {
            title: 'Total Barge Quantity',
            dataIndex: 'bargeQuantityInMt',

        },
        {
            title: 'Total Redraft Quantity',
            dataIndex: 'supplierWiseRedraftQty',

        },
    ];


    return (
        <div>
            <Table
                rowKey={(row) => row.uniqueKey}
                dataSource={supplierSummaryData}
                columns={tableColumn}
                pagination={false}
            />
        </div>
    )
}

export default BMSupplierLevelSummery;