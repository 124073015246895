
import { Card,Form, Input,Table } from "antd";
import { useState } from "react";
import { FileUploadForm } from "../../../../common";

export interface BargeSalesProps{
    gridData : (value) => void;
}

export const BargeSales = (props:BargeSalesProps) => {
    const [gridData, setGridData] = useState({});
    const [filesFormRef] = Form.useForm();

    const tableColumns: any = [
        {
            title: 'Typical Analysis',
            dataIndex: 'typicalAnalysis',
            width: 130,
            align: 'left',
        },
        {
            title: 'Units',
            dataIndex: 'units',
            width: 130,
            align: 'left',
        },
        {
            title: 'Basis',
            dataIndex: 'basis',
            width: 130,
            align: 'left',
        },
        {
            title: 'Typical',
            dataIndex: 'typical',
            width: 130,
            align: 'left',
        },
        {
            title: 'Rejection',
            dataIndex: 'rejection',
            width: 130,
            align: 'left',
        },
        {
            title: 'Actual',
            width: 130,
            align: 'left',
            render: (name, row, i) => {
                return <Form>
                    <Form.Item
                        name="actual" >
                        <Input autoComplete='off' onChange={(e) => onActualChange(e,row.name)} />
                    </Form.Item>
                </Form>
            }
        },
    ]

    const onActualChange = (e,title) => {
       const newData:any = gridData
      newData[title] = Number(e.target.value) 
      setGridData((prevData) =>( {...prevData,...newData}))
        props.gridData(newData)
    }

    
    const tableData:any = [
        {
            typicalAnalysis: 'Gross Calorific Value',
            name:'qualityCalorificValueActual',
            units: 'Kcal/Kg',
            basis: '',
            typical: '',
            rejection: ''        },
        {
            typicalAnalysis: '',
            name:'qualityCalorificValue2Actual',
            units: 'Kcal/Kg',
            basis: '',
            typical: '',
            rejection: ''
        },
        {
            typicalAnalysis: '',
            name:'qualityCalorificValue3Actual',
            units: 'Kcal/Kg',
            basis: '',
            typical: '',
            rejection: ''
        },
        {
            typicalAnalysis: 'Total Moisture',
            name:'qualityTotalMoistureActual',
            units: '%',
            basis: '',
            typical: '',
            rejection: ''
        },
        {
            typicalAnalysis: 'inherent Moisture',
            name:'qualityInherentMoistureActual',
            units: '%',
            basis: '',
            typical: '',
            rejection: ''
        },
        {
            typicalAnalysis: 'Ash',
            name:'qualityAshActual',
            units: '%',
            basis: '',
            typical: '',
            rejection: ''
        },
        {
            typicalAnalysis: 'Volatile Matter (VM)',
            name:'qualityVolatileMatterActual',
            units: '%',
            basis: '',
            typical: '',
            rejection: ''
        },
        {
            typicalAnalysis: 'Fixed Carbon',
            name:'qualityFixedCarbonActual',
            units: '%',
            basis: '',
            typical: '',
            rejection: ''
        },
        {
            typicalAnalysis: 'Fuel Ratio (FC/VM)',
            name:'qualityFuelRatioActual',
            units: '%',
            basis: '',
            typical: '',
            rejection: ''
        },
        {
            typicalAnalysis: 'Total Sulphur',
            name:'qualityTotalSulphurActual',
            units: '%',
            basis: '',
            typical: '',
            rejection: ''
        },
        {
            typicalAnalysis: 'HGI',
            name:'qualityHgiActual',
            units: '',
            basis: '',
            typical: '',
            rejection: ''
        },
    ]

    return (
        <Card>
            <Table
                className='contracts'
                columns={tableColumns}
                dataSource={tableData}
                pagination={false}
                scroll={{ x: 500 }}
                size="large"
                bordered
            ></Table>
            <br></br>
                <FileUploadForm key={Date.now()} maxCount={3} layoutType='vertical' formRef={filesFormRef} initialValues={[]} >
                    {/* <Row justify="end">
                        <Col>
                            {
                                <Space>
                                    <Button type="primary">
                                        Save and Next
                                    </Button>
                                </Space>
                            }</Col>
                    </Row> */}
                </FileUploadForm>
                <br></br>
                {/* <Affix offsetBottom={0}>
                    <Card bodyStyle={{ padding: '6px 12px' }}>
                        <Row justify='space-between'>
                            <Col><Button onClick={() => navigate(-1)}>Previous</Button></Col>
                            <Col><Button type="primary">Submit</Button></Col>
                        </Row>
                    </Card>
                </Affix> */}

        </Card>
    )
}