import { ContractIdRequest, ContractTypesEnum, SalesContractService } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Form, Input, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertMessages, useAuthState } from "../../../../../common";
import Link from 'antd/lib/typography/Link';


const SalesPendingApproval = () => {
  const [salesPending, setSalesPending] = useState<any>();
  const [searchData, setSearchData] = useState("");
  const service = new SalesContractService();
  const { authContext } = useAuthState();


  useEffect(() => {
    salesPendingData();
  }, [authContext.defaultPlant]);


  const salesPendingData = () => {
    service.salePendingData({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setSalesPending(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.bpName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.action)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
      render: (value, rowData) => {
        const link = `/#/so-detail-view?sc_id=${rowData.contractId}`
        return<> {
        <Link href={link} >{value}</Link>
      }
      </>}

    },
    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('YYYY-MM-DD') : "" },
      sorter: (a, b) => moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Customer',
      dataIndex: 'bpName',
    },


    {
      title: 'LayCan',
      dataIndex: 'layCan',
      render: (value, record) => { return record?.layCan ? Array.isArray(record?.layCan) ? `${moment(record.layCan[0]).format('DD MMM YYYY')} - ${moment(record.layCan[1]).format('DD MMM YYYY')}` : `${moment(record.layCan.split(',')[0]).format('DD MMM YYYY')} - ${moment(record.layCan.split(',')[1]).format('DD MMM YYYY')}` : ''; }
    },

    {
      title: 'Quality & Quantity',
      dataIndex: "quality",
      render: (value, record) => { return <b>{value} - {record.quantities}</b> }
    },
    //quantities quality

    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, rowData) => {
        return <span >
          <Button size="small" onClick={() => {
          saleForApproval(rowData);


        }}>Approve</Button>
        <Button size="small" onClick={()=>{saleForReject(rowData)

        }}>Reject</Button></span>
      }
    }
  ]

  const saleForApproval = (record: any) => {
    console.log(record)
    const req = new ContractIdRequest(record.contractId, authContext.defaultPlant, record.contractType)
    if (record.contractType === ContractTypesEnum.ADDENDUM) {
      service.approveSalesAddendum(req).then(res => {
        if (res.status) {
          salesPendingData();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.error);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
      return
    } else {
      service.saleForApproval(req).then(res => {
        if (res.status) {
          salesPendingData()
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.error);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  }


  const saleForReject=(record:any)=>{
    const req = new ContractIdRequest(record.contractId,record.contractType,authContext.defaultPlant)
    service.saleForReject(req).then(res =>{
      if(res.status){
        salesPendingData()
        AlertMessages.getSuccessMessage(res.internalMessage);
      }else {
        AlertMessages.getErrorMessage(res.error);
      }

    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  
  }

  return (
    <Card>


      <Form layout="vertical">
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >


          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
          </Col>
        </Row>



      </Form>
      <Table className='contracts' columns={tableColumns} dataSource={salesPending}></Table>

    </Card>

  )

}
export default SalesPendingApproval;