import { Card, Col, Form, FormInstance, Input, InputNumber, Radio, Row, Select, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AccountsService, CurrencyService, LcTypeEnum, PaymentModesEnum } from '@exportx/shared-models-and-services';
import { FormLayout } from 'antd/lib/form/Form';
import { PlusCircleOutlined, DeleteFilled } from '@ant-design/icons';
interface IPaymentProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
  account?: string
}
const { Option } = Select;
export const PaymentTerm = (props: IPaymentProps) => {
  const [currencies, setCurrencies] = useState<any[]>([]);
  const { formRef, layoutType, initialValues, account } = props;
  const [paymentMode, setPaymentMode] = useState(initialValues?.paymentMode);
  const [lcType, setLCType] = useState(initialValues?.lcType ? initialValues?.lcType : LcTypeEnum.percentage)
  const [initialValuesState, setInitialValuesState] = useState(initialValues);
  const [ledgerAccounts, setLedgerAccounts] = useState([]);
  const [key, setKey] = useState(0);
  const { formatMessage: fm } = useIntl();
  const currencyService = new CurrencyService();
  const accountsService = new AccountsService();

  const getAllAccountsData = () => {
    accountsService.getAllPmtLedgerAccounts().then((res => {
      if (res.status) {
        setLedgerAccounts(res.data)
      } else {
        setLedgerAccounts([])
      }
    })).catch((err => {
      setLedgerAccounts([])
    }))
  }
  useEffect(() => {
    setPaymentMode(props.initialValues?.paymentMode);
    setLCType(initialValues?.lcType ? initialValues?.lcType : LcTypeEnum.percentage);
  }, [initialValues])



  useEffect(() => {
    const initialValuesLocal = { ...initialValuesState };
    if (paymentMode === PaymentModesEnum.LETTER_OF_CREDIT || paymentMode === PaymentModesEnum.BOTH) {
      if (!initialValuesLocal?.lc?.[0]) {
        initialValuesLocal.lc = ['']
      }
    }
    if (paymentMode === PaymentModesEnum.TELEGRAPHIC_TRANSFER || paymentMode === PaymentModesEnum.BOTH) {
      if (!initialValuesLocal?.telegraphicTransfer?.[0]) {
        initialValuesLocal.telegraphicTransfer = ['']
      }
    }
    if (!initialValuesLocal?.others?.[0]) {
      initialValuesLocal.others = ['']
    }
    setInitialValuesState(initialValuesLocal);
    setKey(prev => prev + 1);
  }, [paymentMode]);

  useEffect(() => {
    if (props?.account) {
      getAllAccountsData();
    }
    getCurrencyData()
  }, []);


  const getCurrencyData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrencies(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  return (
    <Card title={fm({ id: `mdm.components.paymentTermForm.header`, defaultMessage: `Payment Mode` })}>
      <Form
        initialValues={initialValuesState}
        form={formRef}
        layout={layoutType}
        key={key}
        autoComplete="off">
        <Row>
          <Col>
            <Form.Item
              name={"paymentMode"}
              label={fm({ id: 'mdm.components.purchaseTypesForm.lc', defaultMessage: 'Purchase Term' })}
            >
              <Radio.Group buttonStyle="solid" onChange={(e) => { setPaymentMode(e.target.value) }}>
                {React.Children.toArray(Object.keys(PaymentModesEnum).map(contractTermKey => {
                  return <Radio.Button value={PaymentModesEnum[contractTermKey]}>{PaymentModesEnum[contractTermKey]}</Radio.Button>
                }))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1}>
            <Form.Item label="Account" rules={[{ required: account ? true : false }]}
              name="actLedger" style={{ width: '100%', display: props.account ? 'block' : 'none' }}>
              <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Account'>
                {React.Children.toArray(ledgerAccounts.map((a) => {
                  return <Option value={a.actLedId}>{a.actLedName}</Option>
                }))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <br></br>
        {(paymentMode === PaymentModesEnum.LETTER_OF_CREDIT || paymentMode === PaymentModesEnum.BOTH) && <>
          <Row >
            <Col><Form.Item
              name={"lcType"}
              label={fm({ id: 'mdm.components.purchaseTypesForm.lcType', defaultMessage: 'LC Type' })}
              rules={[
                {
                  required: true, message: `Please Select LC Type`
                }
              ]}
            >
              <Radio.Group buttonStyle="solid" onChange={(e) => { setLCType(e.target.value) }}>
                {React.Children.toArray(Object.keys(LcTypeEnum).map(lcType => {
                  return <Radio.Button value={LcTypeEnum[lcType]}>{LcTypeEnum[lcType]}</Radio.Button>
                }))}
              </Radio.Group>

            </Form.Item></Col>
            <Col><Form.Item
              name={"lcValue"}
              label={fm({ id: 'mdm.components.purchaseTypesForm.lcValue', defaultMessage: lcType === LcTypeEnum.percentage ? LcTypeEnum.percentage : 'LC Value' })}
              rules={[
                {
                  required: true, message: `Please Enter LC ${lcType === LcTypeEnum.percentage ? 'Percentage' : 'Value'}`
                }
              ]}

            >
              {lcType === LcTypeEnum.percentage ? <InputNumber placeholder={fm({ id: 'mdm.components.paymentTermForm.enter', defaultMessage: "Enter Value" })} max={100} /> : <Input placeholder={fm({ id: 'mdm.components.paymentTermForm.enter', defaultMessage: "Enter Value" })} />}

            </Form.Item>


            </Col>


            <Col>{(lcType !== LcTypeEnum.percentage) && <Form.Item
              label={fm({ id: `.currency`, defaultMessage: "Currency" })}
              name="lcCurrency"
              rules={[
                {
                  required: true, message: fm({ id: "mdm.components.contractDetailsForm.currencyReq", defaultMessage: "Please Select Currency" })
                }
              ]}

            >
              <Select
                placeholder={fm({ id: `.currencyReq`, defaultMessage: "Please Select Currency" })}
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
              >
                <Option value={''}>Please Select</Option>
                {React.Children.toArray(currencies.map(item => {
                  return <Option value={item.currencyCode}>{item.currencyName}</Option>
                }))}
              </Select>
            </Form.Item>}</Col>
          </Row>
        </>}
        <br></br>

        {(paymentMode === PaymentModesEnum.TELEGRAPHIC_TRANSFER || paymentMode === PaymentModesEnum.BOTH) && <Form.List name="telegraphicTransfer">
          {(fields, { add, remove }) => (
            <>
              {React.Children.toArray(fields.map(({ key, name, ...restField }, index) => (
                <Row>
                  <Col xs={{ span: 22 }}
                    sm={{ span: 22 }}
                    md={{ span: 22 }}
                    lg={{ span: 22 }}
                    xl={{ span: 22 }}><Form.Item
                      {...restField}
                      name={[name, 'telegraphicTransfer']}
                    >
                      <Input placeholder={fm({ id: 'mdm.components.paymentTermForm.telegraphicTransfer', defaultMessage: "Telegraphic Transfer" })} />
                    </Form.Item></Col>
                  <Col xs={{ span: 2 }}
                    sm={{ span: 2 }}
                    md={{ span: 2 }}
                    lg={{ span: 2 }}
                    xl={{ span: 2 }}>
                    {index !== 0 &&
                      <span style={{
                        position: "absolute",
                        marginTop: "15px",
                        marginLeft: "35px",
                      }}>
                        <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                          <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(name)} />
                        </Tooltip>
                      </span>
                    }
                    {(fields.length - 1 === index) && <span style={{
                      position: "absolute",
                      marginTop: "15px",
                      marginLeft: "15px"
                    }}>
                      <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                        <PlusCircleOutlined onClick={() => add()} />
                      </Tooltip>
                    </span>}
                  </Col>
                </Row>
              )))}
            </>
          )}
        </Form.List>}
        <br></br>

        {(paymentMode === PaymentModesEnum.LETTER_OF_CREDIT || paymentMode === PaymentModesEnum.BOTH) && <Form.List name="lc">
          {(fields, { add, remove }) => (
            <>
              {React.Children.toArray(fields.map(({ key, name, ...restField }, index) => (
                <Row>
                  <Col xs={{ span: 22 }}
                    sm={{ span: 22 }}
                    md={{ span: 22 }}
                    lg={{ span: 22 }}
                    xl={{ span: 22 }}><Form.Item
                      {...restField}
                      name={[name, 'letterOfCredit']}
                    >
                      <Input placeholder={fm({ id: 'mdm.components.paymentTermForm.letterOfCredit', defaultMessage: "Letter Of Credit" })} />
                    </Form.Item></Col>
                  <Col xs={{ span: 2 }}
                    sm={{ span: 2 }}
                    md={{ span: 2 }}
                    lg={{ span: 2 }}
                    xl={{ span: 2 }}>
                    {index !== 0 &&
                      <span style={{
                        position: "absolute",
                        marginTop: "15px",
                        marginLeft: "35px",
                      }}>
                        <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                          <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(name)} />
                        </Tooltip>
                      </span>
                    }
                    {(fields.length - 1 === index) && <span style={{
                      position: "absolute",
                      marginTop: "15px",
                      marginLeft: "15px"
                    }}>
                      <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                        <PlusCircleOutlined onClick={() => add()} />
                      </Tooltip>
                    </span>}
                  </Col>
                </Row>
              )))}
            </>
          )}
        </Form.List>}
        <br></br>

        <Form.List name="others">
          {(fields, { add, remove }) => (
            <>
              {React.Children.toArray(fields.map(({ key, name, ...restField }, index) => (
                <Row>
                  <Col xs={{ span: 22 }}
                    sm={{ span: 22 }}
                    md={{ span: 22 }}
                    lg={{ span: 22 }}
                    xl={{ span: 22 }}><Form.Item
                      {...restField}
                      name={[name, 'others']}
                    >
                      <Input placeholder={fm({ id: 'mdm.components.paymentTermForm.others', defaultMessage: "Others" })} />
                    </Form.Item></Col>
                  <Col xs={{ span: 2 }}
                    sm={{ span: 2 }}
                    md={{ span: 2 }}
                    lg={{ span: 2 }}
                    xl={{ span: 2 }}>
                    {index !== 0 &&
                      <span style={{
                        position: "absolute",
                        marginTop: "15px",
                        marginLeft: "35px",
                      }}>
                        <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                          <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(name)} />
                        </Tooltip>
                      </span>
                    }
                    {(fields.length - 1 === index) && <span style={{
                      position: "absolute",
                      marginTop: "15px",
                      marginLeft: "15px"
                    }}>
                      <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                        <PlusCircleOutlined onClick={() => add()} />
                      </Tooltip>
                    </span>}
                  </Col>
                </Row>
              )))}
            </>
          )}
        </Form.List>
        {props?.children}
      </Form>
    </Card>
  )
}
export default PaymentTerm;

