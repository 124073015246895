import { BPDropDownDto, BpCategoryGetDto, BusinessPartnerService, BusinessPartnerTypeEnum, FixtureNotesEnum } from '@exportx/shared-models-and-services';
import { Card, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import DatePicker from '../../../../common/data-picker/date-picker';



const { RangePicker } = DatePicker;
interface IFixturenotedetailsProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}

const { Option } = Select;

export const FixtureDetails = (props: IFixturenotedetailsProps) => {
  const { formRef, layoutType, initialValues } = props;
  const { formatMessage: fm } = useIntl();
  const businessPartnerService = new BusinessPartnerService();
  const [vendorsData, setVendorsData] = useState<BPDropDownDto[]>([]);
  const onChange: any['onChange'] = (date, dateString) => {
  };




  useEffect(() => {
    getAllBusinessPartnersForDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllBusinessPartnersForDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
    businessPartnerService.getAllBusinessPartnersForDropDown(bpCategoryGetDto).then(res => {
      if (res.status) {
        if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.VENDOR)
          setVendorsData(res.data);

      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }
  return (
    <div>
      <Card
        title={fm({ id: `mdm.components.fixtureNoteForm.header`, defaultMessage: `FixtureNoteDetails` })}

      >
        <Form initialValues={initialValues}
          form={formRef}
          layout={layoutType}
          autoComplete="off"

        >
          <Row>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.fixtureNoteForm.fixtureNoteType', defaultMessage: " Fixture Note Type" })}
                name="fixtureNoteType"
                rules={[{ required: true, message: fm({ id: "mdm.components.fixtureNoteForm.fixtureNoteTypeReq", defaultMessage: "Please Select fixtureNoteType" }) }]}
              >

                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear showSearch placeholder={fm({ id: `mdm.components.fixtureNoteForm.fixtureNoteTypeReq`, defaultMessage: ` Please  Select Fixture Note Type ` })}>
                  {Object.keys(FixtureNotesEnum).map(contractTermKey => {
                    return <Option value={FixtureNotesEnum[contractTermKey]}>{FixtureNotesEnum[contractTermKey]}</Option>
                  })}
                </Select>

              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
              <Form.Item

                label={fm({ id: 'mdm.components.fixtureNoteForm.supplierReferenceNo', defaultMessage: "Supplier Reference No" })}
                name="supplierReferenceNo"
                style={{ width: '150px' }}
              >
                <Input></Input>

              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.fixtureNoteForm.motherVesselName', defaultMessage: "Mother Vessel Name" })}
                name="motherVesselName"

              >
                <Input></Input>

              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>

              <Form.Item
                label={fm({ id: 'mdm.components.fixtureNoteForm.agreementDate', defaultMessage: "Agreement Date" })}
                name="agreementDate"
                rules={[{ required: true, message: fm({ id: "mdm.components.fixtureNoteForm.agreementDateReq", defaultMessage: "Please Select Agreement Date" }) }]}
              >
                <DatePicker onChange={onChange} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>

          </Row>
          <Row>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.fixtureNoteForm.vendor', defaultMessage: "Vendor" })}
                name="vendor"
                rules={[{ required: true, message: fm({ id: "mdm.components.fixtureNoteForm.vendorReq", defaultMessage: "Please Select Vendor" }) }]}
              >
                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear showSearch placeholder={fm({ id: 'mdm.components.fixtureNoteForm.vendorReq', defaultMessage: "Please Select Vendor " })} style={{ width: "170px" }}>
                  <Option value={''}>Please Select</Option>
                  {vendorsData.map(item => {
                    return <Option value={item.bpId}>{item.bpName}</Option>
                  })}
                </Select>




              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
              <Form.Item

                label={fm({ id: 'mdm.components.fixtureNoteForm.layCan', defaultMessage: "Laycan" })}
                name="layCan"
                style={{ width: '150px' }}
              >
                <RangePicker format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.fixtureNoteForm.quantityInMT', defaultMessage: "Quantity In MT" })}
                name="quantityInMT"

              >
                <Input></Input>

              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>

              <Form.Item
                label={fm({ id: 'mdm.components.fixtureNoteForm.allowedDays', defaultMessage: "Allowed Days" })}
                name="allowedDays"

              >
                <Input />
              </Form.Item>
            </Col>



          </Row>
          <Row>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.fixtureNoteForm.loadingRatePerDay', defaultMessage: "Loading Rate per Day" })}
                name="loadingRatePerDay"

              >
                <Input></Input>

              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
              <Form.Item

                label={fm({ id: 'mdm.components.fixtureNoteForm.deadFreight', defaultMessage: "Dead Freight" })}
                name="deadFreight"
                style={{ width: '150px' }}
              >
                <Input></Input>

              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>
              <Form.Item
                label={fm({ id: 'mdm.components.fixtureNoteForm.vesselType', defaultMessage: "Vessel Type" })}
                name="vesselType"

              >
                <Select
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  allowClear showSearch placeholder={fm({ id: 'mdm.components.fixtureNoteForm.vesseltypeReq', defaultMessage: "Please Select Vessel Type " })} style={{ width: "170px" }}>
                  <Select.Option value="geared">GEARED</Select.Option>
                  <Select.Option value="gearless">GEARLESS</Select.Option>

                </Select>

              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 4, offset: 2 }}>

              <Form.Item
                label={fm({ id: 'mdm.components.fixtureNoteForm.portOfLoading', defaultMessage: "Port of Loading" })}
                name="portOfLoading"

              >
                <Input></Input>
              </Form.Item>
            </Col>



          </Row>

        </Form>

        {props?.children}
      </Card>



    </div>
  )
}

export default FixtureDetails;