import { ContractStatusEnum, ContractTypesEnum, PreviewRequests, PriceTypeEnum, ProcurementContractTypeEnum, VesselAgreementAll, VesselAgreementDto, VesselAgreementPreviewDto, VesselAgreementService } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Affix, Button, Card, Col, Descriptions, Form, Layout, Row, Space, Tooltip } from 'antd';
import Link from 'antd/es/typography/Link';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../../common';
import PaymentTermsView from '../../../contracts/view-components/payment-terms-view';

interface IProps {
  contractTypeCategory?: ProcurementContractTypeEnum;
}


export const VesselAgreementDetailedView = (props: IProps) => {
  const [gridData, setGridData] = useState<any>();
  const [snapData, setSnapData] = useState<any[]>([]);
  const [filesFormRef] = Form.useForm();
  const { authContext } = useAuthState();
  const location = useLocation();
  const paramId = queryString.parse(location.search)?.va_Id;
  console.log('paramId', paramId)
  let { agreementId }: any = paramId != null ? { agreementId: paramId } : { agreementId: null };

  const navigate = useNavigate()

  let pcUpdatePath = '/vesselAgreement-update';
  let detailViewPath = '/vesselAgreement-detailed-view';
  let vesselAddendum = '/vesselAgreement-addendum';

  const service = new VesselAgreementService();

  useEffect(() => {
    getAgreementPreviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementId]);

  const getAgreementPreviewData = () => {
    const req = new PreviewRequests(agreementId, authContext.defaultPlant)
    service.getAgreementPreviewData(req).then(res => {
      if (res.status) {
        setGridData(res.data);
        setSnapData(res.snapData);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };

  const sendForApproval = () => {
    service.sendForApproval({ baId: agreementId, plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAgreementPreviewData();
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const addendumClickHandler = () => {
    const link = `${vesselAddendum}?va_Id=${agreementId}`
    navigate(link, { state: { record: {...gridData, action:'addendum'} } })
  }

  console.log('gridData', gridData)

  const approveOrRejectHandler = (agreementId: string, status: ContractStatusEnum) => {
    const req = new VesselAgreementPreviewDto(agreementId, authContext.defaultPlant, authContext.user.userName, status, gridData.contractType)
    console.log('req', req);
    // return
    service.approveOrRejectAgreement(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        getAgreementPreviewData();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button onClick={() => sendForApproval()}>Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <span>
        <Button size="small" onClick={() => {
          approveOrRejectHandler(agreementId, ContractStatusEnum.ACTIVE);
        }}>Approve</Button> &nbsp;&nbsp;
        <Button size="small" onClick={() => {
          approveOrRejectHandler(agreementId, ContractStatusEnum.REJECT); 
        }}>Reject</Button>
      </span>
    }
    if (status === ContractStatusEnum.ACTIVE && gridData?.contractType === ContractTypesEnum.ORIGINAL) {
      return <Button onClick={() => addendumClickHandler()}>Create Addendum</Button>
    }
  }


  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => { 
      getFilesData(fileValues.filesData).then(filesData => {
        service.addNewFiles({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          vaId: agreementId
        }).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            getAgreementPreviewData();
            filesFormRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      });
    });
  }

  const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
    for (const snapDate of snapData) {
      let valueAtGivenKey: any = snapDate;
      for (const key of keys) {
        valueAtGivenKey = valueAtGivenKey?.[key];
      }
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (gridData.contractType === ContractTypesEnum.ADDENDUM) {
          if (isRawData) {
            return value;
          } else {
            return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapDate.fixtureNoteNo.replace('SNP', 'ADD')}><b>
              {value}
            </b></Tooltip></>
          }
        }
      }
    };
    return value
  }

  const valiDateChildWithSnapShotChild = (index: number, key: string, value: string): any => {
    let valueAtGivenKey: any = snapData?.[0]?.specPriceData?.[index]?.[key];
    if (valueAtGivenKey) {
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (gridData.contractType === ContractTypesEnum.ADDENDUM) {
          console.log('came', key, value, valueAtGivenKey)
          return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapData?.[0]?.fixtureNoteNo?.replace('SNP', 'ADD')}><b>
            {value}
          </b></Tooltip></>
        }
      };
    }
    return value;//'hhhhhhhhhhhh'
  }


  const paymentTerms = { lc: gridData?.lc, others: gridData?.others, telegraphicTransfer: gridData?.telegraphicTransfer, lcValue: gridData?.lcValue, lcType: gridData?.lcType, lcCurrency: gridData?.lcCurrency, paymentMode: gridData?.paymentMode }
  const columns: ColumnProps<any>[] = [
    {
      title: 'LayCan',
      dataIndex: 'layCan',
      render: (value, record) => {
        return <>
          {value[0] === null || value[1] === null ? "-" : moment(value[0]).format('DD MMM YY') + " To " + moment(value[1]).format('DD MMM YY')}
        </>
      }
    },
    {
      title: 'Vessel Name',
      dataIndex: 'vesselName',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'vesselName', value)}</span>
      ),
    },
    {
      title: 'quantity',
      dataIndex: 'quantity',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'quantity', value)}</span>
      ),
    },
    {
      title: 'Tolerance',
      dataIndex: 'tolerance',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'tolerance', value)}</span>
      ),
    },
    {
      title: 'Price Type ',
      dataIndex: 'priceType',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'priceType', value)}</span>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'freightPrice',
      align: 'center',
      render: (value, record, index) => {
        if (isPermissionExist([150, 159, 168])) {
          return <>
            {record.priceType === PriceTypeEnum.LUMP_SUM ? valiDateChildWithSnapShotChild(index, 'freightPrice', value) : record.priceType === PriceTypeEnum.PER_MT ? <>{valiDateChildWithSnapShotChild(index, 'freightPrice', record.freightPrice)} (PMT)  </> : undefined}
          </>
        } else return <></>

      }
    },
    {
      title: "VAT",
      dataIndex: 'vatInclOrExcl',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'vatInclOrExcl', value)}</span>
      ),

    },
    {
      title: "Load Port",
      dataIndex: 'loadPortName',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'loadPortName', value)}</span>
      ),

    },
    {
      title: "Load Rate",
      dataIndex: 'loadRate',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'loadRate', value)}</span>
      ),

    },
    {
      title: "Discharge Port",
      dataIndex: 'dischargePortName',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'dischargePortName', value)}</span>
      ),

    },
    {
      title: "Discharge Rate",
      dataIndex: 'dischargeRate',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'dischargeRate', value)}</span>
      ),

    },
    {
      title: "Demurrage Rate",
      dataIndex: 'demurrageRate',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'demurrageRate', value)}</span>
      ),

    },
    {
      title: "Dispatch Rate",
      dataIndex: 'dispatchRate',
      align: 'center',
      render: (value, row: any, index) => (
        <span>{valiDateChildWithSnapShotChild(index, 'dispatchRate', value)}</span>
      ),

    },

  ];

  const remarks: ColumnProps<any>[] = [
    {
      title: "Remarks",
      dataIndex: "remarks"
    }
  ]

  const link = `/#/detailCustomer?bp_id=${gridData?.vesselVendorId}`

  return (
    <>
      <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>
        <Card
          style={{ textAlign: 'center' }}
          className='default-card-class'
          title={<span style={{ color: 'white' }}> Contract -  {(gridData?.fixtureNoteNo)}
            <span style={{ paddingLeft: 20 }}> <StatusTag status={gridData?.status} />
            </span>
          </span>
          }
          extra={<>{getExtraComponent(gridData?.status)}</>}
        >
          <Row>
            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"Vendor"}><span><Link href={link}>
                  {valiDateWithSnapShotData(['vendor'], gridData?.vendorName, false)}
                </Link></span></Descriptions.Item>
              </Descriptions></Col>

            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"Contract Mode"}>
                  {valiDateWithSnapShotData(['contractMode'], gridData?.contractMode, false)}</Descriptions.Item>
              </Descriptions></Col>
            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"ContractDate"}>
                  {valiDateWithSnapShotData(['agreementDate'], gridData?.agreementDate ? moment(gridData?.agreementDate).format('DD/MM/YYYY h:mm A') : '', false)}</Descriptions.Item>
              </Descriptions></Col>
            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"Currency"}>
                  {valiDateWithSnapShotData(['currency'], gridData?.currency, false)}
                </Descriptions.Item>

              </Descriptions></Col>
            <Col span={8}>
              <Descriptions>

                <Descriptions.Item label={"Approved By"}>
                  {gridData?.approvedBy}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={8}>
              <Descriptions>
                <Descriptions.Item label={"Approved Date"}>
                  {gridData?.approvedOn ? moment(gridData?.approvedOn).format('DD-MM-YYYY h:mm A') : ''}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>

            <Descriptions>
              <Descriptions.Item label={gridData?.contractType === 'Addendum' ? 'Base Contract' : 'Addendum Data' }>
                {/* {gridData?.addendumData?.map((rec) => {
                  const link = `/#${detailViewPath}?va_Id=${rec.agreementId}`
                  return <><a href={link} className="link-primary" >
                    {`${rec.contractNo} ,`}
                  </a></>
                })} */}
                {gridData?.contractType === 'Original' ? (
                  gridData?.addendumData?.map((rec) => {
                  const link = `/#${detailViewPath}?va_Id=${rec.agreementId}`;
                    return (
                      <a href={link} className="link-primary" key={rec.agreementId}>
                        {`${rec.contractNo} ,`}
                      </a>
                    );
                  })
                ) : (
                  (() => {
                    const link = `/#${detailViewPath}?va_Id=${gridData?.baseContractId}`;
                      return (
                        <a href={link} className="link-primary">
                          {gridData?.baseContractNo}
                        </a>
                      );
                    }
                  )()
                )}
              </Descriptions.Item>
            </Descriptions>
          </Row>

          <br />

          <Card>
            <Table
              dataSource={gridData?.specPriceData ? gridData?.specPriceData : []}
              columns={columns}
              pagination={false} />
          </Card>

          <Card >
          <PaymentTermsView paymentTerms={gridData?.paymentTerms} snapShotData={snapData} contractType={gridData?.contractType} />
          </Card>
          <Card>
            <Table dataSource={Array(gridData)} columns={remarks}
              pagination={false} /></Card>

          {gridData?.filesData &&
            <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} >
              <Row justify="end">
                <Col>
                  {
                    <Space>
                      <Button
                        onClick={fileUploadFormHandler}
                        type="primary">
                        Save and Next
                      </Button>
                    </Space>
                  }</Col>
              </Row>
            </FileUploadForm>
          }
        </Card>
        <Affix offsetBottom={0}>
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>
              <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
              <Col>{(gridData?.['status'] === ContractStatusEnum.DRAFT || gridData?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && <Button onClick={() => {
                const link = `${pcUpdatePath}?va_Id=${agreementId}`
                navigate(link, { state: { record: gridData } })
              }}>Edit</Button>}</Col>
            </Row>
          </Card>
        </Affix>
      </Layout>
    </>
  );

}
export default VesselAgreementDetailedView;