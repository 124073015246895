import React, { useEffect, useState } from 'react';
import { Card, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';

import './shipping-terms-grid.css';
import { ShippingTermsService, ShippingTermsDto, } from '@exportx/shared-models-and-services';
import { AlertMessages, FilterableGrid, isPermissionExist } from '../../../../../common';
import { useIntl } from 'react-intl';

/* eslint-disable-next-line */
export interface ShippingTermsGridProps { }

export function ShippingTermsGrid(
  props: ShippingTermsGridProps
) {
  const navigate = useNavigate();
  const service = new ShippingTermsService();
  const [shippingTermsData, setShippingTermsData] = useState<ShippingTermsDto[]>([]);
  const { formatMessage: fm } = useIntl();

  useEffect(()=>{
      if(!isPermissionExist([51])){
        return navigate('/')
      }
  },[])

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getAll = () => {
    service.getAll().then(res => {
      if (res.status) {
        setShippingTermsData(res.data);
      } else {
        setShippingTermsData([]);
      }
    }).catch(err => {
      setShippingTermsData([]);
    })
  }


  const deleteUser = (Data: ShippingTermsDto) => {

    Data.isActive = Data.isActive ? false : true;
    service.activatedeActivate(Data).then(res => {
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Shipping Terms ${Data.isActive ? ' activated ' : ' deactivated '} SuccessFully` }));
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Shipping Term Name',
      dataIndex: 'shippingTermsName',
      //  responsive: ['lg'],
      sorter: (a, b) => a.shippingTermsName.localeCompare(b.shippingTermsName),
      sortDirections: ['descend', 'ascend'],
    }
  ];

  const view = (shippingtermsData: ShippingTermsDto, isUpdate: boolean = false) => {
    let isNewRecord = true;
    if (shippingtermsData) {
      isNewRecord = false;
    }
    let pathToreDirect = '/createShippingTerm';
    navigate(pathToreDirect, { state: { shippingtermsData, isNewRecord, isUpdate } });
  }

  return (
    <Card title='Shipping Term Details'
      className='default-card-class'
      extra={<>{isPermissionExist([50]) && (<Button onClick={() => view(undefined, false)} >
        {fm({ id: 'common.create', defaultMessage: 'Create' })} </Button>)}</>}
        >
      <FilterableGrid
        rowKey='shippingTermsId'
        mainColumns={columnsSkelton}
        data={shippingTermsData}
        filterColumnsData={[
          {
            dataIndex: 'shippingTermsName',
            placeHolder: fm({
              id: 'pms.components.ClusterGrid.shippingTermsName',
              defaultMessage: 'Shipment Term Name'
            })
          }
        ]}
        otherTableProps={{ bordered: true }} activateOrDeactivate={deleteUser} view={view} />

    </Card>

  );
}

export default ShippingTermsGrid;


