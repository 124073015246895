import React, { useEffect, useState } from 'react'
import { Card, Form, Input, Row, Col, Descriptions, Select, Radio, FormInstance } from 'antd'
import { BargeIdRequestDto, BargeService, CostingTypesEnum, GetPurchaseContractDataByBargeIdDto } from '@exportx/shared-models-and-services';
import { GarPenalty } from './gar-penalty';
import { ASHPenalty } from './ash-penalty';
import { SulphurPenalty } from './sulphur-penalty';
import { CoalCostingFileUploads } from './coal-costing-file-uploads';
import FormItem from 'antd/es/form/FormItem';
import { useAuthState } from '../../../../common';
import Pph22Export from './pph22-export-form';
import { getLocalFormat } from '@exportx/ui-utils';

interface IBGCoalCostingsForm {
  coalCostingType: CostingTypesEnum;
  selectedRowKeysData?: any[];
  formRef: FormInstance<any>;
}
const { Option } = Select;

const calculateSum = (array, property) => {
  const total = array.reduce((accumulator, object) => {
    const value = object[property] ? Number(object[property]) : 0;
    return accumulator + value;
  }, 0);
  return total;
}

export const BGCoalCostingsForm = (props: IBGCoalCostingsForm) => {
  const { authContext } = useAuthState();
  const { coalCostingType, selectedRowKeysData } = props;
  const [initialValues, setInitialValues] = useState<any>({
    billingCurrency: authContext.defaultPlantCurrency
  });
  const [formRef] = Form.useForm();

  useEffect(() => {
    const totalBargeQty = calculateSum(selectedRowKeysData, 'bargeQuantityInMt');
    const pph22Export = (totalBargeQty * selectedRowKeysData[0].pricePmt) * 0.015;
    const coalPrice = totalBargeQty * selectedRowKeysData[0].pricePmt;
    setInitialValues({
      bargeQuantityInMT: totalBargeQty,
      coalPricePerMT: selectedRowKeysData[0].pricePmt,
      purchaseCurrency: selectedRowKeysData[0].purchaseCurrency,
      billingCurrency: authContext.defaultPlantCurrency,
      exchangeRate: 1,
      coalPricePerMTInLocalCurrency: selectedRowKeysData[0].pricePmt,
      coalPrice: coalPrice,
      pph22Export: pph22Export,
      freightPricePmt: Number(selectedRowKeysData[0].freightPricePmt),
      advanceAdjustments: 0,
      payableToSupplier: coalPrice - pph22Export
    })
  }, []);

  const currencyChange = (currency: string) => {
    setInitialValues((prev: any) => {
      return {
        ...prev,
        billingCurrency: currency
      }
    })
  }

  const exchangeRateHandler = (e: any) => {
    const eRate = e.target.value;
    const totalBargeQty = calculateSum(selectedRowKeysData, 'bargeQuantityInMt');
    const pph22Export = (totalBargeQty * selectedRowKeysData[0].pricePmt) * 0.015 * eRate;
    const coalPrice = totalBargeQty * selectedRowKeysData[0].pricePmt * eRate;
    setInitialValues((prev: any) => {
      return {
        ...prev,
        coalPricePerMTInLocalCurrency: selectedRowKeysData[0].pricePmt * eRate,
        coalPrice: coalPrice,
        pph22Export: pph22Export,
        freightPricePmt: Number(selectedRowKeysData[0].freightPricePmt) * eRate,
        exchangeRate: eRate,
        payableToSupplier: coalPrice - pph22Export
      }
    })
  }
  useEffect(() => {
    formRef.setFieldsValue(initialValues)
  }, [initialValues])





  return (
    <div className='mt-2'>
      <Form layout='vertical' form={formRef} initialValues={initialValues}>
        <Card title='Coal Costing'>

          <Row>
            <Col span={8}>
              <FormItem name='bargeQuantityInMT' label='Barge Quantity in MT' >
                <Input placeholder='Barge Quantity in MT'></Input>
              </FormItem>
            </Col>
            <Col span={6} offset={2}>
              <FormItem name='coalPricePerMT' >
                <Descriptions layout='vertical' colon={false}>
                  <Descriptions.Item label="Coal Price per MT" >{`${selectedRowKeysData[0].pricePmt} ${selectedRowKeysData[0].purchaseCurrency}`}</Descriptions.Item>
                </Descriptions>
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem name='billingCurrency' label='Billing Currency'>
                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={'Please Select Currency'} onChange={currencyChange}>
                  <Option value={''}> Please Select Currency</Option>
                  <Option value={authContext.defaultPlantCurrency}>{authContext.defaultPlantCurrency}</Option>
                  <Option value={selectedRowKeysData[0].purchaseCurrency}>{selectedRowKeysData[0].purchaseCurrency}</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            {
              selectedRowKeysData[0].purchaseCurrency !== initialValues?.billingCurrency && <><Col span={8}>
                <FormItem name='exchangeRate' label={`Exchange Rate ${selectedRowKeysData[0].purchaseCurrency} to ${initialValues?.billingCurrency}`}>
                  <Input placeholder={`Exchange Rate ${selectedRowKeysData[0].purchaseCurrency} to ${initialValues?.billingCurrency}`} onChange={exchangeRateHandler}></Input>
                </FormItem>
              </Col>

                <Col span={6} offset={2}>
                  <FormItem name={`coalPricePerMTInLocalCurrency`}>
                    <Descriptions layout='vertical' colon={false}>
                      <Descriptions.Item label={`Coal Price PerMT In ${authContext.defaultPlantCurrency}`} >{
                        getLocalFormat(initialValues?.coalPricePerMTInLocalCurrency, initialValues?.billingCurrency)
                      }</Descriptions.Item>
                    </Descriptions>
                  </FormItem>
                </Col></>
            }


            <Col span={8}>
              <FormItem name='coalPrice'>
                <Descriptions layout='vertical' colon={false} >
                  <Descriptions.Item label="Coal Price" >{getLocalFormat(initialValues?.coalPrice, initialValues?.billingCurrency)}</Descriptions.Item>
                </Descriptions>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name='pph22Export'>
                <Descriptions layout='vertical' colon={false}>
                  <Descriptions.Item label="PPh22 Export" >
                    {getLocalFormat(initialValues?.pph22Export, initialValues?.billingCurrency)}</Descriptions.Item>
                </Descriptions>
              </FormItem>
            </Col>
            <Col span={selectedRowKeysData[0].purchaseCurrency !== initialValues?.billingCurrency ? 6 : 8} offset={selectedRowKeysData[0].purchaseCurrency !== initialValues?.billingCurrency ? 2 : 0}>
              <FormItem name='advanceAdjustments'>
                <Descriptions layout='vertical' colon={false}>
                  <Descriptions.Item label="Advance Adjustments" >
                    {getLocalFormat(initialValues?.advanceAdjustments, initialValues?.billingCurrency)}
                  </Descriptions.Item>
                </Descriptions>
              </FormItem>
            </Col>


            <Col span={8}>
              <FormItem name='freightPricePmt'>
                <Descriptions layout='vertical' colon={false}>
                  <Descriptions.Item label="Freight Price PMT" >
                    {getLocalFormat(initialValues?.freightPricePmt, initialValues?.billingCurrency)}
                  </Descriptions.Item>
                </Descriptions>
              </FormItem>
            </Col>

          </Row>

          <Row >
            <Col span={8}>
              <Form.Item name='invoice' label='Invoice %'>
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <FormItem name='payableToSupplier'>
                <Descriptions layout='vertical' colon={false} style={{ paddingLeft: 100 }}>
                  <Descriptions.Item label="Payable to Supplier" >
                    {getLocalFormat(initialValues?.payableToSupplier, initialValues?.billingCurrency)}
                  </Descriptions.Item>
                </Descriptions>
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormItem name='pph22Export' label={'PPH22 Export Applicable'}>
                <Radio.Group>
                  <Radio value={'Yes'}>Yes</Radio>
                  <Radio value={'No'}>No</Radio>
                </Radio.Group>
              </FormItem>
              <Pph22Export />
            </Col>
          </Row>
          {coalCostingType === CostingTypesEnum.COMMERCIAL && <GarPenalty />}
          {coalCostingType === CostingTypesEnum.COMMERCIAL && <ASHPenalty />}
          {coalCostingType === CostingTypesEnum.COMMERCIAL && <SulphurPenalty />}
        </Card>

        <CoalCostingFileUploads />

      </Form>
    </div>
  )
}

export default BGCoalCostingsForm

