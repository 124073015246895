import { Card, Col, Form, FormInstance, Input, Row } from 'antd'
import { FormLayout } from 'antd/lib/form/Form';
import React from 'react'

interface ISizeDistributionProps {
    formRef: FormInstance<any>;
    initialValues: any;
    children?: React.ReactNode;
    layoutType: FormLayout;
}

export const SizeDistribution = (props: ISizeDistributionProps) => {
    const { formRef, initialValues, layoutType } = props;
    return (

        <Card>
            <Form
                layout={layoutType} form={formRef} initialValues={initialValues}
            >
                <Row>
                    <h3>Size Distribution(%)</h3>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }} >
                        <Form.Item name="sizeDist1Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist1Typical">
                            <Input type="text" placeholder='Typical' />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist1Rejection">
                            <Input type="text" placeholder='Rejection' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist2Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist2Typical">
                            <Input type="text" placeholder='Typical' />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist2Rejection">
                            <Input type="text" placeholder='Rejection' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist3Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist3Typical">
                            <Input type="text" placeholder='Typical' />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist3Rejection">
                            <Input type="text" placeholder='Rejection' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist4Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist4Typical">
                            <Input type="text" placeholder='Typical' />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist4Rejection">
                            <Input type="text" placeholder='Rejection' />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist5Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist5Typical">
                            <Input type="text" placeholder='Typical' />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                        <Form.Item name="sizeDist5Rejection">
                            <Input type="text" placeholder='Rejection' />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

export default SizeDistribution;