import { DownCircleFilled, UpCircleFilled } from "@ant-design/icons";
import { Button, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AlertMessages, StatusTag, useAuthState } from "../../../common";
import { ContractStatusEnum, CostingInvoiceApproveReqDto, CostingInvoiceReqDto, CostingInvoiceService } from "@exportx/shared-models-and-services";
import { ApprovalTracking } from "./approval-tracking";
import { getLocalFormat } from "@exportx/ui-utils";
import ViewFileHandling from "../../../common/file-upload-form/view-files-handling";

interface InvoiceDetailsProps {
  costingId: string;
  status: ContractStatusEnum;
  level: ContractStatusEnum;
  invoiceStatus: ContractStatusEnum;
  invApproveDisabled?: boolean;
  includeFreight?: boolean

}


export const InvoiceDetails = (props: InvoiceDetailsProps) => {
  const { costingId, status, level, invApproveDisabled, includeFreight } = props
  const [expandedIndex, setExpandedIndex] = useState([]);
  const invoiceService = new CostingInvoiceService();
  const [costingInvoices, setCostingInvoices] = useState([])
  const { authContext } = useAuthState();

  useEffect(() => {
    if (costingId) {
      const req = new CostingInvoiceReqDto(costingId, authContext.defaultPlant);
      invoiceService.getAllCostingInvoice(req).then(res => {
        if (res.status) {
          setCostingInvoices(res.data);
        } else {
          setCostingInvoices([]);
          console.log(res.internalMessage);

        }
      }).catch(err => console.log(err.message));

    }

  }, [costingId])

  // useEffect(() => {
  //   if (level && initialValues && initialValues?.length) {
  //     setCostingInvoices(initialValues?.filter((inv) => level === ContractStatusEnum.BUSINESS_APPROVAL ? inv.status === ContractStatusEnum.PENDING_APPROVAL : inv.status !== ContractStatusEnum.PENDING_APPROVAL))
  //   }

  // }, [level, initialValues])

  const approvalHandler = (id) => {
    const req = new CostingInvoiceApproveReqDto(id, authContext.user.userName, authContext.defaultPlant, status, level)
    invoiceService.approveOrRejectCostingInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        window.location.reload();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  }
  const rejectedHandler = (id) => {
    const req = new CostingInvoiceApproveReqDto(id, authContext.user.userName, authContext.defaultPlant, ContractStatusEnum.REJECT, level)
    invoiceService.approveOrRejectCostingInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        window.location.reload();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  }

  const columns: any = [
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      render: (text, record) => <span>{text ? moment(text).format('DD-MM-YYYY') : ''}</span>

    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      key: 'invoiceAmount',
      render: (value, record) => {
        return <>{getLocalFormat(value, authContext.defaultPlantCurrency)}</>
      }

    },
    ...includeFreight ? [
      {
        title: 'Coal %',
        dataIndex: 'coal_percent',
        key: 'coal_percent',
      },
  
      {
        title: 'Coal Amount',
        dataIndex: 'coal_amount',
        key: 'coal_amount',
        render: (value, record) => {
          return <>{getLocalFormat(value, authContext.defaultPlantCurrency)}</>
        }
      },
      {
        title: 'Freight %',
        dataIndex: 'freight_percent',
        key: 'freight_percent',
      },
      {
        title: 'Freight Amount',
        dataIndex: 'freight_amount',
        key: 'freight_amount',
        render: (value, record) => {
          return <>{getLocalFormat(value, authContext.defaultPlantCurrency)}</>
        }
      },
    ]:[
      {
        title: 'Invoice %',
        dataIndex: 'invoicePercent',
        key: 'invoicePercent',
      }
    ],
   
    {
      title: 'Invoice File',
      dataIndex: 'filesData',
      render: (value, record) => {
        return <>
          <ViewFileHandling filesData={value.length ? value : []} required={true} />

        </>
      }

    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => {
        return <>
          <StatusTag status={value} />
        </>
      }
    },

    {
      title: 'Action',
      dataIndex: 'businessNo',
      key: 'businessNo',
      render: (value, record) => {
        if (record.status !== ContractStatusEnum.ACTIVE) {
          return (
            <>
              <Button
                disabled={invApproveDisabled}
                onClick={() => { approvalHandler(record?.invoiceId); }}
                type="primary"
              >
                Approve
              </Button> &nbsp; &nbsp;
              <Button
                disabled={invApproveDisabled}
                onClick={() => { rejectedHandler(record?.invoiceId); }}
              >Reject
              </Button>
            </>
          )

        }
      }
    },
  ]

  const setIndex = (expanded, record) => {
    const expandedRows = []
    if (expanded) {
      expandedRows.push(record?.invoiceId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };
  const renderItems = (record: any) => {
    return <ApprovalTracking history={record?.workLog} />
  };

  return (

    <Table dataSource={costingInvoices} columns={columns} pagination={false} style={{ width: '100%' }} size="small"
    scroll={{x: includeFreight ? '170%' : '100%'}}
      rowKey={record => record.invoiceId}
      expandable={{
        expandedRowRender: renderItems,
        expandedRowKeys: expandedIndex,
        onExpand: setIndex,


        fixed: 'right'
      }}

      expandIcon={({ expanded, onExpand, record }) =>
        expanded ? (
          <UpCircleFilled
            onClick={(e) => onExpand(record, e)}
          >
            Collapse
          </UpCircleFilled>
        ) : (
          <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
        )
      }
      bordered />

  )

}