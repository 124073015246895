import { ContractTypesEnum, PurchaseContractDto, PurchaseTermDataDto, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';

export interface IASHData {
    purchaseTermData: PurchaseTermDataDto,
    specType: SpecTypesEnum;
    snapShotPurchaseTermData: PurchaseTermDataDto;
    contractType: ContractTypesEnum;
    snapSpecType: SpecTypesEnum;
}
const ASHFusionView = (props: IASHData) => {
    const { contractType } = props;
    const [ashFusionDataSource, setASHFusionDataSource] = useState([]);
    const [addendumAshFusionDataSource, setAddendumASHFusionDataSource] = useState([]);

    useEffect(() => {
        if (props.purchaseTermData)
            setASHFusionDataSource(constructDataSource(props.purchaseTermData, props.specType));
        if (props.purchaseTermData)
            setAddendumASHFusionDataSource(constructDataSource(props.purchaseTermData, props.snapSpecType));
    }, [props.purchaseTermData]);

    const sharedOnCell = (_: any, index: number) => {
        if ([0, 2, 4, 6, 8, 10, 12, 14, 16].includes(index)) {
            return { colSpan: 0 };
        }
        return {};
    }
    const valiDateWithSnapShotData = (index: number, key: string, value: string): any => {
        let valueAtGivenKey: any = addendumAshFusionDataSource?.[index]?.[key];
        if (valueAtGivenKey) {
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (contractType === ContractTypesEnum.ADDENDUM && valueAtGivenKey !== '-') {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={''}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }
    const constructDataSource = (purchaseTermData: PurchaseTermDataDto, specType: SpecTypesEnum) => {
        let data = specType == SpecTypesEnum.ASTM ? purchaseTermData.astmData : purchaseTermData.isoData;
        let dataSource = [];

        dataSource.push({
            component: 'Reducing Atmosphere',
            rowSpan: 8,
            typical: 'Initial Deformation (Deg.C)',
            rejection: '-',
        });

        dataSource.push({
            component: 'Reducing Atmosphere',
            rowSpan: 0,
            typical: data?.ashReducingInitialTypical ? data?.ashReducingInitialTypical : '-',
            rejection: data?.ashReducingInitialRejection ? data?.ashReducingInitialRejection : '-',
        });

        dataSource.push({
            component: 'Reducing Atmosphere',
            rowSpan: 0,
            typical: 'Softening (Deg.C)',
            rejection: '-',
        });

        dataSource.push({
            component: 'Reducing Atmosphere',
            rowSpan: 0,
            typical: data?.ashReducingSofteningTypical ? data?.ashReducingSofteningTypical : '-',
            rejection: data?.ashReducingSofteningRejection ? data?.ashReducingSofteningRejection : '-',
        });

        dataSource.push({
            component: 'Reducing Atmosphere',
            rowSpan: 0,
            typical: 'Hemispherical Deformation (Deg.C)',
            rejection: data?.sizeDist1Rejection ? data?.sizeDist1Rejection : '-',
        });
        //5
        dataSource.push({
            component: 'Reducing Atmosphere',
            rowSpan: 0,
            typical: data?.ashReducingHemisphericalTypical ? data?.ashReducingHemisphericalTypical : '-',
            rejection: data?.ashReducingHemisphericalRejection ? data?.ashReducingHemisphericalRejection : '-',
        });

        dataSource.push({
            component: 'Reducing Atmosphere',
            rowSpan: 0,
            typical: 'Flow (Deg.C)',
            rejection: data?.sizeDist1Rejection ? data?.sizeDist1Rejection : '-',
        });

        dataSource.push({
            component: 'Reducing Atmosphere',
            rowSpan: 0,
            typical: data?.ashReducingFlowTypical ? data?.ashReducingFlowTypical : '-',
            rejection: data?.ashReducingFlowRejection ? data?.ashReducingFlowRejection : '-',
        });

        //9
        dataSource.push({
            component: 'Oxidizing Atmosphere',
            rowSpan: 8,
            typical: 'Initial Deformation (Deg.C)',
            rejection: '-',
        });

        dataSource.push({
            component: 'Oxidizing Atmosphere',
            rowSpan: 0,
            typical: data?.ashOxidizingInitialTypical ? data?.ashOxidizingInitialTypical : '-',
            rejection: data?.ashOxidizingInitialRejection ? data?.ashOxidizingInitialRejection : '-',
        });

        dataSource.push({
            component: 'Oxidizing Atmosphere',
            rowSpan: 0,
            typical: 'Softening (Deg.C)',
            rejection: data?.sizeDist1Rejection ? data?.sizeDist1Rejection : '-',
        });

        dataSource.push({
            component: 'Oxidizing Atmosphere',
            rowSpan: 0,
            typical: data?.ashOxidizingSofteningTypical ? data?.ashOxidizingSofteningTypical : '-',
            rejection: data?.ashOxidizingSofteningRejection ? data?.ashOxidizingSofteningRejection : '-',
        });

        dataSource.push({
            component: 'Oxidizing Atmosphere',
            rowSpan: 0,
            typical: 'Hemispherical Deformation (Deg.C)',
            rejection: data?.sizeDist1Rejection ? data?.sizeDist1Rejection : '-',
        });

        dataSource.push({
            component: 'Oxidizing Atmosphere',
            rowSpan: 0,
            typical: data?.ashOxidizingHemisphericalTypical ? data?.ashOxidizingHemisphericalTypical : '-',
            rejection: data?.ashOxidizingHemisphericalRejection ? data?.ashOxidizingHemisphericalRejection : '-',
        });
        dataSource.push({
            component: 'Oxidizing Atmosphere',
            rowSpan: 0,
            typical: 'Flow (Deg.C)',
            rejection: data?.sizeDist1Rejection ? data?.sizeDist1Rejection : '-',
        });

        dataSource.push({
            component: 'Oxidizing Atmosphere',
            rowSpan: 0,
            typical: data?.ashOxidizingFlowTypical ? data?.ashOxidizingFlowTypical : '-',
            rejection: data?.ashOxidizingFlowRejection ? data?.ashOxidizingFlowRejection : '-',
        });

        return dataSource;
    };



    const columns: ColumnsType<any> = [
        {
            title: '',
            key: 'component',
            dataIndex: 'component',
            onCell: (record: any) => ({
                rowSpan: record.rowSpan
            }),
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'components', value)}</span>
            ),
        },
        {
            title: 'Ash Typical',
            dataIndex: 'typical',
            key: 'typical',
            onCell: (_, index) => ({
                colSpan: [0, 2, 4, 6, 8, 10, 12, 14, 16].includes((index as number)) ? 2 : 1,
            }),
            render: (text, record, index) => (
                [0, 2, 4, 6, 8, 10, 12, 14, 16].includes((index as number)) ? <a>{valiDateWithSnapShotData(index, 'typical', text)}</a> : <>{text}</>
            ),
        },
        {
            title: 'Ash Rejection',
            dataIndex: 'rejection',
            key: 'rejection',
            onCell: sharedOnCell,
            render: (value, row: any, index) => (
                <span>{valiDateWithSnapShotData(index, 'rejection', value)}</span>
            ),
        }
    ];

    const rowClassName = (row: any, index) => {
        return index % 2 === 0 ? 'row-even' : 'row-odd'; 
      };

    return (
        <Table columns={columns} dataSource={ashFusionDataSource} rowClassName={rowClassName} pagination={false}  className="contracts-PO-List"/>
    )
}

export default ASHFusionView;