import { Card, Checkbox, Collapse, Row, Col, Form, theme, CollapseProps, GetProp } from "antd";
import { useEffect, useState, type CSSProperties } from 'react';

import { CaretRightOutlined } from '@ant-design/icons';


export const CostingsSelection = ({ formRef, initialValues }) => {

  const [values, setValues] = useState({
    performa_purchase: [],
    performa_purchase_invoice: [],
    performa_purchase_payments: [],
    com_purchase: [],
    com_purchase_invoice: [],
    com_purchase_payments: [],
    performa_sales: [],
    performa_sales_invoices: [],
    performa_sales_payments: [],
    com_sales: [],
    com_sales_invoices: [],
    com_sales_payments: [],
    procurement_fees: [],
    procurement_fees_invoices: [],
    procurement_fees_payments: [],
    marketing_fees: [],
    marketing_fees_invoices: [],
    marketing_fees_payments: [],
    barge_costing: [],
    barge_costing_invoices: [],
    barge_costing_payments: [],
    chemical_spray: [],
    chemical_spray_invoices: [],
    chemical_spray_payments: [],
    export_tax: [],
    export_tax_invoices: [],
    export_tax_payments: [],
    doc_charges: [],
    doc_charges_invoices: [],
    doc_charges_payments: [],
    lap_charges: [],
    lap_charges_invoices: [],
    lap_charges_payments: [],
    sam_charges: [],
    sam_charges_invoices: [],
    sam_charges_payments: [],
    pre_ship_inspection: [],
    pre_ship_inspection_invoices: [],
    pre_ship_inspection_payments: [],
    other_expenses: [],
    other_expenses_invoices: [],
    other_expenses_payments: [],
    ret_cargo_charges: [],
    ret_cargo_charges_invoices: [],
    ret_cargo_charges_payments: [],
    float_crane_charges: [],
    float_crane_charges_invoices: [],
    float_crane_charges_payments: [],
    stevedor_costing: [],
    stevedor_costing_invoices: [],
    stevedor_costing_payments: [],
    freight_costing: [],
    freight_costing_invoices: [],
    freight_costing_payments: [],
    pnbp_charges: [],
    pnbp_charges_invoices: [],
    pnbp_charges_payments: [],
    other_income: [],
    other_income_invoices: [],
    other_income_payments: [],
    insurance: [],
    insurance_invoices: [],
    insurance_payments: [],
    dead_freight: [],
    dead_freight_invoices: [],
    dead_freight_payments: [],
    demurrage: [],
    demurrage_invoices: [],
    demurrage_payments: [],
    cre_deb_notes: [],
    cre_deb_notes_invoices: [],
    cre_deb_notes_payments: [],
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          performa_purchase: features?.filter((cou) => 270 <= +cou && +cou <= 278)?.map(Number),
          performa_purchase_invoice: features?.filter((cou) => 279 <= +cou && +cou <= 286)?.map(Number),
          performa_purchase_payments: features?.filter((cou) => 287 <= +cou && +cou <= 290)?.map(Number),

          com_purchase: features?.filter((cou) => 291 <= +cou && +cou <= 299)?.map(Number),
          com_purchase_invoice: features?.filter((cou) => 300 <= +cou && +cou <= 307)?.map(Number),
          com_purchase_payments: features?.filter((cou) => 308 <= +cou && +cou <= 311)?.map(Number),

          performa_sales: features?.filter((cou) => 312 <= +cou && +cou <= 320)?.map(Number),
          performa_sales_invoices: features?.filter((cou) => 321 <= +cou && +cou <= 328)?.map(Number),
          performa_sales_payments: features?.filter((cou) => 329 <= +cou && +cou <= 332)?.map(Number),

          com_sales: features?.filter((cou) => 333 <= +cou && +cou <= 341)?.map(Number),
          com_sales_invoices: features?.filter((cou) => 342 <= +cou && +cou <= 349)?.map(Number),
          com_sales_payments: features?.filter((cou) => 350 <= +cou && +cou <= 353)?.map(Number),

          procurement_fees: features?.filter((cou) => 354 <= +cou && +cou <= 362)?.map(Number),
          procurement_fees_invoices: features?.filter((cou) => 363 <= +cou && +cou <= 370)?.map(Number),
          procurement_fees_payments: features?.filter((cou) => 371 <= +cou && +cou <= 374)?.map(Number),

          marketing_fees: features?.filter((cou) => 375 <= +cou && +cou <= 382)?.map(Number),
          marketing_fees_invoices: features?.filter((cou) => 383 <= +cou && +cou <= 390)?.map(Number),
          marketing_fees_payments: features?.filter((cou) => 391 <= +cou && +cou <= 394)?.map(Number),

          barge_costing: features?.filter((cou) => 395 <= +cou && +cou <= 403)?.map(Number),
          barge_costing_invoices: features?.filter((cou) => 404 <= +cou && +cou <= 411)?.map(Number),
          barge_costing_payments: features?.filter((cou) => 412 <= +cou && +cou <= 415)?.map(Number),

          chemical_spray: features?.filter((cou) => 416 <= +cou && +cou <= 424)?.map(Number),
          chemical_spray_invoices: features?.filter((cou) => 425 <= +cou && +cou <= 432)?.map(Number),
          chemical_spray_payments: features?.filter((cou) => 433 <= +cou && +cou <= 436)?.map(Number),

          export_tax: features?.filter((cou) => 437 <= +cou && +cou <= 445)?.map(Number),
          export_tax_invoices: features?.filter((cou) => 446 <= +cou && +cou <= 453)?.map(Number),
          export_tax_payments: features?.filter((cou) => 454 <= +cou && +cou <= 457)?.map(Number),

          doc_charges: features?.filter((cou) => 458 <= +cou && +cou <= 466)?.map(Number),
          doc_charges_invoices: features?.filter((cou) => 467 <= +cou && +cou <= 474)?.map(Number),
          doc_charges_payments: features?.filter((cou) => 475 <= +cou && +cou <= 478)?.map(Number),

          lap_charges: features?.filter((cou) => 479 <= +cou && +cou <= 487)?.map(Number),
          lap_charges_invoices: features?.filter((cou) => 488 <= +cou && +cou <= 495)?.map(Number),
          lap_charges_payments: features?.filter((cou) => 496 <= +cou && +cou <= 499)?.map(Number),

          sam_charges: features?.filter((cou) => 500 <= +cou && +cou <= 508)?.map(Number),
          sam_charges_invoices: features?.filter((cou) => 509 <= +cou && +cou <= 516)?.map(Number),
          sam_charges_payments: features?.filter((cou) => 517 <= +cou && +cou <= 520)?.map(Number),

          pre_ship_inspection: features?.filter((cou) => 521 <= +cou && +cou <= 529)?.map(Number),
          pre_ship_inspection_invoices: features?.filter((cou) => 530 <= +cou && +cou <= 537)?.map(Number),
          pre_ship_inspection_payments: features?.filter((cou) => 538 <= +cou && +cou <= 541)?.map(Number),

          other_expenses: features?.filter((cou) => 542 <= +cou && +cou <= 550)?.map(Number),
          other_expenses_invoices: features?.filter((cou) => 551 <= +cou && +cou <= 558)?.map(Number),
          other_expenses_payments: features?.filter((cou) => 559 <= +cou && +cou <= 562)?.map(Number),

          ret_cargo_charges: features?.filter((cou) => 563 <= +cou && +cou <= 571)?.map(Number),
          ret_cargo_charges_invoices: features?.filter((cou) => 572 <= +cou && +cou <= 579)?.map(Number),
          ret_cargo_charges_payments: features?.filter((cou) => 580 <= +cou && +cou <= 583)?.map(Number),

          float_crane_charges: features?.filter((cou) => 584 <= +cou && +cou <= 592)?.map(Number),
          float_crane_charges_invoices: features?.filter((cou) => 593 <= +cou && +cou <= 600)?.map(Number),
          float_crane_charges_payments: features?.filter((cou) => 601 <= +cou && +cou <= 604)?.map(Number),

          stevedor_costing: features?.filter((cou) => 605 <= +cou && +cou <= 613)?.map(Number),
          stevedor_costing_invoices: features?.filter((cou) => 614 <= +cou && +cou <= 621)?.map(Number),
          stevedor_costing_payments: features?.filter((cou) => 622 <= +cou && +cou <= 625)?.map(Number),

          freight_costing: features?.filter((cou) => 626 <= +cou && +cou <= 633)?.map(Number),
          freight_costing_invoices: features?.filter((cou) => 634 <= +cou && +cou <= 641)?.map(Number),
          freight_costing_payments: features?.filter((cou) => 642 <= +cou && +cou <= 645)?.map(Number),

          pnbp_charges: features?.filter((cou) => 646 <= +cou && +cou <= 653)?.map(Number),
          pnbp_charges_invoices: features?.filter((cou) => 654 <= +cou && +cou <= 661)?.map(Number),
          pnbp_charges_payments: features?.filter((cou) => 662 <= +cou && +cou <= 665)?.map(Number),

          other_income: features?.filter((cou) => 666 <= +cou && +cou <= 673)?.map(Number),
          other_income_invoices: features?.filter((cou) => 674 <= +cou && +cou <= 681)?.map(Number),
          other_income_payments: features?.filter((cou) => 682 <= +cou && +cou <= 685)?.map(Number),

          insurance: features?.filter((cou) => 686 <= +cou && +cou <= 693)?.map(Number),
          insurance_invoices: features?.filter((cou) => 694 <= +cou && +cou <= 701)?.map(Number),
          insurance_payments: features?.filter((cou) => 702 <= +cou && +cou <= 705)?.map(Number),

          dead_freight: features?.filter((cou) => 706 <= +cou && +cou <= 713)?.map(Number),
          dead_freight_invoices: features?.filter((cou) => 714 <= +cou && +cou <= 721)?.map(Number),
          dead_freight_payments: features?.filter((cou) => 722 <= +cou && +cou <= 725)?.map(Number),

          demurrage: features?.filter((cou) => 726 <= +cou && +cou <= 733)?.map(Number),
          demurrage_invoices: features?.filter((cou) => 734 <= +cou && +cou <= 741)?.map(Number),
          demurrage_payments: features?.filter((cou) => 742 <= +cou && +cou <= 745)?.map(Number),

          cre_deb_notes: features?.filter((cou) => 746 <= +cou && +cou <= 753)?.map(Number),
          cre_deb_notes_invoices: features?.filter((cou) => 754 <= +cou && +cou <= 761)?.map(Number),
          cre_deb_notes_payments: features?.filter((cou) => 762 <= +cou && +cou <= 765)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  const { token } = theme.useToken();


  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({ ...prev, [name]: value }))
    }
    else setValues((prev) => ({ ...prev, [name]: [] }));
  }


  const panelStyle: React.CSSProperties = {
    marginBottom: 10,
    background: 'rgb(241, 239, 234)',
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (
    panelStyle
  ) => [
      {
        key: '1',
        label: 'Performa Purchase',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Purchase Purchase Costing"
            extra={<Checkbox
              name="performa_purchase"
              indeterminate={values.performa_purchase.length > 0 && values.performa_purchase.length < 9}
              checked={values.performa_purchase.length === 9}
              onChange={(e) => onChange(e, 'performa_purchase', [270, 271, 272, 273, 274, 275, 276, 277, 278])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="performa_purchase" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, performa_purchase: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={270}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={271}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={272}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={273}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={274}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={275}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={276}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={277}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={278}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Performa Purchase Invoices"
            extra={<Checkbox
              name="performa_purchase_invoice"
              indeterminate={values.performa_purchase_invoice.length > 0 && values.performa_purchase_invoice.length < 8}
              checked={values.performa_purchase_invoice.length === 8}
              onChange={(e) => onChange(e, 'performa_purchase_invoice', [279, 280, 281, 282, 283, 284, 285, 286])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="performa_purchase_invoice" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, performa_purchase_invoice: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={279}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={280}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={281}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={282}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={283}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={284}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={285}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={286}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Performa Purchase Payments"
            extra={<Checkbox
              name="performa_purchase_payments"
              indeterminate={values.performa_purchase_payments.length > 0 && values.performa_purchase_payments.length < 4}
              checked={values.performa_purchase_payments.length === 4}
              onChange={(e) => onChange(e, 'performa_purchase_payments', [287, 288, 289, 290])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="performa_purchase_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, performa_purchase_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={287}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={288}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={289}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={290}>Reject</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '2',
        label: 'Commercial Purchase',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Commercial Purchase Costing"
            extra={<Checkbox
              name="com_purchase"
              indeterminate={values.com_purchase.length > 0 && values.com_purchase.length < 9}
              checked={values.com_purchase.length === 9}
              onChange={(e) => onChange(e, 'com_purchase', [291, 292, 293, 294, 295, 296, 297, 298, 299])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="com_purchase" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, com_purchase: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={291}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={292}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={293}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={294}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={295}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={296}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={297}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={298}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={299}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Commercial Purchase Invoices"
            extra={<Checkbox
              name="com_purchase_invoice"
              indeterminate={values.com_purchase_invoice.length > 0 && values.com_purchase_invoice.length < 7}
              checked={values.com_purchase_invoice.length === 7}
              onChange={(e) => onChange(e, 'com_purchase_invoice', [300, 301, 302, 303, 304, 305, 306])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="com_purchase_invoice" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, com_purchase_invoice: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={300}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={301}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={302}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={303}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={304}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={305}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={306}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Commercial Purchase Payments"
            extra={<Checkbox
              name="com_purchase_payments"
              indeterminate={values.com_purchase_payments.length > 0 && values.com_purchase_payments.length < 4}
              checked={values.com_purchase_payments.length === 4}
              onChange={(e) => onChange(e, 'com_purchase_payments', [308, 309, 310, 311])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="com_purchase_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, com_purchase_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={308}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={309}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={310}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={311}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '3',
        label: 'Performa Sales',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Performa Sales Costing"
            extra={<Checkbox
              name="performa_sales"
              indeterminate={values.performa_sales.length > 0 && values.performa_sales.length < 9}
              checked={values.performa_sales.length === 9}
              onChange={(e) => onChange(e, 'performa_sales', [312, 313, 314, 315, 316, 317, 318, 319, 320])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="performa_sales" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, performa_sales: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={312}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={313}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={314}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={315}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={316}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={317}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={318}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={319}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={320}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Performa Sales Invoices"
            extra={<Checkbox
              name="performa_sales_invoices"
              indeterminate={values.performa_sales_invoices.length > 0 && values.performa_sales_invoices.length < 8}
              checked={values.performa_sales_invoices.length === 8}
              onChange={(e) => onChange(e, 'performa_sales_invoices', [321, 322, 323, 324, 325, 326, 327, 328])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="performa_sales_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, performa_sales_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={321}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={322}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={323}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={324}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={325}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={326}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={327}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={328}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Performa Sales Payments"
            extra={<Checkbox
              name="performa_sales_payments"
              indeterminate={values.performa_sales_payments.length > 0 && values.performa_sales_payments.length < 4}
              checked={values.performa_sales_payments.length === 4}
              onChange={(e) => onChange(e, 'performa_sales_payments', [329, 330, 331, 332])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="performa_sales_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, performa_sales_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={329}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={330}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={331}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={332}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '4',
        label: 'Commercial Sales',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Commercial Sales Costing"
            extra={<Checkbox
              name="com_sales"
              indeterminate={values.com_sales.length > 0 && values.com_sales.length < 9}
              checked={values.com_sales.length === 9}
              onChange={(e) => onChange(e, 'com_sales', [333, 334, 335, 336, 337, 338, 339, 340, 341])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="com_sales" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, com_sales: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={333}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={334}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={335}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={336}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={337}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={338}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={339}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={340}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={341}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Commercial Sales Invoices"
            extra={<Checkbox
              name="com_sales_invoices"
              indeterminate={values.com_sales_invoices.length > 0 && values.com_sales_invoices.length < 8}
              checked={values.com_sales_invoices.length === 8}
              onChange={(e) => onChange(e, 'com_sales_invoices', [342, 343, 344, 345, 346, 347, 348, 349])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="com_sales_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, com_sales_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={342}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={343}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={344}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={345}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={346}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={347}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={348}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={349}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Commercial Sales Payments"
            extra={<Checkbox
              name="com_sales_payments"
              indeterminate={values.com_sales_payments.length > 0 && values.com_sales_payments.length < 4}
              checked={values.com_sales_payments.length === 4}
              onChange={(e) => onChange(e, 'com_sales_payments', [350, 351, 352, 353])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="com_sales_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, com_sales_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={350}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={351}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={352}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={353}>Reject</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '5',
        label: 'Procurement Fees',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Procurement Fees Costing"
            extra={<Checkbox
              name="procurement_fees"
              indeterminate={values.procurement_fees.length > 0 && values.procurement_fees.length < 9}
              checked={values.procurement_fees.length === 9}
              onChange={(e) => onChange(e, 'procurement_fees', [354, 355, 356, 357, 358, 359, 360, 361, 362])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="procurement_fees" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, procurement_fees: value }))}>

                <Row>

                  <Col span={6}>
                    <Checkbox value={354}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={355}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={356}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={357}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={358}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={359}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={360}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={361}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={362}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Procurement Fees Invoices"
            extra={<Checkbox
              name="procurement_fees_invoices"
              indeterminate={values.procurement_fees_invoices.length > 0 && values.procurement_fees_invoices.length < 8}
              checked={values.procurement_fees_invoices.length === 8}
              onChange={(e) => onChange(e, 'procurement_fees_invoices', [363, 364, 365, 366, 367, 368, 369, 370])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="procurement_fees_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, procurement_fees_invoices: value }))}>

                <Row>

                  <Col span={6}>
                    <Checkbox value={363}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={364}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={365}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={366}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={367}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={368}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={369}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={370}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Procurement Fees Payments"
            extra={<Checkbox
              name="procurement_fees_payments"
              indeterminate={values.procurement_fees_payments.length > 0 && values.procurement_fees_payments.length < 4}
              checked={values.procurement_fees_payments.length === 4}
              onChange={(e) => onChange(e, 'procurement_fees_payments', [371, 372, 373, 374])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="procurement_fees_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, procurement_fees_payments: value }))}>

                <Row>

                  <Col span={6}>
                    <Checkbox value={371}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={372}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={373}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={374}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '6',
        label: 'Marketing Fees',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Marketing Fees Costing"
            extra={<Checkbox
              name="marketing_fees"
              indeterminate={values.marketing_fees.length > 0 && values.marketing_fees.length < 9}
              checked={values.marketing_fees.length === 9}
              onChange={(e) => onChange(e, 'marketing_fees', [375, 376, 377, 378, 379, 380, 381, 382,])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="marketing_fees" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, marketing_fees: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={375}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={376}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={377}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={378}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={379}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={380}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={381}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={382}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value="4">Files</Checkbox> // need to add new value
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Marketing Fees Invoices"
            extra={<Checkbox
              name="marketing_fees_invoices"
              indeterminate={values.marketing_fees_invoices.length > 0 && values.marketing_fees_invoices.length < 8}
              checked={values.marketing_fees_invoices.length === 8}
              onChange={(e) => onChange(e, 'marketing_fees_invoices', [383, 384, 385, 386, 387, 388, 389, 390])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="marketing_fees_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, marketing_fees_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={383}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={384}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={385}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={386}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={387}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={388}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={389}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={390}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Marketing Fees Payments"
            extra={<Checkbox
              name="marketing_fees_payments"
              indeterminate={values.marketing_fees_payments.length > 0 && values.marketing_fees_payments.length < 4}
              checked={values.marketing_fees_payments.length === 4}
              onChange={(e) => onChange(e, 'marketing_fees_payments', [391, 392, 393, 394])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="marketing_fees_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, marketing_fees_payments: value }))}>
                <Row>
                  <Col span={6}>
                    <Checkbox value={391}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={392}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={393}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={394}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '7',
        label: 'Barge Costing',
        style: panelStyle,
        forceRender: true,
        children: <>

          <Card size="small" type="inner" title="Barge Costing"
            extra={<Checkbox
              name="barge_costing"
              indeterminate={values.barge_costing.length > 0 && values.barge_costing.length < 9}
              checked={values.barge_costing.length === 9}
              onChange={(e) => onChange(e, 'barge_costing', [395, 396, 397, 398, 399, 400, 401, 402, 403])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="barge_costing" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, barge_costing: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={395}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={396}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={397}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={398}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={399}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={400}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={401}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={402}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={403}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Barge Costing Invoices"
            extra={<Checkbox
              name="barge_costing_invoices"
              indeterminate={values.barge_costing_invoices.length > 0 && values.barge_costing_invoices.length < 8}
              checked={values.barge_costing_invoices.length === 8}
              onChange={(e) => onChange(e, 'barge_costing_invoices', [404, 405, 406, 407, 408, 409, 410, 411])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="barge_costing_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, barge_costing_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={404}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={405}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={406}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={407}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={408}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={409}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={410}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={411}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Barge Costing Payments"
            extra={<Checkbox
              name="barge_costing_payments"
              indeterminate={values.barge_costing_payments.length > 0 && values.barge_costing_payments.length < 4}
              checked={values.barge_costing_payments.length === 4}
              onChange={(e) => onChange(e, 'barge_costing_payments', [412, 413, 414, 415])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="barge_costing_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, barge_costing_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={412}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={413}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={414}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={415}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '8',
        label: 'Chemical Spray',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Chemical Spray Costing"
            extra={<Checkbox
              name="chemical_spray"
              indeterminate={values.chemical_spray.length > 0 && values.chemical_spray.length < 9}
              checked={values.chemical_spray.length === 9}
              onChange={(e) => onChange(e, 'chemical_spray', [416, 417, 418, 419, 420, 421, 422, 423, 424])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="chemical_spray" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, chemical_spray: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={416}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={417}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={418}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={419}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={420}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={421}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={422}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={423}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={424}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Chemical Spray Invoices"
            extra={<Checkbox
              name="chemical_spray_invoices"
              indeterminate={values.chemical_spray_invoices.length > 0 && values.chemical_spray_invoices.length < 8}
              checked={values.chemical_spray_invoices.length === 8}
              onChange={(e) => onChange(e, 'chemical_spray_invoices', [425, 426, 427, 428, 429, 430, 431, 432])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="chemical_spray_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, chemical_spray_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={425}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={426}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={427}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={428}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={429}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={430}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={431}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={432}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Chemical Spray Payments"
            extra={<Checkbox
              name="chemical_spray_payments"
              indeterminate={values.chemical_spray_payments.length > 0 && values.chemical_spray_payments.length < 4}
              checked={values.chemical_spray_payments.length === 4}
              onChange={(e) => onChange(e, 'chemical_spray_payments', [433, 434, 435, 436])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="chemical_spray_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, chemical_spray_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={433}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={434}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={435}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={436}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '9',
        label: 'Export Tax',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Export Tax Costing"
            extra={<Checkbox
              name="export_tax"
              indeterminate={values.export_tax.length > 0 && values.export_tax.length < 9}
              checked={values.export_tax.length === 9}
              onChange={(e) => onChange(e, 'export_tax', [437, 438, 439, 440, 441, 442, 443, 444, 445])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="export_tax" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, export_tax: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={437}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={438}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={439}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={440}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={441}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={442}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={443}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={444}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={445}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Export Tax Invoices"
            extra={<Checkbox
              name="export_tax_invoices"
              indeterminate={values.export_tax_invoices.length > 0 && values.export_tax_invoices.length < 8}
              checked={values.export_tax_invoices.length === 8}
              onChange={(e) => onChange(e, 'export_tax_invoices', [446, 447, 448, 449, 450, 451, 452, 453])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="export_tax_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, export_tax_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={446}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={447}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={448}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={449}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={450}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={451}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={452}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={453}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Export Tax Payments"
            extra={<Checkbox
              name="export_tax_payments"
              indeterminate={values.export_tax_payments.length > 0 && values.export_tax_payments.length < 4}
              checked={values.export_tax_payments.length === 4}
              onChange={(e) => onChange(e, 'export_tax_payments', [454, 455, 456, 457])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="export_tax_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, export_tax_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={454}>View</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={455}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={456}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={457}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '10',
        label: 'Documentation Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Documentation Charges Costing"
            extra={<Checkbox
              name="doc_charges"
              indeterminate={values.doc_charges.length > 0 && values.doc_charges.length < 9}
              checked={values.doc_charges.length === 9}
              onChange={(e) => onChange(e, 'doc_charges', [458, 459, 460, 461, 462, 463, 464, 465, 466])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="doc_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, doc_charges: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={458}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={459}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={460}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={461}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={462}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={463}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={464}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={465}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={466}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Documentation Charges Invoices"
            extra={<Checkbox
              name="doc_charges_invoices"
              indeterminate={values.doc_charges_invoices.length > 0 && values.doc_charges_invoices.length < 8}
              checked={values.doc_charges_invoices.length === 8}
              onChange={(e) => onChange(e, 'doc_charges_invoices', [467, 468, 469, 470, 471, 472, 473, 474])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="doc_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, doc_charges_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={467}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={468}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={469}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={470}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={471}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={472}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={473}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={474}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Documentation Charges Payments"
            extra={<Checkbox
              name="costing_details"
              indeterminate={values.doc_charges_payments.length > 0 && values.doc_charges_payments.length < 4}
              checked={values.doc_charges_payments.length === 4}
              onChange={(e) => onChange(e, 'doc_charges_payments', [475, 476, 477, 478])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="doc_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, doc_charges_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={475}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={476}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={477}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={478}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '11',
        label: 'Laporan Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Laporan Charges Costing"
            extra={<Checkbox
              name="lap_charges"
              indeterminate={values.lap_charges.length > 0 && values.lap_charges.length < 9}
              checked={values.lap_charges.length === 9}
              onChange={(e) => onChange(e, 'lap_charges', [479, 480, 481, 482, 483, 484, 485, 486, 487])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="lap_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, lap_charges: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={479}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={480}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={481}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={482}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={483}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={484}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={485}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={486}>Management Approval</Checkbox>
                  </Col>

                  <Col span={6}>
                    <Checkbox value={487}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Laporan Charges Invoices"
            extra={<Checkbox
              name="lap_charges_invoices"
              indeterminate={values.lap_charges_invoices.length > 0 && values.lap_charges_invoices.length < 8}
              checked={values.lap_charges_invoices.length === 8}
              onChange={(e) => onChange(e, 'lap_charges_invoices', [488, 489, 490, 491, 492, 493, 494, 495])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="lap_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, lap_charges_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={488}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={489}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={490}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={491}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={492}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={493}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={494}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={495}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Laporan Charges Payments"
            extra={<Checkbox
              name="lap_charges_payments"
              indeterminate={values.lap_charges_payments.length > 0 && values.lap_charges_payments.length < 4}
              checked={values.lap_charges_payments.length === 4}
              onChange={(e) => onChange(e, 'lap_charges_payments', [496, 497, 498, 499])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="lap_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, lap_charges_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={496}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={497}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={498}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={499}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '12',
        label: 'Sampling & Analasyis Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Sampling & Analasyis Charges"
            extra={<Checkbox
              name="sam_charges"
              indeterminate={values.sam_charges.length > 0 && values.sam_charges.length < 9}
              checked={values.sam_charges.length === 9}
              onChange={(e) => onChange(e, 'sam_charges', [500, 501, 502, 503, 504, 505, 506, 507, 508])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="sam_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, sam_charges: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={500}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={501}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={502}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={503}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={504}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={505}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={506}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={507}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={508}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Sampling & Analasyis Charges Invoices"
            extra={<Checkbox
              name="sam_charges_invoices"
              indeterminate={values.sam_charges_invoices.length > 0 && values.sam_charges_invoices.length < 8}
              checked={values.sam_charges_invoices.length === 8}
              onChange={(e) => onChange(e, 'sam_charges_invoices', [509, 510, 511, 512, 513, 514, 515, 516])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="sam_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, sam_charges_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={509}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={510}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={511}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={512}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={513}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={514}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={515}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={516}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Sampling & Analasyis Charges Payments"
            extra={<Checkbox
              name="sam_charges_payments"
              indeterminate={values.sam_charges_payments.length > 0 && values.sam_charges_payments.length < 4}
              checked={values.sam_charges_payments.length === 4}
              onChange={(e) => onChange(e, 'sam_charges_payments', [517, 518, 519, 520])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="sam_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, sam_charges_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={517}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={518}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={519}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={520}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '13',
        label: 'Pre Shipment Inspection',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Pre Shipment Inspection"
            extra={<Checkbox
              name="pre_ship_inspection"
              indeterminate={values.pre_ship_inspection.length > 0 && values.pre_ship_inspection.length < 9}
              checked={values.pre_ship_inspection.length === 9}
              onChange={(e) => onChange(e, 'pre_ship_inspection', [521, 522, 523, 524, 525, 526, 527, 528, 529])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="pre_ship_inspection" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, pre_ship_inspection: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={521}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={522}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={523}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={524}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={525}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={526}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={527}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={528}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={529}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Pre Shipment Inspection Invoices"
            extra={<Checkbox
              name="pre_ship_inspection_invoices"
              indeterminate={values.pre_ship_inspection_invoices.length > 0 && values.pre_ship_inspection_invoices.length < 8}
              checked={values.pre_ship_inspection_invoices.length === 8}
              onChange={(e) => onChange(e, 'pre_ship_inspection_invoices', [530, 531, 532, 533, 534, 535, 536, 537])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="pre_ship_inspection_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, pre_ship_inspection_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={530}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={531}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={532}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={533}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={534}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={535}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={536}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={537}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Pre Shipment Inspection Payments"
            extra={<Checkbox
              name="pre_ship_inspection_payments"
              indeterminate={values.pre_ship_inspection_payments.length > 0 && values.pre_ship_inspection_payments.length < 4}
              checked={values.pre_ship_inspection_payments.length === 4}
              onChange={(e) => onChange(e, 'pre_ship_inspection_payments', [538, 539, 540, 541])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="pre_ship_inspection_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, pre_ship_inspection_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={538}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={539}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={540}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={541}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '14',
        label: 'Other Expenses',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Other Expenses"
            extra={<Checkbox
              name="other_expenses"
              indeterminate={values.other_expenses.length > 0 && values.other_expenses.length < 9}
              checked={values.other_expenses.length === 9}
              onChange={(e) => onChange(e, 'other_expenses', [542, 543, 544, 545, 546, 547, 548, 549, 550])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="other_expenses" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, other_expenses: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={542}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={543}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={544}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={545}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={546}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={547}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={548}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={549}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={550}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Other Expenses Invoices"
            extra={<Checkbox
              name="other_expenses_invoices"
              indeterminate={values.other_expenses_invoices.length > 0 && values.other_expenses_invoices.length < 8}
              checked={values.other_expenses_invoices.length === 8}
              onChange={(e) => onChange(e, 'other_expenses_invoices', [551, 552, 553, 554, 555, 556, 557, 558])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="other_expenses_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, other_expenses_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={551}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={552}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={553}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={554}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={555}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={556}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={557}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={558}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Other Expenses Payments"
            extra={<Checkbox
              name="other_expenses_payments"
              indeterminate={values.other_expenses_payments.length > 0 && values.other_expenses_payments.length < 4}
              checked={values.other_expenses_payments.length === 4}
              onChange={(e) => onChange(e, 'other_expenses_payments', [559, 560, 561, 562])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="other_expenses_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, other_expenses_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={559}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={560}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={561}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={562}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '15',
        label: 'Return Cargo Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Return Cargo Charges"
            extra={<Checkbox
              name="ret_cargo_charges"
              indeterminate={values.ret_cargo_charges.length > 0 && values.ret_cargo_charges.length < 9}
              checked={values.ret_cargo_charges.length === 9}
              onChange={(e) => onChange(e, 'ret_cargo_charges', [563, 564, 565, 566, 567, 568, 569, 570, 571])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="ret_cargo_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, ret_cargo_charges: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={563}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={564}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={565}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={566}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={567}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={568}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={569}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={570}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={571}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Return Cargo Charges Invoices"
            extra={<Checkbox
              name="ret_cargo_charges_invoices"
              indeterminate={values.ret_cargo_charges_invoices.length > 0 && values.ret_cargo_charges_invoices.length < 8}
              checked={values.ret_cargo_charges_invoices.length === 8}
              onChange={(e) => onChange(e, 'ret_cargo_charges_invoices', [572, 573, 574, 575, 576, 577, 578, 579])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="ret_cargo_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, ret_cargo_charges_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={572}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={573}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={574}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={575}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={576}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={577}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={578}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={579}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Return Cargo Charges Payments"
            extra={<Checkbox
              name="ret_cargo_charges_payments"
              indeterminate={values.ret_cargo_charges_payments.length > 0 && values.ret_cargo_charges_payments.length < 4}
              checked={values.ret_cargo_charges_payments.length === 4}
              onChange={(e) => onChange(e, 'ret_cargo_charges_payments', [580, 581, 582, 583])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="ret_cargo_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, ret_cargo_charges_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={580}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={581}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={582}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={583}>Release</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '16',
        label: 'Floating Crane Charges',
        style: panelStyle,
        forceRender: true,
        children: <>

          <Card size="small" type="inner" title="Floating Crane Charges"
            extra={<Checkbox
              name="float_crane_charges"
              indeterminate={values.float_crane_charges.length > 0 && values.float_crane_charges.length < 9}
              checked={values.float_crane_charges.length === 9}
              onChange={(e) => onChange(e, 'float_crane_charges', [584, 585, 586, 587, 588, 589, 590, 591, 592])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="float_crane_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, float_crane_charges: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={584}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={585}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={586}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={587}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={588}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={589}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={590}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={591}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={592}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Floating Crane Invoices"
            extra={<Checkbox
              name="float_crane_charges_invoices"
              indeterminate={values.float_crane_charges_invoices.length > 0 && values.float_crane_charges_invoices.length < 8}
              checked={values.float_crane_charges_invoices.length === 8}
              onChange={(e) => onChange(e, 'float_crane_charges_invoices', [593, 594, 595, 596, 597, 598, 599, 600])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="float_crane_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, float_crane_charges_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={593}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={594}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={595}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={596}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={597}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={598}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={599}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={600}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Floating Crane Payments"
            extra={<Checkbox
              name="float_crane_charges_payments"
              indeterminate={values.float_crane_charges_payments.length > 0 && values.float_crane_charges_payments.length < 4}
              checked={values.float_crane_charges_payments.length === 4}
              onChange={(e) => onChange(e, 'float_crane_charges_payments', [601, 602, 603, 604])}
            >
              <b>Select All</b>
            </Checkbox>}>
            <Form.Item name="float_crane_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, float_crane_charges_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={601}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={602}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={603}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={604}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '17',
        label: 'Stevedore Costing',
        style: panelStyle,
        forceRender: true,
        children: <>

          <Card size="small" type="inner" title="Stevedore Costing"
            extra={<Checkbox
              name="stevedor_costing"
              indeterminate={values.stevedor_costing.length > 0 && values.stevedor_costing.length < 9}
              checked={values.stevedor_costing.length === 9}
              onChange={(e) => onChange(e, 'stevedor_costing', [605, 606, 607, 608, 609, 610, 611, 612, 613])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="stevedor_costing" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, stevedor_costing: value }))}>

                <Row>

                  <Col span={6}>
                    <Checkbox value={605}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={606}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={607}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={608}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={609}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={610}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={611}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={612}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={613}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Stevedore Costing Invoices"
            extra={<Checkbox
              name="costing_details"
              indeterminate={values.stevedor_costing_invoices.length > 0 && values.stevedor_costing_invoices.length < 8}
              checked={values.stevedor_costing_invoices.length === 8}
              onChange={(e) => onChange(e, 'stevedor_costing_invoices', [614, 615, 616, 617, 618, 619, 620, 621])}
            >
              <b>Select All</b>
            </Checkbox>}>
            <Form.Item name="stevedor_costing_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, stevedor_costing_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={614}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={615}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={616}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={617}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={618}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={619}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={620}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={621}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Stevedore Costing Payments"
            extra={<Checkbox
              name="stevedor_costing_payments"
              indeterminate={values.stevedor_costing_payments.length > 0 && values.stevedor_costing_payments.length < 4}
              checked={values.stevedor_costing_payments.length === 4}
              onChange={(e) => onChange(e, 'stevedor_costing_payments', [622, 623, 624, 625])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="stevedor_costing_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, stevedor_costing_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={622}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={623}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={624}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={625}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>

        </>

      },
      {
        key: '19',
        label: 'Freight Costing',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Freight Costing"
            extra={<Checkbox
              name="freight_costing"
              indeterminate={values.freight_costing.length > 0 && values.freight_costing.length < 8}
              checked={values.freight_costing.length === 8}
              onChange={(e) => onChange(e, 'freight_costing', [626, 627, 628, 629, 630, 631, 632, 633])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="freight_costing" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, freight_costing: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={626}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={627}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={628}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={629}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={629}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={630}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={631}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={632}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={633}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Freight Costing Invoices"
            extra={<Checkbox
              name="freight_costing_invoices"
              indeterminate={values.freight_costing_invoices.length > 0 && values.freight_costing_invoices.length < 8}
              checked={values.freight_costing_invoices.length === 8}
              onChange={(e) => onChange(e, 'freight_costing_invoices', [634, 635, 636, 637, 638, 639, 640, 641])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="freight_costing_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, freight_costing_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={634}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={635}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={636}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={637}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={638}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={639}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={640}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={641}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Freight Costing Payments"
            extra={<Checkbox
              name="freight_costing_payments"
              indeterminate={values.freight_costing_payments.length > 0 && values.freight_costing_payments.length < 4}
              checked={values.freight_costing_payments.length === 4}
              onChange={(e) => onChange(e, 'freight_costing_payments', [642, 643, 644, 645])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="freight_costing_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, freight_costing_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={642}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={643}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={644}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={645}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '20',
        label: 'PNBP Charges',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="PNBP Charges"
            extra={<Checkbox
              name="pnbp_charges"
              indeterminate={values.pnbp_charges.length > 0 && values.pnbp_charges.length < 8}
              checked={values.pnbp_charges.length === 8}
              onChange={(e) => onChange(e, 'pnbp_charges', [646, 647, 648, 649, 650, 651, 652, 653])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="pnbp_charges" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, pnbp_charges: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={646}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={647}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={648}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={649}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={650}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={651}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={652}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={653}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="PNBP Charges Invoices"
            extra={<Checkbox
              name="pnbp_charges_invoices"
              indeterminate={values.pnbp_charges_invoices.length > 0 && values.pnbp_charges_invoices.length < 8}
              checked={values.pnbp_charges_invoices.length === 8}
              onChange={(e) => onChange(e, 'pnbp_charges_invoices', [654, 655, 656, 657, 658, 659, 660, 661])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="pnbp_charges_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, pnbp_charges_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={654}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={655}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={656}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={657}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={658}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={659}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={660}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={661}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="PNBP Charges Payments"
            extra={<Checkbox
              name="pnbp_charges_payments"
              indeterminate={values.pnbp_charges_payments.length > 0 && values.pnbp_charges_payments.length < 4}
              checked={values.pnbp_charges_payments.length === 4}
              onChange={(e) => onChange(e, 'pnbp_charges_payments', [662, 663, 664, 665])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="pnbp_charges_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, pnbp_charges_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={662}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={663}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={664}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={665}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '21',
        label: 'Other Income',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Other Income"
            extra={<Checkbox
              name="other_income"
              indeterminate={values.other_income.length > 0 && values.other_income.length < 8}
              checked={values.other_income.length === 8}
              onChange={(e) => onChange(e, 'other_income', [666, 667, 668, 669, 670, 671, 672, 673])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="other_income" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, other_income: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={666}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={667}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={668}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={669}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={670}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={671}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={672}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={673}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Other Income Invoices"
            extra={<Checkbox
              name="other_income_invoices"
              indeterminate={values.other_income_invoices.length > 0 && values.other_income_invoices.length < 8}
              checked={values.other_income_invoices.length === 8}
              onChange={(e) => onChange(e, 'other_income_invoices', [674, 675, 676, 677, 678, 679, 680, 681])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="other_income_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, other_income_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={674}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={675}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={676}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={677}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={678}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={679}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={680}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={681}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Other Income Payments"
            extra={<Checkbox
              name="other_income_payments"
              indeterminate={values.other_income_payments.length > 0 && values.other_income_payments.length < 4}
              checked={values.other_income_payments.length === 4}
              onChange={(e) => onChange(e, 'other_income_payments', [682, 683, 684, 685])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="other_income_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, other_income_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={682}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={683}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={684}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={685}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '18',
        label: 'Insurance',
        style: panelStyle,
        forceRender: true,
        children: <>

          <Card size="small" type="inner" title="Insurance"
            extra={<Checkbox
              name="insurance"
              indeterminate={values.insurance.length > 0 && values.insurance.length < 8}
              checked={values.insurance.length === 8}
              onChange={(e) => onChange(e, 'insurance', [686, 687, 688, 689, 690, 691, 692, 693])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="insurance" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, insurance: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={686}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={687}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={688}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={689}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={690}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={691}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={692}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={693}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Insurance Invoices"
            extra={<Checkbox
              name="insurance_invoices"
              indeterminate={values.insurance_invoices.length > 0 && values.insurance_invoices.length < 8}
              checked={values.insurance_invoices.length === 8}
              onChange={(e) => onChange(e, 'insurance_invoices', [694, 695, 696, 697, 698, 699, 700, 701])}
            >
              <b>Select All</b>
            </Checkbox>}>

            <Form.Item name="insurance_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, insurance_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={694}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={695}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={696}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={697}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={698}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={699}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={700}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={701}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Insurance Payments"
            extra={<Checkbox
              name="insurance_payments"
              indeterminate={values.insurance_payments.length > 0 && values.insurance_payments.length < 4}
              checked={values.insurance_payments.length === 4}
              onChange={(e) => onChange(e, 'insurance_payments', [702, 703, 704, 705])}
            >
              <b>Select All</b>
            </Checkbox>}>

            <Form.Item name="insurance_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, insurance_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={702}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={703}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={704}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={705}>Reject</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },



      {
        key: '22',
        label: 'Dead Freight',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Dead Freight"
            extra={<Checkbox
              name="dead_freight"
              indeterminate={values.dead_freight.length > 0 && values.dead_freight.length < 8}
              checked={values.dead_freight.length === 8}
              onChange={(e) => onChange(e, 'dead_freight', [706, 707, 708, 709, 710, 711, 712, 713])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="dead_freight" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, dead_freight: value }))}>

                <Row>

                  <Col span={6}>
                    <Checkbox value={706}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={707}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={708}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={709}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={710}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={711}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={712}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={713}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Dead Freight Invoices"
            extra={<Checkbox
              name="dead_freight_invoices"
              indeterminate={values.dead_freight_invoices.length > 0 && values.dead_freight_invoices.length < 8}
              checked={values.dead_freight_invoices.length === 8}
              onChange={(e) => onChange(e, 'dead_freight_invoices', [714, 715, 716, 717, 718, 719, 720, 721])}
            >
              <b>Select All</b>
            </Checkbox>}>

            <Form.Item name="dead_freight_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, dead_freight_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={714}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={715}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={716}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={717}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={718}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={719}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={720}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={721}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Dead Freight Payments"
            extra={<Checkbox
              name="dead_freight_payments"
              indeterminate={values.dead_freight_payments.length > 0 && values.dead_freight_payments.length < 4}
              checked={values.dead_freight_payments.length === 4}
              onChange={(e) => onChange(e, 'dead_freight_payments', [722, 723, 724, 725])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="dead_freight_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, dead_freight_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={722}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={723}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={724}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={725}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '23',
        label: 'Demurrage',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Demurrage"
            extra={<Checkbox
              name="demurrage"
              indeterminate={values.demurrage.length > 0 && values.demurrage.length < 8}
              checked={values.demurrage.length === 8}
              onChange={(e) => onChange(e, 'demurrage', [726, 727, 728, 729, 730, 731, 732, 733])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="demurrage" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, demurrage: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={726}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={727}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={728}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={729}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={730}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={731}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={732}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={733}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Demurrage Invoices"
            extra={<Checkbox
              name="demurrage_invoices"
              indeterminate={values.demurrage_invoices.length > 0 && values.demurrage_invoices.length < 8}
              checked={values.demurrage_invoices.length === 8}
              onChange={(e) => onChange(e, 'demurrage_invoices', [734, 735, 736, 737, 738, 739, 740, 741])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="demurrage_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, demurrage_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={734}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={735}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={736}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={737}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={738}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={739}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={740}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={741}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Demurrage Payments"
            extra={<Checkbox
              name="demurrage_payments"
              indeterminate={values.demurrage_payments.length > 0 && values.demurrage_payments.length < 4}
              checked={values.demurrage_payments.length === 4}
              onChange={(e) => onChange(e, 'demurrage_payments', [742, 743, 744, 745])}
            >
              <b>Select All</b>
            </Checkbox>}>

            <Form.Item name="demurrage_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, demurrage_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={742}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={743}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={744}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={745}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },
      {
        key: '24',
        label: 'Credit And Debit Notes',
        style: panelStyle,
        forceRender: true,
        children: <>
          <Card size="small" type="inner" title="Credit And Debit Notes"
            extra={<Checkbox
              name="cre_deb_notes"
              indeterminate={values.cre_deb_notes.length > 0 && values.cre_deb_notes.length < 8}
              checked={values.cre_deb_notes.length === 8}
              onChange={(e) => onChange(e, 'cre_deb_notes', [746, 747, 748, 749, 750, 751, 752, 753])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="cre_deb_notes" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, cre_deb_notes: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={746}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={747}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={748}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={749}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={750}>Business Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={751}>Audit Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={752}>Management Approval</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={753}>Files</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Credit And Debit Notes Invoices"
            extra={<Checkbox
              name="cre_deb_notes_invoices"
              indeterminate={values.cre_deb_notes_invoices.length > 0 && values.cre_deb_notes_invoices.length < 8}
              checked={values.cre_deb_notes_invoices.length === 8}
              onChange={(e) => onChange(e, 'cre_deb_notes_invoices', [754, 755, 756, 757, 758, 759, 760, 761])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="cre_deb_notes_invoices" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, cre_deb_notes_invoices: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={754}>Add</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={755}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={756}>Update</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={757}>Delete</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={758}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={759}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={760}>Release</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={761}>Files</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
          <Card size="small" type="inner" title="Credit And Debit Notes Payments"
            extra={<Checkbox
              name="cre_deb_notes_payments"
              indeterminate={values.cre_deb_notes_payments.length > 0 && values.cre_deb_notes_payments.length < 4}
              checked={values.cre_deb_notes_payments.length === 4}
              onChange={(e) => onChange(e, 'costing_details', [762, 763, 764, 765])}
            >
              <b>Select All</b>
            </Checkbox>}
          >
            <Form.Item name="cre_deb_notes_payments" style={{ marginBottom: 0 }}>
              <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, cre_deb_notes_payments: value }))}>

                <Row>
                  <Col span={6}>
                    <Checkbox value={762}>View</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={763}>Approve</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={764}>Reject</Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={765}>Release</Checkbox>
                  </Col>

                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </>

      },

    ];


  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}

    >
      <Collapse
        size="large"
        expandIconPosition='end'
        bordered={false}
        defaultActiveKey={['0']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={getItems(panelStyle)}
      />
    </Form>

  )
}