import { ContractIdRequest, ContractModesEnum, PurchaseContractService, SalesContractService, SalesTransferGetAllDto } from '@exportx/shared-models-and-services'
import { Button, Card, Table } from 'antd';
import React, { useEffect, useInsertionEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../common';

interface ISalesTransferProps {
    contractType: ContractModesEnum
}

export const TransferLogGrid = (props: ISalesTransferProps) => {
    const { contractType } = props;
    const [gridData, setGridData] = useState<any>();
    const [approvePermission, setApprovePermission] = useState(false)
    const navigate = useNavigate()
    const salesContractService = new SalesContractService();
    const purchaseContractService = new PurchaseContractService();
    const { authContext } = useAuthState();

    const { state }: any = useLocation()

    const { ContractId } = state ? state : { ContractId: null }

    useEffect(()=>{
        let permission;
        if(contractType === ContractModesEnum.SALES){
            permission = [108];
            setApprovePermission(isPermissionExist([109]))
        }
        if(contractType === ContractModesEnum.PURCHASE){
            permission = [123]
            setApprovePermission(isPermissionExist([124]))
        }
        if(!isPermissionExist(permission)) navigate('/')
    },[contractType])

    useEffect(() => {
        getAllTransferData();

    }, [ContractId])

    const getAllTransferData = () => {
        const req = new ContractIdRequest(ContractId, authContext.defaultPlant)
        if (contractType === ContractModesEnum.SALES) {
            salesContractService.getSalesTransferData(req).then(res => {
                if (res.status) {
                    setGridData(res.data);
                }
            }).catch(err => {
                console.log(err.message)
            })
        }
        if (contractType === ContractModesEnum.PURCHASE) {
            purchaseContractService.getPcTransferData(req).then(res => {
                if (res.status) {
                    setGridData(res.data);
                }
            }).catch(err => {
                console.log(err.message)
            })
        }

    }

    const salesTransferStatusApproval = (salesTransferLogId) => {
        const req = new ContractIdRequest(salesTransferLogId, authContext.defaultPlant)
        salesContractService.salesTransferStatusApproval(req).then(res => {
            if (contractType === ContractModesEnum.SALES) {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage)
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage)
                }
            }
        }).catch(err => {
            console.log(err.message)
        })

        if (contractType === ContractModesEnum.PURCHASE) {
            purchaseContractService.PcTransferStatusApproval(req).then(res => {
                if (res.status) {
                    AlertMessages.getSuccessMessage(res.internalMessage)
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage)
                }
            }).catch(err => {
                console.log(err.message)
            })
        }
    }

    const columns = [
        {
            title: 'Previous Contract No',
            dataIndex: 'previousContractNo',
        },
        {
            title: 'Previous Company',
            dataIndex: 'fromPlant',
        },
        {
            title: 'Transfer Company',
            dataIndex: 'toPlant',
        },
        {
            title: 'Contract No',
            dataIndex: 'contract No',
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (rowData, record) => {
                return <span>
                    {approvePermission && (
                    <Button type='primary' onClick={() => salesTransferStatusApproval(record?.salesTransferLogId)}>
                        Approved
                    </Button>)}
                </span>
            }

        },
    ]
    return (
        <>
            <Card title={contractType}>
                <Table className='contracts'
                    columns={columns}
                    dataSource={gridData}
                    pagination={false}
                    bordered
                />
            </Card>
        </>
    )
}

export default TransferLogGrid