import { Button, Card, Col,  Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Descriptions } from 'antd';
import { AlertMessages, TableForm, useAuthState } from '../../../common';
import { AccountsService, AccountsTransactionTypeEnum, AccountsVoucherTypesEnum, BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, PaymentVoucherMasterDto, PaymentVoucherService } from '@exportx/shared-models-and-services';
import { useIntl } from 'react-intl';
import moment from 'moment';
import DatePicker from '../../../common/data-picker/date-picker';


export const JournalF = () => {
    const { authContext } = useAuthState();
    const { Option } = Select;
    const pathToreDirect = '/journalVoucher';
    const { TextArea } = Input;
    const { formatMessage: fm } = useIntl();
    const navigate = useNavigate();
    const { state }: any = useLocation()
    const { groupEntryData } = state ? state : { groupEntryData: {} };
    const [formRef] = Form.useForm();
    const [nonLedgerAccounts, setNonLedgerAccounts] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDebit, setTotalDebit] = useState(0);
    const service = new PaymentVoucherService();
    const [businessDropDown, setBusinessDropDown] = useState<BusinessNoDropDownDto[]>([])



    if (groupEntryData?.tranDate)
        groupEntryData.tranDate = moment(groupEntryData.tranDate)
    else
        groupEntryData.tranDate = moment()

    useEffect(() => {
        const accountsService = new AccountsService();
        const businessService = new BusinessNumberService()
        const getNonLedgerAccounts = () => {
            accountsService.getAllNonPmtLedgerAccounts().then((res => {
                if (res.status) {
                    setNonLedgerAccounts(res.data)
                } else {
                    setNonLedgerAccounts([])
                }
            })).catch(err => {
                setNonLedgerAccounts([])
            })
        }
        const getBusinessNoDropDown = () => {
            const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
            businessService.getBusinessNoDropDown(req).then(res => {
                if (res.status) {
                    setBusinessDropDown(res.data)
                } else {
                    console.log(res.internalMessage)
                }
            }).catch(err => {
                console.log(err.message)
            })
        }
        getNonLedgerAccounts();
        getBusinessNoDropDown();
    }, [])





    const columns: any = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (value, row, index) => {
                return (
                    <Form.Item name={[index, "type"]}>
                        <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} placeholder='Select Account Type ' allowClear showSearch>
                            {Object.keys(AccountsTransactionTypeEnum).map((b) => {
                                return <Option value={AccountsTransactionTypeEnum[b]}>{AccountsTransactionTypeEnum[b] === AccountsTransactionTypeEnum.DEBIT ? 'BY' : 'TO'}</Option>
                            })}
                        </Select>
                    </Form.Item>
                );
            }
        },
        {
            title: 'Particulars',
            dataIndex: 'particulars',
            render: (value, row, index) => {
                return (
                    <Form.Item name={[index, "ledgerID"]}>
                        <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Search Particulars '>
                            {nonLedgerAccounts.map((b) => {
                                return <Option value={b.actLedId}>{b.actLedName}</Option>
                            })}
                        </Select>
                    </Form.Item>
                );
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: '120px',
            render: (value, row, index) => {
                return (
                    <>
                        <Form.Item name={[index, "amount"]}>
                            <Input
                                placeholder="amount"
                            />
                        </Form.Item>
                        <Form.Item name={"type"} style={{ display: 'none' }}>
                            <Input hidden />
                        </Form.Item>
                        <Form.Item name={"plantCode"} style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={"slNo"} style={{ display: 'none' }}>
                            <Input hidden />
                        </Form.Item>
                    </>
                );
            }
        }
    ]

    const submitFormHandler = () => {
        formRef.validateFields().then(values => {
            submitForm(values)
        })
    }
    const clearData = () => {
        formRef.resetFields();
    }

    const submitForm = (values: any) => {
        if (totalAmount !== totalDebit) {
            AlertMessages.getErrorMessage('debit and Credit Entry Should be Equal');
            return false;
        }
        const createDto: PaymentVoucherMasterDto = new PaymentVoucherMasterDto(values.voucherId, values.slNo, undefined, AccountsVoucherTypesEnum.JOURNAL_VOUCHER, values.tranDate, values.voucherDisplayID, values.remarks, `${totalAmount}`, authContext.defaultPlant, undefined, authContext.user.userName, authContext.user.userName, undefined, values.type, values.transaction, undefined, undefined, undefined, undefined, []);
        let voucherId = values.voucherId;
        let transaction = values.transaction;
        let voucherSlNo = values.slNo;
        const req: any = { ...createDto, actLedger: values.actLedger, type: values.type, voucherId, transaction, voucherSlNo };
        service.create(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(`journal Voucher Created SuccessFully`);
                setTimeout(() => {
                    navigate(pathToreDirect);
                }, 1000);
            }
            else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }

        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const handleTotal = (_, values: any) => {
        if (values?.pmtVoucherTransaction) {
            const transactions = [...values.pmtVoucherTransaction];
            let debitSum = 0;
            let creditSum = 0;
            transactions.forEach(element => {
                if (element?.type === AccountsTransactionTypeEnum.DEBIT) {
                    debitSum += element?.amount ? Number(element.amount) : 0;
                }
                if (element?.type === AccountsTransactionTypeEnum.CREDIT) {
                    creditSum += element?.amount ? Number(element.amount) : 0;
                }
            });
            formRef.setFieldValue('totalAmount', debitSum);
            setTotalAmount(creditSum);
            setTotalDebit(debitSum);
        }

    }

    return (
        <div>
            <Card title={<span> {"Journal Voucher Details"}</span>}
                extra={<Button type='primary' className='panel_button' onClick={() => navigate(pathToreDirect)}>View</Button>}>
                <Card>
                    <Form
                        layout='vertical'
                        form={formRef}
                        autoComplete='off'
                        onValuesChange={handleTotal}
                        initialValues={groupEntryData}
                    >
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1}>
                                <Form.Item name={"voucherId"} style={{ display: 'none' }}>
                                    <Input hidden />
                                </Form.Item>
                                <Form.Item name={"slNo"} style={{ display: 'none' }}>
                                    <Input hidden />
                                </Form.Item>

                                <Form.Item label="Business Number" rules={[{ required: false, }]}
                                    name="businessNo" >
                                    <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Business Number'>
                                        {businessDropDown.map(pmtMode => {
                                            return <Option value={pmtMode.bnId}>{pmtMode.businessNo}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 3 }} offset={1}>
                                <Form.Item label="Date" rules={[{ required: true, }]}
                                    name="tranDate" style={{ width: '100%' }}>
                                    <DatePicker />
                                    {/* <input  value={date.toLocaleDateString('en-CA')} onChange={onSetDate} /> */}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1}>
                                {/* <Descriptions>
                                    <Descriptions.Item>Voucher No:</Descriptions.Item>
                                </Descriptions> */}
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1} >
                                {/* <Form.Item label="Execuitive Name" rules={[{ required: false, }]}
                                    name="execuitive" >
                                    <Select  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Execuitive'>
                                        {pmtModes.map(pmtMode => {
                                            return <Option value={pmtMode.paymentMode}>{pmtMode.paymentMode}</Option>
                                        })}
                                    </Select>
                                </Form.Item> */}
                            </Col>
                        </Row>

                        <Form.List name="pmtVoucherTransaction">
                            {(particulars, { add, remove }) => {
                                return <TableForm dataSource={particulars} add={add} remove={remove} columns={columns} />;
                            }}
                        </Form.List>
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1} >
                                <Descriptions>
                                    <Descriptions.Item>Total Credit Amount: {totalAmount}</Descriptions.Item>
                                    <Form.Item name="totalAmount" style={{ display: 'none' }}>
                                        <Input hidden />
                                    </Form.Item>
                                </Descriptions>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 10 }} offset={1} >
                                <Descriptions>
                                    <Descriptions.Item>Total Debit Amount: {totalDebit}</Descriptions.Item>
                                    <Form.Item name="totalDebit" style={{ display: 'none' }}>
                                        <Input hidden />
                                    </Form.Item>
                                </Descriptions>
                            </Col>
                        </Row>

                        <br /><br />
                        <Row>
                            <Col xs={{ span: 24 }} lg={{ span: 22 }} offset={1}>
                                <Form.Item name='remarks' label='Narration'>
                                    <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ float: 'right' }}>
                            <Button type='primary' onClick={submitFormHandler} >Submit</Button>
                            {/* <Button danger>Cancel</Button> */}
                            <Button htmlType="button" style={{ float: 'right' }} onClick={() => {
                                clearData();
                            }}>Clear</Button>
                        </div>

                    </Form>
                </Card>
            </Card>
        </div >
    )
}

export default JournalF;