import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { BusinessNumberService, PlantCodeReqDto, SalesContractService, PurchaseDropDownDto, TaxTypeReqDto, TaxesService, TaxTypes, TaxDropDownDto, MarkupPriceService, MarkupPriceIdReqDto, BNoRequest } from '@exportx/shared-models-and-services';
import { useLocation, useNavigate } from 'react-router-dom';
// import './budget-and-costing-form.css';
import { AlertMessages, useAuthState } from '../../../../common';
import CoalCostingGridForm from './coal-costing-grid-form';
import BargeCostingGridForm from './barge-costing-grid-form';
import CoalServiceGridForm from './coal-service-grid-form';
import { DoChargesGridForm } from './doc-charges-grid-form';

const { Option } = Select;

export const MarkupPriceForm = () => {
  const [form] = Form.useForm();
  const [formRef] = Form.useForm();
  const [businessNum, setBusinessNum] = useState<any[]>([]);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [initialValues, setInitialValues] = useState<any>({
    coalCostingData: [''],
    coalServiceData: [''],
    bargeCostData: [''],
    docData: ['']
  })

  const [businessNo, setBusinessNo] = useState<String>();
  const [saleContract, setSalesContract] = useState<String>();
  const [purchaseContracts, setPurchaseContracts] = useState<PurchaseDropDownDto[]>([]);
  const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([]);

  const { authContext } = useAuthState()
  const navigate = useNavigate();
  const { state }: any = useLocation()
  const { id } = state ? state : { id: null }

  const businessNumberService = new BusinessNumberService();
  const mupService = new MarkupPriceService();
  const salesContractService = new SalesContractService();
  const taxesService = new TaxesService();


  useEffect(() => {
    if (id) getMarkupPriceInfo();
  }, [id])

  const getMarkupPriceInfo = () => {
    const req = new MarkupPriceIdReqDto(id, authContext.defaultPlant)
    mupService.getMarkupPriceById(req).then(res => {
      if (res.status) {
        let objCopy = {
          ...res.data,
          coalCostingData: !res.data?.coalCostingData?.length ? [''] : res.data?.coalCostingData,
          coalServiceData: !res.data?.coalServiceData?.length ? [''] : res.data?.coalServiceData,
          bargeCostData: !res.data?.bargeCostData?.length ? [''] : res.data?.bargeCostData,
          docData: !res.data?.docData?.length ? [''] : res.data?.docData
        }
        setInitialValues(objCopy)
        setBusinessNo(res?.data?.businessNoId)
        setSalesContract(res?.data?.salesContractId)
        setExchangeRate(res.data?.exchangeRate ?? 1)
        formRef.setFieldsValue({ exchangeRate: res?.data?.exchangeRate })
        formRef.setFieldsValue({ businessNoId: res?.data?.businessNoId })
        formRef.setFieldsValue({ saleContractId: res?.data?.salesContractId })
        form.setFieldsValue(objCopy)
        getAllSaleContractsForDropDown();
        getBusinessNoCooInfo();
      } else {
        // AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  useEffect(() => {
    getBusinessNoDropData();
    getAllActiveVatDropDown();
  }, [])

  useEffect(() => {
    getTotal();
  }, [initialValues])

  const getAllActiveVatDropDown = () => {
    const req = new TaxTypeReqDto();
    req.taxType = TaxTypes.VAT;
    taxesService.getTaxesByCategory(req).then(res => {
      if (res.status) {
        setTaxDropDown(res.data)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const budgetAndCoasting = () => {
    formRef.validateFields().then(values => {
      form.validateFields().then(child => {
        mupService.createMarkupPrice({ ...values, ...child, plantCode: authContext.defaultPlant, userName: authContext.user.userName, markupPriceId: initialValues?.markupPriceId }).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setTimeout(() => {
              navigate('/markup-price')
            }, 1000)
          } else {
            AlertMessages.getErrorMessage(res.internalMessage)
          }
        }).catch(err => {
          console.log(err.message)
        })

      }).catch(err => {
        console.log(err.message)
      })


    })
  }

  const getBusinessNoDropData = () => {
    businessNumberService.getAllBusinessNumber(new PlantCodeReqDto(authContext.defaultPlant)).then(res => {
      if (res.status) {
        setBusinessNum(res.data)
      }
    }).catch(err => {
      console.log('error:' + err.message);
    })
  }

  const onChangeBusinessNo = (value: string) => {
    setBusinessNo(value);
    formRef.setFieldsValue({ businessNoId: value })
    formRef.setFieldsValue({ saleContractId: undefined })
    getAllSaleContractsForDropDown();
    getBusinessNoCooInfo()

  }


  const getBusinessNoCooInfo = () => {

    businessNumberService.getCooInfoByBusinessNo(new BNoRequest(formRef.getFieldValue('businessNoId'))).then((res) => {
      if (res.status) {
        if (res.data?.length) {
          const { qty, price } = res.data[0] || {}
          form.setFieldValue('cooQty', qty)
          form.setFieldValue('cooPriceIdr', price)
          setInitialValues((prev) => ({ ...prev, cooQty: qty, cooPriceIdr: price }))
        }
      }
      else {

      }
    })
  }

  const getAllSaleContractsForDropDown = () => {
    salesContractService.getAllSaleContractsForDropDown({
      plantCode: authContext.defaultPlant,
      businessNoId: formRef.getFieldValue('businessNoId'),
    }).then(res => {
      if (res.status) {
        setPurchaseContracts(res.data);
      } else {
        setPurchaseContracts([]);
      }
    }).catch(err => {
      setPurchaseContracts([]);
      console.log('error: ' + err.message);
    })
  };


  const getTotal = () => {

    const totalForm = [...initialValues?.coalCostingData, ...initialValues?.coalServiceData, ...initialValues?.bargeCostData, ...initialValues?.docData]

    const cooQty = form.getFieldValue('cooQty') ?? 0;
    const cooPriceIdr = form.getFieldValue('cooPriceIdr') ?? 0;
    const cooTotal = (cooQty * cooPriceIdr)?.toFixed(2);

    const cooPriceUsd = (+cooTotal / +cooQty / +exchangeRate)?.toFixed(2);

    let totalQty = initialValues?.coalCostingData?.reduce((acc: number, curr: any) => { return acc + Number(curr.quantity ?? 0) }, 0)
    // totalQty += Number(cooQty);
    let total = totalForm?.reduce((acc: number, curr: any) => { return acc + Number(curr.total ?? 0) }, 0)
    total += Number(cooTotal);
    const totalPriceUsd = (+total / +totalQty / +exchangeRate)?.toFixed(2);
    const kawalTotal = form.getFieldValue('kawalTotal') || totalQty;
    const kawalPriceUsd = (Number(kawalTotal) / Number(totalQty) / Number(exchangeRate ?? 1))?.toFixed(2);

    const finalPrice = Number((Number(form.getFieldValue('markupPriceUsd') ?? 0)) + (Number(kawalPriceUsd) ?? 0) + (+totalPriceUsd))?.toFixed(2);
    form.setFieldValue('cooPriceUsd', cooPriceUsd)
    form.setFieldValue('totalQty', totalQty)
    form.setFieldValue('total', total)
    form.setFieldValue('totalPriceUsd', totalPriceUsd)
    form.setFieldValue('cooTotal', cooTotal)
    form.setFieldValue('kawalQty', totalQty)
    form.setFieldValue('kawalTotal', total)
    form.setFieldValue('kawalPriceUsd', kawalPriceUsd)
    form.setFieldValue('finalPrice', finalPrice)
  }


  const onChangeMarkup = (e: any) => {
    const markup = Number(e.target.value ?? 0);
    const priceUsd = form.getFieldValue('totalPriceUsd') ?? 0;
    const markupPriceUsd = (priceUsd * (markup / 100))?.toFixed(2);
    form.setFieldValue('markupPriceUsd', markupPriceUsd);
    const finalPrice = (Number(priceUsd) + Number(markupPriceUsd))?.toFixed(2);
    form.setFieldValue('kawalPriceUsd', finalPrice)
    form.setFieldValue('finalPrice', finalPrice);
  }

  const onChangeKawal = (value: string) => {
    const quantity = form.getFieldValue('kawalQty') ?? 0;
    const total = value ?? 0;
    const priceUsd = (+total / +quantity / +exchangeRate)?.toFixed(2);
    const finalPrice = ((Number(form.getFieldValue('markupPriceUsd') ?? 0)) + (Number(form.getFieldValue('totalPriceUsd') ?? 0)) + (+priceUsd))?.toFixed(2);
    form.setFieldValue('kawalPriceUsd', priceUsd);
    form.setFieldValue('finalPrice', finalPrice);

  }

  const onVatChangeCoo = (value) => {
    form.setFieldValue('cooPpnId', value)
    const total = form.getFieldValue('cooTotal') ?? 0;
    const vat = taxDropDown.find(el => el.taxId === value)?.taxPercentage ?? 0;
    if (vat) {
      const includeVat = (+total * Number(vat ?? 1) / 100) + Number(total);
      form.setFieldValue('cooPriceAftExchg', includeVat);

    } else form.setFieldValue('cooPriceAftExchg', total);
  }

  const onVatChangeTotal = (value) => {
    form.setFieldValue('totalPpnId', value)
    const total = form.getFieldValue('total') ?? 0;
    const vat = taxDropDown.find(el => el.taxId === value)?.taxPercentage ?? 0;
    if (vat) {
      const includeVat = (+total * Number(vat ?? 1) / 100) + Number(total);
      form.setFieldValue('totalPriceAftExchg', includeVat);

    } else form.setFieldValue('totalPriceAftExchg', total);
  }

  const onVatChangeKawal = (value) => {
    form.setFieldValue('kawalPpnId', value)
    const total = form.getFieldValue('kawalTotal') ?? 0;
    const vat = taxDropDown.find(el => el.taxId === value)?.taxPercentage ?? 0;
    if (vat) {
      const includeVat = (+total * Number(vat ?? 1) / 100) + Number(total);
      form.setFieldValue('kawalPriceAftExchg', includeVat);

    } else form.setFieldValue('kawalPriceAftExchg', total);
  }


  return (
    <>
      <Card title={<span style={{ color: 'black' }}> New Mark Up Price</span>}
        style={{ textAlign: 'left' }} extra={<span style={{ color: 'white' }} ><Button type="primary" onClick={() => navigate("/markup-price")}>View</Button> </span>}>

        <Card size='small' style={{ paddingBlockEnd: '10px' }}>

          <Form layout='vertical'
            form={formRef}
            initialValues={{ ...initialValues }}
          >
            <Row gutter={16}>

              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
                <Form.Item label="Business No" rules={[{ required: true, }]}
                  name={"businessNoId"} style={{ width: '80%' }}>
                  <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear placeholder='Select Business No'
                    onChange={onChangeBusinessNo}
                  >
                    {businessNum.map(b => {
                      return <Option value={b.bnId} key={b.bnId}>{b.motherVesselName + `(${b.businessNo})`}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
                <Form.Item label="Sales Contract" rules={[{ required: true, }]}
                  name={"saleContractId"} style={{ width: '100%' }}>
                  <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear placeholder='Select Sales Contract No'
                    onChange={(value) => { form.setFieldValue('saleContractId', value); setSalesContract(value) }}>
                    {purchaseContracts.map(b => {
                      return <Option value={b.pcId}>{b.contractAndSupplier}</Option>
                    })}
                  </Select>
                </Form.Item>

              </Col>
              <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
                <Form.Item label="Exchange Rate" rules={[{ required: true }]}
                  name="exchangeRate"><Input placeholder="Exchange Rate*" type='number' onChange={(e) => {
                    setExchangeRate(Number(e.target.value))
                  }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Form
            initialValues={initialValues}
            form={form}
          >
            <div className="section_block w-100" >
              <div className="table-responsive">
                <table className='table table-sm table-bordered'>
                  <thead className='table-header'>
                    <tr className="bg-primary text-white">
                      <th className='text-center' style={{ minWidth: 200 }} >Barge</th>
                      <th className='text-center' style={{ minWidth: 150 }} >Quantity</th>
                      <th className='text-center' style={{ minWidth: 150 }} >IDR PMT </th>
                      <th className='text-center' style={{ minWidth: 200 }} >Total</th>
                      <th className='text-center' style={{ minWidth: 150 }} >VAT</th>
                      <th className='text-center' style={{ minWidth: 150 }} >Total After VAT</th>
                      <th style={{ minWidth: 150 }} className='text-right' >USD PMT </th>
                      <th style={{ minWidth: 100 }} className='text-center'>Action</th>
                    </tr>

                  </thead>
                  <tbody>
                    <tr>
                      <th colSpan={8}>Coal Costing</th>
                    </tr>
                    <Form.List name="coalCostingData">
                      {(coalCostingData, { add, remove }) => {
                        return (
                          <CoalCostingGridForm
                            coalCostingData={coalCostingData}
                            form={form}
                            add={add}
                            remove={remove}
                            businessNo={businessNo}
                            saleContract={saleContract}
                            exchangeRate={exchangeRate}
                            setInitialValues={setInitialValues}
                          />
                        )
                      }}
                    </Form.List>
                    <tr>
                      <th colSpan={8}>Coal Service</th>
                    </tr>
                    <Form.List name="coalServiceData">
                      {(coalServiceData, { add, remove }) => {
                        const data = form.getFieldValue('coalServiceData');
                        return (
                          <CoalServiceGridForm
                            coalServiceData={coalServiceData}
                            form={form}
                            add={add}
                            remove={remove}
                            businessNo={businessNo}
                            saleContract={saleContract}
                            exchangeRate={exchangeRate}
                            setInitialValues={setInitialValues}
                          />
                        )
                      }}
                    </Form.List>

                    <tr>
                      <th colSpan={8}>Barge Costing</th>
                    </tr>
                    <Form.List name="bargeCostData">
                      {(bargeCostData, { add, remove }) => (
                        <BargeCostingGridForm
                          bargeCostData={bargeCostData}
                          form={form}
                          add={add}
                          remove={remove}
                          businessNo={businessNo}
                          saleContract={saleContract}
                          exchangeRate={exchangeRate}
                          setInitialValues={setInitialValues}
                        />
                      )}
                    </Form.List>

                    <tr>
                      <th colSpan={8}>Documentation Charges</th>
                    </tr>
                    <Form.List name="docData">
                      {(docData, { add, remove }) => (
                        <DoChargesGridForm
                          docData={docData}
                          form={form}
                          add={add}
                          remove={remove}
                          businessNo={businessNo}
                          saleContract={saleContract}
                          exchangeRate={exchangeRate}
                          setInitialValues={setInitialValues}

                        />

                      )}
                    </Form.List>

                    <tr>
                      <td className='table-form'><b>Coo </b> </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'cooQty'}>
                          <Input type='number' placeholder='Enter Quantity' disabled />
                        </Form.Item>
                      </td>

                      <td className='table-form'>
                        <Form.Item
                          name={'cooPriceIdr'}>
                          <Input type='number' placeholder='Enter Price' disabled />
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'cooTotal'}>
                          <Input type='number' placeholder='Enter total' disabled />
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={"cooPpnId"}
                        >
                          <Select
                            showSearch
                            placeholder='Please  Select'
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => onVatChangeCoo(e)}
                          >
                            <Option key={0} value={null}>Select Vat</Option>
                            {taxDropDown.map((rec) => {
                              return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} (${rec.taxPercentage})`}</Option>
                            })}
                          </Select>
                        </Form.Item>
                      </td>

                      <td className='table-form'>
                        <Form.Item
                          name={'cooPriceAftExchg'}>
                          <Input type='number' placeholder='Enter price' disabled />
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'cooPriceUsd'}>
                          <Input type='number' placeholder='Enter price' disabled />
                        </Form.Item>
                      </td>
                      <td></td>

                    </tr>

                    <tr>
                      <td className='table-form'> <b>
                        Total
                      </b>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'totalQty'}>
                          <Input type='number' placeholder='Enter Quantity' disabled />
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'totalPriceIdr'}>
                          <Input type='number' placeholder='Enter Price' disabled />
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'total'}>
                          <Input type='number' placeholder='Enter Price' disabled />
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={"totalPpnId"}
                        >
                          <Select
                            showSearch
                            placeholder='Please  Select'
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => onVatChangeTotal(e)}
                          >
                            <Option key={0} value={null}>Select Vat</Option>
                            {taxDropDown.map((rec) => {
                              return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} (${rec.taxPercentage})`}</Option>
                            })}
                          </Select>
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'totalPriceAftExchg'}>
                          <Input type='number' disabled placeholder='Total' />
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'totalPriceUsd'}>
                          <Input type='number' disabled />
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td
                        className='table-form'
                      >
                        <Form.Item label="Markup" rules={[{ required: true }]}
                          name="markupPercent"><Input placeholder="Markup" type='number' onChange={onChangeMarkup} />
                        </Form.Item>
                      </td>
                      <td colSpan={5}></td>
                      <td
                        className='table-form'
                      >
                        <Form.Item
                          name={'markupPriceUsd'}>
                          <Input type='number' disabled />
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                    {/* <tr>
                      <td
                        colSpan={5}
                        className='table-form'
                      >
                        <b>Price</b>

                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'priceAftExchange'}>
                          <Input type='number' disabled />
                        </Form.Item>

                      </td>
                      <td></td>
                    </tr> */}

                    <tr>
                      <td className='table-form'>
                        <b>With Kawal</b>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'kawalQty'}>
                          <Input type='number' disabled />
                        </Form.Item>

                      </td>
                      <td></td>
                      <td className='table-form'>
                        <Form.Item
                          name={'kawalTotal'}>
                          <Input type='number' onChange={(e) => onChangeKawal(e.target.value)} />
                        </Form.Item>

                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={"kawalPpnId"}
                        >
                          <Select
                            showSearch
                            placeholder='Please  Select'
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => onVatChangeKawal(e)}
                          >
                            <Option key={0} value={null}>Select Vat</Option>
                            {taxDropDown.map((rec) => {
                              return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} (${rec.taxPercentage})`}</Option>
                            })}
                          </Select>
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'kawalPriceAftExchg'}>
                          <Input type='number' disabled />
                        </Form.Item>
                      </td>
                      <td className='table-form'>
                        <Form.Item
                          name={'kawalPriceUsd'}>
                          <Input type='number' disabled />
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td colSpan={6}><b>Final Price</b></td>
                      <td>
                        <Form.Item
                          name={'finalPrice'}>
                          <Input type='number' disabled />
                        </Form.Item>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Form>
        </Card>
        <div style={{ paddingTop: '10px' }}>
          <Button type="primary" style={{ float: 'right' }} onClick={() => { budgetAndCoasting() }}> Submit</Button>
        </div>
      </Card>
    </>
  );
}

export default MarkupPriceForm;