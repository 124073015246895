import { Form, FormInstance, Select, Tooltip } from 'antd';
import { PlusCircleOutlined, DeleteFilled } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import { useIntl } from 'react-intl';
import { ResponsePersonEnum } from '@exportx/shared-models-and-services';
import React from 'react';


interface ICommercialDocumentsProps {
  formRef: FormInstance<any>
  children?: React.ReactNode;
  initialValues: any;
}
const { Option } = Select;
export const CommercialDocuments = (props: ICommercialDocumentsProps) => {
  const { formRef, initialValues } = props;
  const { formatMessage: fm } = useIntl();

  return (
    <Form form={formRef} initialValues={initialValues} autoComplete='off'>
      <Row>
        <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
          <Form.Item label="COO" name="coo"
            rules={[{ required: true, message: 'Please COO' }]}
          >
            <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: "100%" }} >
              <Option value={''}>Please Select</Option>
              {React.Children.toArray(Object.keys(ResponsePersonEnum).map(vessel => {
                return <Option value={ResponsePersonEnum[vessel]}>{ResponsePersonEnum[vessel]}</Option>
              }))}
            </Select>
          </Form.Item>
        </Col >
        <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
          <Form.Item label="Bl" name="bl"
            rules={[{ required: true, message: 'Please BL' }]}>
            <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: "100%" }} >
              <Option value={''}>Please Select</Option>
              {React.Children.toArray(Object.keys(ResponsePersonEnum).map(vessel => {
                return <Option value={ResponsePersonEnum[vessel]}>{ResponsePersonEnum[vessel]}</Option>
              }))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.List name="commercialDocuments" >
        {(fields, { add, remove }, { errors }) => (
          <>
            {
              fields.map((field, index) => (
                <Row>
                  <Col
                    xs={{ span: 22 }}
                    sm={{ span: 22 }}
                    md={{ span: 22 }}
                    lg={{ span: 22 }}
                    xl={{ span: 22 }}>
                    <Form.Item
                      required={false}
                      key={field.key}
                      name={[field.name, 'documents']}
                    >
                      <Input placeholder="" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>

                  <Col xs={{ span: 2 }}
                    sm={{ span: 2 }}
                    md={{ span: 2 }}
                    lg={{ span: 2 }}
                    xl={{ span: 2 }}>
                    {index !== 0 &&
                      <span style={{
                        position: "absolute",
                        marginLeft: "35px",
                      }}>
                        <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                          <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                            remove(field.name);
                          }} />
                        </Tooltip>
                      </span>
                    }
                    {(fields.length - 1 === index) && <span style={{
                      position: "absolute",
                      marginLeft: "15px"
                    }}>
                      <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                        <PlusCircleOutlined onClick={() => add()} />
                      </Tooltip>
                    </span>}
                  </Col>
                </Row>
              ))}
          </>
        )}
      </Form.List>
      {props?.children}

    </Form >
  )
}

export default CommercialDocuments;