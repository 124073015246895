import { Card } from "antd";
import { HorizontalBarChart } from "../../common/charts/horizontal-bar";

export const TopFiveVendorsByPurchasesChart = () => {
    const data = [
        {
            year: 'EXIM MINERALS DMCC',
            value: 653,
        },
        {
            year: 'PT. ICON COMMODITIES INDONESIA',
            value: 212,
        },
        {
            year: 'PT BINA INSAN SUKSES MANDIRI',
            value: 512,
        },
        {
            year: 'PT. BELAYAN INTERNATIONAL COAL  (HAJI IDA)',
            value: 671,
        },
        {
            year: 'PT. SINAR KUMALA NAGA (PBD) ARJ',
            value: 428,
        },
    ];
    return <>
        <Card title='Top Five Vendors by Purchases'>
            <HorizontalBarChart chartData={data} />
        </Card>
    </>
}