import { CompanyService, GetCompanyNames, PurchaseContractService, SalesContractService, SaleTransferReqDto } from '@exportx/shared-models-and-services'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import React from 'react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AlertMessages, useAuthState } from '../../../common'

interface ISalesTranseferProps {
    formRef: InstanceType<any>
    plantCode: any
    contractType: string;
}

const ContractTransfer = (props: ISalesTranseferProps) => {
    const [companyName, setCompanyName] = useState<GetCompanyNames[]>()
    const { plantCode, contractType } = props
    const { Option } = Select;
    const [formRef] = Form.useForm();
    const companyService = new CompanyService();
    const { authContext } = useAuthState();
    const salesContractService = new SalesContractService();
    const purchaseContractService = new PurchaseContractService();
    const [toPlant, setToPlant] = useState(undefined);
    const Navigate = useNavigate()
    const { state }: any = useLocation()


    let PathToRedirect = '/sales-transfer-log';

    if (contractType === "Sale Contract") {
        PathToRedirect = '/sales-transfer-log';
    }
    if (contractType === "Purchase Contract") {
        PathToRedirect = '/purchase-transfer-log'
    }

    useEffect(() => {
        getAllActiveCompanyNames();
    }, [plantCode])

    if (plantCode.plantCode) {
        formRef.setFieldValue('fromPlant', plantCode.plantCode)
    }

    const getAllActiveCompanyNames = () => {
        companyService.getAllActiveCompanyNames().then(res => {
            if (res.status) {
                setCompanyName(res.data)
            }
        })
    }

    const contractTransferRequest = () => {
        formRef.validateFields().then(values => {
            const req = new SaleTransferReqDto(values.fromPlant, values.toPlant, plantCode.previousContractId, authContext.user.userName);
            if (values.toPlant === 'Others') {
                req['presentContractNo'] = values.presentContractNo;
            }
            if (contractType === 'Sale Contract') {
                salesContractService.createSaleTransferLog(req).then(res => {
                    if (res.status) {
                        AlertMessages.getSuccessMessage(`${res.internalMessage}`);
                        setTimeout(() => {
                            Navigate(PathToRedirect, { state: { ContractId: plantCode.previousContractId } })
                        }, 1000)
                    }
                }).catch(err => {
                    AlertMessages.getErrorMessage(err.message)
                })
            } if (contractType === "Purchase Contract") {
                purchaseContractService.createPcTransferLog(req).then(res => {
                    if (res.status) {
                        AlertMessages.getSuccessMessage(`${res.internalMessage}`);
                        setTimeout(() => {
                            Navigate(PathToRedirect, { state: { ContractId: plantCode.previousContractId } })
                        }, 1000)
                    }
                }).catch(err => {
                    AlertMessages.getErrorMessage(err.message)
                })
            }

        })
    }


    return (
        <>

            <Form layout='vertical' form={formRef}>
                <Row >
                    <Col offset={1} span={6}>
                        <FormItem name={"fromPlant"} label={"From Company"} rules={[{ required: true }]}>
                            <Input placeholder='From Company' disabled={true}></Input>
                        </FormItem>
                    </Col>

                    <Col offset={2} span={6}>
                        <FormItem name="toPlant" label={"To Company"} rules={[{ required: true, message: "To Company Should Not Be Empty" }]}>
                            <Select
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                allowClear showSearch placeholder="Select Transfer Company" onChange={(toPlant) => { setToPlant(toPlant) }}>
                                <Option value={''}> Please Select</Option>
                                {companyName?.filter((filter) => { return filter?.companyCode !== plantCode.plantCode })?.map((rec => { return <Option value={rec?.companyCode}>{rec?.companyCode}  {`(${rec?.companyName})`}</Option> }))}
                                <Option value={'Others'}>Others</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col offset={2} span={6}>{toPlant === 'Others' && <><FormItem name={"presentContractNo"} label={"New Contract No"} rules={[{ required: true }]}>
                        <Input placeholder='Contract No' />
                    </FormItem></>}</Col>
                </Row>
                <Row>
                    <Col span={8}></Col>
                    <Col span={8}></Col>
                    <Col span={8} > <Button type='primary' onClick={contractTransferRequest}>Transfer</Button></Col>

                </Row>
            </Form>

        </>
    )
}

export default ContractTransfer