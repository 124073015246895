import React, { useEffect, useState } from "react";
import { ApprovalTracking } from ".";
import { getLocalFormat, SequenceUtils } from "@exportx/ui-utils";
import moment from "moment";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { ExpenseEntryAllDto, ExpenseEntryIdReq, ExpenseEntryService } from "@exportx/shared-models-and-services";
import { AlertMessages, useAuthState } from "../../../common";

const { Text } = Typography;

export const ExpenseEntryCostingDetails = ({ expenseEntryId, remainingAmount }) => {
  const { authContext } = useAuthState()
  const expenseEntryService = new ExpenseEntryService()
  const [initialValues, setInitialValues] = useState<any>();
  const [advTotal, setAdvTotal] = useState<number>(0)

  useEffect(() => {
    if (expenseEntryId) {
      updateExpensive(expenseEntryId);
    };
  }, [expenseEntryId]);

  const updateExpensive = (value) => {
    const req = new ExpenseEntryIdReq()
    req.expenseEntryId = value;
    req.plantCode = authContext.defaultPlant;
    expenseEntryService.updateExpensive(req).then((res) => {
      if (res.status) {
        setInitialValues({
          ...res.data,
          jisdorDate: res.data?.
            expenseEntryTransactions[0]?.jisdorDate,
          currency: res.data?.
            expenseEntryTransactions[0]?.currency,
          quoteCurrency: authContext.defaultPlantCurrency
        });
        const advTotal = Number(res.data.advanceTarnsactionsData.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2))
        setAdvTotal(advTotal)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch((err) => {
      console.log(err.message)
    })
  };

  const tableColumns: ColumnsType<any> = [

    {
      title: 'Barge No',
      dataIndex: 'bargesId',
      render: (value, record) => {
        if (record.bgId) {
            const link = `/#/barge-detail-view?barge_id=${record.bgId}`;
            return <a href={link} className="link-primary" target="_blank">
                {value}
            </a>

        }
        return <span>{value}</span>

    }

    },
    // {
    //   title: 'Barge Name',
    //   dataIndex: 'bargeId'

    // },
    {
      title: 'Business No',
      dataIndex: 'businessNumber'

    },
    {
      title: 'Vendor',
      dataIndex: 'actLedName'

    },
    {
      title: 'Quantity',
      dataIndex: 'qty'

    },
    {
      title: 'Payable (lumpsum or PMT)',
      dataIndex: 'payable'

    },
    {
      title: 'Price',
      dataIndex: 'expensivePrice',
      render: (text, row) => {
        return getLocalFormat(text, row?.currency)
      }

    }

  ]
  return (
    <>
      <Table
        size='small'
        rowKey={(rowData) => rowData.expenseEntryId}
        columns={tableColumns}
        dataSource={initialValues?.expenseEntryTransactions}
        bordered={true}
        pagination={false}
        style={{ width: '100%' }}
      />
      <table className="table table-bordered table-sm mt-4">
        <tr>
          <th colSpan={2}>Exchange Date</th>
          <td>{initialValues?.jisdorDate ? moment(initialValues?.jisdorDate)?.format('DD-MM-YYYY') : 'N/A'}</td>
        </tr>
        <tr>
          <th colSpan={2}>Exchange Rate</th>
          <td>
            <Text>{getLocalFormat(initialValues?.exchangeRateValue, initialValues?.quoteCurrency)}</Text>
          </td>
        </tr>
        <tr>
          <th colSpan={2}>Total</th>
          <td>{getLocalFormat(initialValues?.total, initialValues?.quoteCurrency)}</td>
        </tr>
        <tr>
          <th>VAT</th>
          <td>
            <Text>{`${initialValues?.vatApplicable}`}</Text>
          </td>
          <td>{getLocalFormat(initialValues?.vat, initialValues?.quoteCurrency)}</td>
        </tr>
        <tr>
          <th>TDS:</th>
          <td><Text>{`${initialValues?.tdsName} - ${initialValues?.tdsPercent}`}</Text></td>
          <td>{getLocalFormat(initialValues?.tds, initialValues?.quoteCurrency)}</td>
        </tr>
        <tr>
          <th colSpan={3} >
            Advance Adjustments
          </th>
          <td></td>

        </tr>
        {initialValues?.advanceTarnsactionsData?.map((adv) => (
          <tr key={adv.id}>
            <th colSpan={2} >
              Adv deduction against contract : {adv.adjustedCostingNo}
            </th>
            <td>{getLocalFormat(adv.totalAdjustedAmount, initialValues?.quoteCurrency)}</td>

          </tr>
        ))}
        <tr>
          <th colSpan={2} >
            Total Advance Adjustments
          </th>
          <td> {getLocalFormat(advTotal, initialValues?.quoteCurrency)}</td>
        </tr>
        <tr>
          <th colSpan={2} >
            To be paid to Supplier
          </th>
          <td>
            <span style={{ color: 'green', fontWeight: 'bold' }}>{getLocalFormat(initialValues?.totalAmount - advTotal, initialValues?.quoteCurrency)}</span>
          </td>
        </tr>
        {remainingAmount > 0 &&
          <tr>
            <th colSpan={2} >
              Remaining Amount
            </th>
            <td>
              <span style={{ color: 'red', fontWeight: 'bold' }}>{getLocalFormat(remainingAmount, initialValues?.quoteCurrency)}</span>
            </td>
          </tr>
        }
      </table>

      {/* {!remainingAmount && */}

      <ApprovalTracking history={initialValues?.workLog} />
      {/* } */}
    </>
  )
}
