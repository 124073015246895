export const FileTypeEnum = Object.freeze({
    PDF: "application/pdf",
    IMAGE: 'image/jpeg',
    ANY: 'ANY',
    BOTH: 'BOTH'
});

export const FilesRequirementEnum = Object.freeze({
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE',
    ANY: 'ANY'
})

export const filePermissions = {
    salesContracts: {
        contract: {
            noOfFiles: FilesRequirementEnum.SINGLE,
            fileType: FileTypeEnum.PDF
        },
        addendum: {
            noOfFiles: FilesRequirementEnum.ANY,
            fileType: FileTypeEnum.IMAGE
        },
        priceCalculation: {
            noOfFiles: FilesRequirementEnum.MULTIPLE,
            fileType: FileTypeEnum.IMAGE
        },
        others: {
            noOfFiles: FilesRequirementEnum.MULTIPLE,
            fileType: FileTypeEnum.ANY
        }
    },
    purchaseContracts: {
        contract: {
            noOfFiles: FilesRequirementEnum.SINGLE,
            fileType: FileTypeEnum.PDF
        },
        addendum: {
            noOfFiles: FilesRequirementEnum.ANY,
            fileType: FileTypeEnum.IMAGE
        },
        priceCalculation: {
            noOfFiles: FilesRequirementEnum.MULTIPLE,
            fileType: FileTypeEnum.IMAGE
        },
        others: {
            noOfFiles: FilesRequirementEnum.MULTIPLE,
            fileType: FileTypeEnum.ANY
        }
    },
    serviceOrProcurementContracts: {
        contract: {
            noOfFiles: FilesRequirementEnum.SINGLE,
            fileType: FileTypeEnum.PDF
        },
        addendum: {
            noOfFiles: FilesRequirementEnum.ANY,
            fileType: FileTypeEnum.IMAGE
        },
        others: {
            noOfFiles: FilesRequirementEnum.MULTIPLE,
            fileType: FileTypeEnum.ANY
        }
    },
    bargingAgreements: {
        spal: {
            noOfFiles: FilesRequirementEnum.MULTIPLE,
            fileType: FileTypeEnum.BOTH
        },
        others: {
            noOfFiles: FilesRequirementEnum.MULTIPLE,
            fileType: FileTypeEnum.ANY
        }
    },
    advanceContracts: {
        contracts: {
            noOfFiles: FilesRequirementEnum.SINGLE,
            fileType: FileTypeEnum.PDF
        },
        others: {
            noOfFiles: FilesRequirementEnum.MULTIPLE,
            fileType: FileTypeEnum.ANY
        }
    },
    businessPartner: {
        kycForm: {
            noOfFiles: FilesRequirementEnum.ANY,
            fileType: FileTypeEnum.PDF
        },
        others: {
            noOfFiles: FilesRequirementEnum.MULTIPLE,
            fileType: FileTypeEnum.ANY
        }
    }
}
