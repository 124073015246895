import React, { useEffect, useState } from 'react';
import { Card, Col, Descriptions, Row, Table, Image } from 'antd';
import moment from 'moment';


interface IPreviewProps {
    labels: any;
    data: any;
    primaryKeyData: any;
    children?: React.ReactNode;
}

export const PreviewComponent = (props: IPreviewProps) => {
    const [filePreviewData, setFilePreviewData] = useState<any[]>([]);
    const { labels, data, primaryKeyData } = props;

    useEffect(() => {
        const isFileDataAvailable = labels.find(label => label.filePreview);
        if (isFileDataAvailable) {
            getFilePreviewData(labels.find(label => label.filePreview))
        }
    }, [data])


    const getFilePreviewData = async (labelData) => {
        await labelData.keysAndLabelMapping.map(async (label: any) => {
            const filePreviewDataToSet = [];
            const dataToBeIterated = data[label.key] ? [...data[label.key]] : [];
            for (const imageData of dataToBeIterated) {
                filePreviewDataToSet.push({
                    ...imageData
                });
            };
            setFilePreviewData(filePreviewDataToSet);
        });
    }
    const checkIfValidUUID = (str: string) => {
        // Regular expression to check if string is a valid UUID
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

        return regexExp.test(str);
    }

    const getInnerTable = (columns, dataSource) => {
        return <Table columns={columns} dataSource={dataSource} pagination={false} bordered={true} />
    }

    const getDescriptionData = (text) => {
        if (Array.isArray(text)) {
            let dataToBePrint = '';
            text.forEach(item => {
                if (moment.isMoment(item)) {
                    dataToBePrint += moment(item).format('DD-MMM-YYYY HH:mm');
                } else {
                    dataToBePrint += text;
                }
                dataToBePrint += ' - ';
            })
            return dataToBePrint.trim().slice(0, -1);
        } else if (moment.isMoment(text)) {
            return moment(text).format('DD-MMM-YYYY HH:mm')
        } else {
            return checkIfValidUUID(text) ? primaryKeyData[text] : text;
        }
    }


    return (
        <Card title={'Preview and Save the Data'}>
            {
                labels.map((labelData: any) => {
                    if (labelData.isGridDisplay) {
                        return <Card title={labelData.title}>
                            {
                                labelData.keysAndLabelMapping.map((label: any) => {
                                    const columns = Object.keys(label.labels).map(item => {
                                        return {
                                            title: label.labels[item],
                                            dataIndex: item,
                                            render: (text: any) => {
                                                if (Array.isArray(text)) {
                                                    let dataToBePrint = '';
                                                    text.forEach(item => {
                                                        if (moment.isMoment(item)) {
                                                            dataToBePrint += moment(item).format('DD-MMM-YYYY  HH:mm');
                                                        } else {
                                                            dataToBePrint += text;
                                                        }
                                                        dataToBePrint += ' - ';
                                                    })
                                                    return dataToBePrint.trim().slice(0, -1);
                                                } else {
                                                    if (moment.isMoment(text)) {
                                                        return moment(text).format('DD-MMM-YYYY HH:mm')
                                                    } else {
                                                        return text;
                                                    }
                                                }
                                            },
                                        }
                                    })
                                    let presentData = data;
                                    label.key.split('.').map(eachkey => {
                                        presentData = presentData[eachkey];
                                        console.log(labelData.title)
                                        console.log(presentData);
                                    })
                                    return <Table columns={columns} dataSource={presentData} pagination={false} bordered={true} />
                                })
                            }
                        </Card>
                    } else {
                        if (labelData?.filePreview) {
                            return <>
                                {
                                    [...filePreviewData].map((imageData: any, index) => {
                                        if (index % 2 === 0) {
                                            return (
                                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }}>
                                                    {imageData.type.includes('image') ? <Image
                                                        width={500}
                                                        height={375}
                                                        title={imageData.fileDescription}
                                                        src={imageData.base64Url}
                                                    /> :
                                                        <Card
                                                            title={<span style={{ color: 'white' }}>{imageData.fileDescription}</span>}
                                                            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#1f8ede' }}
                                                            bodyStyle={{ overflow: 'auto' }}
                                                        ><embed src={imageData.base64Url} width="500" height="375"
                                                            type={imageData.type} /></Card>}
                                                </Col>
                                            );
                                        } else {
                                            return (
                                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 2 }} lg={{ span: 10, offset: 2 }} xl={{ span: 10, offset: 2 }}>
                                                    {imageData.type.includes('image') ? <Image
                                                        width={500}
                                                        height={375}
                                                        title={imageData.fileDescription}
                                                        src={imageData.base64Url}
                                                    /> :
                                                        <Card
                                                            title={<span style={{ color: 'white' }}>{imageData.fileDescription}</span>}
                                                            style={{ textAlign: 'center' }} headStyle={{ backgroundColor: '#1f8ede' }}
                                                            bodyStyle={{ overflow: 'auto' }}
                                                        ><embed src={imageData.base64Url} width="500" height="375"
                                                            type={imageData.type} /></Card>}
                                                </Col>
                                            );
                                        }
                                    }).reduce((r, element, index2) => {
                                        index2 % 2 === 0 && r.push([]);
                                        r[r.length - 1].push(element);
                                        return r;
                                    }, []).map((rowContent) => {
                                        return <Row>{rowContent}</Row>;
                                    })
                                }
                            </>
                        } else {
                            return <><Descriptions
                                title={labelData.title}
                                bordered
                                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                                size='small'
                            >
                                {
                                    labelData.keysAndLabelMapping.map((label: any) => {
                                        {
                                            return Object.keys(label.labels ? label.labels : {}).map(item => {
                                                let presentData = data;
                                                label.key.split('.').map(eachkey => {
                                                    presentData = presentData[eachkey];
                                                })
                                                if (label?.innerGridKeys && label.innerGridKeys.includes(item)) {
                                                    const columns = Object.keys(label.innerGridLabels).map(item => {
                                                        return {
                                                            title: label.innerGridLabels[item],
                                                            dataIndex: item,
                                                            render: (text: any) => {
                                                                if (Array.isArray(text)) {
                                                                    let dataToBePrint = '';
                                                                    text.forEach(item => {
                                                                        if (moment.isMoment(item)) {
                                                                            dataToBePrint += moment(item).format();
                                                                        } else {
                                                                            dataToBePrint += text;
                                                                        }
                                                                        dataToBePrint += ' - ';
                                                                    })
                                                                    return dataToBePrint.trim().slice(0, -1);
                                                                } else {
                                                                    if (moment.isMoment(text)) {
                                                                        return moment(text).format()
                                                                    } else {
                                                                        return text;
                                                                    }
                                                                }
                                                            },
                                                        }
                                                    })
                                                    return <Descriptions.Item label={label.labels[item]}>
                                                        {getInnerTable(columns, presentData?.[item])}
                                                    </Descriptions.Item>
                                                } else {
                                                    return <Descriptions.Item label={label.labels[item]}>
                                                        {getDescriptionData(presentData?.[item])}
                                                    </Descriptions.Item>
                                                }

                                            })
                                        }
                                    })
                                }
                            </Descriptions>
                            </>;
                        }
                    }

                })
            }
            {props?.children}
        </Card>
    )
}

export default PreviewComponent;