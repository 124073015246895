import { CurrencyService } from '@exportx/shared-models-and-services';
import { Card, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';

interface IPriceDetailsProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}


const PriceDetails = (props: IPriceDetailsProps) => {
  const { formRef, layoutType, initialValues } = props;
  const { Option } = Select;
  const currencyService = new CurrencyService()
  const [country, setCountry] = useState<any[]>([]);
  const { formatMessage: fm } = useIntl();


  useEffect(() => {
    getCurrencyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCurrencyData = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCountry(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };




  return (
    <div>
      <Card
        title={fm({ id: `mdm.components. fixturePriceForm.header`, defaultMessage: `Price Details` })}
      >
        <Form initialValues={initialValues}
          form={formRef}
          layout={layoutType}
          autoComplete="off"
          onFinish={onFinish}><Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }}  >
              <Form.Item
                label={fm({ id: 'mdm.components.fixturePriceForm.currency', defaultMessage: " Currency" })}
                name="currency"

              >
                {/* <Select   filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch  placeholder="PLEASE SELECT" style={{width:"220px"}} >
          <Select.Option value="rupee">RUPEE</Select.Option>
        </Select> */}
                <Select
                  placeholder={fm({ id: 'mdm.components.fixturePriceForm.currencyReq', defaultMessage: "PLEASE SELECT CURRENCY" })}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  style={{ width: "210px" }}
                >
                  <Option value={''}>Please Select</Option>
                  {country.map(item => {
                    return <Option value={item.currencyCode}>{item.currencyName}</Option>
                  })}
                </Select>


              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
              <Form.Item
                label={fm({ id: 'mdm.components.fixturePriceForm.pricePerMT', defaultMessage: " Price Per MT" })}
                name="pricePerMT"
              >
                <Input placeholder={fm({ id: 'mdm.components.fixturePriceForm.pricePerMTReq', defaultMessage: "PLEASE Enter Price Per MT" })} style={{ width: "220px" }}></Input>
              </Form.Item>

            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
              <Form.Item
                label={fm({ id: 'mdm.components.fixturePriceForm.demurrageType', defaultMessage: "Demurrage Type" })}
                name="demurrageType"
              >
                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={fm({ id: 'mdm.components.fixturePriceForm.demurrageTypeReq', defaultMessage: "Please Select Demurrage Type " })} style={{ width: "220px" }} >
                  <Select.Option value="cqd">CQD</Select.Option>
                  <Select.Option value="demurrage/dispatch">DEMURRAGE/DESPATCH</Select.Option>
                </Select>

              </Form.Item>

            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 2 }} >
              <Form.Item
                label={fm({ id: 'mdm.components.fixturePriceForm.demurrageRatePerDay', defaultMessage: 'Demurrage Rate per Day' })}
                name="demurrageRatePerDay"
              >
                <Input placeholder={fm({ id: 'mdm.components.fixturePriceForm.demurrageRatePerDayReq', defaultMessage: "Please Enter Demurrage Rate per Day " })} style={{ width: "220px" }}></Input>
              </Form.Item>

            </Col>

          </Row>
        </Form>
        {props?.children}
      </Card>



    </div>
  )
}

export default PriceDetails;