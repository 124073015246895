import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const BusinessPartnerSelection = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    customers: [],
    vendors: [],
    facilitator: [],

  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          customers: features?.filter((cou) => 74 <= +cou && +cou <= 80)?.map(Number),
          vendors: features?.filter((cou) => 81 <= +cou && +cou <= 87)?.map(Number),
          facilitator: features?.filter((cou) => 88 <= +cou && +cou <= 94)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({...prev, [name]: value}))
    }
    else setValues((prev) => ({...prev, [name]: []}));
  }


  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}

    >
      <Card size="small" title="Customers"
        extra={<Checkbox
          name="customers"
          indeterminate={values.customers.length > 0 && values.customers.length < 7}
          checked={values.customers.length === 7}
          onChange={(e) => onChange(e, 'customers', [74, 75, 76, 77, 78, 79, 80])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="customers" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, customers: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={74} >Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={75} >View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={76} >Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={77} >Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={78} >KYC</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={79} >Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={80} >Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Vednors"
       extra={<Checkbox
        name="vendors"
        indeterminate={values.vendors.length > 0 && values.vendors.length < 7}
        checked={values.vendors.length === 7}
        onChange={(e) => onChange(e, 'vendors', [81, 82, 83, 84, 85, 86, 87])}
      >
        <b>Select All</b>
      </Checkbox>}
      >
        <Form.Item name="vendors" style={{ marginBottom: 0 }}>
        <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, vendors: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={81}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={82}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={83}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={84}>Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={85}>KKYC</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={86}>Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={87}>Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Facilitator"
      extra={<Checkbox
        name="facilitator"
        indeterminate={values.facilitator.length > 0 && values.facilitator.length < 7}
        checked={values.facilitator.length === 7}
        onChange={(e) => onChange(e, 'facilitator', [88, 89, 90, 91, 92, 93, 94])}
      >
        <b>Select All</b>
      </Checkbox>}
      >
        <Form.Item name="facilitator" style={{ marginBottom: 0 }}>
        <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, facilitator: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={88}>Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={89}>View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={90}>Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={91}>Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={92}>KYC</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={93}>Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={94}>Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>

    </Form>
  )
}