import { PriceTypeEnum } from "@exportx/shared-models-and-services";
import { Card, Descriptions } from "antd";
import moment from 'moment';
interface IBargePreviewProps {
    getAllBarge: any

}

export const BargePreview = (props: IBargePreviewProps) => {
    const { getAllBarge } = props;

    return (
        <>

            <Card

            >
                <br></br>
                <Card title={'Barge Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                    <Descriptions
                        title=""
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    >
                        <Descriptions.Item label="Barge Nomination">
                            {getAllBarge?.bargeDetails.bargeNomination}
                        </Descriptions.Item>
                        <Descriptions.Item label="Purchase Contract">
                            {getAllBarge?.bargeDetails?.purchaseContractNo}
                        </Descriptions.Item>
                        <Descriptions.Item label="Purchase Quality">
                            {getAllBarge?.bargeDetails?.quality
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="Purchase Terms">
                            {getAllBarge?.bargeDetails?.purchasingTerm}
                        </Descriptions.Item>
                        <Descriptions.Item label="Purchase Type">
                            {getAllBarge?.bargeDetails?.purchaseType
                            }
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Barge Vendor">
                                <span onClick={() => navigate('/detailCustomer', { state: { bpId: getAllBarge?.bargeVendor } })}> {getAllBarge?.bargeVendorName}</span>
                            </Descriptions.Item> */}
                        <Descriptions.Item label="Barge Vendor">
                            {getAllBarge?.bargeDetails?.bargeVendorName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Barging Type">
                            {getAllBarge?.bargeDetails.bargingType}
                        </Descriptions.Item>

                        {getAllBarge?.bargeDetails.bargingType === PriceTypeEnum.PER_MT ?
                        
                        <Descriptions.Item label="Base Price Per MT">
                            {getAllBarge?.bargeDetails.bargingBasePrice || 0} {getAllBarge?.bargeDetails.bargingBasePriceCurrency}
                        </Descriptions.Item>
                    :
                        <Descriptions.Item label="Lumpsum Price">
                            {getAllBarge?.bargeDetails.lumpSumBasePrice || 0} {getAllBarge?.bargeDetails.lumpSumBasePriceCurrency}
                        </Descriptions.Item>
                    
                    }



                        <Descriptions.Item label="Loading Jetty">
                            {getAllBarge?.bargeDetails.loadingJetty}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Vat Applicability">
=======
                            <Descriptions.Item label="Barge Vendor">
                                {getAllBarge?.bargeDetails?.bargeVendorName}
                            </Descriptions.Item>
                          <Descriptions.Item label="Barging Type">
                                {getAllBarge?.bargeDetails.bargingType}
                            </Descriptions.Item>
                            <Descriptions.Item label="Base Price Per MT">
                                {getAllBarge?.bargeDetails.bargingBasePrice}
                            </Descriptions.Item>
                            

                            <Descriptions.Item label="Loading Jetty">
                                {getAllBarge?.bargeDetails.loadingJetty}
                            </Descriptions.Item>
                            {/* <Descriptions.Item label="Vat Applicability">
>>>>>>> antd-upgrade-latest
                                {getAllBarge?.vatApplicability}
                            </Descriptions.Item> */}
                        <Descriptions.Item label="Barge Id">{getAllBarge?.bargeId}</Descriptions.Item>
                        <Descriptions.Item label=" Quantity In MT">
                            {getAllBarge?.bargeDetails.bargeQuantityInMt}
                        </Descriptions.Item>
                        <Descriptions.Item label=" Loaded Quantity In MT">
                            {getAllBarge?.bargeDetails.loadedQuantityInMt}
                        </Descriptions.Item>
                        <Descriptions.Item label="Return Cargo Quantity In MT">
                            {getAllBarge?.bargeDetails.returnCargoQuantityInMt}
                        </Descriptions.Item>
                        <Descriptions.Item label="Dead Weight">
                            {getAllBarge?.bargeDetails.deadFreight}
                        </Descriptions.Item>
                        <Descriptions.Item label="Dead Weight Incurred">
                            {getAllBarge?.bargeDetails.deadFreightIncurred}
                        </Descriptions.Item>
                        <Descriptions.Item label="Dead Weight Applicability">
                            {getAllBarge?.bargeDetails.deadFreightApplicability}
                        </Descriptions.Item>
                        <Descriptions.Item label="TDS">
                            {getAllBarge?.bargeDetails.pph5}
                        </Descriptions.Item>

                    </Descriptions>
                    <br></br>

                </Card>
                <br></br>
                <Card title={'Barge Timing'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                    <Descriptions
                        title=""
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    >
                        <Descriptions.Item label="Loading Start Date">
                            {getAllBarge?.bargeTiming?.loadingDate ? moment(getAllBarge?.bargeTiming?.loadingDate).format('DD-MM-YYYY HH:mm') : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="Loading Completion Date">

                            {getAllBarge?.bargeTiming?.completionTime ? moment(getAllBarge?.bargeTiming.completionTime).format('DD-MM-YYYY HH:mm') : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="NOR Anchorage">
                            {/* {moment(getAllBarge?.bargeTiming.norAnchorage).format('DD-MMM-YYYY  HH:mm')} */}
                            {getAllBarge?.bargeTiming?.norAnchorage ? moment(getAllBarge?.bargeTiming.norAnchorage).format('DD-MM-YYYY HH:mm') : ""}
                        </Descriptions.Item>

                    </Descriptions>


                </Card>


                <br></br>

                <Card title={'Other Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                    <Descriptions
                        title=""
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    >
                        <Descriptions.Item label="Chemical Spray">
                            {getAllBarge?.bargeOthers.chemicalSpray}
                        </Descriptions.Item>
                        <Descriptions.Item label="Chemical Spray Value">
                            {getAllBarge.bargeOthers.chemicalSprayPrice}
                        </Descriptions.Item>

                        <Descriptions.Item label="Remarks">
                            {getAllBarge?.bargeOthers.remarks}
                        </Descriptions.Item>
                    </Descriptions>

                    {/* <FileUploadForm key= {Date.now()} maxCount={3} layoutType='vertical' formRef={filesFormRef} initialValues={[]} /> */}


                </Card><br></br>
            </Card><br></br>



        </>
    )

}
export default BargePreview