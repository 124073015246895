import { DeleteOutlined, EditOutlined, FilePdfTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { BargeCostingsService, BgDeadFreightIdReqDto, ContractStatusEnum, GetDataDeadFreightDto, ReferenceFeatures } from "@exportx/shared-models-and-services";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Divider, Input, Popconfirm, Tooltip } from 'antd';
import Table, { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { AlertMessages, isPermissionExist, useAuthState } from "../../../common";
import AddInvoiceDrawer from "../add-invoice-drawer";
import Invoice from "../invoice/invoice";
import { getLocalFormat } from '@exportx/ui-utils';
import { SequenceUtils } from '@exportx/ui-utils';

interface IProps {
    gridData?: GetDataDeadFreightDto[];
    getDeadFreightData?: () => void;
    setInitialValues?: (value: any) => void;
    deadFreightApproval?: boolean;
    setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>
    accessId?: any;
}


export const DeadFreightList = (props: IProps) => {


    const bargeCostingService = new BargeCostingsService();
    const { authContext } = useAuthState()
    const { gridData, getDeadFreightData, setInitialValues, deadFreightApproval, setDummyRefresh, accessId } = props
    const [drawerOpen, setDrawerOpen] = useState<boolean>()
    const [costingId, setCostingId] = useState<any>()
    const [dummyRefreshValue, setDummyRefreshValue] = useState<number>(1);
    const [searchedText, setSearchedText] = useState("");


    const deleteDeadFreight = (bgDeadFreightId) => {
        const req = new BgDeadFreightIdReqDto(bgDeadFreightId, authContext.defaultPlant)
        bargeCostingService.deleteDeadFreight(req).then(res => {
            if (res.status) {
                getDeadFreightData()
                AlertMessages.getSuccessMessage(res.internalMessage);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch(err => {
            console.log(err.message)
        })
    }

    const tableColumns: ColumnsType<any> = [
        {
            title: 'DeadFreightAction',
            width: 80,
            render: (text, rowData) => (
                <span>
              {[ContractStatusEnum.CANCELLED, ContractStatusEnum.DRAFT, ContractStatusEnum.PENDING_APPROVAL].includes(rowData.approvalStatus) && isPermissionExist([accessId?.deadFreightEdit]) && (

                    <Tooltip placement="topRight" title="Edit">
                        <EditOutlined type="edit"
                            onClick={() => { setInitialValues(rowData); setDummyRefresh(prev => prev + 1) }}
                            style={{ color: '#1890ff', fontSize: '20px' }}
                        /></Tooltip>)}

                    <Divider type="vertical" />
                    {isPermissionExist([accessId?.deadFreightdelete]) && (
                    <Popconfirm
                        onConfirm={e => {
                            deleteDeadFreight(rowData.bgDeadFreightId)
                        }}
                        title={'Are you sure to Delete Record ?'}>
                        <Tooltip placement="topRight" title="Delete">
                            <DeleteOutlined type='delete' style={{ color: 'red', fontSize: '20px' }} />
                        </Tooltip>
                    </Popconfirm>)}
                    <Divider type="vertical" />
                    {isPermissionExist([accessId?.view])}
                    <Tooltip placement="topRight" title="Add Invoice">
                        <PlusSquareTwoTone onClick={() => {
                            setDrawerOpen(true);
                            setCostingId((prev) => { return { ...prev, costingId: rowData.bgDeadFreightId, totalAmount: rowData.totalAmount } });
                        }}
                            style={{
                                color: '#1890ff', fontSize: '20px'
                            }}
                        />
                    </Tooltip>

                    <Divider type='vertical'></Divider>
                    <PDFDownloadLink
                        document={<Invoice invoice={''} demurrageName={''} costingName={ReferenceFeatures.MV_DEAD_FREIGHT} />}
                        fileName={`${rowData.costingNo}.pdf`}
                    >
                        <Tooltip placement="topRight" title="Invoice">
                            <FilePdfTwoTone style={{ color: '#1890ff', fontSize: '20px' }}
                            />
                        </Tooltip>

                    </PDFDownloadLink>
                </span >
            )

        },
        {
            title: 'DeadFreight',
            dataIndex: 'costingNo',
            width: 40,
            filteredValue: [String(searchedText).toLowerCase()],
            onFilter: (value, record) => {
                return SequenceUtils.globalFilter(value, record)
            },

        },
        {
            title: 'MV Name',
            dataIndex: 'businessNo',
            width: 40,
            render: (value, record) => {
                const href = `/#/bn-detail-view?bn_Id=${record.bnId}`
                return <>
                    {<a href={href} style={{ fontSize: 12 }}>{value + " " + `(${record.motherVesselName})`}</a>}
                </>
            }

        },

        {
            title: 'PricePerMT',
            dataIndex: 'pricePerMT',
            width: 40,
            render: (value, record) => {
                return <>
                    {getLocalFormat(Number(value), record.baseCurrency)}
                </>
            }
        },
        {
            title: 'Account Type',
            dataIndex: 'account',
        },
        {
            title: 'BusinessPartner',
            dataIndex: 'supplierName',
        },
        {
            title: 'DeadFreight QTY',
            dataIndex: 'deadFreight',
            render: (value, record) => {
                return <>
                    {value + " MT"}
                </>
            }
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            render: (value, record) => {
                return <>
                    {getLocalFormat(Number(value), record.baseCurrency)}
                </>
            }
        }

    ]
    return (

        <>
            <Input.Search
                placeholder="Search"
                allowClear
                onChange={(e) => { setSearchedText(e.target.value) }}
                onSearch={(value) => { setSearchedText(value) }}
                style={{ width: 200, float: "right", display: `${deadFreightApproval ? "none" : ""}` }}
            />
            <Table
                bordered={true}
                scroll={{ x: 500 }}
                style={{ display: `${deadFreightApproval ? "none" : ""}` }}
                columns={tableColumns}
                dataSource={gridData}
                pagination={false}
                rowClassName={(record, index) => record.approvalStatus === ContractStatusEnum.CANCELLED ? 'bg-warning' : ''}
            ></Table>
            <AddInvoiceDrawer referenceFeatures={ReferenceFeatures.MV_DEAD_FREIGHT} key={dummyRefreshValue} setDummyRefresh={setDummyRefreshValue} openDrawer={drawerOpen} setDrawerOpen={setDrawerOpen} costingId={costingId} accessId={accessId} />

        </>
    )

}
export default DeadFreightList

