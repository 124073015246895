//import { DownpaymentsService } from '@exportx/shared-models-and-services';
import { VendorAdvService } from '@exportx/shared-models-and-services';
import { Button, Col, Collapse, Form, Row, Space } from 'antd';
import { useState } from 'react'
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, PreviewComponent } from '../../../../common';
import DownPayments from './downpayments';

const { Panel } = Collapse;
const DownPaymentPreview = () => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();

 
  const [defaultOpenKey, setDefaultOpenKey] = useState(['1']);
  const [downPaymentData, setDownPaymentData] = useState([]);
  const [downPaymentsFormRef] = Form.useForm();
  const service = new VendorAdvService();
  let pathToreDirect = '/downPaymentGrid';


  const DownPaymentFormhandler = () => {
    downPaymentsFormRef.validateFields().then(values => {

      setDownPaymentData((prevState) => { return { ...prevState,  ...values } });
      setDefaultOpenKey(['2']);

    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const previewDataLabels = [{
    title: fm({ id: `step1heading`, defaultMessage: `Down Payments` }),
    keysAndLabelMapping: [
      {
        key: "DownPayments",
        labels: {
          vendor: fm({ id: "mdm.components.downPayments.vendor", defaultMessage: 'Vendor' }),
          contractDate: fm({ id: "mdm.components.downPayments.contractDate", defaultMessage: 'Contract Date' }),
          downPaymentAmount: fm({ id: "mdm.components.downPayments.downPaymentAmount", defaultMessage: 'Down Payment Amount' }),
          remarks: fm({ id: 'mdm.components.downPayments.remarks', defaultMessage: "Remarks" }),
        }
      }
    ],


    isGridDisplay: false
  }]





  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const clearDownPayments = () => {

  }

  const saveDownPayments = () => {
    service.create(downPaymentData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Company created SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }




  return (
    <div>
    <Collapse activeKey={defaultOpenKey} onChange={onChange}>
        <Panel header="Down Payments" key='1'  extra={<span style={{color:'white'}}><Button type="primary" className='panel_button' onClick={()=>navigate(pathToreDirect)}>View</Button></span>}>
          <DownPayments formRef={downPaymentsFormRef} initialValues={downPaymentData
          } layoutType='vertical'>
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button
                      onClick={() => { clearDownPayments(); }}
                    >
                      Clear
                    </Button>
                    <Button onClick={DownPaymentFormhandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </DownPayments>

        </Panel>
        <Panel header="Preview" key="2">
          <>
            <PreviewComponent data={{DownPayments:downPaymentData}} labels={previewDataLabels} primaryKeyData={{}}>
              <Row justify="end">
                <Col>
                  {
                    <Space>
                      <Button onClick={() => { clearDownPayments(); }}>
                        {fm({ id: "common.clear", defaultMessage: "Clear" })}
                      </Button>
                      <Button onClick={saveDownPayments} type="primary">
                        Save
                      </Button>
                    </Space>
                  }</Col>
              </Row>
            </PreviewComponent>
          </>
        </Panel>
      </Collapse>
    </div>
  )
}

export default DownPaymentPreview