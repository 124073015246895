import { AllocatedTypeEnum, BargeBusinessRequest, BargeMapppingUpdateDto, BargeStatusEnum, BusinessNoBargeMappingDto, BusinessNumberService } from "@exportx/shared-models-and-services";
import { SequenceUtils } from "@exportx/ui-utils";
import { Button, Card, Col, Descriptions, Drawer, Form, Row, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { AlertMessages } from "../../../../../common";
import DatePicker from "../../../../../common/data-picker/date-picker";
import BMSupplierLevelSummery, { IBMSupplierLevelSummery } from "./barge-mapping-supplier-level-summery";
import { MapBarges } from "./map-barges";


const { Option } = Select;

export const BargeMappingView = () => {
    const { formatMessage: fm } = useIntl();
    //const navigate = useNavigate();
    const { state }: any = useLocation();
    let { businessNo } = state ? state : { businessNo: '' };

    const [mapVisible, setMapVisible] = useState(false);
    const [gridData, setGridData] = useState<BusinessNoBargeMappingDto[]>([]);
    const [supplierSummaryData, setSupplierSummaryData] = useState<IBMSupplierLevelSummery[]>([]);
    const [totalBargeQuantity, setTotalBargeQuantity] = useState<number>(0);
    const [totalRedraftQuantity, setTotalRedraftQuantity] = useState<number>(0)
    const [editData, setEditData] = useState<any>(false);
    const [dummyState, setDummyState] = useState<number>(1);

    const [bargeMappingForm] = Form.useForm();

    const service = new BusinessNumberService();



    useEffect(() => {
        getAllBargeBusinessMapping(businessNo)
    }, [businessNo]);

    const setSupplierWiseSummaryData = (bargeMappingData: BusinessNoBargeMappingDto[]) => {
        const tableDataSource = [];
        const mapData: Map<string, BusinessNoBargeMappingDto[]> = new Map();
        let totalAllocatedQty = 0;
        let totalRedraftQty = 0;
        bargeMappingData.forEach(rec => {
            let key = `${rec.supplier}$@$${rec.mineName ? rec.mineName : rec.mine}$@$${rec.purchaseQuality}`;
            if (mapData.has(key)) {
                mapData.get(key).push(rec);
            } else {
                mapData.set(key, [rec]);
            }
        });
        for (const [uniqueKey, uniqueData] of mapData.entries()) {
            let supplierWiseAllocatedQty = 0;
            let supplierWiseRedraftQty = 0;
            for (const eachData of uniqueData) {
                let redraftQuantity = 0
                if (eachData.allocationType === AllocatedTypeEnum.FULL) {
                    supplierWiseAllocatedQty += Number(eachData.bargeQuantityInMt);
                    totalAllocatedQty += Number(eachData.bargeQuantityInMt);
                } else {
                    supplierWiseAllocatedQty += Number(eachData.quantity) ? Number(eachData.quantity) : 0;
                    totalAllocatedQty += Number(eachData.quantity) ? Number(eachData.quantity) : 0;
                }

                if (eachData.redraftQuantity === null || Number(eachData.redraftQuantity) === 0) {
                    if (eachData.allocationType === AllocatedTypeEnum.FULL) {
                        redraftQuantity = Number(eachData.bargeQuantityInMt);
                    } else {
                        redraftQuantity = Number(eachData.quantity);
                    }
                } else {
                    redraftQuantity = Number(eachData.redraftQuantity);
                }

                supplierWiseRedraftQty += Number(redraftQuantity);
                totalRedraftQty += Number(redraftQuantity);
            }
            tableDataSource.push({
                bargeQuantityInMt: Number(supplierWiseAllocatedQty.toFixed(3)),
                supplierWiseRedraftQty: Number(supplierWiseRedraftQty.toFixed(3)),
                allocationDisplayId: uniqueData[0]?.allocationDisplayId,
                loadingJetty: uniqueData[0]?.loadingJetty,
                supplier: uniqueData[0]?.supplier,
                purchaseQuality: uniqueData[0]?.purchaseQuality,
                uniqueKey: uniqueKey
            });
        }
        setSupplierSummaryData(tableDataSource);
        setTotalBargeQuantity(totalAllocatedQty);
        setTotalRedraftQuantity(totalRedraftQty);
    }
    const getAllBargeBusinessMapping = (businessNoId: string) => {
        const req = new BargeBusinessRequest(businessNoId)
        service.getAllBargeBusinessMapping(req).then(res => {
            if (res.status) {
                setGridData(res.data);
                setSupplierWiseSummaryData(res.data);
            } else {
                setGridData([]);
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message)
        });
    }

    const closeDrawer1 = () => {
        setMapVisible(false);
        bargeMappingForm.resetFields();
        setDummyState(prev => prev + 1);
    }

    const openMapBargeData = () => {
        setMapVisible(true);
        setDummyState(prev => prev + 1);
    }

    const rowDataUpdateHAndler = (rowData, index) => {
        const updateData = gridData
        updateData[index] = rowData;
        setEditData(updateData)
    }

    const handleSubmit = () => {
        const req: BargeMapppingUpdateDto[] = []
        editData.forEach((el) => { req.push(new BargeMapppingUpdateDto(el.id, el.bargeId, el.bargeStatus, el.commenceDischarge, el.completionDischarge, el.loadingDate, el.completionTime, el.norAnchorage)) })
        service.bargeAllocationUpdate(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                setEditData(false);
                getAllBargeBusinessMapping(businessNo);
                setDummyState(prev => prev + 1);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message)
        })
    }

    const handleCancel = () => {
        setEditData(false);
    }

    const editBargeData = () => {
        setEditData(true);
    }

    const tableColumns: ColumnsType<any> = [
        {
            title: 'Barge Id',
            dataIndex: 'bargeId',
            width: 160,
            fixed: 'left',

            render: (text, record) => {
                const link = `/#/barge-detail-view?barge_id=${record.bargeUid}`;
                return <>
                    <a href={link} className="link-primary" >
                        {SequenceUtils.formatNumberToSpecificLength(record.bargeId)}
                    </a></>
            }
        },
        {
            title: 'Allocation Id',
            dataIndex: 'allocationDisplayId',
            width: 160,
            sorter: (a, b) => {
                const aValue = String(a.allocationDisplayId);
                const bValue = String(b.allocationDisplayId);
                return aValue.localeCompare(bValue);
            },
            sortDirections: ['ascend', 'descend'],
            onFilter: (value, record) => {
                return (
                    String(record.bargeId)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.allocationDisplayId)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.bargeNomination)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.loadingJetty)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.supplier)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.mine)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.purchaseQuality)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.quantity)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.redraftQuantity)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.loadingDate)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.completionTime)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.norAnchorage)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.commenceDischarge)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.completionDischarge)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||

                    String(record.bargeStatus)
                        .toLowerCase()
                        .includes(value.toLocaleString()))
            },
        },
        {
            title: 'Barge Nomination',
            dataIndex: 'bargeNomination',
            width: 160,
        },
        {
            title: 'Jetty',
            dataIndex: 'loadingJetty',
            width: 160,
        },
        {
            title: 'Supplier',
            dataIndex: 'supplier',
            width: 160,
        },
        {
            title: 'Mine',
            dataIndex: 'mine',
            width: 160,
        },
        {
            title: 'Purchase Quality',
            dataIndex: 'purchaseQuality',
            width: 160,
        },
        {
            title: 'Barge Quantity',
            dataIndex: 'quantity',
            width: 160,
            render: (value, record, index) => {
                let qty = Number(record.quantity) ? Number(record.quantity)?.toFixed(3) : Number(record.bargeQuantityInMt)?.toFixed(3);
                return <>{qty}</>
            },
        },
        {
            title: 'Redraft Quantity',
            dataIndex: 'redraftQuantity',
            width: 160,
            render: (value, record, index) => {
                let qty = Number(record.redraftQuantity) ? Number(record.redraftQuantity).toFixed(3) : Number(0).toFixed(3);
                return <>{Number(qty) !== 0 ? qty : 0}</>;
            },
        },

        {
            title: 'Barge Status',
            dataIndex: 'bargeStatus',
            width: 200,
            render: (text, record, index) => {
                return <>
                    {editData ?
                        <Select
                            showSearch
                            optionFilterProp="children"
                            onChange={(value) => { record.bargeStatus = value; rowDataUpdateHAndler(record, index) }}
                            allowClear
                            defaultValue={record.bargeStatus}
                            style={{ width: '180px' }}
                            placeholder='Please Select'>
                            {Object.keys(BargeStatusEnum).map(key => {
                                return <Option value={BargeStatusEnum[key]}>{BargeStatusEnum[key]}</Option>
                            })}
                        </Select>
                        : text}</>
            }
        },

        {
            title: 'Loading Start Date By Jetty',
            dataIndex: 'loadingDate',
            width: 160,
            render: (text, record, index) => {
                const formattedDate = record.loadingDate
                    ? moment(record.loadingDate).format('DD-MMM-YYYY')
                    : '';

                return editData ? (
                    <DatePicker
                        defaultValue={record.loadingDate ? moment(record.loadingDate) : null}
                        onChange={(value) => {
                            if (moment(value).isValid()) {
                                record.loadingDate = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                rowDataUpdateHAndler(record, index);
                            }
                        }}
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="Select Date & time"
                        autoComplete="off"
                        allowClear
                    />
                ) : (
                    <span>{formattedDate}</span>
                );
            },
        },



        {
            title: 'Loading Completion Time at Jetty',
            dataIndex: 'completionTime',
            width: 160,
            render: (text, record, index) => {
                const formattedDate = record.completionTime
                    ? moment(record.completionTime).format('DD-MMM-YYYY')
                    : '';

                return editData ? (
                    <DatePicker
                        defaultValue={record.completionTime ? moment(record.completionTime) : null}
                        onChange={(value) => {
                            if (moment(value).isValid()) {
                                record.completionTime = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                rowDataUpdateHAndler(record, index);
                            }
                        }}
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="Select Date & time"
                        autoComplete="off"
                        allowClear
                    />
                ) : (
                    <span>{formattedDate}</span>
                );
            },
        },
        {
            title: 'Nor Anchorage',
            dataIndex: 'norAnchorage',
            width: 160,
            render: (text, record, index) => {
                const formattedDate = record.norAnchorage
                    ? moment(record.norAnchorage).format('DD-MMM-YYYY')
                    : '';

                return editData ? (
                    <DatePicker
                        defaultValue={record.norAnchorage ? moment(record.norAnchorage) : null}
                        onChange={(value) => {
                            if (moment(value).isValid()) {
                                record.norAnchorage = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                rowDataUpdateHAndler(record, index);
                            }
                        }}
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="Select Date & time"
                        autoComplete="off"
                        allowClear
                    />
                ) : (
                    <span>{formattedDate}</span>
                );
            },
        },
        {
            title: 'Commence Discharging',
            dataIndex: 'commenceDischarge',
            width: 160,
            render: (text, record, index) => {
                const formattedDate = record.commenceDischarge
                    ? moment(record.commenceDischarge).format('DD-MMM-YYYY')
                    : '';

                return editData ? (
                    <DatePicker
                        defaultValue={record.commenceDischarge ? moment(record.commenceDischarge) : null}
                        onChange={(value) => {
                            if (moment(value).isValid()) {
                                record.commenceDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                rowDataUpdateHAndler(record, index);
                            }
                        }}
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="Select Date & time"
                        autoComplete="off"
                        allowClear
                    />
                ) : (
                    <span>{formattedDate}</span>
                );
            },
        },

        {
            title: 'Completed Discharging',
            dataIndex: 'completionDischarge',
            width: 160,
            render: (text, record, index) => {
                const formattedDate = record.completionDischarge
                    ? moment(record.completionDischarge).format('DD-MMM-YYYY')
                    : '';

                return editData ? (
                    <DatePicker
                        defaultValue={record.completionDischarge ? moment(record.completionDischarge) : null}
                        onChange={(value) => {
                            if (moment(value).isValid()) {
                                record.completionDischarge = moment(value).format("YYYY-MM-DD[T]HH:mm:ss");
                                rowDataUpdateHAndler(record, index);
                            }
                        }}
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"
                        placeholder="Select Date & time"
                        autoComplete="off"
                        allowClear
                    />
                ) : (
                    <span>{formattedDate}</span>
                );
            },
        }
    ]



    return (
        <Card title={'Barge Data'}>
            <Card>
                <Descriptions column={{ xxl: 5, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
                    <Descriptions.Item label={'Total Barge Quantity'}>{totalBargeQuantity?.toFixed(3)}</Descriptions.Item>
                    <Descriptions.Item label={'Total Redraft Quantity'}>{totalRedraftQuantity?.toFixed(3)}</Descriptions.Item>
                </Descriptions>
                <Row justify="end">
                    <Col>
                        <Space>
                            <Button type="primary" onClick={() => openMapBargeData()}>Map Barges</Button>
                            <Button type="primary" onClick={() => editBargeData()}> Edit Barges</Button>
                        </Space>
                    </Col>
                </Row>
                <br></br>
                <Table
                    rowKey={(row) => row.id}
                    className='contracts'
                    columns={tableColumns}
                    dataSource={gridData}
                    pagination={false}
                    scroll={{ x: 500 }}
                    size="large"
                    bordered
                />
                <br />
                <Row>
                    {editData && <Col>
                        <Space>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button type="primary" onClick={handleSubmit}>Save</Button>
                        </Space>
                    </Col>}
                </Row>
                <br />
                <br />
                <BMSupplierLevelSummery supplierSummaryData={supplierSummaryData} />
                <Drawer
                    bodyStyle={{ paddingBottom: 80 }}
                    width={1000}
                    onClose={closeDrawer1}
                    open={mapVisible}
                    closable={true} key={dummyState} >
                    <MapBarges setDummyState={setDummyState} bargeMappingForm={bargeMappingForm} getAllBargeBusinessMapping={getAllBargeBusinessMapping} closeDrawer1={closeDrawer1} />
                </Drawer>
            </Card>
        </Card>
    )
}