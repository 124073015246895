import { Card, Col, Form, FormInstance, Input, Row } from 'antd';
import React from 'react'
import FileUploadForm from '../../../../common/file-upload-form/FileUploadForm';
import { FormLayout } from 'antd/lib/form/Form';
import { useIntl } from 'react-intl';


interface ITermsProps{
  formRef: FormInstance<any>;
	initialValues: any;
	children?: React.ReactNode;
	layoutType: FormLayout;
}

const Terms = (props:ITermsProps) => {
  const { formRef, layoutType, initialValues } = props;
  const { formatMessage: fm } = useIntl();
  const onFinish = (values: any) => {
    console.log('Success:', values);
   
  };

  return (
    <div>
<Card
title={fm({ id: `mdm.components. termsForm.header`, defaultMessage: `Terms` })}
>
    <Form initialValues={initialValues}
					form={formRef}
					layout={layoutType}
					autoComplete="off"
          onFinish={onFinish}><Row>
<Col xs={{span:24}} lg={{span:11, offset:1}} >
<Form.Item
label={fm({ id: 'mdm.components.termsForm.cancellationFee', defaultMessage: 'Cancellation Fee' })}
name="cancellationFee"

>
<Input  placeholder="Cancellation Fee" style={{width:"220px"}}></Input>
</Form.Item>

</Col>
<Col xs={{span:24}} lg={{span:11, offset:1}}>
<Form.Item
label={fm({ id: 'mdm.components.termsForm.paymentTerms', defaultMessage: 'Payment Terms' })}
name="paymentTerms"
>
<Input  placeholder="Payment Terms" style={{width:"220px"}}></Input>
</Form.Item>

</Col>  
</Row>
<Row>
<Col xs={{span:24}} lg={{span:11, offset:1}} >
<Form.Item
label={fm({ id: 'mdm.components.termsForm.others', defaultMessage: 'Others' })}
name="others"
>
<Input  placeholder="Other T&C" style={{width:"220px"}}></Input>
         
</Form.Item>

</Col>
<Col xs={{span:24}} lg={{span:11, offset:1}}>
<Form.Item
label={fm({ id: 'mdm.components.termsForm.remarks', defaultMessage: 'Remarks' })}
name="remarks"
>
<Input placeholder='Remarks' style={{width:"220px"}}></Input>
</Form.Item>

</Col>
    
</Row>

<FileUploadForm maxCount={3} layoutType={'inline'} formRef={undefined} initialValues={[]}/>
</Form>
{props?.children}
</Card>



    </div>
  )
}

export default  Terms;