import { Card, Tabs } from "antd";
import PreshipmentReport from "./pre-shipment-report";

const { TabPane } = Tabs;
const PreShipment = () => {
  return (
    <Card title="Pre-Shipment-S&A"
    >
    <Tabs>
      <TabPane tab="No Barge" key="1">
        <PreshipmentReport/>
      </TabPane>
      <TabPane tab="Barge" key="2">
       <PreshipmentReport/>
      </TabPane>
     </Tabs>
     </Card>

  )
}

export default PreShipment;