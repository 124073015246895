import { MinusCircleOutlined } from '@ant-design/icons';
import {
  FileHandlingService,
  FileUploadIdModel,
} from '@exportx/shared-models-and-services';
import { Popconfirm } from 'antd';
import { AlertMessages } from '../notifications';
import { useAuthState } from '../iam-client-react';

interface viewFiles {
  name: string;
  uid: string;
  size: number;
  type: string;
  fileDescription: string;
  filePath: string;
  lastModified: string;
  lastModifiedDate: string;
  percent: number;
  fileUploadId: string;
  base64Url: string;
}

interface IProps {
  filesData: viewFiles[];
  required: boolean;
  getAllData?: () => void;
}

const ViewFileHandling = (props: IProps) => {
  const { filesData, required, getAllData } = props;
  const fileUploadPath = window[`_env_`]['APP_MDM_SERVICE_URL'];
  const { authContext } = useAuthState();
  const fileService = new FileHandlingService();

  function deleteFile(fileUploadId) {
    const req = new FileUploadIdModel(
      fileUploadId,
      authContext.defaultPlant,
      authContext.user.userName,
    );
    fileService
      .deleteSingleFile(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          getAllData();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => console.log(err.message, 'err message'));
  }

  return (
    <>
      {filesData?.map((rec, index) => {
        if (!rec || typeof rec === 'object') {
          <></>;
        }
        let path = '';
        if (rec?.base64Url) {
          path = rec?.base64Url;
        } else {
          if (rec?.filePath.startsWith('http')) {
            path = rec?.filePath;
          } else {
            path = fileUploadPath + '/' + rec?.filePath?.slice(7);
          }
        }

        if (rec) {
          return (
            <>
              <a
                href={`${path}`}
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: '12px' }}
              >
                {rec?.fileDescription}
              </a>{' '}
              &nbsp;
              <Popconfirm
                onConfirm={(e) => {
                  deleteFile(rec?.fileUploadId);
                }}
                title={'Are you sure to Delete File ?'}
              >
                <MinusCircleOutlined
                  hidden={required}
                  type="delete"
                  style={{ color: 'red', fontSize: '13px' }}
                />
              </Popconfirm>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </>
          );
        }
      })}
    </>
  );
};

export default ViewFileHandling;
