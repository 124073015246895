import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const LcTrackerSelection = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    purchase_lc_tracker: [],
    sales_lc_tracker: [],
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          purchase_lc_tracker: features?.filter((cou) => 772 <= +cou && +cou <= 777)?.map(Number),
          sales_lc_tracker: features?.filter((cou) => 778 <= +cou && +cou <= 783)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({ ...prev, [name]: value }))
    }
    else setValues((prev) => ({ ...prev, [name]: [] }));
  }

  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}
    >
      <Card size="small" title="Purchase LcTracker"
        extra={<Checkbox
          name="purchase_lc_tracker"
          indeterminate={values.purchase_lc_tracker.length > 0 && values.purchase_lc_tracker.length < 6}
          checked={values.purchase_lc_tracker.length === 6}
          onChange={(e) => onChange(e, 'purchase_lc_tracker', [772, 773, 774, 775, 776, 777])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="purchase_lc_tracker" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              <Col span={6}>
                <Checkbox value={772} >Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={773} >View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={774} >Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={775} >Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={776} >Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={777} >Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Sales LcTracker"
        extra={<Checkbox
          name="sales_lc_tracker"
          indeterminate={values.sales_lc_tracker.length > 0 && values.sales_lc_tracker.length < 6}
          checked={values.sales_lc_tracker.length === 6}
          onChange={(e) => onChange(e, 'sales_lc_tracker', [778, 779, 780, 781, 782, 783])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="sales_lc_tracker" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, sales_lc_tracker: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={778} >Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={779} >View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={780} >Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={781} >Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={782} >Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={783} >Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>

    </Form>
  )
}