import { PriceTypeEnum } from '@exportx/shared-models-and-services';
import { Form, Input, Select, Tooltip } from 'antd'
import { useState } from 'react'
import { useIntl } from 'react-intl';
import { useAuthState } from '../../../../common';
import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { costingTypes } from './constant';
import React from 'react';

const { Option } = Select;
export const MiscExpensesDataGridFrom = (props) => {
    const [dummyStateToRefresh, setDummyStateToRefresh] = useState(0);
    const { formatMessage: fm } = useIntl();
    const { authContext } = useAuthState();
    const { miscExpensesData, form, currency, add, remove, selectedCurrency, exchangeRate } = props;

    const getTotal = (name) => {
        const priceType = form.getFieldValue(['miscExpensesData', name, 'priceType']);
        const quantity = form.getFieldValue(['miscExpensesData', name, 'quantity']) ? form.getFieldValue(['miscExpensesData', name, 'quantity']) : 0;
        const pricePMT = form.getFieldValue(['miscExpensesData', name, 'pricePMT']) ? form.getFieldValue(['miscExpensesData', name, 'pricePMT']) : 0;
        const presentCurrency = form.getFieldValue(['miscExpensesData', name, 'currency']) ? form.getFieldValue(['miscExpensesData', name, 'currency']) : 0;
        if (priceType === PriceTypeEnum.LUMP_SUM) {
            form.setFieldValue(['miscExpensesData', name, 'total'], pricePMT);
            return pricePMT;
        }
        const total = quantity * pricePMT;
        form.setFieldValue(['miscExpensesData', name, 'total'], total);
        return total;
    }
    return (
        <>
            {
                React.Children.toArray(miscExpensesData.map((field, index) => (
                    <tr>
                        <td className='table-form'>
                            <Form.Item
                                key={field.key}
                                name={[field.name, "costingType"]} rules={[{ required: true, message: "Select CostingType" }]}>
                                <Select showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {React.Children.toArray(costingTypes?.map((el) => {
                                        return <Option value={el} >{el}</Option>
                                    }))
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td colSpan={3} className='text-center'>-</td>
                        <td className='table-form'>
                            <Form.Item
                                name={[field.name, "priceType"]}>
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch onChange={() => setDummyStateToRefresh(c => c + 1)}>
                                    {React.Children.toArray(Object.keys(PriceTypeEnum).map(deduction => {
                                        return <Option value={PriceTypeEnum[deduction]}>{PriceTypeEnum[deduction]}</Option>
                                    }))}
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Enter Quantity" }]}
                                name={[field.name, "quantity"]}><Input type='number' onChange={() => setDummyStateToRefresh(c => c + 1)} />
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Enter PricePMT" }]}
                                name={[field.name, "pricePMT"]}><Input type='number' onChange={() => setDummyStateToRefresh(c => c + 1)} />
                            </Form.Item>
                        </td>
                        <td className='table-form'>
                            <Form.Item rules={[{ required: true, message: "Select Currency" }]} name={[field.name, "currency"]} style={{ width: "80%" }}>
                                <Select className="w-100"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {currency?.map((c) => {
                                        return <Option value={c.currencyCode}>{c.currencyName}</Option>
                                    })
                                    }
                                </Select>
                            </Form.Item>
                        </td>
                        <td className='text-right'>
                            {getTotal(field.name)}
                            <Form.Item name={[field.name, "total"]} style={{ display: 'none' }}>
                                <Input hidden />
                            </Form.Item>
                        </td>
                        <td className='text-center'>
                            {(miscExpensesData.length - 1 === index) && <span>
                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                    <PlusCircleOutlined style={{ fontSize: '21px' }} onClick={() => add({ priceType: PriceTypeEnum.PER_MT })} />
                                </Tooltip>
                            </span>}
                            {index !== 0 && <span>
                                <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                    <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => {
                                        remove(field.name);
                                    }} />
                                </Tooltip>
                            </span>}
                        </td>
                    </tr>
                )))
            }
            <tr>
                <td colSpan={5}></td>
                <td className='text-right' style={{ fontWeight: 'bold' }}>{form.getFieldValue(['miscExpensesData'])?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.quantity ? curr.quantity : 0), 0)}</td>
                <td colSpan={2}></td>
                <td className='text-right' style={{ fontWeight: 'bold' }} >{form.getFieldValue(['miscExpensesData'])?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.total ? curr.total : 0), 0)}</td>
                <td></td>
            </tr>
        </>
    )
}

export default MiscExpensesDataGridFrom;