import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const BusinessNoSelection = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    business_no: [],
    business_report: []
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          business_no: features?.filter((cou) => 253 <= +cou && +cou <= 260)?.map(Number),
          business_report: features?.filter((cou) => 261 <= +cou && +cou <= 261)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({ ...prev, [name]: value }))
    }
    else setValues((prev) => ({ ...prev, [name]: [] }));
  }

  return (
    <Form
      name="validate_other"
      layout='vertical'
      initialValues={values}
      form={formRef}
    >
      <Card size="small" title="Business Number"
        extra={<Checkbox
          name="customers"
          indeterminate={values.business_no.length > 0 && values.business_no.length < 8}
          checked={values.business_no.length === 8}
          onChange={(e) => onChange(e, 'business_no', [253, 254, 255, 256, 257, 258, 259, 260])}
        >
          <b>Select All</b>
        </Checkbox>}
      >
        <Form.Item name="business_no" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, business_no: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={253} >Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={254} >View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={255} >Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={256} >Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={257} >View Price</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={258} >Change Allocation</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={259} >Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={260} >Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      <br />
      <Card size="small" title="Business Report"
       extra={<Checkbox
        name="customers"
        indeterminate={values.business_report.length > 0 && values.business_report.length < 1}
        checked={values.business_report.length === 1}
        onChange={(e) => onChange(e, 'business_report', [261])}
      >
        <b>Select All</b>
      </Checkbox>}
      >
        <Form.Item name="business_report" style={{ marginBottom: 0 }}>
          <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, business_report: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={261} >View</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      {/* <br /> */}
      {/* <Card size="small" title="Mother Vessel operational Data">
                <Form.Item name="mv_operational_data" style={{ marginBottom: 0 }}>
                    <Checkbox.Group style={{ width: '100%' }}>
                        <Row>
                            <Col span={6}>
                                <Checkbox
                                    value='1,2,3,4'

                                >
                                    Select All
                                </Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox value="1">Add</Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox value="2">View</Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox value="3">Update</Checkbox>
                            </Col>

                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Card>
            <br />
            <Card size="small" title="Mother Vessel Completion Report">
                <Form.Item name="mv_completion_report" style={{ marginBottom: 0 }}>
                    <Checkbox.Group style={{ width: '100%' }}>
                        <Row>
                            <Col span={6}>
                                <Checkbox
                                    value='1,2,3,4'
                                >
                                    Select All
                                </Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox value="1">Add</Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox value="2">View</Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox value="3">Update</Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox value="4">Excel</Checkbox>
                            </Col>
                            <Col span={6}>
                                <Checkbox value="4">Files</Checkbox>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            </Card> */}

    </Form>
  )
}