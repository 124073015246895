import { Card } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React from 'react'

export const TopFiveVendorsByPayable = () => {

  const columns: ColumnsType<any> = [
    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
    },

    {
      title: "Within Due",
      dataIndex: 'withInDue'
    },
    {
      title: 'Over Due',
      dataIndex: 'overDue'
    },
    {
      title: 'Due Invoices',
      dataIndex: 'dueInvoices'
    },
    {
      title: 'Above Credit Limit',
      dataIndex: 'aboveCreditLimit'
    },
  ];
  return (
    <Card title='Top Five Vendors by Payable'>
      <Table
        rowKey={record => record.baId}
        columns={columns}
        dataSource={[]}
        scroll={{ x: 500 }}
        size="large"
        bordered
        pagination={false} />
    </Card>
  )
}

export default TopFiveVendorsByPayable;