import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { getLocalFormat } from "@exportx/ui-utils";
import { Table, Typography } from "antd";
import moment from "moment";
import { useAuthState } from "../../../common";
import { BargeCostingIdReqDto, BargeCostingsService, BgDemurrageIdReqDto, BusinessPartnerTypeEnum, ContractStatusEnum, ContractStatusReqDto, GetDataDeadFreightDto, GetExpApprovalDataDto, ShippingTypes } from "@exportx/shared-models-and-services"
import { ApprovalTracking } from "./approval-tracking";


const { Text } = Typography;

export const DemurrageDispatchDetails = ({ costingId, remainingAmount }) => {
    const navigate = useNavigate();
    const { authContext } = useAuthState();
    const bargeCostingService = new BargeCostingsService();

    const [data, setData] = useState<any>();
    useEffect(() => {
        const req = new BgDemurrageIdReqDto(costingId, authContext.defaultPlant)
        bargeCostingService.getBgDemurrageById(req).then(res => {
            if (res.status) {
                setData(res.data)
            }
        }).catch(err => {
            console.log(err.message)
        })


    }, [costingId])

    return (
        <>
            <table className="table table-bordered table-sm mt-4">
                <tr>
                    <th colSpan={2}>Mv Stowage Plan</th>
                    <td> <Text>{data?.stowagePlanInMt}</Text></td>
                </tr>
                <tr>
                    <th colSpan={2}>Contract No</th>
                    <td> <Text>{data?.contractNo}</Text></td>
                </tr>
                <tr>
                    <th colSpan={2}>Shipping Type</th>
                    <td> <Text>{data?.shippingType}</Text></td>
                </tr>
                <tr>
                    <th colSpan={2}>Supply Tonage</th>
                    <td> <Text>{getLocalFormat(data?.supplyTonnage, data?.quoteCurrency)}</Text></td>
                </tr>
                {[ShippingTypes.CFR_DP, ShippingTypes.CFR_DP].includes(data?.shippingType) &&
                    <tr>
                        <th colSpan={2}>Port Type</th>
                        <td> <Text>{data?.portType}</Text></td>
                    </tr>
                }
                <tr>
                    <th colSpan={2}>Demurrage/Dispatch Applicable</th>
                    <td> <Text>{data?.costingApplicable ? 'Yes' : 'N/A'}</Text></td>
                </tr>

                {data?.costingApplicable &&
                    <>

                        <tr>
                            <th colSpan={2}>Load Rate Per Day</th>
                            <td> <Text>{getLocalFormat(data?.portRate, data?.quoteCurrency)}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Demurrage Rate Per Day</th>
                            <td> <Text>{getLocalFormat(data?.demurrgeRatePerDay, data?.quoteCurrency)}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Dispatch Rate Per Day</th>
                            <td> <Text>{getLocalFormat(data?.dispatchRatePerDay, data?.quoteCurrency)}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Allowed Time to Load</th>
                            <td> <Text>{data?.allowedTimeToLoad}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Time  Completed to Load</th>
                            <td> <Text>{data?.timeCompletedForLoadMV}</Text></td>
                        </tr>
                        <tr>
                            <th colSpan={2}>No of Diff Days</th>
                            <td> <Text>{data?.noofDaysDifference}</Text></td>
                        </tr>

                        <tr>
                            <th colSpan={2}>Exchange Date</th>
                            <td>{data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'N/A'}</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Exchange Rate</th>
                            <td>
                                <Text>{data?.exchangeRate}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Amount</th>
                            <td>
                                <Text>{getLocalFormat(data?.cost, data?.baseCurrency)}</Text>

                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Discount</th>
                            <td>
                                <Text>{getLocalFormat(data?.discount, data?.baseCurrency)}</Text>

                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Notes</th>
                            <td>
                                <Text>{data?.notes}</Text>

                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Amount After Discount</th>
                            <td>
                                <Text>{getLocalFormat(data?.amtAftDiscount, data?.baseCurrency)}</Text>

                            </td>
                        </tr>
                        <tr>
                            <th>TAX 1</th>
                            <td>
                                <Text>{`${data?.taxName1 || ''} - ${data?.taxPercent1}`}</Text>
                            </td>
                            <td>{getLocalFormat(data?.taxTotal1, data?.baseCurrency)}</td>
                        </tr>
                        <tr>
                            <th>TAX 2</th>
                            <td>
                                <Text>{`${data?.taxName2 || ''} - ${data?.taxPercent2}`}</Text>
                            </td>
                            <td>
                                <Text>{getLocalFormat(data?.taxTotal2, data?.baseCurrency)}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th>TDS:</th>
                            <td><Text>{`${data?.tdsName} - ${data?.tdsPercent}`}</Text></td>
                            <td>{getLocalFormat(data?.tdsTotal, data?.baseCurrency)}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} > Total Amount</th>
                            <td>{getLocalFormat(data?.totalAmount, data?.baseCurrency)}</td>

                        </tr>
                        <tr>
                            <th colSpan={2} >
                                To be paid to Supplier
                            </th>
                            <td>
                                <span style={{ color: 'green', fontWeight: 'bold' }}>{getLocalFormat(data?.totalAmount, data?.baseCurrency)}</span>
                            </td>
                        </tr>
                    </>
                }

            </table>

            <ApprovalTracking history={data?.workLog} />
        </>
    )
}