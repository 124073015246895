import { BargeIdRequestDto, BargeService, CalculationTableDto, CommercialGetAllDto, ContractModesEnum, ContractTermEnum, MutualAgreed, PurchaseSupplierReportDto, SamplingAndAnalysisService, ScqTypicalRequestDto } from '@exportx/shared-models-and-services';
import { Card, Col, Form, Input, InputNumber, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../common';

interface IProsQualityPriceAdjustments {
  selectedRowData: CalculationTableDto;
  contractType: ContractModesEnum;
  formInitialValues: CommercialGetAllDto

}

const { Option } = Select;
export const QualityPriceAdjustmentsDeatils = (props: IProsQualityPriceAdjustments) => {
  const { contractType, selectedRowData, formInitialValues } = props;
  const [bargeGridData, setBargeGridData] = useState<PurchaseSupplierReportDto[]>([]);
  const [initialValues, setInitialValues] = useState(undefined);
  const { authContext } = useAuthState();
  const qualityService = new SamplingAndAnalysisService();
  const bargeService = new BargeService();
  const [formRef] = Form.useForm();


  useEffect(() => {
    if (contractType === ContractModesEnum.SALES) {
      getScqTypicalSpecs();
    } else {
      getPurchaseSamplingSupplier();

    }
  }, [contractType, selectedRowData?.bgUId]);

  useEffect(() => {
    if (formInitialValues) {
      formRef.setFieldValue("gcvAdjustment", formInitialValues?.gcvAdjustment)
      formRef.setFieldValue("ashPriceAdjustment", formInitialValues?.ashPriceAdjustment)
      formRef.setFieldValue("tsPriceAdjustment", formInitialValues?.tsPriceAdjustment)
      formRef.setFieldValue("totalPenalty", formInitialValues?.totalPenalty)
    }

  }, [formInitialValues])

  useEffect(() => {
    if (bargeGridData) {
      setTotalValuesToFields();
    }
  }, [bargeGridData]);

  const getScqTypicalSpecs = () => {
    const req = new ScqTypicalRequestDto(formInitialValues?.bnId, selectedRowData?.bgUId, formRef.getFieldValue('allocatedId'), authContext.defaultPlant);
    bargeService.getScqTypicalSpecs(req).then(res => {
      if (res.status) {
        setBargeGridData(res.data);
        // calculateDefaultBonusOrPenalty(res.data);

      } else {
        console.log(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  };


  const setTotalValuesToFields = () => {
    formRef.validateFields().then(values => {
      const bonusPenaltyTotal: number = Number((Number(values.tsPriceAdjustment) + Number(values.gcvAdjustment) + Number(values.ashPriceAdjustment)).toFixed(2));
      const adjustedPrice: number = Number((Number(formInitialValues?.adjustedPriceValue)).toFixed(2));
      formRef.setFieldValue('totalBonusPrice', bonusPenaltyTotal);
      //Contract price - (GCV Adjustment +TS Penalty cal + Ash Penalty cal)
      formRef.setFieldValue('qualityAdjustPrice', Number(adjustedPrice));
    })

  }

  const rejectionHandler = (name: string, rowData: PurchaseSupplierReportDto) => {
    let disabled = true;
    let initialValue = null;
    if (name === 'gcvRejection') {
      if (Number(rowData?.cvActual) < Number(rowData?.cvRejection)) {
        // disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    } else if (name === 'ashRejection') {
      if (Number(rowData?.ashTypical) < Number(rowData?.ashActual)) {
        // disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    } else {
      if (Number(rowData?.tsTypical) < Number(rowData?.tsActual)) {
        // disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    }

    formRef.setFieldValue(name, initialValue);
    return <Form.Item name={name} initialValue={initialValue}>
      <Select allowClear showSearch disabled={disabled} >
        <Option value={null} >{'No Rejection'}</Option>
        {React.Children.toArray(Object.keys(MutualAgreed).map(key => {
          return <Option value={MutualAgreed[key]}>{MutualAgreed[key]}</Option>
        }))}
      </Select>
    </Form.Item>
  }

  const tableColumns: any = [
    {
      title: 'Typical Analysis',
      dataIndex: 'typicalAnalysis',
      width: 130,
      align: 'center',
    },
    {
      title: 'Units',
      dataIndex: 'units',
      width: 130,
      align: 'center',
    },
    {
      title: 'Basis',
      dataIndex: 'basis',
      width: 130,
      align: 'center',
    },
    {
      title: 'Typical',
      dataIndex: 'typical',
      width: 130,
      align: 'center',
    },
    {
      title: 'Rejection',
      dataIndex: 'rejection',
      width: 130,
      align: 'center',
    },
    {
      title: 'Bonus Cap',
      dataIndex: 'bonusCap',
      width: 130,
      align: 'center'
    },
    {
      title: 'Penalty',
      dataIndex: 'penalty',
      width: 130,
      align: 'center'
    },
    {
      title: 'Actual',
      width: 130,
      align: 'center',
      dataIndex: 'actual'
    },
    {
      title: 'Rejection',
      width: 130,
      align: 'center',
      dataIndex: 'rejectionApplied',
      render: (name, row) => {
        return rejectionHandler(name, bargeGridData[0]);
      }
    },
    {
      title: 'Bonus/Penalty',
      width: 130,
      align: 'center',
      dataIndex: 'bonusOrPenaltyCap',
      render: (name, row) => {
        return <Form.Item name={name}>
          <InputNumber onChange={() => {
            const one = formRef.getFieldValue("gcvAdjustment")
            const two = formRef.getFieldValue("ashPriceAdjustment")
            const th = formRef.getFieldValue("tsPriceAdjustment")
            formRef.setFieldValue('totalBonusPrice', Number(one) + Number(two) + Number(th));
            setTotalValuesToFields();
          }} disabled={true} />
        </Form.Item>
      }
    },
  ];


  const tableData: any = [
    {
      typicalAnalysis: 'Gross Calorific Value-1',
      name: 'qualityCalorificValueActual',
      units: 'Kcal/Kg',
      basis: bargeGridData[0]?.cvBasis,
      typical: bargeGridData[0]?.cvTypical,
      rejection: bargeGridData[0]?.cvRejection,
      bonusCap: bargeGridData[0]?.cvBonusCap,
      penalty: bargeGridData[0]?.cvPenality,
      actual: bargeGridData[0]?.['cvActual'],
      rejectionApplied: "gcvRejection",
      bonusOrPenaltyCap: 'gcvAdjustment'
    },
    {
      typicalAnalysis: 'Ash',
      name: 'qualityAshActual',
      units: '%',
      basis: bargeGridData[0]?.ashBasis,
      typical: bargeGridData[0]?.ashTypical,
      rejection: bargeGridData[0]?.ashRejection,
      penalty: bargeGridData[0]?.ashPenality,
      actual: bargeGridData[0]?.['ashActual'],
      rejectionApplied: "ashRejection",
      bonusOrPenaltyCap: 'ashPriceAdjustment'
    },
    {
      typicalAnalysis: 'Total Sulphur',
      name: 'qualityTotalSulphurActual',
      units: '%',
      basis: bargeGridData[0]?.tsBasis,
      typical: bargeGridData[0]?.tsTypical,
      rejection: bargeGridData[0]?.tsRejection,
      penalty: bargeGridData[0]?.tsPenality,
      actual: bargeGridData[0]?.['tsActual'],
      rejectionApplied: "tsRejection",
      bonusOrPenaltyCap: 'tsPriceAdjustment'
    }
  ];


  const getPurchaseSamplingSupplier = () => {
    const req = new BargeIdRequestDto(selectedRowData?.bgUId, authContext.defaultPlant);
    qualityService.getPurchaseSamplingSupplier(req).then(res => {
      if (res.status) {
        setBargeGridData(res.data);
        // calculateDefaultBonusOrPenalty(res.data);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  return (
    <div>
      {
        bargeGridData.length !== 0 &&
        <Card title={'Price Adjustment'} className='default-card-class'>
          <Form form={formRef} initialValues={initialValues} autoComplete='off'>
            <Table
              className='contracts'
              columns={tableColumns}
              dataSource={tableData}
              pagination={false}
              bordered
            />
            <br></br>
            <Row>
              <Col span={8}>

              </Col>
              <Col span={9}>
              </Col>
              <Col span={7}>
                <Form.Item label={'Total Penalty / Bonus'} name='totalBonusPrice'>
                  <Input disabled={true} >
                  </Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
              </Col>
              <Col span={10}>
              </Col>
              <Col span={6}>
                <Form.Item label={'Adjusted Price'} name={'qualityAdjustPrice'}>
                  <Input disabled={true}>
                  </Input>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      }
    </div>
  )
}

export default QualityPriceAdjustmentsDeatils;