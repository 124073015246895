import { Pie, G2 } from '@ant-design/plots';
import { useEffect, useState } from 'react';


export interface IDonutChartProps {
    chartData: any[];
    height: string;
}

export const DonutChart = (props: IDonutChartProps) => {
    const {height} = props;
    const G = G2.getEngine('canvas');
    const [data, setData] = useState<any[]>([]);
    useEffect(() => {
        console.log(props.chartData);
        setData(props.chartData);
    }, []);
    const cfg: any = {
        appendPadding: 5,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        legend: false,
        style: {
            autoFit: true,
            // width:"1500px",
            // height: "300px"
            height: {height}
        },
        label: {
            type: 'spider',
            labelHeight: 40,
            formatter: (data: any, mappingData: any) => {
                const group = new G.Group({});
                group.addShape({
                    type: 'circle',
                    attrs: {
                        x: 0,
                        y: 0,
                        width: 10,
                        height: 50,
                        r: 5,
                        fill: mappingData.color,
                    },
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 10,
                        y: 8,
                        text: `${data.type}`,
                        fill: mappingData.color,
                    },
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 0,
                        y: 25,
                        text: `${data.value}`,
                        fill: 'rgba(0, 0, 0, 0.65)',
                        fontWeight: 700,
                    },
                });
                return group;
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };
    const config = cfg;
    return (
        <div>
            <Pie {...config} />
        </div>
    );
};
