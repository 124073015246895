import { Card, Checkbox, Row, Col, Form } from "antd";
import { useEffect, useState } from "react";


export const BargeSelection = ({ formRef, initialValues }) => {
  const [values, setValues] = useState({
    barges: [],
  })

  useEffect(() => {
    if (initialValues) {
      const features = initialValues?.featureIds?.split(',')
      setValues((prev) => {
        return {
          ...prev,
          barges: features?.filter((cou) => 262 <= +cou && +cou <= 269)?.map(Number),
        };
      });
    }

  }, [initialValues])

  useEffect(() => {
    formRef.setFieldsValue(values)
  }, [formRef, values])

  const onChange = (e: any, name?: string, value?: number[]) => {
    if (e.target.checked) {
      setValues((prev) => ({...prev, [name]: value}))
    }
    else setValues((prev) => ({...prev, [name]: []}));
  }

  return (
    <Form
      name="validate_other"
      initialValues={values}
      layout='vertical'
      form={formRef}
    >
      <Card size="small" title="Barge Operational Data"
       extra={<Checkbox
        name="customers"
        indeterminate={values.barges.length > 0 && values.barges.length < 8}
        checked={values.barges.length === 8}
        onChange={(e) => onChange(e, 'barges', [262, 263, 264, 265, 266, 267, 268, 269])}
      >
        <b>Select All</b>
      </Checkbox>}
      >
        <Form.Item name="barges" style={{ marginBottom: 0 }}>
        <Checkbox.Group style={{ width: '100%' }} onChange={(value) => setValues(prev => ({ ...prev, barges: value }))}>
            <Row>
              <Col span={6}>
                <Checkbox value={262} >Add</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={263} >View</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={264} >Update</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={265}>Delete</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={266} >View price</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={267} >Change Allocations</Checkbox>
              </Col>
              {/* <Col span={6}>
                <Checkbox value="4" >BL Creation</Checkbox>
              </Col> */}
              <Col span={6}>
                <Checkbox value={268} >Excel</Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={269} >Files</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card>

    </Form>
  )
}