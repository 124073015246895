import { UploadOutlined } from '@ant-design/icons';
import { BargeCostingIdReqDto, BargeCostingsService, BargeService, BgBargeCostingCreateDto, BpTaxableDto, BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, BusinessPartnerPreviewData, BusinessPartnerService, CompanyCodeReq, CompanyTdsService, CurrencyConverterService, CurrencyExchangeRequestDto, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, MapBgCToBg, PerformaAdvanceDataDto, PriceTypeEnum, RecentUnCostBargingDto, TaxesAndItemsService, TdsResModel, UnCostedBargesReqDto } from '@exportx/shared-models-and-services';
import { SequenceUtils, getBase64, getLocalFormat } from '@exportx/ui-utils';
import { Button, Card, Checkbox, Col, Descriptions, Drawer, Form, Input, InputNumber, List, Row, Select, Table, Typography, Upload } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';
import BargeCostingsList from './barge-costings-list';
import BargeAdvanceAdjustments from './barge-advance-adjustment';
import TextArea from 'antd/es/input/TextArea';

const { Option } = Select;
const { Text } = Typography;

export const BargeCostings = () => {
  const { authContext } = useAuthState();
  const [formRef] = Form.useForm();
  const [bpTaxes, setBpTaxes] = useState<BpTaxableDto>(null);
  const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
  const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
  let presentMoment: any = moment();
  const getDefaultObject = () => {
    const defaultObj = new BgBargeCostingCreateDto();
    defaultObj.billingCurrency = authContext.defaultPlantCurrency;
    defaultObj.costingDate = presentMoment;
    defaultObj.plantCode = authContext.defaultPlant;
    defaultObj.bargeCostings = [];
    return defaultObj;
  }
  const [formValues, setFormValues] = useState<BgBargeCostingCreateDto>(getDefaultObject());
  const [dropDown, setDropDown] = useState<RecentUnCostBargingDto[]>([]);
  const [filterValues, setFilterValues] = useState<string[]>([]);
  const [fileList, setFileList] = useState([]);
  const [businessDropDown, setBusinessDropDown] = useState<BusinessNoDropDownDto[]>([])
  const [gridDrawer, setGridDrawer] = useState<boolean>(false);
  const [dummyRefresh, setDummyRefresh] = useState<number>(0);

  const [priceType, setPriceType] = useState<PriceTypeEnum>(PriceTypeEnum.PER_MT)
  let location = useLocation();
  let paramsId = queryString.parse(location.search)?.costingId

  const [adjustedData, setAdjustedData] = useState<any[]>([]);
  const [invoiceContracts, setInvoiceContracts] = useState<PerformaAdvanceDataDto[]>([]);
  const [accessId, setAccessId] = useState({})

  const bpService = new BusinessPartnerService();
  const bargeService = new BargeService();
  const companyTdsService = new CompanyTdsService();
  const taxesAndItemsService = new TaxesAndItemsService();
  const bargeCostingService = new BargeCostingsService();
  const businessService = new BusinessNumberService()

  useEffect(()=>{
    setAccessId({
      create: 404,
      view: 405,
      update: 406,
      delete: 407,
      approve: 408,
      reject: 409,
      release: 410,
      files: 411,
      bargeEdit: 397,
      bargeDelete: 398
    })
  },[])

  useEffect(() => {
    getRecentUnCostedBargingBarges(undefined);
    getTaxesByItemsId()
    getBusinessNoDropDown();
  }, []);

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
    businessService.getBusinessNoDropDown(req).then(res => {
      if (res.status) {
        setBusinessDropDown(res.data)
      } else {
        console.log(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }
  useEffect(() => {
    formRef.setFieldsValue(formValues);
    const totalAmountOfAdjustments = adjustedData?.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0) ?? 0;
    const remaining = (Number(formRef.getFieldValue('totalAmount'))) - Number(totalAmountOfAdjustments)
    formRef.setFieldValue("remainingBalance", Number(remaining?.toFixed(2)).toLocaleString());
    formRef.setFieldValue("totalAdjustedValue", Number(totalAmountOfAdjustments?.toFixed(2)).toLocaleString());
  }, [formValues, adjustedData]);

  const onFileChange = (data) => {
    setFileList([data.file]);
  }

  const getRecentUnCostedBargingBarges = (costingId: string, businessNo?: string) => {
    const req = new UnCostedBargesReqDto();
    req.costingId = costingId;
    req.plantCode = authContext.defaultPlant;
    req.businessNo = businessNo;
    bargeService.getRecentUnCostedBargingBarges(req).then(res => {
      if (res.status) {
        setDropDown(res.data);
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getTdsData = () => {
    const req = new CompanyCodeReq();
    req.company = authContext.defaultPlant;
    req.date = formRef.getFieldValue('costingDate');
    companyTdsService.getTdsByCompanyCode(req).then(res => {
      if (res.status) {
        setTdsModel(res.data);
      } else {
        setTdsModel([]);
      }
    }).catch(err => {
      console.log(err);
      setTdsModel([]);
    });
  }


  const getTaxesByItemsId = () => {
    const req = new ItemsIdRequestDto(ItemsCodesEnum.BARGE, formValues.costingDate)
    taxesAndItemsService.getVatByItemsId(req).then(res => {
      if (res.status) {
        setVat(res.data);
      } else {
        setVat([]);
      }
    }).catch(err => {
      console.log(err);
      setVat([]);
    });
  }


  const getTaxesAgainstBusinessPartner = (bpId: string) => {
    getTdsData();
    bpService.getBusinessPartnerTaxes(new BusinessPartnerPreviewData(bpId)).then(res => {
      if (res.status) {
        setBpTaxes(res.data);
      }
    }).catch(err => {
      console.log(err);
      setBpTaxes(null);
    });
  }

  useEffect(() => {
    formRef.setFieldsValue(formValues)
  }, [formValues])

  const bgDataToBgCostingData = (selectedBgDropDownData: RecentUnCostBargingDto[]) => {
    let costingsTotal = 0;
    let totalAmount = 0;
    const data = selectedBgDropDownData.map((mainRec, index) => {
      const bg = formRef.getFieldValue('bargeCostings').filter(rec => rec.bargeId === mainRec.bgId)[0];
      const price = mainRec.bargingType === PriceTypeEnum.LUMP_SUM ? mainRec?.lumpsumPrice : mainRec.bargingBasePrice;
      const currency = mainRec.bargingType === PriceTypeEnum.LUMP_SUM ? mainRec?.lumpsumPriceCurrency : mainRec.bargingBasePriceCurrency;
      const formInWardQty = Number(bg?.inWardQty);
      const formDeadFreightQty = Number(bg?.deadFreightQty);
      const exchangeRate = authContext.defaultPlantCurrency === currency ? 1 : 1;
      const priceAftExchg = price * exchangeRate
      let baseFreight = ((!Number.isNaN(formInWardQty)) ? formInWardQty : mainRec.bargeQuantityInMt) * priceAftExchg;
      if (mainRec.bargingType === PriceTypeEnum.LUMP_SUM) {
        baseFreight = priceAftExchg
      }
      const deadFreight = ((!Number.isNaN(formDeadFreightQty)) ? formDeadFreightQty : mainRec.deadFreightIncurred) * priceAftExchg;
      const obj = new MapBgCToBg(undefined, undefined, mainRec.bgId, mainRec.bargeId, mainRec.bargingType as PriceTypeEnum, mainRec.bargeNomination, currency, authContext.defaultPlantCurrency, exchangeRate, undefined, mainRec.pcId, mainRec.bargeSupplierId, mainRec.bargeSupplierId, mainRec.loadingDate, (!Number.isNaN(formInWardQty)) ? formInWardQty : mainRec.bargeQuantityInMt, price, (!Number.isNaN(formDeadFreightQty)) ? formDeadFreightQty : mainRec.deadFreightIncurred, baseFreight, deadFreight, 0, authContext.defaultPlant, authContext.user.userName, mainRec.deadFreightApplicability);
      let total = deadFreight + baseFreight;
      obj.total = total || priceAftExchg;

      costingsTotal += total;
      return obj
    })
    const bargeType = selectedBgDropDownData[0]?.bargingType || PriceTypeEnum.PER_MT
    console.log('bargeType', bargeType)
    setPriceType(bargeType as PriceTypeEnum)
    setFormValues(prevState => {
      const tdsPercentage = prevState?.tdsPercentage ? prevState?.tdsPercentage : 0;
      const tdsTotal = (tdsPercentage / 100) * costingsTotal;
      let taxTotal = 0
      if (formValues?.taxId) {
        const taxId = vat[0]?.taxId ? vat[0]?.taxId : null;
        const taxPercentage = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
        taxTotal = (taxPercentage / 100) * costingsTotal
      }
      totalAmount = +((costingsTotal + taxTotal) - tdsTotal)?.toFixed(2);
      return {
        ...prevState,
        bargeCostings: data,
        costingsTotal: costingsTotal,
        totalAmount: totalAmount,
        coalBpId: selectedBgDropDownData[0]?.bargeSupplierId,
        bargeBpId: selectedBgDropDownData[0]?.bargeSupplierId,
        coalSupplier: selectedBgDropDownData[0]?.coalSupplierName,
        bargeSupplier: selectedBgDropDownData[0]?.bargeSupplierName,
        deadFreightApplicability: selectedBgDropDownData[0]?.deadFreightApplicability,
        tdsTotal: tdsTotal,
        taxTotal,
        barges: filterValues
      }
    });
  }

  useEffect(() => {
    const set = new Set(filterValues);
    if (set.size) {
      bgDataToBgCostingData(dropDown.filter((el) => set.has(el.bgId)))
    } else {
      bgDataToBgCostingData(dropDown.filter((el) => set.has(el.bgId)))
    }
  }, [filterValues])


  const onSelect = (value) => {
    let result = dropDown.filter((el) => el.bgId === value);
    if (!bpTaxes) {
      getTaxesAgainstBusinessPartner(result[0].bargeSupplierId);
    } else {
      if (bpTaxes.bpId !== result[0].bargeSupplierId) {
        getTaxesAgainstBusinessPartner(result[0].bargeSupplierId);
      }
    }
    const set: Set<string> = new Set(formRef.getFieldValue('barges'));//new Set(filterValues);
    if (!set.size) {
      set.add(value);
      setFilterValues([value]);
    } else {
      const array = Array.from(set);
      if (dropDown.filter((el) => el.bgId === array[0])[0]?.bargeSupplierId === result[0]?.bargeSupplierId) {
        set.add(value);
      } else {
        set.delete(value);
        AlertMessages.getErrorMessage('Please select Barges with same supplier');
      }
      setFilterValues(Array.from(set));
      formRef.setFieldValue('barges', Array.from(set))
    }
  }

  const onDeselect = (value) => {
    const set: Set<string> = new Set(formRef.getFieldValue('barges'));//new Set(filterValues);
    set.delete(value);
    const bargeCostings = formRef.getFieldValue('bargeCostings').filter(rec => rec.bargeId !== value);
    formRef.setFieldValue('bargeCostings', bargeCostings)
    setFilterValues(Array.from(set));
    formRef.setFieldValue('barges', Array.from(set))
  }

  const columns: any = [
    {
      title: 'Barge No',
      dataIndex: 'bargeNo',
      render: (value, row, index) => {
        return (
          <Form.Item name={[index, "bargeId"]}>
            {SequenceUtils.formatNumberToSpecificLength(formValues.bargeCostings[index]?.['bargeNo']) + ' - ' + formValues.bargeCostings[index]?.['bargeNomination']}
            <Input hidden />
          </Form.Item>
        );
      }
    },
    {
      title: 'Inward Qty',
      dataIndex: 'inWardQty',
      render: (value, row, index) => {
        return (
          <>
            <Form.Item name={[index, "inWardQty"]}>
              <InputNumber onChange={() => onChangeHandler(index)} min={0} />
            </Form.Item>
          </>
        );
      }
    },
    {
      title: priceType === PriceTypeEnum.PER_MT ? 'BasePrice PMT' : 'Lumpsum',
      dataIndex: 'basePrice',
      align: 'right',
      render: (value, row, index) => {
        return (
          <>
            <Form.Item name={[index, "basePrice"]}>
              {getLocalFormat(formValues.bargeCostings[index]?.basePrice, formValues.bargeCostings[index]?.baseCurrency)}
              <Input hidden />
            </Form.Item>
          </>
        );
      }
    },
    {
      title: 'JISDOR Date',
      dataIndex: 'exchangeDate',
      render: (value, row, index) => {
        return (
          <>
            {formValues.bargeCostings[index]?.baseCurrency === authContext.defaultPlantCurrency ? <><Form.Item name={[index, "exchangeDate"]} style={{ display: 'none' }}>
              <DatePicker />
            </Form.Item> - </> : <Form.Item name={[index, "exchangeDate"]}>
              <DatePicker onChange={() => exchangeRateOnChangeHandler(index)} />
            </Form.Item>}
          </>
        );
      }
    },
    {
      title: 'Exchange Rate',
      dataIndex: 'exchangeRate',
      render: (value, row, index) => {
        return (
          <>
            <Form.Item name={[index, "exchangeRate"]} hidden>
              <InputNumber disabled={true} />
            </Form.Item>
            <Form.Item name={[index, "displayExchangeRate"]} >
              <InputNumber disabled={true} />
            </Form.Item>
          </>
        );
      }
    },
    {
      title: 'Dead Qty',
      dataIndex: 'deadFreightQty',
      render: (value, row, index) => {
        return (
          <>
            <Form.Item name={[index, "deadFreightQty"]}>
              <InputNumber onChange={() => onChangeHandler(index)} min={0} />
            </Form.Item>
          </>
        );
      }
    },
    {
      title: 'Base Freight',
      dataIndex: 'baseFreight',
      align: 'right',
      render: (value, row, index) => {
        return (
          <>
            <Form.Item name={[index, "baseFreight"]}>
              {getLocalFormat(formValues.bargeCostings[index]?.baseFreight, formValues?.billingCurrency)}
              <Input hidden />
            </Form.Item>
          </>
        );
      }
    },
    {
      title: 'Dead Freight',
      dataIndex: 'deadFreight',
      align: 'right',
      render: (value, row, index) => {
        return (
          <>
            <Form.Item name={[index, "deadFreight"]}>
              {getLocalFormat(formValues.bargeCostings[index]?.deadFreight, formValues?.billingCurrency)}
              <Input hidden />
            </Form.Item>
            <Form.Item name={[index, 'deadFrightApplicability']} style={{ display: 'none' }}>
              <Input hidden />
            </Form.Item>
          </>
        );
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align: 'right',
      render: (value, row, index) => {
        return (
          <>
            <Form.Item name={[index, "total"]}>
              {getLocalFormat(formValues.bargeCostings[index]?.total, formValues?.billingCurrency)}
              <Input hidden />
            </Form.Item>
          </>
        );
      }
    },
  ]

  const getCurrencyExchange = async (billingCurrency: string, negotiationCurrency: string, exchangeDate: string) => {
    try {
      const currencyConverterService = new CurrencyConverterService();
      const req = new CurrencyExchangeRequestDto(billingCurrency, negotiationCurrency, moment(exchangeDate).format('YYYY-MM-DD'))
      const res = await currencyConverterService.getCurrencyExchangeRate(req);
      if (!res.status) {
        AlertMessages.getErrorMessage(res.internalMessage);
        return;
      } else {
        return res.data;
      }
    } catch (error: any) {
      AlertMessages.getErrorMessage(error.message);
    }
  }

  const exchangeRateOnChangeHandler = async (index: number) => {
    const formInWardQty = Number(formRef.getFieldValue(['bargeCostings', index, 'inWardQty']));
    const formDeadFreightQty = Number(formRef.getFieldValue(['bargeCostings', index, 'deadFreightQty']));
    const basePrice = Number(formRef.getFieldValue(['bargeCostings', index, 'basePrice']));
    const exchangeDate = formRef.getFieldValue(['bargeCostings', index, 'exchangeDate']);
    let displayExchangeRate = formRef.getFieldValue(['bargeCostings', index, 'displayExchangeRate']);
    let exchangeRate = formRef.getFieldValue(['bargeCostings', index, 'exchangeRate']);
    if (exchangeDate) {
      const exchangeRateRes = await getCurrencyExchange(formValues.bargeCostings[index].baseCurrency, formValues.bargeCostings[index].quoteCurrency, exchangeDate);
      if (!exchangeRateRes) {
        return;
      } else {
        exchangeRate = Number(exchangeRateRes.exchangeRate);
        displayExchangeRate = Number(exchangeRateRes.displayExchangeRate);
      }
    } else {
      exchangeRate = 1;
      displayExchangeRate = 1;
    }
    onChangeFormValuesSetter(index, formInWardQty, formDeadFreightQty, basePrice, exchangeRate, exchangeDate, displayExchangeRate);
  }

  const onChangeHandler = (index: number) => {
    const formInWardQty = Number(formRef.getFieldValue(['bargeCostings', index, 'inWardQty']));
    const formDeadFreightQty = Number(formRef.getFieldValue(['bargeCostings', index, 'deadFreightQty']));
    const basePrice = Number(formRef.getFieldValue(['bargeCostings', index, 'basePrice']));
    let exchangeRate = Number(formRef.getFieldValue(['bargeCostings', index, 'exchangeRate']));
    let displayExchangeRate = Number(formRef.getFieldValue(['bargeCostings', index, 'displayExchangeRate']));
    onChangeFormValuesSetter(index, formInWardQty, formDeadFreightQty, basePrice, exchangeRate, undefined, displayExchangeRate);
  }

  const onChangeFormValuesSetter = (index: number, formInWardQty: number, formDeadFreightQty: number, basePrice: number, exchangeRate: number, exchangeDate: any, displayExchangeRate: number,) => {
    setFormValues(prevState => {
      const prevBargeCostings = prevState.bargeCostings;
      let totalAmount = prevState.totalAmount;
      let costingsTotal = prevState.costingsTotal;
      const prevBargeData = prevState.bargeCostings[index];
      let baseFreight = (!Number.isNaN(formInWardQty) ? formInWardQty : prevBargeData.inWardQty) * basePrice * exchangeRate;
      if (priceType === PriceTypeEnum?.LUMP_SUM) {
        baseFreight = basePrice * exchangeRate
      }

      const deadFreight = (!Number.isNaN(formDeadFreightQty) ? formDeadFreightQty : prevBargeData.deadFreightQty) * basePrice * exchangeRate;
      if (exchangeDate) {
        prevBargeData.exchangeDate = exchangeDate;
        prevBargeData.exchangeRate = exchangeRate;
        prevBargeData['displayExchangeRate'] = displayExchangeRate;
      }
      prevBargeCostings[index] = prevBargeData;
      prevBargeData.inWardQty = (!Number.isNaN(formInWardQty)) ? formInWardQty : prevBargeData.inWardQty;
      prevBargeData.baseFreight = baseFreight;
      prevBargeData.deadFreightQty = (!Number.isNaN(formDeadFreightQty)) ? formDeadFreightQty : prevBargeData.deadFreightQty;
      prevBargeData.deadFreight = deadFreight;
      let total = deadFreight + baseFreight;
      costingsTotal -= Number(prevBargeData.total);
      prevBargeData.total = total;
      costingsTotal += total;



      const tdsPercentage = prevState?.tdsPercentage ? prevState?.tdsPercentage : 0;
      const tdsTotal = (tdsPercentage / 100) * costingsTotal;
      let taxTotal = 0;
      const taxPercentage = prevState?.taxPercentage ? prevState?.taxPercentage : 0;
      taxTotal = (taxPercentage / 100) * costingsTotal;
      totalAmount = +((costingsTotal + taxTotal) - tdsTotal)?.toFixed(2);



      return {
        ...prevState,
        bargeCostings: prevBargeCostings,
        costingsTotal,
        totalAmount,
        taxTotal,
        tdsTotal: tdsTotal
      }
    });
  }

  const bargeCostingSave = () => {
    formRef.validateFields().then(async values => {
      const formFilesData = formRef.getFieldValue('filesData');
      let filesData = []
      if (formFilesData?.file) {
        const base64 = await getBase64(formFilesData.file.originFileObj);
        filesData = [{
          ...formFilesData.file,
          base64Url: base64,
          fileDescription: 'Invoice file'
        }];
      }

      bargeCostingService.createBargePurchaseFinancialBargeCosting({ ...formValues, filesData, remarks: values.remarks ?? '', plantCode: authContext.defaultPlant, userName: authContext.user.userName, businessNo: values.businessNo, adjustmentTransactions: adjustedData }).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setTimeout(() => {
            // navigate(pathToreDirect)
            window.location.reload()
          }, 1000);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage)
        }
      }).catch(err => {
        console.log(err.message)
      })

    })
  }

  const suffixSelector2 = (
    <Form.Item name="baseCurrency" noStyle label="Please Select">
      <Select disabled>
        <Option value={formValues.billingCurrency}>{formValues.billingCurrency}</Option>
      </Select>
    </Form.Item>
  );

  const tdsOnchange = (tdsId) => {
    const obj = tdsModel.filter(res => res.companyTdsId === tdsId)[0];
    if (tdsId) {
      setFormValues(prevState => {
        const tdsPercentage = obj?.tdsValue ? obj?.tdsValue : 0;
        const tdsTotal = (tdsPercentage / 100) * prevState.costingsTotal;
        const taxTotal = prevState.taxTotal;
        return {
          ...prevState,
          tdsId,
          tdsPercentage,
          tdsTotal,
          totalAmount: +((prevState.costingsTotal + taxTotal) - tdsTotal)?.toFixed(2)
        }
      })
    } else {
      setFormValues(prevState => {
        const tdsPercentage = 0;
        const totalAmount = (prevState.totalAmount + prevState.tdsTotal);
        return {
          ...prevState,
          tdsId,
          tdsPercentage,
          tdsTotal: 0,
          totalAmount
        }
      })
    }
  }

  const vatOnchange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const taxId = vat[0]?.taxId ? vat[0]?.taxId : null;
      const taxPercentage = vat[0]?.taxPercentage ? vat[0]?.taxPercentage : 0;
      setFormValues(prevState => {
        const taxTotal = (taxPercentage / 100) * prevState.costingsTotal;
        const tdsTotal = prevState.tdsTotal;
        return {
          ...prevState,
          taxId,
          taxPercentage,
          taxTotal,
          totalAmount: +((prevState.costingsTotal + taxTotal) - tdsTotal)?.toFixed(2),
          vat: e.target.checked
        }
      })
    } else {
      const taxId = null;
      const taxPercentage = 0;
      setFormValues(prevState => {
        const totalAmount = (prevState.totalAmount - prevState.taxTotal);
        return {
          ...prevState,
          taxId,
          taxPercentage,
          taxTotal: 0,
          totalAmount: totalAmount,
          vat: e.target.checked
        }
      })
    }

  }

  useEffect(() => {
    if (paramsId) {
      editHandler(paramsId)
    }

  }, [paramsId])

  const editHandler = (costingId: string) => {
    bargeCostingService.getBargeCostingDataByCostingId(new BargeCostingIdReqDto(costingId, authContext.defaultPlant)).then(res => {
      if (res.status) {
        setEditDataToForm(res.data);
      } else {
        //setBargeCostingData([]);
      }
    }).catch(err => {
      console.log(err)
      // setBargeCostingData([]);
    })
  }

  const setEditDataToForm = (editData: any) => {
    editData.costingDate = moment(editData.costingDate);
    editData.bargeCostings = editData.bargeCostings.map(rec => {
      return {
        ...rec,
        exchangeDate: rec.exchangeDate ? moment(rec.exchangeDate) : undefined,
        displayExchangeRate: rec.exchangeRate
      }
    })
    setPriceType(editData.bargeCostings[0]?.bargingType)

    getRecentUnCostedBargingBarges(editData.costingId);
    getTaxesAgainstBusinessPartner(editData.bargeBpId);
    //console.log(editData);
    setFormValues(editData);
    setAdjustedData(editData.advanceTarnsactionsData)
    //setFilterValues(editData.barges)
    setGridDrawer(false)
  }

  const workLogOnChange = () => {
    setFormValues(prevState => {
      const remarks = formRef.getFieldValue('remarks');
      return {
        ...prevState,
        remarks
      }
    })
  }

  return (
    <Card title={<span style={{ color: 'white' }}>Barge Costing</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#2196f3', border: 0 }}
      extra={<>{isPermissionExist([396]) && (<Button
        onClick={() => { setGridDrawer(true); setDummyRefresh(dummyRefresh + 1); }}
        className='panel_button'
      >View</Button>)}</>

      }>
      {isPermissionExist([395]) && (
      <Form form={formRef} initialValues={formValues} autoComplete='off' layout='vertical'>


        <Row gutter={16}>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item label="Business Number" rules={[{ required: false, }]}
              name="businessNo">
              <Select
                onChange={(value) => getRecentUnCostedBargingBarges(formRef.getFieldValue('costingId'), value)}
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Business Number'>
                {businessDropDown.map(pmtMode => {
                  return <Option value={pmtMode.bnId}>{pmtMode.businessName}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item name='barges' label='Select Barge' >
              <Select
                allowClear showSearch placeholder='Select Barge'
                mode='multiple'
                onSelect={(value) => onSelect(value)}
                onDeselect={(value) => onDeselect(value)}
                value={filterValues}
                style={{ width: '100%', minWidth: 200 }}
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
              >
                {dropDown.map((rec) => {
                  return <Option value={rec.bgId}>{SequenceUtils.formatNumberToSpecificLength(rec.bargeId) + ' - ' + rec.bargeNomination}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }}>
            <Form.Item label={'Date'} name='costingDate'>
              <DatePicker defaultValue={moment()} />
            </Form.Item>
          </Col>
          <Form.Item name={"costingId"} style={{ display: 'none' }}>
            <Input hidden />
          </Form.Item>
          <Form.Item name={"costingNo"} style={{ display: 'none' }}>
            <Input hidden />
          </Form.Item>
        </Row>
        {/* <Row justify='space-between'>
              <Col>
                <Form.Item label={<b>supplier</b>} >
                  {formValues?.coalSupplier}
                </Form.Item>
                <Form.Item name='coalBpId' style={{ display: 'none' }}>
                  <Input hidden />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name='bargeSupplier' label={<b>Barges Supplier</b>}>
                  {formValues?.bargeSupplier}
                </Form.Item>
                <Form.Item name='bargeBpId' style={{ display: 'none' }}>
                  <Input hidden />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name='deadFrightApplicability' label={<b>Dead Fright</b>}>
                  <Input hidden />
                  {formValues?.deadFreightApplicability}
                </Form.Item>
              </Col>
            </Row> */}

        <Form.List name="bargeCostings">
          {(particulars, { add, remove }) => {
            return <Table
              className='contracts'
              bordered
              columns={columns}
              dataSource={particulars}
              pagination={false}
              summary={(pageData: any[]) => {
                return (
                  <></>
                );
              }}
            />;
          }}
        </Form.List>
        <br />
        {formRef.getFieldValue('barges')?.length > 0 &&
          <Row>
            <Col xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}>
              <Card
                className='default-card-class'
                title={'Advance Adjustment'}
              >
                <BargeAdvanceAdjustments
                  bargeSelectionRowKeys={formRef.getFieldValue('barges')}
                  formRef={formRef}
                  bargeVendor={formRef.getFieldValue('bargeBpId')}
                  totalInvoice={formRef.getFieldValue('totalAmount')}
                  setAdjustedData={setAdjustedData}
                  adjustedData={adjustedData}
                  setInvoiceContracts={setInvoiceContracts}
                  invoiceContracts={invoiceContracts}


                />
              </Card>
            </Col>

            <Col xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}>
              <Card
                className='default-card-class'
                title={'Price Adjustment'}
              >
                <Row>
                  <Col span={1}> </Col>
                  <Col span={16}>
                    <Descriptions >
                      <Descriptions.Item label={'Coal Supplier'}>{<span  ><b> {formValues?.coalSupplier}</b></span>}</Descriptions.Item>
                    </Descriptions>
                  </Col>

                </Row>
                <Row>
                  <Col span={1}> </Col>
                  <Col span={16}>
                    <Descriptions >
                      <Descriptions.Item label={'Barge Supplier'}>{<span  ><b> {formValues?.bargeSupplier}</b></span>}</Descriptions.Item>
                    </Descriptions>
                  </Col>

                </Row>
                <Row>
                  <Col span={1}> </Col>
                  <Col span={16}>
                    <Descriptions >
                      <Form.Item name='deadFrightApplicability' label={<b>Dead Fright</b>}>
                        <Input hidden />
                      </Form.Item>
                      <Descriptions.Item>{<span  ><b> {formValues?.deadFreightApplicability}</b></span>}</Descriptions.Item>
                    </Descriptions>
                  </Col>

                </Row>

                <Row>
                  <Col span={5} offset={1}>
                    <b>Total</b>
                  </Col>
                  <Col span={14} offset={4}>
                    <Text>{getLocalFormat(formValues?.costingsTotal, formValues?.billingCurrency)}</Text>
                  </Col>

                </Row>
                <br />

                <Row>
                  <Col span={7} offset={1}>
                    <Form.Item name='vat' valuePropName="checked" label={<b>Vat Applicable(Y/N)&nbsp;&nbsp;</b>}>
                      <Checkbox onChange={vatOnchange} />
                    </Form.Item>
                  </Col>
                  <Col span={14} offset={2}>
                    <Text>{getLocalFormat(formValues?.taxTotal, formValues?.billingCurrency)}</Text>
                  </Col>

                </Row>
                <Row>
                  <Col span={7} offset={1}>
                    <Form.Item name='tdsId' label={<b>TDS&nbsp;&nbsp;</b>}>
                      <Select
                        onChange={tdsOnchange}
                        placeholder='Select TDS'
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                      >
                        <Option value={null}>Not Applicable</Option>
                        {tdsModel?.map((rec) => {
                          return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={14} offset={2}>
                    <Text>{getLocalFormat(formValues?.tdsTotal, formValues?.billingCurrency)}</Text>
                  </Col>

                </Row>
                <br></br>

                <Row>
                  <Col span={7} offset={1}>
                    <Form.Item label={'Amount'}></Form.Item>
                  </Col>
                  <Col span={14} offset={2}>
                    <Form.Item name={'totalAmount'}>
                      <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
                      </InputNumber>
                    </Form.Item>
                  </Col>

                </Row>
                <Row>
                  <Col span={7} offset={1}>
                    <Form.Item label={'Adjusted Against Advances'}></Form.Item>
                  </Col>
                  <Col span={14} offset={2}>
                    <Form.Item name={'totalAdjustedValue'}>
                      <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
                      </InputNumber>
                    </Form.Item>
                  </Col>

                </Row>
                <Row>
                  <Col span={7} offset={1}>
                    <Form.Item label={'To be paid to Supplier'}></Form.Item>
                  </Col>
                  <Col span={14} offset={2}>
                    <Form.Item name={'remainingBalance'}>
                      <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
                      </InputNumber>
                    </Form.Item>
                  </Col>

                </Row>
                <Row>
                  {/* <Form.Item
                  label="Work Log"
                  name='remarks'
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                    },
                    // ...classValidator.alphaNumericSpecialCharsWithSpaces(),
                    {
                      pattern: /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{}|\\:;"'<>,.?/~`-]+$/, // Add the regex pattern to accept special characters.
                      message: 'Invalid characters in Work Log',
                    },
                  ]}
                >
                  <Input style={{ width: '100%' }} onChange={() => workLogOnChange()} />
                </Form.Item> */}
                  <Col span={7} offset={1}>
                    <Form.Item label={'Work Log'}></Form.Item>
                  </Col>
                  <Col span={14} offset={2}>
                    <Form.Item
                      name='remarks'
                      style={{ width: '100%' }}
                      rules={[
                        {
                          required: false,
                          message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                        },
                        // ...classValidator.alphaNumericSpecialCharsWithSpaces(),
                        {
                          pattern: /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{}|\\:;"'<>,.?/~`-]+$/, // Add the regex pattern to accept special characters.
                          message: 'Invalid characters in Work Log',
                        },
                      ]}
                    >
                      <TextArea rows={2} style={{ width: '150%' }} onChange={() => workLogOnChange()} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className='pr-2'>
                  <Col span={15}>
                    <Form.Item name='filesData' label="Upload The Invoice">
                      <Upload
                        multiple={false}
                        fileList={fileList}
                        listType='text'
                        maxCount={1}
                        customRequest={onFileChange}
                        onRemove={() => setFileList([])}
                        showUploadList>
                        <Button icon={<UploadOutlined />}>Choose File</Button>
                      </Upload>
                    </Form.Item>
                  </Col>

                </Row>

                <Row className='pt-2 pr-2' justify='space-between'>
                  <Col>
                  </Col>
                  <Col>
                    <Button type='primary' onClick={bargeCostingSave}>Submit For Approval</Button>        </Col>
                </Row>

              </Card>

            </Col>

          </Row>
        }

        {/* <Row>
              <Col span={16}>
                <Form.Item name='filesData' label="Upload The Invoice"  >
                  <Upload
                    multiple={false}
                    fileList={fileList}
                    listType='text'
                    maxCount={1}
                    customRequest={onFileChange}
                    onRemove={() => setFileList([])}
                    showUploadList>
                    <Button icon={<UploadOutlined />}>Choose File</Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Row justify='space-between'><Col>
                  <Form.Item name={"vat"} valuePropName="checked" >
                    <Checkbox onChange={vatOnchange}>Vat Applicable(Y/N)</Checkbox>
                  </Form.Item ></Col><Col><Text>{getLocalFormat(formValues?.taxTotal, formValues?.billingCurrency)}</Text></Col></Row>
              </Col>
            </Row>
            <Row>
              <Col span={16}>
                <Row>
                  <Col span={3}>Worklog :</Col>
                  <Col span={20}>
                    {formValues?.workLog?.length > 0 && <List
                      bordered
                      dataSource={formValues?.workLog ? formValues?.workLog : []}
                      renderItem={(item, index) => (
                        <List.Item key={index} style={{ width: "100%" }}>
                          <div><span>{index + 1}</span><span>.</span>&nbsp;{item.workLog}</div>
                        </List.Item>
                      )}
                    />}

                  </Col>
                </Row>

              </Col>
              <Col span={8}>
                <Row justify='space-between'><Col style={{ float: 'right' }}>
                  <Form.Item name='tdsId' label={<b>TDS</b>}>
                    <Select
                      onChange={tdsOnchange}
                      placeholder='Select TDS'
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                    >
                      <Option value={null}>Not Applicable</Option>
                      {tdsModel?.map((rec) => {
                        return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
                      })}
                    </Select>
                  </Form.Item></Col><Col><Text>{getLocalFormat(formValues?.tdsTotal, formValues?.billingCurrency)}</Text></Col></Row>
              </Col>
            </Row> */}
        {/* <Row>
              <Col span={8}>
                <Row justify='space-between'><Col style={{ float: 'right' }}><b>Total Amount</b></Col><Col><Text>{getLocalFormat(formValues?.totalAmount, formValues?.billingCurrency)}</Text></Col></Row>
              </Col>
            </Row>
            <Row className='pt-2' justify='space-between'>
              <Col>
              </Col>
              <Col>
                <Button type='primary' onClick={bargeCostingSave}>Submit For Approval</Button>
              </Col>
            </Row> */}
      </Form>)}

      <Drawer
        open={gridDrawer}
        onClose={() => setGridDrawer(false)}
        key={dummyRefresh}
        width={'65%'}

      >
        <BargeCostingsList setEditDataToForm={setEditDataToForm} paramsId={paramsId} accessId={accessId}/>

      </Drawer>


    </Card>
  )
}

export default BargeCostings;