export class AshModel {
    ashSio2Basis: string;
    ashSio2Typical: number;
    ashSio2Rejection: number;
    ashAi203Basis: string;
    ashAi203Typical: number;
    ashAi203Rejection: number;
    ashFe203Basis: string;
    ashFe203Typical: number;
    ashFe203Rejection: number;
    ashCaoBasis: string;
    ashCaoTypical: number;
    ashCaoRejection: number;
    ashMgoBasis: string;
    ashMgoTypical: number;
    ashMgoRejection: number;
    ashNa2oBasis: string;
    ashNa2oTypical: number;
    ashNa2oRejection: number;
    ashK2oBasis: string;
    ashK2oTypical: number;
    ashK2oRejection: number;
    ashTio2Basis: string;
    ashTio2Typical: number;
    ashTio2Rejection: number;
    ashMn3o4Basis: string;
    ashMn3o4Typical: number;
    ashMn3o4Rejection: number;
    ashSo3Basis: string;
    ashSo3Typical: number;
    ashSo3Rejection: number;
    ashP2o5Basis: string;
    ashP2o5Typical: number;
    ashP2o5Rejection: number;
}