import { DeleteFilled, PlusCircleOutlined } from '@ant-design/icons';
import { BPDropDownDto, BpCategoryGetDto, BusinessPartnerService, BusinessPartnerTypeEnum, SamplingAnalysisReqDto, SpecTypesEnum } from '@exportx/shared-models-and-services';
import { SequenceUtils } from '@exportx/ui-utils';
import { Affix, Button, Card, Col, Form, FormInstance, Row, Select, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;
interface ISurveyorDetailsProps {
    formRef: FormInstance<any>;
    initialValues: SamplingAnalysisReqDto;
    children?: React.ReactNode;
    layoutType: FormLayout;
    businessNo?: string;
    setViewForm?: (value: any) => void;
    viewForm?: boolean;
    onResets?: () => void;
    samplingAnalysisSaveData?: () => void;
    setDummyRefresh?: React.Dispatch<React.SetStateAction<number>>
    mappingSurveyors?: any;
}
export const SamplingAndAnalysis = (props: ISurveyorDetailsProps) => {
    const [surveyorDropDownData, setSurveyorDropDownData] = useState<BPDropDownDto[]>([]);
    const { formatMessage: fm } = useIntl();
    let pathToreDirect = '/barge-detail-view';
    const navigate = useNavigate();
    const { formRef, layoutType, initialValues, businessNo, setViewForm, viewForm, samplingAnalysisSaveData, onResets, mappingSurveyors } = props;
    const businessPartnerService = new BusinessPartnerService();
    const [empty, setEmpty] = useState<string>('Create');


    useEffect(() => {
        getAllSurveyorDropDown();
        onReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [businessNo])


    useEffect(() => {
        if (initialValues) {
            formRef.setFieldsValue(initialValues)
        };
        buttonName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues])

    const onReset = () => {
        formRef.resetFields()
    }

    const getAllSurveyorDropDown = () => {
        businessPartnerService.getAllSurveyorDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR)).then(res => {
            if (res.status) {
                setSurveyorDropDownData(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }
    const redirectToFullView = (bargeId: number) => {
        navigate(pathToreDirect, { state: { bargeId } });
    }

    const columns: ColumnsType<any> = [
        {
            title: 'bargeId',
            dataIndex: "bargeId",
            render: (text, record) => {
                const link = `/#/barge-detail-view?barge_id=${record.id}`;
                return <>
                    <a href={link} className="link-primary"
                        onClick={() => redirectToFullView(record.id)}>
                        {SequenceUtils.formatNumberToSpecificLength(record.bargeId)}
                    </a>
                </>
            }
        },

        {
            title: 'Barge Nomination',
            dataIndex: 'bargeNomination'
        },
        {
            title: 'Internal Surveyor',
            dataIndex: 'internalSurveyor'
        },
        {
            title: 'Internal SurveyorAnalysis Type',
            dataIndex: 'internalSurveyorAnalysisType'
        },
        {
            title: 'Supplier Surveyor',
            dataIndex: 'supplierSurveyor'
        },
        {
            title: 'Supplier SurveyorAnalysis Type',
            dataIndex: 'supplierSurveyorAnalysisType'
        },
        {
            title: 'Additional Surveyor',
            dataIndex: 'additionalSurveyor'
        },
        {
            title: 'Additional Analysis Type',
            dataIndex: 'additionalAnalysisType'
        },
        {
            title: 'Additional Surveyor1',
            dataIndex: 'additionalSurveyor1'
        },
        {
            title: 'Additional Analysis Type1',
            dataIndex: 'additionalAnalysisType1'
        },
        {
            title: 'Joint Surveyor',
            dataIndex: 'jointSurveyor'
        },
        {
            title: 'Joint Surveyor Analysis Type',
            dataIndex: 'jointSurveyorAnalysisType'
        },
    ];

    const buttonName = () => {
        Object.keys(initialValues).forEach((keys) => {
            Array(initialValues).forEach((rec) => {
                if (rec[keys] !== null) {
                    if (keys !== 'businessNo') {
                        if (keys === 'witnessAnalysis') {
                            rec[keys].forEach((witRecord) => {
                                Object.keys(witRecord).forEach((witKeys) => {
                                    if (witRecord[witKeys] !== null) {
                                        setEmpty('Edit');
                                    };
                                });
                            });
                        } else if (rec[keys]) {
                            setEmpty('Edit');
                        };
                    };
                };
            });
        });
    };

    return (

        <>


            {viewForm ?
                <Form
                    layout={layoutType}
                    form={formRef}
                    initialValues={initialValues}
                    autoComplete="off"
                >

                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                            <Form.Item label={fm({ id: 'mdm.components.surveyorForm.suppliersureyorname', defaultMessage: " SupplierSureyor Name" })} name="supplierSurveyor">
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                    allowClear showSearch placeholder="Please Select" >
                                    <Option value={''}>Please Select</Option>
                                    {surveyorDropDownData.map(item => {
                                        return <Option value={item.bpId}>{item.bpName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                            <Form.Item label={fm({ id: 'mdm.components.surveyorForm.supplierSurveyorAnalysis', defaultMessage: " Analysis Type" })} name="supplierSurveyorAnalysisType">
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                    allowClear showSearch placeholder="Please Select">
                                    <Option value={''}>Please Select</Option>
                                    {Object.keys(SpecTypesEnum).map(contractTermKey => {
                                        return <Option value={SpecTypesEnum[contractTermKey]}>{SpecTypesEnum[contractTermKey]}</Option>
                                    })}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 4 }}>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                            <Form.Item label={fm({ id: 'mdm.components.surveyorForm.srplsurveyorname', defaultMessage: "Srpl Surveyor Name " })} name="internalSurveyor">
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                    allowClear showSearch placeholder="Please Select">
                                    <Option value={''}>Please Select</Option>
                                    {surveyorDropDownData.map(item => {
                                        return <Option value={item.bpId}>{item.bpName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                            <Form.Item label={fm({ id: 'mdm.components.surveyorForm.srplsurveyoranalysis', defaultMessage: "Analysis Type " })} name="internalSurveyorAnalysisType">
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                    allowClear showSearch placeholder="Please Select">
                                    <Option value={''}>Please Select</Option>
                                    {Object.keys(SpecTypesEnum).map(contractTermKey => {
                                        return <Option value={SpecTypesEnum[contractTermKey]}>{SpecTypesEnum[contractTermKey]}</Option>
                                    })}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 4 }}>

                        </Col>

                    </Row>
                    <Row>

                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 3 }}>
                            <Form.Item label={fm({ id: 'mdm.components.surveyorForm.buyerAdditionalSurveyorName', defaultMessage: "Witness Surveyor Name" })} name="witnessSurveyor">
                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                    allowClear showSearch placeholder="Please Select">
                                    <Option value={''}>Please Select</Option>
                                    {surveyorDropDownData.map(item => {
                                        return <Option value={item.bpId}>{item.bpName}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>



                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
                            <Form.Item label={fm({ id: 'mdm.components.surveyorForm.buyerAdditionalSurveyorAnalysis', defaultMessage: "Analysis Type" })} name="jointSurveyorAnalysisType">

                                <Select
                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}

                                    placeholder="Please Select"
                                >
                                    <Option value={''}>Please Select</Option>
                                    {Object.keys(SpecTypesEnum).map(contractTermKey => {
                                        return <Option value={SpecTypesEnum[contractTermKey]}>{SpecTypesEnum[contractTermKey]}</Option>
                                    })}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4, offset: 4 }}>

                        </Col>
                    </Row>

                    <Form.List name='witnessAnalysis' >

                        {(fields, { add, remove }) => (
                            <>

                                {fields.map(({ key, name, }, index) => {

                                    return <Row>

                                        <Col span={8} offset={3} >
                                            <Form.Item label={fm({ id: 'mdm.components.surveyorForm.buyerAdditionalSurveyorName', defaultMessage: "Buyer additional Surveyor Name" })} name={[name, "additionalSurveyor"]}>
                                                <Select
                                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                                    showSearch
                                                    allowClear
                                                    placeholder="Please Select"
                                                    style={{ width: 190 }}
                                                >
                                                    <Option value={''}>Please Select</Option>
                                                    {surveyorDropDownData.map(item => {
                                                        return <Option value={item.bpId}>{item.bpName}</Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item label={fm({ id: 'mdm.components.surveyorForm.buyerAdditionalSurveyorAnalysis', defaultMessage: "Analysis Type" })} name={[name, "witnessSurveyorAnalysisType"]}>
                                                <Select
                                                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                                    allowClear showSearch style={{ width: 190 }} placeholder="Please Select">
                                                    <Option value={''}>Please Select</Option>
                                                    {Object.keys(SpecTypesEnum).map(contractTermKey => {
                                                        return <Option value={SpecTypesEnum[contractTermKey]}>{SpecTypesEnum[contractTermKey]}</Option>
                                                    })}
                                                </Select>

                                            </Form.Item>
                                        </Col>

                                        <Col xs={{ span: 2 }}
                                            sm={{ span: 2 }}
                                            md={{ span: 2 }}
                                            lg={{ span: 2 }}
                                            xl={{ span: 2 }}>
                                            {index !== 0 &&
                                                <span style={{
                                                    position: "absolute",
                                                    marginTop: "33px",
                                                    marginLeft: "150px",
                                                }}>
                                                    <Tooltip placement="top" title={fm({ id: "common.delete" })}>
                                                        <DeleteFilled style={{ color: '#f70404', fontSize: '21px', float: 'right' }} onClick={() => remove(name)} />
                                                    </Tooltip>
                                                </span>
                                            }
                                            {((fields.length - 1 === index) && fields.length !== 3) && <span style={{
                                                position: "absolute",
                                                marginTop: "33px",
                                                marginLeft: "125px"
                                            }}>
                                                <Tooltip placement="top" title={fm({ id: "common.add", defaultMessage: 'Add' })} >
                                                    <PlusCircleOutlined key={Date.now()} onClick={() => { add(); }} />
                                                </Tooltip>
                                            </span>}


                                        </Col>

                                    </Row>

                                }

                                )}
                            </>
                        )}


                    </Form.List>
                    <Affix offsetBottom={0}>
                        <Card bodyStyle={{ padding: '6px 12px' }}>
                            <Row justify='space-between'>
                                <Button type='primary' ghost onClick={onResets}>Clear</Button>&nbsp;
                                <Button type='primary' onClick={samplingAnalysisSaveData}>Submit</Button>
                            </Row>
                        </Card>
                    </Affix>
                    <span style={{ float: 'right' }}>

                    </span>


                    {props?.children}
                </Form>

                :

                <>
                    <Card>
                        <Button style={{ float: 'right', display: `${viewForm ? 'none' : ''}` }} onClick={() => { setViewForm(true); }} type='primary'>{empty}</Button>

                        <br />
                        <br />

                      
                        <Card title={'Sampling and analysis Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        <table className='table table-sm table-bordered' >
                            <tr>
                                <th></th>
                                <th>Surveyor Name</th>
                                <th>Analysis Type</th>
                            </tr>
                            <tr>
                                <td>
                                    Supplier Surveyor
                                </td>
                                <td>
                                    {initialValues?.supplierSurveyorName}
                                </td>
                                <td>
                                    {initialValues?.supplierSurveyorAnalysisType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                SRPL Surveyor Name
                                </td>
                                <td>
                                    {initialValues?.internalSurveyorName}
                                </td>
                                <td>
                                    {initialValues?.internalSurveyorAnalysisType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Witness Surveyor
                                </td>
                                <td>
                                    {initialValues?.witnessSurveyorName}
                                </td>
                                <td>
                                    {initialValues?.jointSurveyorAnalysisType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Buyer additional Surveyor Name
                                </td>

                                <td>
                                    {initialValues?.witnessAnalysis[0]?.additionalSurveyorName}
                                </td>
                                <td>
                                    {initialValues?.witnessAnalysis[0]?.witnessSurveyorAnalysisType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Buyer additional Surveyor Name1
                                </td>
                                <td>
                                    {initialValues?.witnessAnalysis[1]?.additionalSurveyorName}
                                </td>
                                <td>
                                    {initialValues?.witnessAnalysis[1]?.witnessSurveyorAnalysisType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Buyer additional Surveyor Name2
                                </td>
                                <td>
                                    {initialValues?.witnessAnalysis[2]?.additionalSurveyorName}
                                </td>
                                <td>
                                    {initialValues?.witnessAnalysis[2]?.witnessSurveyorAnalysisType}
                                </td>
                            </tr>
                        </table>
                    </Card>
                    </Card>

                    <Card>

                        <Table className='contracts' columns={columns} dataSource={mappingSurveyors} pagination={false} scroll={{ x: 1300 }}></Table>

                    </Card>
                </>}


        </>

    )
}
export default SamplingAndAnalysis