import { ContractStatusEnum, ProcurementContractTypeEnum } from '@exportx/shared-models-and-services';
import { Button, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { isPermissionExist } from '../iam-client-react';

interface IProps {
  status: ContractStatusEnum;
  statusApproval: (status: ContractStatusEnum, id: string) => void;
  id: string;
  from?: string
}



const StatusApprovalsButton = (props: IProps) => {
  const [buttonName, setButtonName] = useState<string>()
  const { status, statusApproval, id } = props;

  useEffect(() => {
    buttonsHandler();
  }, [status])


  const buttonsHandler = () => {
    setButtonName(getStatus(status).buttonName);
  };

  const getStatus = (status: ContractStatusEnum) => {
    const obj = {
      [ContractStatusEnum.DRAFT]: ContractStatusEnum.PENDING_APPROVAL,
      [ContractStatusEnum.PENDING_APPROVAL]: ContractStatusEnum.ACTIVE,
      [ContractStatusEnum.ACTIVE]: ContractStatusEnum.DRAFT,
    };
    const buttonName = {
      [ContractStatusEnum.DRAFT]: ContractStatusEnum.PENDING_APPROVAL,
      [ContractStatusEnum.PENDING_APPROVAL]: 'Approve',
    }
    return { status: obj[status], buttonName: buttonName[status] };
  }

  const PermissionExist = (type: string) => {
    if (props?.from === ProcurementContractTypeEnum.Procurement_Fees) {
      return type === 'Approve' ? isPermissionExist([151]) : isPermissionExist([152])
    } else if (props?.from === ProcurementContractTypeEnum.Marketing_Fees) {
      return type === 'Approve' ? isPermissionExist([169]) : isPermissionExist([170])
    }
    else if (props?.from === ProcurementContractTypeEnum?.Coal_Service_Fees) {
      return type === 'Approve' ? isPermissionExist([160]) : isPermissionExist([161])

    }
    return true

  }

  return <div style={{display: 'flex'}}>

    {PermissionExist('Approve') && status !== ContractStatusEnum.ACTIVE && (
      <Button
        // type="primary"
        size='small'
        className='approve'
        // disabled={status === ContractStatusEnum.ACTIVE}
        onClick={() => statusApproval(getStatus(status).status, id)}
      >
        {buttonName ? buttonName : getStatus(ContractStatusEnum.PENDING_APPROVAL).buttonName}
      </Button>
    )
    }
    &nbsp;&nbsp;
    {PermissionExist('Reject') && status === ContractStatusEnum.PENDING_APPROVAL && (

      <Button
        // type="primary"
        // danger
        size='small'
        className='reject'
        // disabled={status === ContractStatusEnum.ACTIVE || status === ContractStatusEnum.DRAFT}
        onClick={() => statusApproval(getStatus(ContractStatusEnum.ACTIVE).status, id)}
      >
        Reject
      </Button>
    )
    }

  </div>
}

export default StatusApprovalsButton


