import { getLocalFormat } from "@exportx/ui-utils"
import Table, { ColumnsType } from "antd/es/table"
import moment from "moment"
import { useState } from "react"
import { useAuthState } from "../../../../common"
import BargePaymentNoticeView from "./barge-payment-notice-view"
import { UpCircleFilled, DownCircleFilled } from '@ant-design/icons';

const BargePaymentNotice =()=>{
    const { authContext } = useAuthState()
    const [expandedIndex, setExpandedIndex] = useState([]);
    const [bargeCosting, setBargeCosting] = useState<any[]>([]);
    const columns:ColumnsType<any>=[
        {
            title: 'Barge Costing',
            dataIndex: 'costingNo'
        },
        {
            title: 'Date',
            dataIndex: 'costingDate',
            render: (value, record) => {
                return <>{moment(value).format('YYYY-MM-DD')}</>
            }
        },
        {
            title: 'Business',
            dataIndex: 'businessNo'
        },
        {
            title: 'Supplier Name',
            dataIndex: 'bpName'
        },
        {
            title: 'Amount To Be Paid',
            dataIndex: 'amountToBePaid',
            align: 'right',
            render :(value,record)=>{
                return<>{getLocalFormat(value, authContext.defaultPlantCurrency)}</>
            }
           
        }
    ]
    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.bpfCostingIid);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    }
    const renderItems = (record: any, index, indent, expanded) => {
        console.log(record)
        return <>
            <BargePaymentNoticeView costingData={record.detailsData} tableData={record} />
        </>
    };

    
return(
    <Table 
    
    expandIconColumnIndex={0}
    className='contracts'
    rowKey={record => record.id}
    columns={columns}
    dataSource={null}
    
    expandable={{
        expandedRowRender: renderItems,
        expandedRowKeys: expandedIndex,
        onExpand: setIndex,
        fixed: 'right'
    }}
    expandIcon={({ expanded, onExpand, record }) =>
    expanded ? (
        <UpCircleFilled
            onClick={(e) => onExpand(record, e)}
        >
            Collapse
        </UpCircleFilled>
    ) : (
        <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
    )
}
pagination={{
    onChange(current) {
        setExpandedIndex([]);
    }
}}
scroll={{ x: 500 }}
size="large"
bordered/>

)
}
export default BargePaymentNotice