import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

    row: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 4,
        fontSize: 8,
        fontStyle: 'bold',
    },
    bargeName: {
        width: '10%',
        fontSize: 8,
        textAlign: 'center',

    },
    mvName: {
        width: '10%',
        fontSize: 8,
        // borderRightColor: '#333333',
        // borderRightWidth: 0.4,
        textAlign: 'center',

    },
    pcName: {
        width: '10%',
        fontSize: 8,
        // borderRightColor: '#333333',
        // borderRightWidth: 0.4,
        textAlign: 'center',

    },
    quality: {
        width: '10%',
        fontSize: 8,
        // borderRightColor: '#333333',
        // borderRightWidth: 0.4,
        textAlign: 'center',

    },
    grade: {
        width: '10%',
        fontSize: 8,
        // borderRightColor: '#333333',
        //borderRightWidth: 0.4,
        textAlign: 'center',

    },
    rate: {
        width: '50%',
        fontSize: 8,
        textAlign: 'center',
        paddingRight: 8,
    },
});


const InvoiceQualityResults = ({ results }) => {

    const rows = results?.map(item =>

        <View style={styles.row}>
            <Text style={styles.bargeName}>{item.type ? item.type : " "}</Text>
            <Text style={styles.mvName}>{item.basis ? item.basis : " "}</Text>
            <Text style={styles.pcName}>{item.typical ? item?.typical : " "}</Text>
            <Text style={styles.quality}>{item.rejection ? item?.rejection : " "}</Text>
            <Text style={styles.grade}>{item.actual ? item.actual : " "}</Text>
            <Text style={styles.rate}>{item.desc ? item.desc : " "}</Text>
        </View>
    )


    return (

        <Fragment>
            <Text style={{ marginTop: 8, marginBottom: 8, paddingLeft: 5 }}>Penality / Bonus </Text>
            <View style={[styles.row, { borderWidth: 0.4, borderColor: '#333333' }]}>
                <Text style={styles.bargeName}>Type</Text>
                <Text style={styles.mvName}>Basis</Text>
                <Text style={styles.pcName}>Typical</Text>
                <Text style={styles.quality}>Rejection</Text>
                <Text style={styles.quality}>Actual</Text>
                <Text style={styles.rate}>Bonus / Penality</Text>
            </View>
            {rows}

        </Fragment>
    )
}

export default InvoiceQualityResults