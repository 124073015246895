import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { SequenceUtils } from '@exportx/ui-utils';
import { BargeCostingVouchersParentDto, VouchersBargeDataDto } from '@exportx/shared-models-and-services';



interface InvoiceTitleIProps {
    invoice: any;
    demurrageName: string;
    costingName: string;
    purchaseType: string;
    purchaseIndexLinked: string;
}


const borderColor = 'black'


const styles = StyleSheet.create({

    row: {
        flexDirection: 'row',
        // borderColor: borderColor,
        borderBottomWidth: 0.4,
        borderBottomColor: "black",
        borderTop: 0.4,
        borderTopColor: "black",
        alignItems: 'center',
        marginTop: 10,
        fontStyle: 'bold',
    },
    bargeName: {
        width: '15%',
        fontSize: 8,
        textAlign: 'center',

        padding: 4,
    },
    bargeName1: {
        width: '15%',
        fontSize: 8,
        textAlign: 'center',
        minHeight: 35,
        paddingTop: 8,

        alignSelf: 'center'
    },
    bargeNomination: {
        width: '25%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        borderLeft: 0.4,
        padding: 4,
        borderRightColor: "black",

    },
    bargeNomination1: {
        width: '25%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        borderLeft: 0.4,
        // padding: 4,
        borderRightColor: "black",
        // height: 10,
        minHeight: 35,
        paddingTop: 8,
        // padding: 4,

    },
    mvName: {
        width: '15%',
        fontSize: 8,
        textAlign: 'center',
        // borderRight: 0.4,
        padding: 4,
        borderRightColor: "black",
    },
    mvName1: {
        width: '15%',
        fontSize: 8,
        textAlign: 'center',
        // borderRight: 0.4,
        // padding: 4,
        borderRightColor: "black",
        // height: 10,
        minHeight: 35,
        paddingTop: 8,
        // padding: 4,

    },
    pcName: {
        width: '20%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        padding: 4,
        borderRightColor: "black",
        // borderColor: '#000000'
        borderTop: 0,
        borderTopColor: 'black',
        borderLeftColor: 'black',
        borderLeft: 0.4,
    },
    pcName1: {
        width: '20%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        // padding: 4,
        borderRightColor: "black",
        // borderColor: '#000000'
        borderTop: 0,
        borderTopColor: 'black',
        borderLeftColor: 'black',
        borderLeft: 0.4,
        minHeight: 35,
        paddingTop: 8,
        // padding: 4,

    },

    quality: {
        width: '10%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        padding: 4,
        borderRightColor: 'black',
        // borderColor: '#000000'
    },
    quality1: {
        width: '10%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        // padding: 4,
        borderRightColor: 'black',
        minHeight: 35,
        paddingTop: 8,
        // padding: 4,

    },
    vendor: {
        width: '20%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        padding: 4,
        borderRightColor: 'black',
        // borderColor: '#000000'
    },
    vendor1: {
        width: '20%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        // padding: 4,
        borderRightColor: 'black',
        minHeight: 35,
        paddingTop: 8,
        // padding: 4,
        // borderColor: '#000000'
    },
    grade: {
        width: '13%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        padding: 4,
        borderRightColor: borderColor,
    },
    grade1: {
        width: '13%',
        fontSize: 8,
        textAlign: 'center',
        borderRight: 0.4,
        // padding: 4,
        borderRightColor: borderColor,
        minHeight: 35,
        paddingTop: 8,
        // padding: 4,
    },
    rate: {
        width: '13%',
        fontSize: 8,
        textAlign: 'center',
        // borderRight: 0.4,
        padding: 4,
        // borderRightColor: borderColor,
    },
    rate1: {
        width: '13%',
        fontSize: 8,
        textAlign: 'center',
        minHeight: 35,
        paddingTop: 8,
        // padding: 4,

    },
});

const InvoiceTitle = (props: InvoiceTitleIProps) => {
    const { purchaseType, purchaseIndexLinked = "No", costingName, invoice } = props;
    console.log(invoice, "invoice")

    const rows = invoice?.bargeVouchers.map((item: VouchersBargeDataDto) =>
        <View style={{ flexDirection: 'row', alignItems: 'center', }} key={item.bargeId}>
            {(purchaseType === "FOB BARGE" || purchaseType === "CPS FOB BARGE") &&
                <Text style={styles.bargeName1}>{SequenceUtils?.formatNumberToSpecificLength(item?.bargeId?.toString())}</Text>
            }
            {(purchaseType === "FOB BARGE" || purchaseType === "CPS FOB BARGE") &&
                <Text style={styles.bargeNomination1}>{item.bargeNomination ? item.bargeNomination : "-"}</Text>
            }
            {(purchaseType === "FOB BARGE" || purchaseType === "CPS FOB BARGE") &&
                <Text style={styles.mvName1}>{item.loadingJetty ? item.loadingJetty : "-"}</Text>
            }
            <Text style={styles.pcName1}>{item.contractNo}</Text>

            {/* {purchaseType === "CPS FOB BARGE" && <Text style={styles.vendor1}>{item.paymentToVendor}</Text>} */}

            <Text style={styles.quality1}>{item.quality}</Text>
            <Text style={styles.grade1}>{purchaseType}</Text>
            {purchaseIndexLinked !== 'yes' ?
                costingName !== "Export Tax" &&
                <Text style={styles.rate1}>
                    {item.bargingBasePrice ? item.bargingBasePrice : ''}
                    {item.bargingBasePrice && item.bargingBasePrice !== '' && item.bargingBasePrice !== null
                        ? parseFloat(item.bargingBasePrice).toFixed(2) : ''}
                    {purchaseType === "CPS FOB BARGE" && item.bargingBasePrice
                        ? parseFloat(item.bargingBasePrice).toFixed(2) : ''}
                </Text>
                :
                <Text style={styles.rate1}>Index Linked</Text>
            }

        </View>
    )

    return (

        <Fragment>
            <View style={styles.row}>
                {(purchaseType === "FOB BARGE" || purchaseType === "CPS FOB BARGE") &&
                    <Text style={styles.bargeName}>Barge ID</Text>
                }
                {(purchaseType === "FOB BARGE" || purchaseType === "CPS FOB BARGE") &&
                    <Text style={styles.bargeNomination}>Barge Name</Text>
                }
                {(purchaseType === "FOB BARGE" || purchaseType === "CPS FOB BARGE") &&
                    <Text style={styles.mvName}>Loading Jetty</Text>
                }
                <Text style={styles.pcName}>Purchase Contract</Text>

                {purchaseType === "CPS FOB BARGE" &&
                    <Text style={styles.vendor}>Vendor Name</Text>
                }

                <Text style={styles.quality}>Grade</Text>
                <Text style={styles.grade}>Inco Term</Text>
                {costingName !== "Export Tax" && <Text style={styles.rate}>Price PMT</Text>}
            </View>
            {rows}

            <Text style={{
                borderBottom: 0.4, borderBottomColor: '#000000'
            }} />
        </Fragment>

    )
}

export default InvoiceTitle