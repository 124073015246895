export class UltimateModel {
    ultimateCarbonBasis: string;
    ultimateCarbonTypical: number;
    ultimateCarbonRejection: number;

    ultimateHydrogenBasis: string;
    ultimateHydrogenTypical: number;
    ultimateHydrogenRejection: number;
    
    ultimateNitrogenBasis: string;
    ultimateNitrogenTypical: number;
    ultimateNitrogenRejection: number;
}