import { Card, Button, Row, Col, Statistic } from 'antd';
import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';
import { ReactComponent as PCIcon } from '../../common/icons/purchase-contracts.svg';
import { ReactComponent as SCIcon } from '../../common/icons/sales-contract.svg';
import { ReactComponent as BudgetAndCostingIcon } from '../../common/icons/budgeting.svg';
import { TopFiveCustomersBySalesChart } from './top-five-by-sales-chart';
import { TopFiveVendorsByPurchasesChart } from './top-five-by-purchse-chart';
import TopFiveCustomersByReceivable from './top-five-customers-by-receivables';
import TopFiveVendorsByPayable from './top-five-vendors-by-payable';
import { InvoiceStatusTracker } from './invoice-status-tracker';
import { BillStatusTracker } from './bills-status-tracker';

export const LandingPageDashBoard = () => {
    const { formatMessage: fm } = useIntl();
    return (
        <Card
            title={'Dashboard'}
            style={{ overflow: 'auto' }}
            className='default-card-class'
            bodyStyle={{ overflow: 'auto' }}
        >
            <Row justify='space-between'>
                <Col span={6}>
                    <Card>
                        <Statistic title={<Row justify='space-between'><Col><h5>Purchase Contracts</h5></Col><Col><Icon component={PCIcon} style={{ fontSize: '20px' }} /></Col></Row>} value={93} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={<Row justify='space-between'><Col><h5>Sales Contracts</h5></Col><Col><Icon component={SCIcon} style={{ fontSize: '20px' }} /></Col></Row>} value={93} />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic title={<Row justify='space-between'><Col><h5>Business Numbers</h5></Col><Col><Icon component={BudgetAndCostingIcon} style={{ fontSize: '20px' }} /></Col></Row>} value={93} />
                    </Card>
                </Col>
            </Row>

            <Row className='pt-5'>
                <Col span={12}>
                    <TopFiveCustomersBySalesChart />
                </Col>
                <Col span={12}>
                    <TopFiveCustomersByReceivable />
                </Col>
            </Row>

            <Row className='pt-5'>
                <Col span={12}>
                    <TopFiveVendorsByPurchasesChart />
                </Col>
                <Col span={12}>
                    <TopFiveVendorsByPayable />
                </Col>
            </Row>

            <Row className='pt-5'>
                <Col span={12}>
                    <InvoiceStatusTracker />
                </Col>
                <Col span={12}>
                    <BillStatusTracker />
                </Col>
            </Row>

        </Card>
    )
}

export default LandingPageDashBoard;