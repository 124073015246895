import { CountryDeactivateDto, CountryDto, CountryService } from '@exportx/shared-models-and-services';
import { Button, Card } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, FilterableGrid, isPermissionExist, useAuthState } from '../../../../../common';

interface IPropsCountriesGrid {
}

export const CountriesGrid = (props: IPropsCountriesGrid) => {
    const navigate = useNavigate();
    //to access internationalized message intl object 
    const { formatMessage: fm } = useIntl();//Common for any Component that might be Page,Grid,Form.....
    //to access global authorized user data(Login user Data which is passed as created user or updated user)
    const { authContext } = useAuthState();//Common for all Page Components
    //to store Grid data
    const [gridData, setGridData] = useState<CountryDto[]>([]);//This is common for all Page Components But Variable Name May Vary

    //To Access Backend Services Via Shared Services
    const service = new CountryService();
   
    useEffect(()=>{
        if(!isPermissionExist([3])) {
            return navigate('/');
        }
    },[])

    useEffect(() => {
            getGridData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getGridData = () => {
        service.getAllCountries().then(res => {
            if (res.status) {
                setGridData(res.data);
            } else {
                // AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }


    const activateOrDeactivate = (countryData: CountryDto) => {
        const countryDeactivateDto: CountryDeactivateDto = new CountryDeactivateDto(countryData.countryId, authContext.user.userName, countryData.versionFlag);
        service.activateOrDeactivate(countryDeactivateDto).then(res => {
            if (res.status) {
                getGridData();
                AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Country ${countryData.isActive ? ' activated ' : ' deactivated '} SuccessFully` }));
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        });
    }

    const view = (countryData: CountryDto, isUpdate: boolean = false) => {
        let isNewRecord = true;
        if (countryData) {
            isNewRecord = false;
        }
        let pathToreDirect = '/createCountry';
        navigate(pathToreDirect, { state: { countryData, isNewRecord, isUpdate } });
    }


    const columns: ColumnsType<any> = [
        {
            title: fm({ id: "mdm.components.countriesGrid.countryName", defaultMessage: 'Country Name' }),
            dataIndex: 'countryName',
            key: 'countryName',
        },
        {
            title: fm({ id: "mdm.components.countriesGrid.alphaCodetwo", defaultMessage: '  Alpha Code Two' }),
            dataIndex: 'alphaCodetwo',
            key: 'alphaCodetwo',
        },
        {
            title: fm({ id: "mdm.components.countriesGrid.alphaCodethree", defaultMessage: 'Alpha Code Three' }),
            dataIndex: 'alphaCodethree',
            key: 'alphaCodethree',
        },
        {
            title: fm({ id: "mdm.components.countriesGrid.numbericCode", defaultMessage: 'Numeric Code' }),
            dataIndex: 'numbericCode',
            key: 'numbericCode',
            align: "right"
        }
    ];
    return (
        <Card
            title={fm({ id: `countiesGrid.header`, defaultMessage: `Country Details` })}
            style={{ overflow: 'auto' }}
            className='default-card-class'
            extra={<>{isPermissionExist([2]) && (
                <Button
                    onClick={() => view(undefined, false)}
                >
                    {fm({ id: 'common.create', defaultMessage: 'Create' })}
                </Button>
                )}</>
            }
            bodyStyle={{ overflow: 'auto' }}
        >
            <FilterableGrid
                rowKey='countryId'
                mainColumns={columns}
                data={gridData}
                //filterColumnsData={[{ dataIndex: 'countryName', placeHolder: fm({ id: "mdm.components.countiesGrid.countyName", defaultMessage: 'Country Name' }) }]}
                filterColumnsData={[{ dataIndex: 'countryName', placeHolder: fm({ id: "mdm.components.countiesGrid.countyName", defaultMessage: 'Country Name' }) }, { dataIndex: 'alphaCodetwo', placeHolder: fm({ id: "mdm.components.countiesGrid.alphaCodetwo", defaultMessage: 'alphaCodetwo ' }) }, { dataIndex: 'alphaCodethree', placeHolder: fm({ id: "mdm.components.countiesGrid.alphaCodethree", defaultMessage: 'alphaCodethree ' }) }, { dataIndex: 'numbericCode', placeHolder: fm({ id: "mdm.components.countiesGrid.numbericCode", defaultMessage: 'numbericCode ' }) },]}
                otherTableProps={{ bordered: true }} activateOrDeactivate={activateOrDeactivate} view={view}
            /></Card>
    )
}

export default CountriesGrid;