import { BargeCostingsService } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Descriptions, Input, Row, Table } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ColumnsType } from 'antd/lib/table';

interface aaa {
    bargeId: string;
    bargeNomination: string;
    plannedQuantity: string;
    bargingBasePrice: string;
    bargeQuantityInMt: string;
    loadingJetty?: any;
    loadingDate?: any;
    bargingBasePriceCurrency?: any;
    bargingType: string;
    lumpsumPrice?: any;
    lumpsumPriceCurrency?: any;
    supplierDeadFreightValue?: any;
    internalDeadFreightValue?: any;
    vatApplicability?: any;
    deadFreight?: any;
    bargeBpName?: any;
    purchaseType: string;
    bapDeadFreight?: any;
    total: number;
}
interface IBargeCostingApprovalViewProps {
    costingData?: aaa
    tableData?: any
}

const BargePaymentNoticeView = (props: IBargeCostingApprovalViewProps) => {

    const bargeCostingService = new BargeCostingsService()

    const { costingData,tableData } = props;

    const amountAdjustMent: ColumnsType<any> = [
        {
            title: "Advance Ref",
            dataIndex: ""
        },
        {
            title: "Date",
            dataIndex: ""
        },
        {
            title: "Type",
            dataIndex: ""
        },
        {
            title: "Advance Paid",
            dataIndex: ""
        },
        {
            title: "Recovered",
            dataIndex: ""
        },
        {
            title: "Balance",
            dataIndex: ""
        },
        {
            title: "Amount to Adjusted",
            dataIndex: "",

        },


    ]

    const columns: ColumnsType<any> = [
        {
            title: "Barge #",
            dataIndex: ""
        },
        {
            title: "Inward Date",
            dataIndex: ""
        },
        {
            title: "Inward Qty",
            dataIndex: ""
        },
        {
            title: "Dead Qty",
            dataIndex: ""
        },
        {
            title: "Base Frieght",
            dataIndex: ""
        },
        {
            title: "Dead Freight",
            dataIndex: ""
        },
        {
            title: "PPN",
            dataIndex: ""
        },
        {
            title: "PP 15",
            dataIndex: ""
        },
        

        {
            title: "Total",
            dataIndex: "",

        },


    ]

    const bpfCostingStatusApproval = () => {
        // bargeCostingService.bpfCostingStatusApproval().then(res => {

        // })
    }

    return (
        <Card>
            <Card>

                <Row>
                    <Col span={2} offset={1}></Col>
                    <Col span={6} offset={2}>
                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Barges" >{costingData?.bargeBpName}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={6} offset={2}>
                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Supplier" >{costingData?.bargeBpName}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={1} offset={4}></Col>
                </Row>
                <Row>
                    <Col span={2} offset={1}></Col>
                    <Col span={6} offset={2}>
                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Supplier" >{costingData?.bargeBpName}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={6} offset={2}>
                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Barge Supplier" >{costingData?.bargeBpName}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={1} offset={4}>

                    </Col>
                </Row>
                <Table columns={columns} dataSource={null}></Table>
                {/* <Row>
                    <Col span={2} offset={1}></Col>
                    <Col span={6} offset={2}>

                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="inward Qty" >{costingData?.bargeQuantityInMt}</Descriptions.Item>
                        </Descriptions>

                    </Col>
                    <Col span={6} offset={2}>

                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Based Freight" >{Number(costingData?.bargeQuantityInMt) * Number(costingData?.bargingBasePrice)}</Descriptions.Item>
                        </Descriptions>

                    </Col>
                    <Col span={4} offset={1}></Col>
                </Row>
                <Row>
                    <Col span={2} offset={1}></Col>
                    <Col span={6} offset={2}>

                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Dead Qty" >{costingData?.bapDeadFreight}</Descriptions.Item>
                        </Descriptions>

                    </Col>
                    <Col span={6} offset={2}>

                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Dead Freight" >{costingData?.deadFreight}</Descriptions.Item>
                        </Descriptions>

                    </Col>
                    <Col span={4} offset={1}></Col>
                </Row>
                <Row>
                    <Col span={2} offset={1}></Col>
                    <Col span={6} offset={2}></Col>
                    <Col span={6} offset={2}>

                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="PPN" >{Number(costingData?.bargeQuantityInMt) * Number(costingData?.bargingBasePrice) * 0.10}</Descriptions.Item>
                        </Descriptions>

                    </Col>
                    <Col span={4} offset={1}></Col>
                </Row>
                <Row>
                    <Col span={2} offset={1}></Col>
                    <Col span={6} offset={2}></Col>
                    <Col span={6} offset={2}>

                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="PP15" >{Number(costingData?.bargeQuantityInMt) * Number(costingData?.bargingBasePrice) * 0.015}</Descriptions.Item>
                        </Descriptions>

                    </Col>
                    <Col span={4} offset={1}></Col>
                </Row>
                <Row>
                    <Col span={2} offset={1}></Col>
                    <Col span={6} offset={2}></Col>
                    <Col span={6} offset={2}>

                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Total" >{costingData?.total}</Descriptions.Item>
                        </Descriptions>

                    </Col>
                    <Col span={4} offset={1}></Col>
                </Row> */}
<br/>
                <Row>
                <Col span={6} offset={2}></Col>
                <Col span={6} offset={2}></Col>
                <Col span={6} offset={2}>
                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Balance to be paid" >{costingData?.total}</Descriptions.Item>
                        </Descriptions>

                    </Col>
                </Row>


                 <div><b>Amount to Adjusted against Advances</b></div>
                 <br/>

                    <Table columns={amountAdjustMent} dataSource={null}></Table>
                </Card>
                <Row>
                    <Col span={6} offset={2}>
                        <FormItem name='' label="Amount to be paid to Barge Supplier" >
                            <Input type='text'></Input>
                        </FormItem>
                    </Col>
                    <Col span={6} offset={2}></Col>
                    <Col span={6} offset={2}></Col>
                </Row>
                <Row>
                    <Col span={6} offset={2}>

                        <Descriptions layout='horizontal'>
                            <Descriptions.Item label="Balance to be paid" >{ }</Descriptions.Item>
                        </Descriptions>
                    </Col>

                    <Col span={6} offset={2}></Col>
                    <Col span={6} offset={2}>
                        <Button type='primary' onClick={bpfCostingStatusApproval}>Submit</Button>
                    </Col>
                    
                </Row>
            </Card>
        

    )
}

export default BargePaymentNoticeView