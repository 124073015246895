import { Tabs } from "antd";
// import PurchaseActiveApproval from "./purchase-approval/purchase-contract-active";
// import PurchasePendingApproval from "./purchase-approval/purchase-pendding-approval";
import AdvancePendingApproval from "./advance-contract/advance-pending-approval";
import AdvanceActiveApproval from "./advance-contract/advance-contract-active";
import { useState } from "react";


const { TabPane } = Tabs;
const AdvanceContractApproval = () => {
  return (

    <Tabs destroyInactiveTabPane={true}>
      <TabPane tab="Pending" key="1">
        <AdvancePendingApproval  />
      </TabPane>
      <TabPane tab="Approved" key="2">
        <AdvanceActiveApproval  />
      </TabPane>


    </Tabs>


  )
}

export default AdvanceContractApproval;