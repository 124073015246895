import {
  ContractTypesEnum,
  PurchaseContractDto,
  SurveyorDataDto,
  SurveyorEnum,
} from '@exportx/shared-models-and-services';
import { Card, Col, Descriptions, Flex, List, Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../common';
import React from 'react';

interface ISurveyorProps {
  surveyorDetails: SurveyorDataDto[];
  snapShotData: PurchaseContractDto[];
  contractType: ContractTypesEnum;
}
export const SurveyorDetailsView = (props: ISurveyorProps) => {
  const { surveyorDetails, contractType, snapShotData } = props;
  const [surveyorData, setSurveyorData] = useState([]);
  const [snapShotSurveyorData, setSnapShotSurveyorData] = useState([]);
  const { authContext } = useAuthState();

  const valiDateWithSnapShotData = (
    index: number,
    key: string,
    value: string,
  ): any => {
    let valueAtGivenKey: any = snapShotSurveyorData?.[index]?.[key];
    if (valueAtGivenKey) {
      if (valueAtGivenKey == value) {
        return value;
      } else {
        if (contractType === ContractTypesEnum.ADDENDUM) {
          return (
            <>
              <s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>
                {valueAtGivenKey}
              </s>
              &nbsp;
              <Tooltip
                placement="top"
                title={snapShotData?.[0]?.contractDetails?.contractNo?.replace(
                  'SNP',
                  'ADD',
                )}
              >
                <b>{value}</b>
              </Tooltip>
            </>
          );
        }
      }
    }
    return value; //'hhhhhhhhhhhh'
  };

  console.log(surveyorDetails);

  useEffect(() => {
    if (surveyorDetails?.length) {
      setSurveyorData(surveyorDetails);
    }
  }, [props.surveyorDetails]);

  useEffect(() => {
    if (props?.snapShotData?.length) {
      let snapQualityToBeSet = props?.snapShotData?.[0]?.surveyorDetails
        ? props.snapShotData[0].surveyorDetails
        : [];
      setSnapShotSurveyorData(processSurveyorData(snapQualityToBeSet));
    }
  }, [props.snapShotData]);

  const surveyor: ColumnsType<any> = [
    {
      title: 'Surveyor Type',
      dataIndex: 'surveyorType',
      onCell: (record: any) => ({
        rowSpan: record.rowSpan,
      }),
      render: (value, row: any, index) => {
        return (
          <span>{valiDateWithSnapShotData(index, 'surveyorType', value)}</span>
        );
      },
    },
    {
      title: 'Surveyor Name',
      dataIndex: 'surveyorName',
      render: (value, row: any, index) => {
        return (
          <span>{valiDateWithSnapShotData(index, 'surveyorName', value)}</span>
        );
      },
    },
    {
      title: 'Surveyor Analysis Type',
      dataIndex: 'analysisType',
      render: (value, row: any, index) => {
        return (
          <span>{valiDateWithSnapShotData(index, 'analysisType', value)}</span>
        );
      },
    },
  ];

  const processSurveyorData = (data: SurveyorDataDto[]) => {
    const surveyorGridData = [];
    data?.forEach((surveyorTypeData, typeIndex) => {
      surveyorTypeData.surveyorAndAnalysis?.forEach((surveyorData, index) => {
        let gridObj: any = {};
        gridObj.surveyorName = surveyorData.surveyorName;
        gridObj.analysisType = surveyorData.analysisType;
        gridObj.surveyorType =
          surveyorTypeData.surveyorType === SurveyorEnum.INTERNAL
            ? authContext.defaultPlant
            : surveyorTypeData.surveyorType;
        gridObj.surveyorId = surveyorData.surveyorId
          ? surveyorData.surveyorId
          : `${typeIndex}-${index}`;
        gridObj.rowSpan = 0;
        if (index === 0) {
          gridObj.rowSpan = surveyorTypeData.surveyorAndAnalysis.length;
        }
        surveyorGridData.push(gridObj);
      });
    });
    return surveyorGridData;
  };
  const rowClassName = (record, index) => {
    if (!snapShotSurveyorData?.[index]) {
      if (contractType === ContractTypesEnum.ADDENDUM) {
        return 'highlighted-row';
      } else {
        return '';
      }
    }
    return '';
  };

  return (
    <div>
      <Row style={{ padding: '5px' }}>
        {surveyorData?.map((el, index) => {
          return (
            <Col
              span={8}
              key={index}
              xs={24}
              md={24}
              lg={12}
              xl={8}
              style={{ padding: '5px' }}
            >
              <Card
                bordered={false}
                bodyStyle={{ padding: '0' }}
                style={{ boxShadow: 'none' }}
              >
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#323F49',
                  }}
                >
                  {el.surveyorType === 'Internal' ? authContext.defaultPlant : el.surveyorType}
                </span>
                {el.surveyorAndAnalysis?.map((an, idx) => (
                  <Row
                    key={idx}
                    justify="space-between"
                    align="middle"
                    style={{
                      padding: '5px 10px',
                      backgroundColor: '#F7F9FC',
                      margin: '5px 0px',
                      borderRadius: '5px',
                    }}
                  >
                    <Col>
                      <List style={{ fontWeight: 400, color: '#323F49' }}>
                        {an.surveyorName}
                      </List>
                    </Col>
                    <Col>
                      <List
                        style={{
                          width: '75px',
                          textAlign: 'center',
                          backgroundColor: '#D9F1FD',
                          borderRadius: '18px',
                          fontWeight: 400,
                          color: '#323F49',
                        }}
                      >
                        {an.analysisType}
                      </List>
                    </Col>
                  </Row>
                ))}
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* <Table columns={surveyor} rowKey={(row) => row.surveyorId} dataSource={surveyorData} pagination={false} bordered rowClassName={rowClassName} /> */}
      <br></br>
    </div>
  );
};

export default SurveyorDetailsView;
