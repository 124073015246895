import { FixtureNotesService } from '@exportx/shared-models-and-services';
import { Button, Col, Collapse, Form, Row, Space } from 'antd';
import { useState } from 'react'
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, PreviewComponent } from '../../../../common';
import { FixtureDetails } from './fixturedetails';
import PriceDetails from './pricedetails';
import Terms from './terms';
const { Panel } = Collapse;




const FixtureNoteDetails = () => {
  const navigate = useNavigate();
  //Common for all Page Components


  const { formatMessage: fm } = useIntl();
  const [defaultOpenKey, setDefaultOpenKey] = useState(['1']);
  const [fixedNoteOrderData, setFixedNoteOrderData] = useState({ fixtureDetails: [], priceDetails: [], terms: [] });
  const [fixtureDetailsFormRef] = Form.useForm();
  const [priceDetailsFormRef] = Form.useForm();
  const [termsDetailsFormRef] = Form.useForm();

  const service = new FixtureNotesService();
  let pathToreDirect = '/fixtureNotes';



  const fixturedDetailsFormhandler = () => {
    fixtureDetailsFormRef.validateFields().then(values => {

      setFixedNoteOrderData((prevState) => { return { ...prevState, fixtureDetails: values } });
      setDefaultOpenKey(['2']);

    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const priceDetailsFormhandler = () => {
    priceDetailsFormRef.validateFields().then(values => {

      setFixedNoteOrderData((prevState) => { return { ...prevState, priceDetails: values } });
      setDefaultOpenKey(['3']);

    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const termsDetailsFormhandler = () => {
    termsDetailsFormRef.validateFields().then(values => {

      setFixedNoteOrderData((prevState) => { return { ...prevState, termsDetails: values } });
      setDefaultOpenKey(['4']);

    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }





  const previewDataLabels = [{
    title: fm({ id: `step1heading`, defaultMessage: `Fixture Note Details` }),
    keysAndLabelMapping: [
      {
        key: "fixtureDetails",
        labels: {
          fixtureNoteType: fm({ id: 'mdm.components.fixtureNoteForm.fixtureNotetype', defaultMessage: "FixtureNoteType" }),
          supplierReferenceNo: fm({ id: 'mdm.components.fixtureNoteForm.supplierReferenceNo', defaultMessage: "Supplier Reference No" }),
          motherVesselName: fm({ id: 'mdm.components.fixtureNoteForm.motherVesselName', defaultMessage: "Mother Vessel Name" }),
          agreementDate: fm({ id: 'mdm.components.fixtureNoteForm.agreementdate', defaultMessage: "Agreement Date" }),
          vendor: fm({ id: 'mdm.components.fixtureNoteForm.vendor', defaultMessage: " Vendor" }),
          layCan: fm({ id: 'mdm.components.fixtureNoteForm.layCan', defaultMessage: " LayCan" }),
          quantityInMT: fm({ id: 'mdm.components.fixtureNoteForm.quantityInMT', defaultMessage: "Quantity In MT" }),
          allowedDays: fm({ id: 'mdm.components.fixtureNoteForm.alloweddays', defaultMessage: "Allowed Days" }),
          loadingRatePerDay: fm({ id: 'mdm.components.fixtureNoteForm.loadingrateperday', defaultMessage: "Loading Rate per Day" }),
          deadFreight: fm({ id: 'mdm.components.fixtureNoteForm.deadfreight', defaultMessage: "Dead Freight" }),
          vesselType: fm({ id: 'mdm.components.fixtureNoteForm.vesseltype', defaultMessage: "Vessel Type" }),
          portOfLoading: fm({ id: 'mdm.components.fixtureNoteForm.portofloading', defaultMessage: "Port of Loading" }),
        }
      },
      {
        key: "priceDetails",
        labels: {
          currency: fm({ id: 'mdm.components.fixturePriceForm.currency', defaultMessage: " Currency" }),
          pricePerMT: fm({ id: 'mdm.components.fixturePriceForm.pricePerMT', defaultMessage: " Price Per MT" }),
          demurrageType: fm({ id: 'mdm.components.fixturePriceForm.demurrageType', defaultMessage: "Demurrage Type" }),
          demurrageRatePerDay: fm({ id: 'mdm.components.fixturePriceForm.demurrageRatePerDay', defaultMessage: 'Demurrage Rate per Day' }),
        }
      },
      {
        key: 'terms',
        labels: {
          cancellationFee: fm({ id: 'mdm.components.termsForm.cancellationFee', defaultMessage: " Cancellation Fee" }),
          paymentTerms:fm({ id: 'mdm.components.termsForm.paymentTerms', defaultMessage: " Payment Terms" }),
          others:fm({ id: 'mdm.components.termsForm.others', defaultMessage: " Others" }),
          remarks:fm({ id: 'mdm.components.termsForm.remarks', defaultMessage: " Remarks" })

        }
      }
    ],
    isGridDisplay: false
  }]
  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const clearFixtureNoteDetails = () => {

  }

  const saveFixtureNotes = () => {
    service.create(fixedNoteOrderData).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Company created SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  return (
    <div>
      <Collapse activeKey={defaultOpenKey} onChange={onChange}>
        <Panel header="FixturedNoteDetails" key='1' >
          <FixtureDetails formRef={fixtureDetailsFormRef} initialValues={fixedNoteOrderData.fixtureDetails
          } layoutType='vertical'>
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button
                      onClick={() => { clearFixtureNoteDetails(); }}
                    >
                      Clear
                    </Button>
                    <Button onClick={fixturedDetailsFormhandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </FixtureDetails>

        </Panel>
        <Panel header="PriceDetails" key='2' >
          <PriceDetails formRef={priceDetailsFormRef} initialValues={fixedNoteOrderData.priceDetails
          } layoutType='vertical'>
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button
                      onClick={() => { clearFixtureNoteDetails(); }}
                    >
                      Clear
                    </Button>
                    <Button onClick={priceDetailsFormhandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </PriceDetails>

        </Panel>

        <Panel header="Terms" key='3' >
          <Terms formRef={termsDetailsFormRef} initialValues={fixedNoteOrderData.terms
          } layoutType='vertical'>
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button
                      onClick={() => { clearFixtureNoteDetails(); }}
                    >
                      Clear
                    </Button>
                    <Button onClick={termsDetailsFormhandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </Terms>

        </Panel>
        {/* <Panel header="New Barging Agreement" key="4">
        <p><Newbargeagreement/></p>
      </Panel> */}
        <Panel header="Preview" key="4">
          <>
            <PreviewComponent data={fixedNoteOrderData} labels={previewDataLabels} primaryKeyData={{}}>
              <Row justify="end">
                <Col>
                  {
                    <Space>
                      <Button
                        onClick={() => { clearFixtureNoteDetails(); }}
                      >
                        {fm({ id: "common.clear", defaultMessage: "Clear" })}
                      </Button>
                      <Button onClick={saveFixtureNotes} type="primary">
                        Save
                      </Button>
                    </Space>
                  }</Col>
              </Row>
            </PreviewComponent>
          </>
        </Panel>
      </Collapse>


    </div>
  )
}

export default FixtureNoteDetails;