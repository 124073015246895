import { BargeCostingIdReqDto, BargeCostingsService, ContractModesEnum, CostingWorkLogsEnum, PriceTypeEnum, VendorAgainstBpId } from "@exportx/shared-models-and-services";
import React, { useEffect, useState } from "react";
import { useAuthState } from "../../../common";
import { ApprovalTracking } from "./approval-tracking";
import moment from "moment";
import { getLocalFormat } from "@exportx/ui-utils";
import { Table, Typography } from "antd";
import { SequenceUtils } from '@exportx/ui-utils';



interface BargeCostingDetailsProps {
    costingId: string;
    remainingAmount?: number
}
export const BargeCostingDetails = (props: BargeCostingDetailsProps) => {
    const { costingId } = props

    const service = new BargeCostingsService();
    const { authContext } = useAuthState();
    const [data, setFormValues] = useState<any>();
    const { Text } = Typography;
    const [advTotal, setAdvTotal] = useState<number>(0)
    const [bargeData, setBargeData] = useState<any>([]);

    useEffect(() => {

        bgCostingData()
    }, [costingId])

    const bgCostingData = () => {
        service.getBargeCostingDataByCostingId(new BargeCostingIdReqDto(costingId, authContext.defaultPlant)).then(res => {
            if (res.status) {
                const costings = res.data?.bargeCostings[0] || {}
                setFormValues({ ...res.data, ...costings });
                const advTotal = Number(res.data.advanceTarnsactionsData.reduce((a, c) => a + Number(c.totalAdjustedAmount), 0).toFixed(2))
                setBargeData(res.data?.bargeCostings?.map((item) => ({ ...item, bargeVendor: res.data?.bargeSupplier, coalVendor: res.data?.coalSupplier, remaining: res.data?.totalAmount, businessNo: res?.data?.businessNo })))
                setAdvTotal(advTotal)
            } else {
                setFormValues(null);
            }
        }).catch(err => {
            console.log(err)
            setFormValues(null);
        });
    }
    const sharedOnCell = (_, index?: number) => {
        if (index == 0) return { rowSpan: bargeData.length }
        else return { rowSpan: 0 }
    };

    const columns = [
        {
            title: 'Barge ID',
            dataIndex: 'bargeNo',
            key: 'bargeNo',
            render: (value, record) => {
                if (record.bgUId) {
                    const link = `/#/barge-detail-view?barge_id=${record.bargeId}`;
                    return <a href={link} className="link-primary" target="_blank">
                        {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
                    </a>

                }
                return <span>{value}</span>

            }
        },
        {
            title: 'Barge Nomination',
            dataIndex: 'bargeNomination',
            key: 'bargeNomination',
        },
        {
            title: 'Business No',
            dataIndex: 'businessNo',
            key: 'businessNo',
        },
        {
            title: 'Barge Vendor',
            dataIndex: 'bargeVendor',
            key: 'bargeVendor',
        },
        {
            title: 'Jetty',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Supplier',
            dataIndex: 'coalVendor',
            key: 'coalVendor',
        },
        {
            title: 'Grade',
            dataIndex: 'incoterm',
            key: 'incoterm',
        },
        {
            title: 'Quantity',
            dataIndex: 'inWardQty',
            key: 'inWardQty',
            onCell: sharedOnCell,
        },
        {
            title: data?.bargingType === PriceTypeEnum.PER_MT ? 'price PMT' : 'Lumpsum',
            dataIndex: 'basePrice',
            key: 'basePrice',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.basePrice, record?.baseCurrency)}</span>

        },
        {
            title: 'Total Payable',
            dataIndex: 'remaining',
            key: 'remaining',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.remaining, record?.quoteCurrency || record?.baseCurrency)}</span>

        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.paid, record?.quoteCurrency || record?.baseCurrency)}</span>

        },
        {
            title: 'Remaining',
            dataIndex: 'remaining',
            key: 'remaining',
            onCell: sharedOnCell,
            render: (text, record) => <span>{getLocalFormat(record?.remaining, record?.quoteCurrency || record?.baseCurrency)}</span>

        },
    ];


    return (
        <>
            <Table dataSource={bargeData} columns={columns} pagination={false} style={{ width: '100%' }} className="inner-table" />
            <table className="table table-bordered table-sm mt-4">
                <tr>
                    <th colSpan={2}>Exchange Date</th>
                    <td>
                        {moment(data?.exchangeDate)?.format('DD-MM-YYYY')}
                    </td>
                </tr>
                <tr>
                    <th colSpan={2}>Exchange  Rate</th>
                    <td>
                        <Text>{getLocalFormat(data?.exchangeRate, data?.quoteCurrency)}</Text>
                    </td>
                </tr>
                <tr>
                    <th colSpan={2}>Total Amount</th>
                    <td>
                        <Text>{getLocalFormat(data?.costingsTotal, data?.billingCurrency)}</Text>
                    </td>
                </tr>
                <tr>
                    <th>Base Freight</th>
                    <td></td>
                    <td>{getLocalFormat(data?.baseFreight, data?.billingCurrency)}</td>
                </tr>
                <tr>
                    <th>Dead Freight</th>
                    <td></td>
                    <td>{getLocalFormat(data?.deadFreight, data?.billingCurrency)}</td>
                </tr>
                <tr>
                    <th>VAT</th>
                    <td>
                        <Text>{`${data?.vat ? 'Yes' : 'No'}`}</Text>
                    </td>
                    <td>
                        <Text>{getLocalFormat(data?.taxTotal, data?.billingCurrency)}</Text>
                    </td>
                </tr>
                <tr>
                    <th>TDS:</th>
                    <td><Text>{`${data?.tdsName} - ${data?.tdsPercentage}`}</Text></td>
                    <td>{getLocalFormat(data?.tdsTotal, data?.billingCurrency)}</td>
                </tr>
                {/* <tr>
                    <th colSpan={2} > Amount</th>
                    <td>{getLocalFormat(data?.totalAmount - advTotal, data?.billingCurrency)}</td>

                </tr> */}
                <tr>
                    <th colSpan={3} >
                        Advance Adjustments
                    </th>
                    <td></td>

                </tr>
                {data?.advanceTarnsactionsData?.map((adv) => (
                    <tr key={adv.id}>
                        <th colSpan={2} >
                            Adv deduction against contract : {adv.adjustedCostingNo}
                        </th>
                        <td>{getLocalFormat(adv.totalAdjustedAmount, data?.billingCurrency)}</td>

                    </tr>
                ))}
                <tr>
                    <th colSpan={2} >
                        Total Advance Adjustments
                    </th>
                    <td> {getLocalFormat(advTotal, data?.billingCurrency)}</td>
                </tr>
                <tr>
                    <th colSpan={2} >
                        Payable to Vendor
                    </th>
                    <td>
                    <span style={{color:'green', fontWeight:'bold'}}>{getLocalFormat(data?.totalAmount - advTotal, data?.billingCurrency)}</span>
                    </td>
                </tr>
                {props.remainingAmount &&
                    <tr>
                        <th colSpan={2} >
                            Remaining Amount
                        </th>
                        <td>
                        <span style={{color:'red', fontWeight:'bold'}}>{getLocalFormat(props?.remainingAmount, data?.billingCurrency)}</span> 
                        </td>
                    </tr>
                }
            </table>

            {/* {!props.remainingAmount && */}

                <>
                    <br />
                    <ApprovalTracking history={data?.workLog} />
                </>
            {/* } */}
        </>
    )
}