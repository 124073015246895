import { ClassValidator } from '@exportx/ui-utils';
import { Row, Col, Form, Input } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { useIntl } from 'react-intl';

interface IPropsUserBasicDetails {
  field: FormListFieldData
}

const UserBasicDetails = (props: IPropsUserBasicDetails) => {
  const { formatMessage: fm } = useIntl();
  const classValidator = new ClassValidator();
  const { field } = props;
  return (
    <>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }}>
          <Form.Item
            label={fm({ id: "common.userForm.firstName", defaultMessage: `First Name` })}
            {...field}
            name={[field.name, 'firstName']}
            rules={[
              {
                required: true, message: fm({ id: "common.userForm.reqFirstName" })
              },
              ...classValidator.alphaSpaces(),
              {
                max: 15, message: fm({ id: "common.userForm.reqMaxFirstName" })
              }
            ]}
          >
            <Input placeholder={fm({ id: `common.userForm.reqFirstName`, defaultMessage: `Enter First Name` })} autoComplete='off' />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
          <Form.Item
            label={fm({ id: "common.userForm.middleName", defaultMessage: `Middle Name` })}
            {...field}
            name={[field.name, 'middleName']}
            rules={[
              {
                max: 15, message: fm({ id: "common.userForm.reqMaxFirstName" })
              },
              ...classValidator.alphaSpaces(),
            ]}
          >
            <Input placeholder={fm({ id: `common.contactForm.middleName`, defaultMessage: `Enter Middle Name` })} autoComplete='off' />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }}>
          <Form.Item label={fm({ id: "common.userForm.lastName", defaultMessage: `Last Name` })}
            {...field}
            name={[field.name, 'lastName']}
            rules={[
              {
                required: true, message: fm({ id: "common.userForm.reqLastName" })
              },
              ...classValidator.alphaSpaces(),
              {
                max: 30,
                message: fm({
                  id: "common.userForm.reqMaxLastName"
                })
              }
            ]}
          >
            <Input placeholder={fm({ id: `common.userForm.reqLastName`, defaultMessage: `Enter Last Name` })} autoComplete='off' />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default UserBasicDetails;