import { DeleteOutlined, EditOutlined, FileExcelFilled } from '@ant-design/icons';
import { AccountsVoucherTypesEnum, PaymentDateReqDto, PaymentVoucherIdRequest, PaymentVoucherService, ReceiptVoucherTypeEnum } from '@exportx/shared-models-and-services';
import { Button, Card, Col,  Form, Input, Row, Table, Tooltip } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AlertMessages, useAuthState } from '../../../common';
import DatePicker from '../../../common/data-picker/date-picker';

interface paymentTypeCategory {
  paymentTypeCategory: AccountsVoucherTypesEnum;
  
}
export const PaymentG = (props: paymentTypeCategory) => {
  const [form] = Form.useForm();
  const { authContext } = useAuthState();
  const [gridData, setGridData] = useState([]);
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const service = new PaymentVoucherService();
  const [searchData,setSearchData]=useState("")
  const { paymentTypeCategory } = props;
  const formRoute = paymentTypeCategory === AccountsVoucherTypesEnum.PAYMENT_VOUCHER ? '/create-payment-voucher' : '/create-receipt-voucher';
  useEffect(() => {
    getAllVouchers(undefined, undefined)
  }, []);

  const getAllVouchers = (fromDate: string, toDate: string) => {
    const req: PaymentDateReqDto = new PaymentDateReqDto();
    req.plantCode = authContext.defaultPlant;
    req.voucherType = paymentTypeCategory;
    req.fromDate = fromDate;
    req.toDate = toDate;
    service.getAllPaymentVoucher(req).then(res => {
      if (res.status) {
        setGridData(res.data)
      } else {
        setGridData([])
        if (res.errorCode !== 99998) {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      setGridData([])
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const deleteRecord = (voucherId) => {
    const req = new PaymentVoucherIdRequest(voucherId)
    service.deletePaymentVoucher(req).then((res) => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage)
        getAllVouchers(undefined, undefined);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }


  const handleExport = (e: any) => {
    e.preventDefault();
    // Formatting columns - to set default render
    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("/");
    // Formatting columns - to set default render
    let cloneArr = columns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
    });

    const excel = new Excel();
    excel
      .addSheet("Sheet1")
    excel.addRow();
    excel.addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`Payment-Voucher-${currentDate}.xlsx`);
  };


  const columns: any = [
    {
      title: 'Date',
      dataIndex: 'tranDate',
      width: '190px',
      sorter: (a, b) => a.tranDate.localeCompare(b.tranDate),
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchData).toLowerCase()],
            onFilter: (value, record) => {
                return (
                    String(record.tranDate)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                    String(record.slNo)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                        String(record.voucherDisplayID)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                        String(record.actLedName)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                        String(record.pmtVoucherTransaction)
                        .toLowerCase()
                        .includes(value.toLocaleString()) ||
                       
                    String(record.status)
                        .toLowerCase()
                        .includes(value.toLocaleString()))
            },
      render: (val) => {
        return moment(val).format('DD-MMM-YYYY');
      }
    },
    {
      title: 'ID',
      dataIndex: 'slNo',
      width: '100px',
      sorter: (a, b) => a.slNo.localeCompare(b.slNo),
      sortDirections: ['descend', 'ascend']

    },
    {
      title: 'Voucher No',
      dataIndex: 'voucherDisplayID',
      width: '220px',
      sorter: (a, b) => a.voucherDisplayID.localeCompare(b.voucherDisplayID),
      sortDirections: ['descend', 'ascend']

    },
    {
      title: 'Particulars',
      dataIndex: 'actLedName',
      width: '220px',
      sorter: (a, b) => a.actLedName.localeCompare(b.actLedName),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Amount',
      dataIndex: 'pmtVoucherTransaction',
      width: '160px',
      align: 'right' as 'right',
      sorter: (a, b) => a.pmtVoucherTransaction.localeCompare(b.pmtVoucherTransaction),
      sortDirections: ['descend', 'ascend'],
      render: (rec) => {
        let total = 0;
        rec.forEach(element => {
          total += Number(element.amount)
        });
        return total;
      }
    },
    {
      title: 'Action',
      Width: '100px',
      render: (text, rowData) => (
        <span>
          <Tooltip placement='topRight' title="Edit">
            <EditOutlined className={'editSamplTypeIcon'} type="edit"
              onClick={() => {
                navigate(formRoute, { state: { groupEntryData: rowData, isUpdate: true } })
              }}
              style={{ color: '#1890ff', fontSize: '20px' }}
            /></Tooltip>
          <Tooltip placement="topRight" title="Delete">
            <DeleteOutlined type='delete' onClick={() => { deleteRecord(rowData?.voucherId); }} style={{ color: 'red', fontSize: '20px' }} />
          </Tooltip>
        </span>
      )

    }
  ]
  return (
    <div>
      <Card title={<span>{paymentTypeCategory === AccountsVoucherTypesEnum.PAYMENT_VOUCHER ? ReceiptVoucherTypeEnum.PAYMENT_VOUCHER : (paymentTypeCategory === AccountsVoucherTypesEnum.RECEIPT_VOUCHER ? ReceiptVoucherTypeEnum.RECEIPT_VOUCHER : "")}</span>}
        extra={<Button type='primary' onClick={() => navigate(formRoute)} >Create</Button>}>
        <Card>
          <>
            <Form autoComplete="off" form={form}>
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1}>
                  <Form.Item label="From Date" rules={[{ required: true }]}
                    name="fromDate">
                    <DatePicker />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1}></Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
                  <Form.Item label="To Date" rules={[{ required: true }]}
                    name="toDate"><DatePicker />
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1}></Col>

              </Row>
            </Form>

            <div style={{ float: 'right' }}>
              <Button type='primary' onClick={() => {
                form.validateFields().then(value => {
                  const fromDate = moment(form.getFieldValue('fromDate')).format('yyyy-M-D');
                  const toDate = moment(form.getFieldValue('toDate')).format('yyyy-M-D');
                  getAllVouchers(fromDate, toDate);
                })
              }}>Display Payments</Button>
            </div>
            <br /><br />
          </>
          <Tooltip placement="topRight" title="Export As Excel">
            <Button
              //disabled={gridData.length === 0}
              style={{ float: "right" }}
              size='small'
              type="default"
              //className={gridData.length === 0 ? "export-excel-btn-disabled" : "export-excel-btn"}
              onClick={handleExport}
              icon={<FileExcelFilled />}
            >Export</Button>
          </Tooltip>
          <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />
          <Table columns={columns} dataSource={gridData} pagination={false} bordered />
        </Card>
      </Card >
    </div >
  )
}

export default PaymentG;