import { ContractStatusEnum, DebCreIdReq, DebitCreditService } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Form, RadioChangeEvent, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';
const { Text } = Typography;

export interface DebitCreditNoteChildProps {
    debCreId: string
}

export default function DebitCreditNoteChild(props: DebitCreditNoteChildProps) {

    const [noteType, setNoteype] = useState<any>()
    const [data, setData] = useState<any[]>([])
    const { authContext } = useAuthState()


    const debitCreditService = new DebitCreditService();
    useEffect(() => {
        getData()
    }, [props.debCreId])


    const getData = () => {
        const req = new DebCreIdReq(props.debCreId, authContext.defaultPlant)
        debitCreditService.getDataForUpdate(req).then((res) => {
            if (res.status) {
                setData(res.data)
            } else {
                setData([])
            }
        }).catch((err) => {
            console.log('err', err)
        })
    }

    const [formRef] = Form.useForm()
    const tableColumns: ColumnsType<any> = [
        {
            title: "Against",
            dataIndex: "against"
        },
        {
            title: "Refrence",
            dataIndex: "refrence",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            align: 'right',
            render: (value) => Number(value).toFixed(2)
        }

    ]
    const options = [
        { label: 'Debit Note', value: 'Debit Note' },
        { label: 'Credit Note', value: 'Credit Note' },
    ];

    const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
        setNoteype(value);
    };

    const updateStatus = (value) => {
        const reqObj = { approvalStatus: value, approvedBy: authContext.user.userName, debCreId: data[0]?.debitCreditId, plantCode: authContext.defaultPlant, workLog: formRef.getFieldValue('remarks') }
        debitCreditService.updateApprovalStatus(reqObj).then((res) => {
            setData([])
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage)
                window.location.reload()
            } else {
                AlertMessages.getErrorMessage(res.internalMessage)
            }
        }).catch(err => console.log(err.message))

    }

    return (
        <Card>
            <Form form={formRef} autoComplete='off'>
                <Row gutter={24}>
                    <Col span={6} >
                        <Form.Item name='noteType' label={<b>Note Type</b>}   >
                            {<Text>{data[0]?.noteType}</Text>}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={7}>
                        <Form.Item name='debitCreditNo' label={<b>No#</b>}   >
                            {<Text>{data[0]?.debitCreditNo}</Text>}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name='date' label='Date:'   >
                            <DatePicker value={moment(data[0]?.debitCreditEntryDate)} defaultValue={moment(data[0]?.debitCreditEntryDate)} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <Form.Item name='account' label={<b>{'Account'}</b>}   >
                            {data[0]?.debitCreditTransactions[0]?.account}
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='center' >
                    <Table rowKey={(row) => row.debCreId} size="small" scroll={{ x: 600, y: 600 }} bordered columns={tableColumns}
                        dataSource={data[0]?.debitCreditTransactions} pagination={false}
                        summary={(pageData) => {
                            let SubTotal = 0
                            pageData.forEach(({ amount }) => {
                                SubTotal += Number(amount);
                            });
                            return (
                                <>
                                    <Table.Summary.Row className='tableFooter'>
                                        <Table.Summary.Cell index={1} colSpan={1}>{ } </Table.Summary.Cell>
                                        <Table.Summary.Cell index={2} colSpan={1} ><Text style={{ textAlign: 'center' }}><b>Sub Total</b>  </Text></Table.Summary.Cell>
                                        <Table.Summary.Cell index={3}><Text style={{ textAlign: 'end' }}>{SubTotal.toFixed(2)}</Text></Table.Summary.Cell>
                                        {/* <Table.Summary.Cell index={12}><Text style={{ textAlign: 'end' }}>{Math.round(totalQuantity)}</Text></Table.Summary.Cell> */}
                                    </Table.Summary.Row>
                                </>
                            );
                        }
                        } ></Table>
                </Row>
                <Row gutter={24} justify={'start'}>
                    <Col span={6}>
                        <Form.Item name='tds' label={<b>TDS</b>}   >
                            {data[0]?.tdsTotal}
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'start'}>
                    <Col span={6}>
                        <Form.Item name='tdsType' label={<b>VAT</b>}   >
                            {data[0]?.taxTotal}
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col span={6}>
                        <Form.Item name='tdsType' label={<b>Total</b>}   >
                            {data[0]?.totalAmount}
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'start'}>
                    <Col span={6}>
                        <Form.Item name='remarks' label={<b>Worklog</b>}  >
                            <TextArea rows={3} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} justify={'end'}>
                    <Col span={2}>
                        <Button type='primary' onClick={() => { updateStatus(ContractStatusEnum.ACTIVE) }}>Approve</Button>
                    </Col>
                    <Col span={2}>
                        <Button style={{ color: 'red' }} type='default' onClick={() => { updateStatus(ContractStatusEnum.CANCELLED) }} >Reject</Button>
                    </Col>
                </Row>
            </Form>

        </Card>
    )

}
