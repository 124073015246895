import { ContractIdRequest, ContractTypesEnum, PurchaseContractService } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Form, Input, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertMessages, useAuthState } from "../../../../../common";
import Link from 'antd/lib/typography/Link';



const PurchasePendingApproval = () => {
  const [purchasePending, setPurchasePending] = useState<any>();
  const [searchData, setSearchData] = useState("")

  const service = new PurchaseContractService()
  const navigate = useNavigate();
  let pathToreDirect = '/po-detail-view'
  const { authContext } = useAuthState();



  useEffect(() => {
    purchasePendingD();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.defaultPlant]);


  const purchasePendingD = () => {
    service.purchasePendingData({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setPurchasePending(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }
  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.bpName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.action)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
      render: (value, rowData) => {
        const link = `/#/po-detail-view?pc_id=${rowData.contractId}`
      return <> {
        <Link href={link} className="link-primary" >{value}</Link>
      }
      </>}
    },
    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('YYYY-MM-DD') : "" },
      sorter: (a, b) => moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },

    {
      title: 'Laycan',
      dataIndex: 'layCan',
      render: (value, record) => { return record?.layCan ? Array.isArray(record?.layCan) ? `${moment(record.layCan[0]).format('DD MMM YYYY')} - ${moment(record.layCan[1]).format('DD MMM YYYY')}` : `${moment(record.layCan.split(',')[0]).format('DD MMM YYYY')} - ${moment(record.layCan.split(',')[1]).format('DD MMM YYYY')}` : ''; }
    },

    {
      title: 'Quality & Quantity',
      dataIndex: 'quality'
    },

    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, rowData) => {
        return <span >
          <Button size="small" onClick={() => { purchaseForApproval(rowData); }}>Approve</Button>
          <Button size="small" onClick={() => { PurchaseForReject(rowData) }}>Reject</Button></span>
      }
    },



  ]
  const purchaseForApproval = (record: any) => {
    const req = new ContractIdRequest(record.contractId, authContext.defaultPlant, record.contractType)
    if (record.contractType === ContractTypesEnum.ADDENDUM) {
      service.approvePurchaseAddendum(req).then(res => {
        if (res.status) {
          purchasePendingD();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.error);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
      return
    } else {
      service.purchaseForApproval(req).then(res => {
        if (res.status) {
          purchasePendingD();
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.error);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

  }

  const PurchaseForReject = (record: any) => {
    const req = new ContractIdRequest(record.contractId, record.contractType, authContext.defaultPlant)
    service.purchaseForReject(req).then(res => {
      if (res.status) {
        purchasePendingD()
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.error);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  const redirectToFullView = (contractId: string) => {
    navigate(pathToreDirect, { state: { contractId } });
  }

  return (
    <Card>


      <Form layout="vertical">
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >


          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
          </Col>
        </Row>



      </Form>
      <Table className='contracts' columns={tableColumns} dataSource={purchasePending} ></Table>

    </Card>

  )

}
export default PurchasePendingApproval;