import { Tabs } from "antd";
import SalesActiveApproval from "./sales-approval/sales-active";
import SalesPendingApproval from "./sales-approval/sales.pending";


const { TabPane } = Tabs;


interface ISalesApprovalProps {
  approval?: string;
  
}
const SalesApproval = (props: ISalesApprovalProps) => {
  return (

    <Tabs destroyInactiveTabPane={true}>
      <TabPane tab="Pending" key="1">
        <SalesPendingApproval />
      </TabPane>
      <TabPane tab="Approved" key="2">
        <SalesActiveApproval />
      </TabPane>


    </Tabs>


  )
}

export default SalesApproval;