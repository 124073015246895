import { DeleteFilled } from '@ant-design/icons';
import { AllocatedTypeEnum, BusinessNoBargeMappingDto, GetAllBargeMappingDataDto } from '@exportx/shared-models-and-services';
import { Button, Col, Form, FormListFieldData, Row, Select, Table } from 'antd';
import { commonColumns } from './common-columns';
import { RedraftForm } from './redraft-form';

interface IPropsBargeMapAllocForm {
    businessNo: string;
    fields: FormListFieldData[];
    add: (defaultValue?: any, insertIndex?: number) => void;
    remove: (index: number | number[]) => void;
    bargeAllocatedData: any[];
    allocationDataMap: Map<string, GetAllBargeMappingDataDto[]>;
    selectedAllocations: Map<number, string>;
    setSelectedAllocations: React.Dispatch<React.SetStateAction<Map<number, string>>>;
    selectedRowKeysData: Map<string, any>;
    setSelectedRowKeysData: React.Dispatch<React.SetStateAction<Map<string, any>>>;
    formValuesData: Map<string, any>;
    setFormValuesData: React.Dispatch<React.SetStateAction<Map<string, any>>>;
    getBargesAllocatedData: (allocationId: string[]) => void
};


const { Option } = Select;
export const BargeMapAllocForm = (props: IPropsBargeMapAllocForm) => {
    const { businessNo, fields, add, remove, bargeAllocatedData, allocationDataMap, selectedAllocations, setSelectedAllocations, selectedRowKeysData, setSelectedRowKeysData, formValuesData, setFormValuesData, getBargesAllocatedData } = props;
    //const [noDataAllocationIds, setNoDataAllocationIds] = useState<string[]>([]);


    // useEffect(() => {
    //     if (selectedAllocations.size) {
    //         for (const [index, allocationId] of selectedAllocations.entries()) {
    //             if (!allocationDataMap.has(allocationId) && !noDataAllocationIds.includes(allocationId)) {
    //                 debouncedSendRequest(allocationId, Number(index));
    //             };
    //         };
    //     };
    // }, [selectedAllocations]);



    // memoize the callback with useCallback
    // we need it since it's a dependency in useMemo below
    const allocationIdOnChangeHandler = (allocationId: string, index: number) => {
        getBargesAllocatedData([allocationId]);
        const updatedMap = new Map(selectedAllocations);
        updatedMap.set(index, allocationId);
        setSelectedAllocations(updatedMap);
    };

    // memoize the debounce call with useMemo
    // const debouncedSendRequest = useMemo(() => {
    //     return debounce(allocationIdOnChangeHandler, 1500);
    // }, [allocationIdOnChangeHandler]);



    const selectedRowOnChange = (allocationId: string, selectedRow, updatedFormValuesData: Map<string, any>) => {
        if (!formValuesData.has(selectedRow)) {
            const barges = allocationDataMap.get(allocationId)?.filter(record => `${record.id}$@$${record.allocationUId}` === selectedRow)[0];
            updatedFormValuesData.set(selectedRow, new BusinessNoBargeMappingDto(undefined, Number(barges?.allocationId), barges.allocationUId, AllocatedTypeEnum.FULL, undefined, undefined, undefined, undefined, undefined, undefined, businessNo, barges?.bargeId ? Number(barges?.bargeId) : undefined, barges?.id, undefined, barges.bargeNomination, undefined, undefined, Number(barges.pcQualities), Number(barges.bargeQuantityInMt), undefined, undefined, undefined, barges.loadingDate, undefined, undefined));
        }
        return updatedFormValuesData;
    };

    const rowSelection = (allocationId: string): any => {
        return {
            onChange: (selectedRowKeys, selectedRows) => {
                if (selectedRowKeys.length) {
                    const updatedMap = new Map(selectedRowKeysData);
                    updatedMap.set(allocationId, selectedRowKeys);
                    setSelectedRowKeysData(updatedMap);
                    let updatedFormValuesData = new Map(formValuesData);
                    for (const selectedRow of selectedRowKeys) {
                        updatedFormValuesData = selectedRowOnChange(allocationId, selectedRow, updatedFormValuesData);
                    };
                    const barges = allocationDataMap.get(allocationId).map(record => `${record.id}$@$${record.allocationUId}`);
                    const presentSelected = barges.filter(uniqueKey => !selectedRowKeys.includes(uniqueKey));
                    for (const selected of presentSelected) {
                        updatedFormValuesData.delete(selected);
                    };
                    setFormValuesData(updatedFormValuesData);
                } else {
                    const barges = allocationDataMap.get(allocationId).map(record => `${record.id}$@$${record.allocationUId}`);
                    let updatedFormValuesData = new Map(formValuesData);
                    for (const selected of barges) {
                        updatedFormValuesData.delete(selected);
                    };
                    const updatedMap = new Map(selectedRowKeysData);
                    setFormValuesData(updatedFormValuesData);
                    updatedMap.delete(allocationId);
                    setSelectedRowKeysData(updatedMap);
                };
            },
            selectedRowKeys: selectedRowKeysData.get(allocationId)
        }
    }


    const getExpandKeys = (index, name): any => {
        return {
            expandedRowRender: (record: any, indent, expanded) => {
                return <RedraftForm index={index} record={record} formValuesData={formValuesData} setFormValuesData={setFormValuesData} />
            },
            expandedRowKeys: selectedRowKeysData.get(index),
            fixed: 'right'
        };
    };

    const onRemoveHandler = (index, callBack) => {
        const updatedMap = new Map(selectedAllocations);
        updatedMap.delete(index);
        setSelectedAllocations(updatedMap);
        const updatedMapRowKeys = new Map(selectedRowKeysData);
        updatedMapRowKeys.delete(index);
        setSelectedRowKeysData(updatedMapRowKeys);
        callBack(index);
    };

    return (
        <>
            {fields.map(({ key, name, ...restField }, index) => (
                <>
                    {/* <Form.Item label='Allocation ID' name={[name, 'allocationId']}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: '150px' }}
                            placeholder='Please Select Allocation Id'
                            onChange={(value) => allocationIdOnChangeHandler(value, index)}
                        >
                            {bargeAllocatedData.map(item => {
                                return <Option
                                    disabled={Array.from(selectedAllocations.values()).includes(item.id)}
                                    value={item.id} >{item.displayAllocationId}</Option>
                            })}
                        </Select>
                    </Form.Item> */}
                    <Form.Item label='Allocation ID' name={[name, 'allocationId']}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: '150px' }}
                            placeholder='Please Select Allocation Id'
                            onChange={(value) => allocationIdOnChangeHandler(value, index)}
                        >
                            {bargeAllocatedData
                                .sort((b, a) => {
                                    // Check if the displayAllocationId values are numeric or not
                                    const isNumeric = !isNaN(a.displayAllocationId) && !isNaN(b.displayAllocationId);
                                    if (isNumeric) {
                                        return b.displayAllocationId - a.displayAllocationId; // Sort numeric values in descending order
                                    } else {
                                        return b.displayAllocationId.localeCompare(a.displayAllocationId); // Sort strings in descending order
                                    }
                                })
                                .map(item => (
                                    <Option
                                        disabled={Array.from(selectedAllocations.values()).includes(item.id)}
                                        value={item.id}
                                        key={item.id} // Add a unique key for each option
                                    >
                                        {item.displayAllocationId}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Row>
                        <Col span={22}>
                            {selectedAllocations.has(index) && (
                                <Table
                                    className='contracts'
                                    rowSelection={{
                                        type: 'checkbox',
                                        ...rowSelection(selectedAllocations.get(index)),
                                    }}
                                    expandable={{ ...getExpandKeys(selectedAllocations.get(index), name) }}
                                    expandIcon={({ expanded, onExpand, record }) => <></>}
                                    rowKey={record => record.id + '$@$' + record.allocationUId}
                                    dataSource={allocationDataMap.get(selectedAllocations.get(index))}
                                    columns={[...commonColumns]}
                                    pagination={false}
                                    bordered />
                            )}
                        </Col>
                        <Col span={2}>
                            {index !== 0 && <DeleteFilled onClick={() => onRemoveHandler(index, remove)} style={{ color: "red", fontSize: 20 }} />}
                        </Col>
                    </Row>
                </>
            ))}
            {!(fields.length >= bargeAllocatedData.length) && <Form.Item>
                <Button onClick={() => add()}>Add More</Button>
            </Form.Item>}
        </>
    );
};

export default BargeMapAllocForm