import React, { useEffect, useState } from 'react';
import {
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Button,
  Avatar,
  MenuProps,
  Modal,
  Form,
  Input,
} from 'antd';

import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import './header-nav-component.css';
import {
  LoginUserDto,
  UsersService,
} from '@exportx/shared-models-and-services';
import { AlertMessages } from '../notifications';
import { logout, useAuthState } from '../iam-client-react';
import axios from 'axios';
import { useIntl } from 'react-intl';
const { Header } = Layout;
interface IProps {
  collapsed: boolean;
  toggle: () => void;
}
export const CommonHeader = (props: IProps) => {
  const { authContext, dispatch } = useAuthState();
  const service = new UsersService();
  const { formatMessage: fm } = useIntl();
  const [formRef] = Form.useForm();


  const [changePswdModal, setChangePswdModal] = useState<boolean>(false)
  let profilePicPath = authContext.user.profilePicPath
    ? authContext.user.profilePicPath
    : ``;

  const logoutHandler = async () => {
    try {
      const req = new LoginUserDto(
        authContext.user.userName,
        `SaiResources${Math.random()}`,
      );
      const saltRes = await axios.post(
        `${authContext.authServerUrl}/authentications/logOut`,
        req,
      );
      if (saltRes.status) {
        logout(dispatch);
      } else {
        throw Error(saltRes.data.internalMessage);
      }
    } catch (error: any) {
      AlertMessages.getErrorMessage(error.message);
    }
  };

  const changePswdHandler = () => {
    setChangePswdModal(true)

  }

  useEffect(() => {
    formRef.setFieldValue('userName', authContext.user.userName)
  }, [authContext.user])

  const menu = (
    <Menu>
      <Menu.Item>UserName: {authContext.user.userName}</Menu.Item>
      <Menu.Item>Roles: {authContext.user.roles}</Menu.Item>
      <Menu.Item>Plant: {authContext.defaultPlant}</Menu.Item>
      <Menu.Item key="logout">
        <Button onClick={() => logoutHandler()}>logout</Button>
      </Menu.Item>
    </Menu>
  );

  const items = [
    {
      label: `UserName: ${authContext.user.userName}`,
      key: 'UserName',
    },
    {
      label: `Roles: ${authContext.user.roles}`,
      key: 'Roles',
    },
    {
      label: `Unit: ${authContext.user.defaultPlant}`,
      key: 'unit',
    },
    {
      label: `Change Password`,
      key: 'changePswd',
    },
    {
      label: `logout`,
      key: 'logout',
    },
  ];

  const logoutMenuClickHandler: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      logoutHandler();
    }
    if (key === 'changePswd') {
      changePswdHandler();
    }
  };
  const handleLogin = () => {
    formRef?.validateFields().then((res) => {
      const payload = { userId: authContext.user.userId, ...res }
      service.changePassword(payload).then((res: any) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setChangePswdModal(false)
        } else {
          AlertMessages.getErrorMessage(res.message);
        };
      }).catch(err => {
        console.log(err.message, 'error msg')
      })
    })

  }
  return (
    <Header className="header-row" style={{ background: '#fff', padding: 0 }}>
      <Row justify="space-between" align="middle">
        <Col span={4}>
          <div className="logo">
            <h1>{authContext.defaultPlant}</h1>
          </div>
        </Col>
        <Col span={1}>
          <span className="ant-pro-global-header-trigger">
            {React.createElement(
              props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'trigger',
                onClick: props.toggle,
              },
            )}
          </span>
        </Col>
        <Col span={2}></Col>
        <Col span={7}></Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Dropdown menu={{ items, onClick: logoutMenuClickHandler }}>
            <span className="ant-dropdown-link">
              <Avatar src={profilePicPath} size={'default'} shape="circle">
                {authContext.user.userName}
              </Avatar>
              {/* <Button
                size="large"
                style={{ marginRight: 14, verticalAlign: 'middle', backgroundColor: '#00a2ae', color: '#fff' }}
              >
                {authContext.user.userName}
              </Button> */}
            </span>
          </Dropdown>
        </Col>
        <Col></Col>
      </Row>
      <Modal
        title="Change Password"
        open={changePswdModal}
        centered
        footer={null}
        onCancel={()=> setChangePswdModal(false)}
      >

        <Form
          name="login-form"
          form={formRef}
          // initialValues={}
          onFinish={handleLogin}
          style={{ minWidth: 300 }}
        >
          <br />

          <Form.Item
            name='userName'
            label={fm({ id: 'user.form.userName', defaultMessage: 'user Name' })}
            rules={[{ message: fm({ id: 'user.form.userNameRequired', defaultMessage: 'User name is required' }) },
            ]}>
            <Input placeholder={fm({ id: 'user.form.userName', defaultMessage: 'user Name' })} disabled />
          </Form.Item>

          <Form.Item
            name="password"
            label={fm({ id: 'user.form.password', defaultMessage: 'Current Password' })}
            hasFeedback
            rules={[
              { required: true, message: fm({ id: 'user.form.passwordRequired', defaultMessage: 'Password is required' }) },
            ]}

          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="new_password"
            label={fm({ id: 'user.form.new_password', defaultMessage: 'New Password' })}
            hasFeedback
            rules={[
              { required: true, message: fm({ id: 'user.form.passwordRequired', defaultMessage: 'New Password is required' }) },
            ]}

          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={fm({ id: 'user.form.confirmPassword', defaultMessage: 'Confirm New Password' })}
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: fm({ id: 'user.form.confirmPasswordRequired', defaultMessage: 'confirm password is required' }) },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(fm({ id: 'user.form.passwordNotMatch', defaultMessage: 'Entered password doesnot match' })));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item style={{ width: '250px', alignItems: 'center', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit" >
              Submit
            </Button>
            {/* Or <a href="/">register now!</a> */}
          </Form.Item>
        </Form>
      </Modal>
    </Header>
  );
};
