import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Upload } from 'antd';
import { FileUploadForm } from '../../../../common';

export const CoalCostingFileUploads = () => {
  return (
      <Form>
        <Card title='File Upload Details'>
          <Row>
            <Col span={8} offset={2}>
              <b>INVOICE</b>
            </Col>
            <Col span={8}>
              <Upload >
                <Button icon={<UploadOutlined />}>Choose File</Button>
              </Upload>
            </Col>
          </Row> <br/>
          <Row>
            <Col span={8} offset={2}>
              <b>DSR</b>
            </Col>
            <Col span={8}>
              <Upload >
                <Button icon={<UploadOutlined />}>Choose File</Button>
              </Upload>
            </Col>
          </Row><br/>
          <Row>
            <Col span={8} offset={2}>
              <b>COA</b>
            </Col>
            <Col span={8}>
              <Upload >
                <Button icon={<UploadOutlined />}>Choose File</Button>
              </Upload>
            </Col>
          </Row><br/>
          <Row>
            <Col span={8} offset={2}>
              <b>BL</b>
            </Col>
            <Col span={8}>
              <Upload >
                <Button icon={<UploadOutlined />}>Choose File</Button>
              </Upload>
            </Col>
          </Row>
          <hr></hr>
          <FileUploadForm maxCount={1} layoutType={'vertical'} formRef={undefined} initialValues={[]} ></FileUploadForm>
        </Card>
      </Form>
  )
}

export default CoalCostingFileUploads;