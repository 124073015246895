import React, { useEffect, useState } from 'react';
import { BusinessAllocationDropdownDto, BusinessNumberService, CostingTypesEnum, PurchaseContractService, PurchaseDropDownDto, BGCoalCostingService, GetBargesCoalCoastReq } from '@exportx/shared-models-and-services';
import { Card, Row, Col, Select, Form, Table, Button, Drawer, Space } from 'antd';
import { useAuthState } from '../../../../common';
import { useIntl } from 'react-intl';
import CoalCostingStepper from './coal-costings-stepper';
import { useNavigate } from 'react-router-dom';
import { SequenceUtils } from '@exportx/ui-utils';

interface ICoalCostingsGrid {
    coalCostingType: CostingTypesEnum;
}
const { Option } = Select;
let viewPortWidth = window.innerWidth;
export const CoalCostingsGrid = (props: ICoalCostingsGrid) => {
    const { formatMessage: fm } = useIntl();
    const navigate = useNavigate();
    const { coalCostingType } = props;
    const [formRef] = Form.useForm();
    const { authContext } = useAuthState();
    const purchaseService = new PurchaseContractService();
    const businessNumberService = new BusinessNumberService();
    const bgCoalCostingService = new BGCoalCostingService();
    const [pcs, setPcs] = useState<PurchaseDropDownDto[]>([]);
    const [businessNumbers, setBusinessNumbers] = useState<BusinessAllocationDropdownDto[]>([]);
    const [gridData, setGridData] = useState([]);
    const [pcData, setPcData] = useState(null)
    const [visible, setVisible] = useState(false);



    let pathToreDirectToBargeDetails = '/barge-detail-view';
    const redirectToBargeDetailsFullView = (bargeId: number) => {
        navigate(pathToreDirectToBargeDetails, { state: { bargeId } });
    }


    useEffect(() => {
        const getAllPurchaseContracts = () => {
            purchaseService.getAllPurchaseContractsForDropDown({ plantCode: authContext.defaultPlant }).then(res => {
                if (res.status) {
                    setPcs(res.data);
                }
            }).catch(err => {
                console.log('error: ' + err.message);
            })
        };
        getAllPurchaseContracts();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    const getBusinessAllocationDropDown = () => {
        businessNumberService.getBusinessAllocationDropDown({ contractId: formRef.getFieldValue('purchaseContract'), plantCode: authContext.defaultPlant }).then(res => {
            if (res.status) {
                setBusinessNumbers(res.data)
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }

    const getBargesForPurFinancialCoalCosting = () => {
        const req = new GetBargesCoalCoastReq();
        req.purchaseContractId = formRef.getFieldValue('purchaseContract');
        req.costingType = coalCostingType;
        req.businessNoIds = formRef.getFieldValue('businessNumber');
        bgCoalCostingService.getBargesForPurFinancialCoalCosting(req).then((res: any) => {
            if (res.status) {
                setGridData(res.data.barges);
                setPcData(
                    {
                        ['purchaseContractId']: res.data.purchaseContractId,
                        ['purchaseContractNo']: res.data.purchaseContractNo,
                        ['supplierName']: res.data.supplierName
                    }
                )
            } else {
                setGridData([]);
                setPcData(null);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
            setGridData([]);
            setPcData(null);
        })
    };

    const pcOnChange = () => {
        getBusinessAllocationDropDown();
        getBargesForPurFinancialCoalCosting();
    }

    const bnOnChange = () => {
        getBargesForPurFinancialCoalCosting();
    }

    const tableColumns = [
        { title: 'Costing ID', dataIndex: 'costingId' },
        {
            title: 'Barge ID', dataIndex: 'bargeNo',
            render: (text, record) => <><a className="link-primary" onClick={() => redirectToBargeDetailsFullView(record.bargeNo)}>
                {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
            </a></>
        },
        { title: 'Barge Nomination', dataIndex: 'bargeNomination' },
        { title: 'Business No', dataIndex: 'businessNo' },
        { title: 'Purchase Contract', dataIndex: 'purchaseContractNo' },
        { title: 'Quality', dataIndex: 'quality' },
        { title: 'Purchase Type', dataIndex: 'purchaseType' },
        { title: 'Quantity	' },
        { title: 'Price PMT	' },
        { title: 'Coal Price	' },
        { title: 'PPH22	' },
        { title: 'Advance Adjustment	' },
        { title: 'Total Payable	' },
        { title: 'Paid	' },
        { title: 'Remaining' },
        { title: 'Action' }
    ]


    return (
        <div >
            <Form layout='vertical' form={formRef}>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
                        <Form.Item
                            label="Purchase Contract"
                            rules={[{ required: true, message: 'Purchase Contract is required' }]}
                            name="purchaseContract"
                        >
                            <Select
                                showSearch
                                onChange={pcOnChange}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {pcs.map(item => {
                                    return <Option value={item.pcId}>{item.contractAndSupplier}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 7, offset: 1 }}>
                        <Form.Item
                            label="Business Number"
                            name="businessNumber"
                        >
                            <Select
                                mode='multiple'
                                onChange={bnOnChange}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {businessNumbers.map(item => {
                                    return <Option value={item.businessNoId}>{item.businessNo}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 2, offset: 6 }}>
                        <Form.Item
                            style={{ marginTop: "29px" }}
                        >
                            <Button type="primary" onClick={() => {
                                setVisible(true);
                            }}>New</Button>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
            {pcData && <Card title={`${pcData?.purchaseContractNo} - ${pcData?.supplierName}`}>
                <Table
                    className='contracts'
                    columns={tableColumns}
                    dataSource={gridData}
                    scroll={{ x: 500 }}
                    size="large"
                    bordered
                  
                />
            </Card>}
            <Drawer
                title={`Coal Costing Purchase Financial for Barge (${coalCostingType})`}
                width={900}
                onClose={() => {
                    setVisible(false);
                }}
                open={visible}
                bodyStyle={{ paddingBottom: 0, overflowY: 'unset' }}
                footer={undefined}
            >
                <CoalCostingStepper cancelHandler={() => setVisible(false)} coalCostingType={coalCostingType} />
            </Drawer>
        </div>
    )
}

export default CoalCostingsGrid;