
import { CalculationTableDto, ContractIdRequest, ContractModesEnum, CostingTypesEnum, PcPurchaseTypeReq, ProcurementContractService, ProcurementContractTypeEnum, PurchaseContractService, PurchaseDropDownDto, SalesContractService } from '@exportx/shared-models-and-services';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';

const { Option } = Select;

export interface IProcurementFilterInitialValues {
    businessNumber: string;
    contractId: string;
    qualityId: string;
    incoterm: string;
    purchaseType: string;
    costingDate: any;
    costingId?: string;
    pcrContractId?: string;
    bnId?: string;
}

interface IProcurementFilterComponent {
    loadBargesOnClickHandler: (values: IProcurementFilterInitialValues) => void;
    initialValues?: IProcurementFilterInitialValues;
    contractType: ContractModesEnum;
    formRef: FormInstance<any>;
    setBargeData?: React.Dispatch<React.SetStateAction<CalculationTableDto[]>>
    pcrContractType?: ProcurementContractTypeEnum;
    costingType?: CostingTypesEnum
}
export const MarketingFilterComponent = (props: IProcurementFilterComponent) => {
    const { authContext } = useAuthState()
    const { setBargeData, loadBargesOnClickHandler, formRef, contractType, initialValues, pcrContractType } = props;
    const [purchaseContracts, setPurchaseContracts] = useState<PurchaseDropDownDto[]>([]);
    const [qualitiesData, setQualitiesData] = useState([]);
    const [purchaseTypesData, setPurchaseTypesData] = useState([]);
    const [buttonDisableHandler, setButtonDisableHandler] = useState(true);
    const [procurement, setProcurement] = useState<any[]>([]);


    const purchaseContractService = new PurchaseContractService();
    const salesContractService = new SalesContractService();
    const procurementContractService = new ProcurementContractService();

    useEffect(() => {
        procurementDropDown();
    }, [contractType]);

    useEffect(() => {
        if (initialValues) {
            handleEditClick();
        }
        if (initialValues?.pcrContractId) {
            formRef.setFieldValue('pcrContractId', initialValues?.pcrContractId);
            procurementContractOnchange(initialValues?.pcrContractId);
            purchaseContractOnChange(initialValues?.contractId);
            formRef.setFieldValue('contractId', initialValues?.contractId);
            qualityOnChange(initialValues?.qualityId);
            purchaseTypeOnChange(initialValues?.purchaseType);
            getQualityAndLaycanDropdown(initialValues?.pcrContractId);
            formRef.setFieldValue('qualityId', initialValues?.qualityId);

        };
    }, [initialValues])

    if (initialValues?.costingDate) {
        initialValues.costingDate = moment(initialValues?.costingDate)
    }

    async function handleEditClick() {
        formRef.setFieldsValue({ contractId: initialValues?.contractId, costingDate: moment(initialValues?.costingDate) });
        formRef.setFieldsValue({ qualityId: initialValues?.qualityId })
        await qualityOnChange(initialValues?.qualityId)
        formRef.setFieldsValue({ purchaseType: initialValues?.purchaseType })
        purchaseTypeOnChange(initialValues?.purchaseType)
        bargeOnClickHandler();
    }




    const getQualityAndLaycanDropdown = (pcId: string) => {
        purchaseContractService.getQualityAndLaycanDropdown(new ContractIdRequest(pcId, authContext.defaultPlant, undefined, pcrContractType)).then(res => {
            if (res.status) {
                setQualitiesData(res.data);
            } else {
                setQualitiesData([]);
                setPurchaseTypesData([]);

            }
        }).catch(err => {
            setQualitiesData([]);
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        })
    };






    const procurementContractOnchange = async (contractId?: string) => {
        formRef.setFieldValue('contractId', contractId ? contractId : undefined);
        await purchaseContractOnChange(undefined);
        getAllSaleContractsForDropDown();
        getSalesQualityAndLaycanDropdown(contractId);

        setBargeData([]);
    };

    const purchaseContractOnChange = async (pcId: string) => {
        if (pcId) {
            getSalesQualityAndLaycanDropdown(pcId);
        } else {
            formRef.setFieldValue('qualityId', '');
            formRef.setFieldValue('purchaseType', '');
            setQualitiesData([]);
            setPurchaseTypesData([]);
            formRef.setFieldValue('allocatedId', undefined);
        }
        setBargeData([]);
    }



    const qualityOnChange = async (pcQualitiesId: string) => {
        if (pcQualitiesId) {
            getScSalesTypesDropDown(pcQualitiesId);
        } else {
            formRef.setFieldValue('purchaseType', '');
            setPurchaseTypesData([]);
        }
    }

    const purchaseTypeOnChange = (pcTypeId: string) => {
        if (pcTypeId) {
            const pcType = purchaseTypesData?.filter(rec => rec.pcTypeId == pcTypeId)[0];
            formRef.setFieldValue('incoterm', pcType?.purchaseType);
            setButtonDisableHandler(false);
        } else {
            formRef.setFieldValue('incoterm', '');
            setButtonDisableHandler(true);
        }
    }

    const bargeOnClickHandler = () => {
        formRef.validateFields().then(formData => {
            loadBargesOnClickHandler(formData);
        });
    };

    const getAllSaleContractsForDropDown = () => {
        salesContractService.getAllSaleContractsForDropDown({
            plantCode: authContext.defaultPlant,
            pcrContractType: pcrContractType,
            pcrContractId: initialValues?.pcrContractId ? initialValues?.pcrContractId : formRef.getFieldValue('pcrContractId')
        }).then(res => {
            if (res.status) {
                setPurchaseContracts(res.data);
                formRef.setFieldValue('contractId', res.data[0]?.pcId);
                setQualitiesData([]);
                setPurchaseTypesData([]);
            } else {
                setPurchaseContracts([]);
                setQualitiesData([]);
                setPurchaseTypesData([]);
            }
        }).catch(err => {
            setPurchaseContracts([]);
            setQualitiesData([]);
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        })
    };


    const getSalesQualityAndLaycanDropdown = (pcId: string) => {
        salesContractService.getQualityAndLaycanDropdown(new ContractIdRequest(pcId, authContext.defaultPlant, undefined, pcrContractType)).then(res => {
            if (res.status) {
                setQualitiesData(res.data);
            } else {
                setQualitiesData([]);
                setPurchaseTypesData([]);
            };
        }).catch(err => {
            setQualitiesData([]);
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        });
    };

    const getScSalesTypesDropDown = (pcQualitiesId: string) => {
        salesContractService.getScSalesTypesDropDown(new PcPurchaseTypeReq(pcQualitiesId, pcrContractType)).then(res => {
            if (res.status) {
                setPurchaseTypesData(res.data);
            } else {
                setPurchaseTypesData([]);
            }
        }).catch(err => {
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        });
    };


    const procurementDropDown = () => {
        const req = new ContractIdRequest(undefined, authContext.defaultPlant, pcrContractType)
        procurementContractService.procurementDropDown(req).then(res => {
            if (res.status) {
                setProcurement(res.data);
            } else {
                console.log(res.internalMessage);
            }
        }).catch(err => console.log(err.message));
    }


    return (
        <div>
            <Form form={formRef} layout='vertical' autoComplete='off' initialValues={initialValues}>

                <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label={"Marketing Fees"}
                            name="pcrContractId"
                            rules={[{ required: true, message: `${"Marketing Fees"} Is Required ` }]}
                        >
                            <Select
                                onChange={(e) => procurementContractOnchange(e)}
                                allowClear
                                showSearch
                                placeholder={"Marketing Fees"}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {procurement.map(item => {
                                    return <Option style={{ width: 250 }} value={item.pcID}>{item.contractNo}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            hidden
                            name={'allocatedId'}>
                            <Input>
                            </Input>
                        </Form.Item>
                        <Form.Item
                            hidden
                            name={'businessNumber'}>
                            <Input>
                            </Input>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label={`Sales Contract`}
                            rules={[{ required: true, message: 'Sales Contract is required' }]}
                            name="contractId"
                        >
                            <Select
                                onChange={purchaseContractOnChange}
                                allowClear
                                showSearch
                                placeholder={`Select Sales  Contract No`}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {purchaseContracts.map(item => {
                                    return <Option style={{ width: 250 }} value={item.pcId}>{item.contractAndSupplier}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label="Laycan (Quality)"
                            name="qualityId"
                            rules={[{ required: true, message: 'Laycan (Quality) is required' }]}
                        >
                            <Select
                                allowClear
                                onChange={qualityOnChange}
                                showSearch
                                placeholder='Select Laycan'
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {qualitiesData?.map(item => {
                                    return <Option value={item.qualityId} style={{ width: 250 }}>{moment(item.laycan[0]).format('DD MMM YY') + ' - ' + moment(item.laycan[1]).format('DD MMM YY')} - ({`${item.quality}`})</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label="Sales Type"
                            name="purchaseType"
                            rules={[{ required: true, message: 'Contract Type is required' }]}
                        >
                            <Select
                                allowClear
                                onChange={purchaseTypeOnChange}
                                showSearch
                                placeholder={`Select Sales Type`}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {purchaseTypesData?.map(item => {
                                    return <Option value={item?.pcTypeId}>{`${item?.purchaseType} ${item?.jettyName ? `- (${item?.jettyName})` : ''}`}</Option>
                                })}
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }}>
                        <Form.Item  label={'Date'} name='costingDate' rules={[{ required: true, message: 'Costing Date is required' }]}>
                            <DatePicker style={{ width: '80%' }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 1 }}>
                        <Form.Item
                            style={{ marginTop: "24px" }}
                        >
                            <Button type="primary" disabled={buttonDisableHandler} onClick={bargeOnClickHandler}>Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name='incoterm' style={{ display: 'none' }}>
                    <Input />
                </Form.Item>
            </Form>
        </div>
    )
}

export default MarketingFilterComponent;