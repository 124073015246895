import { Space, Dropdown, Button } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';
interface ISaveAndNextButtonProps {
    goto: number[];
    goToOnClickHandler: (info: any) => void
    saveButtonHandler: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>
}
export const SaveAndNextButton = (props: ISaveAndNextButtonProps) => {
    const { goto, goToOnClickHandler, saveButtonHandler } = props;
    const { formatMessage: fm } = useIntl();
    return (
        <Space>
            <Dropdown.Button icon={<DownOutlined />} menu={{
                items: goto.map(item => {
                    return {
                        label: `${item}`,
                        key: `${item}`,
                    }
                }), onClick: goToOnClickHandler
            }}>{fm({ id: "common.goTo", defaultMessage: "Go To" })}</Dropdown.Button>

            <Button onClick={saveButtonHandler} type="primary">
                Save and Next
            </Button>
        </Space>
    )
}

export default SaveAndNextButton;