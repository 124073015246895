import { EditFilled, EyeOutlined, RightSquareOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Drawer, Popconfirm, Switch, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';

import { DestinationDto, DestinationService } from '@exportx/shared-models-and-services';
import { Link, useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist } from '../../../../../common';
import DestinationForm from '../destination-form/destination-form';
import './destination-grid.css';

/* eslint-disable-next-line */


export function DestinationGrid() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const destinationService = new DestinationService();
  const [selectedDestinationData, setSelectedDestinationData] = useState<any>(undefined);
  const [destinationData, setDestinationData] = useState<DestinationDto[]>([]);
  const navigate = useNavigate()


  const openFormWithData = (viewData: DestinationDto) => {
    setSelectedDestinationData(viewData);
    navigate('/destination-form', { state: { destinationData: viewData, isUpdate: true } })
  }

  useEffect(()=>{
      if(!isPermissionExist([63])){
        return navigate('/')
      }
  },[])

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAll = () => {
    destinationService.getAllDestination().then(res => {
      if (res.status) {
        setDestinationData(res.data);
      } else {
        if (res.errorCode) {
          setDestinationData([]);
        }
      }
    }).catch(err => {
      setDestinationData([]);
    })
  }



  const deleteProfile = (destinationData: DestinationDto) => {
    destinationData.isActive = destinationData.isActive ? false : true;
    destinationService.ActivateorDeactivateDestination(destinationData).then(res => {
      console.log(res);
      if (res.status) {
        getAll();
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        if (res.errorCode) {
          AlertMessages.getErrorMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const columnsSkelton: ColumnProps<any>[] = [
    {
      title: 'Country Name',
      dataIndex: 'countryName',
      render: (text, rowData) => { return rowData.countryName },
      sorter: (a, b) => a.countryName.localeCompare(b.countryName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Province Name',
      dataIndex: 'destinationName',
      sorter: (a, b) => a.destinationName.trim().localeCompare(b.destinationName.trim()),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: `Action`,
      dataIndex: 'action',
      align: "center",
      render: (text, rowData) => (
        <span>
          {isPermissionExist([63]) && (
          <Tooltip title={'View'}  >
            <EyeOutlined style={{ color: "#1890ff", fontSize: '24px' }} onClick={() => (navigate('/destination-form', { state: { destinationData: rowData, hidden: true } }))}></EyeOutlined>
          </Tooltip>)}
          {isPermissionExist([64]) && (
          <EditFilled type="edit"
            onClick={() => {
              if (rowData.isActive) {
                openFormWithData(rowData);
              } else {
                AlertMessages.getErrorMessage('You Cannot Edit Deactivated Destination');
              }
            }}
            style={{ color: '#1890ff', fontSize: '24px' }}
          />)}

          <Divider type="vertical" />
          {isPermissionExist([65]) && (
          <Popconfirm onConfirm={e => { deleteProfile(rowData); }}
            title={
              rowData.isActive
                ? 'Are you sure to Deactivate ?'
                : 'Are you sure to Activate ?'
            }
          >
            <Switch size="default"
              className={rowData.isActive ? 'toggle-activated' : 'toggle-deactivated'}
              checkedChildren={<RightSquareOutlined type="check" />}
              unCheckedChildren={<RightSquareOutlined type="close" />}
              checked={rowData.isActive}
            />

          </Popconfirm>)}
        </span>
      )
    }
  ];
  const closeDrawer = () => {
    setDrawerVisible(false);
  }
  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  }




  return (
    <Card
      className='default-card-class'
      title={<span> Province </span>}
      extra={<>{isPermissionExist([62]) && (<Link to='/destination-form' ><span ><Button>Create </Button> </span></Link>)}</>} >
      <Table
        rowKey={record => record.destinationId}
        columns={columnsSkelton}
        dataSource={destinationData}
        scroll={{ x: true }}
        pagination={false}
        onChange={onChange}
        bordered />
      <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '85%' : '85%'}
        onClose={closeDrawer} open={drawerVisible} closable={true}>
        <Card headStyle={{ textAlign: 'center', fontWeight: 500, fontSize: 16 }} size='small'>
          <DestinationForm key={Date.now()}
            isUpdate={true}
            destinatonData={selectedDestinationData}
            closeForm={closeDrawer} />
        </Card>
      </Drawer>
    </Card>
  );
}





export default DestinationGrid;
