import { Card, Tabs } from "antd";
import AgingReportTab from "./aging-report-tab";
import { ContractModesEnum } from "@exportx/shared-models-and-services";

const { TabPane } = Tabs;

interface IProps {
  contractType: ContractModesEnum
}
export const AgingReport = (props: IProps) => {

  return (
    <Card title="Aging Report">
      <Tabs >
        <TabPane tab="Delayed Samples Report" key="1">
          <AgingReportTab contractType={props.contractType} />
        </TabPane>

      </Tabs>
    </Card>

  )
}

export default AgingReport;