import { BpCategoryGetDto, BPDropDownDto, BusinessPartnerService, BusinessPartnerTypeEnum } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Form, FormInstance, Input, Row, Select } from 'antd'
import DatePicker from '../../../../common/data-picker/date-picker';
import FormItem from 'antd/es/form/FormItem';
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';


const { Option } = Select;
interface IDownPaymentsProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}



const DownPayments = (props: IDownPaymentsProps) => {
  const { formRef, layoutType, initialValues } = props;
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };
  const { formatMessage: fm } = useIntl();
  const businessPartnerService = new BusinessPartnerService();
  const [vendorsData, setVendorsData] = useState<BPDropDownDto[]>([]);
  const suffixSelector = (
    <Form.Item name="suffix" noStyle label="Please Select">
      <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 80 }}>

        <Option value="IDR">IDR</Option>
        <Option value="USD">USD</Option>
      </Select>
    </Form.Item>
  );

  useEffect(() => {
    getAllBusinessPartnersForDropDown(new BpCategoryGetDto(BusinessPartnerTypeEnum.VENDOR));
  }, []);

  const getAllBusinessPartnersForDropDown = (bpCategoryGetDto: BpCategoryGetDto) => {
    businessPartnerService.getAllBusinessPartnersForDropDown(bpCategoryGetDto).then(res => {
      if (res.status) {
        if (bpCategoryGetDto.businessPartnerType === BusinessPartnerTypeEnum.VENDOR)
          setVendorsData(res.data);

      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }
  return (

    <Card>



      <Card title="Add details to create Down Payment" >
        <Form initialValues={initialValues}
          form={formRef}
          layout={layoutType}
          autoComplete="off"
          onFinish={onFinish}>

          <Row>
            <FormItem name="contractType" rules={[{ required: true, message: 'Please select Contract Type' }]}
              label={fm({ id: "mdm.components.advance.contractType", defaultMessage: 'Contract Type' })}>
              <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder="Please select Contract Type" >
                <Option value="Vendor Advance"><span >Vendor Advance</span></Option>
                <Option value="Down Payments"><span>Down Payments</span></Option>
              </Select>
            </FormItem>
          </Row>
          <br></br>

          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 6 }} >
              <Form.Item
                // label="Vendor " 
                name="vendorId"
                label={fm({ id: "mdm.components.downPayments.vendorId", defaultMessage: 'Vendor' })}
                rules={[{ required: true, message: 'Vendor' }]}>
                <Select filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder={fm({ id: `mdm.components.downPayments.reqVendorId`, defaultMessage: `Please Search Vendor` })}>
                  <Option value={''}>Please Select</Option>
                  {vendorsData.map(item => {
                    return <Option value={item.bpId}>{item.bpName}</Option>
                  })}
                </Select>

              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }} offset={2} >
              <Form.Item
                name="contractDate"
                label={fm({ id: "mdm.components.downPayments.contractDate", defaultMessage: 'Contract Date' })}

                rules={[{ required: true, message: 'Contract Date' }]} >
                <DatePicker style={{ width: "250px" }} format="YYYY-MM-DD" />
              </Form.Item></Col>

            <Col xs={{ span: 24 }} lg={{ span: 6 }} offset={2}>
              <Form.Item
                name="advanceAmount"
                label={fm({ id: "mdm.components.downPayments.downPaymentAmount", defaultMessage: 'Down Payment Amount' })}

                rules={[{ required: true, message: 'Down Payment Amount' }]}>
                <Input
                  placeholder={fm({ id: `mdm.components.downPayments.reqDownPaymentAmount`, defaultMessage: `Down Payment Amount` })} autoComplete='off'
                  addonAfter={suffixSelector}></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} >
              <Form.Item label={fm({ id: 'mdm.components.downPayments.remarks', defaultMessage: 'Remarks' })}
                name="remarks"

              >

                <Input.TextArea />
              </Form.Item></Col>

            <Button onClick={() => console.log(formRef.getFieldsValue(), "values")}> button</Button>
          </Row>


          {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item> */}

        </Form>
        {props?.children}
      </Card>
    </Card>

  )
}

export default DownPayments