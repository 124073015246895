import { Affix, Button, Card, Col, Descriptions, Divider, Form, Layout, Row, Space, Tabs, Tag } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BusinessNumberService, ContractIdRequest, ContractModesEnum, ContractStatusEnum, ContractTypesEnum, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, PurchaseContractDto, PurchaseContractService, TaxesAndItemsService, } from '@exportx/shared-models-and-services';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import { useIntl } from 'react-intl';
import QualityDetailView from './quality-detail-view';
import { getFilesData } from '@exportx/ui-utils';
import SurveyorDetailsView from './surveyor-details-view';
import ContractDetailsView from './contract-details-view';
import PaymentTermsView from './payment-terms-view';
import ShippingTermsView from './shipping-terms-view';
import CommercialDocumentsView from './commercial-documents';
import AllPurchaseContractDetailedView from './all-purchase-contracts-detailed-view';
import queryString from 'query-string';
import { ArrowLeftOutlined, CheckCircleFilled, CloseCircleFilled, DownloadOutlined, EditFilled } from '@ant-design/icons';
import TabPane from 'antd/es/tabs/TabPane';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckCircle, faCircleXmark, faDownload } from '@fortawesome/free-solid-svg-icons';

export const PurchaseContractDetailView = () => {
  const { authContext } = useAuthState();
  const { formatMessage: fm } = useIntl();
  const printRef = React.useRef();
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState<PurchaseContractDto>(null);
  const [snapData, setSnapData] = useState<PurchaseContractDto[]>([]);
  const [contractDetails, setContractDetails] = useState(null);
  const [shippingTerms, setShippingTerms] = useState(null);
  const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
  const [allocateData, setAllocateData] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [filesFormRef] = Form.useForm();
  const [refresh, setRefresh] = useState<number>(0);
  const service = new PurchaseContractService();
  const taxesAndItemsService = new TaxesAndItemsService();
  const location = useLocation();
  const paramPcId = queryString.parse(location.search)?.pc_id;
  const paramPlantCode = queryString.parse(location.search)?.plantCode;
  const businessService = new BusinessNumberService();
  

  let { contractId }: any = paramPcId != null ? { contractId: paramPcId } : { contractId: null };
  let { plantCode }: any = paramPlantCode != null ? { plantCode: paramPlantCode } : { plantCode: authContext.defaultPlant };


  useEffect(()=>{
    if(!isPermissionExist([111])) return navigate('/')
  },[])
  useEffect(() => {
    if (contractId) {
      getSalesPreviewData(contractId, plantCode);
    }
  }, [contractId, refresh]);

  const getAssociatedBusinessNoByPcId = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode: authContext.defaultPlant, contractType: previewData?.contractType };
    businessService.getAllocationByPcId(req).then(res => {
        if (res.status) {
          setAllocateData(res.data)
            console.log('data----', res.data.allocationData);
        }
    }).catch(err => {
        console.log(err.message);
    })
}

  const getSalesPreviewData = (contractId: string, plantCode: string) => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.getPurchasePreviewData(req).then(res => {
      if (res.status) {
        setPreviewData(res.data);
        setSnapData(res?.snapData ? res.snapData : []);
        setContractDetails(res.data.contractDetails);
        if(res.data.contractDetails.contractDate){
          getTaxesByItemsId(res.data.contractDetails.contractDate)
        }
        setOpenModal(false);
        setShippingTerms(res.data.shippingTerms);
        getAssociatedBusinessNoByPcId();
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        setOpenModal(false);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
      setOpenModal(false);
    })
  }

  const getTaxesByItemsId = (contractDate) => {
    const req = new ItemsIdRequestDto(ItemsCodesEnum.COAL, contractDate)
    taxesAndItemsService.getVatByItemsId(req).then(res => {
      if (res.status) {
        setVat(res.data);
      } else {
        setVat([]);
      }
    }).catch(err => {
      console.log(err);
      setVat([]);
    });
  }

  const handleDownloadPdf = async () => {
    console.log('print the pdf');
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('print.pdf');
  };



  const sendForApproval = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.sendForApproval(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Purchase  Order Sent successfully' }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  // const addendumApproval = () => {
  //   const req = { ...new ContractIdRequest(contractId), plantCode };
  //   service.approvePurchaseAddendum(req).then(res => {
  //     if (res.status) {
  //       getSalesPreviewData(contractId, plantCode);
  //       AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Purchase Order Addendum Sent successfully' }));
  //     } else {
  //       AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const PurchaseForReject = () => {
    const req = new ContractIdRequest(contractId, authContext.defaultPlant)
    service.purchaseForReject(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.error);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  const purchaseForApproval = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode, userName: authContext.user.userName};
    if (previewData?.contractType === ContractTypesEnum.ADDENDUM) {
    service.approvePurchaseAddendum(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Purchase Order Addendum Sent successfully' }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }else{
      service.purchaseForApproval(req).then(res => {
        if (res.status) {
          getSalesPreviewData(contractId, plantCode);
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.error);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }

  }



  const addendumClickHandler = () => {
    navigate('/po-addendum', { state: previewData });
  }



  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button className='new-button' onClick={() => sendForApproval()}>Send For Approval</Button></>
    }
    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <> {isPermissionExist([119]) && <Button size="small" className='approve'  onClick={() => purchaseForApproval()}><FontAwesomeIcon icon={faCheckCircle} />Approve</Button>}
   
      {isPermissionExist([120]) && <Button size="small" className='reject' onClick={() => PurchaseForReject()}><FontAwesomeIcon icon={faCircleXmark} />Reject</Button>}</>;
    }

   
    // if (status === ContractStatusEnum.PENDING_APPROVAL) {
    //   return <><Button hidden={previewData?.contractType === ContractTypesEnum.ADDENDUM ? false : true} onClick={() => addendumApproval()}>Approve</Button></>;
    // }

    if (status === ContractStatusEnum.ACTIVE && isPermissionExist([116])) {
      return <><Button className='new-button' hidden={previewData?.contractType === ContractTypesEnum.ADDENDUM ? true : false} onClick={() => addendumClickHandler()} >Create Addendum</Button></>
    }
  }

  const setFormData = () => {
    navigate('/po-update', { state: previewData });
  }

  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      console.log('fileValues ===>', fileValues)

      getFilesData(fileValues.filesData).then(filesData => {
        console.log('filesData ===>', filesData)
        setPreviewData((prevState) => { return { ...prevState, filesData: [...prevState.filesData, ...filesData] } });
        service.addMoreFilesToPurchaseOrder({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          contractId: contractId
        }).then(res => {
          if (res.status) {
            getSalesPreviewData(contractId, plantCode);
            filesFormRef.resetFields();
            AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Sale Order Sent successfully' }));
          } else {
            AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      });
    });
  }

  const pcQuantityTotal = previewData?.qualityDetails.qualityData.reduce((a, c) => a + Number(c.quantity), 0);

  console.log(previewData?.surveyorDetails);

  return (
    <>
      <Layout style={{ backgroundColor: '#F8F8F8', border: 10 }}>
        {/* <Card  bordered={false} style={{padding:'0px'}} title={<Space size={5}>
               <a href="#/po-tracker" style={{fontSize: '18px', color: '#6B7280' }}>
               <FontAwesomeIcon icon={faArrowLeft} />
               </a>
               <span>{contractDetails?.['contractNo']} Purchase Details</span>&nbsp;
               <StatusTag status={contractDetails?.['status']} />
              </Space>} 
              headStyle={{border: 'none'}}
              style={{backgroundColor: '#F8F8F8'}}
              ref={printRef}
               extra={<Space size={10}>
              <Button icon={<FontAwesomeIcon icon={faDownload} />} style={{ border: 'none', fontSize: '15px', padding: '7px', borderRadius: '50%', color: '#0295D4', backgroundColor: '#DEECF3'}} onClick={handleDownloadPdf}></Button>
              {isPermissionExist([112]) && (contractDetails?.['status'] === ContractStatusEnum.DRAFT || contractDetails?.['status'] === ContractStatusEnum.PENDING_APPROVAL) 
              && <Button  icon={<EditFilled />} style={{ border: 'none', fontSize: '15px', padding: '7px', borderRadius: '50%', color: '#0295D4', backgroundColor: '#DEECF3'}} onClick={setFormData}></Button>}
                {getExtraComponent(contractDetails?.['status'])}
              </Space>}
              > */}

          <div style={{ backgroundColor: '#F5F5F5', padding: '0px' }} ref={printRef}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 'none', marginBottom: '10px' }}>
              <Space size={5}>
                <a href="#/po-tracker" style={{ fontSize: '18px', color: '#6B7280' }}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </a>
                <span style={{fontSize:'16px', fontWeight: 600, color: '#323F49'}}>{contractDetails?.['contractNo']} Purchase Details</span>&nbsp;
                <StatusTag status={contractDetails?.['status']} />
              </Space>
              <Space size={10}>
                <Button
                  icon={<FontAwesomeIcon icon={faDownload} />}
                  style={{
                    border: 'none',
                    fontSize: '15px',
                    padding: '7px',
                    borderRadius: '50%',
                    color: '#0295D4',
                    backgroundColor: '#DEECF3'
                  }}
                  onClick={handleDownloadPdf}
                />
                {isPermissionExist([112]) && (contractDetails?.['status'] === ContractStatusEnum.DRAFT || contractDetails?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && (
                  <Button
                    icon={<EditFilled />}
                    style={{
                      border: 'none',
                      fontSize: '15px',
                      padding: '7px',
                      borderRadius: '50%',
                      color: '#0295D4',
                      backgroundColor: '#DEECF3'
                    }}
                    onClick={setFormData}
                  />
                )}
                {getExtraComponent(contractDetails?.['status'])}
              </Space>
              </div>  
         
          {/* <br></br> */}
        {/* <Row justify="end">
          <Col><> <Button href="#/po-tracker" style={{ color: 'black' }}>PO Tracker</Button> <Button style={{ marginLeft: 20 }} onClick={handleDownloadPdf}>Download Purchase Order</Button>
          </></Col></Row> */}
        {/* <Card
          title={<span ><a href="#/po-tracker" style={{ color: 'black' }}><ArrowLeftOutlined /></a> {contractDetails?.['contractNo']} Purchase Details
           <StatusTag status={contractDetails?.['status']} /> 
          </span>}
          style={{ background: 'transparent' }}
          headStyle={{ backgroundColor: '#2196f3', color: '#fff' }}
          ref={printRef}
          extra={<>{getExtraComponent(contractDetails?.['status'])}</>}
          > */}
          {/* <br></br> */}
          <ContractDetailsView addendumData={previewData?.addendumData} contractDetails={contractDetails} shippingTerms={shippingTerms} contractMode={ContractModesEnum.PURCHASE} contractType={previewData?.contractType} plantCode={plantCode} snapShotData={snapData} allocateData={allocateData} />
          <br></br>
          <Card title={<span style={{fontSize: '16px', fontWeight: 600}}>Laycan Details</span>} headStyle={{ paddingTop: '8px', border: 0 }} bordered={false} bodyStyle={{ paddingTop: '12px', paddingBottom: 0, overflow: 'auto' }} style={{borderRadius: '8px'}}>
            <QualityDetailView classes='contracts' qualityData={previewData?.qualityDetails?.qualityData} type={ContractModesEnum.PURCHASE} plantCode={plantCode} snapShotData={snapData} contractType={previewData?.contractType} vat={vat}/>
          
            {previewData?.surveyorDetails.length > 0 && (<Divider dashed={true}></Divider>)}
          {previewData?.surveyorDetails.length > 0 && (<span style={{fontSize: '16px', fontWeight: 600, padding: '10px'}}>Surveyor Details</span>)}
          
          <SurveyorDetailsView surveyorDetails={previewData?.surveyorDetails} snapShotData={snapData} contractType={previewData?.contractType} />
          </Card>

          <br></br>

          <Row gutter={[16, 12]}>
            <Col
              span={previewData?.contractType === ContractTypesEnum.ADDENDUM ? 16 : 24}
              xs={24}
              md={24}
              lg={previewData?.contractType === ContractTypesEnum.ADDENDUM ? 16 : 24}
              xl={previewData?.contractType === ContractTypesEnum.ADDENDUM ? 16 : 24}
              style={{ display: 'flex' }}
            >

          <Card title="Additional terms" bordered={false} headStyle={{ fontSize: '16px', fontWeight: 600, color: '#323F49', border: 'none', paddingTop: '10px'}} style={{ width: '100%', borderRadius: '8px'}}>
          <Tabs tabPosition='left' tabBarStyle={{backgroundColor: '#F7F9FC'}}>
            <TabPane tab="Payment Terms" key="1">
          <PaymentTermsView paymentTerms={previewData?.paymentTerms} snapShotData={snapData} contractType={previewData?.contractType} />
          </TabPane>
          <TabPane tab="Shipping Terms" key="2">
          <ShippingTermsView shippingTerms={previewData?.shippingTerms} snapShotData={snapData} contractType={previewData?.contractType} />
          </TabPane>
          <TabPane tab="Commercial Terms" key="3">
          <CommercialDocumentsView commercialDocuments={previewData?.commercialDocuments} snapShotData={snapData} contractType={previewData?.contractType} />
          </TabPane>
          <TabPane tab="Remarks" key="4">
          <Row>
            {/* {previewData?.remarks &&  */}
            <Col xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}>
              <Card title={<span style={{ color: 'black' }}>{'Remarks'}</span>} style={{ textAlign: 'left', backgroundColor: '#F7F9FC',  height: '300px', overflowY: 'auto' }} bordered={false} headStyle={{ border: 0 }} bodyStyle={{paddingTop: '2px'}}>
              <Divider dashed style={{marginTop: 0, marginBottom: '10px'}}></Divider>
              <pre>
                  {previewData?.remarks}
                </pre>
              </Card>
            </Col>
          </Row>
          </TabPane>
          <TabPane tab="Audit Information" key="5">
          <Card title={<span style={{ color: 'black' }}>{'User Details'}</span>} style={{ textAlign: 'left', backgroundColor: '#F7F9FC', height: '300px', overflowY: 'auto'}} bordered={false} headStyle={{ border: 0 }} bodyStyle={{paddingTop: '2px'}}>
          <Divider dashed style={{marginTop: 0, marginBottom: '10px'}}></Divider>
          <Row>
          <Col>
          <Descriptions
                 column={
                  previewData?.contractType === ContractTypesEnum.ADDENDUM
                    ? { xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }
                    : { xxl: 4, xl: 4, lg: 3, md: 2, sm: 1, xs: 1 }
                }
                >
                <Descriptions.Item label="Created By" >
                <span className='descriptions'>{contractDetails?.createdBy}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Approved By" >
                <span className='descriptions'>{contractDetails?.approvedBy}</span>
                </Descriptions.Item>
                </Descriptions>
                </Col>
                <Col>
                <Descriptions
                 column={
                  previewData?.contractType === ContractTypesEnum.ADDENDUM
                    ? { xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }
                    : { xxl: 4, xl: 4, lg: 3, md: 2, sm: 1, xs: 1 }
                }
                >
                <Descriptions.Item label="Created Date">
                <span className='descriptions'>{contractDetails?.createdDate ? moment(contractDetails?.createdDate).format('DD-MMM-YYYY h:mm A') : ''}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Approved Date">
                <span className='descriptions'>{contractDetails?.approvedOn ? moment(contractDetails?.approvedOn).format('DD-MMM-YYYY h:mm A') : ''}</span>
                </Descriptions.Item>
              </Descriptions>
                </Col>
                </Row>
            </Card>
          </TabPane>
          </Tabs>
          </Card>
          </Col>
          <br></br>

          {previewData?.contractType !== ContractTypesEnum.ADDENDUM && (
            <Col span={16} xs={24} md={24} lg={16} xl={16} style={{display: 'flex'}}>
            <Card bordered={false} style={{width: '100%', borderRadius: '8px'}}>
          <AllPurchaseContractDetailedView pcQuantityTotal={pcQuantityTotal} contractId={contractId} plantCode={plantCode} bpId={previewData?.contractDetails?.businessPartner} contractType={previewData?.contractType} allocateData={allocateData} contractDetails={contractDetails} />
          </Card>
          </Col>
          )}

          <Col span={8} xs={24} md={24} lg={8} xl={8} style={{display: 'flex'}}>
          {previewData?.filesData && isPermissionExist([122]) && (<FileUploadForm maxCount={3} layoutType='vertical' 
          formRef={filesFormRef} 
          initialValues={previewData?.filesData} 
          contractType={previewData?.contractType}
          formType='View'
          fileModal={openModal}
          setFileModal={setOpenModal}
          setRefresh={setRefresh}
          >
           
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button onClick={fileUploadFormHandler} type="primary" className='new-button'>
                      Save
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </FileUploadForm>)}
          </Col>
          </Row>
        {/* </Card> */}
        <br></br>
        {/* <Affix offsetBottom={0}>
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>
              <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
              <Col>{isPermissionExist([112]) && (contractDetails?.['status'] === ContractStatusEnum.DRAFT || contractDetails?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && <Button onClick={setFormData}>Edit</Button>}</Col>
            </Row>
          </Card>
        </Affix> */}

        </div>

        {/* </Card> */}

      </Layout >
    </>
  )
}
export default PurchaseContractDetailView;