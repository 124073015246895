import { Tabs } from "antd";
import ProcurementActiveApproval from "./procurement-approval/procurment-active";
import ProcurementPendingApproval from "./procurement-approval/procurment-pending";




const { TabPane } = Tabs;
const ProcurementServiceApproval = () => {
  return (

    <Tabs destroyInactiveTabPane={true}>
      <TabPane tab="Pending" key="1">
        <ProcurementPendingApproval />
      </TabPane>
      <TabPane tab="Approved" key="2">
        <ProcurementActiveApproval />
      </TabPane>


    </Tabs>


  )
}

export default ProcurementServiceApproval;