import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { AccountsService, AdvanceReceiversService, BargeCostingIdReqDto, BargeCostingsEnum, BargeCostingsService, CommercialCoalCostingService, ContractModesEnum, ContractStatusEnum, CostingInvoiceDto, CostingInvoiceReqDto, CostingInvoiceService, CostingWorkLogsEnum, ExpenseEntryService, LedgerUpdateDto, PaymentNoticeChildDto, PaymentNoticeChildReq, PaymentNoticeGetDataDto, PaymentNoticeService, PerformaCoalCostingService, PmtTransactionalDto, ReferenceFeatures, StatusUpdateReqDto, TransactionalIdReqDto, VendorAdvService } from '@exportx/shared-models-and-services';
import { ClassValidator, SequenceUtils, getLocalFormat } from '@exportx/ui-utils';
import { Button, Card, Col, Form, Input, InputNumber, List, Row, Select, Typography } from 'antd';
import Table from 'antd/es/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, StatusTag, useAuthState } from '../../../common';
import { PaymentNoticePageEnum } from './payment-notice';
import { PerformaApprovalDetails, BargeCostingDetails, CmApprovalDetails, ExpenseEntryCostingDetails, AccountPayableCostings } from '../costing-detail-view';
import ViewFileHandling from '../../../common/file-upload-form/view-files-handling';

interface IPaymentNoticeChildProps {
    record: PaymentNoticeGetDataDto;
    type: PaymentNoticePageEnum;
    getParentData: () => void;
    paymentType?: PaymentNoticePageEnum
}

const { Option } = Select;
export const PaymentNoticeChild = (prop: IPaymentNoticeChildProps) => {
    const paymentNoticeService = new PaymentNoticeService();
    const [formRef] = Form.useForm();
    const fileUploadPath = window[`_env_`]['APP_MDM_SERVICE_URL'];
    const { authContext } = useAuthState();
    const classValidator = new ClassValidator();
    const { type, record, getParentData, paymentType } = prop;
    const [ledgerAccounts, setLedgerAccounts] = useState([]);
    const [childData, setChildData] = useState<PaymentNoticeChildDto>(null);
    const [accountLedger, setAccountLedger] = useState(null);
    const [hiddenState, setHiddenState] = useState<boolean>(true);
    const commercialService = new CommercialCoalCostingService();
    const performaService = new PerformaCoalCostingService();
    const service = new BargeCostingsService();
    const expensiveService = new ExpenseEntryService();
    const [kycData, setKycData] = useState<any[]>([]);
    const [transactionalData, setTransactionalData] = useState<PmtTransactionalDto[]>([]);
    const invoiceService = new CostingInvoiceService();
    const [invoiceData, setInvoiceData] = useState<CostingInvoiceDto[]>([]);
    const [filteredInVoice, setFilteredInvoice] = useState<any>()
    const advanceService = new VendorAdvService();
    const advanceReceivablesService = new AdvanceReceiversService();
    const [expandedIndex, setExpandedIndex] = useState([]);



    useEffect(() => {
        if (type === PaymentNoticePageEnum.CREATE) {
            const accountsService = new AccountsService();
            const getAllAccountsData = () => {
                accountsService.getAllPmtLedgerAccounts().then((res => {
                    if (res.status) {
                        setLedgerAccounts(res.data)
                    } else {
                        setLedgerAccounts([])
                    }
                })).catch((err => {
                    setLedgerAccounts([])
                }))
            }
            getAllAccountsData();
        } else {
            const req = new BargeCostingIdReqDto(record.costingId, authContext.defaultPlant)
            paymentNoticeService.getPmtNoticeLedgerData(req).then(res => {
                if (res.status) {
                    setAccountLedger(res.data);
                } else {
                    setAccountLedger(null);
                }
            }).catch(err => {
                console.log(err)
                setAccountLedger(null);
            })
        }
        if (record.expenseType === BargeCostingsEnum.DEAD_FREIGHT) {
            const req = new BargeCostingIdReqDto(record.costingId, authContext.defaultPlant);
            getDeadFreightDataByCostingId(req);
        }

        else if (record.expenseType === ReferenceFeatures.EXPENSE_ENTRY) {
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            getExpensivePmtData(req);
        }

        else if (record.expenseType === BargeCostingsEnum.DEMURRAGE) {
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            getDemurragePmtData(req);
        }

        else if (record.expenseType === BargeCostingsEnum.BARGE_COSTINGS || record.expenseType === CostingWorkLogsEnum.BARGE_COSTING) {
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            getPaymentNoticeChildDataForBargeCosting(req);
        }

        else if (record.expenseType === BargeCostingsEnum.PERFORMA_PC_COSTING || record.expenseType === BargeCostingsEnum.PERFORMA_SC_COSTING) {
            const cType: ContractModesEnum = record.expenseType === BargeCostingsEnum.PERFORMA_PC_COSTING ? ContractModesEnum.PURCHASE : ContractModesEnum.SALES
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant, cType);
            getPmtPerformData(req);
        }

        else if (record.expenseType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING || record.expenseType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING || record.expenseType === CostingWorkLogsEnum.PROCUREMENT_FEES || record.expenseType === CostingWorkLogsEnum.MARKETING_FEES) {
            let contractType: ContractModesEnum;
            if (record.expenseType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING) {
                contractType = ContractModesEnum.PURCHASE;
            } else if (record.expenseType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING) {
                contractType = ContractModesEnum.SALES;
            } else if (record.expenseType === CostingWorkLogsEnum.PROCUREMENT_FEES) {
                contractType = ContractModesEnum.PROCUREMENT_FEES;
            } else if (record.expenseType === CostingWorkLogsEnum.MARKETING_FEES) {
                contractType = ContractModesEnum.MARKETING_FEES;
            }
            const req: PaymentNoticeChildReq = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant, contractType);
            commercialsData(req);
        } else if (record.expenseType === ReferenceFeatures.ADVANCE_INVOICE) {
            const req = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            advanceService.getAdvanceCostingData(req).then(res => {
                if (res.status) {
                    // res.data.basePrice = Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage));
                    // console.log(Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage)))
                    setChildData(res.data)
                } else {
                    setChildData(undefined)
                }
            })
        } else if (record.expenseType === ReferenceFeatures.ADVANCE_RECEIVABLES) {
            const req = new PaymentNoticeChildReq(record.paymentNoticeId, record.costingId, authContext.defaultPlant);
            advanceReceivablesService.getReceivablesCostingData(req).then(res => {
                if (res.status) {
                    // res.data.basePrice = Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage));
                    // console.log(Number(res.data.tdsTotal) * (100 / Number(res.data.tdsPercentage)))
                    setChildData(res.data)
                } else {
                    setChildData(undefined)
                }
            })
        }
        getAllCostingInvoice();

    }, []);

    useEffect(() => {
        setTransactionalData(record.transactionalData);
    }, [prop.record.transactionalData]);

    const getDeadFreightDataByCostingId = (req) => {
        paymentNoticeService.getDeadFreightDataByCostingId(req).then(res => {
            if (res.status) {

                setChildData(res.data);
            }
        }).catch(err => {
            console.log(err.message);
        });
    };

    const getAllCostingInvoice = () => {
        const req = new CostingInvoiceReqDto(record.costingId, authContext.defaultPlant, undefined, record.expenseType);
        invoiceService.getAllCostingInvoice(req).then(res => {
            if (res.status) {
                setInvoiceData(res.data?.filter((rec) => rec.status === ContractStatusEnum.ACTIVE));
            } else {
                setInvoiceData([]);
                console.log(res.internalMessage);

            };
        }).catch(err => console.log(err.message));
    };


    const getPaymentNoticeChildDataForBargeCosting = (req) => {
        service.getPaymentNoticeChildDataForBargeCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            };
        }).catch(err => {
            console.log(err);
        });
    };

    const getPmtPerformData = (req) => {
        performaService.getPaymentNoticeChildDataPerFormaCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            }
        }).catch((err) => console.log(err.message));
    };

    const commercialsData = (req) => {
        commercialService.getPaymentNoticeChildDataCommercialCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            }
        }).catch(err => {
            console.log(err.message);
        });
    };

    const getExpensivePmtData = (req) => {
        expensiveService.getPaymentNoticeForExpensiveCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            }
        }).catch(err => console.log(err.message));
    };

    const getDemurragePmtData = (req) => {
        service.getPaymentNoticeForDemurrageCosting(req).then(res => {
            if (res.status) {
                setChildData(res.data);
            }
        }).catch(err => console.log(err.message))
    }

    const bankAccountsHandler = (value, children) => {
        if (children === "Bank Accounts") {
            setHiddenState(false);
            paymentNoticeService.getKycDropDownData({ actLedger: childData?.bpId }).then(res => {
                if (res.status) {
                    setKycData(res.data);
                } else {
                    setKycData([]);
                    setHiddenState(true);
                }
            }).catch(err => console.log(err.message, "err message"))
        } else {
            setHiddenState(true);
        }
    };

    const invoiceOnChangeHandler = (value: string) => {
        const againstInvoiceData = invoiceData.filter((rec) => rec.invoiceId === value)[0];
        setFilteredInvoice(againstInvoiceData);
        const remainingInvoice = Number(againstInvoiceData?.invoiceAmount) - record?.transactionalData?.filter((rec) => rec.invoiceNo === value).reduce((a, c) => a + Number(c.transactionAmount), 0);
        setFilteredInvoice((prev) => { return { ...prev, remainingInvoice: remainingInvoice } });
    };


    const ledgerUpdate = (invoiceNo: string) => {
        formRef.validateFields().then(values => {
            if (Number(childData?.totalAmount) < Number(record?.transactionalTotalAmount) + Number(values.transactionAmount)) {
                return AlertMessages.getErrorMessage('Do Not Exceed Enter Payable Amount More Than Total Amount');
            };
            if (Number(filteredInVoice?.remainingInvoice) < Number(values?.transactionAmount)) {
                return AlertMessages.getErrorMessage('Do Not Exceed Enter Payable Amount More Than Total Amount');
            };
            const req = new LedgerUpdateDto(record.paymentNoticeId, authContext.user.userName, authContext.defaultPlant, values.actLedger, values.remarks, values.transactionAmount, values.kycId, invoiceNo, childData?.totalAmount);
            paymentNoticeService.updatePaymentNotice(req).then(res => {
                if (res.status) {
                    getParentData();
                    window.location.reload();
                } else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err);
            });
        });
    };


    const transactionAmountOnchangeHandler = (value: number) => {
        if (Number(childData?.totalAmount) < Number(record?.transactionalTotalAmount) + Number(value)) {
            formRef.setFieldValue('transactionAmount', 0)
            return AlertMessages.getErrorMessage('Do Not Exceed Enter Payable Amount More Than Total Amount')
        };
        if (Number(filteredInVoice?.remainingInvoice) < Number(value)) {
            formRef.setFieldValue('transactionAmount', 0)
            return AlertMessages.getErrorMessage('Do Not Exceed Enter Payable Amount More Than Total Amount')
        };
    }

    const statusApprovalAndReject = (status: ContractStatusEnum) => {
        let number = type === PaymentNoticePageEnum.APPROVAL ? 1 : 2;
        formRef.validateFields().then(values => {
            const req: any = new StatusUpdateReqDto(record.paymentNoticeId, status, authContext.user.userName, authContext.defaultPlant, number, number, values.remarks, Number(values.amountPayable));
            paymentNoticeService.statusApprovalAndReject(req).then(res => {
                if (res.status) {
                    getParentData();
                }
                else {
                    AlertMessages.getErrorMessage(res.internalMessage);
                }
            }).catch(err => {
                console.log(err);
            });
        });
    };

    const submitHandler = (invoiceNo: string, status: ContractStatusEnum) => {
        if (type === PaymentNoticePageEnum.CREATE) {
            ledgerUpdate(invoiceNo);
        } else {
            statusApprovalAndReject(status);
        };
    };

    const bg = [];
    const barges = childData?.businessNoAndBarge?.barges?.forEach((rec) => {
        if (rec.bargeNo)
            bg.push((rec?.bargeNo) + ` (${rec.bgNomination})`)
    });

    const approveTransactionRecord = (transId: string, pmtNoticeId: string, status: ContractStatusEnum) => {
        const req = new TransactionalIdReqDto(pmtNoticeId, transId, authContext.defaultPlant, status);
        paymentNoticeService.pmtTransactionalApproval(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                window.location.reload();
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            };
        }).catch(err => console.log(err.message, 'err message'));
    };

    const approvalTransactionalTotalAmount = transactionalData?.reduce((a, c) => a + Number(c.transactionAmount), 0);
    const remainingAmount = Number(childData?.totalAmount) - Number(record?.transactionalTotalAmount);

    const columns = [
        {
            title: 'Act Grp',
            dataIndex: 'actGrp',
        },
        {
            title: 'Act Ledger',
            dataIndex: 'actLedger',
        },
        {
            title: 'Bank Name',
            dataIndex: 'bankName',
        },
        {
            title: 'Paid Date',
            dataIndex: 'paidDate',
            render: (value) => {
                return <>
                    {moment(value).format('YYYY-MM-DD')}
                </>
            }
        },
        {
            title: 'Transaction Amount',
            dataIndex: 'transactionAmount',
            render: (text) => getLocalFormat(text, authContext.defaultPlantCurrency)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => {
                return <StatusTag status={text} />
            }
        },
        ...(type !== PaymentNoticePageEnum.CREATE && type !== PaymentNoticePageEnum.CLOSED && paymentType !== PaymentNoticePageEnum.RECEIVABLES ?
            [
                {
                    title: 'Action',
                    render: (value, record: PmtTransactionalDto) => {
                        return <>
                            {
                                type === PaymentNoticePageEnum.APPROVAL ? <Button type='primary' disabled={record.status === ContractStatusEnum.PENDING_APPROVAL ? false : true} onClick={() => approveTransactionRecord(record.transId, record.pmtNoticeId, ContractStatusEnum.FREEZED)}>Approve</Button> :
                                    type === PaymentNoticePageEnum.RELEASE ? <Button type='primary' disabled={record.status === ContractStatusEnum.FREEZED ? false : true} onClick={() => approveTransactionRecord(record.transId, record.pmtNoticeId, ContractStatusEnum.CLOSED)}>release</Button> : ''
                            }
                        </>
                    }
                }
            ]
            :
            []
        ),
        ...(paymentType === PaymentNoticePageEnum.RECEIVABLES && type === PaymentNoticePageEnum.RELEASE ?
            [
                {
                    title: 'Action',
                    render: (value, record: PmtTransactionalDto) => {
                        return <>
                            {
                                <Button type='primary' disabled={record.status === ContractStatusEnum.PENDING_APPROVAL ? false : true} onClick={() => approveTransactionRecord(record.transId, record.pmtNoticeId, ContractStatusEnum.CLOSED)}>release</Button>
                            }
                        </>
                    }
                }
            ]
            :
            []
        )



    ]

    const invoiceColumns = [

        {
            title: 'Invoice No	',
            dataIndex: 'invoiceNo',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoiceDate',
            render: (text, record) => { return record.invoiceDate !== undefined ? moment(record.invoiceDate).format('YYYY-MM-DD') : "" },
        },
        {
            title: 'Invoice %	',
            dataIndex: 'invoicePercent',
        },
        {
            title: 'Invoice Amount	',
            dataIndex: 'invoiceAmount',
            render: (text) => getLocalFormat(text, authContext.defaultPlantCurrency)
        },
        {
            title: 'Remaining Amount	',
            dataIndex: 'invoiceAmount',
            render: (text, row) => {
                const remainingInvoice = Number(text) - record?.transactionalData?.filter((rec) => rec.invoiceNo === row.invoiceId).reduce((a, c) => a + Number(c.transactionAmount), 0);
                return getLocalFormat(remainingInvoice, authContext.defaultPlantCurrency)
            }
        },
        {
            title: 'Invoice File',
            dataIndex: 'filesData',
            render: (value, record) => {
                return <>
                    <ViewFileHandling filesData={value.length ? value : []} required={true} />

                </>
            }

        },
        {
            title: 'Status	',
            dataIndex: 'status',
            render: (text, row) => {
                return <StatusTag status={text} />
            }
        },


    ];



    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.invoiceId);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    };
    const renderItems = (invoiceInfo: CostingInvoiceDto, index, indent, expanded) => {

        formRef.setFieldValue('invoiceNo', invoiceInfo.invoiceId)


        const invoiceTransactionData = record.transactionalData.filter((rec) => rec.invoiceNo === invoiceInfo.invoiceId);


        const outstandingInvoice = Number(invoiceInfo?.invoiceAmount ?? 0) - record?.transactionalData?.filter((rec) => rec.invoiceNo === invoiceInfo.invoiceId).reduce((a, c) => a + Number(c.transactionAmount), 0);
        return <>
            <div>
                <Form layout='vertical' form={formRef} autoComplete='off'>
                    <Form.Item name={"invoiceNo"} style={{ display: 'none' }}>
                        <Input hidden />
                    </Form.Item>
                    {(type === PaymentNoticePageEnum.CREATE && outstandingInvoice > 0) &&
                        <>
                            <Row gutter={16}>
                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                                    {type === PaymentNoticePageEnum.CREATE ?
                                        <Form.Item rules={[{ required: true }]}
                                            label={paymentType === PaymentNoticePageEnum.RECEIVABLES ? "Receivable by" : 'Payable by'}

                                            name="actLedger" style={{ width: '100%' }}>
                                            <Select

                                                onChange={(value, opt: any) => bankAccountsHandler(value, opt?.grpName)}
                                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank Account'>
                                                {ledgerAccounts.map((a) => {
                                                    return <Option grpName={a.actLedActGrpId.actGrpName} value={a.actLedId} key={a.actLedId}>{a.actLedName}</Option>
                                                })}
                                            </Select>
                                        </Form.Item> : <>{accountLedger?.actLedName}</>
                                    }

                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                                    {type === PaymentNoticePageEnum.CREATE ?
                                        <Form.Item
                                            label='Receiver Bank'
                                            rules={[{ required: false }]}
                                            name="kycId" style={{ width: '100%' }}

                                        >
                                            <Select
                                                disabled={hiddenState}
                                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch placeholder='Select Bank'>
                                                {kycData.map((a) => {
                                                    return <Option value={a.kycId} key={a.kycId}>{a.bankName}</Option>
                                                })}
                                            </Select>
                                        </Form.Item> : <>{accountLedger?.bankName}</>
                                    }
                                </Col>

                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
                                    {type === PaymentNoticePageEnum.CREATE ?
                                        <Form.Item
                                            label='Enter Payable Amount'
                                            name='transactionAmount'
                                            style={{ width: '100%' }}
                                            rules={[{ required: true, message: 'Enter Payable Amount' }
                                            ]}
                                        >
                                            <InputNumber
                                                disabled={isNaN(remainingAmount) || Math.abs(remainingAmount) < 0 ? true : false}
                                                style={{ width: '100%' }}
                                                max={outstandingInvoice}
                                                onChange={transactionAmountOnchangeHandler}
                                            />
                                        </Form.Item>

                                        : <> {approvalTransactionalTotalAmount}</>
                                    }
                                </Col>


                            </Row>
                            <br />
                            <Row>
                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
                                    <Form.Item
                                        label='Work Log'
                                        name='remarks'
                                        style={{ width: '100%' }}
                                        rules={[
                                            {
                                                required: true, message: 'Enter Work Log'// fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                                            },
                                            ...classValidator.alphaNumericSpecialCharsWithSpaces(),
                                        ]}
                                    >
                                        <Input style={{ width: '100%' }} onChange={() => { }} />
                                    </Form.Item>
                                </Col>
                                <Col span={4} />
                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} >
                                    <Form.Item
                                        style={{ marginTop: "24px" }}
                                    >
                                        <Button type="primary" onClick={() => submitHandler(invoiceInfo.invoiceId, ContractStatusEnum.ACTIVE)}>Submit For Approval</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }

                    {/* <Row> */}

                    <br />
                    {invoiceTransactionData.length !== 0 &&
                        <>
                            <Table pagination={false} columns={columns} dataSource={invoiceTransactionData} />
                        </>
                    }
                    {/* </Row> */}



                </Form>

            </div>
        </>
    };

    return (
        <Card>
            <AccountPayableCostings
                costingId={record.costingId}
                costingType={record.expenseType}
                remainingAmount={remainingAmount}
            />

            {invoiceData.length !== 0 &&

                <Table
                    columns={invoiceColumns}
                    dataSource={invoiceData}
                    rowKey={(row) => row.invoiceId}
                    pagination={false}
                    expandable={{
                        expandedRowRender: renderItems,
                        expandedRowKeys: expandedIndex,
                        onExpand: setIndex,
                        fixed: 'right'
                    }}
                    expandIcon={({ expanded, onExpand, record }) =>
                        expanded ? (
                            <UpCircleFilled
                                onClick={(e) => onExpand(record, e)}
                            >
                                Collapse
                            </UpCircleFilled>
                        ) : (
                            <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
                        )
                    }
                    scroll={{ x: 500 }}
                    size="large"
                />
            }
        </Card>
    )
}

export default PaymentNoticeChild;