import { Table } from 'antd'
import React from 'react'

const PreshipmentReport = () => {

   const columns: any = [
      {
         title: 'Supplier',
         dataIndex: 'supplier',
         key: 'supplier',
         width: '80px',
      },
      {
         title: 'Jetty',
         dataIndex: 'jetty',
         key: 'jetty',
         width: '80px',
      },
      {
         title: 'Basis',
         dataIndex: 'basis',
         key: 'jetty',
         width: '80px',
      },
      {
         title: 'GROSS CALORIFIC VALUE',
         children: [
            {
               title: 'Value 1',
               key: 'val1',
               width: '80px',
            },
            {
               title: 'Value 2',
               key: 'val2',
               width: '80px',
            },
            {
               title: 'Value 3',
               key: 'val3',
               width: '80px',
            },
         ],
      },
      {
         title: 'Ash(%)',
         dataIndex: '',
         key: 'ash',
         width: '80px',
      },
      {
         title: 'Total Sulphur(%)',
         dataIndex: 'TS',
         key: 'ts',
         width: '120px',
      },
      {
         title: 'Total Moisture(%)',
         dataIndex: 'TM',
         key: 'tm',
         width: '120px',
      },
      {
         title: 'Inherent Moisture(%)',
         width: '120px',
      },
      {
         title: 'Volatile Moisture(%)',
         width: '120px',
      },
      {
         title: 'Fixed Carbon Moisture(%)',
         width: '120px',
      },
      {
         title: 'Fuel Ratio FC/VM',
         width: '120px',
      },
      {
         title: 'HGI',
         width: '80px',
      },
   ]
   return (
      <div>
         <Table bordered columns={columns} scroll={{ x: 1500 }} />
      </div>
   )
}

export default PreshipmentReport