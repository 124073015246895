import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '@exportx/ui-utils';
import { messages } from '@exportx/shared-resources';
import {
  HashRouter
} from "react-router-dom";
import { ConfigProvider, theme } from 'antd';
import { IAMClientProvider } from './components/common';
export const intlMessages: any = {
  en: messages
};
export const language = navigator.language.split(/[-_]/)[0];
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const authServerUrl = window[`_env_`]['APP_MDM_SERVICE_URL'];
const clientId = window[`_env_`]['APP_IAM_CLIENT_ID'];
const mapLoginPlantCode = window[`_env_`][`MAP_LOGIN_PLANT_CODE`];
root.render(
  <IAMClientProvider authServerUrl={authServerUrl} clientId={clientId} mapLoginPlantCode={mapLoginPlantCode}>
    <HashRouter>
      <IntlProvider locale={language} messages={flattenMessages(intlMessages[language])}>
        <ConfigProvider
          theme={{
            algorithm: theme.compactAlgorithm,
            token: {
              colorPrimary: '#1890ff',
              borderRadius: 4
            },
          }}
        >
          <App />
        </ConfigProvider>

      </IntlProvider>
    </HashRouter>
  </IAMClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
