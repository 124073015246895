import React, { useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { useIntl } from 'react-intl';
import { ClassValidator } from '@exportx/ui-utils';
import { InputTypes } from '../generic-form/generic-form';
import { CountryService } from '@exportx/shared-models-and-services';
import GenericFormWithOutPopUP from '../generic-form-without-pop-up/generic-form-without-pop-up';

export interface IAddressProps {
    formRef: FormInstance<any>,
    perRowCount?: number;
    initialValues: any;
    title: string;
    isUpdate: boolean;
    isNewRecord: boolean;
    closeForm: () => void;
    clearData: () => void;
    submitForm: () => void;
    setPrimaryKeyDataFromSub: (objectData: any) => void;
}


export const AddressForm = (props: IAddressProps) => {
    const [countries, setCountries] = useState([]);
    const countryService = new CountryService();
    const { formatMessage: fm } = useIntl();
    const classValidator = new ClassValidator();
    const { formRef, initialValues, title, isUpdate, isNewRecord, perRowCount, clearData, submitForm, closeForm, setPrimaryKeyDataFromSub } = props;

    useEffect(() => {
        getAllCountriesDropDownData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllCountriesDropDownData = () => {
        countryService.getAllCountryDropDownDto().then(res => {
            if (res.status) {
                const aaa=res.data.map(rec => { return { value: rec.countryId, label: rec.countryName } });
                setCountries(aaa);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }
    const countryOnChangeHandler = (countryData) => {
        const selectedCountryData = countries.find(country => country.value === countryData)
        setPrimaryKeyDataFromSub({ [countryData]: selectedCountryData?.label });
    }
    const formItems = [
        {
            name: 'doorNo',
            label: fm({ id: "common.addressForm.doorNo", defaultMessage: 'Door No' }),
            fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "common.addressForm.reqDoorNo", defaultMessage: 'Please Enter Door No' }) },
            validationRules: [
                {
                    required: false, message: fm({ id: `common.addressForm.reqDoorNo`, defaultMessage: "Please Enter Door No" })
                },
                {
                    max: 30,
                    message: fm({ id: "common.addressForm.doorNoLength" })
                },
            ]
        },
        {
            name: 'addressLine1',
            label: fm({ id: "common.addressForm.addressLine1", defaultMessage: 'Address Line1' }),
            fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "common.addressForm.reqAddressLine1", defaultMessage: 'Enter Address Line1' }) },
            validationRules: [
                { required: true, message: fm({ id: "common.addressForm.reqAddressLine1", defaultMessage: "Enter Address Line1" }) }, {
                    max: 30,
                    message: fm({ id: "common.addressForm.addressLine1Length" })
                },
            ]
        },
        {
            name: 'addressLine2',
            label: fm({ id: "common.addressForm.addressLine2", defaultMessage: 'Address Line2' }),
            fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "common.addressForm.reqAddressLine1", defaultMessage: 'Enter Address Line2' }) },
            validationRules: [
                {
                    max: 30,
                    message: fm({ id: "common.addressForm.addressLine2Length" })
                },
            ]
        },
        {
            name: 'landmark',
            label: fm({ id: "common.addressForm.landmark", defaultMessage: 'Landmark' }),
            fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "common.addressForm.reqLandmark", defaultMessage: 'Enter LandMark' }) },
            validationRules: [
                {
                    max: 30,
                    message: fm({ id: "common.addressForm.landMarkLength" })
                },
            ]
        },
        {
            name: 'state',
            label: fm({ id: "common.addressForm.state", defaultMessage: 'State' }),
            fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "common.addressForm.reqState", defaultMessage: 'Enter State' }) },
            validationRules: [
                {
                    max: 30,
                    message: fm({ id: "common.addressForm.stateLength" })
                },
                ...classValidator.alphaSpaces(),
            ]
        },
        {
            name: 'zipCode',
            label: fm({ id: "common.addressForm.zipCode", defaultMessage: 'Zip Code' }),
            fieldType: { type: InputTypes.Input, placeHolder: fm({ id: "common.addressForm.reqState", defaultMessage: 'Enter Zip Code' }) },
            validationRules: [
                {
                    max: 10,
                    message: fm({ id: "common.addressForm.zipCodeLength" })
                },
                { required: false, message: fm({ id: "common.addressForm.reqZipCode" }) },
                ...classValidator.onlyNumeric(),
            ]
        },
        {
            name: 'country',
            label: fm({ id: "common.addressForm.country", defaultMessage: 'Country' }),
            fieldType: { type: InputTypes.Select, options: countries, placeHolder: fm({ id: "common.addressForm.reqCountry", defaultMessage: 'Select Country' }), callBack: countryOnChangeHandler },
            validationRules: [
                { required: true, message: fm({ id: "common.addressForm.reqCountry" }) },
            ]
        }
    ]
    return (
        <GenericFormWithOutPopUP
            formItems={formItems}
            formRef={formRef}
            initialValues={initialValues}
            title={title}
            isUpdate={isUpdate}
            isNewRecord={isNewRecord}
            perRowCount={perRowCount}
            closeForm={closeForm}
            submitForm={submitForm}
            clearData={clearData}
        />
    )
}

export default AddressForm;