import { SalesContractService } from "@exportx/shared-models-and-services";
import { Card, Col, Form, Input, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertMessages, useAuthState } from "../../../../../common";
import Link from 'antd/lib/typography/Link';


const SalesActiveApproval = () => {
  const [saleActive, setSaleActive] = useState<any>();
  const [searchData, setSearchData] = useState("")
  const { authContext } = useAuthState()
  let pathToreDirect = '/#/so-detail-view';
  const service = new SalesContractService();


  useEffect(() => {
    salesApproval();
  }, []);


  const salesApproval = () => {
    service.saleApprovedData({ plantCode: authContext.defaultPlant }).then(res => {
      if (res.status) {
        setSaleActive(res.data)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const tableColumns: any = [

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.bpName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.approvedBy)
            .toLowerCase()
            .includes(value.toLocaleString()))
      },
      render: (value, rowData) => {
        const link = `${pathToreDirect}?sc_id=${rowData.contractId}`
        return<> {
        <Link href={link} >{value}</Link>
      }
      </>}
    },

    // {
    //   title: 'Contract Type',
    //   dataIndex: 'contractType',
    // },

    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('YYYY-MM-DD') : "" },
      sorter: (a, b) => moment(a.contractDate).unix() - moment(b.contractDate).unix(),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Customer',
      dataIndex: 'bpName',
    },

    {
      title: 'Laycan',
      dataIndex: 'layCan',
      render: (value, record) => { return record?.layCan ? Array.isArray(record?.layCan) ? `${moment(record.layCan[0]).format('DD MMM YYYY')} - ${moment(record.layCan[1]).format('DD MMM YYYY')}` : `${moment(record.layCan.split(',')[0]).format('DD MMM YYYY')} - ${moment(record.layCan.split(',')[1]).format('DD MMM YYYY')}` : ''; }
    },

    {
      title: 'Quality & Quantity',
      dataIndex: 'quality'
    },

    {
      title: 'Approved By',
      dataIndex: 'approvedBy',
    }
  ]

  return (
    <Card>


      <Form layout="vertical">
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} ></Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }} offset={1} >

            <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: 'right', paddingBlockEnd: '10px' }} />
          </Col>
        </Row>



      </Form>
      <Table className='contracts' columns={tableColumns} dataSource={saleActive}></Table>

    </Card>

  )

}
export default SalesActiveApproval;