import { ColaIndexTypeEnum } from '@exportx/shared-models-and-services';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import CoalIndexPricesForm from '../coal-index-prices-form';
import CoalIndexPricesGrid from '../coal-index-prices-grid';
import { isPermissionExist } from '../../../../../common';
import { useNavigate } from 'react-router-dom';
interface ICoalIndexPageProps {
  coalIndexType: ColaIndexTypeEnum
}
export const CoalIndexPricesPage = (props: ICoalIndexPageProps) => {
  const [refreshKey, setRefreshKey] = useState(1);
  const [hasPermission,setPermission] = useState(false)
  const navigate = useNavigate();
  const { coalIndexType } = props;
  const saveCallBack = () => {
    setRefreshKey(key => key + 1)
  }

  useEffect(() => {
    let permission = false;
    
    if (coalIndexType === ColaIndexTypeEnum.MONTHLY) {
      permission = isPermissionExist([134]);
    } else {
      permission = isPermissionExist([126]);
    }
    
    setPermission(permission);
    
    if (!permission) {
      navigate('/');
    }
    
  }, [coalIndexType, navigate]);

  return (
    <Card>
      {coalIndexType === ColaIndexTypeEnum.WEEKLY && isPermissionExist([125]) ?
          <CoalIndexPricesForm coalIndexType={coalIndexType} saveCallBack={saveCallBack} /> : <></>
      }
      {(hasPermission) && (
          <CoalIndexPricesGrid coalIndexType={coalIndexType} key={refreshKey} />
      )}
    </Card>
  )
}

export default CoalIndexPricesPage;