import { CoalIndexedPriceService, ColaIndexTypeEnum, IndexedDataDto, ProrateGradeIndexes, SpecificationsEnum } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Divider, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages } from '../../../../../common';
import './coal-index-view.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';




interface IProps {
  indexedData: IndexedDataDto
  cancelHandler: () => void
}

export const CoalIndexView = (props: IProps) => {
  const { formatMessage: fm } = useIntl();
  const [data, setData] = useState([]);
  const { indexedData, cancelHandler } = props;
  const service = new CoalIndexedPriceService();
  console.log('indexData', indexedData);
  const columns = [];
  columns.push(
    {
      title: indexedData.coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'Week Ending' : 'Month Ending',
      dataIndex: indexedData.coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'weekEndingDate' : 'monthYear',
      render: (value, record) => {
        return <p>{value ? indexedData.coalIndexType === ColaIndexTypeEnum.WEEKLY ? moment(value).format('DD-MMM-YY') : moment(value)?.format('MMM-YYYY') : ''}</p>
    }

    }
  );



  indexedData?.proRateIndexName?.toString()?.split(',').forEach((garIndex, index) => {
    if (garIndex.slice(0, 3) === 'gar') {
      const garIndexNumber = Number(garIndex.slice(3, garIndex.length));
      const narIndex = ProrateGradeIndexes.garIndexes?.findIndex((grade) => grade === garIndexNumber);
      // ProrateGradeIndexes.garIndexes.find((grade, index) => {
      //   if (grade === garIndexNumber) {
      //     narIndex = index;
      //   }
      // })
      // columns.push({
      //   title: `${garIndexNumber}  ${SpecificationsEnum.GAR} (${ProrateGradeIndexes.narIndexes[narIndex]}  ${SpecificationsEnum.NAR})`,
      //   dataIndex: `${garIndex}`,
      // });

      columns.push({
        title: (
          <span>
            <span>{garIndexNumber} {SpecificationsEnum.GAR}</span>
            <span style={{ color: '#64748B', fontSize: '10px', fontWeight: 600 }}> ({ProrateGradeIndexes.narIndexes[narIndex]} {SpecificationsEnum.NAR})</span>
          </span>
        ),
        dataIndex: `${garIndex}`,
      });

    } else {
      const garIndexNumber = Number(garIndex.slice(3, garIndex.length));
      const narIndex = ProrateGradeIndexes.narIndexes?.findIndex((grade) => grade === garIndexNumber);
      // ProrateGradeIndexes.narIndexes.find((grade, index) => {
      //   if (grade === garIndexNumber) {
      //     narIndex = index;
      //   }
      // })
      // columns.push({
      //   title: `${ProrateGradeIndexes.garIndexes[narIndex]}  ${SpecificationsEnum.GAR} (${garIndexNumber}  ${SpecificationsEnum.NAR})`,
      //   dataIndex: `gar${ProrateGradeIndexes.garIndexes[narIndex]}`,
      // });

      columns.push({
        title: (
          <span>
            <span>{ProrateGradeIndexes.garIndexes[narIndex]} {SpecificationsEnum.GAR}</span>
            <span style={{ color: '#64748B', fontSize: '10px', fontWeight: 600 }}>
              {/* ({garIndexNumber} {SpecificationsEnum.NAR}) */}
            </span>
          </span>
        ),
        dataIndex: `gar${ProrateGradeIndexes.garIndexes[narIndex]}`,
      });

    }

  });

  useEffect(() => {
    let requestObject = {
      indexIds: indexedData.indexIds.split(','),
      coalIndexType: indexedData.coalIndexType
    }
    getIndexedData(requestObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexedData]);

  const getIndexedData = (requestObj: any) => {
    service.getIndexedDataOfGivenIds(requestObj).then(res => {
      if (res.status) {
        setData(res.data);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const getAvgData = (garIndex: string, weight: number) => {
    let total = 0;
    data.forEach(rec => {
      total += Number(rec[garIndex])
    })
    return parseFloat(((total / data.length) * (weight / 100)).toFixed(2));
  }

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? 'row-even' : 'row-odd'; 
  };


  return (
    <>
      {/* <Card
        style={{ height: '90vh', width: '100%', top: 0, bottom: 0 }}
        bodyStyle={{ maxHeight: '90vh' }}
        actions={[
          <Row justify='space-around'>
            <Col></Col>
            <Col>
              <Button onClick={() => { cancelHandler(); }}>close</Button>
            </Col>
          </Row>
        ]}>

        <div className='row'>
          <label className='contract_display_header_label' style={{ color: 'grey' }}>Coal Index Type</label>
          &nbsp;&nbsp;&nbsp;<span><b>{indexedData.coalIndexType}</b></span>
        </div><br />


        <Table columns={columns} dataSource={data} pagination={false} />
        <br />

        <div className='coal-index-css'>
          <table style={{ width: '100%' }}>
            <tr>
              <td>Prorata Grade Considered</td><td>{indexedData.proRateIndexName?.toString()}</td>
            </tr>
            {
              indexedData.isWeighted && <>
                <tr>
                  <td>Base line Gar</td><td>{indexedData.baselineGar}</td>
                </tr>
                {indexedData.proRateIndexName?.toString()?.split(',').map((rec, index) => {
                  const weight = Number(indexedData.prorataGarWeights.split(',')[index]);
                  return <tr>
                    <td>{weight}%</td><td>{getAvgData(rec, weight)}</td>
                  </tr>
                })}

              </>
            }
            <tr><td>Aveage coal Base Price PMT</td><td>{indexedData.avgCoalBasePrice}</td></tr>
            <tr><td>Prorata Coal Base Price PMT</td><td>{indexedData.proRateCoalBasePrice}</td></tr>
            <tr><td>Bonus Base Price PMT</td><td>{indexedData.bonusPricePMT} USD</td></tr>
            <tr><td>Discount Price PMT</td><td>{indexedData.discountPricePMT} USD</td></tr>
            <tr><td>Derived Coal Base Price PMT</td><td>{indexedData.finalCoalBasePricePMT} USD</td></tr>
            <tr><td><b>Final Coal Base Price PMT</b></td><td><b>{indexedData.finalCoalBasePricePMT} USD</b></td></tr>
          </table>
        </div>
      </Card> */}

            <Col style={{fontSize: '16px', fontWeight: 400, color: '#64748B'}}>Coal Index Type</Col>
            <Col><span style={{fontSize: '16px', fontWeight: 600, color: '#323F49'}}>{indexedData.coalIndexType}</span></Col>
            <br />

            {indexedData.isFutureIndex ? (

              <div>
                <Divider dashed style={{marginTop: 0}}></Divider>
                  <Row> 
                    <Col span={8}>
                      <div style={{ fontSize: '16px', fontWeight: 500, color: '#323F49' }}>
                        <p style={{ marginBottom: '4px', color: '#64748B' }}>Is Future Indexed</p>
                        <span>Yes</span>
                      </div>
                    </Col>

                    <Col span={8}>
                      <div style={{ fontSize: '16px', fontWeight: 500, color: '#323F49' }}>
                        <p style={{ marginBottom: '4px', color: '#64748B' }}>Future Index Date Method</p>
                        <span>{indexedData.futureIndexDateMethod}</span>
                      </div>
                    </Col>

                    <Col span={8}>
                      <div style={{ fontSize: '16px', fontWeight: 500, color: '#323F49' }}>
                        <p style={{ marginBottom: '4px', color: '#64748B' }}>Future Indexes</p>
                        <span>{indexedData.futureIndexPeriod}</span>
                      </div>
                    </Col>
                  </Row>
              </div>
            ):(

            <Table className='purchase-Details' bordered= {false} columns={columns} dataSource={data} pagination={false} rowClassName={rowClassName}/>
            
            )}

              <Col span={20} push={4} style={{ marginTop: '50px' }}>
              <Card title='Financials' headStyle={{fontSize: '16px', fontWeight: 600, color: '#323F49', border: 'none'}} bordered={false} style={{ width: '100%', backgroundColor: '#F2F7FD', boxShadow: 'none'}}>

            {indexedData.isWeighted && (
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', fontWeight: 500, color: '#323F49' }}>
                  <p style={{ marginBottom: "4px" }}>Baseline GAR</p>
                    <span>{indexedData.baselineGar}</span>
                </div>
                  {indexedData.proRateIndexName?.toString()?.split(',').map((rec, index) => {
                  const weight = Number(indexedData.prorataGarWeights.split(',')[index]);
                  return (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', color: '#4A5E6D', fontSize: '14px', fontWeight: 500}}>
                      <span style={{marginBottom: '4px'}}>{weight}% ({rec.slice(3, rec.length)})</span>
                      <span>{getAvgData(rec, weight)}</span>
                    </div>
                    );
                    })}
              </div>
            )}

              <Divider dashed style={{marginTop: '6px'}}></Divider>

              <div style={{ fontSize: '16px', fontWeight: 500, color: '#323F49'}}>CoalBase Prices</div>&nbsp;

              <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: 500, color: '#4A5E6D' }}>
                <p>Average PMT</p>
                <span>{indexedData.avgCoalBasePrice} USD</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: 500, color: '#4A5E6D' }}>
                <p>Prorata PMT</p>
                <span>{indexedData.proRateCoalBasePrice} USD</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: 500, color: '#4A5E6D' }}>
                <p>Stevedoring/Floating Crane</p>
                <span>{indexedData.steveDoorPrice} USD</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: 500, color: '#4A5E6D' }}>
                <p>Bonus PMT</p>
                <span>{indexedData.bonusPricePMT.toFixed(2)} USD</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', fontWeight: 500, color: '#4A5E6D' }}>
                <p>Discount PMT</p>
                <span>{indexedData.discountPricePMT.toFixed(2)} USD</span>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', fontWeight: 600, color: '#323F49' }}>
                <p>Delivered PMT</p>
                <span>{indexedData.finalCoalBasePricePMT} USD</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px', fontWeight: 600, color: '#323F49' }}>
                <p>Final PMT</p>
                <span>{indexedData.finalCoalBasePricePMT} USD</span>
              </div>

              </Card>
              </Col>
    </>
  )
}

export default CoalIndexView;

