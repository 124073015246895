import { BasisDropDownDto, BasisService } from '@exportx/shared-models-and-services';
import { Card, Col, Form, FormInstance, Input, Row, Select } from 'antd'
import { FormLayout } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react'


interface IUltimateProps {
  formRef: FormInstance<any>;
  initialValues: any;
  children?: React.ReactNode;
  layoutType: FormLayout;
}



export const Ultimate = (props: IUltimateProps) => {
  const { formRef, initialValues,  layoutType } = props;
  const { Option } = Select;

  const basisService = new BasisService();
  const [basisData, setBasisData] = useState<BasisDropDownDto[]>([]);
  useEffect(() => {
    getCBasisData();
  }, []);


  const getCBasisData = () => {
    basisService.getAllBasisDropDownDto().then(res => {
      if (res.status) {
        setBasisData(res.data);
      }
    }).catch(err => {
      console.log('error: ' + err.message);
    })
  }




  return (
    <Card>
      <Form layout={layoutType} form={formRef} initialValues={initialValues}>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Carbon(%)" name='ultimateCarbonBasis'>
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
               
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>



            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name='ultimateCarbonTypical'>
              <Input placeholder='Typical' type="number" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name='ultimateCarbonRejection'>
              <Input placeholder='Rejection' type="number" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Hydrogen(%)" name='ultimateHydrogenBasis'>
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                placeholder='Basis'
              >
                <Option value={''}>Please Select</Option>
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>

            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name='ultimateHydrogenTypical'>
              <Input placeholder='Typical' type="number" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name='ultimateHydrogenRejection'>
              <Input placeholder='Rejection' type="number" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row >
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item label="Nitrogen(%)" name='ultimateNitrogenBasis'>
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}  allowClear showSearch placeholder='Basis'>
             
                {basisData.map(item => {
                  return <Option value={item.basisCode}>{item.basisCode}</Option>
                })}
              </Select>


            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name='ultimateNitrogenTypical'>
              <Input placeholder='Typical' type="number" style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item name='ultimateNitrogenRejection'>
              <Input type="number" placeholder='Rejection' style={{ marginTop: "30px" }} />
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Card>
  )
}
export default Ultimate;