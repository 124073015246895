import { Tabs } from "antd";
import BargeAgreementPendingApproval from "./barge-approval/barge-agreement-pending";
import BargeAgreementActiveApproval from "./barge-approval/barge-agreement-active";


const { TabPane } = Tabs;
const BargeAgreementApproval = () => {
  return (

    <Tabs destroyInactiveTabPane={true}>
      <TabPane tab="Pending" key="1">
        <BargeAgreementPendingApproval />
      </TabPane>
      <TabPane tab="Approved" key="2">
        <BargeAgreementActiveApproval  />
      </TabPane>


    </Tabs>


  )
}

export default BargeAgreementApproval;